(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'MessageService', 'Customer', '$rootScope', 'Auth', '$stateParams', '$cookieStore'];

    function HomeController ($scope, Principal, LoginService, $state, MessageService, Customer, $rootScope, Auth, $stateParams, $cookieStore) {
        var vm = this;
        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.storeId = $stateParams.storeId || $cookieStore.get("store-id");
        if (!vm.storeId) {
            return;
        }

        $scope.with = window.screen.width;
        vm.password = null;
        vm.rememberMe = true;
        vm.phone = null;

        Auth.logout();

         
        function cancel() {
            vm.credentials = {
                phone: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
        }

        function login(event) {
            if (vm.phone == null || vm.phone == '' || vm.password == null || vm.password == '') {
                MessageService.error("请输入手机号和密码");
                return;
            }
            event.preventDefault();
            Auth.login({
                phone: vm.phone,
                password: vm.password,
                storeId:vm.storeId,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;

                $state.go('webArticle', {storeId:vm.storeId});
                $(".wrap").css({margin: '48px 10px 10px 130px'});

                localStorage.removeItem('menu1');
                localStorage.removeItem('menu2');
                localStorage.removeItem('pageName');

                $rootScope.$broadcast('authenticationSuccess');
                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function (err) {
                MessageService.error("用户名或者密码错误");
                vm.authenticationError = true;
            });


        }

    }
})();
