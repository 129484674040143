(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('GalleryUpload0PageController', GalleryUpload0PageController);

    GalleryUpload0PageController.$inject = ['$scope', '$q', '$stateParams', '$state', '$localStorage', 'StoreWechatSetting', '$http', 'PlatformWechatSetting', 'AuthServerProvider', 'Store', 'Article'];

    function GalleryUpload0PageController($scope, $q, $stateParams, $state, $localStorage, StoreWechatSetting, $http, PlatformWechatSetting, AuthServerProvider, Store, Article) {
        var vm = this;
        $(".navbar").hide();
        $(".wrap").hide();
        var storeId = $stateParams.storeId;
        var fid = $stateParams.fid;
        var aid = $stateParams.aid;
        localStorage.setItem('themeFlag', 'two');

        if ($stateParams.auth) {
            AuthServerProvider.storeAuthenticationToken($stateParams.auth, true);
            init();
        }
        else if ($stateParams.ticket) {
            var token = getToken($stateParams.ticket, function(token) {
                if (token) {
                    AuthServerProvider.storeAuthenticationToken(token, true);
                    init();
                } else {
                    // illegal
                    console.error("Cannot open article with failure of getToken");
                }
            });
        } else {
            // illegal
            console.error("Cannot open article with nothing ticket or auth");
        }

        function getToken(ticket, callback) {
            Article.getToken({ticket: ticket}, function (res) {
                if (res.status == 200) {
                    var token = res.data;
                    if (token && callback) {
                        callback(token);
                    } else {
                        console.log("Invalid token or callback, token=" + token);
                    }
                } else {
                    console.log("Error to getToken with ticket: " + ticket + ", article=" + aid);
                }
            });
        }

        function init() {
            $state.go('galleryUploadPage', {storeId: storeId, fid: fid, aid: aid})
        }
        // /{storeId}/{fid}/gallery/uploading/{userId}
    }
})();
