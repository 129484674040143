(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('EditorPhotoAlbumEcController', EditorPhotoAlbumEcController);
    EditorPhotoAlbumEcController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'WxJS', 'Gallery', 'ItemValue', 'SweetAlert', 'Theme', '$localStorage', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'Store', 'Article', 'CommonService', '$http',
        'Folder', 'MessageNotification', 'ThemeClassify', 'entity', 'Product', 'ProductXml', 'DateUtils', 'Iphone', 'Algorithm',
        'StoreWorkEditConfig', '$translate', 'MessageService','IMAGE_SUPPORTED_MIME','StoreStorages','$websocket'];

    function EditorPhotoAlbumEcController($state, $scope, $q, $stateParams, Convert, Qinius, WxJS, Gallery, ItemValue, SweetAlert, Theme, $localStorage, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, Store, Article, CommonService, $http,
          Folder, MessageNotification, ThemeClassify, entity, Product, ProductXml, DateUtils, Iphone, Algorithm,
          StoreWorkEditConfig, $translate, MessageService,IMAGE_SUPPORTED_MIME,StoreStorages, $websocket) {
        //初始化
        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');
        $(".wrap").removeAttr("style");
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        //#ifdef MP-WEIXIN || MP-TOUTIAO
        console.log('MP-WEIXIN')
        // #endif

        var vm = this;
        vm.isEdit = entity.type == 'edit' ? true : false;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.counts = 0;
        vm.selectImg = [];
        vm.indexNum = 0;
        vm.maxId = null;
        vm.itemsPerPage = 40;
        vm.photoAll = [];
        $scope.domains = Qinius.getDomain();
        vm.platformSetting = PlatformWechatSetting.get();
        vm.photoArray = [];
        vm.photoSelectAll = localStorage.getItem("photoSelectAll") ? JSON.parse(localStorage.getItem("photoSelectAll")) : [];
        vm.isPhone = false;
        vm.threeIsModal = false;
        vm.threeModalText = "";
        vm.threeLoading = true;
        vm.threeLoadingText=$translate.instant("hint.loadingText");
        vm.threeError = false;
        vm.threeErrorText=$translate.instant("hint.loadingText");
        vm.threeError1 = false;
        vm.threeErrorText1='';
        vm.page8 = false;
        vm.store = Store.get({id: vm.storeId});
        $scope.agentIos = false;
        Qinius.get({}, function (res) {
            vm.token = res.token;
        });
        vm.isIPhone6 = isIPhone6();
        vm.supportedMimeTypes = IMAGE_SUPPORTED_MIME;

        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            //或者return false;
            e.preventDefault();
        };

        init()

        var templateEdit = localStorage.getItem("templateEdit") != null ?  localStorage.getItem("templateEdit") : "";
        function init() {
            if (!vm.isEdit) {
                Article.getByUser({id: $stateParams.aid}, function (resp) {
                    if (resp.status == 200) {
                        vm.article = resp.data;
                        if(vm.article.invalid){//作品失效
                            $state.go('articlesFailure',{storeId:vm.storeId});
                            return
                        }
                        getProduct(vm.article.productId,function () {
                            ItemValue.get({id: $stateParams.vid}, function (res) {
                                $("body").toggleClass('bg');
                                $scope.status = localStorage.getItem('Production');
                                vm.productXmlLine = angular.copy(res.data.productXmlLines[0]);
                                angular.forEach(res.data.productXmlLines, function (line) {
                                    if (line.id == vm.xmlId) {
                                        vm.productXmlLine = angular.copy(line);
                                    }
                                })
                                vm.article.cover = vm.productXmlLine.coverImg;
                                vm.article.themeClassifyId = vm.productXmlLine.productXmlThemeId;
                                vm.article.themeClassifyName = vm.productXmlLine.productXmlThemeName;
                                vm.article.productXmlId = vm.productXmlLine.productXmlId;
                                vm.article.productXmlName = vm.productXmlLine.productXmlName;
                                vm.article.musicIdentify = vm.productXmlLine ? vm.productXmlLine.musicIdentify : res.data.musicIdentify;
                                vm.article.editorType= res.data.productEditorType;
                                vm.article.itemValueId= res.data.itemValueId || vm.vid;
                                if (!res.data.productBokehType) {
                                    vm.article.bokehtype = 'None';
                                } else {
                                    vm.article.bokehtype = res.data.productBokehType;
                                }
                                console.log(vm.article.bokehtype)
                                Convert.xmlToDocument2({
                                    identifier: vm.productXmlLine.productXmlIdentifier,
                                    provider: "qiniu",
                                    editorType:vm.article.editorType,
                                    patternType:vm.product.patternType,
                                }, function (res) {
                                    vm.replace = res.replace;
                                    vm.counts = 0;
                                    $scope.pages = res;
                                    if($scope.pages.config.productType == 'CALENDAR'&&$scope.pages.config.dynamic){
                                        vm.isCalendar = true;
                                        yearInfo()
                                    }
                                    angular.forEach(res.pages.page, function (page, i) {
                                        if(!page.backup){
                                            angular.forEach(page.levels.level, function (level) {
                                                if (level.imagebox && level.imagebox.geometry) {
                                                    vm.counts++;
                                                }
                                            })
                                        }
                                    });
                                    if(templateEdit){
                                        threeInfo1($translate.instant("editorCommon.replaceTemplateSuccess"));
                                    }
                                    vm.page0=true;
                                    if(vm.counts == 0 || templateEdit){
                                        vm.page0=false;
                                        vm.page1 = false;
                                        vm.page2 = false;
                                        $scope.book();
                                        loadAll('isNull')
                                    }
                                    vm.threeLoading = false;
                                });
                            });
                        });
                    }
                },function (error) {
                    if(error.status === 403){//token失效
                        return $state.go('404',{storeId:vm.storeId});
                    }
                    if(error.data.status === 404){//未找到作品
                        $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                    }
                })
            } else {
                vm.page0=false;
                Article.getByUser({id: $stateParams.aid}, function (res) {
                    vm.article = res.data;
                    if(vm.article.invalid){//作品失效
                        $state.go('articlesFailure',{storeId:vm.storeId});
                        return
                    }
                    getProduct(vm.article.productId,function () {
                        ItemValue.get({id: res.data.itemValueId}, function (resp) {
                            if(resp.status == 200){
                                vm.article.skuNumber = resp.data.skuNumber;
                                vm.article.productBokeh = resp.data.productBokeh;
                                vm.article.productBokehType = resp.data.productBokehType;
                                if (!resp.data.productBokehType) {
                                    vm.article.bokehtype = 'None';
                                } else {
                                    vm.article.bokehtype = resp.data.productBokehType;
                                }
                                angular.forEach(resp.data.productXmlLines, function (line) {
                                    if (line.productXmlId == vm.article.productXmlId) {
                                        vm.productXmlLine = angular.copy(line);
                                    }
                                });
                                Convert.xmlToDocument2({
                                    identifier: vm.article.fileName,
                                    provider: 'qiniu',
                                    editorType:vm.article.editorType,
                                    patternType:vm.product.patternType,
                                }, function (res) {
                                    vm.article.document = res;
                                    vm.replace = res.replace;

                                    angular.forEach(vm.article.document.pages.page, function (page, i) {
                                        if(!page.backup){
                                            angular.forEach(page.levels.level, function (level) {
                                                if (level.imagebox && level.imagebox.geometry) {
                                                    vm.counts++;
                                                }
                                            })
                                        }
                                    });
                                    $scope.book();
                                    vm.threeLoading = false;
                                });
                            }else {
                                MessageService.error(res.message);
                            }
                        });
                    });
                },function (error) {
                    if(error.status === 403){//token失效
                        return $state.go('404',{storeId:vm.storeId});
                    }
                    if(error.data.status === 404){//未找到作品
                        $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                    }
                })
                loadAll('isNull');
            }
            vm.page1=false;
            vm.page2 = false;
            vm.page4 = false;
            vm.page5 = false;

            $scope.qiniuId = localStorage.getItem("gallery") ? JSON.parse(localStorage.getItem("gallery")) : [];
        }
        function getProduct(id,callback){
            Product.get({id: id, cancelled: false}, function (res) {
                vm.product = res.data;
                vm.forceFill = res.data.forceFill;
                if(callback){callback()}
            });
        }

        $q.all([$scope.domains.$promise,vm.platformSetting.$promise]).then(function () {
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            vm.platformSetting = vm.platformSetting.data;

            vm.lagnuages =[];
            if ($localStorage.lagnuages) {
                for(var i = 0 ; i<$localStorage.lagnuages.length; i++ ){
                    var lang = $localStorage.lagnuages[i];
                    var langObj = {key:lang};
                    if (lang == 'zh-cn') {
                        langObj.value = "中文";
                    }
                    if (lang == 'en') {
                        langObj.value = "English";
                    }
                    if (vm.lang == lang) {
                        vm.langValue = langObj.value;
                        langObj.selected = true;
                    }
                    vm.lagnuages.push(langObj);
                }
            }


            /**
             * wechat api
             */
            if (wx) {
                var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
                WxJS.get({url: url}, function (res) {
                    if(res.status==200) {
                        $scope.agentIos = browserVersions();
                        var config = res.data;
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.appId, // 必填，公众号的唯一标识
                            timestamp: config.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.nonceStr, // 必填，生成签名的随机串
                            signature: config.signature,// 必填，签名，见附录1
                            jsApiList: ['chooseImage', 'uploadImage', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function () {
                            console.log('wechat api ready!');

                            wx.hideMenuItems({
                                menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                            });
                        });
                        wx.error(function (res) {
                            console.log(res)
                        });
                    }
                }, function () {
                    console.log("wx config error");
                });
            }

            //获取照片旋转角度使用
            //消息列表
            $timeout(function () {
                qInit();
            })
            vm.isIPhoneX = Iphone;
            photoAllInfo();
            websocket();
        });
        //------------------------------------------------  page0 ------------------------------------------------
        vm.isUploadNeedKnow = false;
        $scope.uploadNeedKnowClick = function () {
            vm.isUploadNeedKnow = true;
        }
        $scope.uploadNeedKnowHide = function () {
            vm.isUploadNeedKnow = false;
        }
        vm.isPage0Upload = false;
        $scope.page0Upload = function(){
            uploadAble(StoreStorages,vm.storeId, function (res) {
                if(res.status == 200){
                    vm.isPage0Upload = true;
                    if($scope.agentIos){
                        $rootScope.$broadcast('clickAndroidUpload',true);
                    }else {
                        $timeout(function () {
                            $("#uploadImagePhotobook").trigger("click");
                        })
                    }
                }else{
                    if(res.status == 400){
                        threeInfo('系统出错');
                    } else {
                        threeInfo(res.data||'系统出错');
                    }
                }
            })
        }
        $scope.$on('uoloadFinished', function(event, args) {
            if(args){
                if(vm.isPage0Upload){
                    vm.page0 = false;
                    vm.page1 = true;
                    vm.isPage0Upload = false;
                }
                if(vm.fullMap){
                    vm.photoSelectAll.forEach(function (item) {
                        item.selected = true;
                        vm.photoSelectAll2.push(item)
                    })
                }
                updatePic();
            }
        });
        vm.uploadPhotoItem=[];

        $scope.uploadPhoto = function () {
            uploadAble(StoreStorages,vm.storeId, function (res) {
                if(res.status == 200){
                    if($scope.agentIos){
                        $rootScope.$broadcast('clickAndroidUpload',true);
                    }else{
                        $timeout(function () {
                            $("#uploadImagePhotobook").trigger("click");
                        })
                    }
                }else{
                    if(res.status == 400){
                        threeInfo('系统出错');
                    } else {
                        threeInfo(res.data||'系统出错');
                    }
                }
            })
        }
        //------------------------------------------------  page1 ------------------------------------------------
        $scope.showGallery = function () {
            $('.c-productThree2-head3-page2').animate({
                scrollTop: 0
            }, 200);
            vm.page0 = false;
            vm.page1 = false;
            vm.page2 = true;
            vm.page3 = false;
            vm.page4 = false;
            vm.currentNav = 'gallery';//folder
            vm.photoAll=[];
            vm.indexNum = 0;
            vm.maxId = null;
            vm.photoCompleted = false;
            vm.isShowGallery = true;
            loadAll('isNull');
        }

        var longPressTimer = null;
        vm.draggable = false;
        var sort1 = null;
        function photoAllInfo() {
            sort1 = new Sortable(document.getElementById('photoAllId'), {
                swap: false,
                filter: '.filtered',
                animation: 150,
                forceFallback: false,
                delay: 500, // 设置长按时间为0.5秒
                onEnd: function (/**Event*/evt) {
                    sortArray = [];
                    angular.forEach($('#photoAllId').find(".sortingPageView"), function (item, index) {
                        var page = item.getAttribute("data-page");
                        sortArray.push(JSON.parse(page));
                    });
                    if(sortArray && sortArray.length>0){
                        vm.photoSelectAll = [];
                        $timeout(function () {
                            vm.photoSelectAll = angular.copy(sortArray);
                            sortArray = [];
                        })
                    }
                    // longPressTimer = setTimeout(function() {
                    //     sort1.option("delay", 500);
                    //     vm.draggable = false;
                    //     $scope.$apply();
                    // }, 1000);
                },
                // 元素被选中
                onChoose: function (/**Event*/evt) {
                    vm.draggable = true;
                    $scope.$apply();
                    sort1.option("delay", 0);
                    // clearTimeout(longPressTimer);
                },
            });
        }

        $scope.completeSort = function(){
            sort1.option("delay", 500);
            vm.draggable = false;
            // $scope.$apply();
        }

        $scope.photoReverse = function () {
            if(vm.photoSelectAll && vm.photoSelectAll.length>0){
                vm.photoSelectAll.reverse();
            }
        }
        //------------------------------------------------  page2 ------------------------------------------------
        var sort = null;
        $scope.getBack = function (item) {
            if(item == 'page2'){
                vm.page1 = true;
                vm.page2 = false;
            }
            if(item == 'page7'){
                vm.page7 = false;
                vm.page3 = true;
                sort.destroy();
            }
            if(item == 'page9'){
                vm.page2 = true;
                vm.page9 = false;
                vm.folderOne = null;
            }
        }
        $scope.galleryNavSel = function (item) {
            vm.currentNav = item;
            vm.indexNum = 0;
            vm.maxId = null;
            vm.photoCompleted = false;
            vm.photoAll = [];
            vm.folderOne = null;
            if(item == 'gallery'){
                loadAll('isNull')
            }
            if(item == 'folder'){
                loadFolders()
            }
        };

        function qInit(){
            $('.c-productThree2-head3-page2').on('scroll', function(event){
                var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                if(!vm.photoCompleted){
                    if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                        vm.indexNum++;
                        loadAll('isNull');
                    }
                }
            })
            $('.c-productThree2-head3-page9').on('scroll', function(event){
                var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                if(!vm.photoCompleted){
                    if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                        vm.indexNum++;
                        loadAll(vm.folderOne.id);
                    }
                }
                $scope.$digest();
            })
        }
        // 增加文件夹
        $scope.addFolder = function () {
            swal({
                title: $translate.instant("editorCommon.addPhotoAlbumPop"),
                text: "",
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonText: $translate.instant("button.confirm"),
                cancelButtonText: $translate.instant("button.cancel"),
                animation: "slide-from-top",
                inputPlaceholder: $translate.instant("editorCommon.pleaseFolderName")
            }, function (inputValue) {
                if (inputValue === false) {
                    $(".folder-footer").show();
                    return false;
                }
                if (inputValue === "") {
                    swal.showInputError($translate.instant("editorCommon.pleaseName"));
                    return false
                }
                if (inputValue.indexOf(" ") != -1) {
                    swal.showInputError($translate.instant("editorCommon.pleaseEnterTheContent"));
                    return false
                }
                Folder.save({
                    articleId:vm.article.id,
                    name: inputValue,
                    type: 'Gallery'
                }, function (resp) {
                    $scope.Folders.push(resp.data);
                    console.log($scope.Folders)
                    swal.close();
                }, function () {
                    swal.showInputError($translate.instant("editorCommon.pleaseEnterTheCorrectFileName"));
                })
            });
        };
        //文件夹多选
        vm.isChoiceFolder = false;
        $scope.choiceFolder = function () {
            vm.isChoiceFolder = true;
        }
        $scope.cancelFolder = function () {
            vm.isChoiceFolder = false;
            vm.folderSelectData = "";
            $scope.Folders.forEach(function (data) {
                data.selected = false;
            })
        }
        vm.isEditFolderName = false;
        $scope.editFolder = function () {
            if(!vm.folderSelectData && vm.folderListSelect.length != 1){
                threeInfo($translate.instant("editorCommon.pleaseSelectAFolder"));
                return
            }
            vm.isEditFolderName = true;
        }
        $scope.editFolderNameHide = function () {
            vm.isEditFolderName = false;
        }
        $scope.editFolderNameSave = function () {
            vm.isEditFolderName = false;
            if (!vm.folderSelectData || !vm.folderSelectData.name) {
                threeInfo($translate.instant("editorCommon.pleaseName"));
                return false
            }
            if (vm.folderSelectData.name.indexOf(" ") != -1) {
                threeInfo($translate.instant("editorCommon.pleaseEnterTheContent"));
                return false
            }
            Folder.update(vm.folderSelectData, function (resp) {
                threeInfo($translate.instant("editorCommon.folderNameSuccess"));
                $scope.cancelFolder();
                loadFolders();
                swal.close();
            }, function () {
                threeInfo($translate.instant("editorCommon.pleaseEnterTheCorrectFileName"));
            })
        }


        $scope.Folders = [];
        function loadFolders() {
            Folder.storeGetAllByCondition({
                items: [{key: "type", op: "=", value: "Gallery"},{key: "articleId", op: "=", value: vm.article.id}],
                sort: ["id,asc"]
            }, function (resp) {
                $scope.Folders = resp.data || [];
            })
        }
        vm.folderSelectData = "";
        $scope.folderSelect = function (item) {
            vm.photoCompleted = false;
            if(vm.isChoiceFolder){
                item.selected = !item.selected ? true : false;
                foldersInfo()
            }else {
                vm.folderOne = item;
                vm.photoAll = [];
                vm.photoArray = [];
                vm.indexNum = 0;
                vm.maxId = null;
                loadAll(item.id)
                vm.page2 = false;
                vm.page9 = true;
            }
        }
        vm.folderListSelect = [];
        function foldersInfo(){
            vm.folderListSelect = [];
            $scope.Folders.forEach(function (data) {
                if(data.selected){
                    vm.folderSelectData = data;
                    vm.folderListSelect.push(data);
                }
            })
        }
        $scope.folderSave = function () {
            if(vm.fullMap){
                if(vm.photoSelectAll.length > vm.fullMapNumber){
                    threeInfo($translate.instant("hint.imagesMaxQuantity"))
                    return
                }
                vm.threeLoading = true;
                vm.threeLoadingText = "加载中";
                photoSave1(vm.photoSelectAll,0,function () {
                    vm.threeLoading = false;
                    $scope.fullMapHide();
                });
                return
            }
            vm.page1 = true;
            vm.page2 = false;
            vm.page9 = false;
            vm.folderOne = null;
            for(var i=0; i<vm.photoSelectAll.length; i++){
                vm.photoSelectAll[i].seq = i+1;
            };
        }
        $scope.folderSave1 = function(){
            vm.threeLoading = true;
            vm.threeLoadingText = $translate.instant("hint.loadingText");
            $scope.cancelFolder();
            angular.forEach(vm.folderListSelect, function (item) {
                folderSave1Info(item)
            })
        }
        function folderSave1Info(item){
            Gallery.storeByCondition({
                items: [{key: "folderId", op: "=", value: item.id}],
                page: vm.indexNum,
                size: vm.itemsPerPage,
                sort: sorts()
            }, function (res) {
                for (var i = 0; i < res.length; i++) {
                    if (!res[i].cancelled) {
                        res[i].selected = true;
                        photoSelect(res[i].selected, res[i])
                    }
                };
                vm.threeLoading = false;
                vm.threeLoadingText = '';
                vm.page2 = false;
                vm.page1 = true;
            },function () {

            });
        }
        //------------------------------------------------  page3 ------------------------------------------------
        vm.sortingPage = [];
        var sortArray = [];
        $scope.getSortIndex = function (index) {
            var sortIndexList = sort.toArray();
            var _index = sortIndexList.findIndex(function(item){
                return item == index
            })
            return _index + 1;
        }
        $scope.sortingPage = function () {
            var _unreplacePage=[];
            for(var i=0; i<$scope.template.pages.page.length; i++){
                if(!$scope.template.pages.page[i].unreplace){
                    _unreplacePage.push($scope.template.pages.page[i])
                }
            }
            if(_unreplacePage.length<2 || !vm.replace){
                swal({
                    title: $translate.instant("editorCommon.describe27"),
                    text: "",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }

            vm.sortingPage = [];
            sortArray = [];
            vm.page7 = true;
            vm.page3 = false;
            $timeout(function () {
                vm.sortingPage = angular.copy($scope.template.pages.page);
                var currentId = null;
                var originalId = null;
                var currentSpread = null;
                var originalSpread = null;
                var moveTimer = null;
                sort = new Sortable(document.getElementById('my-ui-list1'), {
                    swap: true,
                    filter: '.filtered',
                    cancel: ".notDrag",
                    animation: 150,
                    onMove:function (evt,originalEvent) {
                        currentSpread = evt.dragged.dataset.spread;
                        originalSpread = originalEvent.target.dataset.spread;
                        currentId = evt.dragged.dataset.id;
                        originalId = originalEvent.target.dataset.id;
                        var itemDomList = document.getElementById("my-ui-list1").children;
                        var currentIndex = null;
                        var originalIndex = null;
                        for(var i=0;i< itemDomList.length -1;i++){
                            $(originalEvent.target).find(".sort-item-mask").css({
                                display:'none'
                            });
                            $(itemDomList[i]).find(".sort-item-cell").css({
                                display:'none'
                            })
                            if(itemDomList[i].dataset.id === currentId){
                                currentIndex = i;
                            }
                            if(itemDomList[i].dataset.id === originalId){
                                originalIndex = i;
                            }
                        }
                        //给禁用的项加蒙版
                        if(currentSpread !== originalSpread){
                            $(originalEvent.target).find(".sort-item-mask").css({
                                display:'flex'
                            });
                            for(var i=0;i< itemDomList.length -1;i++){
                                $(itemDomList[i]).find(".sort-item-cell").css({
                                    display:'none'
                                })
                            }
                        }else{//给可插入的加插入线
                            for(var i=0;i< itemDomList.length -1;i++){
                                $(itemDomList[i]).find(".sort-item-mask").css({
                                    display:'none'
                                })
                            }
                            if(currentIndex > originalIndex){//加左边插入线
                                $(originalEvent.target).find(".sort-item-cell").css({
                                    left:'-10px',
                                    right:'auto',
                                    display:'block'
                                })
                            }else{
                                //加右侧插入线
                                $(originalEvent.target).find(".sort-item-cell").css({
                                    right:'-10px',
                                    left:'auto',
                                    display:'block'
                                })
                            }

                        }
                        if(moveTimer){
                            clearTimeout(moveTimer);
                            moveTimer = null;
                        }
                        moveTimer = setTimeout(function () {
                            currentId = null;
                            for(var i=0;i< itemDomList.length -1;i++){
                                $(itemDomList[i]).find(".sort-item-cell").css({
                                    display:'none'
                                })
                                $(itemDomList[i]).find(".sort-item-mask").css({
                                    display:'none'
                                })
                            }
                        },100)
                        return false;

                    },
                    onEnd: function (/**Event*/evt) {
                        //排序结束
                        if(!currentId) return;
                        var itemDomList = document.getElementById("my-ui-list1").children;
                        var moveList = [];
                        var lockList = [];
                        for(var i=0;i< itemDomList.length;i++){
                            $(itemDomList[i]).find(".sort-item-mask").css({
                                display:'none'
                            });
                            $(itemDomList[i]).find(".sort-item-cell").css({
                                display:'none'
                            })
                            if(itemDomList[i].dataset.spread === currentSpread){
                                moveList.push(itemDomList[i].dataset.id);
                            }else{
                                lockList.push({
                                    id:itemDomList[i].dataset.id,
                                    index:i
                                })
                            }
                        }
                        var currendIndex = moveList.findIndex(function(item){ return item === currentId});
                        var originalIndex = moveList.findIndex(function(item){ return item === originalId});
                        if(currendIndex < 0 || originalIndex < 0) return;
                        //两个元素相邻，做交换操作
                        if(currendIndex + 1 === originalIndex || originalIndex + 1 === currendIndex){
                            var temp = moveList[currendIndex];
                            moveList[currendIndex] = moveList[originalIndex];
                            moveList[originalIndex] = temp;
                        }else{
                            //先删除当前的元素
                            moveList.splice(currendIndex,1);
                            originalIndex = moveList.findIndex(function(item){ return item === originalId});
                            if(currendIndex < originalIndex){//向下移动，插入到后面
                                moveList.splice(originalIndex+ 1,0,currentId);
                            }else{//向上移动，插入到目标元素前面
                                moveList.splice(originalIndex,0,currentId);
                            }
                        }
                        //把不可动的元素按之前的索引重新插入
                        lockList.forEach(function(item){
                            moveList.splice(item.index,0,item.id);
                        });
                        currentId = null;
                        sort.sort(moveList,true);
                        $scope.$apply();
                        sortArray = [];
                        var arr = sort.toArray();
                        if(arr.length < vm.sortingPage.length){
                            var _arr1 = [];
                            for(var i=0; i<vm.sortingPage.length; i++){
                                var exist = false;
                                for(var j=0; j<arr.length; j++){
                                    if(arr[j] == i){
                                        exist = true;
                                        break
                                    }
                                }
                                if(!exist){
                                    _arr1.push(i)
                                }
                            }
                            _arr1.forEach(function (arrItem, arrIndex) {
                                arr.splice(arrItem, 0, arrItem);
                            })
                        }
                        arr.forEach(function (item) {
                            sortArray.push(vm.sortingPage[item])
                        })
                        // vm.sortingPage = [];
                        // $timeout(function () {
                        //     vm.sortingPage = angular.copy(sortArray);
                        // })
                    }
                });
            })
        };
        $scope.restorePage7 = function () {
            vm.page7 = false;
            $timeout(function () {
                sort.destroy();
                $scope.sortingPage();
            })
        }
        $scope.savePage7 = function () {
            if(sortArray && sortArray.length>0){
                $scope.template.pages.page = [];
                $timeout(function () {
                    $scope.template.pages.page = angular.copy(sortArray);
                })
            }
            vm.page7 = false;
            vm.page3 = true;
            vm.sortingPage = [];
            sort.destroy();
        }


        // 上传图片后更新图片库/文件夹
        function updatePic() {
            if (vm.folderOne && vm.folderOne.id) {
                // $scope.showFolderContent(vm.folderOne);
                vm.photoAll = [];
                vm.galleryPage = [];
                loadAll(vm.folderOne.id);
            } else {
                vm.photoAll=[];
                vm.galleryPage=[];
                vm.indexNum = 0;
                vm.maxId = null;
                vm.photoCompleted = false;
                // vm.isShowGallery = true;
                loadAll('isNull');
            }
        }

        function loadAll(folderId) {
            var items = [];
            items.push({ key: "articleId", op: "=", value: vm.aid });
            if (folderId == 'isNull') {
                items.push({key: "folderId", op: "isNull", value: ''})
            } else {
                items.push({ key: "folderId", op: "=", value: folderId })
            }
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            Gallery.storeByCondition({
                items: items,
                page: vm.indexNum,
                size: vm.itemsPerPage,
                sort: sorts()
            }, onSuccess, onError);
        }

        function sorts() {
            return ["id,desc"];
        }

        function onSuccess(res) {
            if (vm.maxId == null && res.data.length > 0) {
                vm.maxId = res.data[0].id;
            }
            for (var i = 0; i < res.data.length; i++) {
                if (!res.data[i].cancelled) {
                    if(vm.photoAll){
                        vm.photoAll.push(res.data[i])
                    }
                    for(var j=0; j<vm.photoSelectAll.length; j++){
                        if(vm.photoSelectAll[j].id == res.data[i].id){
                            vm.photoSelectAll[j] = res.data[i];
                        }
                    }
                }
            }
            if (res.data.length < vm.itemsPerPage) {
                vm.photoCompleted = true;
            }
            getPagePhoto();
        }

        function onError(data) {
            if(data.status !== 403){
                MessageService.hint($translate.instant("editorCommon.failedToObtainThePhotoGallery"));
            }
        }
        function getPagePhoto() {
            var timeList=[];
            vm.photoArray = [];
            for(var i=0; i<vm.photoAll.length; i++){
                var photoCreatedDate = new Date(vm.photoAll[i].createdDate);
                if (checkExist(timeList, photoCreatedDate)) {
                    timeList.push(photoCreatedDate);
                }
            }
            //将相同日期下的照片整合
            for (var m = 0; m < timeList.length; m++) {
                var tempObj = {};
                var tempItems = [];
                for (var j = 0; j < vm.photoAll.length; j++) {
                    var createdDate = new Date(vm.photoAll[j].createdDate);
                    if (timeList[m].getFullYear() == createdDate.getFullYear() &&
                        timeList[m].getMonth() == createdDate.getMonth() &&
                        timeList[m].getDate() == createdDate.getDate()) {
                        tempItems.push(vm.photoAll[j])
                    }
                }
                var time = timeList[m].getFullYear() + '-' + (timeList[m].getMonth() + 1) + '-' + timeList[m].getDate();
                tempObj = {"createDate": time, "galleries": tempItems};
                vm.photoArray.push(tempObj);
            }
            getPagePhotoSelected();
            photoArrayAllSelect();
        }
        function getPagePhotoSelected() {
            for(var i=0; i<vm.photoArray.length; i++){
                vm.photoArray[i].selected = true;
                for(var j=0; j<vm.photoArray[i].galleries.length; j++){
                    vm.photoArray[i].galleries[j].selected = false;
                    for(var k=0; k<vm.photoSelectAll.length; k++){
                        if(vm.photoArray[i].galleries[j].id == vm.photoSelectAll[k].id){
                            vm.photoArray[i].galleries[j].selected = true;
                        }
                    }
                    if(!vm.photoArray[i].galleries[j].selected){
                        vm.photoArray[i].selected = false;
                    }
                }
            }
            photoSelectAllInit(true)
        }
        // 判断是否重复
        function checkExist(list, obj) {
            if (list == null || list.length == 0) {
                return true;
            }
            for (var i = 0; i < list.length; i++) {
                if (list[i].getFullYear() == obj.getFullYear() &&
                    list[i].getMonth() == obj.getMonth() &&
                    list[i].getDate() == obj.getDate()) {
                    return false;
                }
            }
            return true;
        }
        //全选当前图片
        $scope.photoAllCb = function (item) {
            item.selected = !item.selected ? true : false;
            for(var i=0; i<item.galleries.length; i++){
                item.galleries[i].selected = item.selected;
                photoSelect(item.galleries[i].selected, item.galleries[i])
            }
            photoArrayAllSelect()
        }
        //选中当前图片
        $scope.photoOneCb = function (item) {
            if(vm.fullMap && !item.selected && vm.photoSelectAll.length >= vm.fullMapNumber){
                threeInfo($translate.instant("hint.imagesMaxQuantity"))
                return
            }
            item.selected = !item.selected ? true : false;
            photoSelect(item.selected, item);
            photoArrayAllSelect()
        }
        function photoSelect(select, data) {
            if (vm.photoSelectAll == null) {
                var _index = [].findIndex(function(item) {
                    return item.id === data.id
                });
            }else{
                var _index = vm.photoSelectAll.findIndex(function(item) {
                    return item.id === data.id
                });
            }

            if(select){
                if(_index == -1){
                    vm.photoSelectAll.push(data)
                }
            }else {
                if(_index>-1){
                    vm.photoSelectAll.splice(_index,1);
                }
            }
        }
        function photoArrayAllSelect(){
            for(var i=0; i<vm.photoArray.length; i++){
                vm.photoArray[i].selected = true;
                for(var j=0; j<vm.photoArray[i].galleries.length; j++) {
                    if (!vm.photoArray[i].galleries[j].selected) {
                        vm.photoArray[i].selected = false;
                    }
                }
            }
        }
        //添加图片
        $scope.photoSave = function () {
            if(vm.isShowGallery1){
                vm.page2 = false;
                vm.page7 = true;
                vm.page1 = false;
                vm.isShowGallery1 = false;
            }
            if(vm.isShowGallery){
                vm.page1 = true;
                vm.page2 = false;
                vm.isShowGallery = false;
            }
            for(var i=0; i<vm.photoSelectAll.length; i++){
                vm.photoSelectAll[i].seq = i+1;
            }
        }
        $scope.deletePhotoSelect = function (e,index, item) {
            if(item.selectSeq){
                for(var i=0; i<$scope.pages.pages.page[item.page-1].levels.level.length; i++){
                    var _level = $scope.pages.pages.page[item.page-1].levels.level[i];
                    _level.imageboxSum = 1;
                    if(_level.imagebox){
                        if(_level.imageboxSum == item.number){
                            _level.imagebox.selectSeq = false;
                            break;
                        }
                        _level.imageboxSum++;
                    }
                }
            }
            vm.photoSelectAll.splice(index, 1);
            fn(e);
        }


        //获取dpi
        js_getDPI(function (dpi) {
            vm.windowDpi = dpi;
        });

        //一键成书
        $scope.book = function (onePage) {
            if(templateEdit){
                localStorage.removeItem("templateEdit");
                abook();
                return;
            }
            vm.onePage = onePage;
            var pages = {};
            if (vm.isEdit) {
                pages = angular.copy(vm.article.document);
            } else {
                pages = angular.copy($scope.pages);
            }
            if(pages.config.productType == 'BOOK' && !vm.isEdit && vm.product.aiFill && !vm.onePage){
                if(vm.photoSelectAll.length == 0 && vm.productXmlLine.minPicQuantity == 0){
                    localStorage.removeItem("templateEdit");
                    abook();
                    return;
                }
                if(vm.photoSelectAll.length < vm.productXmlLine.minPicQuantity){
                    SweetAlert.swal({
                        title: "请至少选择" + vm.productXmlLine.minPicQuantity + "张照片，再尝试一键成书！",
                        imageUrl: Theme.sweetHintImg(),
                        timer: 1500,
                        showConfirmButton: false
                    });
                    return;
                }
                smartLayout2($scope.pages.pages,$scope.pages.backupPages.list, Algorithm, vm.photoSelectAll, 'photoAlbum', function (pages,backupPages, photoSelectAll){
                    $scope.pages.pages.page = pages;
                    vm.photoSelectAll = photoSelectAll;
                    localStorage.removeItem("templateEdit");
                    abook();
                });
            }else{
                if(!vm.isEdit && vm.forceFill && vm.photoSelectAll.length < vm.counts && !vm.onePage){
                    SweetAlert.swal({
                        title: "请选择" + vm.counts + "张照片，再尝试一键成书！",
                        imageUrl: Theme.sweetHintImg(),
                        timer: 1500,
                        showConfirmButton: false
                    });
                    return;
                }
                localStorage.removeItem("templateEdit");
                abook();
            }
        };
        vm.pageData = null;
        vm.pageIndex = 0;
        $scope.template = null;
        vm.reservePage=[];
        vm.standbyPage = [];
        function abook() {
            vm.page1 = false;
            vm.page8 = true;
            var pages = [];
            if (vm.isEdit) {
                pages = angular.copy(vm.article.document);
            } else {
                pages = angular.copy($scope.pages);
            }
            pages.backupPages.list.forEach(function (item) {
                var _page1 = angular.copy(item);
                getPageType(_page1)
                _page1.numberOfHoles = 0;
                if(_page1.levels && _page1.levels.level && _page1.levels.level.length>0){
                    _page1.levels.level.forEach(function (level){
                        if(level.imagebox && level.imagebox.geometry){
                            _page1.numberOfHoles++;
                        }
                    });
                }
                vm.reservePage.push(_page1);
            })
            var _pages = {
                config:pages.config,
                pages:{
                    page:[]
                }
            }
            pages.pages.page.forEach(function (item) {
                if(!item.backup){
                    var _page1 = angular.copy(item);
                    _pages.pages.page.push(_page1);
                }
            });
            documentAnalysis($scope, $http, vm, _pages,FontManagement,'other', function(page){
                ratioInfo(page)
            }, function () {
                $timeout(function () {
                    $scope.template = angular.copy(pages);
                    $scope.template.config.startYear = pages.config.startYear;
                    $scope.template.config.startMonth = pages.config.startMonth;
                    $scope.template.pages.page=[];
                    _pages.pages.page.forEach(function (item) {
                        var _page = item;
                        _page.seq = $scope.template.pages.page.length;
                        $scope.template.pages.page.push(_page);
                    });
                    vm.page8 = false;
                    vm.page3 = true;
                    vm.pageSum = $scope.template.pages.page.length - 1;
                    vm.isGuidePage = true;
                    vm.guidePage = 1;
                    imageboxLow();
                    recommendInit($scope.template.pages.page);
                    vm.windowWidth2 = ($(window).width() - 75)/2;
                    if($scope.template.config.productType == 'CALENDAR'){
                        vm.windowWidth2 = ($(window).width() - 60);
                    }
                    var _width = $scope.template.pages.page[0].custom.width;
                    for(var i=0; i<$scope.template.pages.page.length; i++){
                        if($scope.template.pages.page[i].config.partid == 'P'){
                            if(!$scope.template.pages.page[i].spread){
                                _width = $scope.template.pages.page[i].custom.width;
                                break
                            }else{
                                _width = $scope.template.pages.page[i].custom.width;
                            }
                        }
                    }
                    vm.ratio = vm.windowWidth2 / _width;
                    $scope.template.config.parts.list.forEach(function (part) {
                        if(!part.slideable){
                            for(var i=0; i<$scope.template.pages.page.length; i++){
                                if($scope.template.pages.page[i].config.partid == part.id){
                                    if($scope.template.pages.page[i].custom.width*vm.ratio>($(window).width() - 59)){
                                        part.slideable = true;
                                        break
                                    }
                                }
                            }
                        }
                    })
                }, 2000)
            })
        }

        function recommendInit(pages) {
            pages.forEach(function (page) {
                page.numberOfHoles = 0;
                page.recommendLayout = [];
                page.levels.level.forEach(function (level){
                    if(level.imagebox && level.imagebox.geometry){
                        page.numberOfHoles++;
                    }
                });
            });
            vm.reservePage.forEach(function (page) {
                for(var i=0; i<pages.length; i++){
                    if(pages[i].numberOfHoles === page.numberOfHoles && pages[i].uuid != page.uuid && pages[i].spread == page.spread && page.config.partid == pages[i].config.partid){
                        pages[i].recommendLayout.push(page)
                    }
                }
            });
        }
        $scope.pageChange = function (item, index) {
            var _recommendLayout = [];
            item.recommendLayout.forEach(function (recommendLayoutItem) {
                if(recommendLayoutItem.uuid != item.uuid){
                    _recommendLayout.push(recommendLayoutItem)
                }
            })
            if(_recommendLayout.length > 0){
                var x = _recommendLayout.length-1;
                var y = 0;
                var rand = parseInt(Math.random() * (x - y + 1) + y);
                vm.pageData = $scope.template.pages.page;
                vm.pageIndex = index;
                $scope.layoutCb(_recommendLayout[rand], function () {
                    $scope.template.pages.page = vm.pageData;
                })
            }
        }

        function imageboxLow() {
            $scope.template.pages.page.forEach(function (page) {
                if(page && page.levels && page.levels.level){
                    page.levels.level.forEach(function (level) {
                        if(level.imagebox&&level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                            pxInsufficient(level.imagebox, vm.imgSize, vm.imgDpi)
                        }
                    })
                }
            })
        }

        vm.ratioInfo = ratioInfo;
        function ratioInfo(item) {
            if(item.config.partid != 'P' || item.spread){
                vm.windowWidth1 = ($(window).width() - 60);
            }else{
                vm.windowWidth1 = ($(window).width() - 75)/2;
            }
            vm.windowWidth = $(window).width() * 0.8;
            vm.windowHeight = $(window).height() * 0.91 - 205;
            if(vm.islayout ||vm.mapDepot || vm.isMaterial || (vm.istemplate && vm.page4)){
                vm.windowHeight = document.body.clientHeight - 265 - (document.body.clientHeight*0.02);
                if(vm.isIPhoneX){
                    vm.windowHeight = vm.windowHeight - 30;
                }
                if(vm.mapDepot){
                    vm.windowHeight = vm.windowHeight - 20;
                }
            }
            var ratio = item.custom.width / item.custom.height;
            var ratio1 = vm.windowWidth / vm.windowHeight;
            item.custom.ratio = vm.windowWidth1 / item.custom.width;
            if (ratio >= ratio1) {
                item.custom.ratio1 = vm.windowWidth / item.custom.width;
            } else {
                item.custom.ratio1 = vm.windowHeight / item.custom.height;
            }
            item.custom.thumbnailScale = 300 / item.custom.width;
        }

        $scope.editPageCb = function (index) {
            vm.isDocumentTextDetection = false;
            vm.isGuidePage = true;
            vm.guidePage = 4;
            vm.page1 = false;
            vm.page2 = false;
            vm.page3 = false;
            vm.page4 = true;
            vm.pageData = angular.copy($scope.template.pages.page);
            vm.pageIndex = index;
            layoutInfo();
            $scope.makeBody();
            photoSelectAllInit();
        }

        $scope.previousPage = function () {
            vm.pageIndex--;
            ratioInfo(vm.pageData[vm.pageIndex]);
        }
        $scope.nextPage = function () {
            vm.pageIndex++;
            ratioInfo(vm.pageData[vm.pageIndex]);
        }

        /*******************************************布局*************************************************************************/
        vm.islayout = false;
        $scope.layout = function(){
            vm.islayout = true;
            ratioInfo(vm.pageData[vm.pageIndex]);
            $scope.layoutFn(true);
        }
        $scope.layoutHide = function(){
            vm.islayout = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.layoutFn = function(item){
            if(item){
                var numberOfHoles = 0;
                vm.recommendLayout = [];
                vm.pageData[vm.pageIndex].levels.level.forEach(function (level){
                    if(level.imagebox && level.imagebox.geometry){
                        numberOfHoles++;
                    }
                });
                vm.reservePage.forEach(function (page) {
                    if(page.numberOfHoles === numberOfHoles && vm.pageData[vm.pageIndex].spread == page.spread && page.config.partid == vm.pageData[vm.pageIndex].config.partid){
                        vm.recommendLayout.push(page);
                    }
                });
                vm.islayout1 = true;
                vm.islayout2 = false;
            }else{
                vm.islayout1 = false;
                vm.islayout2 = true;
            }
        }
        $scope.layoutCb = function(item, callback){
            if(item.uuid != vm.pageData[vm.pageIndex].uuid){
                vm.threeLoading = true;
                vm.threeLoadingText = $translate.instant("editorCommon.loadingText2");
                $scope.layoutHide();
                item.config = vm.pageData[vm.pageIndex].config;
                var pages = {
                    config:$scope.template.config,
                    pages:{
                        page: [angular.copy(item)]
                    }
                }
                documentAnalysis($scope, $http, vm, pages,FontManagement,'other', function(page){
                    ratioInfo(page)
                }, function () {
                    $timeout(function () {
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                        var layoutPage = pages.pages.page[0];
                        layoutPage.backup = false;
                        layoutPage.unreplace = false;
                        pageDataInfo(layoutPage, function () {
                            imageboxLow();
                            recommendInit(vm.pageData)
                            if(callback){callback()}
                        })
                    }, 200);
                })
            }
        }
        function pageDataInfo(page, callback) {
            vm.threeLoading = true;
            vm.threeLoadingText = $translate.instant("editorCommon.loadingText2");
            var _pageData = angular.copy(vm.pageData[vm.pageIndex]);
            var _pageDataImageboxImage = [];
            var _pageDataImageboxImageSum = 0;
            for(var i=0; i<_pageData.levels.level.length; i++){
                var _lecel = _pageData.levels.level[i];
                if(_lecel.imagebox && _lecel.imagebox.image && _lecel.imagebox.image.resource && _lecel.imagebox.image.resource.identifier){
                    _lecel.imagebox.imgRatio = _lecel.imagebox.image.width/_lecel.imagebox.image.height;
                    _pageDataImageboxImage.push(_lecel.imagebox)
                }
            }
            if(!page.levels.level){
                page.levels.level=[];
            }
            _pageDataImageboxImage = imageboxAdapt(page.levels.level, _pageDataImageboxImage);
            angular.forEach(page.levels.level, function (level, i) {
                if (level.imagebox) {
                    var _imagebox = null;
                    for(var j=0; j<_pageDataImageboxImage.length; j++){
                        if(_pageDataImageboxImage[j].imageboxIndex == _pageDataImageboxImageSum){
                            _imagebox = _pageDataImageboxImage[j];
                            break
                        }
                    }
                    _pageDataImageboxImageSum++;
                    if (_imagebox) {
                        level.imagebox.bokehtype = level.imagebox.bokehtype || vm.article.bokehtype || 'None';
                        level.imagebox.image = {
                            blurredrectangle: {
                                width: 0,
                                height: 0,
                                x: 0,
                                y: 0
                            },
                            resource: {
                                identifier: _imagebox ? _imagebox.image.resource.identifier : '',
                                provider: _imagebox ? _imagebox.image.resource.provider : 'qiniu'
                            },
                            resourceid:_imagebox ? _imagebox.image.resourceid : '',
                            filterResource: {
                                identifier: '',
                                provider: 'qiniu'
                            },
                            angle: 0,
                            offsetx: 0,
                            offsety: 0,
                            imageInfo: _imagebox? _imagebox.image.imageInfo : '',
                            width: _imagebox ? _imagebox.image.width : '',
                            height: _imagebox ? _imagebox.image.height : '',
                            imgSize: _imagebox ? _imagebox.image.imgSize : '',
                            index: _imagebox ? _imagebox.image.seq : '',
                            parameter: _imagebox ? angular.fromJson(_imagebox.image.parameter) : [],
                            scale: 1
                        }
                        if (level.imagebox.image.resource.identifier) {

                            addImagebox($http, level.imagebox, function () {})
                            // getBackgroundSource3(level.imagebox, level.imagebox.geometry.width, level.imagebox.geometry.height, level.imagebox.image, level.imagebox.bokehtype, $scope)
                        }
                    } else {
                        level.imagebox.image = {
                            resource: {
                                identifier: '',
                                provider: ''
                            },
                            resourceid:"",
                            filterResource: {
                                identifier: '',
                                provider: ''
                            },
                            width: '',
                            height: '',
                            imgSize: '',
                            offsetx: 0,
                            offsety: 0,
                            index: '',
                            parameter: [],
                            scale: 1,
                            angle: 0,
                        }
                    }
                }
            })
            $timeout(function () {
                vm.pageData[vm.pageIndex] = page;
                vm.threeLoading = false;
                vm.threeLoadingText = '';
                threeInfo1($translate.instant("editorCommon.changePageSuccess"));
                layoutInfo();
                if(callback){callback()}
            },200)
        }
        function layoutInfo(){
            vm.reservePage.forEach(function (item) {
                item.layoutQuantity=0;
            })
            vm.pageData.forEach(function (template) {
                vm.reservePage.forEach(function (reservePage) {
                    if(reservePage.uuid == template.uuid){
                        reservePage.layoutQuantity++
                    }
                })
            })
        }
        /*******************************************点击模板*************************************************************************/
        $scope.templateShow = function () {
            vm.istemplate = true;
            productInit()
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.templateHide = function () {
            vm.istemplate = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.tempInfo = tempInfo;

        function productInit() {
            vm.modelTab=[{name:$translate.instant("editorCommon.all"), id:''}];
            ItemValue.get({id: vm.article.itemValueId}, function (res) {
                vm.tempList = res.data.productXmlLines;
                vm.tempList.forEach(function (item) {
                    if (item.themeClassifies && !item.cancelled && item.paid) {
                        item.themeClassifies.forEach(function (themeClassifie) {
                            var _not = true;
                            for(var i=0; i<vm.modelTab.length; i++){
                                if(themeClassifie.id == vm.modelTab[i].id){
                                    _not = false;
                                    break
                                }
                            }
                            if(_not){
                                vm.modelTab.push(themeClassifie)
                            }
                        })
                    }
                })
                tempInfo(vm.modelTab[0])
            })
        }
        function tempInfo(item){
            vm.temps=[];
            vm.productXmlThemeId=item.id;
            vm.tempList.forEach(function (data) {
                if(!vm.productXmlThemeId){
                    vm.temps.push(data)
                }else{
                    var _not = false;
                    data.themeClassifies.forEach(function (themeClassifie) {
                        if(themeClassifie.id == vm.productXmlThemeId){
                            _not = true;
                        }
                    })
                    if(_not){
                        vm.temps.push(data)
                    }
                }
            })
            vm.temps.sort(sortNavTitle("seq"));
        }
        function sortNavTitle(propertyName) {
            return function(object1, object2) {
                var value1 = object1[propertyName];
                var value2 = object2[propertyName];
                return value1 - value2;
            }
        }


        vm.imgs = [];
        var templateSwiper = null;
        $scope.templateCb = function (temp) {
            if (vm.article.productXmlId == temp.productXmlId) {
                vm.threeIsModal1 = true;
                vm.threeModalText1 = $translate.instant("editorCommon.alreadyExistTemplate");
                return
            }
            vm.xmlShowPic = true;
            $scope.chooseTemp = temp;
            ProductXml.get({id: temp.productXmlId}, function (res) {
                if (res.data.documentPics && res.data.documentPics.length > 0) {
                    res.data.documentPics.sort(function (a, b) {
                        return a.seq - b.seq;
                    });
                }
                vm.imgs = res.data.documentPics;
                if(vm.imgs.length > 0){
                    $timeout(function () {
                        templateSwiperInit();
                        templateSwiper.init();//现在才初始化
                    },200)
                }
            }, function () {

            })
        }
        function templateSwiperInit() {
            templateSwiper = new Swiper ('.swiper-template-view-content', {
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            })
        }

        var inits = 0, num = 0;
        $scope.previewClose = function () {
            vm.xmlShowPic = false;
        };
        $scope.changeAllTemp = function () {
            vm.threeIsModal = true;
            vm.threeModalText = $translate.instant("editorCommon.whetherReplaceTemplate");
        }

        function templateReplace() {
            if(vm.isEdit){
                vm.threeLoading = true;
                vm.pageData.forEach(function (page) {
                    if(page.levels.level){
                        page.levels.level.forEach(function (level) {
                            if (level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                                vm.photoSelectAll.push({
                                    height: level.imagebox.image.resource.height,
                                    id: level.imagebox.image.resourceid,
                                    imageInfo: level.imagebox.image.imageInfo,
                                    identifier: level.imagebox.image.resource.identifier.indexOf('http') > -1 ? level.imagebox.image.resource.identifier.substring(level.imagebox.image.resource.identifier.lastIndexOf('/')+1) : level.imagebox.image.resource.identifier,
                                    imgsize: level.imagebox.image.imgsize,
                                    name: null,
                                    provider: "qiniu",
                                    usenum: 1,
                                    width: level.imagebox.image.resource.width
                                });
                            }
                        })
                    }
                })
                Convert.xmlToDocument2({
                    identifier: $scope.chooseTemp.productXmlIdentifier,
                    provider: "qiniu",
                    editorType:vm.article.editorType,
                    patternType:vm.product.patternType,
                }, function (res) {
                    vm.article.document = res;
                    vm.replace = res.replace;

                    angular.forEach(vm.article.document.pages.page, function (page, i) {
                        if(!page.backup){
                            angular.forEach(page.levels.level, function (level) {
                                if (level.imagebox && level.imagebox.geometry) {
                                    vm.counts++;
                                }
                            })
                        }
                    });

                    vm.productXmlLine = angular.copy($scope.chooseTemp);
                    vm.modelBlendentUuid=vm.productXmlLine.modelBlendentUuid;
                    vm.article.modelBlendentUuid= vm.productXmlLine.modelBlendentUuid;
                    vm.article.cover= vm.productXmlLine.coverImg;
                    vm.article.themeClassifyId= vm.productXmlLine.productXmlThemeId;
                    vm.article.themeClassifyName= vm.productXmlLine.productXmlThemeName;
                    vm.article.productXmlId= vm.productXmlLine.productXmlId;
                    vm.article.productXmlName= vm.productXmlLine.productXmlName;
                    vm.article.productXmlLineId= vm.productXmlLine.id;
                    if(vm.productXmlLine && vm.productXmlLine.musicIdentify){
                        vm.article.musicIdentify= vm.productXmlLine.musicIdentify;
                    }
                    $scope.savePage4Return();
                    vm.editProductXml = true;
                    vm.reservePage=[];
                    abook();
                    $scope.previewClose();
                    vm.threeLoading = false;
                });
            }else{
                localStorage.setItem("templateEdit", 'true');
                // window.location.href = vm.platformSetting.editorNotifyUrl+'/#/'+vm.storeId+'/'+vm.pid+'/'+vm.vid+'/'+$scope.chooseTemp.id+'/'+vm.aid+'/editorPhotoAlbum';
                $state.go('editorPhotoAlbumM', {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: $scope.chooseTemp.id, aid: vm.aid});
                // window.location.reload();
                localStorage.setItem("photoSelectAll", JSON.stringify(vm.photoSelectAll));
            }
        }

        /*******************************************点击图片库*************************************************************************/
        vm.mapDepot = false;
        $scope.photoGalleryShow = function () {
            vm.mapDepot = true;
            vm.mapDepot1 = true;
            vm.mapDepot2 = false;
            updatePic()
            ratioInfo(vm.pageData[vm.pageIndex])
            photoSelectAllInit(true)
        }
        $scope.photoGalleryHide = function () {
            vm.mapDepot = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $('#photoGallery').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.photoCompleted){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.indexNum++
                    loadAll("isNull")
                }
            }
        })


        /*******************************************素材处理*************************************************************************/
        $scope.makeBody = vm.makeBody = function (event) {
            $timeout(function () {
                vm.levelIndex = null;
                vm.levelType = null;
                vm.isImageboxSelectItem = false;
                vm.textStyleShow = false;
                vm.textboxSelect = false;
                vm.filterShow = false;
                if(vm.mapDepot || vm.isMaterial || vm.istemplate || vm.islayout){
                    vm.istemplate = false;
                    vm.isMaterial = false;
                    vm.mapDepot = false;
                    vm.islayout = false;
                    ratioInfo(vm.pageData[vm.pageIndex]);
                }else {
                    vm.istemplate = false;
                    vm.isMaterial = false;
                    vm.mapDepot = false;
                    vm.islayout = false;
                }
            })
            fn(event)
        }
        vm.levelDome = function(item){
            vm.isImageboxSelectItem = false;
            vm.filterShow = false;
            vm.textStyleShow = false;
            vm.istemplate = false;
            vm.isMaterial = false;
            vm.textboxSelect = false;
            vm.mapDepot = false;
            vm.isAddImagebox = false;
            vm.islayout = false;
            if(item){
                vm[item] = true;
            }
        }

        vm.isMaterial = false;
        $scope.materialShow = function () {
            vm.isMaterial = true;
            vm.materialPage = 0;
            vm.materialSum = 20;
            vm.materialData = [];
            //分类
            ThemeClassify.getAllByCondition4({type: 'CLIPART',storeId:vm.storeId}, function (res) {
                vm.classify = [];
                if($scope.template.cliparts && $scope.template.cliparts.clipart && $scope.template.cliparts.clipart.length>0){
                    vm.classify = [{
                        id: "",
                        name: $translate.instant("editorCommon.recommend"),
                        seq: 0,
                        type: "CLIPART",
                        used: true
                    }]
                }
                res.data.forEach(function (item) {
                    vm.classify.push(item)
                })
                vm.classifyW = res.data.length * 86;
                initMaterial(vm.classify[0].id)
            });
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.classifyCb = function (item) {
            vm.materialPage = 0;
            vm.materialData = [];
            initMaterial(item.id)
        }
        //获取素材
        vm.isMaterial1 = false;

        function initMaterial(id) {
            vm.classifyId = id;
            if(id){
                ClipArt.byCondition3({
                    items: [
                        {key: "themeClassify.id", op: "=", value: id}],
                    page: vm.materialPage,
                    size: vm.materialSum,
                    sort: ["seq,asc"]
                }, function (res) {
                    angular.forEach(res.data, function (resp) {
                        var arr = {
                            name: resp.name,
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                            themeClassifyName: resp.themeClassifyName,
                            themeClassifyId: resp.themeClassifyId,
                            resource: {
                                identifier: $scope.domain + resp.identifier,
                                provider: resp.provider
                            }
                        }
                        vm.materialData.push(arr);
                    })
                    vm.materialW = vm.materialData.length * 90 + 20;
                    if (res.data.length < vm.materialSum) {
                        vm.isMaterial1 = true;
                    }else {
                        vm.isMaterial1 = false;
                    }
                });
            }else{
                $scope.template.cliparts.clipart.forEach(function (clipart) {
                    var arr = {
                        name: "",
                        identifier: clipart.resource.identifier,
                        provider: "",
                        themeClassifyName: $translate.instant("editorCommon.recommend"),
                        themeClassifyId: "",
                        resource: clipart.resource
                    }
                    vm.materialData.push(arr);
                })
                vm.materialW = vm.materialData.length * 90 + 20;
                vm.isMaterial1 = true;
            }
        }
        $('#materialView').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.isMaterial1){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.materialPage++;
                    initMaterial(vm.classifyId)
                }
            }
        })
        $scope.materialHide = function () {
            vm.isMaterial = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }

        //添加素材
        vm.repeatAddNumber = 0;
        $scope.addImageart = function (item){
            vm.threeLoading = true;
            vm.threeLoadingText=$translate.instant("hint.loadingText");
            imageartAdd($http, item, vm.pageData[vm.pageIndex].trimbox, function(imageart){
                if(vm.repeatAddNumber>4){
                    vm.repeatAddNumber = 0;
                }
                var _num = 10*vm.repeatAddNumber;
                imageart.geometry.x += _num/vm.pageData[vm.pageIndex].custom.ratio1;
                imageart.geometry.y += _num/vm.pageData[vm.pageIndex].custom.ratio1;
                var _level = {
                    seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                    imageart: imageart
                }
                vm.pageData[vm.pageIndex].levels.level.push(_level);
                vm.threeLoading = false;
                vm.repeatAddNumber++;
            }, vm.pageData[vm.pageIndex].custom.ratio1)
        }
        $scope.savePage4 = function(){
            // vm.pageData = angular.copy($scope.template.pages.page);
            $scope.template.pages.page = vm.pageData;
            vm.page4 = false;
            vm.page3 = true;
        }
        $scope.savePage4Return = function(){
            vm.page4 = false;
            vm.page3 = true;
        }
        /*******************************************文字处理*************************************************************************/
        $scope.colorList = ['#ff001e', '#ff5c00', '#ff9700', '#61734d', '#838c97', '#8d4ac8', '#b8e986', '#7e6565', '#ffffff', '#f1e7d9', '#b5ce99', '#82c281', '#dac2ef', '#000', '#797979', '#7c330a', '#eec78d', '#444444', '#589ceb', '#4a7bc8', '#ddabab'];
        $scope.selectedColor = $scope.colorList[0];

        vm.textboxSelect = false;
        vm.textStyleTab = [
            {name: $translate.instant("font.textStyleTabFont"), id: 'textStyleTabFont'},
            {name: $translate.instant("font.textStyleTabSize"), id: 'textStyleTabSize'},
            {name: $translate.instant("font.textStyleTabType"), id: 'textStyleTabType'},
            {name: $translate.instant("font.textStyleTabColor"), id: 'textStyleTabColor'},
        ];
        vm.textStyleItem = 'textStyleTabFont';
        //添加文字
        $scope.fontShow = function () {
            vm.page5 = true;
            vm.fontTab = 'fontColor';
            $scope.selectedColor = $scope.colorList[0];

            // var _level ={
            //     seq: vm.pageData[vm.pageIndex].levels.level.length+1,
            //     textbox: textbox
            // }
            vm.fontData = {
                seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                textbox: {
                    type: 'add',
                    geometry: {
                        x: '0',
                        y: '0',
                        width: 100,
                        height: 20
                    },
                    rotation: {
                        angle: '0'
                    },
                    text: '',
                    style: {
                        font: "",
                        size: 20,
                        color: $scope.selectedColor,
                        bold: false,
                        italic: false,
                        lineheight: 20,
                        letterspacing: 0,
                        align: 'left',
                        writingmode:'horizontal-tb'
                    },
                    fontuuid: vm.fontItem.uuid,
                    identifier: "",
                    editable: true,
                    movable: true,
                    lock: false,
                    version: '2.0',
                    transparency: 1,
                    useradded: true
                }
            }
            var _fontStyle = [];
            _fontStyle.push(vm.fontItem.fontStyles[j].style)
            if (_fontStyle.indexOf('NORMAL') != '-1') {
                vm.fontData.textbox.style.bold = false;
                vm.fontData.textbox.style.italic = false;
            } else {
                if (_fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    vm.fontData.textbox.style.bold = true;
                    vm.fontData.textbox.style.italic = true;
                } else {
                    if (_fontStyle.indexOf('ITALIC') != '-1') {
                        vm.fontData.textbox.style.italic = true;
                        vm.fontData.textbox.style.bold = false;
                    }
                    if (_fontStyle.indexOf('BOLD') != '-1') {
                        vm.fontData.textbox.style.bold = true;
                        vm.fontData.textbox.style.italic = false;
                    }
                }
            }
            vm.text = '';
            $timeout(function () {
                $('textarea.three-font-body-input').focus()
            },100);
        };
        //切换字体
        $scope.updateTextFont = function (data) {
            if (data == undefined) {
                threeInfo($translate.instant("font.failedAddText"));
                return
            }
            if (!data.enable) {
                threeInfo($translate.instant("font.fontChange"));
                return
            }
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            vm.fontStyle = [];
            for (var i = 0; i < vm.fontList.length; i++) {
                if (vm.fontList[i].uuid == data.uuid) {
                    for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                        vm.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold = false;
            text.style.italic = false;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.bold = false;
                text.style.italic = false;
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    text.style.bold = true;
                    text.style.italic = true;
                } else {
                    if (vm.fontStyle.indexOf('ITALIC') != '-1') {
                        text.style.italic = true;
                    }
                    if (vm.fontStyle.indexOf('BOLD') != '-1') {
                        text.style.bold = true;
                    }
                }
            }
            text.style.font = data.family;
            text.fontuuid = data.uuid;
            text.identifier = $scope.domain + data.fontStyles[0].identifier;
            text.loading = true;
            getFontCSS(FontManagement, text, $scope);
            $timeout(function () {
                sizeHeight();
                fontStyle(data.uuid);
            })
        };

        function fontStyle(fontuuid) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            vm.fontStyle = [];
            for (var i = 0; i < vm.fontList.length; i++) {
                if (vm.fontList[i].uuid == fontuuid) {
                    for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                        vm.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold1 = false;
            text.style.italic1 = false;
            if (vm.fontStyle.indexOf('NORMAL') == '-1') {
                text.style.normal = false;
            } else {
                text.style.normal = true;
            }
            if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                text.style.bold1 = true;
                text.style.italic1 = true;
            } else {
                if (vm.fontStyle.indexOf('ITALIC') != '-1') {
                    text.style.italic1 = true;
                }
                if (vm.fontStyle.indexOf('BOLD') != '-1') {
                    text.style.bold1 = true;
                }
            }
        }

        window.densityInput = function (e) {
            var val = $('#rangeChange').val();
            if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.size = parseInt(val);
                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.lineheight = parseInt(val);
            }

            var currentPage = vm.pageData[vm.pageIndex].levels.level;
            var item = currentPage[vm.levelIndex].textbox;

            if (item.geometry.width < parseInt(item.style.size)) {
                item.geometry.width = (Number(parseInt(item.style.size)) + Number(5));
            }
            if (item.geometry.width > vm.pageData[vm.pageIndex].editbox.width) {
                item.geometry.width = vm.pageData[vm.pageIndex].editbox.width;
                // item.geometry.x = 0;
            }
            sizeHeight();
            $scope.$digest();
        }
        window.textStyleTransparency = function (e) {
            var val = $('#textStyleTransparency').val();
            if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.transparency = parseInt(val) / 100;
                vm.transparency = parseInt(val);
            }
        }
        //斜体
        $scope.operationItalic = function () {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.italic = !text.style.italic;
                $timeout(function () {
                    sizeHeight();
                }, 200)
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.fontStyle.indexOf('BOLD') != '-1') {
                    if (text.style.bold) {
                        text.style.italic = !text.style.italic;
                        $timeout(function () {
                            sizeHeight();
                        }, 200)
                    }
                }
            }
        }
        //粗体
        $scope.operationBold = function () {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.bold = !text.style.bold;
                $timeout(function () {
                    sizeHeight();
                }, 200)
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.fontStyle.indexOf('ITALIC') != '-1') {
                    if (text.style.italic) {
                        text.style.bold = !text.style.bold;
                        $timeout(function () {
                            sizeHeight();
                        }, 200)
                    }
                }
            }
        }
        $scope.operationBarDir = function (item) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            text.style.align = item;
        };
        $scope.fontColorCb = function (item) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            text.style.color = item;
        }
        //获取所有字体
        vm.fontList = [];
        vm.fontItem = null;
        getAllFont();

        function getAllFont() {
            vm.fontList = [];
            vm.fontItem = null;
            FontManagement.getAll({}, function (res) {
                vm.fontList = res;
                if(vm.fontList && vm.fontList.length>0){
                    vm.fontItem = vm.fontList[0];
                    vm.fontList.forEach(function (item){
                        if(item.defaultShow){
                            vm.fontItem = item;
                        }
                    })
                }
            });
        }

        //判断是ios or Android
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

        var textFlag = true;
        var inputEle = document.getElementById('three-font-body-input');

        // 兼容安卓文字输入（首行空格不显示bug）
        $scope.changeText = function (e) {
            if (isAndroid == true && isiOS == false) {
                fn(e);
                var targ = e.currentTarget ? e.currentTarget : e.srcElement;
                vm.text = vm.fontData.textbox.text = targ.value;
            }
        }

        /**
         * 兼容iOS文字输入
         */
        inputEle.addEventListener('compositionstart', function () {
            textFlag = false;
        });
        inputEle.addEventListener('compositionend', function (event) {
            textFlag = true;
            // compositionend 事件发生在 input 之后，故此需要此处调用 input 中逻辑
            if (isAndroid == false && isiOS == true) {
                vm.text = event.target.value;
            }
        });
        inputEle.addEventListener('input', function (event) {
            // 忽略一切非直接输入，不做逻辑处理
            if (textFlag) {
                if (isAndroid == false && isiOS == true) {
                    vm.text = event.target.value;
                }
            };
        });

        $scope.savePage5 = function () {
            if (!vm.fontData.textbox.text) {
                vm.page4 = true;
                vm.page5 = false;
                return
            }
            if(vm.text){
                vm.fontData.textbox.text =  vm.text;
            }
            FontSupport2(FontManagement, vm.fontData.textbox, function (res) {
                var msg = res.data;
                if (res.status == 200) {
                    if (!msg.success) {
                        swal({
                            title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>" + $translate.instant("font.warmPrompt") + "</div>",
                            text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>" + $translate.instant("font.pleaseChangeFonts") + "</div>",
                            html: true,
                            confirmButtonText: $translate.instant("button.returnCorrection")
                        });
                    }else if (msg.success && !msg.supported) {
                        swal({
                            title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>" + $translate.instant("font.warmPrompt") + "</div>",
                            text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>" + $translate.instant("font.notSpecialCharacter") + "<br><span style='color:#FFA91B;font-size: 14px'>" + msg.failed + "</span></div>",
                            html: true,
                            confirmButtonText: $translate.instant("button.returnCorrection")
                        });
                    } else {
                        vm.page4 = true;
                        vm.page5 = false;
                        vm.fontData.textbox.style.color = $scope.selectedColor;
                        // vm.fontData.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                        // vm.fontData.textbox.geometry.height = (Number($("#c-xml-page-material-fontId").height()) + Number(10));
                        // vm.fontData.textbox.geometry.width = Number(($("#c-xml-page-material-fontId").width() * ( vm.windowDpi / 72) + Number(10)) / vm.pageData[vm.pageIndex].custom.ratio1 * (1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1 > 1 ? 1 : 1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1));
                        // vm.fontData.textbox.geometry.height = Number(($("#c-xml-page-material-fontId").height() * ( vm.windowDpi / 72) + Number(5)) / vm.pageData[vm.pageIndex].custom.ratio1) * (1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1 > 1 ? 1 : 1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1);
                        if (vm.fontData.textbox.type == 'add') {
                            vm.fontData.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                            vm.fontData.textbox.geometry.x = (vm.pageData[vm.pageIndex].editbox.width - vm.fontData.textbox.geometry.width) / 2 + vm.pageData[vm.pageIndex].editbox.x;
                            vm.fontData.textbox.geometry.y = (vm.pageData[vm.pageIndex].editbox.height - vm.fontData.textbox.geometry.height) / 2 + vm.pageData[vm.pageIndex].editbox.y;
                            vm.pageData[vm.pageIndex].levels.level.push(vm.fontData)
                            vm.levelIndex = vm.pageData[vm.pageIndex].levels.level.length-1;
                        }
                        if (vm.fontData.textbox.type == 'edit') {
                            // if (vm.fontData.textbox.geometry.width >= vm.pageData[vm.pageIndex].editbox.width) {
                            //     // vm.fontData.textbox.geometry.width = vm.pageData[vm.pageIndex].editbox.width;
                            //     vm.fontData.textbox.geometry.x = vm.pageData[vm.pageIndex].editbox.x;
                            // }
                            // if (vm.fontData.textbox.geometry.height >= vm.pageData[vm.pageIndex].editbox.height) {
                            //     // vm.fontData.textbox.geometry.height = vm.pageData[vm.pageIndex].editbox.height
                            //     vm.fontData.textbox.geometry.y = vm.pageData[vm.pageIndex].editbox.y;
                            // }
                            vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] = vm.fontData;
                        }
                        sizeHeight1()
                    }
                }
            })
        };
        //删除文字
        $scope.deleteTextbox = function (e) {
            fn(e)
            if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                vm.pageData[vm.pageIndex].levels.level.splice(vm.levelIndex, 1);
                vm.levelIndex = null;
                vm.levelType = null;
                $scope.makeBody();
            }
        }
        $scope.editTextbox = function (e, index, item) {
            fn(e)
            vm.page5 = true;
            vm.fontData = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex];
            vm.fontData.textbox.type = 'edit';
            $scope.selectedColor = vm.fontData.textbox.style.color;
            vm.fontTab = 'fontColor';
            vm.text = vm.fontData.textbox.text;
            $timeout(function () {
                $('textarea.three-font-body-input').focus()
            },100);
        }
        //文字样式
        $scope.textStyleTextbox = function () {
            vm.textStyleShow = true;
        }
        function sizeHeight() {
            $timeout(function () {
                var _h = Number($('.pitch1').find('.c-xml-page-textboxe-text span').height()) + 5;
                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h/vm.pageData[vm.pageIndex].custom.ratio1;
                console.log(_h)
                $scope.$digest();
            })
        }
        vm.sizeHeight1 = sizeHeight1;
        function sizeHeight1() {
            $timeout(function () {
                var _h = Number($("#c-xml-page-material-fontId1 span").height()) + Number(10);
                var _w = Number($("#c-xml-page-material-fontId1 span").width()) + Number(10);
                var _sizeWidth = getTextWidthInternal(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.text, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.bold, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.italic, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.size, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.fontuuid);
                if(!writingmodeInit(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h;
                    if(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width < vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.size){
                        vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.size;
                    }
                    if(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width < _sizeWidth){
                        item.geometry.width = (Number(_sizeWidth) + Number(5));
                        return;
                    }
                }
                if(writingmodeInit(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width = _w;
                    if(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height < _sizeWidth){
                        vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _sizeWidth;
                    }
                }
                $scope.$digest();
            })
        }
        //字体样式
        $scope.textStyleTabCb = function (item) {
            vm.textStyleItem = item.id;
        }
        $scope.textStyleHide = function () {
            vm.textStyleShow = false;
        }
        /*******************************************照片框处理*************************************************************************/
        //展开
        vm.isModeOut = false;
        $scope.modeIn = function () {
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if (_imagebox.bokehtype != 'None') {
                if (_imagebox.image && _imagebox.image.resource.identifier) {
                    imageboxModeIn(_imagebox, function (imagebox) {
                        vm.isModeOut = true;
                    })
                }
            }
        };
        //缩放
        $scope.modeOut = function () {
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if (_imagebox.bokehtype == 'None') {
                if (_imagebox.image && _imagebox.image.resource.identifier) {
                    imageboxModeOut(_imagebox, function (imagebox) {
                        vm.isModeOut = false;
                        getBackgroundSource3(_imagebox, _imagebox.geometry.width, _imagebox.geometry.height, _imagebox.image, _imagebox.bokehtypes, $scope);
                    })
                }
            }
        };

        $scope.cropImageboxMassive = function () {
            imageboxMassive(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox);
        }
        $scope.cropImageboxMin = function () {
            imageboxMin(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox);
        }
        $scope.cropImageboxRotate = function (flag) {
            imageboxRotate($scope, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, flag);
        }
        $scope.cropImagRestore = function () {
            imagRestore($scope, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, $http);
        }
        $scope.overturnImagebox = function () {
            imageboxOverturn(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox);
        };

        vm.filterShow = false;
        vm.filterType='';
        vm.filterList=[
            {img:'content/images/filter1.png',type:''},
            {img:'content/images/filter2.png',type:'selfie_anime'},
            {img:'content/images/filter3.png',type:'wave'},
            {img:'content/images/filter4.png',type:'warm'},
            {img:'content/images/filter5.png',type:'scream'},
            {img:'content/images/filter6.png',type:'gothic'},
            {img:'content/images/filter7.png',type:'lavender'},
        ];
        $scope.filterCb = function(item){
            if(!vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox || !vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image || !vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier){
                return;
            }
            vm.threeLoading = true;
            vm.threeLoadingText = $translate.instant("hint.loadingText");
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if(_imagebox.image.resourceid){
                Gallery.getOne({id:_imagebox.image.resourceid},function (res) {
                    // if(res.imgSize / 1024 > 1024*4){
                    //     threeInfo("图片不能大于4M")
                    //     vm.threeLoading = false;
                    //     vm.threeLoadingText = '';
                    //     return
                    // }
                    var _identifier = $scope.domain + res.identifier;
                    if(!item.type){
                        vm.filterType=item.type;
                        vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = _identifier;
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                    }else {
                        Convert.imageEnhance({
                            imageUrl: _identifier,
                            type: item.type
                        }, function (resp) {
                            if(resp.status == 200){
                                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = $scope.domain + resp.message;
                                vm.filterType=item.type;
                            }else{
                                threeInfo(resp.message)
                            }
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                        }, function () {
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                            threeInfo($translate.instant("editorCommon.loadError"))
                        });
                    }
                }, function () {
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    threeInfo($translate.instant("editorCommon.loadError"))
                })
            }else {
                if(!_imagebox.image.resource.identifier1){
                    _imagebox.image.resource.identifier1 = angular.copy(_imagebox.image.resource.identifier);
                }
                if(!item.type){
                    vm.filterType=item.type;
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = _imagebox.image.resource.identifier1;
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                }else {
                    Convert.imageEnhance({
                        imageUrl: _imagebox.image.resource.identifier1,
                        type: item.type
                    }, function (resp) {
                        if(resp.status == 200){
                            vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = $scope.domain + resp.message;
                            vm.filterType=item.type;
                        }else{
                            threeInfo(resp.message)
                        }
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                    }, function () {
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                        threeInfo($translate.instant("editorCommon.loadError"))
                    });
                }
            }
        };
        function threeInfo(item){
            vm.threeError = true;
            vm.threeErrorText=item;
            $timeout(function () {
                vm.threeError = false;
                vm.threeErrorText="";
            },1500)
        }
        function threeInfo1(item){
            vm.threeError1 = true;
            vm.threeErrorText1=item;
            $timeout(function () {
                vm.threeError1 = false;
                vm.threeErrorText1="";
            },1500)
        }
        $scope.cutImage = function () {
            $timeout(function () {
                vm.filterShow = true;
            })
        };
        $scope.filterChoose = function (item) {
            switch (item) {
                case 'selfie_anime':
                    return $translate.instant("editorBtn.selfieAnime");
                    break;
                case 'warm':
                    return $translate.instant("editorBtn.warm");
                    break;
                case 'wave':
                    return $translate.instant("editorBtn.wave");
                    break;
                case 'lavender':
                    return $translate.instant("editorBtn.lavender");
                    break;
                case 'scream':
                    return $translate.instant("editorBtn.scream");
                    break;
                case 'gothic':
                    return $translate.instant("editorBtn.gothic");
                    break;
                case 'body_seg':
                    return $translate.instant("editorBtn.bodySeg");
                    break;
                default:
                    return $translate.instant("editorBtn.originalPainting");
            }
        }

        var dragGal = {};
        var drag = false;
        var startChangeTimeout;
        vm.isSmallPic = false;
        window.startChange = function (e) {
            startChangeTimeout = setTimeout(function() {
                vm.isSmallPic = true;
                $scope.$digest();
            }, 200);
            drag = true;
            var i = e.target.dataset.imgindex;
            if(i >=0){
                if(vm.mapDepot1){
                    $scope.galleryArr = vm.photoSelectAll1[i];
                    $scope.galleryChoose = vm.photoSelectAll1[i].identifier;
                }
                if(vm.mapDepot2){
                    $scope.galleryArr = vm.photoAll[i];
                    $scope.galleryChoose = vm.photoAll[i].identifier;
                }

                $scope.galleryId = i;
                $scope.galleryX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX - 53 : 0;
                $scope.galleryY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY - 40 : 0;
                dragGal.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
                dragGal.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
                dragGal.pageX = e.changedTouches[0].pageX ? e.changedTouches[0].pageX : 0;
                dragGal.pageY = e.changedTouches[0].pageY ? e.changedTouches[0].pageY : 0;
                dragGal.coordX = $scope.galleryX;
                dragGal.coordY = $scope.galleryY;
                $scope.$digest();
                return false;
            }
        };

        window.dragChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (drag) {
                //手指离开屏幕
                $scope.galleryX = (dragGal.coordX + (e.changedTouches[0].clientX - dragGal.clientX));
                $scope.galleryY = (dragGal.coordY + (e.changedTouches[0].clientY - dragGal.clientY));

                // if (Math.abs($scope.galleryY - dragGal.coordY) > 100) {
                //     vm.isSmallPic = true;
                // }


                //向上滑动;
                if ($scope.galleryY <= 0) {
                    $scope.galleryY = 0;
                }
                //向左滑动;        <-----
                if ($scope.galleryX <= 0) {
                    $scope.galleryX = 0;
                }
                //向下滑动;
                if ($scope.galleryY >= $scope.dHeight - 62) {
                    $scope.galleryY = $scope.dHeight - 62;
                }
                // 向右滑动;    ---->
                if ($scope.galleryX >= $scope.dWidth - 62) {
                    $scope.galleryX = $scope.dWidth - 62;
                }

                var _picTop = $("#small-pic").offset().top;
                var _picLeft = $("#small-pic").offset().left;
                var _exist = false;
                for (var i1 = vm.pageData[vm.pageIndex].levels.level.length-1; i1 >= 0; i1--) {
                    var _level = vm.pageData[vm.pageIndex].levels.level[i1];
                    if (_level.imagebox) {
                        var _imageboxLeft = $(".imagebox" + i1).offset().left;
                        var _imageboxTop = $(".imagebox" + i1).offset().top;
                        var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                        var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                        if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight) && !_exist) {
                            _exist = true;
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(103, 106, 108, 0.8)")
                            break;
                        }else{
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(0,0,0,0.2)")
                        }
                    }
                }
                $scope.$digest();
                return false;
            }
        };
        window.stopChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (!drag) {
                return
            }
            drag = false;
            var _picTop = $("#small-pic").offset().top;
            var _picLeft = $("#small-pic").offset().left;
            for (var i1 = vm.pageData[vm.pageIndex].levels.level.length-1; i1 >= 0; i1--) {
                var _level = vm.pageData[vm.pageIndex].levels.level[i1];
                if (_level.imagebox) {
                    var _imageboxLeft = $(".imagebox" + i1).offset().left;
                    var _imageboxTop = $(".imagebox" + i1).offset().top;
                    var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                    var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                    if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight)) {
                        $scope.replaceImagebox1($scope.galleryArr, i1)
                        break;
                    }
                }
            }
            vm.isSmallPic = false;
            $scope.$digest();
        };


        $scope.addImageboxImg = function (item,callback) {
            if(vm.pageData[vm.pageIndex] && vm.pageData[vm.pageIndex].levels.level && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox){
                replaceImagebox(item,function () {
                    if(callback){callback()}
                })
            }else {
                for (var i = 0; i < vm.pageData[vm.pageIndex].levels.level.length; i++) {
                    var _level = vm.pageData[vm.pageIndex].levels.level[i];
                    if (_level.imagebox && _level.imagebox.image && !_level.imagebox.image.resource.identifier) {
                        vm.levelIndex = i;
                        replaceImagebox(item, function () {
                            vm.levelIndex = null;
                            if(callback){callback()}
                        });
                        break;
                    }
                }
            }
        };

        vm.fullMap = false;
        vm.fullMap1 = false;
        vm.fullMap2 = false;
        vm.fullMapNumber = 0;
        $scope.fullMapSelection = vm.fullMapSelection = function(){
            vm.fullMapNumber = 0;
            if(vm.pageData[vm.pageIndex] && vm.pageData[vm.pageIndex].levels.level && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox){
                vm.fullMapNumber = 1;
            }else {
                for (var i = 0; i < vm.pageData[vm.pageIndex].levels.level.length; i++) {
                    var _level = vm.pageData[vm.pageIndex].levels.level[i];
                    if (_level.imagebox && _level.imagebox.image && !_level.imagebox.image.resource.identifier) {
                        vm.fullMapNumber++
                    }
                }
            }
            vm.photoSelectAll2 = angular.copy(vm.photoSelectAll) || [];
            vm.photoSelectAll = [];
            vm.fullMap = true;
            vm.page4 = false;
            vm.mapDepot = false;
            $scope.fullMapCb1();
            vm.ratioInfo(vm.pageData[vm.pageIndex]);
        };
        $scope.fullMapHide = function(){
            vm.page2 = false;
            vm.page9 = false;
            vm.fullMap = false;
            vm.fullMap1 = false;
            vm.fullMap2 = false;
            vm.page4 = true;
            var _photoSelectAll = vm.photoSelectAll;
            vm.photoSelectAll2.forEach(function (photoSelectAll2) {
                var _exist = false;
                for(var i=0; i<vm.photoSelectAll.length; i++){
                    if(vm.photoSelectAll[i].id == photoSelectAll2.id){
                        _exist = true;
                        break
                    }
                }
                if(!_exist){
                    _photoSelectAll.push(photoSelectAll2)
                }
            })
            vm.photoSelectAll = angular.copy(_photoSelectAll);
        };
        $scope.fullMapCb1 = function(){
            vm.fullMap1 = true;
            vm.fullMap2 = false;
            vm.page2 = false;
            vm.page9 = false;
            vm.photoSelectAll2.forEach(function (photoSelectAll2) {
                photoSelectAll2.selected = false;
                for(var i=0; i<vm.photoSelectAll.length; i++){
                    if(vm.photoSelectAll[i].id == photoSelectAll2.id){
                        photoSelectAll2.selected = true;
                        break
                    }
                }
            })
        }
        $scope.fullMapCb2 = function(){
            vm.fullMap1 = false;
            vm.fullMap2 = true;
            vm.page2 = true;
            vm.page9 = false;
            $scope.galleryNavSel('gallery');
        }
        $scope.replaceImagebox1 = function (data, index) {
            vm.levelIndex = index;
            replaceImagebox(data)
        }

        function replaceImagebox(data, callback) {
            var item = angular.copy(data);
            item.identifier = $scope.domain + item.identifier;
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            var identifier = _imagebox.mask.resource.identifier;
            _imagebox.image = {
                resource: {
                    identifier: item.identifier,
                    provider: 'qiniu'
                },
                resourceid:item.sourceId,
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                imageInfo: item.imageInfo,
                width: item.width,
                height: item.height,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0
            }
            addImagebox($http, _imagebox, function () {
                data.selected = true;
                photoSelect(data.selected, data)
                photoSelectAllInit();
                if(callback){callback()}
            })
        }

        function photoSelectAllInit(isSort) {
            if(!vm.pageData){
                return
            }
            vm.photoSelectAll.forEach(function (item) {
                item.frequency = 0;
                for (var i = 0; i < vm.pageData.length; i++) {
                    var _page = vm.pageData[i];
                    if (_page.levels.level) {
                        for (var j = 0; j < _page.levels.level.length; j++) {
                            var _level = _page.levels.level[j];
                            if (_level.imagebox && _level.imagebox.image) {
                                if (_level.imagebox.image.resource && _level.imagebox.image.resource.identifier) {
                                    if(_level.imagebox.image.resourceid == item.sourceId){
                                        item.frequency++
                                    }
                                }
                            }
                        }
                    }
                }
            })
            vm.photoAll.forEach(function (item) {
                item.frequency = 0;
                for (var i = 0; i < vm.pageData.length; i++) {
                    var _page = vm.pageData[i];
                    if (_page.levels.level) {
                        for (var j = 0; j < _page.levels.level.length; j++) {
                            var _level = _page.levels.level[j];
                            if (_level.imagebox && _level.imagebox.image) {
                                if (_level.imagebox.image.resource && _level.imagebox.image.resource.identifier) {
                                    if(_level.imagebox.image.resourceid == item.sourceId){
                                        item.frequency++
                                    }
                                }
                            }
                        }
                    }
                }
            })
            if(isSort){
                vm.photoSelectAll1 = angular.copy(vm.photoSelectAll);
                vm.photoSelectAll1 = vm.photoSelectAll1.sort(sortNavTitle("frequency"));
            }else{
                if(vm.photoSelectAll1){
                    vm.photoSelectAll1.forEach(function (item) {
                        item.frequency = 0;
                        for (var i = 0; i < vm.pageData.length; i++) {
                            var _page = vm.pageData[i];
                            if (_page.levels.level) {
                                for (var j = 0; j < _page.levels.level.length; j++) {
                                    var _level = _page.levels.level[j];
                                    if (_level.imagebox && _level.imagebox.image) {
                                        if (_level.imagebox.image.resource && _level.imagebox.image.resource.identifier) {
                                            if(_level.imagebox.image.resourceid == item.sourceId){
                                                item.frequency++
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    })
                }
            }
        }


        //保存
        function articleOverdue(callback){
            Article.get({id: vm.article.id}, function (article) {
                if(article.data.fileName != vm.article.fileName){
                    MessageService.minConfirm({
                        cancel:$translate.instant("button.cancel"),
                        confirm:$translate.instant("button.refresh"),
                        msg:$translate.instant("hint.articleOverdue")
                    },function(){
                        $state.go("editorPhotoAlbumEditM", {storeId: vm.storeId, aid:  vm.article.id},{reload:true});
                    })
                }else{
                    callback();
                }
            })
        }
        $scope.isSave = false;
        vm.isDocumentTextDetection = false;
        $scope.save = function () {
            articleOverdue(function () {
                if ($scope.isSave) {
                    return;
                }
                var _documentTextDetection = documentTextDetection1($scope.template.pages.page);
                if(!_documentTextDetection.pass){
                    vm.isDocumentTextDetection = true;
                    vm.documentTextDetection = _documentTextDetection.pages;
                    return;
                }
                if(!vm.isPageWarnExamine && pageWarnExamine($scope.template.pages.page, vm.imgSize, vm.imgDpi)){
                    SweetAlert.swal({
                        title: $translate.instant("editorCommon.hint"),
                        imageUrl: Theme.sweetHintImg(),
                        text: $translate.instant("editorCommon.lackPixelsMessage"),
                        showCancelButton: true,
                        confirmButtonText: $translate.instant("button.confirm"),
                        cancelButtonText: $translate.instant("button.cancel"),
                        closeOnConfirm: true,
                        closeOnCancel: true
                    }, function (isConfirm) {
                        if (isConfirm) {
                            vm.isPageWarnExamine = true;
                            $timeout(function () {
                                $scope.save();
                            },200)
                        }
                    })
                    return
                }
                vm.isPageWarnExamine = false;
                if ($scope.template.pages.page.length < $scope.PAGELEN) {
                    SweetAlert.swal({
                        title: $translate.instant("hint.loadingText"),
                        imageUrl: Theme.sweetHintImg(),
                        text: "",
                        timer: 2000,
                        showConfirmButton: false
                    });
                    return
                }

                if (vm.article.ordered) {
                    SweetAlert.swal({
                        title: $translate.instant("font.warmPrompt"),
                        imageUrl: Theme.sweetHintImg(),
                        text: $translate.instant("editorCommon.newWork"),
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                //判断是否有图片未上传  且是否继续保存
                if (!$scope.isContinue()) {
                    // vm.completed = false;
                    SweetAlert.swal({
                        title: $translate.instant("editorCommon.saveSure"),
                        imageUrl: Theme.sweetHintImg(),
                        showCancelButton: true,
                        confirmButtonText: $translate.instant("button.confirm"),
                        cancelButtonText: $translate.instant("button.cancel"),
                        closeOnConfirm: true,
                        closeOnCancel: true
                    }, function (isConfirm) {
                        if (isConfirm) {
                            $timeout(function () {
                                generateJsonFile();
                            }, 500);
                            return;
                        }
                    })
                } else {
                    // vm.completed = true;
                    generateJsonFile();
                }
            })
        }
        function generateJsonFile() {
            vm.threeLoading = true;
            vm.threeLoadingText=$translate.instant("hint.loadingText");
            thumbnailInit(function (res){
                generateJsonFile1()
            })
        }
        var generateJsonFile1 = function (){
            $scope.isSave = true;
            vm.threeLoading = true;
            vm.threeLoadingText=$translate.instant("hint.loadingText");
            var template = saveData();
            template.hostinfo = hostInfo();
            template.replace= vm.replace;
            Convert.articleUploadDocument(template, function (res) {
                vm.article.articleStatus = vm.completed;
                vm.article.fileName = res.data.identifier;
                vm.article.id = vm.aid;
                vm.article.pageCount = $scope.template.pages.page.length;
                Article.update(vm.article, function (resp) {
                    $scope.isSave = true;
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    $scope.isSave = false;
                    swal({
                        title: $translate.instant("editorCommon.modifySuccess"),
                        text: $translate.instant("editorCommon.synchronizationOfWorks"),
                        imageUrl: Theme.sweetSuccedImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                    $state.go("product-view-ec", {storeId: vm.storeId, aid: resp.data.id});
                }, function () {
                    $scope.isSave = false;
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    swal({
                        title: $translate.instant("editorCommon.saveError"),
                        text: $translate.instant("editorCommon.saveError1"),
                        imageUrl: Theme.sweetHintImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                })
            }, function () {
                $scope.isSave = false;
                vm.threeLoading = false;
                vm.threeLoadingText = '';
                swal({
                    title: $translate.instant("editorCommon.saveError"),
                    text: $translate.instant("editorCommon.saveError1"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            })
            localStorage.removeItem("photoSelectAll");
        }

        $scope.isContinue = function () {
            var hasEmpty = true;
            vm.unFilledCount = 0;
            for (var i = 0; i < $scope.template.pages.page.length; i++) {
                var _page = $scope.template.pages.page[i];
                if (_page.levels.level) {
                    for (var j = 0; j < _page.levels.level.length; j++) {
                        var _level = _page.levels.level[j];
                        if (_level.imagebox && _level.imagebox.image) {
                            if (_level.imagebox.image.resource && _level.imagebox.image.resource.identifier) {
                            } else {
                                hasEmpty = false;
                                vm.unFilledCount++;
                            }
                        }
                    }
                }
            }
            vm.completed = hasEmpty ? 'COMPLETED' : 'EDITING';
            return hasEmpty;
        };

        function saveData() {
            return documentMM($scope.template)
        }
        $scope.threeModalHide = function () {
            vm.threeIsModal = false;
            vm.threeModalText = "";
            vm.threeIsModal1 = false;
            vm.threeModalText1 = "";
        }
        $scope.threeModalSave = function () {
            vm.threeIsModal = false;
            vm.threeModalText = "";
            vm.threeIsModal1 = false;
            vm.threeModalText1 = "";
            templateReplace();
        }
        $scope.textLayout = function(item){
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            var text1 = angular.copy(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox);
            text.style.writingmode = item;
            $timeout(function () {
                text.geometry.width = text1.geometry.height;
                text.geometry.height = text1.geometry.width;
            },200)
        }
        $scope.writingmodeInit = function (item) {
            if(item == 'vertical-lr' || item == 'vertical-rl'){
                return true
            }else{
                return false
            }
        }
        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if(res.status == 200){
                vm.imgSize = res.data.imgSize;
                vm.imgDpi = res.data.imgDpi;
            }
        });
        //生成缩略图
        function thumbnailInit(callback){
            thumbnailInit1(function (res){
                if(res){
                    vm.article.covered=true;
                    vm.article.cover=res;
                }else{
                    vm.article.covered=false;
                    vm.article.cover=null;
                }
                callback(res);
            })
        }
        function thumbnailInit1(callback) {
            $timeout(function (){
                html2canvas_adapt(document.getElementById('thumbnail-view'), {
                    onrendered: function (canvas) {
                        if(canvas){
                            var url = canvas.toDataURL("image/png");
                            var blob = dataURLtoBlob(url);
                            var formData = new FormData();
                            formData.append('file', blob);
                            formData.append('key', '3dThumbnail-' + UidService.get());
                            formData.append('token', vm.token);
                            $.ajax({
                                cache: true,
                                type: "POST",
                                url: 'https://upload.qiniup.com/',
                                data: formData,
                                async: false,
                                contentType: false,
                                processData: false,
                                success: function (data) {
                                    var identifier = data.key;
                                    callback(identifier)
                                },
                                error:function () {
                                    if (callback) {callback()};
                                }
                            })
                        }else{
                            if (callback) {callback()};
                        }
                    },
                    error:function(){
                        if (callback) {callback()};
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: $scope.template.pages.page[0].custom.width*$scope.template.pages.page[0].custom.thumbnailScale,
                    height: $scope.template.pages.page[0].custom.height*$scope.template.pages.page[0].custom.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            })
        }
        vm.onArticleChange = onArticleChange;
        function onArticleChange() {}
        // 监听组件内图库返回按钮
        $scope.$on('closeGallery', function(event,close) {
            if (close) {
                $scope.getBack('page2')
            }
        });
        // 监听选择图片确认按钮
        $scope.$on('confirmGallery',function(event,photoSelectAll){
            if(vm.fullMap){
                vm.threeLoading = true;
                vm.threeLoadingText = "加载中";
                photoSave1(vm.photoSelectAll,0,function () {
                    vm.threeLoading = false;
                    $scope.fullMapHide();
                });
                return
            }
            vm.photoSelectAll = photoSelectAll;
            vm.data = [];
            $scope.photoSave();
        })
        function photoSave1(photoSelectAll,index,callback){
            if(photoSelectAll.length == index){
                callback();
                return
            }
            $scope.addImageboxImg(photoSelectAll[index],function () {
                var _index = index+1;
                photoSave1(photoSelectAll,_index,callback)
            })
        }

        $scope.$on('changeLanguage', function () {
            if(vm.istemplate){
                $scope.templateShow();
            }
            if(vm.isMaterial){
                $scope.materialShow();
            }
            getAllFont();
            vm.textStyleTab = [
                {name: $translate.instant("font.textStyleTabFont"), id: 'textStyleTabFont'},
                {name: $translate.instant("font.textStyleTabSize"), id: 'textStyleTabSize'},
                {name: $translate.instant("font.textStyleTabType"), id: 'textStyleTabType'},
                {name: $translate.instant("font.textStyleTabColor"), id: 'textStyleTabColor'},
            ];
        });
        //内页叶序
        $scope.getIndex = function(index){
            var _index = 0;
            for(var i=0; i<$scope.template.pages.page.length; i++){
                var _page = $scope.template.pages.page[i];
                if(_page.config.partid == "P"){
                    break
                }else{
                    _index +=1;
                }
            }
            return Number(index)-Number(_index)+1;
        }
        //部件名
        $scope.partName = function (partid) {
            if (!$scope.template||!$scope.template.config||!$scope.template.config.parts||!$scope.template.config.parts.list) {
                return
            }
            var partName = "";
            for(var i=0; i<$scope.template.config.parts.list.length; i++){
                if($scope.template.config.parts.list[i].id == partid){
                    partName = $scope.template.config.parts.list[i].name;
                }
            }
            return partName
        }
        /*
        * 动态日历
        * */
        vm.years=[];
        vm.months=[1,2,3,4,5,6,7,8,9,10,11,12];
        vm.yearIndex=1;
        vm.monthIndex=0;
        vm.monthIndex1=0;
        vm.isCalendar=false;
        function yearInfo() {
            $scope.pages.pages.page.forEach(function (page) {
                if(vm.monthIndex < page.config.monthIndex){
                    vm.monthIndex = page.config.monthIndex
                }
            })
            var date = new Date();
            var year = date.getFullYear();
            for(var i=-1;i<6;i++){
                vm.years.push(year+i)
            }
            // var now = new Date();
            // var year1 = now.getFullYear();
            // vm.yearIndex = vm.years.indexOf(year1) < 0 ? 1 : vm.years.indexOf(year1);
            // vm.monthIndex1 = now.getMonth();
        }
        $scope.getEndYear = function () {
            return getDay('year',vm.years[vm.yearIndex],vm.months[vm.monthIndex1],vm.monthIndex)
        }
        $scope.getEndMonth = function () {
            return getDay('month',vm.years[vm.yearIndex],vm.months[vm.monthIndex1],vm.monthIndex)
        }
        $scope.yearIndex = function(item){
            if(item == 'subtract'){
                vm.yearIndex = vm.yearIndex-1<0?0:vm.yearIndex-1;
            }
            if(item == 'add'){
                vm.yearIndex = vm.yearIndex+1>vm.years.length-1?vm.years.length-1:vm.yearIndex+1;
            }
        }
        $scope.calendarSave = function () {
            var page = $scope.pages;
            if($scope.template){
                page = $scope.template;
            }
            page.config.startYear = vm.years[vm.yearIndex];
            page.config.startMonth = vm.months[vm.monthIndex1];
            page.pages.page.forEach(function (page) {
                page.levels.level.forEach(function (level) {
                    if (level.calMonthBox && level.calMonthBox.geometry) {
                        var calMonthBox = level.calMonthBox;
                        calMonthBox.obj=null;
                        calMonthBox.sampleYear = getDay('year',vm.years[vm.yearIndex],vm.months[vm.monthIndex1],page.config.monthIndex);
                        calMonthBox.sampleMonth = getDay('month',vm.years[vm.yearIndex],vm.months[vm.monthIndex1],page.config.monthIndex);
                    }
                    if (level.calTitleBox && level.calTitleBox.geometry) {
                        var calTitleBox = level.calTitleBox;
                        calTitleBox.obj=null;
                        calTitleBox.sampleYear = getDay('year',vm.years[vm.yearIndex],vm.months[vm.monthIndex1],page.config.monthIndex);
                        calTitleBox.sampleMonth = getDay('month',vm.years[vm.yearIndex],vm.months[vm.monthIndex1],page.config.monthIndex);
                    }
                })
            })
            vm.isCalendar=false;
        }

        // websocket接收上传的图片（多端互通实时更新）
        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.aid);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:'+ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                if (photo.folderId == null) {
                    vm.photoAll.unshift(photo)
                    getPagePhoto();
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }
    }
})();
