(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('QrcodeController', QrcodeController);

    QrcodeController.$inject = ['$scope', 'Category', '$uibModalInstance', 'MessageService','entity'];

    function QrcodeController( $scope, Category, $uibModalInstance, MessageService,entity) {

        var vm = this;

        vm.code = entity.code;
        vm.clear = clear;
        vm.save = save;

        vm.data={
            range:'this',
            generLoc:'all'
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function () {
            console.log(vm.code)
            if(vm.code.width==''||vm.code.width==null){
                MessageService.error("请输入二维码宽度");
                return
            }
            if(vm.code.height==''||vm.code.height==null){
                MessageService.error("请输入二维码高度");
                return
            }
            if(vm.code.value==''||vm.code.value==null){
                MessageService.error("请输入二维码内容");
                return
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            onSaveSuccess(vm.code);
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }

})();
