(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('CommodityPreviewController', CommodityPreviewController);

    CommodityPreviewController.$inject = ['$scope', 'Principal', 'Product', '$state', 'MessageService', 'Customer', '$rootScope', 'entity', '$stateParams', '$uibModalInstance', 'ProductXml', 'Qinius', '$q'];

    function CommodityPreviewController ($scope, Principal, Product, $state, MessageService, Customer, $rootScope, entity, $stateParams, $uibModalInstance, ProductXml, Qinius, $q) {
        var vm = this;
        $(".wrap").show();
        $(".navbar").show();
        vm.itemValue = entity.value;
        vm.storeId = entity.storeId;
        vm.pid = entity.pid;
        vm.vid = entity.vid;
        vm.aid = entity.aid;
        vm.isMake = entity.isMake || false;
        vm.itemId = entity.itemValueId;
        vm.editorType = entity.editorType;

        if (entity.isShowbotton != null) {
            vm.isShowbotton = entity.isShowbotton
        }else{
            vm.isShowbotton = true;
        }
        console.log(entity);
        vm.height = 937; document.body.clientHeight ;
        vm.domains = Qinius.getDomain({storeId:vm.storeId});

        Product.get({id: vm.pid}, function (res) {
            vm.product = res.data;
        })
        $q.all([vm.domains.$promise]).then(function () {
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            init()
        });
        //初始化
        function init(){

            //获取预览图片
            ProductXml.get({id:vm.itemValue.productXmlId},function(res){
                if(res.status == 200){
                    if (res.data.documentPics && res.data.documentPics.length > 0) {
                        res.data.documentPics.sort(function (a, b) {
                            return a.seq - b.seq;
                        });
                    }
                    vm.imgs = res.data.documentPics;
                    setTimeout(function () {
                        swiper();
                    },300);
                }else {
                    MessageService.error(res.message);
                }

            },function(){

            });
        };
        $scope.modalHide = function(){
            $uibModalInstance.close();
        };
        $scope.modalSubmint = function(){
            var vid = vm.vid;
            if(vm.itemId){
                vid = vm.itemId
            }
            if (vm.editorType == "PICTURE" || vm.editorType == "PICTURE_PRO") {
                $state.go("editorPhotoBothWeb", {storeId: vm.storeId, pid: vm.pid, vid: vid, xmlId: vm.itemValue.id, aid: vm.aid});
            }else{
                $state.go("editorPhotoAlbum", {storeId: vm.storeId, pid: vm.pid, vid: vid, xmlId: vm.itemValue.id, aid: vm.aid});
            }
            $uibModalInstance.close();
        };
        //swiper
        function swiper() {
            var swiper = new Swiper('.swiper-container', {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }
        $scope.valueSeleted = function () {
            var url = $state.href("page-my-suffix-edit", {storeId: vm.storeId, pid: vm.pid, vid: vm.itemValue.itemValueId, aid: vm.aid});
            window.open(url,'_blank');
        };
    }
})();
