(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductViewEc2Controller', ProductViewEc2Controller);

    ProductViewEc2Controller.$inject = ['$scope', '$stateParams', 'entity', '$q', '$localStorage', 'Qinius', 'Store',
        '$http', '$rootScope', 'Theme', 'ItemValue', 'PlatformWechatSetting','Convert','Product','ModulesService'];
    function ProductViewEc2Controller($scope, $stateParams, entity, $q, $localStorage, Qinius, Store,
                                    $http, $rootScope, Theme, ItemValue, PlatformWechatSetting,Convert,Product,ModulesService) {
        var vm = this;

        //风格切换  引入不同的css文件
        Theme.judge();
        vm.platformSetting = PlatformWechatSetting.get();
        var audio = document.getElementById("audio");
        // audio.src = "content/ihope.mp3";
        audio.crossOrigin = 'anonymous';

        var timer = null;
        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        vm.aid = $stateParams.aid;
        vm.article = angular.copy(entity.data);
        vm.bindingtype = 'Left';

        vm.store = Store.get({id: vm.storeId});
        $scope.dHeight = vm.dHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        $scope.dWidth = vm.dWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth, 'background': '#fff'});
        var $li = $(".templateView2-carousel");
        $li.hide();
        $scope.domains = Qinius.getDomain({storeId: vm.storeId});

        function Play() {
            audio.play().then(function () {
                $(".PanelRight").removeClass('hide1');
                timer = window.setInterval(begin, 10);
            }).catch(function (error) {
                console.log(error);
            });
        }

        var rot = 10 - 0;

        function begin() {
            var micon = document.getElementById("music-icon");
            if (micon == null) {
                window.clearInterval(timer);
                return;
            }
            micon.style.transform = "rotate(" + rot + "deg)";
            rot += 1;
        }

        $("#Play").on('click', function () {
            window.clearInterval(timer);
            if (audio.paused) {
                audio.play();
                Play();
                $("#Play").children("img").removeClass("play").addClass("pause");
            }
            else {
                $("#Play").children("img").removeClass("pause").addClass("play");
                audio.pause();
            }
        });

        function makeCode() {
            var qrcode = new QRCode(document.getElementById("qrcode"), {
                width: 100,
                height: 100
            });
            qrcode.makeCode(vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/index');
        }

        getAll();
        function getAll() {
            $q.all([$scope.domains.$promise, vm.article.$promise, vm.platformSetting.$promise, vm.store.$promise]).then(function () {
                if(vm.article.invalid){//作品失效
                    $state.go('articlesFailure',{storeId:vm.storeId});
                    return
                }
                vm.platformSetting = vm.platformSetting.data;
                vm.store = vm.store.data;
                // if (vm.store.storeType2.indexOf("MINI") < 0) {
                if (1) {
                    $scope.miniType = false;
                    makeCode();
                } else {
                    $scope.miniType = true;
                    $http({
                        url: "/fileserves/api/download/getMiniQrCode?storeId=" + vm.storeId,
                        method: 'get',
                        responseType: 'blob'
                    }).success(function (res) {
                        if(res.status == 200){
                            var blob = new Blob([res.data], {
                                type: "image/jpeg"
                            });
                            $scope.image = URL.createObjectURL(blob);
                        }else {
                            MessageService.error(res.message);
                        }
                    })
                }
                Product.get({id: vm.article.productId}, function (resP) {
                    Convert.xmlToDocument2({
                        identifier: vm.article.fileName,
                        provider: 'qiniu',
                        editorType:vm.article.editorType,
                        patternType:resP.data.patternType,
                    }, function (resp) {
                        vm.documentPage = resp;
                    })
                })
                $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                ItemValue.get({id: vm.article.itemValueId}, function (res) {
                    if(res.status == 200){
                        vm.itemValue = res.data;
                        vm.bindingtype =  res.data.bindingType;
                        if (vm.article.musicIdentify) {
                            audio.src = $scope.domain + vm.article.musicIdentify;
                        } else {
                            if (res.data.musicIdentify) {
                                audio.src = $scope.domain + res.data.musicIdentify;
                            } else {
                                audio.src = '';
                            }
                        }
                    }else {
                        MessageService.error(res.message);
                    }
                });
                // 自动播放音乐
                // Play();

                //获取自定义颜色
                ModulesService.getToneSettings(vm.storeId,function (res) {
                    vm.toneSettings = res;
                });
            });
        }

        //旋转屏幕
        // window.addEventListener('orientationchange', function (event) {
        //     setReviewDoc();
        // });
        window.addEventListener('resize', function (event) {
            if (vm.bindingtype == 'None' || $scope.template.pages.page.length < 2) {
                return
            }

            if (window.orientation == 0) {
                $rootScope.orientations = false;
            }
            setReviewDoc();
        });

        if (window.orientation == 90 || window.orientation == -90) {
            $li.width($scope.dWidth);
            $rootScope.orientation = false;
        } else {
            $rootScope.orientations = true;
        }

        function setReviewDoc() {
            $(".save-page").removeClass('hide1');
            $(".view2-article").addClass('hide1');
            $(".share-wrap").addClass('hide1');
            setTimeout(function () {
                if (window.orientation == 180 || window.orientation == 0) {
                    $rootScope.orientations = true;
                    orientationChange();

                } else if (window.orientation == 90 || window.orientation == -90) {
                    $rootScope.orientations = false;
                    orientationChange();
                }
            }, 500);
        }

        function orientationChange() {
            $scope.k = 0;
            $scope.dHeight = document.documentElement.clientHeight;
            $scope.dWidth = document.documentElement.clientWidth;
            $(".back-to-customer").width($scope.dWidth);
            $li.width($scope.dWidth);
            vm.article = angular.copy(entity.data);
            getAll();
            setTimeout(function () {
                $(".save-page").addClass('hide1');
                $(".view2-article").removeClass('hide1');
                $(".share-wrap").removeClass('hide1');
            }, 1500);
        }
    }
})();
