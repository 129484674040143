(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('PageViewArticleController', PageViewArticleController);

    PageViewArticleController.$inject = ['$scope', '$stateParams', 'Qinius', '$q', 'ImageService', 'PlatformWechatSetting',
        'Article', 'Music', 'MessageService','$state', 'ProductXml', 'ItemValue', 'Convert', 'Product','StoreWorkEditConfig','OrderArticle', 'ModulesService','$localStorage','$translate'];

    function PageViewArticleController($scope, $stateParams, Qinius, $q, ImageService, PlatformWechatSetting,
                                       Article, Music, MessageService,$state, ProductXml, ItemValue, Convert, Product, StoreWorkEditConfig,OrderArticle, ModulesService,$localStorage,$translate) {
        var vm = this;
        $(".navbar").hide();
        $(".wrap").addClass('no-margins').css({height:'100vh',background:'#fff'});
        vm.storeId = $stateParams.storeId;
        vm.aid = $stateParams.aid;
        vm.orderArticleId = $stateParams.orderArticleId;
        if($stateParams.authorization){
            $localStorage.authenticationToken = $stateParams.authorization;
        }
        vm.isNav = false;
        if (vm.aid) {
            vm.article = Article.get({id: vm.aid});
        } else {
            vm.isNav = true;
            vm.article = OrderArticle.get({id: vm.orderArticleId});
        }

        vm.itemValue={};
        vm.watchSure = false;
        function getProductXml() {
            ProductXml.get({id:vm.article.productXmlId}, function (productXml) {
                if(productXml.status == 200){
                    vm.fileName = vm.article.fileName;
                    if(productXml.data.modelType && productXml.data.modelType === 'THREE'){
                        if(!productXml.data.modelPath){
                            MessageService.error($translate.instant("editorMessage.not3D"));
                            return
                        }
                        vm.threeProductXmlData = productXml.data;
                        vm.threeView = true;
                    }else if(vm.article.editorType == 'PICTURE' || vm.article.editorType == 'PICTURE_PRO'){
                        vm.picture = true;
                    }else {
                        vm.watchSure = true;
                        // vm.previewArticle = template;
                        ItemValue.get({id: vm.article.itemValueId}, function (resp) {
                            vm.itemValue = resp.data;
                        })
                    }
                }else {
                    MessageService.error(productXml.message)
                }
            })
        }

        // return
        vm.musicPage = false;
        var audio = document.getElementById("audio");
        var timer = null;


        var returnurl = localStorage.getItem('returnurl');
        vm.returnurl = localStorage.getItem('returnurl');
        vm.returnurl1 = localStorage.getItem('returnurl1');
        vm.returnurl2 = localStorage.getItem('returnurl2');
        vm.returnurl2Text = $translate.instant("button.buttonText");
        $scope.domains = Qinius.getDomain();
        vm.platformSetting = PlatformWechatSetting.get();


        $scope.gotoOrder = function(){
            window.location.href = vm.returnurl2;
        }
        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if(res.status == 200){
                vm.returnurl2Text = res.data.buttonText;
            }else{
                swal.showInputError(res.message);
            }
        });

        $(".modal-content").addClass('opacity0');
        vm.isHideMusicBtn = false;
        $q.all([$scope.domains.$promise, vm.platformSetting.$promise, vm.article.$promise]).then(function () {
            vm.article = vm.article.data;
            if(vm.article.invalid){//作品失效
                $state.go('articlesFailure',{storeId:vm.storeId});
                return
            }
            Product.get({id: vm.article.productId}, function (resP) {
                vm.product = resP.data;
                Convert.xmlToDocument2({
                    identifier: vm.article.fileName,
                    provider: 'qiniu',
                    editorType:vm.article.editorType,
                    pictureType:resP.data.pictureType,
                    patternType:resP.data.patternType,
                }, function (resp) {
                    vm.previewArticle = resp;
                    vm.previewArticle.pages.page.forEach(function (page, index){
                        page.seq = index;
                    })
                    console.log(vm.previewArticle)
                },function (error) {
                    if(error.data.status === 403){//未授权
                        $state.go('404',{storeId:vm.storeId});
                    }
                });

                vm.platformSetting = vm.platformSetting.data;
                $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                getProductXml();

                if (vm.article.musicIdentify) {
                    audio.src = $scope.domain + vm.article.musicIdentify;
                    console.log(audio.src)
                    // 自动播放音乐
                    Play();
                } else {
                    audio.src = '';
                    vm.isHideMusicBtn = true;
                }


                if(vm.article.editorType == "POSTER"){
                    vm.isPoster = true;
                    $('#qrcode1').qrcode({
                        render: "canvas",
                        width: 100,
                        height: 100,
                        text: '' + vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + vm.aid + "/viewShare"
                    });
                }else {
                    vm.isPoster = false;
                    // 分享二维码
                    $('#qrcode1').qrcode({
                        render: "canvas",
                        width: 100,
                        height: 100,
                        text: '' + vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + vm.aid + "/view2/0"
                    });
                }
                Music.allByCondition({
                    items: [],
                    sort: ["id,asc"]
                }, function (resp) {
                    if(resp.status==200){
                        $scope.Musics = resp.data;
                    }

                });

                //获取自定义颜色
                ModulesService.getToneSettings(vm.storeId,function (res) {
                    vm.toneSettings = res;
                });
            })
        });

        $scope.edit = function(){
            if(vm.article.editorType == "PICTURE" || vm.article.editorType == "PICTURE_PRO"){
                Product.get({id: vm.article.productId}, function (product) {
                    $state.go("editorPhotoBothWebEdit", {storeId: vm.storeId, aid:  vm.aid});
                })
            } else {
                $state.go('editorPhotoAlbumEdit', {storeId:vm.storeId,aid:vm.aid})
            }
        }
        vm.isPlay = true;
        //音乐
        function Play() {
            audio.play().then(function () {
                vm.isPlay = true;
                $scope.$digest();
                $(".PanelRight").removeClass('hide1');
                timer = window.setInterval(begin, 10);

            }).catch(function (error) {
                console.log(error);
            });
        }

        function Pause() {
            audio.pause();
            vm.isPlay = false;
            $scope.$digest();
        }

        var rot = 10 - 0;
        function begin() {
            var micon = document.getElementById("music-icon");
            if (micon == null) {
                window.clearInterval(timer);
                return;
            }
            micon.style.transform = "rotate(" + rot + "deg)";
            rot += 1;
        }

        $("#Play").on('click', function () {
            window.clearInterval(timer);
            if (audio.paused) {
                if ($(this).children().hasClass('play')) {
                    $("#Play").children("img").removeClass("play").addClass("pause");
                    Play();
                }
            } else {
                $("#Play").children("img").removeClass("pause").addClass("play");
                Pause();
            }
        });

        var audioTest = null;
        $scope.openMusicList = function () {
            vm.musicPage = true;
            audio.pause();
            vm.musicIdentify = vm.article.musicIdentify;
            if (vm.article.musicIdentify) {
                angular.forEach($scope.Musics, function (music, i) {
                    if (music.identify == vm.article.musicIdentify) {
                        $(".music-current").hide();
                        $(".music-current" + i).show();
                        setMusicStyle(i)
                    }
                })
            }

            audioTest = document.getElementById("audition");
            audioTest.crossOrigin = 'anonymous';
        };

        $scope.playList = function (identify, index) {
            // audioTest.pause();
            audioTest.src = $scope.domain + identify;
            vm.musicIdentify = identify;
            audioTest.play();
            setMusicStyle(index);
        };

        function setMusicStyle(index) {
            $(".music-name").removeClass('music-active2');
            $(".music-name" + index).addClass('music-active2');
            $(".music-li").removeClass('music-bgActive2');
            $(".music-li" + index).addClass('music-bgActive2');
            $(".music-icon").hide();
            $(".music-icon" + index).show();
        }

        $scope.saveMusic = function () {
            if (vm.musicIdentify == null || vm.musicIdentify == "") {
                vm.musicPage = false;
            } else {
                Article.updateMusic({id: vm.article.id, musicIdentify: vm.musicIdentify}, function (res) {
                    if(res.status == 200){
                        vm.article = res.data;
                        vm.musicPage = false;
                        audio.src = $scope.domain + vm.article.musicIdentify;
                        audioTest.pause();
                        audio.play();
                        Play();
                    }else {
                        MessageService.error(res.message);
                    }
                }, function () {

                })
            }

        };

        $scope.cancel = function () {
            vm.musicPage = false;
            audioTest.pause();
            audio.play();
        };

        vm.qrcodeShow = false
        $scope.shareParents = function () {
            vm.qrcodeShow = !vm.qrcodeShow;
        }

        $scope.reorder = function(){
            window.location.href = vm.returnurl2;
        }

        $scope.openViewList = function () {
            $scope.viewPage=!$scope.viewPage
        }

        $scope.backToList = function () {
            window.location.href = vm.returnurl1;
        }
    }
})();
