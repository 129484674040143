(function() {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
                parent: 'app2',
                url: '/{storeId}/login',
                data: {
                    authorities: [],
                    pageTitle: 'home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/home/home.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('home2', {
                parent: 'app',
                url: '/',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.commodity-center'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/gallery/gallery.html',
                        controller: 'GalleryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
