(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('Page', Page)
        .factory('Category', Category)
        .factory('FontManagement', FontManagement)
        .factory('FontXmlsManger', FontXmlsManger)
        .factory('FontXmlsFileServes', FontXmlsFileServes)
        .factory('ClipArt', ClipArt)
        .factory('Backgrounds', Backgrounds)
        .factory('Material', Material)
        .factory('Article', Article)
        .factory('Music', Music)
        .factory('XmlToImg', XmlToImg)
        .factory('StoreWorkEditConfig', StoreWorkEditConfig)
        .factory('FrameConfigs', FrameConfigs)
        .factory('Algorithm', Algorithm)
        .factory('StoreStorages', StoreStorages)
        .factory('CalendarService', CalendarService);

    Page.$inject = ['$resource'];
    Category.$inject = ['$resource'];
    FontManagement.$inject = ['$resource'];
    FontXmlsManger.$inject = ['$resource'];
    FontXmlsFileServes.$inject = ['$resource'];
    ClipArt.$inject = ['$resource', 'DateUtils'];
    Backgrounds.$inject = ['$resource', 'DateUtils'];
    Material.$inject = ['$resource'];
    Article.$inject = ['$resource'];
    Music.$inject = ['$resource'];
    XmlToImg.$inject = ['$resource'];
    StoreWorkEditConfig.$inject = ['$resource'];
    FrameConfigs.$inject = ['$resource'];
    Algorithm.$inject = ['$resource'];
    CalendarService.$inject = ['$resource'];
    StoreStorages.$inject = ['$resource'];

    function StoreStorages($resource) {
        var resourceUrl = 'manager/' + 'api/store-storages/:path';
        return $resource(resourceUrl, {}, {
            "uploadAble": {
                method: "GET",
                params: {
                    path: "uploadAble"
                }
            },
        });
    }

    function Algorithm($resource) {
        var resourceUrl = 'service/' + 'api/algorithm/:path/:id';
        return $resource(resourceUrl, {}, {
            "pageSelect": {
                method: "POST",
                params: {
                    path: "pageSelect"
                }
            },
            "pageSelectV2": {
                method: "POST",
                params: {
                    path: "pageSelectV2"
                }
            },
        });
    }
    function FrameConfigs ($resource) {
        var resourceUrl =  'manager/' + 'api/frame-configs/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition3': {
                method: 'POST',
                params: {
                    path: 'byCondition3'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'uploadAble': {
                method:'GET',
                params: {
                    path: 'uploadAble'
                },
            },
            'update': { method:'PUT' }
        });
    }
    function Page ($resource) {
        var resourceUrl =  'api/pages/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    function Category ($resource) {
        var resourceUrl =  'manager/' + 'api/categories/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    function FontManagement ($resource) {
        var resourceUrl =  'manager/' + 'api/font-managements/:path/:id';

        return $resource(resourceUrl, {}, {
            "fontSupport2":{
                method:"POST",
                params:{
                    path:"font-support2"
                }
            },
            "fontSupport":{
                method:"GET",
                params:{
                    path:"font-support"
                }
            },
            "getDefaultShow":{
                method:"GET",
                params:{
                    path:"getDefaultShow"
                }
            },
            "getCss": {
                method: "GET",
                params: {
                    path: "getCss2"
                }
            },
            "getAll": {
                method: "GET",
                params: {
                    path: "getAllForClient"
                },
                isArray:true
            },
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    function FontXmlsManger($resource) {
        var resourceUrl = 'manager/' + 'api/font-xmls/:path/:id';

        return $resource(resourceUrl, {}, {
            "save": {
                method: 'POST'
            },
            "delete": {
                method: 'DELETE'
            },
            "release": {
                method: 'POST',
                params:{
                    path:"release"
                },
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "getAll":{
                method:"POST",
                params:{
                    path:"getAll"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'onlyRelease': {
                method: 'GET',
                params:{
                    path:"onlyRelease"
                },
            },
            'share': {
                method: 'GET',
                params:{
                    path:"share"
                },
            },
            'auditing': {
                method: 'GET',
                params:{
                    path:"auditing"
                },
            },
        });
    }
    function FontXmlsFileServes($resource) {
        var resourceUrl = 'fileserves/' + 'api/font-xmls/:path/:id';

        return $resource(resourceUrl, {}, {
            "save": {
                method: 'POST'
            },
            "delete": {
                method: 'DELETE'
            },
            "release": {
                method: 'POST',
                params:{
                    path:"release"
                },
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                }
            },
            "getAll":{
                method:"POST",
                params:{
                    path:"getAll"
                }
            },
            "getAll2":{
                method:"POST",
                params:{
                    path:"getAll2"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'onlyRelease': {
                method: 'GET',
                params:{
                    path:"onlyRelease"
                },
            },
            'share': {
                method: 'GET',
                params:{
                    path:"share"
                },
            },
            'auditing': {
                method: 'GET',
                params:{
                    path:"auditing"
                },
            },
        });
    }
    function ClipArt($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/clipArt/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            'byCondition3': {
                method: 'POST',
                params: {
                    path: 'byCondition3'
                }
            },
            'byCondition4': {
                method: 'POST',
                params: {
                    path: 'byCondition4'
                }
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                }
            },
            "updateType": {
                method: "GET",
                params: {
                    path: "updateType"
                }
            },
            'get': {
                method: 'GET',
                params: {
                    path: 'getone'
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                params: {
                    path: 'update'
                },
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                params: {
                    path: 'save'
                },
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'batchCreate':{
                method: 'GET',
                params: {
                    path: 'batchCreate'
                }
            },
            'delete': {
                method: 'DELETE',
                params: {
                    path: 'delete'
                }
            }
        });
    }
    function Backgrounds($resource) {
        var service = $resource('manager/api/backgrounds/:path/:id', {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'getAll'
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                }
            },
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
        return service;
    }
    function Material ($resource) {
        var resourceUrl =  'manager/' + 'api/materials/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                }
            },
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getAll': {
                method: 'POST',
                params: {
                    path: 'getAll'
                }
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                }
            }
        });
    }
    function Article($resource) {
        var resourceUrl = 'service/' + 'api/articles/:path/:id';

        return $resource(resourceUrl, {}, {
            "updateMusic":{
                method:"GET",
                params:{
                    path:"updateMusic"
                }
            },
            "standardCreate":{
                method:"GET",
                params:{
                    path:"standardCreate"
                }
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                }
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                }
            },
            "updateName": {
                method: "GET",
                params: {
                    path: "updateName"
                },
            },
            'query': {method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getByUser': {
                method: 'GET',
                params: {
                    path: 'getByUser'
                }
            },
            'update': {method: 'PUT'},
            'delete': {
                method: 'DELETE'
            },
            'moveFolder': {
                method: "GET",
                params: {
                    path: "moveFolder"
                },
            },
            'batchDelete': {
                method: 'GET',
                params: {
                    path: "batchDelete"
                }
            },
            "getPhonePicToken":{
                method: 'GET',
                params: {
                    path: 'getPhonePicToken'
                },
            },
            'shareCallBack':{
                method: 'GET',
                params: {
                    path: "shareCallBack"
                }
            },
            'getToken': {
                method: 'GET',
                params: {
                    path: 'getToken'
                }
            }
        });
    }
    function Music($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/music/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            'allByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                }
            },
            'update': {
                method: 'GET',
                params: {
                    path: 'update'
                }
            },
            'save': {
                method: 'POST'
            },
            'get': {
                method: 'GET'
            },
            'del': {
                method: 'DELETE'
            }
        });
    }
    function XmlToImg ($resource) {
        var resourceUrl = 'justify/' + 'api/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                params: {
                    path: 'xmlToImg'
                }
            },
            'documentToImg': {
                method: 'POST',
                params: {
                    path: 'documentToImg'
                }
            }
        });
    }

    function StoreWorkEditConfig($resource) {
        var resourceUrl = 'manager/' + 'api/store-work-edit-configs/:path/:id';

        return $resource(resourceUrl, {}, {
            'getCurrent': {
                method: 'GET',
                params: {
                    path: "current"
                },
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'update': {
                method: 'PUT',
            },
            'storeId': {
                method: 'GET',
                params: {
                    path: "storeId"
                },
            }
        });
    }
    function CalendarService($resource) {
        var resourceUrl = 'manager/api/calendar-service/:path';
        return $resource(resourceUrl, {}, {
            "getYear":{
                method:"GET",
                params:{
                    path:"getYear"
                },
                isArray:true
            },
            "getYears":{
                method:"GET",
                params:{
                    path:"getYears"
                },
                isArray:true
            },
            "getMonth":{
                method:"GET",
                params:{
                    path:"getMonth"
                }
            },
            'getMonthsOfYear': {
                method: 'GET',
                params:{
                    path:'getMonthsOfYear'
                }
            },
            'getDate': {
                method: 'GET',
                params:{
                    path:'getDate'
                }
            },
            'getDatesOfMonth': {
                method: 'GET',
                params:{
                    path:'getDatesOfMonth'
                }
            },
            'getDatesOfTable': {
                method: 'GET',
                params:{
                    path:'getDatesOfTable'
                }
            },
            'getWeekday': {
                method: 'GET',
                params:{
                    path:'getWeekday'
                }
            },
            'getWeekdays': {
                method: 'GET',
                params:{
                    path:'getWeekdays'
                }
            },
            'queryYearMonthSet': {
                method: "POST",
                params: {
                    path: "queryYearMonthSet"
                }
            },
            'queryWeekdaysSet': {
                method: "POST",
                params: {
                    path: "queryWeekdaysSet"
                }
            },
            'queryMonthdaysSet': {
                method: "POST",
                params: {
                    path: "queryMonthdaysSet"
                }
            },
            'queryMonthTable': {
                method: "POST",
                params: {
                    path: "queryMonthTable"
                }
            }
        });
    }
})();
