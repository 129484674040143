(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('VariableDataController', VariableDataController);

    VariableDataController.$inject = ['$scope', 'Category', '$uibModalInstance', 'MessageService','entity'];

    function VariableDataController( $scope, Category, $uibModalInstance, MessageService,entity) {

        var vm = this;
        vm.data = entity.data;
        console.log(entity)
        console.log(vm.data)

        vm.clear = clear;


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }


    }

})();
