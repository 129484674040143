(function () {
    'use strict';

    var artOperation = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        controllerAs: '$scope',
        bindings: {
            document: '=',
            template: '=',
            arrI: '=',
            pageI: '=',
            currentPage: '=',
            ctrlKeyArray: '=',
            selectBox: '=',
            hasText: '=',
            fontOn: '=',
            fontDocOn: '=',
            isReplaceShow: '=',
        },

        controller: ['$scope', 'ImageService', '$q', 'Qinius', 'Store', '$http', '$rootScope', 'Article', 'FontManagement', '$state',
            function ($scope, ImageService, $q, Qinius, Store, $http, $rootScope, Article, FontManagement, $state) {
                var vm = this;
                var drag = false;
                var dragArt = {};
                console.log($scope)
                $scope.testt = function(){
                    console.log("-----------")
                }

                // 素材鼠标事件
                window.moveArtStart = function (e) {
                    fn(e);
                    if(vm.fontOn||vm.fontDocOn){
                        return
                    }
                    vm.hasText = false;
                    $scope.$parent.hasImgUrl = false;
                    var targ = e.target ? e.target : e.srcElement;
                    var imgIndex = targ.dataset.index || vm.template.levelI;
                    vm.arrI = targ.dataset.arrindex;
                    vm.template.levelI = imgIndex;

                    var page = vm.document.pages[vm.pageI];
                    if (vm.arrI == 1) {
                        vm.currentPage = page.arr;
                    } else {
                        vm.currentPage = page.arr2;
                    }

                    var imageart = vm.currentPage.levels.level[imgIndex].imageart;
                    getChooseBox(imageart, 'imageart');
                    $scope.$parent.removeContented()

                    if (imageart.lock || !imageart.movable) {
                        return
                    }

                    dragArt.clientX = e.clientX || 0;
                    dragArt.clientY = e.clientY || 0;
                    dragArt.coordX = targ.offsetParent.offsetParent.offsetLeft;
                    dragArt.coordY = targ.offsetParent.offsetParent.offsetTop;
                    dragArt.slideW = $(".slide" + vm.arrI + vm.pageI).width();
                    dragArt.slideH = $(".slide" + vm.arrI + vm.pageI).height();
                    drag = true;
                    var all = getAllCtrlKeyArray();
                    if (all.length) {
                        dragArt.ctrlKey = true;
                    } else {
                        dragArt.ctrlKey = false;
                    }
                    document.addEventListener("mousemove", moveArtOn, true);
                    document.addEventListener("mouseup", moveArtEnd, true);
                };

                //move事件
                function moveArtOn(e) {
                    fn(e);
                    if (e.target.nodeName == 'SPAN') {
                        return;
                    }
                    if (drag) {
                        var currentPage = vm.currentPage;
                        var page = vm.document.pages[vm.pageI];
                        var pressArt = currentPage.levels.level[vm.template.levelI].imageart;
                        var clientX = e.clientX - dragArt.clientX;
                        var clientY = e.clientY - dragArt.clientY;

                        var imgOffsetX = (dragArt.coordX + clientX);
                        var imgOffsetY = (dragArt.coordY + clientY);
                        var imgWidth = pressArt.geometry.width * currentPage.pageScaleW * page.scale;
                        var imgHeight = pressArt.geometry.height * currentPage.pageScaleH * page.scale;
                        var x = currentPage.editbox.x * currentPage.pageScaleW * page.scale;
                        var y = currentPage.editbox.y * currentPage.pageScaleW * page.scale;

                        // $(".move-tip").hide();
                        //中心点
                        var xcenter = imgWidth / 2 + imgOffsetX;
                        var ycenter = imgHeight / 2 + imgOffsetY;

                        var artx = 0, arty = 0;

                        if (pressArt.rotation.angle == 0) {

                            // if (imgOffsetX <= 0) {
                            //     imgOffsetX = 0;
                            // }
                            //
                            // if (imgOffsetX <= x || imgOffsetY <= y) {
                            //     $(".move-tip").show();
                            // }
                            // if (imgOffsetY >= (dragArt.slideH - imgHeight - y)) {
                            //     $(".move-tip").show();
                            // }
                            //
                            // if (imgOffsetX >= dragArt.slideW - imgWidth) {
                            //     imgOffsetX = dragArt.slideW - imgWidth;
                            // }
                            // if (imgOffsetX >= dragArt.slideW - imgWidth - x) {
                            //     $(".move-tip").show();
                            // }
                            //
                            // if (imgOffsetY <= 0) {
                            //     imgOffsetY = 0;
                            // }
                            //
                            // if (imgOffsetY >= (dragArt.slideH - imgHeight)) {
                            //     imgOffsetY = dragArt.slideH - imgHeight;
                            // }
                            artx = imgOffsetX / currentPage.pageScaleW / page.scale;
                            arty = imgOffsetY / currentPage.pageScaleH / page.scale
                        } else {
                            // if (xcenter <= 0) {
                            //     xcenter = 0;
                            // }
                            //
                            // if (xcenter >= dragArt.slideW) {
                            //     xcenter = dragArt.slideW;
                            // }
                            //
                            // if (ycenter <= 0) {
                            //     ycenter = 0;
                            // }
                            //
                            // if (ycenter >= dragArt.slideH) {
                            //     ycenter = dragArt.slideH;
                            // }
                            artx = (xcenter - imgWidth / 2) / currentPage.pageScaleW / page.scale;
                            arty = (ycenter - imgHeight / 2) / currentPage.pageScaleH / page.scale;
                        }

                        if (dragArt.ctrlKey) {
                            moveTogether(currentPage, clientX, clientY,dragArt);
                        } else {

                            pressArt.geometry.x = artx;
                            pressArt.geometry.y = arty;

                        }

                        $scope.$apply();

                    }
                    return false;
                }

                //鼠标松开事件
                function moveArtEnd() {
                    if (dragArt.ctrlKey) {
                        moveTogetherEnd();
                    }
                    drag = false;
                    dragArt = {};
                    $(".move-tip").hide();
                    uploadRightPlanel();
                    $scope.$parent.updateHistory();
                    document.removeEventListener("mousemove", moveArtOn, true);
                    document.removeEventListener("mouseup", moveArtEnd, true);
                    $scope.$digest();
                }

                function uploadRightPlanel() {
                    // $scope.template.pages.page[$scope.currentPage.seq - 1] = angular.copy($scope.currentPage);
                    var p = [];
                    angular.forEach(vm.document.pages, function (item, index) {
                        p.push(item.arr);
                        if (item.arr2) {
                            p.push(item.arr2);
                        }
                    });
                    vm.template.pages.page = p;
                }

                function getChooseBox(box, attr) {
                    vm.selectBox = box;
                    vm.selectBox.current = attr;
                    hideChangeBtn();
                }

                function hideChangeBtn() {
                    $(".change-btn").hide();
                    vm.isReplaceShow = true;
                }

                function getAllCtrlKeyArray() {
                    var imageboxes = vm.ctrlKeyArray.imageboxes,
                        textboxes = vm.ctrlKeyArray.textboxes,
                        imagearts = vm.ctrlKeyArray.imagearts,
                        all = [];
                    var barcode = vm.ctrlKeyArray.barcode;
                    //选中的占位框
                    for (var i = 0; i < imageboxes.length; i++) {
                        if (imageboxes[i]) {
                            all.push(imageboxes[i]);
                        }
                    }
                    //选中的文本框
                    for (var i = 0; i < textboxes.length; i++) {
                        if (textboxes[i]) {
                            all.push(textboxes[i]);
                        }
                    }
                    //选中的素材
                    for (var i = 0; i < imagearts.length; i++) {
                        if (imagearts[i]) {
                            all.push(imagearts[i]);
                        }
                    }
                    //选中的条码
                    for (var i = 0; i < barcode.length; i++) {
                        all.push(barcode[i]);
                    }
                    return all;
                }

                function moveTogether(currentPage, clientX, clientY,dragbox) {
                    var page = vm.document.pages[vm.pageI];
                    var imgWidth = 0, imgHeight = 0, x = 0, y = 0, pro = currentPage.pageScaleW * page.scale;
                    angular.forEach(vm.ctrlKeyArray.imageboxes, function (imagebox, i) {
                        // 当前页所有占位框
                        var box = currentPage.levels.level[imagebox.levelI].imagebox;
                        boxSetting(box, imagebox);
                    })

                    angular.forEach(vm.ctrlKeyArray.imagearts, function (imageart, i) {
                        // 当前页所有素材
                        var box = currentPage.levels.level[imageart.levelI].imageart;
                        boxSetting(box, imageart);
                    })

                    angular.forEach(vm.ctrlKeyArray.textboxes, function (textbox, i) {
                        // 当前页所有文字
                        var box = currentPage.levels.level[textbox.levelI].textbox;
                        boxSetting(box, textbox);
                    })

                    angular.forEach(vm.ctrlKeyArray.textdocuments, function (textdocument, i) {
                        // 当前页所有文字
                        var box = currentPage.levels.level[textdocument.levelI].textdocument;
                        boxSetting(box, textdocument);
                    })

                    function boxSetting(box, CtrlKeyBox) {
                        imgWidth = box.geometry.width * pro;
                        imgHeight = box.geometry.height * pro;
                        x = CtrlKeyBox.geometry.x * pro + clientX;
                        y = CtrlKeyBox.geometry.y * pro + clientY;
                        if (x <= 0) {
                            x = 0;
                        }
                        if (x >= (dragbox.slideW - imgWidth)) {
                            x = dragbox.slideW - imgWidth;
                        }
                        if (y <= 0) {
                            y = 0;
                        }

                        if (y >= (dragbox.slideH - imgHeight)) {
                            y = dragbox.slideH - imgHeight;
                        }
                        box.geometry.x = x / pro
                        box.geometry.y = y / pro
                    }
                }

                function moveTogetherEnd() {
                    angular.forEach(vm.ctrlKeyArray.imageboxes, function (imagebox, i) {
                        // 当前页所有占位框
                        var box = vm.currentPage.levels.level[imagebox.levelI].imagebox;
                        ctrlKeySetting(box, imagebox);
                    })

                    angular.forEach(vm.ctrlKeyArray.imagearts, function (imageart, i) {
                        // 当前页所有素材
                        var box = vm.currentPage.levels.level[imageart.levelI].imageart;
                        ctrlKeySetting(box, imageart);
                    })

                    angular.forEach(vm.ctrlKeyArray.textboxes, function (textbox, i) {
                        // 当前页所有文字
                        var box = vm.currentPage.levels.level[textbox.levelI].textbox;
                        ctrlKeySetting(box, textbox);
                    })

                    angular.forEach(vm.ctrlKeyArray.textdocuments, function (textdocument, i) {
                        // 当前页所有文字
                        var box = vm.currentPage.levels.level[textdocument.levelI].textdocument;
                        ctrlKeySetting(box, textdocument);
                    })

                    function ctrlKeySetting(box, CtrlKeyBox) {
                        CtrlKeyBox.geometry.x = box.geometry.x;
                        CtrlKeyBox.geometry.y = box.geometry.y;
                    }
                }

                //防止事件冒泡，默认事件
                function fn(e) {
                    // e = e || window.event;
                    // e.cancelBubble = true;
                    // e.stopPropagation();
                    // e.returnValue = false;
                    // e.preventDefault();
                    if (e && e.stopPropagation) {
                        // 因此它支持W3C的stopPropagation()方法
                        e.stopPropagation();
                    } else {
                        // 否则，我们需要使用IE的方式来取消事件冒泡
                        window.event.cancelBubble = true;
                    }

                    // 阻止默认浏览器动作(W3C)
                    if (e && e.preventDefault) {
                        e.preventDefault();
                    } else {
                        // IE中阻止函数器默认动作的方式
                        window.event.returnValue = false;
                    }
                }
            }],
        link:function () {

        }
    };

    angular
        .module('editorApp')
        .component('artOperation', artOperation);
})();
