(function () {
    'use strict';

    var TextboxEcControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/textbox-ec.html',
        controllerAs: 'vm',
        bindings: {
            fontList:'<',
            type:'<',
            textboxSelect:'<',
            pageData:'=',
            pageIndex:'=',
            levelIndex:'=',
            onAdd:'&',
            onEdit:'&',
            onDelete:'&',
        },

        controller: ['$scope','ModulesService','$timeout', '$http', '$translate', 'MessageService','FontManagement',
            function ($scope,ModulesService,$timeout,$http, $translate, MessageService,FontManagement) {
            var vm = this;
            vm.fontItem = null;
            vm.$onChanges = function (data){
                console.log(data)
                vm.type = "";
                vm.textboxSelect = false;
                vm.textStyleShow = false;
                if(data.fontList){
                    vm.fontList = data.fontList.currentValue;
                }
                if(vm.fontList && vm.fontList.length>0){
                    vm.fontItem = vm.fontList[0];
                    vm.fontList.forEach(function (item){
                        if(item.defaultShow){
                            vm.fontItem = item;
                        }
                    })
                }
                if(data.pageData){
                    vm.pageData = data.pageData.currentValue;
                }
                if(data.pageIndex){
                    vm.pageIndex = data.pageIndex.currentValue;
                }
                if(data.levelIndex){
                    vm.levelIndex = data.levelIndex.currentValue;
                }
                if(data.type){
                    vm.type = data.type.currentValue;
                }
                if(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex]&&vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox){
                    vm.textboxSelect = true;
                }
                if(vm.type == 'add'){
                    $scope.fontShow();
                }
            }

            $scope.colorList = ['#ff001e', '#ff5c00', '#ff9700', '#61734d', '#838c97', '#8d4ac8', '#b8e986', '#7e6565', '#ffffff', '#f1e7d9', '#b5ce99', '#82c281', '#dac2ef', '#000', '#797979', '#7c330a', '#eec78d', '#444444', '#589ceb', '#4a7bc8', '#ddabab'];
            $scope.selectedColor = $scope.colorList[0];

            vm.textboxSelect = false;
            vm.textStyleTab = [
                {name: $translate.instant("font.textStyleTabFont"), id: 'textStyleTabFont'},
                {name: $translate.instant("font.textStyleTabSize"), id: 'textStyleTabSize'},
                {name: $translate.instant("font.textStyleTabType"), id: 'textStyleTabType'},
                {name: $translate.instant("font.textStyleTabColor"), id: 'textStyleTabColor'},
            ];
            vm.textStyleItem = 'textStyleTabFont';
            //添加文字
            $scope.fontShow = function () {
                vm.editbox = true;
                vm.fontTab = 'fontColor';
                $scope.selectedColor = $scope.colorList[0];
                vm.fontData = {
                    seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                    textbox: {
                        type: 'add',
                        geometry: {
                            x: '0',
                            y: '0',
                            width: 100,
                            height: 20
                        },
                        rotation: {
                            angle: '0'
                        },
                        text: '',
                        style: {
                            font: "",
                            size: 20,
                            color: $scope.selectedColor,
                            bold: false,
                            italic: false,
                            lineheight: 20,
                            letterspacing: 0,
                            align: 'left',
                            writingmode:'horizontal-tb'
                        },
                        fontuuid: vm.fontItem.uuid,
                        identifier: "",
                        editable: true,
                        movable: true,
                        lock: false,
                        version: '2.0',
                        transparency: 1,
                        useradded: true
                    }
                }
                var _fontStyle = [];
                _fontStyle.push(vm.fontItem.style)
                if (_fontStyle.indexOf('NORMAL') != '-1') {
                    vm.fontData.textbox.style.bold = false;
                    vm.fontData.textbox.style.italic = false;
                } else {
                    if (_fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                        vm.fontData.textbox.style.bold = true;
                        vm.fontData.textbox.style.italic = true;
                    } else {
                        if (_fontStyle.indexOf('ITALIC') != '-1') {
                            vm.fontData.textbox.style.italic = true;
                            vm.fontData.textbox.style.bold = false;
                        }
                        if (_fontStyle.indexOf('BOLD') != '-1') {
                            vm.fontData.textbox.style.bold = true;
                            vm.fontData.textbox.style.italic = false;
                        }
                    }
                }
                vm.text = '';
                $timeout(function () {
                    $('textarea.three-font-body-input').focus()
                },100);
            };
            //切换字体
            $scope.updateTextFont = function (data) {
                if (data == undefined) {
                    threeInfo($translate.instant("font.failedAddText"));
                    return
                }
                if (!data.enable) {
                    threeInfo($translate.instant("font.fontChange"));
                    return
                }
                var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
                vm.fontStyle = [];
                for (var i = 0; i < vm.fontList.length; i++) {
                    if (vm.fontList[i].uuid == data.uuid) {
                        for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                            vm.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                        }
                    }
                }
                text.style.bold = false;
                text.style.italic = false;
                if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                    text.style.bold = false;
                    text.style.italic = false;
                } else {
                    if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                        text.style.bold = true;
                        text.style.italic = true;
                    } else {
                        if (vm.fontStyle.indexOf('ITALIC') != '-1') {
                            text.style.italic = true;
                        }
                        if (vm.fontStyle.indexOf('BOLD') != '-1') {
                            text.style.bold = true;
                        }
                    }
                }
                text.style.font = data.family;
                text.fontuuid = data.uuid;
                text.identifier = $scope.domain + data.fontStyles[0].identifier;
                text.loading = true;
                getFontCSS(FontManagement, text, $scope);
                $timeout(function () {
                    sizeHeight();
                    fontStyle(data.uuid);
                })
            };

            function fontStyle(fontuuid) {
                var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
                vm.fontStyle = [];
                for (var i = 0; i < vm.fontList.length; i++) {
                    if (vm.fontList[i].uuid == fontuuid) {
                        for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                            vm.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                        }
                    }
                }
                text.style.bold1 = false;
                text.style.italic1 = false;
                if (vm.fontStyle.indexOf('NORMAL') == '-1') {
                    text.style.normal = false;
                } else {
                    text.style.normal = true;
                }
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    text.style.bold1 = true;
                    text.style.italic1 = true;
                } else {
                    if (vm.fontStyle.indexOf('ITALIC') != '-1') {
                        text.style.italic1 = true;
                    }
                    if (vm.fontStyle.indexOf('BOLD') != '-1') {
                        text.style.bold1 = true;
                    }
                }
            }

            window.densityInput = function (e) {
                var val = $('#rangeChange').val();
                if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.size = parseInt(val);
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.lineheight = parseInt(val);
                }

                var currentPage = vm.pageData[vm.pageIndex].levels.level;
                var item = currentPage[vm.levelIndex].textbox;

                if (item.geometry.width < parseInt(item.style.size)) {
                    item.geometry.width = (Number(parseInt(item.style.size)) + Number(5));
                }
                if (item.geometry.width > vm.pageData[vm.pageIndex].editbox.width) {
                    item.geometry.width = vm.pageData[vm.pageIndex].editbox.width;
                    // item.geometry.x = 0;
                }
                sizeHeight1();
                $scope.$digest();
            }
            window.textStyleTransparency = function (e) {
                var val = $('#textStyleTransparency').val();
                if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.transparency = parseInt(val) / 100;
                    vm.transparency = parseInt(val);
                }
                sizeHeight1();
                $scope.$digest();
            }
            //斜体
            $scope.operationItalic = function () {
                var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
                if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                    text.style.italic = !text.style.italic;
                    $timeout(function () {
                        sizeHeight();
                    }, 200)
                } else {
                    if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.fontStyle.indexOf('BOLD') != '-1') {
                        if (text.style.bold) {
                            text.style.italic = !text.style.italic;
                            $timeout(function () {
                                sizeHeight();
                            }, 200)
                        }
                    }
                }
            }
            //粗体
            $scope.operationBold = function () {
                var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
                if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                    text.style.bold = !text.style.bold;
                    $timeout(function () {
                        sizeHeight();
                    }, 200)
                } else {
                    if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.fontStyle.indexOf('ITALIC') != '-1') {
                        if (text.style.italic) {
                            text.style.bold = !text.style.bold;
                            $timeout(function () {
                                sizeHeight();
                            }, 200)
                        }
                    }
                }
            }
            $scope.operationBarDir = function (item) {
                var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
                text.style.align = item;
            };
            $scope.fontColorCb = function (item) {
                var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
                text.style.color = item;
            }

            //判断是ios or Android
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

            var textFlag = true;
            var inputEle = document.getElementById('three-font-body-input');

            // 兼容安卓文字输入（首行空格不显示bug）
            $scope.changeText = function (e) {
                if (isAndroid == true && isiOS == false) {
                    fn(e);
                    var targ = e.currentTarget ? e.currentTarget : e.srcElement;
                    vm.text = vm.fontData.textbox.text = targ.value;
                }
            }

            /**
             * 兼容iOS文字输入
             */
            inputEle.addEventListener('compositionstart', function () {
                textFlag = false;
            });
            inputEle.addEventListener('compositionend', function (event) {
                textFlag = true;
                // compositionend 事件发生在 input 之后，故此需要此处调用 input 中逻辑
                if (isAndroid == false && isiOS == true) {
                    vm.text = event.target.value;
                }
            });
            inputEle.addEventListener('input', function (event) {
                // 忽略一切非直接输入，不做逻辑处理
                if (textFlag) {
                    if (isAndroid == false && isiOS == true) {
                        vm.text = event.target.value;
                    }
                };
            });

            $scope.savePage5 = function () {
                if (!vm.fontData.textbox.text) {
                    vm.editbox = false;
                    return
                }
                if(vm.text){
                    vm.fontData.textbox.text =  vm.text;
                }
                FontSupport2(FontManagement, vm.fontData.textbox, function (res) {
                    var msg = res.data;
                    if (res.status == 200) {
                        if (!msg.success) {
                            swal({
                                title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>" + $translate.instant("font.warmPrompt") + "</div>",
                                text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>" + $translate.instant("font.pleaseChangeFonts") + "</div>",
                                html: true,
                                confirmButtonText: $translate.instant("button.returnCorrection")
                            });
                        }else if (msg.success && !msg.supported) {
                            swal({
                                title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>" + $translate.instant("font.warmPrompt") + "</div>",
                                text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>" + $translate.instant("font.notSpecialCharacter") + "<br><span style='color:#FFA91B;font-size: 14px'>" + msg.failed + "</span></div>",
                                html: true,
                                confirmButtonText: $translate.instant("button.returnCorrection")
                            });
                        } else {
                            vm.editbox = false;
                            vm.fontData.textbox.style.color = $scope.selectedColor;
                            // vm.fontData.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                            // vm.fontData.textbox.geometry.height = (Number($("#c-xml-page-material-fontId").height()) + Number(10));
                            // vm.fontData.textbox.geometry.width = Number(($("#c-xml-page-material-fontId").width() * ( vm.windowDpi / 72) + Number(10)) / vm.pageData[vm.pageIndex].custom.ratio1 * (1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1 > 1 ? 1 : 1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1));
                            // vm.fontData.textbox.geometry.height = Number(($("#c-xml-page-material-fontId").height() * ( vm.windowDpi / 72) + Number(5)) / vm.pageData[vm.pageIndex].custom.ratio1) * (1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1 > 1 ? 1 : 1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1);
                            if (vm.fontData.textbox.type == 'add') {
                                vm.fontData.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                                vm.fontData.textbox.geometry.x = (vm.pageData[vm.pageIndex].editbox.width - vm.fontData.textbox.geometry.width) / 2 + vm.pageData[vm.pageIndex].editbox.x;
                                vm.fontData.textbox.geometry.y = (vm.pageData[vm.pageIndex].editbox.height - vm.fontData.textbox.geometry.height) / 2 + vm.pageData[vm.pageIndex].editbox.y;
                                vm.pageData[vm.pageIndex].levels.level.push(vm.fontData)
                                vm.levelIndex = vm.pageData[vm.pageIndex].levels.level.length-1;
                            }
                            if (vm.fontData.textbox.type == 'edit') {
                                // if (vm.fontData.textbox.geometry.width >= vm.pageData[vm.pageIndex].editbox.width) {
                                //     // vm.fontData.textbox.geometry.width = vm.pageData[vm.pageIndex].editbox.width;
                                //     vm.fontData.textbox.geometry.x = vm.pageData[vm.pageIndex].editbox.x;
                                // }
                                // if (vm.fontData.textbox.geometry.height >= vm.pageData[vm.pageIndex].editbox.height) {
                                //     // vm.fontData.textbox.geometry.height = vm.pageData[vm.pageIndex].editbox.height
                                //     vm.fontData.textbox.geometry.y = vm.pageData[vm.pageIndex].editbox.y;
                                // }
                                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] = vm.fontData;
                            }
                            sizeHeight1(vm.type)
                        }
                    }
                })
            };
            //删除文字
            $scope.deleteTextbox = function (e) {
                fn(e)
                if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                    vm.onDelete();
                }
            }
            $scope.editTextbox = function (e, index, item) {
                fn(e)
                vm.editbox = true;
                vm.fontData = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex];
                vm.fontData.textbox.type = 'edit';
                $scope.selectedColor = vm.fontData.textbox.style.color;
                vm.fontTab = 'fontColor';
                vm.text = vm.fontData.textbox.text;
                $timeout(function () {
                    $('textarea.three-font-body-input').focus()
                },100);
            }
            //文字样式
            $scope.textStyleTextbox = function () {
                vm.textStyleShow = true;
            }
            function sizeHeight() {
                $timeout(function () {
                    var _h = Number($('.pitch1').find('.c-xml-page-textboxe-text span').height()) + 5;
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h/vm.pageData[vm.pageIndex].custom.ratio1;
                    console.log(_h)
                    $scope.$digest();
                })
            }
            vm.sizeHeight1 = sizeHeight1;
            function sizeHeight1(type) {
                $timeout(function () {
                    var _h = Number($("#c-xml-page-material-fontId1 span").height()) + Number(10);
                    var _w = Number($("#c-xml-page-material-fontId1 span").width()) + Number(10);
                    var _sizeWidth = getTextWidthInternal(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.text, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.bold, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.italic, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.size, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.fontuuid);
                    if(!writingmodeInit(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                        vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h;
                        if(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width < vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.size){
                            vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.size;
                        }
                        if(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width < _sizeWidth){
                            item.geometry.width = (Number(_sizeWidth) + Number(5));
                            return;
                        }
                    }
                    if(writingmodeInit(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                        vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width = _w;
                        if(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height < _sizeWidth){
                            vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _sizeWidth;
                        }
                    }
                    if(type == 'add'){
                        vm.onAdd();
                    }
                })
            }
            //字体样式
            $scope.textStyleTabCb = function (item) {
                vm.textStyleItem = item.id;
            }
            $scope.textStyleHide = function () {
                vm.textStyleShow = false;
            }
        }]
    };

    angular.module('editorApp')
    	.component('textboxEc', TextboxEcControl);

})();
