(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductViewEcThreePreviewController', ProductViewEcThreePreviewController);

    ProductViewEcThreePreviewController.$inject = ['$scope', '$stateParams', 'entity', '$q', '$localStorage', 'Qinius',
        'ImageService', '$http', '$rootScope', 'Theme', 'ItemValue', 'PlatformWechatSetting', 'Store', 'FontManagement', '$translate'];
    function ProductViewEcThreePreviewController($scope, $stateParams, entity, $q, $localStorage, Qinius,
                                               ImageService, $http, $rootScope, Theme, ItemValue, PlatformWechatSetting, Store, FontManagement, $translate) {
        var vm = this;

        //风格切换  引入不同的css文件
        Theme.judge();
        vm.platformSetting = PlatformWechatSetting.get();
        var audio = document.getElementById("audio");
        // audio.src = "content/ihope.mp3";
        audio.crossOrigin = 'anonymous';

        var timer = null;
        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        vm.aid = $stateParams.aid;
        vm.article = entity.data;
        vm.reprint = 'LAR';
        $scope.dHeight = vm.dHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        $scope.dWidth = vm.dWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        $scope.document = {
            pages: [{}]
        };
        var artDoc = angular.copy(vm.article.document);

        $('.view2-article').on('click', 'touchstart', 'touchmove', function (event) {
            event.preventDefault();
        });
        /**
         * mm换算成px
         * @param mm
         * @returns {number}
         */
        var unitTransform = function (mm) {
            // return mm * 3;
            return mm / 25.4 * vm.windowDpi;
        };
        //dpi
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[0];
            // vm.windowDpi = 300;
        }

        var $li = $(".templateView2-carousel");
        $li.hide();
        $scope.domains = Qinius.getDomain({storeId: vm.storeId});
        vm.store = Store.get({id: vm.storeId});
        var $load = $(".loading-page");
        var $flagBox = $(".flag-box");
        $flagBox.hide();

        function Play() {
            audio.play().then(function () {
                $(".PanelRight").removeClass('hide1');
                timer = window.setInterval(begin, 10);
            }).catch(function (error) {
                console.log(error);
            });
        }

        var rot = 10 - 0;

        function begin() {
            var micon = document.getElementById("music-icon");
            if (micon == null) {
                window.clearInterval(timer);
                return;
            }
            micon.style.transform = "rotate(" + rot + "deg)";
            rot += 1;
        }

        $("#Play").on('click', function () {
            window.clearInterval(timer);
            if (audio.paused) {
                audio.play();
                Play();
                $("#Play").children("img").removeClass("play").addClass("pause");
            }
            else {
                $("#Play").children("img").removeClass("pause").addClass("play");
                audio.pause();
            }
        });

        function makeCode() {
            var qrcode = new QRCode(document.getElementById("qrcode"), {
                width: 100,
                height: 100
            });
            qrcode.makeCode(vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/index');
        }

        getAll(artDoc);
        function getAll(doc) {
            $q.all([$scope.domains.$promise, vm.article.$promise, vm.platformSetting.$promise, vm.store.$promise]).then(function () {
                if(vm.article.invalid){//作品失效
                    $state.go('articlesFailure',{storeId:vm.storeId});
                    return
                }
                vm.platformSetting = vm.platformSetting.data;
                vm.store = vm.store.data;
                if (vm.store.storeType2.indexOf("MINI") < 0) {
                    $scope.miniType = false;
                    // makeCode();
                } else {
                    $scope.miniType = true;
                    $http({
                        url: "/fileserves/api/download/getMiniQrCode?storeId=" + vm.storeId,
                        method: 'get',
                        responseType: 'blob'
                    }).success(function (res) {
                        if(res.status == 200){
                            var blob = new Blob([res.data], {
                                type: "image/jpeg"
                            });
                            $scope.image = URL.createObjectURL(blob);
                        }else {
                            MessageService.error(res.message);
                        }
                    })
                }
                $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                $scope.len = doc.pages.page.length;
                ItemValue.get({id: vm.article.itemValueId}, function (res) {
                    if(res.status == 200){
                        if (vm.article.musicIdentify) {
                            audio.src = $scope.domain + vm.article.musicIdentify;
                        } else {
                            if (res.data.musicIdentify) {
                                audio.src = $scope.domain + res.data.musicIdentify;
                            } else {
                                audio.src = '';
                            }
                        }
                    }else {
                        MessageService.error(res.message);
                    }
                });
                //取得xml文档内容
                vm.reprint = doc.reprint;
                localStorage.setItem('reprint', vm.reprint);
                // var arrUse = {
                //     pages: {
                //         page: []
                //     },
                //     scene: doc.scene
                // };
                // arrUse.scene.imgUrl = arrUse.scene.resource.identifier;
                // angular.forEach(doc.pages.page, function (page) {
                //     if (!page.backup) {
                //         arrUse.pages.page.push(page);
                //     }
                // });
                // $scope.getDetail(arrUse, 0);
                $scope.getDetail1(doc);

                // 自动播放音乐
                Play();
            });
        }

        /**
         * 赋值 --> 先制作文字 --> 文字 --> [有占位框(生成新数组-->占位框图片转canvas) || 无占位框(占位框图片转canvas)]
         * @param document
         * @param index
         */
        $scope.getDetail1 = function (document) {
            var windowRatio = 0.85;
            $scope.template = angular.copy(document);
            angular.forEach($scope.template.pages.page, function (page, index) {
                page.editbox = {
                    width: transitionPx(page.editbox.width),
                    height: transitionPx(page.editbox.height),
                    x: transitionPx(page.editbox.x),
                    y: transitionPx(page.editbox.y)
                };
                page.mediabox = {
                    width: transitionPx(page.mediabox.width),
                    height: transitionPx(page.mediabox.height),
                    x: transitionPx(page.mediabox.x),
                    y: transitionPx(page.mediabox.y)
                };
                page.trimbox = {
                    width: transitionPx(page.trimbox.width),
                    height: transitionPx(page.trimbox.height),
                    x: transitionPx(page.trimbox.x),
                    y: transitionPx(page.trimbox.y)
                };
                page.custom = {};
                if (page.scene) {
                    page.scene.geometry = {
                        width: transitionPx(page.scene.geometry.width),
                        height: transitionPx(page.scene.geometry.height),
                        x: transitionPx(page.scene.geometry.x),
                        y: transitionPx(page.scene.geometry.y)
                    };
                    page.scene.imagebox.geometry = {
                        width: transitionPx(page.scene.imagebox.geometry.width),
                        height: transitionPx(page.scene.imagebox.geometry.height),
                        x: transitionPx(page.scene.imagebox.geometry.x),
                        y: transitionPx(page.scene.imagebox.geometry.y)
                    };
                    if (page.scene.resource) {
                        getBackgroundSource(page.scene.resource, page.scene.geometry.width, page.scene.geometry.height, page.scene.resource);
                    }
                    page.custom.ratio = $scope.dWidth * windowRatio / page.scene.geometry.width;
                    page.custom.width = page.scene.geometry.width;
                    page.custom.height = page.scene.geometry.height;
                } else {
                    page.custom.ratio = $scope.dWidth * windowRatio / page.mediabox.width;
                    page.custom.width = page.mediabox.width;
                    page.custom.height = page.mediabox.height;
                }
                //条形码
                if (page.barcode) {
                    page.barcode.geometry = {
                        width: transitionPx(page.barcode.geometry.width),
                        height: transitionPx(page.barcode.geometry.height),
                        x: transitionPx(page.barcode.geometry.x),
                        y: transitionPx(page.barcode.geometry.y)
                    };
                }
                if (page.qrcode) {
                    page.qrcode.geometry = {
                        width: transitionPx(page.qrcode.geometry.width),
                        height: transitionPx(page.qrcode.geometry.height),
                        x: transitionPx(page.qrcode.geometry.x),
                        y: transitionPx(page.qrcode.geometry.y)
                    };
                }

                //背景
                if (page.background.resource.identifier) {
                    getBackgroundSource(page.background.resource, page.mediabox.width, page.mediabox.height, page.background.resource);
                    page.background.offsetx = transitionPx(page.background.offsetx);
                    page.background.offsety = transitionPx(page.background.offsety);
                }
                angular.forEach(page.levels.level, function (level, i) {
                    if (level.imageart) {
                        level.imageart.offsetx = transitionPx(level.imageart.offsetx);
                        level.imageart.offsety = transitionPx(level.imageart.offsety);
                        level.imageart.rotation.angle = -level.imageart.rotation.angle;
                        level.imageart.geometry = {
                            width: transitionPx(level.imageart.geometry.width),
                            height: transitionPx(level.imageart.geometry.height),
                            x: transitionPx(level.imageart.geometry.x),
                            y: transitionPx(level.imageart.geometry.y)
                        };
                        if (level.imageart.resource.identifier) {
                            getBackgroundSource(level.imageart.resource, level.imageart.geometry.width, level.imageart.geometry.height, level.imageart.resource);
                        }
                    }
                    if (level.imagebox) {
                        level.imagebox.geometry = {
                            width: transitionPx(level.imagebox.geometry.width),
                            height: transitionPx(level.imagebox.geometry.height),
                            x: transitionPx(level.imagebox.geometry.x),
                            y: transitionPx(level.imagebox.geometry.y)
                        };
                        level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                        level.imagebox.bokehtypes = level.imagebox.bokehtype;
                        if (level.imagebox.image) {
                            level.imagebox.image.offsetx = transitionPx(level.imagebox.image.offsetx);
                            level.imagebox.image.offsety = transitionPx(level.imagebox.image.offsety);
                            if (level.imagebox.image.resource.identifier) {
                                getBackgroundSource1(level.imagebox.image.resource, level.imagebox.geometry.width, level.imagebox.geometry.height, level.imagebox.image, level.imagebox.bokehtypes)
                            }
                        }
                        if (level.imagebox.blurredrectangle) {
                            level.imagebox.blurredrectangle = {
                                width: transitionPx(level.imagebox.blurredrectangle.width),
                                height: transitionPx(level.imagebox.blurredrectangle.height),
                                x: transitionPx(level.imagebox.blurredrectangle.x),
                                y: transitionPx(level.imagebox.blurredrectangle.y)
                            };
                        }
                    }
                    if (level.textbox) {
                        level.textbox.rotation.angle = -level.textbox.rotation.angle;
                        level.textbox.geometry = {
                            width: transitionPx(level.textbox.geometry.width),
                            height: transitionPx(level.textbox.geometry.height),
                            x: transitionPx(level.textbox.geometry.x),
                            y: transitionPx(level.textbox.geometry.y)
                        };
                        if (level.textbox.fontuuid) {
                            //下载字体
                            getFont(level.textbox.fontuuid)
                        }
                        if (level.textbox.style.bold && level.textbox.style.italic) {
                            level.textbox.styles = 'BOLD_ITALIC';
                        } else if (level.textbox.style.bold) {
                            level.textbox.styles = 'BOLD';
                        } else if (level.textbox.style.italic) {
                            level.textbox.styles = 'ITALIC';
                        } else {
                            level.textbox.styles = 'NORMAL';
                        }
                        if (level.textbox.style.bold && level.textbox.style.italic) {
                            level.textbox.styles = 'BOLD_ITALIC';
                        } else if (level.textbox.style.bold) {
                            level.textbox.styles = 'BOLD';
                        } else if (level.textbox.style.italic) {
                            level.textbox.styles = 'ITALIC';
                        } else {
                            level.textbox.styles = 'NORMAL';
                        }
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        var textdocument = angular.copy(level.textdocument);
                        level.textdocument = {
                            background: textdocument.background,
                            geometry: {
                                width: transitionPx(textdocument.geometry.width),
                                height: transitionPx(textdocument.geometry.height),
                                x: transitionPx(textdocument.geometry.x),
                                y: transitionPx(textdocument.geometry.y)
                            },
                            rotation: {
                                angle: -textdocument.rotation.angle || 0
                            },
                            current: 'textdocument',
                            levels: angular.copy(textdocument.levels),
                            lock: true,
                            transparency: textdocument.transparency,
                            movable: textdocument.movable,
                            useradded: textdocument.useradded,
                            editable: textdocument.editable,
                            scale: textdocument.geometry.width / page.mediabox.width
                        }

                        var backg = textdocument.background;
                        var pageW = textdocument.geometry.width,
                            pageH = textdocument.geometry.height;
                        if (backg.resource.identifier) {
                            getBackgroundSource(backg.resource, pageW, pageH, backg);
                            backg.offsetx = transitionPx(backg.offsetx);
                            backg.offsety = transitionPx(backg.offsety);
                        }

                        angular.forEach(textdocument.levels.level, function (lev, l) {
                            if (lev.imageart && lev.imageart.geometry) {
                                var art = lev.imageart;
                                $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                    level.textdocument.levels.level[l].imageart = {
                                        geometry: {
                                            width: transitionPx(art.geometry.width),
                                            height: transitionPx(art.geometry.height),
                                            x: transitionPx(art.geometry.x),
                                            y: transitionPx(art.geometry.y)
                                        },
                                        rotation: angular.copy(art.rotation),
                                        type: art.type,
                                        resource: {
                                            identifier: art.resource.identifier,
                                            provider: art.resource.provider,
                                            width: data.width,
                                            height: data.height
                                        },
                                        scale: art.scale,
                                        offsetx: transitionPx(art.offsetx),
                                        offsety: transitionPx(art.offsety),
                                        current: 'imageart',
                                        lock: false,
                                        transparency: art.transparency,
                                        onlyshow: art.onlyshow,
                                        movable: art.movable,
                                        useradded: art.useradded,
                                        editable: art.editable,
                                    }
                                }).error(function (error) {
                                });
                            }
                            if (lev.textbox && lev.textbox.geometry) {
                                var text = lev.textbox;
                                level.textdocument.levels.level[l].textbox = {
                                    geometry: {
                                        width: transitionPx(text.geometry.width),
                                        height: transitionPx(text.geometry.height),
                                        x: transitionPx(text.geometry.x),
                                        y: transitionPx(text.geometry.y)
                                    },
                                    identifier: text.identifier,
                                    rotation: angular.copy(text.rotation),
                                    angle: angular.copy(text.angle),
                                    status: angular.copy(text.status),
                                    style: angular.copy(text.style),
                                    type: angular.copy(text.type),
                                    text: angular.copy(text.text),
                                    fontStyle: [{
                                        style: '',
                                        path: text.identifier
                                    }],
                                    current: 'textbox',
                                    lock: true,
                                    transparency: text.transparency,
                                    fontuuid: text.fontuuid,
                                    version: text.version
                                }
                                getFont(text.fontuuid)
                            }
                        })
                    }
                })
            })
            console.log($scope.template)
            showPage()
        }
        $scope.getDetail = function (document, index) {
            var obj = document.pages.page[index];
            $scope.scene = angular.copy(document.scene);

            $scope.template = document;
            var bgIdentifier = 'content/images/white.png';
            if (obj.background && obj.background.resource && obj.background.resource.identifier) {
                bgIdentifier = obj.background.resource.identifier + '?imageMogr2/thumbnail/!40p';
            }

            $scope.template.pages.page[index] = {
                bgImg: bgIdentifier,
                background: angular.copy(obj.background),
                barcode: angular.copy(obj.barcode),
                barcodeM: angular.copy(obj.barcode),
                qrcode: angular.copy(obj.qrcode),
                qrcodeM: angular.copy(obj.qrcode),
                barcodeRotation: {
                    angle: obj.barcodeRotation ? obj.barcodeRotation.angle : 0
                },
                mediabox: angular.copy(obj.mediabox),
                trimbox: angular.copy(obj.trimbox),
                textboxes: angular.copy(obj.textboxes),
                imageboxes: angular.copy(obj.imageboxes),
                levels: angular.copy(obj.levels),
                spread: obj.spread,
                centerrotation: obj.centerrotation ? true : false
            };

            $scope.scene = $scope.template.scene;
            $scope.scene.geometry = setGeometry($scope.scene.geometry);
            $scope.scene.imagebox.geometry = setGeometry1($scope.scene.imagebox.geometry);

            unitTransform($scope.template.pages.page[0].mediabox.width) * ($scope.dWidth - 45) / unitTransform($scope.template.pages.page[0].mediabox.width)
            //摆台
            var w0 = $scope.dWidth - 45,
                h0 = ($scope.dWidth - 45) * unitTransform($scope.template.pages.page[0].mediabox.height) / unitTransform($scope.template.pages.page[0].mediabox.width),
                w1 = $scope.scene.imagebox.geometry.width,
                h1 = $scope.scene.imagebox.geometry.height;
            if ((w0 <= h0 || w1 <= h1) && (w0 / h0 < w1 / h1) || w0 >= h0 && w1 >= h1 && (w0 / h0 < w1 / h1)) {
                $scope.imageboxConH = h1;
                $scope.imageboxConW = h1 * w0 / h0;
                $scope.proportion = h0 / h1;
            } else {
                $scope.imageboxConW = w1;
                $scope.imageboxConH = w1 * h0 / w0;
                $scope.proportion = w0 / w1;
            }
            $scope.marginLeft = (w1 - $scope.imageboxConW) / 2;
            $scope.marginTop = (h1 - $scope.imageboxConH) / 2;


            var temp = $scope.template.pages.page[index];
            temp.styleScale = ((vm.dWidth - 45) / (unitTransform(obj.mediabox.width))) / $scope.proportion;
            //背景图片
            var w = temp.width * temp.styleScale,
                h = temp.height * temp.styleScale;
            if (temp.background.resource) {
                getBackgroundSource(temp.background.resource, w, h, temp.background);
                temp.background.offsetx = temp.background.offsetx * temp.styleScale;
                temp.background.offsety = temp.background.offsety * temp.styleScale;
                temp.background.offsetXM = angular.copy(temp.background.offsetx);
                temp.background.offsetYM = angular.copy(temp.background.offsety);
            }
            if (obj.barcode) {
                var barcode = obj.barcode.geometry;
                var angle = 0;
                if (obj.barcode.rotation && obj.barcode.rotation.angle) {
                    angle = obj.barcode.rotation.angle
                }
                temp.barcode = {
                    geometry: {
                        width: unitTransform(barcode.width) * temp.styleScale,
                        height: unitTransform(barcode.height) * temp.styleScale,
                        x: unitTransform(barcode.x) * temp.styleScale,
                        y: unitTransform(barcode.y) * temp.styleScale,
                        widthM: barcode.width,
                        heightM: barcode.height,
                        offsetXM: barcode.x,
                        offsetYM: barcode.y
                    },
                    rotation: {
                        angle: angle
                    }
                }
            }
            if (obj.qrcode) {
                var qrcode = obj.qrcode.geometry;
                var angle = 0;
                if (obj.qrcode.rotation && obj.qrcode.rotation.angle) {
                    angle = obj.qrcode.rotation.angle
                }
                temp.qrcode = {
                    geometry: {
                        width: unitTransform(qrcode.width) * temp.styleScale,
                        height: unitTransform(qrcode.height) * temp.styleScale,
                        x: unitTransform(qrcode.x) * temp.styleScale,
                        y: unitTransform(qrcode.y) * temp.styleScale,
                        widthM: qrcode.width,
                        heightM: qrcode.height,
                        offsetXM: qrcode.x,
                        offsetYM: qrcode.y
                    },
                    rotation: {
                        angle: angle
                    }
                }
            }

            angular.forEach(obj.levels.level, function (level, n) {

                if (level.imageart && level.imageart.geometry) {
                    var art = level.imageart;
                    $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                        temp.levels.level[n].imageart = {
                            geometry: getGeometry(art.geometry, temp),
                            rotation: {
                                angle: -art.rotation.angle
                            },
                            type: art.type,
                            resource: {
                                identifier: art.resource.identifier,
                                provider: art.resource.provider,
                                width: data.width,
                                height: data.height
                            },
                            scale: art.scale,
                            widthM: art.geometry.width,
                            heightM: art.geometry.height,
                            XM: art.geometry.x,
                            YM: art.geometry.y,
                            offsetXM: angular.copy(art.offsetx),
                            offsetYM: angular.copy(art.offsety),
                            offsetx: unitTransform(art.offsetx) * temp.styleScale,
                            offsety: unitTransform(art.offsety) * temp.styleScale,
                            current: 'imageart',
                            levelI: n,
                            pageI: index,
                            lock: false,
                            transparency: art.transparency,
                            onlyshow: art.onlyshow,
                            movable: art.movable,
                            editable: art.editable,
                        }
                    }).error(function (error) {
                    });
                }
                if (level.textbox) {
                    $scope.template.pages.page[index].levels.level[n].textbox = {
                        geometry: getGeometry(level.textbox.geometry, temp),
                        style: angular.copy(level.textbox.style),
                        identifier: level.textbox.identifier,
                        rotation: {
                            angle: -level.textbox.rotation.angle
                        },
                        text: level.textbox.text,
                        styles: 'NORMAL',
                        status: level.textbox.status,
                        type: level.textbox.type,
                        transparency: level.textbox.transparency,
                        movable: level.textbox.movable,
                        editable: level.textbox.editable,
                        fontuuid: level.textbox.fontuuid
                    };
                    var tempText = level.textbox;
                    if (tempText.style.bold && tempText.style.italic) {
                        tempText.styles = 'BOLD_ITALIC';
                    } else if (tempText.style.bold) {
                        tempText.styles = 'BOLD';
                    } else if (tempText.style.italic) {
                        tempText.styles = 'ITALIC';
                    } else {
                        tempText.styles = 'NORMAL';
                    }
                    if (level.textbox.text && level.textbox.identifier) {
                        toImg(tempText, $scope.template.pages.page[index].styleScale);
                    }
                    getFont(level.textbox.fontuuid)
                }
                if (level.imagebox) {
                    var art = level.imagebox;
                    $scope.template.pages.page[index].levels.level[n].imagebox = {
                        geometry: getGeometry(level.imagebox.geometry, temp),
                        blurredrectangle: level.imagebox.blurredrectangle ? getGeometry(level.imagebox.blurredrectangle, temp) : null,

                        mask: angular.copy(level.imagebox.mask),
                        image: {
                            resource: {
                                identifier: level.imagebox.image ? level.imagebox.image.resource.identifier : '',
                                provider: level.imagebox.image ? level.imagebox.image.resource.provider : 'qiniu'
                            },
                            filterResource: {
                                identifier: level.imagebox.image && level.imagebox.image.filterResource ? level.imagebox.image.filterResource.identifier : '',
                                provider: level.imagebox.image && level.imagebox.image.filterResource ? level.imagebox.image.filterResource.provider : 'qiniu'
                            },
                            rotation: angular.copy(level.imagebox.rotation),
                            parameter: (!level.imagebox.image) || level.imagebox.image.parameter == null ? [] : angular.fromJson(level.imagebox.image.parameter),
                            filter: (!level.imagebox.image) || level.imagebox.image.filter == null ? '' : level.imagebox.image.filter,
                            offsetx: level.imagebox.image.offsetx == 0 ? 0 : (unitTransform(level.imagebox.image.offsetx) * $scope.template.pages.page[index].styleScale),
                            offsety: level.imagebox.image.offsety == 0 ? 0 : (unitTransform(level.imagebox.image.offsety) * $scope.template.pages.page[index].styleScale),
                            angle: level.imagebox.image && level.imagebox.image.angle ? level.imagebox.image.angle : 0,
                            scale: (!level.imagebox.image) || level.imagebox.image.scale == 1 ? 1 : level.imagebox.image.scale,
                            index: (!level.imagebox.image) ? '' : level.imagebox.image.index
                        },
                        imgUrl: 'content/images/timg.jpg',
                        maskUrl: 'content/images/timg.jpg',
                        rotation: angular.copy(level.imagebox.rotation),
                        scaleW: 1,
                        scaleH: 1,
                        bokehtype: level.imagebox.bokehtype
                    };

                    if (level.imagebox.bokehtype != 'None') {
                        level.imagebox.blurredrectangle = getGeometry(level.imagebox.blurredrectangle, temp)
                    }

                    var imageboxes = $scope.template.pages.page[index].levels.level[n].imagebox;
                    if (level.imagebox.mask.resource.identifier) {
                        imageboxes.maskUrl = level.imagebox.mask.resource.identifier + '?imageMogr2';
                    }

                    if (level.imagebox.image && level.imagebox.image.resource.identifier) {       //qiniu
                        imageboxes.imgUrl = level.imagebox.image.resource.identifier + '?imageMogr2/auto-orient/thumbnail/!40p';
                        if (imageboxes.image.angle != 0) {
                            imageboxes.imgUrl += '/rotate/' + imageboxes.image.angle
                        }
                    }
                }

                if (level.textdocument && level.textdocument.geometry) {
                    var textdocument = level.textdocument;
                    $scope.template.pages.page[index].levels.level[n].textdocument = {
                        background: textdocument.background,
                        geometry: getGeometry(textdocument.geometry, temp),
                        widthM: textdocument.geometry.width,
                        heightM: textdocument.geometry.height,
                        offsetXM: textdocument.geometry.x,
                        offsetYM: textdocument.geometry.y,
                        rotation: {
                            angle: textdocument.rotation.angle || 0
                        },
                        current: 'textdocument',
                        levels: angular.copy(textdocument.levels),
                        levelI: n,
                        pageI: index,
                        lock: true,
                        transparency: textdocument.transparency,
                        movable: textdocument.movable,
                        editable: textdocument.editable,
                        scale: textdocument.geometry.width / obj.mediabox.width
                    }

                    var backg = temp.levels.level[n].textdocument.background;
                    var pageW = temp.levels.level[n].textdocument.geometry.width,
                        pageH = temp.levels.level[n].textdocument.geometry.height;
                    if (backg.resource.identifier) {
                        getBackgroundSource(backg.resource, pageW, pageH, backg)
                        backg.offsetx = unitTransform(backg.offsetx) * temp.styleScale
                        backg.offsety = unitTransform(backg.offsety) * temp.styleScale
                        backg.offsetXM = angular.copy(backg.offsetx);
                        backg.offsetYM = angular.copy(backg.offsety);
                    }

                    angular.forEach(textdocument.levels.level, function (lev, l) {
                        if (lev.imageart && lev.imageart.geometry) {
                            var art = lev.imageart;
                            $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                temp.levels.level[n].textdocument.levels.level[l].imageart = {
                                    geometry: getGeometry(art.geometry, temp),
                                    rotation: angular.copy(art.rotation),
                                    type: art.type,
                                    resource: {
                                        identifier: art.resource.identifier,
                                        provider: art.resource.provider,
                                        width: data.width,
                                        height: data.height
                                    },
                                    scale: art.scale,
                                    widthM: art.geometry.width,
                                    heightM: art.geometry.height,
                                    XM: art.geometry.x,
                                    YM: art.geometry.y,
                                    offsetXM: angular.copy(art.offsetx),
                                    offsetYM: angular.copy(art.offsety),
                                    offsetx: unitTransform(art.offsetx) * temp.styleScale,
                                    offsety: unitTransform(art.offsety) * temp.styleScale,
                                    current: 'imageart',
                                    levelI: n,
                                    pageI: index,
                                    lock: false,
                                    transparency: art.transparency,
                                    onlyshow: art.onlyshow,
                                    movable: art.movable,
                                    editable: art.editable,
                                }
                            }).error(function (error) {
                            });
                        }
                        if (lev.textbox && lev.textbox.geometry) {
                            var text = lev.textbox;
                            temp.levels.level[n].textdocument.levels.level[l].textbox = {
                                geometry: getGeometry(text.geometry, temp),
                                identifier: text.identifier,
                                rotation: angular.copy(text.rotation),
                                angle: angular.copy(text.angle),
                                status: angular.copy(text.status),
                                style: angular.copy(text.style),
                                type: angular.copy(text.type),
                                text: angular.copy(text.text),
                                fontStyle: [{
                                    style: '',
                                    path: text.identifier
                                }],
                                widthM: text.geometry.width,
                                heightM: text.geometry.height,
                                offsetXM: text.geometry.x,
                                offsetYM: text.geometry.y,
                                current: 'textbox',
                                levelI: n,
                                pageI: index,
                                lock: true,
                                transparency: text.transparency,
                                lineheight: text.style.lineheight,
                                letterspacing: text.style.letterspacing,
                                fontuuid: text.fontuuid,
                                version: text.version
                            }
                            // toImg($scope.template.pages.page[i].levels.level[n].textbox)
                        }
                    })
                }
            });
            //背景图片
            if ($scope.template.pages.page[index].background.resource.identifier) {

                $http({url: $scope.template.pages.page[index].background.resource.identifier + '?imageInfo'}).success(function (data) {
                    var bgImgW = data.width;
                    var bgImgH = data.height;

                    var bgImgR = bgImgW / bgImgH;
                    var bgImgR1 = $scope.template.pages.page[index].width / $scope.template.pages.page[index].height;
                    if (bgImgR >= bgImgR1) {
                        $scope.template.pages.page[index].background.adaptation = 'Height';
                    } else {
                        $scope.template.pages.page[index].background.adaptation = 'Width';
                    }
                }).error(function (error) {
                    MessageService.error("获取图片信息失败");
                });
            }
            var w = (unitTransform(document.scene.geometry.width) * $scope.template.pages.page[index].styleScale);
            $scope.template.pages.page[index].ratio = w / (document.scene.geometry.width / 25.4 * vm.windowDpi)
            $scope.isLeftRotation = false;
            angular.forEach($scope.template.pages.page[0].levels.level, function (image) {
                if (image.imagebox) {
                    imgScale(image.imagebox, 0, 0, image.imagebox.maskUrl, image.imagebox.image.offsetx, image.imagebox.image.offsety);
                }
            })
        };

        function getBackgroundSource1(res, pageW, pageH, item, bokehtypes) {
            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.src = res.identifier + '?imageMogr2/auto-orient/rotate/' + item.angle + '/thumbnail/!40p';
            newImg.onload = function () {
                var imgR = pageW / pageH;
                var imgR1 = newImg.width / newImg.height;
                if (bokehtypes == 'None') {
                    if (imgR >= imgR1) {
                        item.resource.adaptation = 'Height';
                        item.width = pageW;
                        item.height = pageW / newImg.width * newImg.height;
                    } else {
                        item.resource.adaptation = 'Width';
                        item.width = pageH / newImg.height * newImg.width;
                        item.height = pageH;
                    }
                } else {
                    if (imgR >= imgR1) {
                        item.resource.adaptation = 'Height';
                        item.width = pageW;
                        item.height = pageW / newImg.width * newImg.height;
                        item.offsetx = 0;
                        item.offsety = (pageH - pageW / newImg.width * newImg.height) / 2;
                    } else {
                        item.resource.adaptation = 'Width';
                        item.width = pageH / newImg.height * newImg.width;
                        item.height = pageH;
                        item.offsetx = (pageW - pageH / newImg.height * newImg.width) / 2;
                        item.offsety = 0;
                    }
                }
                $scope.$digest();
            };
        }

        function getBackgroundSource(res, pageW, pageH, arr) {
            $http({url: res.identifier + '?imageInfo'}).success(function (data) {
                var bgImgR = data.width / data.height;
                var bgImgR1 = pageW / pageH;
                if (bgImgR >= bgImgR1) {
                    arr.adaptation = 'Height';
                } else {
                    arr.adaptation = 'Width';
                }
            }).error(function (error) {
            });
        }

        function getFont(item) {
            if (item) {
                FontManagement.getCss({uuid: item}, function (res) {
                    if(res.status == 200){
                        var style = document.createElement("style");
                        style.type = 'text/css';
                        try {
                            style.appendChild(document.createTextNode(res.data));
                        } catch (ex) {
                            style.stylesheet.cssText = res.data;
                        }
                        var Head = document.getElementsByTagName("head")[0];
                        Head.appendChild(style);
                    }else {
                        MessageService.error(res.message);
                    }
                });
            }
        }

        //mm转px
        function transitionPx(item) {
            return item * vm.windowDpi / 25.4;
        }

        /**
         * 摆台宽高坐标从 mm 换算成 px
         * @param obj
         * @param index
         * @returns {{height: number, width: number, x: number, y: number}}
         */
        function setGeometry(obj) {
            $scope.template.scene.styleScale = (unitTransform(obj.width) / ($scope.dWidth - 45));
            return {
                height: unitTransform(obj.height) / $scope.template.scene.styleScale,
                width: $scope.dWidth - 45,
                x: 0,
                y: 0
            };
        }

        function setGeometry1(obj) {
            return {
                height: unitTransform(obj.height) / $scope.template.scene.styleScale,
                width: unitTransform(obj.width) / $scope.template.scene.styleScale,
                x: unitTransform(obj.x) / $scope.template.scene.styleScale,
                y: unitTransform(obj.y) / $scope.template.scene.styleScale
            };
        }

        /**
         * 宽高坐标从 mm 换算成 px
         * @param obj
         * @param index
         * @returns {{height: number, width: number, x: number, y: number}}
         */
        function getGeometry(obj, temp) {
            return {
                height: unitTransform(obj.height) * temp.styleScale,
                width: unitTransform(obj.width) * temp.styleScale,
                x: unitTransform(obj.x) * temp.styleScale,
                y: unitTransform(obj.y) * temp.styleScale
            }
        }

        /**
         * 占位框宽高适配，进行canvas操作
         * @param images    占位框
         * @param index     第N页page
         * @param mask      蒙版图形
         * @param x         图片坐标x
         * @param y         图片坐标y
         */
        function imgScale(images, pageI, index, mask, x, y, flag) {
            var scaleW = 1, scaleH = 1;
            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.src = images.imgUrl;

            newImg.onload = function () {
                newImg.onload = null;
                var w = newImg.width;
                var h = newImg.height;
                var maskWidth = images.geometry.width;
                var maskHight = images.geometry.height;
                if ((newImg.width <= newImg.height || maskWidth <= maskHight) && (newImg.width / newImg.height < maskWidth / maskHight) || newImg.width >= newImg.height && maskWidth >= maskHight && (newImg.width / newImg.height < maskWidth / maskHight)) {
                    newImg.width = maskWidth;
                    newImg.height = maskWidth * h / w;
                } else {
                    newImg.height = maskHight;
                    newImg.width = maskHight * w / h;
                }

                scaleW = newImg.width / w;
                scaleH = newImg.height / h;
                x = x / scaleW;
                y = y / scaleH;

                var img = images.imgUrl;
                if (images.bokehtype == 'Transparent') {
                    maskd3(img, images.imgUrl, images, function (src) {
                        images.blobSmall = src;
                        dropPicFinished(pageI, index);
                    });

                } else if (images.bokehtype == 'Blurred') {
                    maskd3(img, images.imgUrl, images, function (src) {
                        images.blobSmall = src;
                        $scope.$digest();
                    });

                    img = img + '/blur/40x1000';
                    maskd(img, images.maskUrl, 'source-in', x, y, maskWidth, maskHight, scaleW, scaleH, images.image.scale, function (src) {
                        images.blobUrl = src;
                        dropPicFinished(pageI, index);
                    });

                } else {
                    maskd(img, images.maskUrl, 'source-in', x, y, maskWidth, maskHight, scaleW, scaleH, images.image.scale, function (src) {
                        images.blobUrl = src;
                        dropPicFinished(pageI, index);
                    });
                }

            };
            newImg.onerror = function () {
                images.imgUrl = $scope.domain + images.image.resource.identifier + '?imageMogr2/auto-orient';
                if (images.image.angle != 0) {
                    images.imgUrl += '/rotate/' + images.image.angle
                }

                if (!flag) {
                    imgScale(images, pageI, index, mask, x, y, true);
                } else {
                    showPage();
                    $(".templateView-loading").addClass('hide1');
                    $(".background").show();
                }

            }
        }


        function dropPicFinished(pageI, index) {
            $scope.$apply(function () {
                if (index == pageI) {
                    showPage();
                }
                if (vm.reprint == 'OTHER' || $scope.template.pages.page.length < 2) {
                    showPage();
                }
                $(".templateView-loading").addClass('hide1');
                $(".background").show();
            });
        }

        function maskd3(img, src, imagebox, callback) {
            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');
            var geo = imagebox.geometry;
            var blurredRec = imagebox.blurredrectangle;

            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.src = src;
            newImg.onload = function () {
                newImg.onload = null;
                newImg.width = blurredRec.width;
                newImg.height = blurredRec.height;
                var masks = new Image();
                masks.crossOrigin = "Anonymous";
                masks.src = imagebox.maskUrl;

                masks.onload = function () {
                    masks.width = geo.width;
                    masks.height = geo.height;
                    imagecanvas.width = geo.width;
                    imagecanvas.height = geo.height;
                    imagecontext.mozImageSmoothingEnabled = true;
                    imagecontext.webkitImageSmoothingEnabled = true;
                    imagecontext.msImageSmoothingEnabled = true;
                    imagecontext.imageSmoothingEnabled = true;

                    imagecontext.drawImage(masks, 0, 0, geo.width, geo.height);
                    imagecontext.globalCompositeOperation = 'source-in';

                    imagecontext.drawImage(newImg, blurredRec.x, blurredRec.y, blurredRec.width, blurredRec.height);

                    if (!callback) {
                        return;
                    }
                    callback.call(this, ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                    imagecanvas = null;
                };
            };
        }

        function showPage() {
            $scope.loading = false;
            $(".page-loading").hide();
            $li.show();
            $load.hide();
            $flagBox.show();
        }

        var getPixelRatio = function (context) {
            var backingStoreRatio = context.webkitBackingStorePixelRatio ||
                context.mozBackingStorePixelRatio ||
                context.msBackingStorePixelRatio ||
                context.oBackingStorePixelRatio ||
                context.backingStorePixelRatio || 1;

            return (window.devicePixelRatio || 1) / backingStoreRatio;
        };

        function maskd(src, maskUrl, attr, x0, y0, width, height, scaleW, scaleH, scale, callback) {
            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');

            var ratio = getPixelRatio(imagecontext);
            imagecanvas.style.width = width + "px";
            imagecanvas.style.height = height + "px";
            imagecanvas.width = width * ratio;
            imagecanvas.height = height * ratio;
            imagecontext.scale(ratio, ratio);

            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.width = width;
            newImg.height = height;
            newImg.src = src;
            newImg.onload = function () {
                newImg.onload = null;
                var masks = new Image();
                masks.crossOrigin = "Anonymous";
                masks.onload = function () {
                    masks.onload = null;
                    masks.width = width;
                    masks.height = height;
                    imagecanvas.width = width;
                    imagecanvas.height = height;

                    imagecontext.drawImage(masks, 0, 0, width, height);
                    imagecontext.globalCompositeOperation = attr;

                    imagecontext.scale(scaleW, scaleH);
                    imagecontext.scale(scale, scale);

                    imagecontext.drawImage(newImg, x0, y0);


                    if (!callback) {
                        return;
                    }
                    callback.call(this, ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                    imagecanvas = null;
                };
                masks.src = maskUrl;
            };
        }

        function toImg(text, scale) {
            text.geometry.width /= scale;
            text.geometry.height /= scale;

            $http({
                url: '/editor/fileserves/api/convert/textToImg',
                method: 'POST',
                data: {
                    identifier: text.identifier,
                    text: text.text,
                    fontSize: text.style.size,
                    style: text.styles,
                    height: text.geometry.height,
                    width: text.geometry.width,
                    color: text.style.color,
                    family: text.style.font,
                    storeId: vm.storeId
                },
                responseType: 'blob'
            }).success(function (res, status, headers, config) {
                if(res.status == 200){
                    text.geometry.width = text.geometry.width * scale;
                    text.geometry.height = text.geometry.height * scale;

                    var createObjectURL = function (blob) {
                        return window[window.webkitURL ? 'webkitURL' : 'URL']['createObjectURL'](blob);
                    };
                    var fileURL = URL.createObjectURL(res.data);

                    text.picCode = text.pic = fileURL;
                    text.identifier = text.identifier || $scope.defaultText.path;
                }else {
                    MessageService.error(res.message);
                }

            }).error(function () {
                console.log("textToImg error");
            });
        }

        //旋转屏幕
        // window.addEventListener('orientationchange', function (event) {
        //     setReviewDoc();
        // });
        window.addEventListener('resize', function (event) {
            if (vm.reprint == 'OTHER' || $scope.template.pages.page.length < 2) {
                return
            }

            if (window.orientation == 0) {
                $rootScope.orientations = false;
            }
            setReviewDoc();
        });

        if (window.orientation == 90 || window.orientation == -90) {
            $li.width($scope.dWidth);
            $rootScope.orientation = false;
            console.log("always 横屏");
        } else {
            $rootScope.orientations = true;
        }

        function setReviewDoc() {
            $(".save-page").removeClass('hide1');
            $(".view2-article").addClass('hide1');
            $(".share-wrap").addClass('hide1');
            setTimeout(function () {
                if (window.orientation == 180 || window.orientation == 0) {
                    console.log("竖屏。。。。。");
                    $rootScope.orientations = true;
                    orientationChange();

                } else if (window.orientation == 90 || window.orientation == -90) {
                    console.log("横屏。。。。。");
                    $rootScope.orientations = false;
                    orientationChange();
                }
            }, 500);
        }

        function orientationChange() {
            $scope.dHeight = document.documentElement.clientHeight;
            $scope.dWidth = document.documentElement.clientWidth;
            $(".back-to-customer").width($scope.dWidth);
            $li.width($scope.dWidth);
            var newDoc = angular.copy(entity.data.document);
            getAll(newDoc);
            setTimeout(function () {
                $(".save-page").addClass('hide1');
                $(".view2-article").removeClass('hide1');
                $(".share-wrap").removeClass('hide1');
            }, 1500);
        }


        $scope.toggle = {
            now: false
        };
//边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.dropShadow = function(imagebox,ratio,ratio1){
            if (imagebox.shadow == null) {
                return
            }
            var width = $scope.imageboxW(imagebox) * ratio;
            var height =$scope.imageboxH(imagebox) * ratio1;
            return dropShadow(imagebox,width,height)
        }
    }
})();
