(function () {
    'use strict';

    var picOperation = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        controllerAs: '$scope',
        bindings: {
            document: '=',
            template: '=',
            arrI: '=',
            pageI: '=',
            currentPage: '=',
            ctrlKeyArray: '=',
            selectBox: '=',
            hasText: '=',
            fontOn: '=',
            fontDocOn: '=',
            isReplaceShow: '=',
            hint:'='
        },

        controller: ['$scope', 'ImageService', '$q', 'Qinius', 'Store', '$http', '$rootScope', 'Article', 'FontManagement', '$state',
            function ($scope, ImageService, $q, Qinius, Store, $http, $rootScope, Article, FontManagement, $state) {
                var vm = this;
                var drag = false;
                var dragBox = {};

                window.moveImgStart = function (e) {
                    fn(e);
                    if(vm.fontOn||vm.fontDocOn){
                        return
                    }
                    vm.hasText = false;
                    var targ = e.target ? e.target : e.srcElement;
                    var imgIndex = targ.dataset.imgindex || vm.template.levelI;
                    vm.pageI = targ.dataset.docindex;
                    vm.arrI = targ.dataset.arrindex;
                    var page = vm.document.pages[vm.pageI];
                    vm.currentPage = page.arr;
                    if (vm.arrI == 2) {
                        vm.currentPage = page.arr2;
                    }
                    vm.template.levelI = imgIndex;
                    var currentImg = vm.currentPage.levels.level[vm.template.levelI].imagebox;

                    if (currentImg.lock) {
                        return
                    }

                    getChooseBox(currentImg, 'imagebox');

                    dragBox.clientX = e.clientX || 0;
                    dragBox.clientY = e.clientY || 0;
                    if(currentImg.image&&currentImg.image.resource&&currentImg.image.resource.identifier){
                        dragBox.coordX = targ.offsetParent.offsetParent.offsetParent.offsetLeft;
                        dragBox.coordY = targ.offsetParent.offsetParent.offsetParent.offsetTop;
                    }else{
                        dragBox.coordX = targ.offsetParent.offsetParent.offsetLeft;
                        dragBox.coordY = targ.offsetParent.offsetParent.offsetTop;
                    }
                    dragBox.slideW = $(".slide" + vm.arrI + vm.pageI).width();
                    dragBox.slideH = $(".slide" + vm.arrI + vm.pageI).height();
                    drag = true;
                    if (currentImg.rotation.angle >= 360) {
                        currentImg.rotation.angle %= 360;
                    }
                    dragBox.geometryX = angular.copy(currentImg.geometry.x);
                    dragBox.geometryY = angular.copy(currentImg.geometry.y);
                    var all = getAllCtrlKeyArray();
                    if (all.length) {
                        dragBox.ctrlKey = true;
                    }
                    if(currentImg.ctrlKey){
                        levelMove(e)
                    }else{
                        document.addEventListener("mousemove", moveImgOn, true);
                        document.addEventListener("mouseup", moveImgEnd, true);
                    }
                    $scope.$apply();
                };

                //move 事件
                function moveImgOn(e) {
                    fn(e);
                    if (e.target.nodeName == 'SPAN') {
                        return;
                    }
                    if (drag) {
                        var page = vm.document.pages[vm.pageI];
                        var currentPage = vm.currentPage;
                        var pressImg = currentPage.levels.level[vm.template.levelI].imagebox;

                        var clientX = e.clientX - dragBox.clientX;
                        var clientY = e.clientY - dragBox.clientY;
                        var imgOffsetX = (dragBox.coordX + e.clientX - dragBox.clientX);
                        var imgOffsetY = (dragBox.coordY + e.clientY - dragBox.clientY);
                        var imgWidth = pressImg.geometry.width * currentPage.pageScaleW * page.scale;
                        var imgHeight = pressImg.geometry.height * currentPage.pageScaleH * page.scale;
                        var x = currentPage.editbox.x * currentPage.pageScaleW * page.scale;
                        var y = currentPage.editbox.y * currentPage.pageScaleW * page.scale;

                        // $(".move-tip").hide();
                        // if (imgOffsetX <= 0) {
                        //     imgOffsetX = 0;
                        // }
                        //
                        // if (imgOffsetX <= x || imgOffsetY <= y) {
                        //     $(".move-tip").show();
                        // }
                        // if (imgOffsetY >= (dragBox.slideH - imgHeight - y)) {
                        //     $(".move-tip").show();
                        // }
                        //
                        //
                        // if (imgOffsetX >= dragBox.slideW - imgWidth) {
                        //     imgOffsetX = dragBox.slideW - imgWidth;
                        // }
                        // if (imgOffsetX >= dragBox.slideW - imgWidth - x) {
                        //     $(".move-tip").show();
                        // }
                        //
                        // if (imgOffsetY <= 0) {
                        //     imgOffsetY = 0;
                        // }
                        //
                        // if (imgOffsetY >= (dragBox.slideH - imgHeight)) {
                        //     imgOffsetY = dragBox.slideH - imgHeight;
                        // }

                        if (dragBox.ctrlKey) {
                            moveTogether(currentPage, clientX, clientY,dragBox);
                        } else {
                            pressImg.geometry.x = dragBox.geometryX + imgOffsetX / currentPage.pageScaleW / page.scale;
                            pressImg.geometry.y = dragBox.geometryY + imgOffsetY / currentPage.pageScaleH / page.scale;
                        }

                        vm.currentPage = currentPage;
                        vm.hint = !editboxIn(vm.currentPage.editboxes.editbox , pressImg.geometry, pressImg.rotation.angle);
                        console.log(pressImg.geometry.x)
                        $scope.$apply();
                    }
                    return false;
                }

                //鼠标松开事件
                function moveImgEnd() {
                    if (dragBox.ctrlKey) {
                        moveTogetherEnd();
                    }
                    drag = false;
                    dragBox = {};
                    $(".move-tip").hide();
                    uploadRightPlanel();
                    $scope.$parent.updateHistory();
                    document.removeEventListener("mousemove", moveImgOn, true);
                    document.removeEventListener("mouseup", moveImgEnd, true);
                    vm.hint = false;
                    $scope.$digest();
                }

                function uploadRightPlanel() {
                    // $scope.template.pages.page[$scope.currentPage.seq - 1] = angular.copy($scope.currentPage);
                    var p = [];
                    angular.forEach(vm.document.pages, function (item, index) {
                        p.push(item.arr);
                        if (item.arr2) {
                            p.push(item.arr2);
                        }
                    });
                    vm.template.pages.page = p;
                }

                function getChooseBox(box, attr) {
                    vm.selectBox = box;
                    vm.selectBox.current = attr;
                    hideChangeBtn();
                }

                function hideChangeBtn() {
                    $(".change-btn").hide();
                    vm.isReplaceShow = true;
                }

                function getAllCtrlKeyArray() {
                    var imageboxes = vm.ctrlKeyArray.imageboxes,
                        textboxes = vm.ctrlKeyArray.textboxes,
                        imagearts = vm.ctrlKeyArray.imagearts,
                        all = [];
                    var barcode = vm.ctrlKeyArray.barcode;
                    angular.forEach(vm.currentPage.levels.level, function (level, i) {
                        if(level.imageart && level.imageart.ctrlKey){
                            all.push(level.imageart)
                        }
                        if(level.imagebox && level.imagebox.ctrlKey){
                            all.push(level.imagebox)
                        }
                        if(level.textbox && level.textbox.ctrlKey){
                            all.push(level.textbox)
                        }
                        if (level.textdocument && level.textdocument.ctrlKey) {
                            all.push(level.textdocument)
                        }
                        if (level.textdocument && level.textdocument.ctrlKey) {
                            all.push(level.textdocument)
                        }
                        if (level.code && level.code.ctrlKey) {
                            all.push(level.code)
                        }
                    })
                    return all;
                }

                function moveTogether(currentPage, clientX, clientY,dragbox) {
                    var page = vm.document.pages[vm.pageI];
                    var imgWidth = 0, imgHeight = 0, x = 0, y = 0, pro = currentPage.pageScaleW * page.scale;
                    angular.forEach(vm.ctrlKeyArray.imageboxes, function (imagebox, i) {
                        // 当前页所有占位框
                        var box = currentPage.levels.level[imagebox.levelI].imagebox;
                        boxSetting(box, imagebox);
                    })

                    angular.forEach(vm.ctrlKeyArray.imagearts, function (imageart, i) {
                        // 当前页所有素材
                        var box = currentPage.levels.level[imageart.levelI].imageart;
                        boxSetting(box, imageart);
                    })

                    angular.forEach(vm.ctrlKeyArray.textboxes, function (textbox, i) {
                        // 当前页所有文字
                        var box = currentPage.levels.level[textbox.levelI].textbox;
                        boxSetting(box, textbox);
                    })

                    angular.forEach(vm.ctrlKeyArray.textdocuments, function (textdocument, i) {
                        // 当前页所有文字
                        var box = currentPage.levels.level[textdocument.levelI].textdocument;
                        boxSetting(box, textdocument);
                    })

                    function boxSetting(box, CtrlKeyBox) {
                        if(!box.lock){
                            console.log(box)
                            imgWidth = box.geometry.width * pro;
                            imgHeight = box.geometry.height * pro;
                            x = CtrlKeyBox.geometry.x * pro + clientX;
                            y = CtrlKeyBox.geometry.y * pro + clientY;
                            // if (x <= 0) {
                            //     x = 0;
                            // }
                            // if (x >= (dragbox.slideW - imgWidth)) {
                            //     x = dragbox.slideW - imgWidth;
                            // }
                            // if (y <= 0) {
                            //     y = 0;
                            // }
                            //
                            // if (y >= (dragbox.slideH - imgHeight)) {
                            //     y = dragbox.slideH - imgHeight;
                            // }
                            box.geometry.x = x / pro
                            box.geometry.y = y / pro
                        }
                    }
                }

                function moveTogetherEnd() {
                    angular.forEach(vm.ctrlKeyArray.imageboxes, function (imagebox, i) {
                        // 当前页所有占位框
                        var box = vm.currentPage.levels.level[imagebox.levelI].imagebox;
                        ctrlKeySetting(box, imagebox);
                    })

                    angular.forEach(vm.ctrlKeyArray.imagearts, function (imageart, i) {
                        // 当前页所有素材
                        var box = vm.currentPage.levels.level[imageart.levelI].imageart;
                        ctrlKeySetting(box, imageart);
                    })

                    angular.forEach(vm.ctrlKeyArray.textboxes, function (textbox, i) {
                        // 当前页所有文字
                        var box = vm.currentPage.levels.level[textbox.levelI].textbox;
                        ctrlKeySetting(box, textbox);
                    })

                    angular.forEach(vm.ctrlKeyArray.textdocuments, function (textdocument, i) {
                        // 当前页所有文字
                        var box = vm.currentPage.levels.level[textdocument.levelI].textdocument;
                        ctrlKeySetting(box, textdocument);
                    });

                    function ctrlKeySetting(box, CtrlKeyBox) {
                        CtrlKeyBox.geometry.x = box.geometry.x;
                        CtrlKeyBox.geometry.y = box.geometry.y;
                    }
                }

                function levelMove(event) {
                    var ratio = 1;
                    if(vm.arrI == 1){
                        ratio = vm.document.pages[vm.pageI].arr.pageScaleW*vm.document.pages[vm.pageI].scale
                    }
                    if(vm.arrI == 2){
                        ratio = vm.document.pages[vm.pageI].arr2.pageScaleW*vm.document.pages[vm.pageI].scale
                    }
                    angular.forEach(vm.currentPage.levels.level, function (level, i) {
                        if(level.imageart && level.imageart.ctrlKey && !level.imageart.lock && !level.imageart.edit){
                            moveWebdown($scope, event,level.imageart, vm.currentPage.trimbox,ratio, function () {

                            })
                        }
                        if(level.imagebox && level.imagebox.ctrlKey && !level.imagebox.lock){
                            // level.imagebox.ctrlKey = false;
                            moveWebdown($scope, event,level.imagebox, vm.currentPage.trimbox,ratio, function () {

                            })
                        }
                        if(level.textbox && level.textbox.ctrlKey && !level.textbox.lock){
                            // level.textbox.ctrlKey = false;
                            moveWebdown($scope, event,level.textbox, vm.currentPage.trimbox,ratio, function () {

                            })
                        }
                        if (level.textdocument && level.textdocument.ctrlKey && !level.textdocument.lock) {
                            // level.textdocument.ctrlKey = false;
                            moveWebdown($scope, event,level.textdocument, vm.currentPage.trimbox,ratio, function () {

                            })
                        }
                        if (level.code && level.code.ctrlKey && !level.code.lock) {
                            // level.textdocument.ctrlKey = false;
                            moveWebdown($scope, event,level.code, vm.currentPage.trimbox,ratio, function () {

                            })
                        }
                    })
                }
                //防止事件冒泡，默认事件
                function fn(e) {
                    // e = e || window.event;
                    // e.cancelBubble = true;
                    // e.stopPropagation();
                    // e.returnValue = false;
                    // e.preventDefault();
                    if (e && e.stopPropagation) {
                        // 因此它支持W3C的stopPropagation()方法
                        e.stopPropagation();
                    } else {
                        // 否则，我们需要使用IE的方式来取消事件冒泡
                        window.event.cancelBubble = true;
                    }

                    // 阻止默认浏览器动作(W3C)
                    if (e && e.preventDefault) {
                        e.preventDefault();
                    } else {
                        // IE中阻止函数器默认动作的方式
                        window.event.returnValue = false;
                    }
                }
            }],
        link:function () {

        }
    };

    angular
        .module('editorApp')
        .component('picOperation', picOperation);
})();
