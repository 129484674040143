//文字校验
function FontSupport2(FontManagement, font, callback) {
    var type = "";
    if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || !localStorage.getItem('distinguish')) {
        type = "wechat"
    }
    else if (localStorage.getItem('distinguish') == 'toutiaoApp') {
        type = "byteDance"
    }
    else if (localStorage.getItem('distinguish') == 'alipayApp') {
        type = "ali"
    }
    font.success = true;
    FontManagement.fontSupport2({
        uuid: font.fontuuid,
        bold: font.style.bold,
        italic: font.style.italic,
        content: font.text,
        type:type
    }, function (res) {
        if(res.status == 200){
            font.success = res.data.success;
        }
        if(callback){callback(res)}
    }, function (error) {
        if(callback){callback()}
    })
}
function textPaste1(event) {
    event.preventDefault();
    var text;
    var clp = (event.originalEvent || event).clipboardData;
    // 兼容针对于opera ie等浏览器
    if (clp === undefined || clp === null) {
        text = window.clipboardData.getData("text") || "";
        if (text !== "") {
            if (window.getSelection) {
                // 针对于ie11 10 9 safari
                var newNode = document.createElement("span");
                newNode.innerHTML = text;
                window.getSelection().getRangeAt(0).insertNode(newNode);
            } else {
                // 兼容ie10 9 8 7 6 5
                document.selection.createRange().pasteHTML(text);
            }
        }
    } else {
        // 兼容chorme或hotfire
        text = clp.getData('text/plain') || "";
        if (text !== "") {
            document.execCommand('insertText', false, text);
        }
    }
}
function getQueryVariable(url, variable) {
    var query = window.location.href.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return (false);
}
function getUrlParam() {
    var url = window.location.href.split("?")[1]; //获取url中"?"符后的字符串包括‘？’ ，window.location.href获取的是url完整的字符串
    var theParam = new Object();
    if(url){
        var str = url.substr(0); //substr是字符串的用法之一，抽取指定字符的数目，这里抽取？后的所有字符
        strs = str.split("&"); //将获取到的字符串从&分割，输出参数数组，即输出[参数1=xx,参数2=xx,参数3=xx,...]的数组形式
        for(var i = 0; i < strs.length; i ++) { //遍历参数数组
            theParam[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]); //这里意思是抽取每个参数等号后面的值，unescape是解码的意思
        }
    }
    return theParam; //返回参数值
}
//编辑器获取dpi
var _dpi = null;
function js_getDPI() {
    if(_dpi){
        return _dpi
    }
    var arrDPI = new Array();
    if (window.screen.deviceXDPI != undefined) {
        arrDPI[0] = window.screen.deviceXDPI;
        arrDPI[1] = window.screen.deviceYDPI;
    } else {
        var tmpNode = document.createElement("DIV");
        tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
        document.body.appendChild(tmpNode);
        arrDPI[0] = parseInt(tmpNode.offsetWidth);
        arrDPI[1] = parseInt(tmpNode.offsetHeight);
        tmpNode.parentNode.removeChild(tmpNode);
    }
    _dpi = arrDPI[1];
    return arrDPI[1];

}
//解析document
function documentAnalysis($scope,$http, vm, document, FontManagement, flag, callbackRatio, callback) {
    var _imgSum = 0, f = 0;
    angular.forEach(document.pages.page, function (page, index) {
        (function (page, index) {
            page.seq = index;
            if(flag=='jianyi'||flag=='gaoji'){
                page.isMaxWidth = getScale($scope.dWidth, $scope.dHeight, page.mediabox.width, page.mediabox.height, index);
            }
            page.mediaboxMM = angular.copy(page.mediabox);
            page.mediabox = {
                width: page.unit == 'px' ? page.mediabox.width : transitionPx(page.mediabox.width),
                height: page.unit == 'px' ? page.mediabox.height : transitionPx(page.mediabox.height),
                x: page.unit == 'px' ? page.mediabox.x : transitionPx(page.mediabox.x),
                y: page.unit == 'px' ? page.mediabox.y : transitionPx(page.mediabox.y)
            };
            page.trimbox = {
                width: page.unit == 'px' ? page.trimbox.width : transitionPx(page.trimbox.width),
                height: page.unit == 'px' ? page.trimbox.height : transitionPx(page.trimbox.height),
                x: page.unit == 'px' ? page.trimbox.x : transitionPx(page.trimbox.x),
                y: page.unit == 'px' ? page.trimbox.y : transitionPx(page.trimbox.y)
            };
            // 兼容旧模板 可编辑区域
            if (!page.editbox) {
                page.editbox = angular.copy(page.trimbox);
            }else{
                page.editbox = {
                    width: page.unit == 'px' ? page.editbox.width : transitionPx(page.editbox.width),
                    height: page.unit == 'px' ? page.editbox.height : transitionPx(page.editbox.height),
                    x: page.unit == 'px' ? page.editbox.x : transitionPx(page.editbox.x),
                    y: page.unit == 'px' ? page.editbox.y : transitionPx(page.editbox.y)
                };
            }
            if(!page.editboxes || !page.editboxes.editbox){
                page.editboxes = {
                    editbox: [page.editbox]
                }
            }else{
                page.editboxes.editbox.forEach(function (editbox, editboxIndex) {
                    page.editboxes.editbox[editboxIndex] = {
                        width:  page.unit == 'px' ? editbox.width : transitionPx(editbox.width),
                        height:  page.unit == 'px' ? editbox.height : transitionPx(editbox.height),
                        x:  page.unit == 'px' ? editbox.x : transitionPx(editbox.x),
                        y:  page.unit == 'px' ? editbox.y : transitionPx(editbox.y)
                    }
                })
            }
            page.custom = {};
            if (page.scene) {
                page.scene.geometry = {
                    width:  page.unit == 'px' ? page.scene.geometry.width : transitionPx(page.scene.geometry.width),
                    height:  page.unit == 'px' ? page.scene.geometry.height : transitionPx(page.scene.geometry.height),
                    x:  page.unit == 'px' ? page.scene.geometry.x : transitionPx(page.scene.geometry.x),
                    y:  page.unit == 'px' ? page.scene.geometry.y : transitionPx(page.scene.geometry.y)
                };
                page.scene.imagebox.geometry = {
                    width:  page.unit == 'px' ? page.scene.imagebox.geometry.width : transitionPx(page.scene.imagebox.geometry.width),
                    height:  page.unit == 'px' ? page.scene.imagebox.geometry.height : transitionPx(page.scene.imagebox.geometry.height),
                    x:  page.unit == 'px' ? page.scene.imagebox.geometry.x : transitionPx(page.scene.imagebox.geometry.x),
                    y:  page.unit == 'px' ? page.scene.imagebox.geometry.y : transitionPx(page.scene.imagebox.geometry.y)
                };
                if (page.scene.resource) {
                    getBackgroundSource($http, page.scene.resource, page.scene.geometry.width, page.scene.geometry.height, document.pages.page[index].scene, $scope);
                }
                page.custom.width = page.scene.geometry.width;
                page.custom.height = page.scene.geometry.height;
                page.sceneCopy = angular.copy(page.scene);
            } else {
                page.custom.width = page.mediabox.width;
                page.custom.height = page.mediabox.height;
            }
            page.custom.thumbnailScale = 300 / page.custom.width;
            callbackRatio(page,index)
            //条形码
            if (page.barcode) {
                page.barcode.geometry = {
                    width:  page.unit == 'px' ? page.barcode.geometry.width : transitionPx(page.barcode.geometry.width),
                    height:  page.unit == 'px' ? page.barcode.geometry.height : transitionPx(page.barcode.geometry.height),
                    x:  page.unit == 'px' ? page.barcode.geometry.x : transitionPx(page.barcode.geometry.x),
                    y:  page.unit == 'px' ? page.barcode.geometry.y : transitionPx(page.barcode.geometry.y)
                };
            }
            if (page.qrcode) {
                page.qrcode.geometry = {
                    width:  page.unit == 'px' ? page.qrcode.geometry.width : transitionPx(page.qrcode.geometry.width),
                    height:  page.unit == 'px' ? page.qrcode.geometry.height : transitionPx(page.qrcode.geometry.height),
                    x:  page.unit == 'px' ? page.qrcode.geometry.x : transitionPx(page.qrcode.geometry.x),
                    y:  page.unit == 'px' ? page.qrcode.geometry.y : transitionPx(page.qrcode.geometry.y)
                };
            }
            //背景
            if (page.background && page.background.resource &&page.background.resource.identifier) {
                getBackgroundSource($http, page.background.resource, page.mediabox.width, page.mediabox.height, document.pages.page[index].background, $scope);
                page.background.offsetx =  page.unit == 'px' ? page.background.offsetx : transitionPx(page.background.offsetx);
                page.background.offsety =  page.unit == 'px' ? page.background.offsety : transitionPx(page.background.offsety);
                page.background.type = 'Pic'
            }
            if(!page.levels.level){
                page.levels.level=[];
            }
            angular.forEach(page.levels.level, function (level, i) {
                (function (level, i) {
                    if (level.code && level.code.geometry) {
                        level.code.lock = true;
                        level.code.lock = true;
                        level.code.geometry = {
                            width:  page.unit == 'px' ? level.code.geometry.width : transitionPx(level.code.geometry.width),
                            height:  page.unit == 'px' ? level.code.geometry.height : transitionPx(level.code.geometry.height),
                            x:  page.unit == 'px' ? level.code.geometry.x : transitionPx(level.code.geometry.x),
                            y:  page.unit == 'px' ? level.code.geometry.y : transitionPx(level.code.geometry.y)
                        };
                    }
                    if (level.imageart && level.imageart.geometry) {
                        level.imageart.lock = true;
                        level.imageart.lock = true;
                        level.imageart.levelI = i;
                        level.imageart.pageI = index;
                        level.imageart.offsetx =  page.unit == 'px' ? level.imageart.offsetx : transitionPx(level.imageart.offsetx);
                        level.imageart.offsety =  page.unit == 'px' ? level.imageart.offsety : transitionPx(level.imageart.offsety);
                        level.imageart.geometry = {
                            width:  page.unit == 'px' ? level.imageart.geometry.width : transitionPx(level.imageart.geometry.width),
                            height:  page.unit == 'px' ? level.imageart.geometry.height : transitionPx(level.imageart.geometry.height),
                            x:  page.unit == 'px' ? level.imageart.geometry.x : transitionPx(level.imageart.geometry.x),
                            y:  page.unit == 'px' ? level.imageart.geometry.y : transitionPx(level.imageart.geometry.y)
                        };
                        level.imageart.rotation.angle = -level.imageart.rotation.angle;
                        if (level.imageart.resource.identifier) {
                            getBackgroundSource($http, level.imageart.resource, level.imageart.geometry.width, level.imageart.geometry.height, document.pages.page[index].levels.level[i].imageart, $scope);
                        }
                    }
                    if((flag=='jianyi'||flag=='gaoji')&&level.imagebox&&level.imagebox.geometry){
                        var img = level.imagebox;
                        page.isImagebox = true;
                        img.transparency = isNaN(img.transparency) ? 1 : img.transparency;
                        img.geometry = {
                            width:  page.unit == 'px' ? img.geometry.width : transitionPx(img.geometry.width),
                            height:  page.unit == 'px' ? img.geometry.height : transitionPx(img.geometry.height),
                            x:  page.unit == 'px' ? img.geometry.x : transitionPx(img.geometry.x),
                            y:  page.unit == 'px' ? img.geometry.y : transitionPx(img.geometry.y)
                        };
                        img.imgUrl = 'content/images/mask_souce.png';
                        img.maskUrl = 'content/images/mask_souce.png';
                        img.current = 'imagebox';
                        img.levelI = i;
                        img.pageI = index;
                        img.lock = true;
                        img.rotation.angle = -img.rotation.angle;

                        if(vm.pid){
                            img.bokehtype = vm.article.bokehtype || "None";
                        }

                        if (img.mask.resource.identifier) {
                            img.maskUrl = img.mask.resource.identifier + '?imageMogr2';
                        }

                        if((!img.image||!img.image.resource)&&level.variable&&vm.variableBoxDatas.length&&level.name){
                            for (var _i = 0; _i < vm.variableBoxDatas.length; _i++) {
                                if(vm.variableBoxDatas[_i].name==level.name){
                                    if(!vm.variableBoxDatas[_i].value){
                                        vm.variableBoxDatas[_i].value = 'https://photoavatar-pic-dev.deltazo.com/file-463ea37f-1577-4451-a903-dc534e83f5f1'
                                    }
                                    vm.variableBoxDatas[_i].identifier = vm.variableBoxDatas[_i].value;
                                    $.ajax({
                                        type: "GET",
                                        url: vm.variableBoxDatas[_i].value + "?imageInfo",
                                        async: false,
                                        success: function (va) {
                                            vm.variableBoxDatas[_i].imgSize = va.size;
                                            if (va.orientation == "Left-bottom" || va.orientation == "Right-top" || va.orientation == "Left-top" || va.orientation == "Right-bottom") {
                                                vm.variableBoxDatas[_i].width = va.height;
                                                vm.variableBoxDatas[_i].height = va.width;
                                            }else{
                                                vm.variableBoxDatas[_i].width = va.width;
                                                vm.variableBoxDatas[_i].height = va.height;
                                            }
                                            img.image = {
                                                resource: {
                                                    identifier: vm.variableBoxDatas[_i].value,
                                                    provider: 'qiniu',
                                                    width: vm.variableBoxDatas[_i].width,
                                                    height: vm.variableBoxDatas[_i].height
                                                },
                                                parameter: [],
                                                offsetx: 0,
                                                offsety: 0,
                                                angle: 0,
                                                scale: 1,
                                                index: i,
                                                imageInfo: va,
                                                width: vm.variableBoxDatas[_i].width,
                                                height: vm.variableBoxDatas[_i].height,
                                                imgSize: vm.variableBoxDatas[_i].imgSize,
                                                resourceid: '',
                                                scaleH: 1,
                                                scaleW: 1,
                                            }
                                        },
                                        error: function (request) {

                                        }
                                    });
                                    break
                                }
                            }
                        }
                        if (img.image && img.image.resource && img.image.resource.identifier) {       //qiniu
                            img.imgUrl = img.image.resource.identifier;
                            img.image = {
                                resource: {
                                    identifier: img.image ? img.image.resource.identifier : '',
                                    provider: img.image ? img.image.resource.provider : 'qiniu',
                                    width: img.image.width,
                                    height: img.image.height
                                },
                                filterResource: {
                                    identifier: img.image && img.image.filterResource ? img.image.filterResource.identifier : '',
                                    provider: img.image && img.image.filterResource ? img.image.filterResource.provider : 'qiniu'
                                },
                                imgsize: img.image.imgsize == null ? '' : img.image.imgsize,
                                parameter: img.image.parameter == null ? [] : angular.fromJson(img.image.parameter),
                                filter: img.image.filter == null ? '' : img.image.filter,
                                offsetx: img.image.offsetx?( page.unit == 'px' ? img.image.offsetx : transitionPx(img.image.offsetx)):0,
                                offsety: img.image.offsety? (page.unit == 'px' ? img.image.offsety : transitionPx(img.image.offsety)):0,
                                angle: img.image && img.image.angle ? img.image.angle : 0,
                                scale: img.image.scale == 1 ? 1 : img.image.scale,
                                index: (!img.image) ? '' : img.image.index,
                                resourceid: (!img.image) ? '' : img.image.resourceid,
                                imageInfo: (!img.image) ? '' : img.image.imageInfo,
                                width: (!img.image) ? '' :  (page.unit == 'px' ? img.image.width : transitionPx(img.image.width)),
                                height: (!img.image) ? '' :  (page.unit == 'px' ? img.image.height : transitionPx(img.image.height)),
                            }
                            if(!vm.pid){
                                if(img.blurredrectangle){
                                    img.blurredrectangle={
                                        width: page.unit == 'px' ? img.blurredrectangle.width : transitionPx(img.blurredrectangle.width),
                                        height: page.unit == 'px' ? img.blurredrectangle.height : transitionPx(img.blurredrectangle.height),
                                        x: page.unit == 'px' ? img.blurredrectangle.x : transitionPx(img.blurredrectangle.x),
                                        y: page.unit == 'px' ? img.blurredrectangle.y : transitionPx(img.blurredrectangle.y)
                                    }
                                }
                                getBackgroundSource4(img,img.geometry.width,img.geometry.height,img.image,$scope,vm,f)
                            }else{
                                var attr = 'center';
                                if(page.backup){
                                    attr = 'backup'
                                }
                                if(!img.image.imageInfo){
                                    $http({url: img.image.resource.identifier + '?imageInfo'}).success(function (data) {
                                        img.image.angle = orientationInit1(data.orientation).angle;
                                        img.flip = orientationInit1(data.orientation).flip;
                                        var _data = orientationInit(data);
                                        img.image.width = _data.width;
                                        img.image.height = _data.height;
                                        imgScale(img, index, i,0,0, attr,$scope,vm,f);
                                    })
                                }else{
                                    var data = img.image.imageInfo;
                                    img.image.angle = orientationInit1(data.orientation).angle;
                                    img.flip = orientationInit1(data.orientation).flip;
                                    var _data = orientationInit(data);
                                    img.image.width = _data.width;
                                    img.image.height = _data.height;
                                    imgScale(img, index, i,0,0, attr,$scope,vm,f);
                                }
                            }
                        }else{
                            img.image = {
                                resource: null,
                                filterResource: {
                                    identifier: '',
                                    provider: ''
                                },
                                offsetx: 0,
                                offsety: 0,
                                width: '',
                                height: '',
                                imgsize: '',
                                index: '',
                                parameter: [],
                                scale: 1,
                                angle: 0,
                            }
                            f++;
                            if (f >= vm.FirstSeven) {
                                showPages();
                            }
                        }
                    }else if (level.imagebox&&level.imagebox.geometry) {
                        page.isImagebox = true;
                        level.imagebox.lock = true;
                        level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                        level.imagebox.geometry = {
                            width:  page.unit == 'px' ? level.imagebox.geometry.width : transitionPx(level.imagebox.geometry.width),
                            height:  page.unit == 'px' ? level.imagebox.geometry.height : transitionPx(level.imagebox.geometry.height),
                            x:  page.unit == 'px' ? level.imagebox.geometry.x : transitionPx(level.imagebox.geometry.x),
                            y:  page.unit == 'px' ? level.imagebox.geometry.y : transitionPx(level.imagebox.geometry.y)
                        };
                        level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                        level.imagebox.bokehtypes = angular.copy(vm.article.bokehtype);
                        if (!vm.isEdit || vm.editProductXml) {
                            if(vm.variableBoxDatas && vm.variableBoxDatas.length && vm.variableBoxDatas.length>0){
                                for (var _i = 0; _i < vm.variableBoxDatas.length; _i++) {
                                    if(vm.variableBoxDatas[_i].name==level.name){
                                        if(!vm.variableBoxDatas[_i].value){
                                            vm.variableBoxDatas[_i].value = 'https://photoavatar-pic-dev.deltazo.com/file-463ea37f-1577-4451-a903-dc534e83f5f1'
                                        }
                                        vm.variableBoxDatas[_i].identifier = vm.variableBoxDatas[_i].value;
                                        level.imagebox.image = {
                                            resource: {
                                                identifier: vm.variableBoxDatas[_i].value,
                                                provider: 'qiniu',
                                            },
                                            parameter: [],
                                            offsetx: 0,
                                            offsety: 0,
                                            angle: 0,
                                            scale: 1,
                                            width: '',
                                            height: '',
                                            imgSize: '',
                                            resourceid: '',
                                            scaleH: 1,
                                            scaleW: 1,
                                        }
                                        break
                                    }
                                }
                            }
                            if (!vm.onePage && vm.photoSelectAll && vm.photoSelectAll.length > _imgSum && !page.backup) {
                                level.imagebox.bokehtype = vm.article.bokehtype;
                                if (!level.imagebox.image || !level.imagebox.image.resource) {
                                    var _imagebox = imgFill($scope, vm, _imgSum);
                                    _imgSum++
                                    level.imagebox.image = {
                                        blurredrectangle: {
                                            width: 0,
                                            height: 0,
                                            x: 0,
                                            y: 0
                                        },
                                        resource: {
                                            identifier: _imagebox ? _imagebox.identifier : '',
                                            provider: _imagebox ? _imagebox.provider : 'qiniu'
                                        },
                                        resourceid:_imagebox ? _imagebox.id : '',
                                        filterResource: {
                                            identifier: '',
                                            provider: 'qiniu'
                                        },
                                        angle: 0,
                                        offsetx: 0,
                                        offsety: 0,
                                        imageInfo: _imagebox ? _imagebox.imageInfo : '',
                                        width: _imagebox ? _imagebox.width : '',
                                        height: _imagebox ? _imagebox.height : '',
                                        imgSize: _imagebox ? _imagebox.imgSize : '',
                                        index: _imagebox ? _imagebox.seq : '',
                                        parameter: _imagebox ? angular.fromJson(_imagebox.parameter) : [],
                                        scale: 1
                                    }
                                    if (level.imagebox.image.resource.identifier) {
                                        addImagebox($http, level.imagebox, function () {})
                                    }
                                }else if (level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                                    level.imagebox.image = {
                                        resource: {
                                            identifier: level.imagebox.image.resource.identifier,
                                            provider: level.imagebox.image.resource.provider
                                        },
                                        resourceid:level.imagebox.image.resourceid,
                                        filterResource: {
                                            identifier: '',
                                            provider: ''
                                        },
                                        width: '',
                                        height: '',
                                        imgSize: '',
                                        offsetx:  page.unit == 'px' ? level.imagebox.image.offsetx : transitionPx(level.imagebox.image.offsetx),
                                        offsety:  page.unit == 'px' ? level.imagebox.image.offsety : transitionPx(level.imagebox.image.offsety),
                                        index: '',
                                        parameter: [],
                                        scale: level.imagebox.image.scale,
                                        angle: level.imagebox.image.angle,
                                    }
                                    addImagebox($http, level.imagebox, function () {})
                                }
                            } else {
                                if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                                    level.imagebox.bokehtype = level.imagebox.bokehtype || 'None';
                                    level.imagebox.image = {
                                        resource: {
                                            identifier: level.imagebox.image.resource.identifier,
                                            provider: level.imagebox.image.resource.provider
                                        },
                                        resourceid:level.imagebox.image.resourceid,
                                        filterResource: {
                                            identifier: '',
                                            provider: ''
                                        },
                                        width: '',
                                        height: '',
                                        imgSize: '',
                                        offsetx:  page.unit == 'px' ? level.imagebox.image.offsetx : transitionPx(level.imagebox.image.offsetx),
                                        offsety:  page.unit == 'px' ? level.imagebox.image.offsety : transitionPx(level.imagebox.image.offsety),
                                        index: '',
                                        parameter: [],
                                        scale: level.imagebox.image.scale,
                                        angle: level.imagebox.image.angle,
                                    }
                                    addImagebox($http, level.imagebox, function () {})
                                } else {
                                    level.imagebox.bokehtype = vm.article.bokehtype;
                                    level.imagebox.image = {
                                        resource: {
                                            identifier: '',
                                            provider: ''
                                        },
                                        resourceid:"",
                                        filterResource: {
                                            identifier: '',
                                            provider: ''
                                        },
                                        width: '',
                                        height: '',
                                        imgSize: '',
                                        offsetx: 0,
                                        offsety: 0,
                                        index: '',
                                        parameter: [],
                                        scale: 1,
                                        angle: 0,
                                    }
                                }
                            }
                        }
                        if (vm.isEdit && !vm.editProductXml) {
                            level.imagebox.bokehtype = level.imagebox.bokehtype;
                            if (level.imagebox.image) {
                                level.imagebox.image.offsetx =  page.unit == 'px' ? level.imagebox.image.offsetx : transitionPx(level.imagebox.image.offsetx);
                                level.imagebox.image.offsety =  page.unit == 'px' ? level.imagebox.image.offsety : transitionPx(level.imagebox.image.offsety);
                                if (level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                                    // if(flag == 'processing'){
                                    //     getBackgroundSource3(level.imagebox, level.imagebox.geometry.width, level.imagebox.geometry.height, level.imagebox.image, level.imagebox.bokehtype, $scope, function () {
                                    //         $scope.$apply();
                                    //     });
                                    // }else{
                                        getBackgroundSource1(level.imagebox.image.resource, level.imagebox.geometry.width, level.imagebox.geometry.height, document.pages.page[index].levels.level[i].imagebox.image, level.imagebox.bokehtype, $scope, function () {
                                        })
                                    // }
                                }
                            }
                            if (level.imagebox.blurredrectangle) {
                                level.imagebox.blurredrectangle = {
                                    width:  page.unit == 'px' ? level.imagebox.blurredrectangle.width : transitionPx(level.imagebox.blurredrectangle.width),
                                    height:  page.unit == 'px' ? level.imagebox.blurredrectangle.height : transitionPx(level.imagebox.blurredrectangle.height),
                                    x:  page.unit == 'px' ? level.imagebox.blurredrectangle.x : transitionPx(level.imagebox.blurredrectangle.x),
                                    y:  page.unit == 'px' ? level.imagebox.blurredrectangle.y : transitionPx(level.imagebox.blurredrectangle.y)
                                };
                            }
                        }
                    }
                    if (level.textbox && level.textbox.geometry) {
                        level.textbox.lock = true;
                        level.textbox.lock = true;
                        level.textbox.levelI = i;
                        level.textbox.pageI = index;
                        level.textbox.geometry = {
                            width:  page.unit == 'px' ? level.textbox.geometry.width : transitionPx(level.textbox.geometry.width),
                            height:  page.unit == 'px' ? level.textbox.geometry.height : transitionPx(level.textbox.geometry.height),
                            x:  page.unit == 'px' ? level.textbox.geometry.x : transitionPx(level.textbox.geometry.x),
                            y:  page.unit == 'px' ? level.textbox.geometry.y : transitionPx(level.textbox.geometry.y)
                        };
                        level.textbox.rotation.angle = -level.textbox.rotation.angle;
                        if (level.textbox.fontuuid) {
                            //下载字体
                            getFontCSS(FontManagement, level.textbox, $scope);
                            FontSupport2(FontManagement, level.textbox);
                        }
                        if (level.textbox.style.bold && level.textbox.style.italic) {
                            level.textbox.styles = 'BOLD_ITALIC';
                        } else if (level.textbox.style.bold) {
                            level.textbox.styles = 'BOLD';
                        } else if (level.textbox.style.italic) {
                            level.textbox.styles = 'ITALIC';
                        } else {
                            level.textbox.styles = 'NORMAL';
                        }
                        if(level.variable){
                            if(vm.variableTextDatas && vm.variableTextDatas.length&&level.name){
                                for (var _i = 0; _i < vm.variableTextDatas.length; _i++) {
                                    if(vm.variableTextDatas[_i].name==level.name){
                                        level.textbox.text = vm.variableTextDatas[_i].value;
                                        break
                                    }
                                }
                            }else if(level.name){
                                level.textbox.text = level.name;
                            }
                            // toImg(textbox, page.styleScale2);
                        }
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        var textdocument = angular.copy(level.textdocument);
                        level.textdocument = {
                            background: textdocument.background,
                            geometry: {
                                width:  page.unit == 'px' ? textdocument.geometry.width : transitionPx(textdocument.geometry.width),
                                height:  page.unit == 'px' ? textdocument.geometry.height : transitionPx(textdocument.geometry.height),
                                x:  page.unit == 'px' ? textdocument.geometry.x : transitionPx(textdocument.geometry.x),
                                y:  page.unit == 'px' ? textdocument.geometry.y : transitionPx(textdocument.geometry.y)
                            },
                            rotation: {
                                angle: -textdocument.rotation.angle!= null? -textdocument.rotation.angle : 0
                            },
                            current: 'textdocument',
                            levels: angular.copy(textdocument.levels),
                            lock: true,
                            transparency: textdocument.transparency,
                            movable: textdocument.movable,
                            useradded: textdocument.useradded,
                            editable: textdocument.editable,
                            scale: textdocument.geometry.width / page.mediabox.width,
                            levelI:i,
                            pageI: index
                        }

                        var backg = textdocument.background;
                        var pageW = textdocument.geometry.width,
                            pageH = textdocument.geometry.height;
                        if (backg.resource.identifier) {
                            getBackgroundSource($http, backg.resource, pageW, pageH, document.pages.page[index].levels.level[i].textdocument.background, $scope);
                            backg.offsetx =  page.unit == 'px' ? backg.offsetx : transitionPx(backg.offsetx);
                            backg.offsety =  page.unit == 'px' ? backg.offsety : transitionPx(backg.offsety);
                        }

                        angular.forEach(textdocument.levels.level, function (lev, l) {
                            if (lev.imageart && lev.imageart.geometry) {
                                var art = lev.imageart;
                                $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                    level.textdocument.levels.level[l].imageart = {
                                        geometry: {
                                            width:  page.unit == 'px' ? art.geometry.width : transitionPx(art.geometry.width),
                                            height:  page.unit == 'px' ? art.geometry.height : transitionPx(art.geometry.height),
                                            x:  page.unit == 'px' ? art.geometry.x : transitionPx(art.geometry.x),
                                            y:  page.unit == 'px' ? art.geometry.y : transitionPx(art.geometry.y)
                                        },
                                        rotation: angular.copy(art.rotation),
                                        type: art.type,
                                        resource: {
                                            identifier: art.resource.identifier,
                                            provider: art.resource.provider,
                                            width: data.width,
                                            height: data.height
                                        },
                                        scale: art.scale,
                                        offsetx:  page.unit == 'px' ? art.offsetx : transitionPx(art.offsetx),
                                        offsety:  page.unit == 'px' ? art.offsety : transitionPx(art.offsety),
                                        current: 'imageart',
                                        lock: false,
                                        transparency: art.transparency,
                                        onlyshow: art.onlyshow,
                                        movable: art.movable,
                                        useradded: art.useradded,
                                        editable: art.editable,
                                        levelI:i,
                                        pageI: index
                                    }
                                }).error(function (error) {
                                });
                            }
                            if (lev.textbox && lev.textbox.geometry) {
                                var text = lev.textbox;
                                level.textdocument.levels.level[l].textbox = {
                                    geometry: {
                                        width:  page.unit == 'px' ? text.geometry.width : transitionPx(text.geometry.width),
                                        height:  page.unit == 'px' ? text.geometry.height : transitionPx(text.geometry.height),
                                        x:  page.unit == 'px' ? text.geometry.x : transitionPx(text.geometry.x),
                                        y:  page.unit == 'px' ? text.geometry.y : transitionPx(text.geometry.y)
                                    },
                                    identifier: text.identifier,
                                    rotation: angular.copy(text.rotation),
                                    angle: angular.copy(text.angle),
                                    status: angular.copy(text.status),
                                    style: angular.copy(text.style),
                                    type: angular.copy(text.type),
                                    text: angular.copy(text.text),
                                    fontStyle: [{
                                        style: '',
                                        path: text.identifier
                                    }],
                                    current: 'textbox',
                                    lock: true,
                                    transparency: text.transparency,
                                    fontuuid: text.fontuuid,
                                    version: text.version,
                                    levelI:i,
                                    pageI: index
                                }
                                getFontCSS(FontManagement, text, $scope)
                            }
                        })
                    }
                    if (level.calMonthBox && level.calMonthBox.geometry) {
                        var calMonthBox = level.calMonthBox;
                        calMonthBox.lock = true;
                        calMonthBox.geometry = {
                            width:  page.unit == 'px' ? calMonthBox.geometry.width : transitionPx(calMonthBox.geometry.width),
                            height:  page.unit == 'px' ? calMonthBox.geometry.height : transitionPx(calMonthBox.geometry.height),
                            x:  page.unit == 'px' ? calMonthBox.geometry.x : transitionPx(calMonthBox.geometry.x),
                            y:  page.unit == 'px' ? calMonthBox.geometry.y : transitionPx(calMonthBox.geometry.y)
                        };

                        calMonthBox.sampleYear = getDay('year',document.config.startYear,document.config.startMonth,page.config.monthIndex);
                        calMonthBox.sampleMonth = getDay('month',document.config.startYear,document.config.startMonth,page.config.monthIndex);
                        calMonthBox.rotation ={
                            angle:0
                        };
                    }
                    if (level.calTitleBox && level.calTitleBox.geometry) {
                        var calTitleBox = level.calTitleBox;
                        calTitleBox.lock = true;
                        calTitleBox.geometry = {
                            width:  page.unit == 'px' ? calTitleBox.geometry.width : transitionPx(calTitleBox.geometry.width),
                            height:  page.unit == 'px' ? calTitleBox.geometry.height : transitionPx(calTitleBox.geometry.height),
                            x:  page.unit == 'px' ? calTitleBox.geometry.x : transitionPx(calTitleBox.geometry.x),
                            y:  page.unit == 'px' ? calTitleBox.geometry.y : transitionPx(calTitleBox.geometry.y)
                        };
                        calTitleBox.sampleYear = getDay('year',document.config.startYear,document.config.startMonth,page.config.monthIndex);
                        calTitleBox.sampleMonth = getDay('month',document.config.startYear,document.config.startMonth,page.config.monthIndex);
                        calTitleBox.rotation ={
                            angle:0
                        };
                    }
                })(level, i)
            })
            getPageType(page);
            page.unit = 'px';
        })(page, index)
    })
    _imgSum = 0;
    setTimeout(function () {
        callback.call(this,document);
        $scope.$apply();
    },1000)
}
function documentMM(template) {
    var _template = angular.copy(template);
    _template.isSize = false;
    angular.forEach(_template.pages.page, function (page, index) {
        page.recommendLayout = null;
        if (page.editbox == null) {
            page.editbox = page.trimbox;
        }
        page.editbox = {
            width: page.unit == 'mm' ? page.editbox.width : transitionMm(page.editbox.width),
            height: page.unit == 'mm' ? page.editbox.height : transitionMm(page.editbox.height),
            x: page.unit == 'mm' ? page.editbox.x : transitionMm(page.editbox.x),
            y: page.unit == 'mm' ? page.editbox.y : transitionMm(page.editbox.y)
        };
        if(page.editboxes && page.editboxes.editbox){
            page.editboxes.editbox.forEach(function (editbox, editboxIndex) {
                page.editboxes.editbox[editboxIndex] = {
                    width: page.unit == 'mm' ? editbox.width : transitionMm(editbox.width),
                    height: page.unit == 'mm' ? editbox.height : transitionMm(editbox.height),
                    x: page.unit == 'mm' ? editbox.x : transitionMm(editbox.x),
                    y: page.unit == 'mm' ? editbox.y : transitionMm(editbox.y)
                }
            })
        }
        page.mediabox = {
            width: page.unit == 'mm' ? page.mediabox.width : transitionMm(page.mediabox.width),
            height: page.unit == 'mm' ? page.mediabox.height : transitionMm(page.mediabox.height),
            x: page.unit == 'mm' ? page.mediabox.x : transitionMm(page.mediabox.x),
            y: page.unit == 'mm' ? page.mediabox.y : transitionMm(page.mediabox.y)
        };
        page.trimbox = {
            width: page.unit == 'mm' ? page.trimbox.width : transitionMm(page.trimbox.width),
            height: page.unit == 'mm' ? page.trimbox.height : transitionMm(page.trimbox.height),
            x: page.unit == 'mm' ? page.trimbox.x : transitionMm(page.trimbox.x),
            y: page.unit == 'mm' ? page.trimbox.y : transitionMm(page.trimbox.y)
        };
        page.custom = {};
        if (page.sceneCopy) {
            page.scene = angular.copy(page.sceneCopy);
        }
        if (page.scene) {
            page.scene.geometry = {
                width: page.unit == 'mm' ? page.scene.geometry.width : transitionMm(page.scene.geometry.width),
                height: page.unit == 'mm' ? page.scene.geometry.height : transitionMm(page.scene.geometry.height),
                x: page.unit == 'mm' ? page.scene.geometry.x : transitionMm(page.scene.geometry.x),
                y: page.unit == 'mm' ? page.scene.geometry.y : transitionMm(page.scene.geometry.y)
            };
            page.scene.imagebox.geometry = {
                width: page.unit == 'mm' ? page.scene.imagebox.geometry.width : transitionMm(page.scene.imagebox.geometry.width),
                height: page.unit == 'mm' ? page.scene.imagebox.geometry.height : transitionMm(page.scene.imagebox.geometry.height),
                x: page.unit == 'mm' ? page.scene.imagebox.geometry.x : transitionMm(page.scene.imagebox.geometry.x),
                y: page.unit == 'mm' ? page.scene.imagebox.geometry.y : transitionMm(page.scene.imagebox.geometry.y)
            };
        }
        //条形码
        if (page.barcode) {
            page.barcode.geometry = {
                width: page.unit == 'mm' ? page.barcode.geometry.width : transitionMm(page.barcode.geometry.width),
                height: page.unit == 'mm' ? page.barcode.geometry.height : transitionMm(page.barcode.geometry.height),
                x: page.unit == 'mm' ? page.barcode.geometry.x : transitionMm(page.barcode.geometry.x),
                y: page.unit == 'mm' ? page.barcode.geometry.y : transitionMm(page.barcode.geometry.y)
            };
        }
        if (page.qrcode) {
            page.qrcode.geometry = {
                width: page.unit == 'mm' ? page.qrcode.geometry.width : transitionMm(page.qrcode.geometry.width),
                height: page.unit == 'mm' ? page.qrcode.geometry.height : transitionMm(page.qrcode.geometry.height),
                x: page.unit == 'mm' ? page.qrcode.geometry.x : transitionMm(page.qrcode.geometry.x),
                y: page.unit == 'mm' ? page.qrcode.geometry.y : transitionMm(page.qrcode.geometry.y)
            };
        }

        //背景
        if (page.background && page.background.resource &&page.background.resource.identifier) {
            page.background.offsetx = page.unit == 'mm' ? page.background.offsetx : transitionMm(page.background.offsetx);
            page.background.offsety = page.unit == 'mm' ? page.background.offsety : transitionMm(page.background.offsety);
        }
        angular.forEach(page.levels.level, function (level, i) {
            if (level.imageart && level.imageart.geometry) {
                level.imageart.rotation.angle = -level.imageart.rotation.angle;
                level.imageart.offsetx = page.unit == 'mm' ? level.imageart.offsetx : transitionMm(level.imageart.offsetx);
                level.imageart.offsety = page.unit == 'mm' ? level.imageart.offsety : transitionMm(level.imageart.offsety);
                level.imageart.width = page.unit == 'mm' ? level.imageart.width : transitionMm(level.imageart.width);
                level.imageart.height = page.unit == 'mm' ? level.imageart.height : transitionMm(level.imageart.height);
                level.imageart.geometry = {
                    width: page.unit == 'mm' ? level.imageart.geometry.width : transitionMm(level.imageart.geometry.width),
                    height: page.unit == 'mm' ? level.imageart.geometry.height : transitionMm(level.imageart.geometry.height),
                    x: page.unit == 'mm' ? level.imageart.geometry.x : transitionMm(level.imageart.geometry.x),
                    y: page.unit == 'mm' ? level.imageart.geometry.y : transitionMm(level.imageart.geometry.y)
                };
            }
            if (level.imagebox && level.imagebox.geometry) {
                level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                level.imagebox.geometry = {
                    width: page.unit == 'mm' ? level.imagebox.geometry.width : transitionMm(level.imagebox.geometry.width),
                    height: page.unit == 'mm' ? level.imagebox.geometry.height : transitionMm(level.imagebox.geometry.height),
                    x: page.unit == 'mm' ? level.imagebox.geometry.x : transitionMm(level.imagebox.geometry.x),
                    y: page.unit == 'mm' ? level.imagebox.geometry.y : transitionMm(level.imagebox.geometry.y)
                };
                if (level.imagebox.blurredrectangle) {
                    level.imagebox.blurredrectangle = {
                        width: page.unit == 'mm' ? level.imagebox.blurredrectangle.width : transitionMm(level.imagebox.blurredrectangle.width),
                        height: page.unit == 'mm' ? level.imagebox.blurredrectangle.height : transitionMm(level.imagebox.blurredrectangle.height),
                        x: page.unit == 'mm' ? level.imagebox.blurredrectangle.x : transitionMm(level.imagebox.blurredrectangle.x),
                        y: page.unit == 'mm' ? level.imagebox.blurredrectangle.y : transitionMm(level.imagebox.blurredrectangle.y)
                    };
                }
                if (level.imagebox.image) {
                    // if(flag=='jianyi'||flag=='gaoji'){
                    //     if (level.imagebox.image.resource) {
                    //         if ((level.imagebox.geometry.width + Math.abs(level.imagebox.image.offsetx) * level.imagebox.image.scale).toFixed(4) - 0 > (level.imagebox.image.width * level.imagebox.image.scale).toFixed(4) - 0) {
                    //             level.imagebox.image.offsetx = -Math.abs(level.imagebox.image.width * level.imagebox.image.scale - level.imagebox.geometry.width) / level.imagebox.image.scale;
                    //         }
                    //         if ((level.imagebox.geometry.height + Math.abs(level.imagebox.image.offsety) * level.imagebox.image.scale).toFixed(4) - 0 > (level.imagebox.image.height * level.imagebox.image.scale).toFixed(4) - 0) {
                    //             level.imagebox.image.offsety = -Math.abs(level.imagebox.image.height * level.imagebox.image.scale - level.imagebox.geometry.height) / level.imagebox.image.scale;
                    //         }
                    //     }
                    // }
                    level.imagebox.image.width = page.unit == 'mm' ? level.imagebox.image.width : transitionMm(level.imagebox.image.width);
                    level.imagebox.image.height = page.unit == 'mm' ? level.imagebox.image.height : transitionMm(level.imagebox.image.height);
                    level.imagebox.image.offsetx = page.unit == 'mm' ? level.imagebox.image.offsetx : transitionMm(level.imagebox.image.offsetx);
                    level.imagebox.image.offsety = page.unit == 'mm' ? level.imagebox.image.offsety : transitionMm(level.imagebox.image.offsety);
                }
            }
            if (level.textbox && level.textbox.geometry) {
                if (!(level.textbox.text == '' || level.textbox.text == null || level.textbox.text == '双击输入文字' || level.textbox.text == '请输入文字') && (level.textbox.geometry.width != 0 && level.textbox.geometry.height != 0)) {
                    level.textbox.rotation.angle = -level.textbox.rotation.angle;
                    level.textbox.geometry = {
                        width: page.unit == 'mm' ? level.textbox.geometry.width : transitionMm(level.textbox.geometry.width),
                        height: page.unit == 'mm' ? level.textbox.geometry.height : transitionMm(level.textbox.geometry.height),
                        x: page.unit == 'mm' ? level.textbox.geometry.x : transitionMm(level.textbox.geometry.x),
                        y: page.unit == 'mm' ? level.textbox.geometry.y : transitionMm(level.textbox.geometry.y)
                    };
                }else{
                    level.textbox = null;
                }
            }
            if (level.textdocument && level.textdocument.geometry) {
                level.textdocument.rotation.angle = -level.textdocument.rotation.angle;
                level.textdocument.geometry = {
                    width: page.unit == 'mm' ? level.textdocument.geometry.width : transitionMm(level.textdocument.geometry.width),
                    height: page.unit == 'mm' ? level.textdocument.geometry.height : transitionMm(level.textdocument.geometry.height),
                    x: page.unit == 'mm' ? level.textdocument.geometry.x : transitionMm(level.textdocument.geometry.x),
                    y: page.unit == 'mm' ? level.textdocument.geometry.y : transitionMm(level.textdocument.geometry.y)
                };

                var backg = level.textdocument.background;
                if (backg.resource.identifier) {
                    backg.offsetx = page.unit == 'mm' ? backg.offsetx : transitionMm(backg.offsetx);
                    backg.offsety = page.unit == 'mm' ? backg.offsety : transitionMm(backg.offsety);
                }

                angular.forEach(level.textdocument.levels.level, function (lev, l) {
                    if (lev.imageart && lev.imageart.geometry) {
                        var art = lev.imageart;
                        art.geometry = {
                            width: page.unit == 'mm' ? art.geometry.width : transitionMm(art.geometry.width),
                            height: page.unit == 'mm' ? art.geometry.height : transitionMm(art.geometry.height),
                            x: page.unit == 'mm' ? art.geometry.x : transitionMm(art.geometry.x),
                            y: page.unit == 'mm' ? art.geometry.y : transitionMm(art.geometry.y)
                        };
                        art.offsetx = page.unit == 'mm' ? art.offsetx : transitionMm(art.offsetx);
                        art.offsety = page.unit == 'mm' ? art.offsety : transitionMm(art.offsety);
                    }
                    if (lev.textbox && lev.textbox.geometry) {
                        var text = lev.textbox;
                        text.geometry = {
                            width: page.unit == 'mm' ? text.geometry.width : transitionMm(text.geometry.width),
                            height: page.unit == 'mm' ? text.geometry.height : transitionMm(text.geometry.height),
                            x: page.unit == 'mm' ? text.geometry.x : transitionMm(text.geometry.x),
                            y: page.unit == 'mm' ? text.geometry.y : transitionMm(text.geometry.y)
                        };
                    }
                })
            }
            if (level.calTitleBox && level.calTitleBox.geometry) {
                var _calTitleBox = angular.copy(level.calTitleBox);
                level.calTitleBox.geometry = {
                    width: page.unit == 'mm' ? level.calTitleBox.geometry.width : transitionMm(level.calTitleBox.geometry.width),
                    height: page.unit == 'mm' ? level.calTitleBox.geometry.height : transitionMm(level.calTitleBox.geometry.height),
                    x: page.unit == 'mm' ? level.calTitleBox.geometry.x : transitionMm(level.calTitleBox.geometry.x),
                    y: page.unit == 'mm' ? level.calTitleBox.geometry.y : transitionMm(level.calTitleBox.geometry.y)
                };
                level.calTitleBox.rotation.angle = -level.calTitleBox.rotation.angle;
                level.calTitleBox.settings={
                    border:level.calTitleBox.settings.border,
                    items:{
                        item:angular.copy(_calTitleBox.settings.items.item?_calTitleBox.settings.items.item:_calTitleBox.settings.items)
                    }
                };
                level.calTitleBox.layout={
                    geometry: {
                        width:level.calTitleBox.layout.width,
                        height:level.calTitleBox.layout.height,
                        x:0,
                        y:0,
                    },
                    previewUrl:level.calTitleBox.layout.previewUrl,
                    type:level.calTitleBox.layout.type,
                    items:{
                        item:angular.copy(_calTitleBox.layout.items.item?_calTitleBox.layout.items.item:_calTitleBox.layout.items),
                    }
                };
                level.calTitleBox.layout.items.item.forEach(function (item) {
                    item.geometry = {
                        width:item.w,
                        height:item.h,
                        x:item.x,
                        y:item.y,
                    }
                })
            }
            if (level.calMonthBox && level.calMonthBox.geometry) {
                var _calMonthBox = angular.copy(level.calMonthBox);
                level.calMonthBox.geometry = {
                    width: page.unit == 'mm' ? level.calMonthBox.geometry.width : transitionMm(level.calMonthBox.geometry.width),
                    height: page.unit == 'mm' ? level.calMonthBox.geometry.height : transitionMm(level.calMonthBox.geometry.height),
                    x: page.unit == 'mm' ? level.calMonthBox.geometry.x : transitionMm(level.calMonthBox.geometry.x),
                    y: page.unit == 'mm' ? level.calMonthBox.geometry.y : transitionMm(level.calMonthBox.geometry.y)
                };
                level.calMonthBox.rotation.angle = -level.calMonthBox.rotation.angle;

                level.calMonthBox.weekStyles={item:angular.copy(_calMonthBox.weekStyles.item?_calMonthBox.weekStyles.item:_calMonthBox.weekStyles)};
                level.calMonthBox.weekLayout={
                    geometry: {
                        width:level.calMonthBox.weekLayout.width,
                        height:level.calMonthBox.weekLayout.height,
                        x:0,
                        y:0,
                    },
                    items:{
                        item:angular.copy(_calMonthBox.weekLayout.items.item?_calMonthBox.weekLayout.items.item:_calMonthBox.weekLayout.items)
                    },
                    type:level.calMonthBox.weekLayout.type,
                    previewUrl:level.calMonthBox.weekLayout.previewUrl,
                };
                level.calMonthBox.weekLayout.items.item.forEach(function (weekLayout) {
                    weekLayout.geometry = {
                        width:weekLayout.w,
                        height:weekLayout.h,
                        x:weekLayout.x,
                        y:weekLayout.y,
                    }
                })
                level.calMonthBox.dateStyles={item:angular.copy(_calMonthBox.dateStyles.item?_calMonthBox.dateStyles.item:_calMonthBox.dateStyles)},
                    level.calMonthBox.dateLayout={
                        geometry: {
                            width:level.calMonthBox.dateLayout.width,
                            height:level.calMonthBox.dateLayout.height,
                            x:0,
                            y:0,
                        },
                        items:{
                            item:angular.copy(_calMonthBox.dateLayout.items.item?_calMonthBox.dateLayout.items.item:_calMonthBox.dateLayout.items)
                        },
                        type:level.calMonthBox.dateLayout.type,
                        previewUrl:level.calMonthBox.dateLayout.previewUrl
                    };
                level.calMonthBox.dateLayout.items.item.forEach(function (dateLayout) {
                    dateLayout.geometry = {
                        width:dateLayout.w,
                        height:dateLayout.h,
                        x:dateLayout.x,
                        y:dateLayout.y,
                    }
                })
                level.calMonthBox.weekStyles.item.forEach(function (weekStyle) {
                    var _items = angular.copy(weekStyle.items.item?weekStyle.items.item:weekStyle.items);
                    _items.forEach(function (_item){
                        _item.geometry = {
                            width:_item.w,
                            height:_item.h,
                            x:_item.x,
                            y:_item.y,
                        }
                    })
                    weekStyle.items = {
                        item:_items
                    }
                })
                level.calMonthBox.dateStyles.item.forEach(function (dateStyle) {
                    var _items = angular.copy(dateStyle.items.item?dateStyle.items.item:dateStyle.items);
                    _items.forEach(function (_item){
                        _item.geometry = {
                            width:_item.w,
                            height:_item.h,
                            x:_item.x,
                            y:_item.y,
                        }
                    })
                    dateStyle.items = {
                        item:_items
                    }
                })
            }
        })
        if(page.mediabox.width - page.mediaboxMM.width < 1 || page.mediabox.width - page.mediaboxMM.width > -1){
            page.unit = 'mm';
        }else{
            _template.isSize = true
        }
    })
    if(!_template.isSize){
        _template.version= '4.0';
        return _template
    }else{
        alert('页面尺寸不对')
        documentMM(_template)
    }
}
var fontuuids = [];
function getFontCSS(FontManagement, item, $scope) {
    if(fontuuids.indexOf(item.fontuuid) == -1){
        fontuuids.push(item.fontuuid);
        FontManagement.getCss({uuid: item.fontuuid}, function (res) {
            var style = document.createElement("style");
            style.type = 'text/css';
            try {
                style.appendChild(document.createTextNode(res.data));
            } catch (ex) {
                style.stylesheet.cssText = res.data;
            }
            var Head = document.getElementsByTagName("head")[0];
            Head.appendChild(style);
            document.fonts.ready.then(function () {
                item.loading = false;
                $scope.$apply();
            });
        });
    }else{
        item.loading = false;
    }
}
//获取选择图片
function imgFill($scope, vm, sum, domain) {
    var _imgFillAll = angular.copy(vm.photoSelectAll);
    if(_imgFillAll[sum].identifier.indexOf('http')==-1){
        _imgFillAll[sum].identifier = $scope.domain + _imgFillAll[sum].identifier;
    }
    return _imgFillAll[sum]
}
function getBackgroundSource($http, res, pageW, pageH, item, $scope, callback) {
    if(item.imageInfo){
        getBackgroundSourceCb(item.imageInfo)
    }else{
        $http({url: res.identifier + '?imageInfo'}).success(function (data1) {
            item.imageInfo =data1;
            getBackgroundSourceCb(data1)
        }).error(function (error) {
        });
    }
    function getBackgroundSourceCb(imageInfo){
        var data = orientationInit(imageInfo);
        // var bgImgW = data.width;
        // var bgImgH = data.height;
        // var bgImgR = bgImgW / bgImgH;
        // var bgImgR1 = pageW / pageH;
        // if (bgImgR >= bgImgR1) {
        //     res.adaptation = 'Width';
        // } else {
        //     res.adaptation = 'Height';
        // }
        var bgImgW = pageW;
        var bgImgH = pageH;
        var bgImgR = bgImgW/bgImgH;
        var bgImgR1 = data.width/data.height;
        if(bgImgR >= bgImgR1){
            res.adaptation = 'Width';
        }else {
            res.adaptation = 'Height';
        }


        item.width = data.width;
        item.height = data.height;
        if(callback){
            callback();
        }
    }
}
function identifierInfo(identifier, item, $scope) {
    var newImg1 = new Image();
    newImg1.crossOrigin = "Anonymous";
    newImg1.src = identifier;
    newImg1.onload = function () {
        item.imgWidth = newImg1.width;
        item.imgHeight = newImg1.height;
        $scope.$apply();
    };
}

function getBackgroundSource1(res, pageW, pageH, item, bokehtype, $scope,callback) {
    if(!item.height && !item.width){
        var newImg = new Image();
        newImg.crossOrigin = "Anonymous";
        newImg.src = res.identifier + '?imageMogr2/strip/rotate/' + item.angle + '/thumbnail/!40p';
        newImg.onload = function () {
            getBackgroundSource1Cb(res, pageW, pageH, item, bokehtype, $scope,newImg, function () {
                if(callback){callback()};
                $scope.$apply();
            })
        };
    }else{
        var newImg = {
            width: angular.copy(item.width),
            height: angular.copy(item.height),
        }
        getBackgroundSource1Cb(res, pageW, pageH, item, bokehtype, $scope,newImg, callback)
    }
}
function getBackgroundSource1Cb(res, pageW, pageH, item, bokehtype, $scope,newImg, callback) {
    var imgR = pageW / pageH;
    var imgR1 = newImg.width / newImg.height;
    if (bokehtype == 'None') {
        if (imgR >= imgR1) {
            item.resource.adaptation = 'Height';
            item.width = pageW;
            item.height = pageW / newImg.width * newImg.height;
        } else {
            item.resource.adaptation = 'Width';
            item.width = pageH / newImg.height * newImg.width;
            item.height = pageH;
        }
    } else {
        if (imgR >= imgR1) {
            item.resource.adaptation = 'Height';
            item.width = pageW;
            item.height = pageW / newImg.width * newImg.height;
            item.offsetx = 0;
            item.offsety = 0;
            res.blurredrectangle = {
                width: pageH / newImg.height * newImg.width,
                height: pageH,
                x: (pageW - pageH / newImg.height * newImg.width) / 2,
                y: 0,
            }
        } else {
            item.resource.adaptation = 'Width';
            item.width = pageH / newImg.height * newImg.width;
            item.height = pageH;
            item.offsetx = 0;
            item.offsety = 0;
            res.blurredrectangle = {
                width: pageW,
                height: pageW / newImg.width * newImg.height,
                x: 0,
                y: (pageH - pageW / newImg.width * newImg.height) / 2,
            }
        }
    }
    if(callback){
        callback()
    }
}
function getBackgroundSource2(res, pageW, pageH, item, bokehtype, $scope, callback) {
    var newImg = new Image();
    newImg.crossOrigin = "Anonymous";
    newImg.src = res.image.resource.identifier + '?imageMogr2/strip/rotate/' + item.angle + '/thumbnail/!20p';
    newImg.onload = function () {
        getBackgroundSource1Cb(res, pageW, pageH, item, bokehtype, $scope,newImg, function () {
            if(callback){callback()};
            $scope.$digest();
        })
    };
}
function getBackgroundSource3(res, pageW, pageH, item, bokehtype, $scope, callback) {
    // var newImg = new Image();
    // newImg.crossOrigin = "Anonymous";
    // newImg.src = res.image.resource.identifier + '?imageMogr2/strip/rotate/' + item.angle;
    // newImg.onload = function () {
    var newImg = {
        width : item.width,
        height: item.height,
    };
    var imgR = pageW / pageH;
    var imgR1 = newImg.width / newImg.height;
    item.imgWidth = newImg.width;
    item.imgHeight = newImg.height;
    if (bokehtype == 'None') {
        if (imgR >= imgR1) {
            item.resource.adaptation = 'Height';
            item.width = pageW;
            item.height = pageW / newImg.width * newImg.height;
            item.offsetx = 0;
            item.offsety = -(item.height - pageH) / 2;
        } else {
            item.resource.adaptation = 'Width';
            item.width = pageH / newImg.height * newImg.width;
            item.height = pageH;
            item.offsetx = -(item.width - pageW) / 2;
            item.offsety = 0;
        }
    } else {
        if (imgR >= imgR1) {
            item.resource.adaptation = 'Height';
            item.width = pageW;
            item.height = pageW / newImg.width * newImg.height;
            item.offsetx = 0;
            item.offsety = 0;
            res.blurredrectangle = {
                width: pageH / newImg.height * newImg.width,
                height: pageH,
                x: (pageW - pageH / newImg.height * newImg.width) / 2,
                y: 0,
            }
        } else {
            item.resource.adaptation = 'Width';
            item.width = pageH / newImg.height * newImg.width;
            item.height = pageH;
            item.offsetx = 0;
            item.offsety = 0;
            res.blurredrectangle = {
                width: pageW,
                height: pageW / newImg.width * newImg.height,
                x: 0,
                y: (pageH - pageW / newImg.width * newImg.height) / 2,
            }
        }
    }
    if(callback){
        callback()
    }
    // };
}
function getBackgroundSource4(res,pageW,pageH,item,$scope,vm,f) {
    var newImg = new Image();
    newImg.crossOrigin = "Anonymous";
    newImg.src = item.resource.identifier + '?imageMogr2/strip/rotate/' + item.angle + '/thumbnail/!40p';
    newImg.onload = function () {
        f++;
        if (f >= vm.counts) {
            showPages();
        }

        var w1 = 0,h1 = 0;
        w1 = newImg.width;
        h1 = newImg.height;
        item.imgsize = newImg.size;
        var width = item.width = w1;
        var height = item.height = h1;
        var wOrigin = angular.copy(width);
        var hOrigin = angular.copy(height);

        item.width1= newImg.width;
        item.height1= newImg.height;
        if ((wOrigin <= hOrigin || pageW <= pageH) && (wOrigin / hOrigin < pageW / pageH) || wOrigin >= hOrigin && pageW >= pageH && (wOrigin / hOrigin < pageW / pageH)) {
            res.adaptation = 'Width';
            item.width= pageW;
            item.height= pageW/width*height;
        }else {
            res.adaptation = 'Height';
            item.width= pageH/height * width;
            item.height= pageH;
        }
        $scope.$digest();
    }
    // $.ajax({
    //     type: "GET",
    //     url: item.resource.identifier + "?imageInfo",
    //     success: function (newImg) {
    //         f++;
    //         if (f >= vm.counts) {
    //             showPages();
    //         }
    //
    //         var w1 = 0,h1 = 0;
    //         w1 = orientationInit(newImg).width;
    //         h1 = orientationInit(newImg).height;
    //         item.imgsize = newImg.size;
    //         var width = item.width = w1;
    //         var height = item.height = h1;
    //         if(item.angle == 90||item.angle ==270){
    //             width = h1;
    //             height = w1;
    //         }
    //         var wOrigin = angular.copy(width);
    //         var hOrigin = angular.copy(height);
    //
    //         item.width1= width;
    //         item.height1= height;
    //         if ((wOrigin <= hOrigin || pageW <= pageH) && (wOrigin / hOrigin < pageW / pageH) || wOrigin >= hOrigin && pageW >= pageH && (wOrigin / hOrigin < pageW / pageH)) {
    //             res.adaptation = 'Width';
    //             item.width= pageW;
    //             item.height= pageW/width*height;
    //         }else {
    //             res.adaptation = 'Height';
    //             item.width= pageH/height * width;
    //             item.height= pageH;
    //         }
    //         getDpi(res);
    //         $scope.$digest();
    //     }
    // })
}

function imgScale(imagebox, index, id,x,y,tag,$scope,vm,f) {
    var width = imagebox.image.width;
    var height = imagebox.image.height;
    var wOrigin = angular.copy(width);
    var hOrigin = angular.copy(height);
    // 单页编辑占位框宽高
    var maskWidth = imagebox.geometry.width;
    var maskHight = imagebox.geometry.height;

    if(imagebox.bokehtype == 'None'){
        if ((wOrigin <= hOrigin || maskWidth <= maskHight) && (wOrigin / hOrigin < maskWidth / maskHight) || wOrigin >= hOrigin && maskWidth >= maskHight && (wOrigin / hOrigin < maskWidth / maskHight)) {
            imagebox.adaptation = 'Width';
            imagebox.image.width= imagebox.geometry.width;
            imagebox.image.height= imagebox.geometry.width/width*height;
            imagebox.image.offsetx= x;
            if(tag=='center' || tag=='backup'){
                imagebox.image.offsety = -(imagebox.image.height-imagebox.geometry.height)/2;
            }else{
                imagebox.image.offsety= y;
            }
        } else {
            imagebox.adaptation = 'Height';
            imagebox.image.width= imagebox.geometry.height/height*width;
            imagebox.image.height= imagebox.geometry.height;
            imagebox.image.offsety= y;
            if(tag=='center' || tag=='backup'){
                imagebox.image.offsetx = -(imagebox.image.width-imagebox.geometry.width)/2;
            }else{
                imagebox.image.offsetx= x;
            }
        }
    }else {
        if ((wOrigin <= hOrigin || maskWidth <= maskHight) && (wOrigin / hOrigin < maskWidth / maskHight) || wOrigin >= hOrigin && maskWidth >= maskHight && (wOrigin / hOrigin < maskWidth / maskHight)) {
            imagebox.adaptation = 'Width';
            imagebox.image.width = imagebox.geometry.width;
            imagebox.image.height = imagebox.geometry.width * height / width;
            imagebox.image.offsetx = 0;
            imagebox.image.offsety = 0;
            imagebox.blurredrectangle = {
                width: imagebox.geometry.height * width / height,
                height: imagebox.geometry.height,
                x: (imagebox.geometry.width - imagebox.geometry.height * width / height) / 2,
                y: 0,
            }
        } else {
            imagebox.adaptation = 'Height';
            imagebox.image.width = imagebox.geometry.height * width / height;
            imagebox.image.height = imagebox.geometry.height;
            imagebox.image.offsetx = 0;
            imagebox.image.offsety = 0;
            imagebox.blurredrectangle = {
                width: imagebox.geometry.width,
                height: imagebox.geometry.width * height / width,
                x: 0,
                y: (imagebox.geometry.height - imagebox.geometry.width * height / width) / 2,
            }
        }
    }

    imagebox.blobImage = true;
    pxInsufficient(imagebox, vm.imgSize, vm.imgDpi);
    // setTimeout(function () {
    //     $scope.$apply();
    // },0)
    // f++;
    // if (f >= vm.counts) {
    //     showPages();
    // }
}

var boxW = 0, boxH = 0, picW = 0, picH = 0, scale = 0;
function getDpi(box) {
    boxW = transitionMm(box.geometry.width);
    boxH = transitionMm(box.geometry.height);
    picW = box.image.width;
    picH = box.image.height;
    scale = box.image.scale;
    var x = 0, y = 0;
    // 宽度适配
    if (box.adaptation=='Width') {
        x = picW / (boxW / 25.4) / scale;
        y = boxH * picW / boxW / (boxH / 25.4) / scale;
    } else {
        x = boxW * picH / boxH / (boxW / 25.4) / scale;
        y = picH / (boxH / 25.4) / scale;
    }
    if (x < 150 || y < 150) {
        box.image.lowSize = true;
    } else {
        box.image.lowSize = false;
    }
}

function getScale(screenW, screenH, pageW, pageH) {
    if (screenW * 0.94 / (screenH - 240) < pageW / pageH) {
        //宽定，高不定
        return true;
    } else {
        //高定，宽不定
        return false;
    }
}

function showPages($scope) {
    // setTimeout(function () {
    //     $scope.loading = false;
    //     $(".page-loading").hide().css({'opacity': 0});
    //     // $li.show();
    //     $(".guide-view").removeClass('opacity-zero');
    //     // 等到刷新后直接赋值
    //     localStorage.setItem('pages', JSON.stringify($scope.template.pages));
    // })
}
//mm转px
function transitionPx(item) {
    return item * js_getDPI() / 25.4;
}
//px转mm
function transitionMm(item) {
    return item * 25.4 / js_getDPI();
}
//阻止默认事件
function fn($event) {
    if($event){
        var $event = $event || window.event;
        if ($event && $event.preventDefault) {
            $event.preventDefault();
        } else {
            window.event.returnValue = false;
            return false;
        }
        $event.stopPropagation();
    }
}
/*******************************************照片框处理*************************************************************************/
//照片框移动
var isImageboxMove = false;
var isImageboxDragging = false;
var imageboxObj = {};
function imageboxTouchStart(e, levelIndex, ratio1, level,  callback,  callback1, callback2) {
    var targ = e.target ? e.target : e.srcElement;
    var imgIndex = targ.dataset.index || levelIndex;
    callback(imgIndex)
    var sticker = level[imgIndex].imagebox;
    if(!sticker || sticker.bokehtype != 'None'){
        return
    }


    imageboxObj.x = e.originalEvent.touches[0].pageX!=null ?e.originalEvent.touches[0].pageX : 0;          //鼠标到页面左边的距离
    imageboxObj.y = e.originalEvent.touches[0].pageY !=null ?e.originalEvent.touches[0].pageY: 0;          //鼠标到页面顶部的距离
    imageboxObj.offsetx = angular.copy(sticker.image.offsetx * sticker.image.scale);
    imageboxObj.offsety = angular.copy(sticker.image.offsety * sticker.image.scale);
    imageboxObj.imageWidth = angular.copy(sticker.image.width * sticker.image.scale);
    imageboxObj.imageHeight = angular.copy(sticker.image.height * sticker.image.scale);
    imageboxObj.geometry = angular.copy(sticker.geometry);
    imageboxObj.image = angular.copy(sticker.image);
    imageboxObj.blurredrectangle = angular.copy(sticker.blurredrectangle);

    if (!e.originalEvent || e.originalEvent.touches.length == 1) {
        callback1(imgIndex)
        imageboxObj.l = sticker.image.offsetx * ratio1 * sticker.image.scale;
        imageboxObj.t = sticker.image.offsety * ratio1 * sticker.image.scale;
        imageboxObj.r = -(sticker.image.width * sticker.image.scale - sticker.geometry.width) * ratio1;
        imageboxObj.b = -(sticker.image.height * sticker.image.scale - sticker.geometry.height) * ratio1;
        imageboxObj.l1 = sticker.geometry.x * ratio1;
        imageboxObj.t1 = sticker.geometry.y * ratio1;
        isImageboxMove = true;
    };
    if (e.originalEvent && e.originalEvent.touches.length >= 2) {
        //获取w和h
        imageboxObj.nw = angular.copy(sticker.image.width) * ratio1;
        imageboxObj.nh = angular.copy(sticker.image.height) * ratio1;
        imageboxObj.scale = angular.copy(sticker.image.scale);
        imageboxObj.start = e.originalEvent.touches;
        //开关打开
        isImageboxDragging = true;
    };
    if(callback2 && sticker.image && sticker.image.resource && sticker.image.resource.identifier && sticker.bokehtype == 'None'){
        callback2()
    }
};
function imageboxTouchmove(e, levelIndex, ratio1, level, pageData,  callback) {
    var sticker = level[levelIndex].imagebox;
    if(!sticker || sticker.bokehtype != 'None'){
        return
    }
    if (!e.originalEvent || e.originalEvent.touches.length == 1) {
        if (!isImageboxMove) {
            return
        }

        if(!sticker.lock){
            //获取x和y
            var nx = e.originalEvent.touches[0].pageX != null ?e.originalEvent.touches[0].pageX: 0;
            var ny = e.originalEvent.touches[0].pageY != null ?e.originalEvent.touches[0].pageY: 0;
            //计算移动后的左偏移量和顶部的偏移量
            var nl = nx - (imageboxObj.x - imageboxObj.l1);
            var nt = ny - (imageboxObj.y - imageboxObj.t1);

            sticker.geometry.x = nl / ratio1;
            sticker.geometry.y = nt / ratio1;
        }else{
            var angle = Number(sticker.rotation.angle);
            var cx = (imageboxObj.l + imageboxObj.r) / 2;
            var cy = (imageboxObj.t + imageboxObj.b) / 2;
            var pt0 = rotatePoint({x: imageboxObj.x, y: imageboxObj.y}, {x: cx, y: cy}, -angle);
            var pt1 = rotatePoint({x: e.originalEvent.touches[0].pageX, y: e.originalEvent.touches[0].pageY}, {x: cx, y: cy}, -angle);
            var deltaX = pt1.x - pt0.x;
            var deltaY = pt1.y - pt0.y;
            var nl = imageboxObj.l + deltaX;
            var nt = imageboxObj.t + deltaY;
            if (nl < imageboxObj.r) {
                nl = imageboxObj.r
            };
            if (nl > 0) {
                nl = 0
            };
            if (nt < imageboxObj.b) {
                nt = imageboxObj.b
            };
            if (nt > 0) {
                nt = 0
            };


            sticker.image.offsetx = nl / ratio1 / sticker.image.scale;
            sticker.image.offsety = nt / ratio1 / sticker.image.scale;
        }
    };
    if (e.originalEvent && e.originalEvent.touches.length >= 2) {
        var now = e.originalEvent.touches;
        Math.abs(e.originalEvent.touches[0].pageX - e.originalEvent.touches[1].pageX);
        if(sticker.lock){
            var _width = angular.copy(Number(imageboxObj.nw) + Number(getDistance(now[0], now[1]) - getDistance(imageboxObj.start[0], imageboxObj.start[1])));
            sticker.image.scale = imageboxObj.scale + (_width/imageboxObj.nw - 1);
            if(sticker.image.scale <= 1){
                sticker.image.scale = 1;
            }

            sticker.image.offsetx = (imageboxObj.offsetx - (sticker.image.width*sticker.image.scale-imageboxObj.imageWidth)/2) / sticker.image.scale;
            sticker.image.offsety = (imageboxObj.offsety - (sticker.image.height*sticker.image.scale-imageboxObj.imageHeight)/2) / sticker.image.scale;
            if(sticker.image.width*sticker.image.scale + sticker.image.offsetx*sticker.image.scale <= sticker.geometry.width){
                sticker.image.offsetx = (sticker.geometry.width - sticker.image.width*sticker.image.scale) / sticker.image.scale;
            }
            if(sticker.image.height*sticker.image.scale + sticker.image.offsety*sticker.image.scale <= sticker.geometry.height){
                sticker.image.offsety = (sticker.geometry.height - sticker.image.height*sticker.image.scale) / sticker.image.scale;
            }
            if(sticker.image.offsetx > 0){
                sticker.image.offsetx = 0;
            }
            if(sticker.image.offsety > 0){
                sticker.image.offsety = 0;
            }
        }else{
            var _width = angular.copy(Number(imageboxObj.geometry.width) + Number(getDistance(now[0], now[1]) - getDistance(imageboxObj.start[0], imageboxObj.start[1])));
            var _w = _width;
            var _h = _w / imageboxObj.geometry.width * imageboxObj.geometry.height;

            if(sticker.image && sticker.image.resource && sticker.image.resource.identifier){
                sticker.image.width = _w / imageboxObj.geometry.width * imageboxObj.image.width;
                sticker.image.height = _w / imageboxObj.geometry.width * imageboxObj.image.height;
                sticker.image.offsetx = _w / imageboxObj.geometry.width * imageboxObj.image.offsetx;
                sticker.image.offsety = _w / imageboxObj.geometry.width * imageboxObj.image.offsety;
                if(sticker.bokehtype != 'None'){
                    sticker.image.offsetx = 0;
                    sticker.image.offsety = 0;
                    sticker.blurredrectangle.width = _w / imageboxObj.geometry.width * imageboxObj.blurredrectangle.width;
                    sticker.blurredrectangle.height = _w / imageboxObj.geometry.width * imageboxObj.blurredrectangle.height;
                    sticker.blurredrectangle.x = _w / imageboxObj.geometry.width * imageboxObj.blurredrectangle.x;
                    sticker.blurredrectangle.y = _w / imageboxObj.geometry.width * imageboxObj.blurredrectangle.y;
                }
            }
            sticker.geometry.width = _w;
            sticker.geometry.height = _h;
            sticker.geometry.x = (imageboxObj.geometry.x - (_w - imageboxObj.geometry.width)/2);
            sticker.geometry.y = (imageboxObj.geometry.y - (_h - imageboxObj.geometry.height)/2);
        }
    }
    if(callback){
        callback()
    }
}
function imageboxTouchend(e, levelIndex, ratio1, level) {
    isImageboxMove = false;
    isImageboxDragging = false;
    imageboxObj = {};
}

//图片框缩放
var imageboxZoomItem = {};
var isDragging = false;
function imageboxZoomStart(e, level, documentClass, ratio, callback) {

    var targ = e.currentTarget ? e.currentTarget : e.srcElement;
    var textIndex = targ.dataset.index;
    textIndex = parseInt(textIndex);
    var sticker = level[textIndex].imagebox;
    if(sticker.lock){
        return
    }
    callback(textIndex);

    imageboxZoomItem.nw = angular.copy(sticker.geometry.width * ratio);
    imageboxZoomItem.nh = angular.copy(sticker.geometry.height * ratio);
    if(sticker.image && sticker.image.resource && sticker.image.resource.identifier){
        imageboxZoomItem.imageboxImgW = angular.copy(sticker.image.width * ratio);
        imageboxZoomItem.imageboxImgH = angular.copy(sticker.image.height * ratio);
        imageboxZoomItem.imageboxImgX = angular.copy(sticker.image.offsetx * ratio);
        imageboxZoomItem.imageboxImgY = angular.copy(sticker.image.offsety * ratio);
        if(sticker.bokehtype != 'None'){
            imageboxZoomItem.imageboxImgblurredrectangleW = angular.copy(sticker.blurredrectangle.width * ratio);
            imageboxZoomItem.imageboxImgblurredrectangleH = angular.copy(sticker.blurredrectangle.height * ratio);
            imageboxZoomItem.imageboxImgblurredrectangleX = angular.copy(sticker.blurredrectangle.x * ratio);
            imageboxZoomItem.imageboxImgblurredrectangleY = angular.copy(sticker.blurredrectangle.y * ratio);
        }
    }
    imageboxZoomItem.x2 = sticker.geometry.x * ratio;
    imageboxZoomItem.y2 = sticker.geometry.y * ratio;
    imageboxZoomItem.top = $('.' + documentClass).offset().top + (sticker.geometry.height * ratio / 2);
    imageboxZoomItem.left = $('.' + documentClass).offset().left + (sticker.geometry.width * ratio / 2);
    imageboxZoomItem.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
    imageboxZoomItem.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;


    var x = Math.abs(imageboxZoomItem.left - e.touches[0].pageX);
    var y = Math.abs(imageboxZoomItem.top - e.touches[0].pageY);
    imageboxZoomItem.distance = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

    imageboxZoomItem.nc = Math.sqrt(Math.pow(imageboxZoomItem.nw, 2) + Math.pow(imageboxZoomItem.nh, 2));
    var angle1 = getAngle1(imageboxZoomItem.left, imageboxZoomItem.top, e.touches[0].pageX, e.touches[0].pageY);
    if(0 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle)< 90){
        imageboxZoomItem.angle1 = Number(angle1) + Number(sticker.rotation.angle);
    }
    else if(90 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 180){
        imageboxZoomItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 90);
    }
    else if(180 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 270){
        imageboxZoomItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 180);
    }
    else if(270 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 360){
        imageboxZoomItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 360);
    }
    else if(360 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 450){
        imageboxZoomItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 360);
    }else{
        imageboxZoomItem.angle1 = Number(angle1);
    }
    isDragging = true;
}
var adsorbNumber = 6;
function imageboxZoomMove(e, imagebox, ratio,editbox, callback) {
    if (!isDragging || imagebox.lock) {
        return
    }
    var _top1 = e.touches[0].pageY;
    var _left1 = e.touches[0].pageX;

    var x = Math.abs(imageboxZoomItem.left - e.touches[0].pageX);
    var y = Math.abs(imageboxZoomItem.top - e.touches[0].pageY);
    var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    var _w = (Number(imageboxZoomItem.nw) + Number(z) - imageboxZoomItem.distance) < 30 ? 31 : (Number(imageboxZoomItem.nw) + Number(z) - imageboxZoomItem.distance);
    var _h = _w / imageboxZoomItem.nw * imageboxZoomItem.nh;
    if(_h<30){
        _h = 31;
        _w = _h / imageboxZoomItem.nh * imageboxZoomItem.nw;
    }

    if(imagebox.image && imagebox.image.resource && imagebox.image.resource.identifier){
        imagebox.image.width = _w / imageboxZoomItem.nw * imageboxZoomItem.imageboxImgW / ratio;
        imagebox.image.height = _w / imageboxZoomItem.nw * imageboxZoomItem.imageboxImgH / ratio;
        imagebox.image.offsetx = _w / imageboxZoomItem.nw * imageboxZoomItem.imageboxImgX / ratio;
        imagebox.image.offsety = _w / imageboxZoomItem.nw * imageboxZoomItem.imageboxImgY / ratio;
        if(imagebox.bokehtype != 'None'){
            imagebox.image.offsetx = 0;
            imagebox.image.offsety = 0;
            imagebox.blurredrectangle.width = _w / imageboxZoomItem.nw * imageboxZoomItem.imageboxImgblurredrectangleW / ratio;
            imagebox.blurredrectangle.height = _w / imageboxZoomItem.nw * imageboxZoomItem.imageboxImgblurredrectangleH / ratio;
            imagebox.blurredrectangle.x = _w / imageboxZoomItem.nw * imageboxZoomItem.imageboxImgblurredrectangleX / ratio;
            imagebox.blurredrectangle.y = _w / imageboxZoomItem.nw * imageboxZoomItem.imageboxImgblurredrectangleY / ratio;
        }
    }
    imagebox.geometry.width = _w / ratio;
    imagebox.geometry.height = _h / ratio;
    imagebox.geometry.x = (imageboxZoomItem.x2 - (_w - imageboxZoomItem.nw)/2) / ratio;
    imagebox.geometry.y = (imageboxZoomItem.y2 - (_h - imageboxZoomItem.nh)/2) / ratio;
    imagebox.rotation.angle = getAngle(imageboxZoomItem.left, imageboxZoomItem.top, _left1, _top1) + imageboxZoomItem.angle1;
    if(imagebox.rotation.angle>360){
        imagebox.rotation.angle = imagebox.rotation.angle - 360;
    }
    imagebox.rotation.angle = adsorbAngle(imagebox.rotation.angle)
    if(callback){
        callback()
    }
}
function adsorbAngle(angle) {
    if(-adsorbNumber < angle && angle < adsorbNumber){angle = 0}
    else if(90-adsorbNumber < angle && angle < 90+adsorbNumber){angle = 90}
    else if(180-adsorbNumber < angle && angle < 180+adsorbNumber){angle = 180}
    else if(270-adsorbNumber < angle && angle < 270+adsorbNumber){angle = 270}
    else if(360-adsorbNumber < angle && angle < 360+adsorbNumber){angle = 360}
    return angle
}
function imageboxZoomEnd(callback) {
    isDragging = false;
    imageboxZoomItem = {};
    if(callback){
        callback()
    }
}
function imageboxStartStartCu(e, item) {
    if (item.lock) {
        return;
    }
    imageboxZoomItem.l0 =  $('.pitch').offset().left;
    imageboxZoomItem.t0 = $('.pitch').offset().top;
    imageboxZoomItem.w0 = $('.pitch')[0].offsetWidth;
    imageboxZoomItem.h0 = $('.pitch')[0].offsetHeight;
    imageboxZoomItem.cx = imageboxZoomItem.l0 + imageboxZoomItem.w0 / 2;   // 原中心点x
    imageboxZoomItem.cy = imageboxZoomItem.t0 + imageboxZoomItem.h0 / 2;   // 原中心点y
    imageboxZoomItem.angle = Number(item.rotation.angle);
    imageboxZoomItem.pt0 = rotatePoint({x: e.touches[0].pageX, y: e.touches[0].pageY}, {x: imageboxZoomItem.cx, y: imageboxZoomItem.cy}, -imageboxZoomItem.angle);
    imageboxZoomItem.dx = 0;     // 框left变化
    imageboxZoomItem.dy = 0;     // 框top变化
    imageboxZoomItem.dw = 0;     // 框长变化
    imageboxZoomItem.dh = 0;     // 框高变化
    imageboxZoomItem.dcx = 0;    // 中心点x向偏移
    imageboxZoomItem.dcy = 0;    // 中心点y向偏移

    imageboxZoomItem.gx = item.geometry.x;
    imageboxZoomItem.gy = item.geometry.y;
    imageboxZoomItem.gw = item.geometry.width;
    imageboxZoomItem.gh = item.geometry.height;


    if(item.image && item.image.resource && item.image.resource.identifier){
        imageboxZoomItem.imageboxImgW = angular.copy(item.image.width);
        imageboxZoomItem.imageboxImgH = angular.copy(item.image.height);
        imageboxZoomItem.imageboxImgX = angular.copy(item.image.offsetx);
        imageboxZoomItem.imageboxImgY = angular.copy(item.image.offsety);
        if(item.bokehtype != 'None'){
            imageboxZoomItem.imageboxImgblurredrectangleW = angular.copy(item.blurredrectangle.width);
            imageboxZoomItem.imageboxImgblurredrectangleH = angular.copy(item.blurredrectangle.height);
            imageboxZoomItem.imageboxImgblurredrectangleX = angular.copy(item.blurredrectangle.x);
            imageboxZoomItem.imageboxImgblurredrectangleY = angular.copy(item.blurredrectangle.y);
        }
    }
    isDragging = true;
}
function imageboxStartOnCu(e, item, stick, scale, moveCallback) {
    if (!isDragging || item.lock) {
        return
    }
    var pt1 = rotatePoint({x: e.touches[0].pageX, y: e.touches[0].pageY}, {x: imageboxZoomItem.cx, y: imageboxZoomItem.cy}, -imageboxZoomItem.angle);
    if (stick == 'left') {
        imageboxZoomItem.dx = pt1.x - imageboxZoomItem.pt0.x;
        imageboxZoomItem.dw = -imageboxZoomItem.dx;
        imageboxZoomItem.dcx = imageboxZoomItem.dx/2;
    }
    else if (stick == 'top') {
        imageboxZoomItem.dy = pt1.y - imageboxZoomItem.pt0.y;
        imageboxZoomItem.dh = -imageboxZoomItem.dy;
        imageboxZoomItem.dcy = imageboxZoomItem.dy/2;
    }
    else if (stick == 'right') {
        imageboxZoomItem.dw = pt1.x - imageboxZoomItem.pt0.x;
        imageboxZoomItem.dcx = imageboxZoomItem.dw / 2;
    }
    else if (stick == 'bottom') {
        imageboxZoomItem.dh = pt1.y - imageboxZoomItem.pt0.y;
        imageboxZoomItem.dcy = imageboxZoomItem.dh / 2;
    }
    else if (stick == 'leftTop') {
        var offsetX = pt1.x - imageboxZoomItem.pt0.x;
        var offsetY = pt1.y - imageboxZoomItem.pt0.y;
        if ((imageboxZoomItem.gw - offsetX / scale) * imageboxZoomItem.gh  > (imageboxZoomItem.gh - offsetY / scale) * imageboxZoomItem.gw ) {
            offsetX = offsetY * imageboxZoomItem.gw / imageboxZoomItem.gh;
        } else {
            offsetY = offsetX * imageboxZoomItem.gh / imageboxZoomItem.gw;
        }

        imageboxZoomItem.dx = offsetX;
        imageboxZoomItem.dy = offsetY;

        imageboxZoomItem.dw = -imageboxZoomItem.dx;
        imageboxZoomItem.dh = -imageboxZoomItem.dy;
        imageboxZoomItem.dcx = imageboxZoomItem.dx / 2;
        imageboxZoomItem.dcy = imageboxZoomItem.dy / 2;
    }
    else if (stick == 'leftBottom') {
        var offsetX = pt1.x - imageboxZoomItem.pt0.x;
        var offsetY = pt1.y - imageboxZoomItem.pt0.y;
        if ((imageboxZoomItem.gw - offsetX / scale) * imageboxZoomItem.gh  > (imageboxZoomItem.gh + offsetY / scale) * imageboxZoomItem.gw ) {
            offsetX = -1 * offsetY * imageboxZoomItem.gw / imageboxZoomItem.gh;
        } else {
            offsetY = -1 * offsetX * imageboxZoomItem.gh / imageboxZoomItem.gw;
        }

        imageboxZoomItem.dx = offsetX;
        imageboxZoomItem.dw = -imageboxZoomItem.dx;
        imageboxZoomItem.dh = offsetY;
        imageboxZoomItem.dcx = imageboxZoomItem.dx / 2;
        imageboxZoomItem.dcy = imageboxZoomItem.dh / 2;
    }
    else if (stick == 'rightTop') {
        var offsetX = pt1.x - imageboxZoomItem.pt0.x;
        var offsetY = pt1.y - imageboxZoomItem.pt0.y;
        if ((imageboxZoomItem.gw + offsetX / scale) * imageboxZoomItem.gh  > (imageboxZoomItem.gh - offsetY / scale) * imageboxZoomItem.gw ) {
            offsetX = -1 * offsetY * imageboxZoomItem.gw / imageboxZoomItem.gh;
        } else {
            offsetY = -1 * offsetX * imageboxZoomItem.gh / imageboxZoomItem.gw;
        }

        imageboxZoomItem.dy = offsetY;
        imageboxZoomItem.dw = offsetX;
        imageboxZoomItem.dh = -imageboxZoomItem.dy;
        imageboxZoomItem.dcx = imageboxZoomItem.dw / 2;
        imageboxZoomItem.dcy = imageboxZoomItem.dy / 2;
    }
    else if (stick == 'rightBottom') {
        var offsetX = pt1.x - imageboxZoomItem.pt0.x;
        var offsetY = pt1.y - imageboxZoomItem.pt0.y;
        if ((imageboxZoomItem.gw + offsetX / scale) * imageboxZoomItem.gh  > (imageboxZoomItem.gh + offsetY / scale) * imageboxZoomItem.gw ) {
            offsetX = offsetY * imageboxZoomItem.gw / imageboxZoomItem.gh;
        } else {
            offsetY = offsetX * imageboxZoomItem.gh / imageboxZoomItem.gw;
        }

        imageboxZoomItem.dw = offsetX;
        imageboxZoomItem.dh = offsetY;
        imageboxZoomItem.dcx = imageboxZoomItem.dw / 2;
        imageboxZoomItem.dcy = imageboxZoomItem.dh / 2;
    }
    else {
        e.stopPropagation();
        return;
    }
    // 如果框尺寸小于5mm, 不处理
    if (imageboxZoomItem.gw + (imageboxZoomItem.dw / scale) < 5 || imageboxZoomItem.gh + (imageboxZoomItem.dh / scale) < 5) {
        e.stopPropagation();
        return;
    }
    // 中心点偏移dc1
    var dc1 = rotatePoint({x: imageboxZoomItem.dcx, y: imageboxZoomItem.dcy}, {x: 0, y: 0}, imageboxZoomItem.angle);
    // 偏移补偿
    var cx_fix =  dc1.x - imageboxZoomItem.dcx;
    var cy_fix = dc1.y - imageboxZoomItem.dcy;

    item.geometry.x = imageboxZoomItem.gx + ((imageboxZoomItem.dx + cx_fix) / scale);
    item.geometry.y = imageboxZoomItem.gy + ((imageboxZoomItem.dy + cy_fix) / scale);
    item.geometry.width = imageboxZoomItem.gw + (imageboxZoomItem.dw / scale);
    item.geometry.height = imageboxZoomItem.gh + (imageboxZoomItem.dh / scale);
    if(item.geometry.width*scale < 30){
        item.geometry.width = 31 / scale
    }
    if(item.geometry.height*scale < 30){
        item.geometry.height = 31 / scale
    }
    if(item.image && item.image.resource && item.image.resource.identifier){
        item.image.width = item.geometry.width / imageboxZoomItem.gw * imageboxZoomItem.imageboxImgW;
        item.image.height = item.geometry.width / imageboxZoomItem.gw * imageboxZoomItem.imageboxImgH;
        item.image.offsetx = item.geometry.width / imageboxZoomItem.gw * imageboxZoomItem.imageboxImgX;
        item.image.offsety = item.geometry.width / imageboxZoomItem.gw * imageboxZoomItem.imageboxImgY;
        if(item.bokehtype != 'None'){
            item.image.offsetx = 0;
            item.image.offsety = 0;
            item.blurredrectangle.width = item.geometry.width / imageboxZoomItem.gw * imageboxZoomItem.imageboxImgblurredrectangleW;
            item.blurredrectangle.height = item.geometry.width / imageboxZoomItem.gw * imageboxZoomItem.imageboxImgblurredrectangleH;
            item.blurredrectangle.x = item.geometry.width / imageboxZoomItem.gw * imageboxZoomItem.imageboxImgblurredrectangleX;
            item.blurredrectangle.y = item.geometry.width / imageboxZoomItem.gw * imageboxZoomItem.imageboxImgblurredrectangleY;
        }
    }
    if (moveCallback) {
        moveCallback(item);
    }
    e.stopPropagation();
}
function imageboxStartEndCu(e, upCallback) {
    isDragging = false;
    imageboxZoomItem = {};
    if (upCallback) {
        upCallback();
    }
    e.stopPropagation();
}
//照片框展开
function imageboxModeIn(imagebox, callback) {
    if (imagebox.image.resource.adaptation == 'Height') {
        imagebox.image.height = imagebox.geometry.width / imagebox.image.width * imagebox.image.height;
        imagebox.image.width = imagebox.geometry.width;
        imagebox.image.offsetx = 0;
        imagebox.image.offsety = 0;
    }
    if (imagebox.image.resource.adaptation == 'Width') {
        imagebox.image.width = imagebox.geometry.height / imagebox.image.height * imagebox.image.width;
        imagebox.image.height = imagebox.geometry.height;
        imagebox.image.offsetx = 0;
        imagebox.image.offsety = 0;
    }
    imagebox.bokehtype = 'None';
    imagebox.image.scale = 1;
    callback(imagebox)
}
//照片框缩回
function imageboxModeOut(imagebox, callback) {

    if (imagebox.image.resource.adaptation == 'Height') {
        imagebox.image.height = imagebox.geometry.width / imagebox.image.width * imagebox.image.height;
        imagebox.image.width = imagebox.geometry.width;
        imagebox.image.offsetx = 0;
        imagebox.image.offsety = 0;
    }
    if (imagebox.image.resource.adaptation == 'Width') {
        imagebox.image.width = imagebox.geometry.height / imagebox.image.height * imagebox.image.width;
        imagebox.image.height = imagebox.geometry.height;
        imagebox.image.offsetx = 0;
        imagebox.image.offsety = 0;
    }

    imagebox.bokehtype = imagebox.bokehtypes;
    imagebox.image.scale = 1;
    callback(imagebox)
}
//照片框放大
function imageboxMassive(imagebox) {
    imagebox.image.scale *= 1.2;
}
//照片框缩小
function imageboxMin(imagebox) {
    imagebox.image.scale = imagebox.image.scale / 1.2;
    imagebox.image.offsetx = 0;
    imagebox.image.offsety = 0;
    if (imagebox.image.scale < 1) {
        imagebox.image.scale = 1;
    }
}
function ImageboxCb(_image, imagebox, pageData,callback){
    imagebox.image.offsetx = (_image.offsetx * _image.scale - (imagebox.image.width*imagebox.image.scale-_image.width*_image.scale)/2) / imagebox.image.scale;
    imagebox.image.offsety = (_image.offsety * _image.scale - (imagebox.image.height*imagebox.image.scale-_image.height*_image.scale)/2) / imagebox.image.scale;
    if(imagebox.image.width*imagebox.image.scale + imagebox.image.offsetx*imagebox.image.scale <= imagebox.geometry.width){
        imagebox.image.offsetx = (imagebox.geometry.width - imagebox.image.width*imagebox.image.scale) / imagebox.image.scale;
    }
    if(imagebox.image.height*imagebox.image.scale + imagebox.image.offsety*imagebox.image.scale <= imagebox.geometry.height){
        imagebox.image.offsety = (imagebox.geometry.height - imagebox.image.height*imagebox.image.scale) / imagebox.image.scale;
    }
    if(imagebox.image.offsetx > 0){
        imagebox.image.offsetx = 0;
    }
    if(imagebox.image.offsety > 0){
        imagebox.image.offsety = 0;
    }
    if(callback){
        callback()
    }
}
//照片框旋转
function imageboxRotate($scope, imagebox, flag, callback) {
    if (imagebox.flip) {
        imagebox.image.angle -= 90;
        if (imagebox.image.angle == -90) {
            imagebox.image.angle = 270
        }
    } else {
        imagebox.image.angle += 90;
        if (Math.abs(imagebox.image.angle) == 360) {
            imagebox.image.angle = 0
        }
    }
    imagebox.image.scale = 1;
    imagebox.image.offsetx = 0;
    imagebox.image.offsety = 0;
    getBackgroundSource2(imagebox, imagebox.geometry.width, imagebox.geometry.height, imagebox.image, imagebox.bokehtype, $scope, function () {
        if(callback){
            callback()
        }
    })
}
//照片框还原
function imagRestore($scope, imagebox, $http) {
    if(imagebox && imagebox.image){
        imagebox.flip = false;
        imagebox.image.scale = 1;
        imagebox.image.angle = 0;
        imagebox.image.offsetx = 0;
        imagebox.image.offsety = 0;
        addImagebox($http, imagebox, function () {})
    }
}
//照片框翻转
function imageboxOverturn(imagebox) {
    imagebox.flip = !imagebox.flip ? true : false;
}
//添加图片框
function imageboxAdd($http, img,editbox, article, callback) {
    $http({url: img.identifier + '?imageInfo'}).success(function (data) {
        var _imagebox = {
            blurredrectangle:null,
            bokehtype:article.bokehtype || 'None',
            bokehtypes:article.bokehtype || 'None',
            geometry:{
                width: data.width,
                height: data.height,
                x:0,
                y:0
            },
            image: {
                resource: {
                    identifier: img.identifier,
                    provider: 'qiniu'
                },
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                scale: 1,
                angle: 0
            },
            mask: {
                resource:{
                    identifier: '',
                    provider: '',
                }
            },
            rotation:{
                angle: 0
            },
            useradded: true,
            lock:true,
        }

        var bgImgW = data.width;
        var bgImgH = data.height;
        var bgImgR = bgImgW / bgImgH;
        var bgImgR1 = editbox.width / editbox.height;
        if (bgImgR >= bgImgR1) {
            _imagebox.geometry.width = editbox.width * 2 / 3;
            _imagebox.geometry.height = _imagebox.geometry.width * bgImgH / bgImgW;
        } else {
            _imagebox.geometry.height = editbox.height * 2 / 3;
            _imagebox.geometry.width = _imagebox.geometry.height * bgImgW / bgImgH;
        }
        _imagebox.geometry.x = (editbox.width - _imagebox.geometry.width) / 2 + editbox.x;
        _imagebox.geometry.y = (editbox.height - _imagebox.geometry.height) / 2 + editbox.y;
        callback(_imagebox)
    }).error(function (error) {
    });
}
/*******************************************素材处理*************************************************************************/
//添加素材
function imageartAdd($http, imageart,editbox, callback, ratio, doodle) {
    ratio = ratio || 1;
    $http({url: imageart.resource.identifier + '?imageInfo'}).success(function (data) {
        var _imageart = {
            movable: true,
            editable: true,
            onlyshow: false,
            offsetx: '0',
            offsety: '0',
            transparency: '1',
            geometry: {
                x: '0',
                y: '0',
                width: data.width,
                height: data.height
            },
            rotation: {
                angle: '0'
            },
            resource: {
                provider: '',
                identifier: imageart.resource.identifier,
                width: '',
                height: ''
            },
            scale: 1,
            useradded: true,
            imageInfo: data
        }
        if(!doodle){
            var w = 110/ratio;
            var h = 110/ratio;
            var bgImgW = data.width;
            var bgImgH = data.height;
            var bgImgR = bgImgW / bgImgH;
            var bgImgR1 = w / h;
            if (bgImgR >= bgImgR1) {
                _imageart.geometry.width = w;
                _imageart.geometry.height = _imageart.geometry.width * bgImgH / bgImgW;
            } else {
                _imageart.geometry.height = h;
                _imageart.geometry.width = _imageart.geometry.height * bgImgW / bgImgH;
            }
            _imageart.geometry.x = (editbox.width - _imageart.geometry.width) / 2 + editbox.x;
            _imageart.geometry.y = (editbox.height - _imageart.geometry.height) / 2 + editbox.y;
        }else{
            _imageart.geometry.width = data.width / ratio;
            _imageart.geometry.height = data.height / ratio;
        }

        _imageart.width = _imageart.geometry.width;
        _imageart.height = _imageart.geometry.height;
        callback(_imageart)
    }).error(function (error) {
    });
}
//素材翻转
function imageartOverturn(imageart) {
    imageart.flip = !imageart.flip ? true : false;
}
//素材复制
function imageartCopys(level, imageart) {
    var _level = angular.copy(imageart);
    _level.seq = level.length + 1;
    level.push(_level)
}
//素材缩放
var imageartZoomItem = {};
var isDragging = false;
function imageartZoomStart(e, level, documentClass, ratio, callback) {

    var targ = e.currentTarget ? e.currentTarget : e.srcElement;
    var textIndex = targ.dataset.index;
    textIndex = parseInt(textIndex);
    var sticker = level[textIndex].imageart;
    if(!sticker.useradded){
        return
    }
    if(callback){callback(textIndex);}

    imageartZoomItem.nw = angular.copy(sticker.geometry.width * ratio);
    imageartZoomItem.nh = angular.copy(sticker.geometry.height * ratio);
    imageartZoomItem.nw1 = angular.copy(sticker.width * ratio);
    imageartZoomItem.nh1 = angular.copy(sticker.height * ratio);
    imageartZoomItem.x1 = angular.copy(sticker.offsetx * ratio);
    imageartZoomItem.y1 = angular.copy(sticker.offsety * ratio);
    imageartZoomItem.x2 = sticker.geometry.x * ratio;
    imageartZoomItem.y2 = sticker.geometry.y * ratio;
    imageartZoomItem.top = $('.' + documentClass).offset().top + (sticker.geometry.height * ratio / 2);
    imageartZoomItem.left = $('.' + documentClass).offset().left + (sticker.geometry.width * ratio / 2);
    imageartZoomItem.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
    imageartZoomItem.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;


    var x = Math.abs(imageartZoomItem.left - e.touches[0].pageX);
    var y = Math.abs(imageartZoomItem.top - e.touches[0].pageY);
    imageartZoomItem.distance = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

    imageartZoomItem.nc = Math.sqrt(Math.pow(imageartZoomItem.nw, 2) + Math.pow(imageartZoomItem.nh, 2));
    var angle1 = getAngle1(imageartZoomItem.left, imageartZoomItem.top, e.touches[0].pageX, e.touches[0].pageY);
    if(0 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle)< 90){
        imageartZoomItem.angle1 = Number(angle1) + Number(sticker.rotation.angle);
    }
    else if(90 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 180){
        imageartZoomItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 90);
    }
    else if(180 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 270){
        imageartZoomItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 180);
    }
    else if(270 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 360){
        imageartZoomItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 360);
    }
    else if(360 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 450){
        imageartZoomItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 360);
    }
    isDragging = true;
}
function imageartZoomMove(e, imageart, ratio,editbox, callback) {
    if (isDragging || imageart.useradded) {
        var _top1 = e.touches[0].pageY;
        var _left1 = e.touches[0].pageX;

        var x = Math.abs(imageartZoomItem.left - e.touches[0].pageX);
        var y = Math.abs(imageartZoomItem.top - e.touches[0].pageY);
        var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        var _w = (Number(imageartZoomItem.nw) + Number(z) - imageartZoomItem.distance);
        var _h = _w / imageartZoomItem.nw * imageartZoomItem.nh;
        // if(_w > editbox.width * ratio){
        //     _w = editbox.width * ratio;
        //     _h = _w / imageartZoomItem.nw * imageartZoomItem.nh;
        // }
        // if(_h > editbox.height * ratio){
        //     _h = editbox.height * ratio;
        //     _w = _h / imageartZoomItem.nh * imageartZoomItem.nw;
        // }

        imageart.geometry.width = _w / ratio;
        imageart.geometry.height = _h / ratio;
        imageart.geometry.x = (imageartZoomItem.x2 - (_w - imageartZoomItem.nw)/2) / ratio;
        imageart.geometry.y = (imageartZoomItem.y2 - (_h - imageartZoomItem.nh)/2) / ratio;
        imageart.offsetx = (_w / (imageartZoomItem.nw / imageartZoomItem.nw1)) / (imageartZoomItem.nw1 / imageartZoomItem.x1) / ratio;
        imageart.offsety = (_h / (imageartZoomItem.nh / imageartZoomItem.nh1)) / (imageartZoomItem.nh1 / imageartZoomItem.y1) / ratio;
        imageart.rotation.angle = getAngle(imageartZoomItem.left, imageartZoomItem.top, _left1, _top1) + imageartZoomItem.angle1;
        if(imageart.rotation.angle>360){
            imageart.rotation.angle = imageart.rotation.angle - 360;
        }
        imageart.rotation.angle = adsorbAngle(imageart.rotation.angle)
    }
    if(callback){
        callback()
    }
}
function imageartZoomEnd(callback) {
    isDragging = false;
    imageartZoomItem = {};
    if(callback){
        callback()
    }
}
//获得人物中心和鼠标坐标连线，与y轴正半轴之间的夹角
function getAngle(px, py, mx, my) {
    var x = Math.abs(px - mx);
    var y = Math.abs(py - my);
    var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    var cos = y / z;
    var radina = Math.acos(cos);//用反三角函数求弧度
    var angle = Math.floor(180 / (Math.PI / radina));//将弧度转换成角度
    if (mx < px && my > py) {//鼠标在第三象限
        angle = angle;
    }
    if (mx < px && my < py) {
        angle = 180 - angle;
    }
    if (mx > px && my < py) {
        angle = 180 + angle;
    }
    if (mx > px && my > py) {
        angle = 360 - angle;
    }
    return angle;
}
//获得人物中心和鼠标坐标连线，与y轴正半轴之间的夹角
function getAngle1(px, py, mx, my) {
    var x = Math.abs(px - mx);
    var y = Math.abs(py - my);
    var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    var cos = y / z;
    var radina = Math.acos(cos);//用反三角函数求弧度
    var angle = Math.floor(180 / (Math.PI / radina));//将弧度转换成角度
    return angle;
}
//素材移动
function imageartTouchstartStart(e, levelIndex, level, ratio,editbox, callback) {
    var targ = e.target ? e.target : e.srcElement;
    var imgIndex = targ.dataset.index != null? targ.dataset.index: levelIndex;
    imgIndex = parseInt(imgIndex);
    var sticker = level[imgIndex].imageart;
    if(!sticker.useradded){
        return
    }
    callback(imgIndex)
    if (!e.originalEvent || e.originalEvent.touches.length == 1) {
        imageartZoomItem.x = e.originalEvent.touches[0].pageX || 0;          //鼠标到页面左边的距离
        imageartZoomItem.y = e.originalEvent.touches[0].pageY || 0;          //鼠标到页面顶部的距离
        imageartZoomItem.l = sticker.geometry.x * ratio;
        imageartZoomItem.t = sticker.geometry.y * ratio;
        // imageartZoomItem.l = (sticker.geometry.x + editbox.x + vm.pageData[vm.pageIndex].mediabox.x) * ratio + $scope.dWidth * 0.05;
        // imageartZoomItem.t = (sticker.geometry.y + editbox.y + vm.pageData[vm.pageIndex].mediabox.y) * ratio + $(".c-productThree4-body").offset().top;
        imageartZoomItem.r = (editbox.width + editbox.x - sticker.geometry.width) * ratio;
        imageartZoomItem.b = (editbox.height + editbox.y - sticker.geometry.height) * ratio;
        if (e.originalEvent && e.originalEvent.touches.length >= 2) {
            imageartZoomItem.start = e.originalEvent.touches;
        };
        isDragging = true;
    };
    if (e.originalEvent && e.originalEvent.touches.length >= 2) {
        imageartZoomItem.x = e.originalEvent.touches[0].pageX != e.originalEvent.touches[0].pageX ? e.originalEvent.touches[0].pageX  : 0;
        imageartZoomItem.y = e.originalEvent.touches[0].pageY != e.originalEvent.touches[0].pageY ? e.originalEvent.touches[0].pageY  : 0;
        //获取w和h
        imageartZoomItem.nw = angular.copy(sticker.geometry.width) * ratio;
        imageartZoomItem.nh = angular.copy(sticker.geometry.height) * ratio;
        imageartZoomItem.nw1 = angular.copy(sticker.width) * ratio;
        imageartZoomItem.nh1 = angular.copy(sticker.height) * ratio;
        imageartZoomItem.x1 = angular.copy(sticker.offsetx * ratio);
        imageartZoomItem.y1 = angular.copy(sticker.offsety * ratio);
        imageartZoomItem.x2 = sticker.geometry.x * ratio;
        imageartZoomItem.y2 = sticker.geometry.y * ratio;
        imageartZoomItem._scale = angular.copy(sticker.scale);
        imageartZoomItem.start = e.originalEvent.touches;
        //开关打开
        isDragging = true;
    };
}
//素材单指拖拽
function imagearOne(e, sticker, ratio, editbox, callback){
    if (!isDragging) {
        return
    }
    // fn(e);
    //获取x和y
    var nx = e.originalEvent.touches[0].pageX != null ? e.originalEvent.touches[0].pageX: 0;
    var ny = e.originalEvent.touches[0].pageY != null ? e.originalEvent.touches[0].pageY: 0;
    //计算移动后的左偏移量和顶部的偏移量
    var nl = nx - (imageartZoomItem.x - imageartZoomItem.l);
    var nt = ny - (imageartZoomItem.y - imageartZoomItem.t);

    // if (nl < editbox.x * ratio) {
    //     nl = editbox.x * ratio
    // }
    // ;
    // if (nl > imageartZoomItem.r) {
    //     nl = imageartZoomItem.r
    // }
    // ;
    // if (nt < editbox.y * ratio) {
    //     nt = editbox.y * ratio
    // }
    // ;
    // if (nt > imageartZoomItem.b) {
    //     nt = imageartZoomItem.b
    // }
    ;

    sticker.geometry.x = nl / ratio;
    sticker.geometry.y = nt / ratio;
    if(callback){
        callback()
    }
};
//素材双指拖拽
function imagearTouchstartTwo(callback) {
    if(imageartZoomItem && imageartZoomItem.start){
        callback(imageartZoomItem)
    }
}
function imagearTwo(e, nl, sticker, editbox, ratio){
    if (!isDragging || !sticker.useradded) {
        return;
    }
    var _w = (Number(imageartZoomItem.nw) + Number(nl));
    var _h = _w / imageartZoomItem.nw * imageartZoomItem.nh;
    // if(_w > editbox.width * ratio){
    //     _w = editbox.width * ratio;
    //     _h = _w / imageartZoomItem.nw * imageartZoomItem.nh;
    // }
    // if(_h > editbox.height * ratio){
    //     _h = editbox.height * ratio;
    //     _w = _h / imageartZoomItem.nh * imageartZoomItem.nw;
    // }
    sticker.geometry.width = _w / ratio;
    sticker.geometry.height = _h / ratio;
    sticker.offsetx = (_w / (imageartZoomItem.nw / imageartZoomItem.nw1)) / (imageartZoomItem.nw1 / imageartZoomItem.x1) / ratio;
    sticker.offsety = (_h / (imageartZoomItem.nh / imageartZoomItem.nh1)) / (imageartZoomItem.nh1 / imageartZoomItem.y1) / ratio;
    sticker.geometry.x = (Number(imageartZoomItem.x2) - Number((_w - imageartZoomItem.nw)/2)) / ratio;
    sticker.geometry.y = (Number(imageartZoomItem.y2) - Number((_h - imageartZoomItem.nh)/2)) / ratio;
}
function getDistance(p1, p2) {
    var x = p2.pageX - p1.pageX,
        y = p2.pageY - p1.pageY;
    return Math.sqrt((x * x) + (y * y));
};

/*******************************************文字处理*************************************************************************/
var textBoxItem = {};
var isTextbox = false;
//文字移动
function textBoxMoveStart(e, levelIndex, level, ratio, editbox, callback) {
    var targ = e.currentTarget ? e.currentTarget : e.srcElement;
    var textIndex = targ.dataset.index != null ? targ.dataset.index : levelIndex;
    textIndex = parseInt(textIndex);
    var sticker = level[textIndex].textbox;
    if(!sticker.editable && !sticker.movable){
        return
    }
    callback(textIndex)
    textBoxItem.x = e.changedTouches[0].clientX != null ? e.changedTouches[0].clientX: 0;          //鼠标到页面左边的距离
    textBoxItem.y = e.changedTouches[0].clientY != null ? e.changedTouches[0].clientY: 0;          //鼠标到页面顶部的距离
    textBoxItem.l = sticker.geometry.x * ratio;
    textBoxItem.t = sticker.geometry.y * ratio;
    textBoxItem.r = (editbox.width + editbox.x - sticker.geometry.width) * ratio;
    textBoxItem.b = (editbox.height + editbox.y - sticker.geometry.height) * ratio;
    isTextbox = true;
};
function textBoxMoveOn(e, textbox, ratio, editbox,callback) {
    if (!isTextbox || !textbox.movable) {
        return
    }
    //获取x和y
    var nx = e.changedTouches[0].clientX != null ?e.changedTouches[0].clientX : 0;
    var ny = e.changedTouches[0].clientY != null ?e.changedTouches[0].clientY : 0;
    //计算移动后的左偏移量和顶部的偏移量
    var nl = nx - (textBoxItem.x - textBoxItem.l);
    var nt = ny - (textBoxItem.y - textBoxItem.t);

    // if (nl < editbox.x * ratio) {
    //     nl = editbox.x * ratio
    // }
    // ;
    // if (nl > textBoxItem.r) {
    //     nl = textBoxItem.r
    // }
    // ;
    // if (nt < editbox.y * ratio) {
    //     nt = editbox.y * ratio
    // }
    // ;
    // if (nt > textBoxItem.b) {
    //     nt = textBoxItem.b
    // }
    // ;

    textbox.geometry.x = nl / ratio;
    textbox.geometry.y = nt / ratio;
    if(callback){callback()}
}
function textBoxMoveEnd() {
    isTextbox = false;
    textBoxItem = {};
}
//文字四角拖拽
function textboxZoomStart(e, levelIndex, level, ratio, documentClass, callback) {
    var targ = e.currentTarget ? e.currentTarget : e.srcElement;
    var textIndex = targ.dataset.index != null ?targ.dataset.index : levelIndex;
    var sticker = level[textIndex].textbox;
    callback(textIndex)
    textBoxItem.x = e.touches[0].pageX;
    textBoxItem.y = e.touches[0].pageY;
    //获取w和h
    textBoxItem.nw = angular.copy(sticker.geometry.width);
    textBoxItem.nh = angular.copy(sticker.geometry.height);
    textBoxItem._left = angular.copy(sticker.geometry.x);
    textBoxItem._top = angular.copy(sticker.geometry.y);
    textBoxItem._size = angular.copy(parseInt(sticker.style.size));
    textBoxItem._lineheight = angular.copy(parseInt(sticker.style.lineheight));

    textBoxItem.top = $('.' + documentClass).offset().top + (sticker.geometry.height * ratio / 2);
    textBoxItem.left = $('.' + documentClass).offset().left + (sticker.geometry.width * ratio / 2);
    var x = Math.abs(textBoxItem.left - e.touches[0].pageX);
    var y = Math.abs(textBoxItem.top - e.touches[0].pageY);
    textBoxItem.distance = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    isTextbox = true;
}
//文字左右拖拽
function textboxZoomRightStart(e, levelIndex, level, ratio, documentClass, callback) {
    var targ = e.currentTarget ? e.currentTarget : e.srcElement;
    var textIndex = targ.dataset.index != null ? targ.dataset.index: levelIndex;
    callback(textIndex)
    var sticker = level[levelIndex].textbox;
    textBoxItem.x = e.touches[0].pageX;
    textBoxItem.nw = angular.copy(sticker.geometry.width) * ratio;
    textBoxItem.nh = angular.copy(sticker.geometry.height) * ratio;
    textBoxItem.left = angular.copy(sticker.geometry.x) * ratio;
    textBoxItem.top = angular.copy(sticker.geometry.y) * ratio;
    textBoxItem.top1 = $('.' + documentClass).offset().top + (sticker.geometry.height * ratio / 2);
    textBoxItem.left1 = $('.' + documentClass).offset().left + (sticker.geometry.width * ratio / 2);
    var x = Math.abs(textBoxItem.left1 - e.touches[0].pageX);
    var y = Math.abs(textBoxItem.top1 - e.touches[0].pageY);
    textBoxItem.distance = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    //开关打开
    isTextbox = true;
}
function textboxZoomLeftStart(e, levelIndex, level, ratio, documentClass, callback) {
    var targ = e.currentTarget ? e.currentTarget : e.srcElement;
    var textIndex = targ.dataset.index != null ? targ.dataset.index : levelIndex;
    var sticker = level[textIndex].textbox;
    callback(textIndex)
    textBoxItem.x = e.touches[0].pageX;
    textBoxItem.nw = angular.copy(sticker.geometry.width) * ratio;
    textBoxItem.nh = angular.copy(sticker.geometry.height) * ratio;
    textBoxItem.left = angular.copy(sticker.geometry.x) * ratio;
    textBoxItem.top = angular.copy(sticker.geometry.y) * ratio;

    textBoxItem.top1 = $('.' + documentClass).offset().top + (sticker.geometry.height * ratio / 2);
    textBoxItem.left1 = $('.' + documentClass).offset().left + (sticker.geometry.width * ratio / 2);
    var x = Math.abs(textBoxItem.left1 - e.touches[0].pageX);
    var y = Math.abs(textBoxItem.top1 - e.touches[0].pageY);
    textBoxItem.distance = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    //开关打开
    isTextbox = true;
}
function textboxZoom(e, item, element, ratio, editbox, callback) {
    if (isTextbox == false) {
        return;
    }
    //获取x和y
    var nx = e.touches[0].pageX;
    //计算移动后的左偏移量和顶部的偏移量
    // var nl = nx - textBoxItem.x;
    var nl = 0;
    if (element == 'left') {
        var x = Math.abs(textBoxItem.left1-nl - e.touches[0].pageX);
        var y = Math.abs(textBoxItem.top1 - e.touches[0].pageY);
        var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        //计算移动后的左偏移量和顶部的偏移量
        nl = -parseInt(z - textBoxItem.distance);
        console.log(nl)
        item.geometry.x = (Number(textBoxItem.left) + Number(nl/2)) / ratio;
        item.geometry.width = (Number(textBoxItem.nw) - Number(nl)) / ratio;
        if (item.geometry.width < parseInt(item.style.size)) {
            item.geometry.width = (Number(parseInt(item.style.size)) + Number(5));
            item.geometry.x = (Number(textBoxItem.left) + Number(nl/2)) / ratio;
            isTextbox = false;
            callback()
            return;
        }
        // if (item.geometry.width > (Number(item.geometry.x) + Number(item.geometry.width))) {
        //     item.geometry.x = 0;
        //     // item.geometry.width = (Number(item.geometry.x) + Number(item.geometry.width));
        //     isTextbox = false;
        //     callback()
        //     return;
        // }
    }
    if (element == 'right') {
        var x = Math.abs(textBoxItem.left1+nl - e.touches[0].pageX);
        var y = Math.abs(textBoxItem.top1 - e.touches[0].pageY);
        var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        //计算移动后的左偏移量和顶部的偏移量
        nl = parseInt(z - textBoxItem.distance);
        item.geometry.width = (Number(textBoxItem.nw) + Number(nl)) / ratio;
        item.geometry.x = (Number(textBoxItem.left) - Number(nl/2)) / ratio;
        if (item.geometry.width < parseInt(item.style.size)) {
            item.geometry.width = (Number(parseInt(item.style.size)) + Number(5));
            isTextbox = false;
            callback()
            return;
        }
        // if (item.geometry.width > editbox.width) {
        //     item.geometry.width = editbox.width;
        //     // item.geometry.x = 0;
        // }
    }
    if (element == 'top') {
        var x = Math.abs(textBoxItem.left1-nl - e.touches[0].pageX);
        var y = Math.abs(textBoxItem.top1 - e.touches[0].pageY);
        var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        //计算移动后的左偏移量和顶部的偏移量
        nl = -parseInt(z - textBoxItem.distance);
        console.log(nl)
        item.geometry.y = (Number(textBoxItem.top) + Number(nl/2)) / ratio;
        item.geometry.height = (Number(textBoxItem.nh) - Number(nl)) / ratio;
        if (item.geometry.height < parseInt(item.style.size)) {
            item.geometry.height = (Number(parseInt(item.style.size)) + Number(5));
            isTextbox = false;
            callback()
            return;
        }
    }
    callback(true)
};
function textboxZoom1(e, item, element, ratio, callback) {
    //开关打开
    if (isTextbox == false) {
        return;
    }
    // if (item.style.size > 100) {
    //     item.style.size = 100;
    //     isTextbox = false;
    //     textBoxItem = {};
    //     return
    // }
    var nl = 0;
    var x = Math.abs(textBoxItem.left + nl - e.touches[0].pageX);
    var y = Math.abs(textBoxItem.top - e.touches[0].pageY);
    var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    //计算移动后的左偏移量和顶部的偏移量
    nl = (parseInt(z - textBoxItem.distance)) / ratio;
    // console.log(nl + '////' + nl1)
    if (element == 'left') {
        if ((Number(textBoxItem.nw) - Number(nl)) < 20) {
            isTextbox = false;
            return;
        }
        item.geometry.width = (Number(textBoxItem.nw) - Number(nl));
        item.geometry.x = (Number(textBoxItem._left) + Number(nl));
        item.style.size = Math.ceil(((Number(textBoxItem.nw) - Number(nl)) / textBoxItem.nw * textBoxItem._size));
        item.style.lineheight =item.style.size;
        item.geometry.y = (Number(textBoxItem._top) - Number(item.geometry.height - textBoxItem.nh));
    }
    if (element == 'top') {
        if ((Number(textBoxItem.nw) + Number(nl)) < 20) {
            isTextbox = false;
            return;
        }
        item.geometry.width = (Number(textBoxItem.nw) + Number(nl));
        item.style.size = Math.ceil(((Number(textBoxItem.nw) + Number(nl)) / textBoxItem.nw * textBoxItem._size));
        item.style.lineheight =item.style.size;
        item.geometry.y = (Number(textBoxItem._top) - Number(item.geometry.height - textBoxItem.nh));
    }
    if (element == 'right') {
        if(writingmodeInit(item.style.writingmode)){
            item.geometry.height = (Number(textBoxItem.nh) + Number(nl) + Number(5));
            item.style.size = Math.ceil(((Number(textBoxItem.nh) + Number(nl)) / textBoxItem.nh * textBoxItem._size));
            item.style.lineheight = item.style.size;
        }else{
            if ((Number(textBoxItem.nw) + Number(nl)) < 20) {
                isTextbox = false;
                return;
            }
            item.geometry.width = (Number(textBoxItem.nw) + Number(nl) + Number(5));
            item.geometry.x = (Number(textBoxItem._left) - Number(nl/2));
            item.style.size = Math.ceil(((Number(textBoxItem.nw) + Number(nl)) / textBoxItem.nw * textBoxItem._size));
            item.style.lineheight = item.style.size;
        }
    }
    if (element == 'bottom') {
        if ((Number(textBoxItem.nw) - Number(nl)) < 20) {
            isTextbox = false;
            return;
        }
        item.geometry.width = (Number(textBoxItem.nw) - Number(nl));
        item.geometry.x = (Number(textBoxItem._left) + Number(nl));
        item.style.size = Math.ceil(((Number(textBoxItem.nw) - Number(nl)) / textBoxItem.nw * textBoxItem._size));
        item.style.lineheight = item.style.size;
        // item.geometry.y = (Number(textBoxItem._top) + Number(item.geometry.height - textBoxItem.nh));
    }
    callback(nl)
}
//文字旋转
function textboxRotateStart(e, levelIndex, level, callback) {
    var targ = e.currentTarget ? e.currentTarget : e.srcElement;
    var textIndex = targ.dataset.index != null ?targ.dataset.index: levelIndex;
    textIndex = parseInt(textIndex);
    var sticker = level[textIndex].textbox;
    if(!sticker.useradded){
        return
    }
    callback(textIndex)
    textBoxItem.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
    textBoxItem.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
    sticker.rotation.angle = -sticker.rotation.angle;
    textBoxItem.textRotate = sticker.rotation.angle ? 360 - Number(sticker.rotation.angle) : 0;
    var radius = Math.sqrt(sticker.geometry.width * sticker.geometry.width + sticker.geometry.height * sticker.geometry.height)
    textBoxItem.originalRotate = parseInt(180 * Math.asin(sticker.geometry.width / radius) / (2 * Math.asin(1))) + textBoxItem.textRotate;
    isTextbox = true;
}
function textboxRotateOn(e, levelIndex, level, ratio, documentClass, callback) {
    if (isTextbox) {
        var text = level[levelIndex].textbox;
        var _top = $('.' + documentClass).offset().top + (text.geometry.height * ratio / 2);
        var _left = $('.' + documentClass).offset().left + (text.geometry.width * ratio / 2);
        var _top1 = e.touches[0].pageY;
        var _left1 = e.touches[0].pageX;
        text.rotation.angle = getAngle(_left, _top, _left1, _top1);
        text.rotation.angle = adsorbAngle(text.rotation.angle);
    }
    callback()
}
//文字缩放
function textboxZoomStart1(e, levelIndex, level, ratio, documentClass, callback) {
    var targ = e.currentTarget ? e.currentTarget : e.srcElement;
    var textIndex = targ.dataset.index != null ?targ.dataset.index : levelIndex;
    var sticker = level[textIndex].textbox;
    callback(textIndex)
    textBoxItem.x = e.touches[0].pageX;
    textBoxItem.y = e.touches[0].pageY;
    //获取w和h
    textBoxItem.nw = angular.copy(sticker.geometry.width);
    textBoxItem.nh = angular.copy(sticker.geometry.height);
    textBoxItem._left = angular.copy(sticker.geometry.x);
    textBoxItem._top = angular.copy(sticker.geometry.y);
    textBoxItem._size = angular.copy(parseInt(sticker.style.size));
    textBoxItem._lineheight = angular.copy(parseInt(sticker.style.lineheight));

    textBoxItem.top = $('.' + documentClass).offset().top + (sticker.geometry.height * ratio / 2);
    textBoxItem.left = $('.' + documentClass).offset().left + (sticker.geometry.width * ratio / 2);
    var x = Math.abs(textBoxItem.left - e.touches[0].pageX);
    var y = Math.abs(textBoxItem.top - e.touches[0].pageY);
    textBoxItem.distance = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

    var angle1 = getAngle1(textBoxItem.left, textBoxItem.top, e.touches[0].pageX, e.touches[0].pageY);
    if(0 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle)< 90){
        textBoxItem.angle1 = Number(angle1) + Number(sticker.rotation.angle);
    }
    else if(90 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 180){
        textBoxItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 90);
    }
    else if(180 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 270){
        textBoxItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 180);
    }
    else if(270 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 360){
        textBoxItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 360);
    }
    else if(360 <= Number(sticker.rotation.angle) && Number(sticker.rotation.angle) < 450){
        textBoxItem.angle1 = Number(angle1) + Number(sticker.rotation.angle - 360);
    }
    isTextbox = true;
}
function textboxZoom2(e, item, ratio, callback) {
    //开关打开
    if (isTextbox == false) {
        return;
    }
    var nl = 0;
    var x = Math.abs(textBoxItem.left + nl - e.touches[0].pageX);
    var y = Math.abs(textBoxItem.top - e.touches[0].pageY);
    var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    var _top1 = e.touches[0].pageY;
    var _left1 = e.touches[0].pageX;
    //计算移动后的左偏移量和顶部的偏移量
    nl = (parseInt(z - textBoxItem.distance)) / ratio;
    if(writingmodeInit(item.style.writingmode)){
        item.geometry.height = (Number(textBoxItem.nh) + Number(nl) + Number(5));
        item.style.size = Math.ceil(((Number(textBoxItem.nh) + Number(nl)) / textBoxItem.nh * textBoxItem._size));
        item.style.lineheight = item.style.size;
    }else{
        if ((Number(textBoxItem.nw) + Number(nl)) < 20) {
            isTextbox = false;
            return;
        }
        item.geometry.width = (Number(textBoxItem.nw) + Number(nl) + Number(5));
        item.geometry.x = (Number(textBoxItem._left) - Number(nl/2));
        item.style.size = Math.ceil(((Number(textBoxItem.nw) + Number(nl)) / textBoxItem.nw * textBoxItem._size));
        item.style.lineheight = item.style.size;
    }

    item.rotation.angle = getAngle(textBoxItem.left, textBoxItem.top, _left1, _top1) + textBoxItem.angle1;
    if(item.rotation.angle>360){
        item.rotation.angle = item.rotation.angle - 360;
    }
    item.rotation.angle = adsorbAngle(item.rotation.angle)
    callback(nl)
}

function browserVersions() {
    var u = navigator.userAgent, app = navigator.appVersion;
    var browser = {
        versions: function () {
            return {         //移动终端浏览器版本信息
                trident: u.indexOf('Trident') > -1, //IE内核
                presto: u.indexOf('Presto') > -1, //opera内核
                webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
                mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
                iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                iPad: u.indexOf('iPad') > -1, //是否iPad
                webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
            };
        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
    }

    var isWx = false;
    if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
        var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            //在微信中打开
            // alert("在微信中打开")
            isWx = true;
        }else if(browser.versions.android){
            //是否在安卓浏览器打开
            // alert("是否在安卓浏览器打开")
            isWx = false;
        }
        if (ua.match(/WeiBo/i) == "weibo") {
            //在新浪微博客户端打开
        }
        if (ua.match(/QQ/i) == "qq") {
            //在QQ空间打开
        }
        if (browser.versions.ios) {
            //是否在IOS浏览器打开
            // alert("是否在IOS浏览器打开")
            isWx = false;
        }
    } else {
        //否则就是PC浏览器打开
    }
    // return isWx

    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // alert("isAndroid: "+isAndroid + " isiOS: "+isiOS)
    // return true
    if(!isWx){
        return false;
    } else if (isAndroid == true && isiOS == false) {
        console.log('android');
        return true;
    } else if (isAndroid == false && isiOS == true) {
        console.log('ios');
        return false;
    }
}

//判断图片数量
function judgeQuantity(article, data, pictureType) {
    var retuenType = false;
    var picturePage = article.expectPageCount || 0;
    if(picturePage && picturePage>0){
        if(pictureType){
            picturePage = picturePage * 2;
        }
        var quantity=0;
        data.forEach(function (item) {
            quantity += Number(item.quantity);
        })
        if(quantity != picturePage){
            retuenType = picturePage;
        }
    }
    return retuenType
}
function judgeQuantity1(article, data, pictureType) {
    var retuenType = false;
    var picturePage = article.expectPageCount || 0;
    if(picturePage && picturePage>0){
        if(pictureType){
            picturePage = picturePage * 2;
        }
        var quantity=0;
        data.forEach(function (item) {
            quantity += Number(item.quantity);
        })
        if(quantity > picturePage){
            retuenType = picturePage;
        }
    }
    return retuenType
}

//智能检测document
function documentDetection(page) {
    var isPass = {
        pass: true,
        pageIndex: null,
        levelIndex: null,
        levelType:null
    };
    // angular.forEach(page, function (page, index) {
    //     if(isPass.pass){
    //         angular.forEach(page.levels.level, function (level, i) {
    //             if (level.imagebox&&level.imagebox.image&&level.imagebox.image.resource && isPass.pass) {
    //                 if(level.imagebox.bokehtype == 'None'){
    //                     level.imagebox.image.width = Number(level.imagebox.image.width);
    //                     level.imagebox.image.height = Number(level.imagebox.image.height);
    //                     level.imagebox.image.offsetx = Number(level.imagebox.image.offsetx);
    //                     level.imagebox.image.offsety = Number(level.imagebox.image.offsety);
    //                     if(level.imagebox.image.offsetx > 1 || level.imagebox.image.offsety > 1 || !level.imagebox.image.width || !level.imagebox.image.height || (level.imagebox.image.offsetx + level.imagebox.image.width)*level.imagebox.image.scale < level.imagebox.geometry.width || (level.imagebox.image.offsety + level.imagebox.image.height)*level.imagebox.image.scale < level.imagebox.geometry.height){
    //                         isPass.pass = false;
    //                         isPass.pageIndex = index + 1;
    //                         isPass.levelIndex = i + 1;
    //                         isPass.levelType = 'imagebox';
    //                         console.log(isPass)
    //                         return false
    //                     }
    //                 }
    //             }
    //         })
    //     }
    // })
    // console.log(isPass)
    return isPass
}
function documentDetectionBoth(document) {
    var isPass = {
        pass: true,
        pageIndex: null,
        levelIndex: null,
        levelType:null,
        both: null
    };
    // angular.forEach(document, function (data, dataIndex) {
    //     if(isPass.pass){
    //         angular.forEach(data.page, function (page, index) {
    //             if(isPass.pass){
    //                 angular.forEach(page.levels.level, function (level, i) {
    //                     if (level.imagebox&&level.imagebox.image&&level.imagebox.image.resource && isPass.pass) {
    //                         if(level.imagebox.bokehtype == 'None'){
    //                             level.imagebox.image.width = Number(level.imagebox.image.width);
    //                             level.imagebox.image.height = Number(level.imagebox.image.height);
    //                             level.imagebox.image.offsetx = Number(level.imagebox.image.offsetx);
    //                             level.imagebox.image.offsety = Number(level.imagebox.image.offsety);
    //                             if(level.imagebox.image.offsetx > 1 || level.imagebox.image.offsety > 1 || !level.imagebox.image.width || !level.imagebox.image.height || (level.imagebox.image.offsetx + level.imagebox.image.width)*level.imagebox.image.scale < level.imagebox.geometry.width || (level.imagebox.image.offsety + level.imagebox.image.height)*level.imagebox.image.scale < level.imagebox.geometry.height){
    //                                 isPass.pass = false;
    //                                 isPass.pageIndex = dataIndex + 1;
    //                                 isPass.levelIndex = i + 1;
    //                                 isPass.levelType = 'imagebox';
    //                                 isPass.bothIndex = index;
    //                                 return false
    //                             }
    //                         }
    //                     }
    //                 })
    //             }
    //         })
    //     }
    // })
    return isPass
}
function documentTextDetection(page) {
    var isPass = {
        pass: true,
        pages:[],
    };
    for(var i=0; i<page.length; i++){
        if (!page[i] || !page[i].levels || !page[i].levels.level) {
            continue;
        }
        if (!page[i].levels.level.length) {
            continue;
        }
        var _page={
            valid: false,
            pageIndex: i + 1,
            texts:[],
            repetitions:[],
        }
        for(var j=0; j<page[i].levels.level.length; j++){
            var _level = page[i].levels.level[j];
            if(_level.textbox && _level.textbox.geometry && _level.textbox.success === false){
                _page.valid = true;
                isPass.isText = true;
                _page.texts.push(_level.textbox)
            }
            var _repetition = [];
            for (var key in _level){
                if(_level[key] && (key=='imagebox'||key=='code'||key=='imageart'||key=='textbox'||key=='textdocument')){
                    _level[key].levelType = key;
                    _repetition.push(_level[key])
                }
            }
            if(_repetition.length>1){
                _page.valid = true;
                isPass.isRepetition = true;
                _page.repetitions.push(_level);
            }
        }
        if(_page.valid){
            isPass.pass = false;
            isPass.pages.push(_page)
        }
    }
    return isPass
}
function documentTextDetection1(page) {
    var isPass = {
        pass: true,
        pages:[],
    };
    for(var i=0; i<page.length; i++){
        if(!page[i].levels.level){
            page[i].levels.level = [];
        }
        for(var j=0; j<page[i].levels.level.length; j++){
            var _level = page[i].levels.level[j];
            if(_level.textbox && !_level.textbox.success){
                isPass.pass = false;
                var _isExist = false;
                for (var c=0; c<isPass.pages.length; c++){
                    if(isPass.pages[c].pageIndex == i+1){
                        _isExist = true;
                        isPass.pages[c].texts.push(_level.textbox.text)
                        break
                    }
                }
                if(!_isExist){
                    isPass.pages.push({
                        pageIndex: i + 1,
                        texts:[_level.textbox.text]
                    })
                }
            }
        }
    }
    return isPass
}

// 判断移动端还是pc端
function isMobile(){
    if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true; // 移动端
    }else{
        return false; // PC端
    }
}
// mm换算成px
function MmTurnPx(mm) {
    return (mm / 25.4 * this.js_getDPI()).toFixed(2) - 0;
};
// px换算成mm
function PxTurnMm(px) {
    return (px / this.js_getDPI() * 25.4).toFixed(2) - 0;
};
//px换算成英寸
function PxTurnIn(item) {
    return (item / this.js_getDPI()).toFixed(2) - 0;
};
//英寸换算成px
function InTurnPx(item) {
    return (item * this.js_getDPI()).toFixed(2) - 0;
};
//英寸换算成mm
function InTurnMm(item) {
    return unitTransformPx(InTurnPx(item));
};
//mm换算成英寸
function MmTurnIn(item) {
    return PxTurnIn(unitTransform(item));
};

//客户主机信息
function hostInfo() {
    var _arr=[
        {
            describe: '操作系统信息',
            name: getOsInfo().name,
            version: getOsInfo().version,
        },
        {
            describe: '浏览器信息',
            browserkernel: getExplore(),
            name:getExploreName()
        },
        {
            describe: '日期',
            time:new Date()
        }
    ];
    // var md = new MobileDetect(window.navigator.userAgent);
    // _arr.push({
    //     describe: '设备信息',
    //     mobile: md.mobile(),
    //     os: md.os(),
    //     userAgent: md.userAgent(),
    //     rests: md
    // });
    return JSON.stringify(_arr);
}
// 获取操作系统信息
function getOsInfo() {
    var userAgent = navigator.userAgent.toLowerCase();
    var name = 'Unknown';
    var version = 'Unknown';
    if (userAgent.indexOf('win') > -1) {
        name = 'Windows';
        if (userAgent.indexOf('windows nt 5.0') > -1) {
            version = 'Windows 2000';
        } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
            version = 'Windows XP';
        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
            version = 'Windows Vista';
        } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
            version = 'Windows 7';
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
            version = 'Windows 8';
        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
            version = 'Windows 8.1';
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
            version = 'Windows 10';
        } else {
            version = 'Unknown';
        }
    } else if (userAgent.indexOf('iphone') > -1) {
        name = 'Iphone';
    } else if (userAgent.indexOf('mac') > -1) {
        name = 'Mac';
    } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {
        name = 'Unix';
    } else if (userAgent.indexOf('linux') > -1) {
        if (userAgent.indexOf('android') > -1) {
            name = 'Android';
        } else {
            name = 'Linux';
        }
    } else {
        name = 'Unknown';
    }
    return { name: name, version: version };
}
function getExplore(){
    var Sys = {};
    var ua = navigator.userAgent.toLowerCase();
    var s;
    (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1] :
        (s = ua.match(/msie ([\d\.]+)/)) ? Sys.ie = s[1] :
            (s = ua.match(/edge\/([\d\.]+)/)) ? Sys.edge = s[1] :
                (s = ua.match(/firefox\/([\d\.]+)/)) ? Sys.firefox = s[1] :
                    (s = ua.match(/(?:opera|opr).([\d\.]+)/)) ? Sys.opera = s[1] :
                        (s = ua.match(/chrome\/([\d\.]+)/)) ? Sys.chrome = s[1] :
                            (s = ua.match(/version\/([\d\.]+).*safari/)) ? Sys.safari = s[1] : 0;
    // 根据关系进行判断
    if (Sys.ie) return ('IE: ' + Sys.ie);
    if (Sys.edge) return ('EDGE: ' + Sys.edge);
    if (Sys.firefox) return ('Firefox: ' + Sys.firefox);
    if (Sys.chrome) return ('Chrome: ' + Sys.chrome);
    if (Sys.opera) return ('Opera: ' + Sys.opera);
    if (Sys.safari) return ('Safari: ' + Sys.safari);
    return 'Unkonwn';
}
function getExploreName(){
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    console.log(userAgent)
    var isOpera = userAgent.indexOf("Opera") > -1
        || userAgent.indexOf("OPR") > -1; //判断是否Opera浏览器

    var isQQ = userAgent.indexOf("QQBrowser") > -1; //判断是否QQBrowser浏览器

    var isUC = userAgent.indexOf("UBrowser") > -1; //判断是否UC浏览器

    var isIE = userAgent.indexOf("compatible") > -1
        && userAgent.indexOf("MSIE") > -1; //判断是否IE7~IE10浏览器

    var isIE11 = userAgent.indexOf("compatible") === -1
        && userAgent.indexOf("Trident") > -1; //判断是否IE11浏览器

    var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器

    var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器

    var isSafari = userAgent.indexOf("Safari") > -1
        && userAgent.indexOf("Chrome") === -1; //判断是否Safari浏览器

    var isChrome = userAgent.indexOf("Chrome") > -1
        && (userAgent.indexOf("; Win") > -1 || userAgent.indexOf("Macintosh;") > -1)
        && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

    var is360 = userAgent.indexOf("Chrome") > -1
        && userAgent.indexOf("; WOW") > -1
        && userAgent.indexOf("Safari") > -1; //判断360浏览器


    if (isIE) {
        var reIE = /MSIE (\d+)\.\d+;/;
        // match() 返回一个数组。数组第一项是匹配到的所有文本；数组第二项是正则中小括号匹配到的文本
        var matchReg = userAgent.match(reIE)
        var fIEVersion = matchReg[1];
        if (fIEVersion == 7) {
            return "IE7及其以下";
        } else if (fIEVersion == 8) {
            return "IE8";
        } else if (fIEVersion == 9) {
            return "IE9";
        } else if (fIEVersion == 10) {
            return "IE10";
        } else {
            return "0";
        }//IE版本过低
        return "IE";
    }
    if (isUC) {
        return "UC";
    }
    if (isQQ) {
        return "QQBrowser";
    }
    if (isIE11) {
        return "IE11";
    }
    if (isOpera) {
        return "Opera";
    }
    if (isEdge) {
        return "Edge";
    }
    if (isFF) {
        return "Firefox";
    }
    if (isSafari) {
        return "Safari";
    }
    if (isChrome) {
        return "Chrome";
    }
    if (is360) {
        return "360";
    }
    return "unknown";
}

//像素不足
var _identifiers = [];
function pxInsufficient(item, imgSize, dpi) {
    if(item.image.resource && item.image.resource.identifier){
        if(item.image.imageInfo){
            var imageInfo = item.image.imageInfo;
            imageInfo = orientationInit(imageInfo)
            item.lowSize=false;
            if(imageInfo.size/1024 < imgSize){
                item.lowSize = true;
                return item.lowSize;
            }
            if(item.image.angle == 90 || item.image.angle == 270){
                var _imageInfo = angular.copy(imageInfo);
                imageInfo.width = _imageInfo.height;
                imageInfo.height = _imageInfo.width;
            }
            var scale = item.image.scale || 1;
            var _dpiW = imageInfo.width*25.4/PxTurnMm(item.geometry.width) / scale;
            var _dpiH = imageInfo.height*25.4/PxTurnMm(item.geometry.height) / scale;
            var _dpi = _dpiW < _dpiH ? _dpiW : _dpiH;
            if (_dpi < dpi) {
                item.lowSize = true;
            } else {
                item.lowSize = false;
            }
            return item.lowSize;
        }else{
            if(_identifiers.indexOf(item.image.resource.identifier) == -1){
                _identifiers.push(item.image.resource.identifier)
                $.ajax({
                    type: "GET",
                    url: item.image.resource.identifier + "?imageInfo",
                    success: function (data1) {
                        item.image.imageInfo = data1;
                        _identifiers = [];
                        pxInsufficient(item, imgSize, dpi)
                    }
                })
            }
        }
    }
}

//检查每一页像素是否不足
function pageWarnExamine(pages, imgSize, imgDpi) {
    var warnIcon = false;
    pages.forEach(function (page) {
        if(page && page.levels && page.levels.level){
            page.levels.level.forEach(function (_level) {
                (function (level) {
                    if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && pxInsufficient(level.imagebox,imgSize, imgDpi)){
                        warnIcon = true;
                    }
                })(_level)
            })
        }
    })
    return warnIcon
}
function activeImageDpi(item){
    var scale = item.image.scale || 1;
    var imageInfo = item.image.imageInfo;
    imageInfo = orientationInit(imageInfo);
    if(item.image.angle == 90 || item.image.angle == 270){
        var _imageInfo = angular.copy(imageInfo);
        imageInfo.width = _imageInfo.height;
        imageInfo.height = _imageInfo.width;
    }
    var _dpiW = imageInfo.width*25.4/PxTurnMm(item.geometry.width) / scale;
    var _dpiH = imageInfo.height*25.4/PxTurnMm(item.geometry.height) / scale;
    var _dpi = _dpiW < _dpiH ? _dpiW : _dpiH;
    return _dpi;
}

//生成缩略图
function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}

//imagebox蒙版
function imageboxResourceCanvas($scope, item, callback) {
    var image = angular.copy(item);
    if(image.image && image.image.resource && image.image.resource.identifier){
        var angle = angular.copy(image.image.angle);
        if(image.flip){
            angle += 180
            if(angle>360){
                angle = angle - 360;
            }
        }
        image.imgUrl = image.image.resource.identifier + '?imageMogr2/strip/rotate/' + angle+'/thumbnail/200x200>';
    }
    var imagecanvas = document.createElement('canvas');
    var imagecontext = imagecanvas.getContext('2d');
    var newImg = document.createElement('img');
    newImg.crossOrigin = "Anonymous";
    newImg.src = image.imgUrl || 'content/images/mask_souce.png';
    newImg.onload = function () {
        newImg.onload = null;
        var _image = getImageSource(newImg, image, image.image.resource.identifier);
        var scaleW = _image.width / newImg.width;
        var scaleH = _image.height / newImg.height;
        var masks = document.createElement('img');
        var maskUrl = 'content/images/mask_souce.png';
        if (image.mask && image.mask.resource && image.mask.resource.identifier) {
            maskUrl = image.mask.resource.identifier + '?imageMogr2/strip/thumbnail/200x200>'
        }
        image.maskUrl = maskUrl;
        masks.src = maskUrl;
        masks.crossOrigin = "anonymous";
        masks.onload = function () {
            masks.onload = null;
            masks.width = image.geometry.width;
            masks.height = image.geometry.height;
            imagecanvas.width = masks.width;
            imagecanvas.height = masks.height;
            imagecontext.drawImage(masks, 0, 0, masks.width, masks.height);
            imagecontext.globalCompositeOperation = 'source-in';

            imagecontext.scale(scaleW, scaleH);
            imagecontext.scale(image.image.scale, image.image.scale);
            var offsetY = image.image.offsety;
            if (item.flip && item.bokehtype == 'None') {
                if (newImg.width / newImg.height < image.geometry.width / image.geometry.height) {
                    // var deltaY = (newImg.width * image.geometry.height / image.geometry.width - newImg.height) * scaleH - newImg.height * scaleH * (image.image.scale - 1) / 2;
                    var deltaY = (newImg.width / image.image.scale * image.geometry.height / image.geometry.width - newImg.height) * scaleH;
                    offsetY = deltaY - offsetY;
                } else if (image.image.scale >= 1) {
                    var deltaY = (1 - image.image.scale) * newImg.height * scaleH / image.image.scale;
                    offsetY = deltaY - offsetY;
                }
            }
            imagecontext.drawImage(newImg, image.image.offsetx / scaleW, offsetY / scaleH);
            var pic = imagecanvas.toDataURL();
            image.bgImg = dataURLtoBlob1(pic);
            item.bgImg = dataURLtoBlob1(pic);
            console.log(item.bgImg)
            if(callback){
                callback(item.bgImg)
            }
            $scope.$apply();
        };
        masks.src = maskUrl;
    };
    newImg.src = image.imgUrl;
}
function dataURLtoBlob1(dataurl) {
    var arr = dataurl.split(','),
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    var createObjectURL = function (blob) {
        return window[window.webkitURL ? 'webkitURL' : 'URL']['createObjectURL'](blob);
    };
    var fileURL = URL.createObjectURL(new Blob([u8arr], {type: 'image/*'}));
    return fileURL;
}
function getImageSource(newImg, imagebox, s1, currentPage) {
    var width = newImg.width;
    var height = newImg.height;

    var _w = imagebox.geometry.width;
    var _h = imagebox.geometry.height;
    var bgImgR = width / height;
    var bgImgR1 = _w / _h;
    var _image = {
        resource: {
            provider: 'qiniu',
            identifier: s1,
        },
        angle: 0,
        scale: 1
    }
    // if (bgImgR >= bgImgR1) {
    //     _image.adaptation = 'Height';
    //     _image.height = _h;
    //     _image.width = _h / height * width;
    // } else {
    //     _image.adaptation = 'Width';
    //     _image.width = _w;
    //     _image.height = _w / width * height;
    // }

    if (imagebox.bokehtype == 'None') {
        if (bgImgR >= bgImgR1) {
            _image.adaptation = 'Height';
            _image.height = _h;
            _image.width = _h / height * width;
        } else {
            _image.adaptation = 'Width';
            _image.width = _w;
            _image.height = _w / width * height;
        }
    } else {
        if (bgImgR >= bgImgR1) {
            _image.adaptation = 'Height';
            _image.width = _w;
            _image.height = _w / width * height;
            imagebox.image.offsetx= 0;
            imagebox.image.offsety= (_h - _w / width * height) / 2;
        } else {
            _image.adaptation = 'Width';
            _image.height = _h;
            _image.width = _h / height * width;
            imagebox.image.offsetx= (_w - _h / height * width) / 2;
            imagebox.image.offsety= 0;
        }
    }


    _image.imgWidth = width;
    _image.imgHeight = height;

    return _image
}

//鼠标样式
function cursorInfo(angle, source, boolean) {
    var _cursor = '';
    if(angle>0 && !boolean){
        angle = 360 - angle;
    }
    if(source == 'left'){
        if(337<=angle || angle<22){_cursor = 'nw-resize';}
        if(22<=angle && angle<67){_cursor = 'n-resize';}
        if(67<=angle && angle<112){_cursor = 'ne-resize';}
        if(112<=angle && angle<157){_cursor = 'e-resize';}
        if(157<=angle && angle<202){_cursor = 'se-resize';}
        if(202<=angle && angle<247){_cursor = 's-resize';}
        if(247<=angle && angle<292){_cursor = 'sw-resize';}
        if(292<=angle && angle<337){_cursor = 'w-resize';}
    }
    if(source == 'top'){
        if(337<=angle || angle<22){_cursor = 'ne-resize';}
        if(22<=angle && angle<67){_cursor = 'e-resize';}
        if(67<=angle && angle<112){_cursor = 'se-resize';}
        if(112<=angle && angle<157){_cursor = 's-resize';}
        if(157<=angle && angle<202){_cursor = 'sw-resize';}
        if(202<=angle && angle<247){_cursor = 'w-resize';}
        if(247<=angle && angle<292){_cursor = 'nw-resize';}
        if(292<=angle && angle<337){_cursor = 'n-resize';}
    }
    if (source == 'right') {
        if(337<=angle || angle<22){_cursor = 'se-resize';}
        if(22<=angle && angle<67){_cursor = 's-resize';}
        if(67<=angle && angle<112){_cursor = 'sw-resize';}
        if(112<=angle && angle<157){_cursor = 'w-resize';}
        if(157<=angle && angle<202){_cursor = 'nw-resize';}
        if(202<=angle && angle<247){_cursor = 'n-resize';}
        if(247<=angle && angle<292){_cursor = 'ne-resize';}
        if(292<=angle && angle<337){_cursor = 'e-resize';}
    }
    if (source == 'bottom'){
        if(337<=angle || angle<22){_cursor = 'sw-resize';}
        if(22<=angle && angle<67){_cursor = 'w-resize';}
        if(67<=angle && angle<112){_cursor = 'nw-resize';}
        if(112<=angle && angle<157){_cursor = 'n-resize';}
        if(157<=angle && angle<202){_cursor = 'ne-resize';}
        if(202<=angle && angle<247){_cursor = 'e-resize';}
        if(247<=angle && angle<292){_cursor = 'se-resize';}
        if(292<=angle && angle<337){_cursor = 's-resize';}
    }


    if(source == 'left1'){
        if(337<=angle || angle<22){_cursor = 'w-resize';}
        if(22<=angle && angle<67){_cursor = 'nw-resize';}
        if(67<=angle && angle<112){_cursor = 'n-resize';}
        if(112<=angle && angle<157){_cursor = 'ne-resize';}
        if(157<=angle && angle<202){_cursor = 'e-resize';}
        if(202<=angle && angle<247){_cursor = 'se-resize';}
        if(247<=angle && angle<292){_cursor = 's-resize';}
        if(292<=angle && angle<337){_cursor = 'sw-resize';}
    }

    if(source == 'top1'){
        if(337<=angle || angle<22){_cursor = 'n-resize';}
        if(22<=angle && angle<67){_cursor = 'ne-resize';}
        if(67<=angle && angle<112){_cursor = 'e-resize';}
        if(112<=angle && angle<157){_cursor = 'se-resize';}
        if(157<=angle && angle<202){_cursor = 's-resize';}
        if(202<=angle && angle<247){_cursor = 'sw-resize';}
        if(247<=angle && angle<292){_cursor = 'w-resize';}
        if(292<=angle && angle<337){_cursor = 'nw-resize';}
    }

    if (source == 'right1') {
        if(337<=angle || angle<22){_cursor = 'e-resize';}
        if(22<=angle && angle<67){_cursor = 'se-resize';}
        if(67<=angle && angle<112){_cursor = 's-resize';}
        if(112<=angle && angle<157){_cursor = 'sw-resize';}
        if(157<=angle && angle<202){_cursor = 'w-resize';}
        if(202<=angle && angle<247){_cursor = 'nw-resize';}
        if(247<=angle && angle<292){_cursor = 'n-resize';}
        if(292<=angle && angle<337){_cursor = 'ne-resize';}
    }

    if (source == 'bottom1') {
        if(337<=angle || angle<22){_cursor = 's-resize';}
        if(22<=angle && angle<67){_cursor = 'sw-resize';}
        if(67<=angle && angle<112){_cursor = 'w-resize';}
        if(112<=angle && angle<157){_cursor = 'nw-resize';}
        if(157<=angle && angle<202){_cursor = 'n-resize';}
        if(202<=angle && angle<247){_cursor = 'ne-resize';}
        if(247<=angle && angle<292){_cursor = 'e-resize';}
        if(292<=angle && angle<337){_cursor = 'se-resize';}
    }
    return _cursor
}
//page排序
function pageSeq(page, callback) {
    page.forEach(function (item, index) {
        item.seq = index + 1;
    })
    callback(page)
}

//编辑器获取消息列表
function getMessageCustom(vm, DateUtils, MessageNotification, callback) {
    MessageNotification.getAllByCondition({
            items:[
                {key:"pageType", op : "=", value:"Edit"},
                {key:"storeId", op : "=", value:vm.storeId},
                {key:"available", op : "=", value:"true"},
                {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
        }, function (res) {
            callback(res)
        }
    )
}
function dropShadow(imagebox,width,height){
    if(imagebox.shadow.x == 0 && imagebox.shadow.y == 0 && imagebox.shadow.blur == 0){
        return ''
    }
    var blur = 0;
    var x = 0;
    var y = 0;
    if (width>=height) {
        blur = width * imagebox.shadow.blur * 0.005
    }else{
        blur = height * imagebox.shadow.blur * 0.005
    }
    x = width * imagebox.shadow.x * 0.01;
    y = height * imagebox.shadow.y * 0.01;
    return 'drop-shadow('+x+'px '+y+'px '+blur+'px '+imagebox.shadow.color+')'
}

//匹配最接近的图片框
function imageboxAdapt(levels, imgs) {
    var imageboxRatios = [];
    angular.forEach(levels, function (level, i) {
        if (level.imagebox && level.imagebox.geometry) {
            imageboxRatios.push(level.imagebox.geometry.width/level.imagebox.geometry.height);
        }
    });
    var differenceValues = [];
    imageboxRatios.forEach(function (item, i) {
        imgs.forEach(function (img, i1) {
            differenceValues.push({
                value: differenceValue(img.imgRatio, item),
                imageboxIndex:i,
                img:img,
                imgIndex: i1
            })
        })
    })
    differenceValues.sort(function(a,b){return a.value-b.value});
    var differenceValues1 = [];
    differenceValues.forEach(function (item) {
        if(differenceValues1.length == 0){
            item.img.imageboxIndex = item.imageboxIndex;
            item.img.imgIndex = item.imgIndex;
            item.img.value = item.value;
            differenceValues1.push(item.img)
        }else{
            var isExist = true;
            for(var i=0; i<differenceValues1.length; i++){
                if(differenceValues1[i].imageboxIndex == item.imageboxIndex || differenceValues1[i].imgIndex == item.imgIndex){
                    isExist = false;
                    break;
                }
            }
            if(isExist){
                item.img.imageboxIndex = item.imageboxIndex;
                item.img.imgIndex = item.imgIndex;
                item.img.value = item.value;
                differenceValues1.push(item.img)
            }
        }
    })
    if(differenceValues1.length>1){
        differenceValues1.sort(function(a,b){ return a.imageboxIndex-b.imageboxIndex});
    }
    return differenceValues1;
}
function differenceValue(a, b) {
    var _c = 0;
    if(a>b){_c=a-b;}else{_c=b-a;};
    return _c
}
//随机布局
function randomPlacement(Convert,Algorithm,pages,placementList,photoSelectAll,_gallaryPicCounts,callback) {
    var _pages = pages;
    var _page = [];
    var _candidates = [];
    var images = [];
    for(var i=_gallaryPicCounts; i<photoSelectAll.length; i++){
        images.push({
            id: photoSelectAll[i].id,
            width: photoSelectAll[i].width,
            height: photoSelectAll[i].height,
        })
    }
    console.log(placementList)
    placementList.forEach(function (placemen) {
        placemen.data.forEach(function (_data) {
            _data.uuid = getUuid();
            var holes = [];
            for(var i=0; i<_data.quantity; i++){
                holes.push({
                    id: _data.uuid,
                    width: 100,
                    height: 100,
                })
            }
            _candidates.push({
                "name": _data.uuid,
                "holes": holes,
                "numberOfHoles":_data.quantity,
                "replaceable": true
            })
        })
    })
    pages.forEach(function (page){
        _page.push({
            "name":  placementList[0].uuid,
            "holes": placementList[0].holes,
            "numberOfHoles": placementList[0].quantity,
            "replaceable": true
        });
    });
    Algorithm.pageSelectV2({
        pages: _page,
        candidates: _candidates,
        numberOfExpected: images.length,
        images:images
    },function (res){
        if(res.numberOfExpected != res.numberOfHoles){
            callback(_pages, true)
            return
        }
        var _uuids = [];
        res.pages.forEach(function (page) {
            _uuids.push(page.name)
        })
        var _pageIndex = 0;
        _pages.forEach(function (page,index) {
            (function (page1,index1) {
                getPlacement(Convert,page1, _uuids[index1], placementList, function () {
                    _pageIndex++;
                    if(_pageIndex == _pages.length){
                        callback(_pages)
                    }
                })
            })(page,index)
        })
    })
}
function getPlacement(Convert,page, uuid, placementList, callback) {
    var _placement = "";
    for(var i=0; i<placementList.length; i++){
        placementList[i].data.forEach(function (_data) {
            if(uuid == _data.uuid){
                _placement = _data.resource.identifier;
            }
        })
    }
    if(!_placement){
        callback(page)
        return
    }
    Convert.xmlToPicLayoutDocument({url: _placement}, function (res) {
        var mediabox = {
            height: transitionPx(res.mediabox.height),
            width: transitionPx(res.mediabox.width),
            x: transitionPx(res.mediabox.x),
            y: transitionPx(res.mediabox.y)
        };
        var _ratio = 1;
        if(page.mediabox.height / mediabox.height >= page.mediabox.width / mediabox.width ){
            _ratio = page.mediabox.width / mediabox.width;
        }else {
            _ratio = page.mediabox.height / mediabox.height;
        }
        if(!page.levels.level){
            page.levels.level=[];
        }
        for(var i=page.levels.level.length-1; i>=0; i--){
            if(page.levels.level[i].imagebox){
                page.levels.level.splice(i,1)
            }
        }
        res.levels.level.forEach(function (level) {
            if(level.imagebox){
                level.imagebox.geometry={
                    width: transitionPx(level.imagebox.geometry.width*_ratio),
                    height: transitionPx(level.imagebox.geometry.height*_ratio),
                    x: transitionPx(level.imagebox.geometry.x*_ratio),
                    y: transitionPx(level.imagebox.geometry.y*_ratio),
                }
                level.seq = page.levels.level.length-1;
                page.levels.level.push(level)
            }
        })
        callback(page)
    })
}

function getUuid(){
    var d = new Date().getTime();
    var uid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
    return uid;
}

//裁切区域
function pageTrimbox(page, text) {
    if(page && page.custom){
        page.mediabox1 = angular.copy(page.mediabox);
        page.custom.width = page.trimbox.width;
        page.custom.height = page.trimbox.height;
        page.mediabox.width = page.trimbox.width;
        page.mediabox.height = page.trimbox.height;
        if (page.background && page.background.resource &&page.background.resource.identifier) {
            page.background.offsetx = page.background.offsetx-page.trimbox.x;
            page.background.offsety = page.background.offsety-page.trimbox.y;
        }
        if (page.barcode) {
            page.barcode.geometry.x = page.barcode.geometry.x-page.trimbox.x;
            page.barcode.geometry.y = page.barcode.geometry.y-page.trimbox.y;
        }
        if (page.qrcode) {
            page.qrcode.geometry.x = page.qrcode.geometry.x-page.trimbox.x;
            page.qrcode.geometry.y = page.qrcode.geometry.y-page.trimbox.y;
        }
        angular.forEach(page.levels.level, function (level, i) {
            if (level.imageart) {
                level.imageart.geometry.x = level.imageart.geometry.x-page.trimbox.x;
                level.imageart.geometry.y = level.imageart.geometry.y-page.trimbox.y;
            }
            if(level.imagebox){
                level.imagebox.geometry.x = level.imagebox.geometry.x-page.trimbox.x;
                level.imagebox.geometry.y = level.imagebox.geometry.y-page.trimbox.y;
            }
            if (level.textbox) {
                if (!(level.textbox.text == '' || level.textbox.text == null || level.textbox.text == text || level.textbox.text == '双击输入文字' || level.textbox.text == '请输入文字') && (level.textbox.geometry.width != 0 && level.textbox.geometry.height != 0)) {
                    level.textbox.geometry.x = level.textbox.geometry.x-page.trimbox.x;
                    level.textbox.geometry.y = level.textbox.geometry.y-page.trimbox.y;
                }else{
                    level.textbox = null;
                }
            }
            if (level.textdocument) {
                level.textdocument.geometry.x = level.textdocument.geometry.x-page.trimbox.x;
                level.textdocument.geometry.y = level.textdocument.geometry.y-page.trimbox.y;
            }
            if (level.code) {
                level.code.geometry.x = level.code.geometry.x-page.trimbox.x;
                level.code.geometry.y = level.code.geometry.y-page.trimbox.y;
            }
            if (level.calTitleBox) {
                level.calTitleBox.geometry.x = level.calTitleBox.geometry.x-page.trimbox.x;
                level.calTitleBox.geometry.y = level.calTitleBox.geometry.y-page.trimbox.y;
            }
            if (level.calMonthBox) {
                level.calMonthBox.geometry.x = level.calMonthBox.geometry.x-page.trimbox.x;
                level.calMonthBox.geometry.y = level.calMonthBox.geometry.y-page.trimbox.y;
            }
        })
    }
}

//判断手机型号
function isIPhone6() {
    var events = navigator.userAgent;
    var _isIPhone6 = false;
    if(events.indexOf('iPhone')>-1){
        //根据尺寸进行判断 苹果的型号
        if(screen.height == 812 && screen.width == 375){
        }else if(screen.height == 736 && screen.width == 414){
            _isIPhone6 = true;
        }else if(screen.height == 667 && screen.width == 375){
            _isIPhone6 = true;
        }else if(screen.height == 568 && screen.width == 320){
            _isIPhone6 = true;
        }
    }
    return _isIPhone6
}

//智能布局
function smartLayout(pages, Algorithm, photoSelectAll, source, callback){
    pages.page.forEach(function (page){
        page.uuid1 = getUuid();
    });
    var _pages = angular.copy(pages);
    var _page = [];
    var _candidates = [];
    var images = [];
    var _photoSelectAll = [];
    photoSelectAll.forEach(function (image){
        images.push({
            id: image.id,
            width: image.width,
            height: image.height,
        })
    })
    pages.page.forEach(function (page){
        page.numberOfHoles = 0;
        page.holes = [];
        if(page.levels.level){
            page.levels.level.forEach(function (level){
                if(level.imagebox && level.imagebox.geometry){
                    if(!source || (source == 'photoAlbum' && (!level.imagebox.image || !level.imagebox.image.resource || !level.imagebox.image.resource.identifier))){
                        page.numberOfHoles++;
                        page.holes.push({
                            id: getUuid(),
                            width: level.imagebox.geometry.width,
                            height: level.imagebox.geometry.height,
                        });
                    }
                }
            })
        }
        if(!page.backup){
            _page.push({
                "name": page.uuid1,
                "holes": page.holes,
                "numberOfHoles": page.numberOfHoles,
                "replaceable": !page.unreplace
            });
        }else{
            _candidates.push({
                "name": page.uuid1,
                "holes": page.holes,
                "numberOfHoles": page.numberOfHoles,
                "replaceable": !page.unreplace
            })
        }
    });
    Algorithm.pageSelectV2({
        pages: _page,
        candidates: _candidates,
        numberOfExpected: photoSelectAll.length,
        images:images
    },function (res){
        var _page1=[];
        if(res.pages && res.pages.length>0){
            for(var i=0; i<res.pages.length; i++){
                for(var j=0; j<_pages.page.length; j++){
                    if(res.pages[i].name == _pages.page[j].uuid1){
                        var _pageData = angular.copy(_pages.page[j]);
                        _pageData.unreplace = !res.pages[i].replaceable;
                        _pageData.backup = false;
                        _page1.push(_pageData);
                        break;
                    }
                }
            }
        }
        if(res.unusedPages && res.unusedPages.length>0){
            for(var i=0; i<res.unusedPages.length; i++){
                for(var j=0; j<_pages.page.length; j++){
                    if(res.unusedPages[i].name == _pages.page[j].uuid1){
                        var _pageData1 = angular.copy(_pages.page[j]);
                        _pageData1.unreplace = !res.unusedPages[i].replaceable;
                        _pageData1.backup = true;
                        _page1.push(_pageData1);
                        break;
                    }
                }
            }
        }
        res.images.forEach(function (imageId){
            for (var i=0; i<photoSelectAll.length; i++){
                if(photoSelectAll[i].id == imageId){
                    _photoSelectAll.push(photoSelectAll[i]);
                }
            }
        })
        callback(_page1, _photoSelectAll)
    })
}
function smartLayout2(pages,backupPages, Algorithm, photoSelectAll, source, callback){
    var _pages = angular.copy(pages);
    var _backupPages = [];
    backupPages.forEach(function (page) {
        getPageType(page)
        if (!_pages.page.some(function(obj){obj.uuid === page.uuid})) {
            _backupPages.push(angular.copy(page));
        }
    });
    var _pages1 = [];
    _pages.page.forEach(function (page){
        getPageType(page)
        page.uuid1 = getUuid();
        page.backup = false;
        _pages1.push(page)
    });
    _backupPages.forEach(function (page){
        page.uuid1 = getUuid();
        page.backup = true;
        _pages1.push(page)
    });
    var _page = [];
    var _candidates = [];
    var images = [];
    var _photoSelectAll = [];
    photoSelectAll.forEach(function (image){
        images.push({
            id: image.id,
            width: image.width,
            height: image.height,
        })
    })
    _pages1.forEach(function (page){
        page.numberOfHoles = 0;
        page.holes = [];
        if(page.levels.level){
            page.levels.level.forEach(function (level){
                if(level.imagebox && level.imagebox.geometry){
                    if(!level.imagebox.image || !level.imagebox.image.resource || !level.imagebox.image.resource.identifier){
                        page.numberOfHoles++;
                        page.holes.push({
                            id: getUuid(),
                            width: level.imagebox.geometry.width,
                            height: level.imagebox.geometry.height,
                        });
                    }
                }
            })
        }
        if(!page.backup){
            _page.push({
                "name": page.uuid1,
                "holes": page.holes,
                "numberOfHoles": page.numberOfHoles,
                "replaceable": page.config.partid == 'P' ? !page.unreplace : false,
                "spread":page.spread
            });
        }else{
            if(page.config.partid == 'P'){
                _candidates.push({
                    "name": page.uuid1,
                    "holes": page.holes,
                    "numberOfHoles": page.numberOfHoles,
                    "replaceable": page.config.partid == 'P' ? !page.unreplace : false,
                    "spread":page.spread
                })
            }
        }
    });
    Algorithm.pageSelectV2({
        pages: _page,
        candidates: _candidates,
        numberOfExpected: photoSelectAll.length,
        images:images
    },function (res){
        var _page1=[];
        var _backupPages1=[];
        if(res.pages && res.pages.length>0){
            for(var i=0; i<res.pages.length; i++){
                for(var j=0; j<_pages1.length; j++){
                    if(res.pages[i].name == _pages1[j].uuid1){
                        var _pageData = angular.copy(_pages1[j]);
                        _pageData.unreplace = !res.pages[i].replaceable;
                        _pageData.backup = false;
                        _page1.push(_pageData);
                        break;
                    }
                }
            }
        }
        if(res.unusedPages && res.unusedPages.length>0){
            for(var i=0; i<res.unusedPages.length; i++){
                for(var j=0; j<_pages1.length; j++){
                    if(res.unusedPages[i].name == _pages1[j].uuid1){
                        var _pageData1 = angular.copy(_pages1[j]);
                        _pageData1.unreplace = !res.unusedPages[i].replaceable;
                        _pageData1.backup = true;
                        _backupPages1.push(_pageData1);
                        break;
                    }
                }
            }
        }
        res.images.forEach(function (imageId){
            for (var i=0; i<photoSelectAll.length; i++){
                if(photoSelectAll[i].id == imageId){
                    _photoSelectAll.push(photoSelectAll[i]);
                }
            }
        })
        callback(_page1,_backupPages1, _photoSelectAll)
    })
}
function getUuid(){
    var d = new Date().getTime();
    var uid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
    return uid;
}

//微信阻止字体放大
function handleFontSizeInit() {
    if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
        handleFontSize();
    } else {
        if (document.addEventListener) {
            document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
        } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", handleFontSize);
            document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
        }
    }
    function handleFontSize() {
        WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
        WeixinJSBridge.on('menu:setfont', function () {
            WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
        });
    }

}


function filterFiles(fileList) {
    var allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/heic'];
    var filteredFiles = [];

    for (var i = 0; i < fileList.length; i++) {
      var file = fileList[i];
      if (allowedTypes.includes(file.type)) {
        filteredFiles.push(file);
      } else if (file.type === '') {
        // 通过读取图片的base64编码来判断图片格式
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend =function() {
          var base64 = reader.result;
          var type = base64.substring(5, base64.indexOf(';'));
          if (allowedTypes.includes(type)) {
            var blob = base64ToBlob(base64);
            var newFile = new File([blob], file.name, { type:type });
            filteredFiles.push(newFile);
          }
        };
      }
    }

    return filteredFiles;
  }

  function base64ToBlob(base64) {
    var parts = base64.split(';base64,');
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }
/**
 * 获取年月
 * */
function getDay(item,year,month,monthIndex) {
    var _ret = "";
    var month = month<10?"0"+month:month;
    var currentDate = new Date(year + "-" + month);
    currentDate.setMonth(currentDate.getMonth() + monthIndex-1);
    if(item=='year'){
        _ret = currentDate.getFullYear()
    }
    if(item=='month'){
        _ret = (currentDate.getMonth() + 1)
    }
    return _ret
}

//获取当前页的类型
function getPageType(page) {
    page.genre = 'original';
    if(page && page.levels && page.levels.level){
        page.levels.level.forEach(function (level) {
            if(page.genre != 'calAll'){
                if(level.calTitleBox&&level.calTitleBox.geometry){
                    if(page.genre == 'calMonth'){
                        page.genre = 'calAll';
                    }else{
                        page.genre = 'calTitle';
                    }
                }
                if(level.calMonthBox&&level.calMonthBox.geometry){
                    if(page.genre == 'calTitle'){
                        page.genre = 'calAll';
                    }else{
                        page.genre = 'calMonth';
                    }
                }
            }
        })
    }
}
//通过部件获取页
function getPage(partid,pages) {
    var page = pages[0];
    for(var i=0; i<pages.length; i++){
        if(pages[i].config.partid == partid){
            page = pages[i];
            break
        }
    }
    return page
}
//获取日历一行几列
function displayType(item) {
    switch (item) {
        case 'C7':
            return 7;
        case 'C14':
            return 14;
        case 'C21':
            return 21;
        default:
            return 7;
    }
}
//判断商户存储空间
function uploadAble(StoreStorages,storeId,callback) {
    StoreStorages.uploadAble({
        storeId:storeId
    },function (res) {
        callback(res)
    },function (error) {
        callback(error)
    })
}


// 圆角切换  固定/百分比
function switchToRoundedMode(imagebox, borderRadiusAllbtn) {
    var radiusW = Math.min(imagebox.geometry.width, imagebox.geometry.height);
    radiusW = radiusW + Number(imagebox.border.lineWidth);
    if (imagebox.border.radius.filletType == 'Proportion') {// 固定值转百分比
        if (borderRadiusAllbtn == true) {
            var all = (imagebox.border.radius.all / radiusW * 100) > 100 ? 100 : Math.round(imagebox.border.radius.all / radiusW * 100);
            imagebox.border.radius.all = all;
            imagebox.border.radius.lt = all;
            imagebox.border.radius.rt = all;
            imagebox.border.radius.rb = all;
            imagebox.border.radius.lb = all;
        } else {
            var scaleDown = scaleDownTo100((imagebox.border.radius.lt / radiusW * 100), (imagebox.border.radius.rt / radiusW * 100), (imagebox.border.radius.rb / radiusW * 100), (imagebox.border.radius.lb / radiusW * 100))
            imagebox.border.radius.lt = scaleDown[0];
            imagebox.border.radius.rt = scaleDown[1];
            imagebox.border.radius.rb = scaleDown[2];
            imagebox.border.radius.lb = scaleDown[3];
        }
    } else {//百分比转固定值
        if (borderRadiusAllbtn == true) {
            var all = Math.round(imagebox.border.radius.all / 100 * radiusW);
            imagebox.border.radius.all = all;
            imagebox.border.radius.lt = all;
            imagebox.border.radius.rt = all;
            imagebox.border.radius.rb = all;
            imagebox.border.radius.lb = all;
        } else {
            imagebox.border.radius.lt = Math.round(imagebox.border.radius.lt / 100 * radiusW);
            imagebox.border.radius.rt = Math.round(imagebox.border.radius.rt / 100 * radiusW);
            imagebox.border.radius.rb = Math.round(imagebox.border.radius.rb / 100 * radiusW);
            imagebox.border.radius.lb = Math.round(imagebox.border.radius.lb / 100 * radiusW);
        }
    }


    // 等比列缩小到100以下
    function scaleDownTo100(value1, value2, value3, value4) {
        var maxValue = Math.max(value1, value2, value3, value4);
        if (maxValue < 100) {
            return [Math.round(value1), Math.round(value2), Math.round(value3), Math.round(value4)];
        }
        var scaleRatio = 100 / maxValue;

        // 缩小数值
        var scaledValue1 = Math.round(value1 * scaleRatio);
        var scaledValue2 = Math.round(value2 * scaleRatio);
        var scaledValue3 = Math.round(value3 * scaleRatio);
        var scaledValue4 = Math.round(value4 * scaleRatio);

        // 返回缩小后的数值
        return [scaledValue1, scaledValue2, scaledValue3, scaledValue4];
    }
}
