(function() {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('gallery', {
            parent: 'entity',
            url: '/{storeId}/gallery',
            data: {
                authorities: [],
                pageTitle: 'editorApp.gallery.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/gallery/gallery.html',
                    controller: 'GalleryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gallery');
                    $translatePartialLoader.addPart('common');
                    return $translate.refresh();
                }]
            }
        }).state('gallery.choose', {
            parent: 'entity',
            url: '/{storeId}/{pid}/gallery',
            data: {
                authorities: [],
                pageTitle: 'editorApp.gallery.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/gallery/gallery.html',
                    controller: 'GalleryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gallery');
                    $translatePartialLoader.addPart('common');
                    return $translate.refresh();
                }]
            }
        }).state('gallery.chooses', {
            parent: 'entity',
            url: '/{storeId}/{aid}/gallerys',
            data: {
                authorities: [],
                pageTitle: 'editorApp.gallery.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/gallery/gallery.html',
                    controller: 'GalleryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gallery');
                    $translatePartialLoader.addPart('common');
                    return $translate.refresh();
                }]
            }
        })
        .state('gallery-edit', {
            parent: 'entity',
            url: '/{storeId}/cart/editGallery',
            data: {
                authorities: [],
                pageTitle: 'editorApp.gallery.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/cart/gallery-dialog.html',
                    controller: 'GalleryDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gallery');
                    $translatePartialLoader.addPart('common');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Gallery', function($stateParams, Gallery) {
                    return Gallery.get().$promise;
                }]
            }
        });
    }

})();
