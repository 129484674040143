(function () {
    'use strict';

    var threePreview4 = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/three-preview4.html',
        // controller: 'articlePreviewController',
        controllerAs: 'vm',
        bindings: {
            height: '<',
            width: '<',
            moulds: '<',
            modelPath: '<',
            modelBlendents: '<',
            modelData: '<',
            modelBlendentUuid: '<',
            pages: '<',
            source: '<',
            onChange:"&"
        },
        controller: ['$timeout','$scope',
            function ($timeout, $scope) {
                var vm = this;
                vm.viewTab = 'Three';
                vm.mouldIndex = 0;
                vm.isLoading = true;
                vm.$onInit = function(){
                    if(!vm.width){vm.width = $(window).width()}
                    if(!vm.height){vm.height = $(window).width()}
                }
                vm.$onChanges = function(data) {
                    if (data.height&&data.height.currentValue) {
                        vm.height = data.height.currentValue;
                    }
                    if (data.width&&data.width.currentValue) {
                        vm.width = data.width.currentValue;
                    }
                    if (data.moulds&&data.moulds.currentValue) {
                        vm.moulds = data.moulds.currentValue;
                    }
                    if (data.modelPath&&data.modelPath.currentValue) {
                        vm.modelPath = data.modelPath.currentValue;
                    }
                    if (data.modelBlendents&&data.modelBlendents.currentValue) {
                        vm.modelBlendents = data.modelBlendents.currentValue;
                    }
                    if (data.modelData&&data.modelData.currentValue) {
                        vm.modelData = data.modelData.currentValue;
                    }
                    if (data.modelBlendentUuid&&data.modelBlendentUuid.currentValue) {
                        vm.modelBlendentUuid = data.modelBlendentUuid.currentValue;
                    }
                    if (data.pages&&data.pages.currentValue) {
                        vm.pages = null;
                        vm.isUpdate = false;
                        $timeout(function () {
                            vm.pages = data.pages.currentValue;
                        })
                    }
                };

                $scope.viewTabCb = function () {
                    vm.viewTab = 'Two';
                }
                $scope.flipOver=function (item) {
                    if(item == 'prev' && vm.mouldIndex>0){
                        vm.mouldIndex--
                        vm.isLoading = false;
                        $timeout(function () {
                            vm.isLoading = true;
                        })
                    }
                    if(item == 'next' && vm.moulds.length-1){
                        vm.mouldIndex++
                        vm.isLoading = false;
                        $timeout(function () {
                            vm.isLoading = true;
                        })
                    }
                }
            }
        ]
    };

    angular
        .module('editorApp')
        .component('threePreview4', threePreview4);
})();
