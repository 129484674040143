(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('EditorFormController', EditorFormController);

    EditorFormController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'WxJS', 'Gallery', 'ItemValue', 'SweetAlert', 'Theme', '$localStorage', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'Store', 'Article', 'CommonService', '$http', 'Folder', 'MessageNotification', 'ThemeClassify', 'entity', 'Product', 'ProductXml', 'DateUtils',
        'Iphone','AuthServerProvider','RecommendPics','$translate','StoreWorkEditConfig','MessageService','IMAGE_SUPPORTED_MIME','StoreStorages','$websocket'];

    function EditorFormController($state, $scope, $q, $stateParams, Convert, Qinius, WxJS, Gallery, ItemValue, SweetAlert, Theme, $localStorage, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, Store, Article, CommonService, $http, Folder, MessageNotification, ThemeClassify, entity, Product, ProductXml, DateUtils,
                                  Iphone, AuthServerProvider, RecommendPics,$translate,StoreWorkEditConfig,MessageService,IMAGE_SUPPORTED_MIME,StoreStorages, $websocket) {
        //初始化
        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});

        var vm = this;
        vm.isIPhone6 = isIPhone6();
        vm.isEdit = entity.type == 'edit' ? true : false;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.counts = 0;
        vm.indexNum = 0;
        vm.itemsPerPage = 40;
        vm.photoAll = [];
        $scope.domains = Qinius.getDomain();
        vm.tokenTime = 360000;
        vm.supportedMimeTypes = IMAGE_SUPPORTED_MIME;
        Qinius.get({}, function (res) {
            vm.token = res.token;
            vm.tokenTime = parseInt((res.expireAt - new Date().getTime()));
        });
        vm.platformSetting = PlatformWechatSetting.get();
        vm.photoArray = [];
        vm.photoSelectAll = localStorage.getItem("photoSelectAll") ? JSON.parse(localStorage.getItem("photoSelectAll")) : [];
        vm.isPhone = false;
        vm.threeIsModal = false;
        vm.threeModalText = "";
        vm.threeLoading = true;
        vm.threeLoadingText=$translate.instant("hint.loadingText");
        vm.threeError = false;
        vm.threeErrorText=$translate.instant("hint.loadingText");
        vm.store = Store.get({id: vm.storeId});
        vm.fontList = [];
        $scope.agentIos = false;
        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            //或者return false;
            e.preventDefault();
        };

        function threeInfo(item){
            vm.threeError = true;
            vm.threeErrorText=item;
            $timeout(function () {
                vm.threeError = false;
                vm.threeErrorText="";
            },1500)
        }
        $q.all([$scope.domains.$promise,vm.platformSetting.$promise]).then(function () {
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';

            vm.platformSetting = vm.platformSetting.data;
            vm.lagnuages =[];
            if ($localStorage.lagnuages) {
                for(var i = 0 ; i<$localStorage.lagnuages.length; i++ ){
                    var lang = $localStorage.lagnuages[i];
                    var langObj = {key:lang};
                    if (lang == 'zh-cn') {
                        langObj.value = "中文";
                    }
                    if (lang == 'en') {
                        langObj.value = "English";
                    }
                    if (vm.lang == lang) {
                        vm.langValue = langObj.value;
                        langObj.selected = true;
                    }
                    vm.lagnuages.push(langObj);
                }
            }

            if (wx) {
                var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
                WxJS.get({url: url}, function (res) {
                    if(res.status==200) {
                        $scope.agentIos = browserVersions();
                        var config = res.data;
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.appId, // 必填，公众号的唯一标识
                            timestamp: config.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.nonceStr, // 必填，生成签名的随机串
                            signature: config.signature,// 必填，签名，见附录1
                            jsApiList: ['chooseImage', 'uploadImage', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function () {
                            console.log('wechat api ready!');

                            wx.hideMenuItems({
                                menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                            });
                        });
                        wx.error(function (res) {
                            console.log(res)
                        });
                    }
                }, function () {
                    console.log("wx config error");
                });
            }

            websocket();
        });
        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if(res.status == 200){
                vm.imgSize = res.data.imgSize;
                vm.imgDpi = res.data.imgDpi;
            }
        });
        init()
        function init() {
            if (!vm.isEdit) {
                Article.getByUser({id: $stateParams.aid}, function (resp) {
                    if (resp.status == 200) {
                        vm.article = resp.data;
                        if(vm.article.invalid){//作品失效
                            $state.go('articlesFailure',{storeId:vm.storeId});
                            return
                        }
                        getProduct(function () {
                            ItemValue.get({id: $stateParams.vid}, function (res) {
                                $("body").toggleClass('bg');
                                vm.pattern = res.data.patternType;
                                $scope.status = localStorage.getItem('Production');
                                vm.productXmlLine = angular.copy(res.data.productXmlLines[0]);
                                angular.forEach(res.data.productXmlLines, function (line) {
                                    if (line.id == vm.xmlId) {
                                        vm.productXmlLine = angular.copy(line);
                                    }
                                })
                                vm.article.modelBlendentUuid = vm.productXmlLine.modelBlendentUuid;
                                vm.article.cover = vm.productXmlLine.coverImg;
                                vm.article.themeClassifyId = vm.productXmlLine.productXmlThemeId;
                                vm.article.themeClassifyName = vm.productXmlLine.productXmlThemeName;
                                vm.article.productXmlId = vm.productXmlLine.productXmlId;
                                vm.article.productXmlName = vm.productXmlLine.productXmlName;
                                vm.article.musicIdentify = vm.productXmlLine ? vm.productXmlLine.musicIdentify : res.data.musicIdentify;
                                vm.article.editorType= res.data.productEditorType;
                                vm.article.itemValueId= res.data.itemValueId || vm.vid;
                                if (!res.data.productBokehType) {
                                    vm.article.bokehtype = 'None';
                                } else {
                                    vm.article.bokehtype = res.data.productBokehType;
                                }
                                Convert.xmlToDocument2({
                                    identifier: vm.productXmlLine.productXmlIdentifier,
                                    provider: "qiniu",
                                    editorType:vm.article.editorType,
                                    patternType:vm.product.patternType,
                                }, function (res) {
                                    vm.replace = res.replace;
                                    vm.counts = 0;
                                    $scope.pages = res;
                                    angular.forEach(res.pages.page, function (page, i) {
                                        if(!page.backup){
                                            angular.forEach(page.levels.level, function (level) {
                                                if (level.imagebox && level.imagebox.geometry) {
                                                    vm.counts++;
                                                }
                                                if (level.textbox && level.textbox.geometry) {
                                                    level.textbox.maxLength = level.textbox.text.length>20 ? level.textbox.text.length : 20;
                                                }
                                            })
                                        }
                                    });
                                    abook();
                                    vm.threeLoading = false;
                                });
                            });
                        });
                    }
                },function (error) {
                    if(error.status === 403){//token失效
                        return $state.go('404',{storeId:vm.storeId});
                    }
                    if(error.data.status === 404){//未找到作品
                        $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                    }
                })
            } else {
                vm.page8 = true;
                Article.getByUser({id: $stateParams.aid}, function (res) {
                    vm.article = res;
                    if(vm.article.invalid){//作品失效
                        $state.go('articlesFailure',{storeId:vm.storeId});
                        return
                    }
                    getProduct()
                    angular.forEach(res.document.pages.page, function (page, i) {
                        if(!page.backup) {
                            angular.forEach(page.levels.level, function (level) {
                                if (level.imagebox && level.imagebox.geometry) {
                                    vm.counts++;
                                }
                            })
                        }
                    });
                    abook();
                },function (error) {
                    if(error.status === 403){//token失效
                        return $state.go('404',{storeId:vm.storeId});
                    }
                    if(error.data.status === 404){//未找到作品
                        $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                    }
                })
            }
        }

        function getProduct(callback) {
            Product.get({id: vm.article.productId, cancelled: false}, function (res) {
                vm.product = res.data;
                if(callback){callback()}
            })
        }

        $q.all([$scope.domains.$promise]).then(function () {
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            // token过期
            setTimeout(function fn() {
                Qinius.get(function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);

            //获取图片库
            loadAll('isNull');
            platformImg();
            vm.isIPhoneX = Iphone;
        });

        $scope.uploadPhoto = function () {
            uploadAble(StoreStorages,vm.storeId, function (res) {
                if(res.status == 200){
                    if($scope.agentIos){
                        $rootScope.$broadcast('clickAndroidUpload',true);
                    }else{
                        $timeout(function () {
                            $("#uploadImageMutipleId").trigger("click");
                        })
                    }
                }else{
                    if(res.status == 400){
                        threeInfo('系统出错');
                    } else {
                        threeInfo(res.data||'系统出错');
                    }
                }
            })
        }
        vm.uploadPhotoItem=[];
        $scope.$on('uoloadFinished', function(event, args) {
            if(args){
                angular.forEach(vm.photoAll,function (qin) {
                    if(qin.blobCover){
                        qin.blobCover = null;
                    }
                })
                // updatePic();
            }
        });
        // 上传图片后更新图片库/文件夹
        function updatePic() {
            vm.photoAll=[];
            vm.indexNum = 0;
            vm.photoCompleted = false;
            loadAll();
        }
        function loadAll() {
            var items = [];
            items.push({key: "folderId", op: "isNull", value: ''})
            items.push({key: "articleId", op: "=", value: vm.aid})
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            Gallery.storeByCondition({
                items: [],
                page: vm.indexNum,
                size: vm.itemsPerPage,
                sort: sorts()
            }, onSuccess, onError);
        }

        function sorts() {
            return ["id,desc"];
        }

        function onSuccess(res) {
            if (vm.maxId == null && res.data.length > 0) {
                vm.maxId = res.data[0].id;
            }
            for (var i = 0; i < res.data.length; i++) {
                if (!res.data[i].cancelled) {
                    res.data[i].identifier = $scope.domain + res.data[i].identifier;
                    vm.photoAll.push(res.data[i])
                }
            }
            if (res.data.length < vm.itemsPerPage) {
                vm.photoCompleted = true;
            }
        }

        function onError(data) {
            if(data.status !== 403){
                MessageService.hint($translate.instant("editorCommon.failedToObtainThePhotoGallery"));
            }

        }


        //获取dpi
        js_getDPI(function (dpi) {
            vm.windowDpi = dpi;
        });
        vm.pageData = null;
        vm.pageIndex = 0;
        $scope.template = null;
        function abook() {
            productXmlGet();
            if (vm.isEdit) {
                $scope.template = angular.copy(vm.article.document);
                $scope.template.pages.page=[];
                vm.article.document.pages.page.forEach(function (page) {
                    if(!page.backup){
                        var _page = page;
                        _page.seq = $scope.template.pages.page.length;
                        $scope.template.pages.page.push(_page);
                    }
                })
            } else {
                $scope.template = angular.copy($scope.pages);
                $scope.template.pages.page=[];
                $scope.pages.pages.page.forEach(function (page) {
                    if(!page.backup){
                        var _page = page;
                        _page.seq = $scope.template.pages.page.length;
                        $scope.template.pages.page.push(_page);
                    }
                })
            }
            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                ratioInfo(page)
            }, function () {
                vm.pageData = [];
                $timeout(function () {
                    vm.pageData = $scope.template.pages.page;
                    vm.pageSum = $scope.template.pages.page.length - 1;
                    vm.pageIndex = 0;
                    thumbnailInit();
                    vm.threeLoading = false;
                }, 1000)
            })
        }
        function productXmlGet(){
            ProductXml.get({id:vm.article.productXmlId}, function (productXml) {
                vm.productXmlData = productXml.data;
            })
        }
        vm.windowWidth = document.body.clientWidth;
        vm.windowHeight = document.body.clientHeight;
        vm.windowHeight1 = 350;
        function ratioInfo(item) {
            var ratio = item.custom.width / item.custom.height;
            var ratio1 = vm.windowWidth / vm.windowHeight1;
            if (ratio >= ratio1) {
                item.custom.ratio1 = vm.windowWidth / item.custom.width;
            } else {
                item.custom.ratio1 = vm.windowHeight1 / item.custom.height;
            }
            item.custom.thumbnailScale = 300 / item.custom.width;
        }

        $scope.previousPage = function () {
            thumbnailInit(function () {
                vm.pageIndex--;
                ratioInfo(vm.pageData[vm.pageIndex]);
            })
        }
        $scope.nextPage = function () {
            thumbnailInit(function () {
                vm.pageIndex++;
                ratioInfo(vm.pageData[vm.pageIndex]);
            })
        }
        $scope.goPageContent = function (index) {
            vm.isDocumentTextDetection=false;
            thumbnailInit(function () {
                vm.pageIndex = index;
                ratioInfo(vm.pageData[vm.pageIndex]);
            })
        }
        /*******************************************点击图片库*************************************************************************/
        $scope.imageboxCb = function(imagebox){
            vm.isGallery = true;
            vm.isPlatformPhoto = false;
            vm.imageboxItem = imagebox;
        };
        $scope.imageboxHide = function(){
            vm.isGallery = false;
            vm.isPlatformPhoto = false;
            vm.imageboxItem = null;
        };
        $scope.mapDepot = function(item){
            if(item){
                vm.isGallery = true;
                vm.isPlatformPhoto=false;
            }else{
                vm.isGallery = false;
                vm.isPlatformPhoto=true;
            }
        }
        vm.recommendPicIndex = 0;
        vm.recommendPicSize = 20;
        vm.recommendPicCompleted = false;
        vm.recommendPics=[];
        function platformImg(){
            RecommendPics.byCondition2({
                items: [],
                page: vm.recommendPicIndex,
                size: vm.recommendPicSize,
                sort: ["seq,asc"]
            }, function (res, headers) {
                var _sum = headers('X-Total-Count');
                for (var i = 0; i < res.data.length; i++) {
                    if (!res.data[i].cancelled) {
                        res.data[i].identifier = $scope.domain + res.data[i].identifier;
                        vm.recommendPics.push(res.data[i])
                    }
                }
                if(vm.recommendPics.length>= _sum){
                    vm.recommendPicCompleted = true;
                }
            }, function () {

            });
        }
        $('#photoGalleryId').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.photoCompleted){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.indexNum++
                    loadAll()
                }
            }
        });
        $('#platformPhotoId').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.recommendPicCompleted){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.recommendPicIndex++
                    platformImg()
                }
            }
        });


        $scope.addImageboxImg = function (item) {
            replaceImagebox(item)
        };
        function replaceImagebox(data) {
            var item = angular.copy(data);
            var _imagebox = vm.imageboxItem;
            _imagebox.image = {
                resource: {
                    identifier: item.identifier,
                    provider: 'qiniu'
                },
                resourceid:item.sourceId,
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                imageInfo:item.imageInfo,
                width: item.width,
                height: item.height,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0
            }
            addImagebox($http, _imagebox, function () {
                thumbnailInit()
                imageSelectInit();
                $scope.imageboxHide();
            });
        }

        function imageSelectInit() {
            vm.photoSelectAll = [];
            for (var i = 0; i < vm.pageData.length; i++) {
                for (var j = 0; j < vm.pageData[i].levels.level.length; j++) {
                    var _level = vm.pageData[i].levels.level[j];
                    if (_level.imagebox) {
                        for (var k = 0; k < vm.photoAll.length; k++) {
                            if (_level.imagebox.image.resourceId == vm.photoAll[k].sourceId) {
                                vm.photoSelectAll.push(vm.photoAll[k]);
                                break
                            }
                        }
                    }
                }
            }
        }
        //文字编辑
        $scope.textboxFocus = function(textbox){
            $timeout(function () {
                vm.textbox = textbox;
            })
        };
        $scope.textboxBlur = function(textbox){
            $timeout(function () {
                vm.textbox = null;
            })
        };
        $scope.textboxChange = function(textbox){
            if(textbox.text.length > textbox.maxLength){
                textbox.text = textbox.text.substring(0, textbox.maxLength)
            }
            sizeHeight()
        }

        function sizeHeight() {
            $timeout(function () {
                var _h = Number($("#c-xml-page-material-fontId1 span").height()) + Number(10);
                var _w = Number($("#c-xml-page-material-fontId1 span").width()) + Number(10);
                if(!writingmodeInit(vm.textbox.style.writingmode)){
                    vm.textbox.geometry.height = _h;
                }
                if(writingmodeInit(vm.textbox.style.writingmode)){
                    vm.textbox.geometry.width = _w;
                }
                thumbnailInit();
            })
        }
        //保存
        $scope.isSave = false;
        vm.isDocumentTextDetection = false;
        function articleOverdue(callback){
            Article.get({id: vm.article.id}, function (article) {
                if(article.data.fileName != vm.article.fileName){
                    MessageService.minConfirm({
                        cancel:$translate.instant("button.cancel"),
                        confirm:$translate.instant("button.refresh"),
                        msg:$translate.instant("hint.articleOverdue")
                    },function(){
                        $state.go("product-choosesThree-ec", {storeId: vm.storeId, aid:  vm.article.id},{reload:true});
                    })
                }else{
                    callback();
                }
            })
        }
        $scope.save = function () {
            articleOverdue(function () {
                vm.threeLoading = true;
                vm.threeLoadingText=$translate.instant("hint.loadingText");
                var _documentTextDetection = documentTextDetection1($scope.template.pages.page);
                if(!_documentTextDetection.pass){
                    vm.isDocumentTextDetection = true;
                    vm.documentTextDetection = _documentTextDetection.pages;
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    return;
                }
                if(!vm.isPageWarnExamine && pageWarnExamine($scope.template.pages.page, vm.imgSize, vm.imgDpi)){
                    SweetAlert.swal({
                        title: $translate.instant("editorCommon.hint"),
                        imageUrl: Theme.sweetHintImg(),
                        text: $translate.instant("editorCommon.lackPixelsMessage"),
                        showCancelButton: true,
                        confirmButtonText: $translate.instant("button.confirm"),
                        cancelButtonText: $translate.instant("button.cancel"),
                        closeOnConfirm: true,
                        closeOnCancel: true
                    }, function (isConfirm) {
                        if (isConfirm) {
                            vm.isPageWarnExamine = true;
                            $timeout(function () {
                                $scope.save();
                            },200)
                        }
                    })
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    return
                }
                vm.isPageWarnExamine = false;
                if ($scope.template.pages.page.length < $scope.PAGELEN) {
                    SweetAlert.swal({
                        title: $translate.instant("hint.loadingText"),
                        imageUrl: Theme.sweetHintImg(),
                        text: "",
                        timer: 2000,
                        showConfirmButton: false
                    });
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    return
                }

                if (vm.article.ordered) {
                    SweetAlert.swal({
                        title: $translate.instant("font.warmPrompt"),
                        imageUrl: Theme.sweetHintImg(),
                        text: $translate.instant("editorCommon.newWork"),
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                //判断是否有图片未上传  且是否继续保存
                if (!$scope.isContinue()) {
                    // vm.completed = false;
                    SweetAlert.swal({
                        title: $translate.instant("editorCommon.saveSure"),
                        imageUrl: Theme.sweetHintImg(),
                        showCancelButton: true,
                        confirmButtonText: $translate.instant("button.confirm"),
                        cancelButtonText: $translate.instant("button.cancel"),
                        closeOnConfirm: true,
                        closeOnCancel: true
                    }, function (isConfirm) {
                        if (isConfirm) {
                            $timeout(function () {
                                generateJsonFile();
                            }, 500);
                            return;
                        }else{
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                        }
                    })
                } else {
                    // vm.completed = true;
                    generateJsonFile();
                }
            })
        }
        $scope.save1 = generateJsonFile;
        vm.isPageWarnExamine = false;
        function generateJsonFile() {
            // vm.deleteList = 0;
            // var  gallieryIds = [];
            // for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
            //     var page = $scope.template.pages.page[i];
            //     if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
            //         continue;
            //     }
            //     for (var j = page.levels.level.length - 1; j >= 0; j--) {
            //         var level = page.levels.level[j];
            //         if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
            //             continue;
            //         }
            //         gallieryIds.push(level.imagebox.image.resourceid);
            //     }
            // }
            // if (gallieryIds.length>0) {
            //     Gallery.checkDeleted(gallieryIds, function(list){
            //         // vm.deleteList = [{id:gallieryIds[0]}];
            //         // vm.completed = false;
            //         // vm.isDestroyed = true;
            //         if (list == null|| list.length ==0) {
            //             generateJsonFile1()
            //         }else{
            //             vm.deleteList = list;
            //             vm.completed = false;
            //             vm.isDestroyed = true;
            //             return;
            //         }
            //     }, function(){
            //         MessageService.error("检测图片失败，请稍后再试。");
            //     })
            // }else{
                generateJsonFile1()
            // }
        }

        $scope.removeGallery = function(){
            if (vm.deleteList == null || vm.deleteList.length == 0) {
                return;
            }
            for (var z = vm.deleteList.length - 1; z >= 0; z--) {
                var g = vm.deleteList[z];
                for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                    var page = $scope.template.pages.page[i];
                    if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                        continue;
                    }
                    for (var j = page.levels.level.length - 1; j >= 0; j--) {
                        var level = page.levels.level[j];
                        if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                            continue;
                        }
                        if (level.imagebox.image.resourceid == g.sourceId) {
                            level.imagebox.image.resource.identifier = "";
                            level.imagebox.image.resource.adaptation = "";
                            level.imagebox.image.resource.provider = "";
                            level.imagebox.image.resource.offsetx = 0;
                            level.imagebox.image.resource.offsety = 0;
                            level.imagebox.image.resource.height = "";
                            level.imagebox.image.resource.width = "";
                            level.imagebox.image.resource.scale = 1;
                            level.imagebox.image.resource.imgSize = "";
                            level.imagebox.image.resourceid = null;
                            level.imagebox.image.imageInfo = null;
                            break;
                        }
                    }
                }
            }
            imageSelectInit();
        }

        $scope.galleryDestroyConfirm = function(){
            $scope.removeGallery();
            generateJsonFile1();
        };

        function generateJsonFile1(){
            if ($scope.isSave) {
                return;
            }
            $scope.isSave = true;
            thumbnailInit(function (){
                vm.threeLoading = true;
                vm.threeLoadingText=$translate.instant("hint.loadingText");
                var template = saveData();
                template.hostinfo = hostInfo();
                template.replace= vm.replace;
                Convert.articleUploadDocument(template, function (res) {
                    vm.article.articleStatus = vm.completed;
                    vm.article.fileName = res.data.identifier;
                    vm.article.id = vm.aid;
                    vm.article.pageCount = $scope.template.pages.page.length;
                    Article.update(vm.article, function (resp) {
                        $scope.isSave = true;
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                        $scope.isSave = false;
                        swal({
                            title: $translate.instant("editorCommon.modifySuccess"),
                            text: $translate.instant("editorCommon.synchronizationOfWorks"),
                            imageUrl: Theme.sweetSuccedImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                        $state.go("product-view5-ec", {storeId: vm.storeId, aid: resp.data.id}, {reload: true});
                    }, function () {
                        $scope.isSave = false;
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                        swal({
                            title: $translate.instant("editorCommon.saveError"),
                            text: $translate.instant("editorCommon.saveError1"),
                            imageUrl: Theme.sweetHintImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                    })
                }, function () {
                    $scope.isSave = false;
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    swal({
                        title: $translate.instant("editorCommon.saveError"),
                        text: $translate.instant("editorCommon.saveError1"),
                        imageUrl: Theme.sweetHintImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                })
                localStorage.removeItem("photoSelectAll");
            })
        }

        $scope.isContinue = function () {
            var hasEmpty = true;
            vm.unFilledCount = 0;
            for (var i = 0; i < $scope.template.pages.page.length; i++) {
                var _page = $scope.template.pages.page[i];
                if (_page.levels.level) {
                    for (var j = 0; j < _page.levels.level.length; j++) {
                        var _level = _page.levels.level[j];
                        if (_level.imagebox && _level.imagebox.image) {
                            if (_level.imagebox.image.resource && _level.imagebox.image.resource.identifier) {
                            } else {
                                hasEmpty = false;
                                vm.unFilledCount++;
                            }
                        }
                    }
                }
            }
            vm.completed = hasEmpty ? 'COMPLETED' : 'EDITING';
            return hasEmpty;
        };

        function saveData() {
            return documentMM($scope.template, [], null);
        }
        //生成缩略图
        vm.isHtml2canvas = false;
        vm.isThreeView3d1 = false;
        vm.isThreeView3d = true;
        vm.threeView3dW=110;
        vm.threeView3dH=110;
        vm.pageData1 = [];
        vm.threeView3d={
            top: 50,
            right: 5
        }

        $scope.threeView3d1Cb = function () {
            vm.isThreeView3d1=true;
            vm.threeView3dW=$(window).width();
            vm.threeView3dH=400;
        }
        $scope.threeView3d1CbHide = function () {
            vm.isThreeView3d1=false;
            vm.threeView3dW=110;
            vm.threeView3dH=110;
        };
        var a = null;
        function thumbnailInit(callback) {
            if(vm.productXmlData && vm.productXmlData.modelType === 'THREE'){
                if (a !== null) {
                    clearTimeout(a)
                }
                a = setTimeout(function() {
                    vm.thumbnailPageData = angular.copy(vm.pageData[vm.pageNumber]);
                    pageTrimbox(vm.thumbnailPageData)
                    $timeout(function () {
                        html2canvas_adapt(document.getElementById('thumbnail-view'), {
                            onrendered: function (canvas) {
                                if(canvas){
                                    var url = canvas.toDataURL("image/png");
                                    var blob = dataURLtoBlob(url);
                                    var formData = new FormData();
                                    formData.append('file', blob);
                                    formData.append('key', '3dThumbnail-' + UidService.get());
                                    formData.append('token', vm.token);
                                    $.ajax({
                                        cache: true,
                                        type: "POST",
                                        url: 'https://upload.qiniup.com/',
                                        data: formData,
                                        async: false,
                                        contentType: false,
                                        processData: false,
                                        success: function (data) {
                                            vm.pageData[vm.pageIndex].pagecover = {
                                                identifier: $scope.domain + data.key,
                                                provider: 'qiniu'
                                            }
                                            if(vm.pageIndex == 0){
                                                vm.article.covered=true;
                                                vm.article.cover=data.key;
                                            }
                                            vm.isHtml2canvas = true;
                                            vm.pageData1 = angular.copy(vm.pageData);
                                            if(callback){callback()};
                                        },
                                        error: function () {
                                            vm.article.covered=false;
                                            vm.article.cover=null;
                                            if (callback) {callback()};
                                        }
                                    })
                                }else{
                                    vm.article.covered=false;
                                    vm.article.cover=null;
                                    if (callback) {callback()};
                                }
                            },
                            letterRendering: true,
                            useCORS: true,
                            width: vm.pageData[vm.pageIndex].custom.width*vm.pageData[vm.pageIndex].custom.thumbnailScale,
                            height: vm.pageData[vm.pageIndex].custom.height*vm.pageData[vm.pageIndex].custom.thumbnailScale,
                            windowWidth: document.body.scrollWidth,
                            windowHeight: document.body.scrollHeight,
                            x: 0,
                            y: 0,
                            backgroundColor: "rgba(0, 0, 0, 0)"
                        });
                    },1000)
                },1000)
            }else{
                if(callback){callback()}
            }
        }

        window.threeView3dStart = function (e) {
            vm.threeView3d1 = angular.copy(vm.threeView3d);
            vm.threeView3d1.x = e.changedTouches[0].clientX != null ? e.changedTouches[0].clientX: 0;          //鼠标到页面左边的距离
            vm.threeView3d1.y = e.changedTouches[0].clientY != null ? e.changedTouches[0].clientY: 0;           //鼠标到页面顶部的距离
            $scope.$digest();
        };
        window.threeView3dOn = function (e) {
            $timeout(function () {
                var nx = e.changedTouches[0].clientX != null ? e.changedTouches[0].clientX: 0;
                var ny = e.changedTouches[0].clientY != null ? e.changedTouches[0].clientY: 0;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - vm.threeView3d1.x;
                var nt = ny - vm.threeView3d1.y;
                vm.threeView3d.right = vm.threeView3d1.right - nl;
                vm.threeView3d.top = vm.threeView3d1.top + nt;
            })
        }
        //部件名
        $scope.partName = function (partid) {
            var partName = "";
            for(var i=0; i<$scope.template.config.parts.list.length; i++){
                if($scope.template.config.parts.list[i].id == partid){
                    partName = $scope.template.config.parts.list[i].name;
                }
            }
            return partName
        }

        // 调出手机键盘导致输入框不可见问题修复
        var element = document.getElementById('pageHeight');
        var elementHeight = element.clientHeight; // 获取元素的客户端高度
        vm.textFouched = false;
        // 监测窗口大小变化
        window.addEventListener('resize', function(event) {
            $timeout(function(){
                if (element.clientHeight < elementHeight) {
                    vm.textFouched = true;
                }else{
                    vm.textFouched = false;
                }
            })
        });

        // websocket接收上传的图片（多端互通实时更新）
        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.aid);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:'+ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                console.log(photo);
                if (photo.folderId == null) {
                    photo.identifier = $scope.domain + photo.identifier;
                    vm.photoAll.unshift(photo)
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }
    }
})();
