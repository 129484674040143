window.angular.module('modules.service', []).factory('ModulesService',
    ['FontManagement', 'MessageService', '$timeout', '$rootScope', '$state', 'ToneSettings', '$uibModal', '$stateParams','$translate', '$translatePartialLoader',
    function (FontManagement, MessageService, $timeout, $rootScope, $state, ToneSettings, $uibModal, $stateParams,$translate, $translatePartialLoader) {

        var vm = this;
        this.storeId = $stateParams.storeId;
        this.pid = $stateParams.pid;
        console.log($stateParams)

        this.sorts = function (predicate, reverse) {
            // return ["createdDate,desc"];
            return [predicate + ',' + (reverse ? 'asc' : 'desc')];
        }

        this.fn = function (e) {
            // e = e || window.event;
            // e.cancelBubble = true;
            // e.stopPropagation();
            // e.returnValue = false;
            // e.preventDefault();
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

        // 得到浏览器dpi
        this.getDPI = function () {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            } else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            // vm.windowDpiW = 300;
            // vm.windowDpiH = 300;
            return arrDPI;
        }

        // 判断是哪个浏览器
        this.myBrowser = function () {
            var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
            var isOpera = userAgent.indexOf("Opera") > -1;
            if (isOpera) {
                return "Opera"
            }
            ; //判断是否Opera浏览器
            if (userAgent.indexOf("Firefox") > -1) {
                return "FF";
            } //判断是否Firefox浏览器
            if (userAgent.indexOf("Chrome") > -1) {
                return "Chrome";
            }
            if (userAgent.indexOf("Safari") > -1) {
                return "Safari";
            } //判断是否Safari浏览器
            if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {
                return "IE";
            }
            ; //判断是否IE浏览器
        }

        // 进度条
        this.rangeValue = function (elem) {
            return elem.value
        }

        // mm换算成px
        this.unitTransform = function (mm) {
            // mm换算成px  Math.ceil(mm / 25.4 * 300);
            // return (mm * 3) - 0;
            var dpi = this.getDPI();
            return mm / 25.4 * dpi[0];
        }

        // px换算成mm
        this.unitTransformPx = function (px) {
            // px换算成mm  Math.floor(px / 300 * 25.4)
            // return (px * 1 / 3).toFixed(2) - 0;
            var dpi = this.getDPI();
            return px / dpi[0] * 25.4;
        }

        this.getGeometryToPx = function (obj) {
            return {
                height: this.unitTransform(obj.height),
                width: this.unitTransform(obj.width),
                x: this.unitTransform(obj.x),
                y: this.unitTransform(obj.y)
            }
        }

        // px---mm
        this.threeHundredDpiToMm = function (attr1, attr2) {
            var dpi = this.getDPI();
            var mm1 = parseFloat((attr1 / dpi[0] * 25.4 - 0)).toFixed(2) - 0;
            var mm2 = parseFloat((attr2 / dpi[0] * 25.4 - 0)).toFixed(2) - 0;
            return {
                mm1: mm1,
                mm2: mm2
            }
        }

        // 获取图片dpi
        this.getPicDpi = function (box) {
            var boxW = 0, boxH = 0, picW = 0, picH = 0, scale = 0;
            boxW = this.unitTransformPx(box.geometry.width);
            boxH = this.unitTransformPx(box.geometry.height);
            picW = box.image.width;
            picH = box.image.height;
            scale = box.image.scale;
            var x = 0, y = 0;
            // 宽度适配
            if (box.adaptation == 'Width') {
                x = picW / (boxW / 25.4) / scale;
                y = boxH * picW / boxW / (boxH / 25.4) / scale;
            } else {
                x = boxW * picH / boxH / (boxW / 25.4) / scale;
                y = picH / (boxH / 25.4) / scale;
            }
            if (x < 150 || y < 150) {
                return true
            } else {
                return false
            }
        }

        // 获取文字样式
        this.getFontCSS = function (item, attr) {
            item.loading = true;
            FontManagement.getCss({uuid: item.fontuuid}, function (res) {
                if (res.status == 200) {
                    var style = document.createElement("style");
                    style.type = 'text/css';
                    try {
                        style.appendChild(document.createTextNode(res.data));
                    } catch (ex) {
                        style.stylesheet.cssText = res.data;
                    }
                    var Head = document.getElementsByTagName("head")[0];
                    Head.appendChild(style);
                    document.fonts.ready.then(function () {
                        item.loading = false;
                        if (attr) {
                            // console.log($rootScope)
                            // updateHistorys();
                        }
                        $rootScope.$apply();
                    });
                } else {
                    MessageService.error(res.message);
                }
            });
        }

        // 获取logo，颜色
        this.getToneSettings = function (storeId, callback) {
            ToneSettings.byStoreId({id: storeId}, function (res) {
                if (res.status == 200) {
                    if (res.data) {
                        for (var key in  res.data) {
                            if (res.data[key]) {
                                document.body.style.setProperty('--' + key, res.data[key]);
                            }
                        }
                        callback.call(this, res.data);
                    }
                } else {
                    MessageService.error(res.message)
                }
            }, function (error) {
            });
        }

        // 全屏
        this.toggleFullScreen = function (attr) {
            if (!attr) {
                //进入全屏
                var de = document.documentElement;
                if (de.requestFullscreen) {
                    de.requestFullscreen();
                } else if (de.mozRequestFullScreen) {
                    de.mozRequestFullScreen();
                } else if (de.webkitRequestFullScreen) {
                    de.webkitRequestFullScreen();
                }
                return true
            } else {
                //退出全屏
                var de = document;
                if (de.exitFullscreen) {
                    de.exitFullscreen();
                } else if (de.mozCancelFullScreen) {
                    de.mozCancelFullScreen();
                } else if (de.webkitCancelFullScreen) {
                    de.webkitCancelFullScreen();
                }
                return false
            }
        }

        // 鼠标放大缩小
        this.bbimg = function (delta, box) {
            if (box.bokehtype != 'None') {
                return
            }

            if (delta < 0 || delta == undefined && event.wheelDelta > 0) {
                box.image.scale *= 1.01;
            } else {
                box.image.scale /= 1.01;
                if (box.image.scale <= 1) {
                    box.image.scale = 1;
                }
            }
            var x = box.image.offsetx * box.image.scale;
            var y = box.image.offsety * box.image.scale;

            //向上滑动;
            if (-y >= (box.image.height * box.image.scale - box.geometry.height)) {
                y = -(box.image.height * box.image.scale - box.geometry.height);
            }
            //向左滑动;        <-----
            if (-x >= (box.image.width * box.image.scale - box.geometry.width)) {
                x = -(box.image.width * box.image.scale - box.geometry.width);
            }
            //向下滑动;
            if (y >= 0) {
                y = 0;
            }
            // 向右滑动;    ---->
            if (x >= 0) {
                x = 0;
            }
            box.image.offsetx = x / box.image.scale;
            box.image.offsety = y / box.image.scale;

            return box;
        }

        // 获取文字style
        this.setFontStyle = function (fontList, uuid, text) {
            var fontStyle = [];
            for (var i = 0; i < fontList.length; i++) {
                if (fontList[i].uuid == uuid) {
                    for (var j = 0; j < fontList[i].fontStyles.length; j++) {
                        fontStyle.push(fontList[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold = false;
            text.style.italic = false;
            if (fontStyle.indexOf('NORMAL') != '-1') {
                text.style.bold = false;
                text.style.italic = false;
            } else {
                if (fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    text.style.bold = true;
                    text.style.italic = true;
                } else {
                    if (fontStyle.indexOf('ITALIC') != '-1') {
                        text.style.italic = true;
                    }
                    if (fontStyle.indexOf('BOLD') != '-1') {
                        text.style.bold = true;
                    }
                }
            }

            return {
                fontStyle: fontStyle,
                style: text.style
            }
        }

        // 预览
        this.pagePreview = function (temp,isShowbotton) {
            $uibModal.open({
                templateUrl: 'app/entities/commodity/commodity-preview.html',
                controller: 'CommodityPreviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function () {
                        var _obj = {
                            storeId: this.storeId,
                            pid: this.pid,
                            value: temp,
                            type: 'temp',
                            isShowbotton: isShowbotton
                        }
                        return _obj;
                    }],
                    translatePartialLoader: [function () {
                        $translatePartialLoader.addPart('commodityPage');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                }
            }).result.then(function (backData) {
                console.log(backData);
            });
        }

        this.setPageHistory = function () {
            $(".save-page").addClass('hide1');
            $(".used-pic-mask").show();
            var mb = vm.myBrowser();
            if (mb == 'FF') {
                $(".leftPanel-con li").addClass('mar-left-6');
            }
        }

        // 获取占位框宽高
        this.imgScale = function (imagebox, x, y, attr) {
            var img = imagebox.image.resource.identifier + '?imageMogr2/strip/thumbnail/!40p';
            if (imagebox.image.angle != 0) {
                img += '/rotate/' + imagebox.image.angle
            }
            if (!imagebox.image.width && !imagebox.image.height) {
            }

            imagebox.image.orignIdentifier = angular.copy(imagebox.image.resource.identifier);
            var width = imagebox.image.width;
            var height = imagebox.image.height;
            var wOrigin = angular.copy(width);
            var hOrigin = angular.copy(height);
            // 单页编辑占位框宽高
            var maskWidth = imagebox.geometry.width;
            var maskHight = imagebox.geometry.height;
            if ((wOrigin <= hOrigin || maskWidth <= maskHight) && (wOrigin / hOrigin < maskWidth / maskHight) || wOrigin >= hOrigin && maskWidth >= maskHight && (wOrigin / hOrigin < maskWidth / maskHight)) {
                imagebox.adaptation = 'Width';
                imagebox.image.width = imagebox.geometry.width;
                imagebox.image.height = imagebox.geometry.width * height / width;
                imagebox.image.offsetx = x;
                imagebox.image.offsety = (imagebox.geometry.height - imagebox.geometry.width * height / width) / 2;
                imagebox.blurredrectangle = {
                    width: imagebox.geometry.height * width / height,
                    height: imagebox.geometry.height,
                    x: (imagebox.geometry.width - imagebox.geometry.height * width / height) / 2,
                    y: 0,
                }
            } else {
                imagebox.adaptation = 'Height';
                imagebox.image.width = imagebox.geometry.height * width / height;
                imagebox.image.height = imagebox.geometry.height;
                imagebox.image.offsetx = (imagebox.geometry.width - imagebox.geometry.height * width / height) / 2;
                imagebox.image.offsety = y;
                imagebox.blurredrectangle = {
                    width: imagebox.geometry.width,
                    height: imagebox.geometry.width * height / width,
                    x: 0,
                    y: (imagebox.geometry.height - imagebox.geometry.width * height / width) / 2,
                }
            }
            if (imagebox.bokehtype != 'None') {
                imagebox.image.offsetx = 0;
                imagebox.image.offsety = 0;
                imagebox.image.scale = 1;
            }
            imagebox.image.lowSize = this.getPicDpi(imagebox);

            // f++;
            // if (f == vm.counts) {
            //     vm.setPageHistory();
            // }
            $timeout(function () {
                imagebox.loading = false;
                // 拖拽进占位框时记录下历史纪录
                if (attr) {
                    // updateHistorys()
                }
            }, 400)
            // return
        }

        // 拖拽占位框
        var isZoomDown = false;
        this.imageboxZoom = function ($event, item, direction, pro, index, slide, $scope) {
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
            var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
            var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
            var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
            var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
            var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;
            var _scale = item.image ? angular.copy(item.image.scale) : 1;
            var maxX = $($event.target).parents('.slide-content').width();
            var maxY = $($event.target).parents('.slide-content').height();

            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;

                if (direction == 'bottom' || direction == 'left') {
                    var _w = (Number(nw) - Number(nl));
                }
                if (direction == 'top' || direction == 'right') {
                    var _w = (Number(nw) + Number(nl));
                }
                var _h = _w / nw * nh;
                // if(_w > ($scope.currentPage.width+$scope.wahBorder/$scope.currentPage.proportion)*$scope.currentPage.scale){
                //     _w = ($scope.currentPage.width+$scope.wahBorder/$scope.currentPage.proportion)*$scope.currentPage.scale;
                //     _h = _w / nw * nh;
                // }
                // if(_h > ($scope.currentPage.height+$scope.xayBorder/$scope.currentPage.proportion)*$scope.currentPage.scale){
                //     _h = ($scope.currentPage.height+$scope.xayBorder/$scope.currentPage.proportion)*$scope.currentPage.scale;
                //     _w = _h / nh * nw;
                // }
                // if (_w > maxX) {
                //     _w = maxX;
                //     _h = _w / nw * nh;
                // }
                // if (_h > maxY) {
                //     _h = maxY;
                //     _w = _h / nh * nw;
                // }
                item.geometry.width = _w / pro;
                item.geometry.height = _h / pro;
                if (item.image && item.image.resource && item.image.resource.identifier) {
                    var _offsetx = (_w / (nw / nw1)) / (nw1 / x1) / pro;
                    var _offsety = (_h / (nh / nh1)) / (nh1 / y1) / pro;
                    vm.imgScale(item, _offsetx, _offsety, false);
                }
                if (direction == 'left') {
                    item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                    item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                    // if (item.geometry.x < 0) {
                    //     item.geometry.x = 0;
                    // }
                    // if (item.geometry.y < 0) {
                    //     item.geometry.y = 0;
                    // }
                }
                if (direction == 'top') {
                    // if (item.geometry.width * pro > maxX - item.geometry.x * pro) {
                    //     item.geometry.width = (maxX - item.geometry.x * pro) / pro;
                    // }
                    item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                    // if (item.geometry.y < 0) {
                    //     item.geometry.y = 0;
                    // }
                }
                if (direction == 'right') {
                    // if (item.geometry.width * pro > maxX - item.geometry.x * pro) {
                    //     item.geometry.width = (maxX - item.geometry.x * pro) / pro;
                    // }
                    // if (Number(item.geometry.height * pro) + Number(item.geometry.y * pro) > maxY) {
                    //     item.geometry.height = (maxY - Number(item.geometry.y * pro)) / pro;
                    //     item.geometry.width = item.geometry.height / nh * nw;
                    //     return;
                    // }
                }
                if (direction == 'bottom') {
                    // if (Number(item.geometry.height * pro) + Number(item.geometry.y * pro) > maxY) {
                    //     item.geometry.height = (maxY - Number(item.geometry.y * pro)) / pro;
                    //     item.geometry.width = item.geometry.height / nh * nw;
                    //     return;
                    // }
                    item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                    // if (item.geometry.x < 0) {
                    //     item.geometry.x = 0;
                    // }
                }
                // $scope.template.levelI = index;
                // $scope.currentPage.levels.level[$scope.template.levelI].imagebox = item;
                $rootScope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                if(item.image && item.image.resource && item.image.resource.identifier){
                    getBackgroundWebSource3($scope,item,item.geometry.width,item.geometry.height,item.image, item.bokehtype);
                }
                $event.stopPropagation();
                isZoomDown = false;
            }
        }
        this.imageboxZoom1 = function ($event, item, direction, pro, index, slide, $scope) {
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
            var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
            var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
            var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
            var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
            var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;
            var _scale = item.image ? angular.copy(item.image.scale) : 1;
            var maxX = $($event.target).parents('.slide-content').width();
            var maxY = $($event.target).parents('.slide-content').height();
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;
                var nt = ny - y;

                if (direction == 'left') {
                    var _w = (Number(nw) - Number(nl));
                }
                if (direction == 'right') {
                    var _w = (Number(nw) + Number(nl));
                }
                if (direction == 'top') {
                    var _h = (Number(nh) - Number(nt));
                }
                if (direction == 'bottom') {
                    var _h = (Number(nh) + Number(nt));
                }
                // if(_w > ($scope.currentPage.width+$scope.wahBorder/$scope.currentPage.proportion)*$scope.currentPage.scale){
                //     _w = ($scope.currentPage.width+$scope.wahBorder/$scope.currentPage.proportion)*$scope.currentPage.scale;
                // }
                // if(_h > ($scope.currentPage.height+$scope.xayBorder/$scope.currentPage.proportion)*$scope.currentPage.scale){
                //     _h = ($scope.currentPage.height+$scope.xayBorder/$scope.currentPage.proportion)*$scope.currentPage.scale;
                // }


                if (item.image && item.image.resource && item.image.resource.identifier) {
                    item.image.angle = 0;
                    vm.imgScale(item, 0, 0, false);
                }
                if (direction == 'left') {
                    item.geometry.x = (Number(x2) - Number(_w - nw)) / pro;
                    item.geometry.width = _w / pro;
                    // if (item.geometry.x < 0) {
                    //     item.geometry.x = 0;
                    // }
                }
                if (direction == 'top') {
                    item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                    item.geometry.height = _h / pro;
                    // if (item.geometry.y < 0) {
                    //     item.geometry.y = 0;
                    // }
                }
                if (direction == 'right') {
                    item.geometry.width = _w / pro;
                }
                if (direction == 'bottom') {
                    item.geometry.height = _h / pro;
                }
                // if (item.geometry.width * pro > maxX - item.geometry.x * pro) {
                //     item.geometry.width = (maxX - item.geometry.x * pro) / pro;
                // }
                // if (item.geometry.height * pro > maxY - item.geometry.y * pro) {
                //     item.geometry.height = (maxY - item.geometry.y * pro) / pro;
                // }
                // $scope.template.levelI = index || $scope.template.levelI;
                // $scope.currentPage.levels.level[$scope.template.levelI].imagebox = item;
                $rootScope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                if(item.image && item.image.resource && item.image.resource.identifier) {
                    getBackgroundWebSource3($scope, item, item.geometry.width, item.geometry.height, item.image, item.bokehtype)
                }
                $event.stopPropagation();
                isZoomDown = false;
            }
        }

        // 旋转角度
        this.getAngle = function (px, py, mx, my) {//获得人物中心和鼠标坐标连线，与y轴正半轴之间的夹角
            var x = Math.abs(px - mx);
            var y = Math.abs(py - my);
            var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
            var cos = y / z;
            var radina = Math.acos(cos);//用反三角函数求弧度
            var angle = Math.floor(180 / (Math.PI / radina));//将弧度转换成角度
            if (mx < px && my > py) {//鼠标在第三象限
                angle = angle;
            }
            if (mx < px && my < py) {
                angle = 180 - angle;
            }
            if (mx > px && my < py) {
                angle = 180 + angle;
            }
            if (mx > px && my > py) {
                angle = 360 - angle;
            }
            return angle;

        }

        this.keycodeArrow = function (keyCode, currentPage, level, selectBox) {
            // var box = [];
            // if (selectBox.current == 'imagebox') {
            //     box = level.imagebox;
            // }
            // if (selectBox.current == 'textbox') {
            //     box = level.textbox;
            // }
            // if (selectBox.current == 'imageart') {
            //     box = level.imageart;
            // }
            // if (selectBox.current == 'textdocument') {
            //     box = level.textdocument;
            // }
            // if (box.lock || box.movable==false) {
            //     return
            // }

            if(selectBox.lock  || selectBox.movable==false){
                return
            }
            // 上
            if (keyCode == 38 && selectBox.geometry.y > 0) {
                selectBox.geometry.y--;
            }
            // 下
            if (keyCode == 40 && selectBox.geometry.y < currentPage.mediabox.height - selectBox.geometry.height) {
                selectBox.geometry.y++;

            }
            // 左
            if (keyCode == 37 && selectBox.geometry.x > 0) {
                selectBox.geometry.x--;
            }
            // 右
            if (keyCode == 39 && selectBox.geometry.x < currentPage.mediabox.width - selectBox.geometry.width) {
                selectBox.geometry.x++;
            }
            $rootScope.$apply();
        }

        this.doubleClickClipart = function (e,item,ratio) {
            var imageartTrim = angular.copy(item);
            imageartTrim.geometry.width1 = angular.copy(item.geometry.width);
            imageartTrim.geometry.height1 = angular.copy(item.geometry.height);
            imageartTrim.geometry.x1 = angular.copy(item.geometry.x);
            imageartTrim.geometry.y1 = angular.copy(item.geometry.y);
            if(imageartTrim.geometry.width * ratio == imageartTrim.geometry.width1 * ratio * imageartTrim.scale){
                imageartTrim.geometry.width = imageartTrim.geometry.width * 0.8;
                imageartTrim.geometry.height = imageartTrim.geometry.height * 0.8;
                imageartTrim.geometry.x = Number(imageartTrim.geometry.x) + Number(imageartTrim.geometry.width / 0.8 * 0.2 / 2);
                imageartTrim.geometry.y = Number(imageartTrim.geometry.y) + Number(imageartTrim.geometry.height / 0.8 * 0.2 / 2);
            }
            return imageartTrim;
        }

        this.generQRCode = function (ele,code) {
            var qrcode = new QRCode(ele, {
                width: code.geometry.width,
                height: code.geometry.height
            });
            qrcode.makeCode(code.value);
        }

        this.splitFileName = function (text) {
            var pattern = /\.{1}[a-z]{1,}$/;
            if (pattern.exec(text) !== null) {
                return (text.slice(0, pattern.exec(text).index));
            } else {
                return text;
            }
        }

        this.setLayoutQuantity = function (res) {
            var arr = [],map = {}

            for (var i = 0; i < res.length; i++) {
                var ai = res[i];
                var quantity = ai.quantity;
                if(ai.quantity>8){
                    quantity = 9;
                }
                if (!map[quantity]) {
                    map[quantity] = [ai]
                } else {
                    map[quantity].push(ai)
                }
            }

            Object.keys(map).forEach(function (key) {
                var name = key + "张照片";
                if(key==9){
                    name = key + "张及以上";
                }
                arr.push({
                    name: name,
                    quantity: Number(key),
                    data: map[key],
                })
            })
            return arr.sort(this.compare('quantity'))
        }

        this.compare = function (property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }

        this.getPageImageboxNums = function (page) {
            var num = 0,arr=[];
            angular.forEach(page.levels.level,function (level) {
                if(level.imagebox&&level.imagebox.image&&level.imagebox.image.resource&&level.imagebox.image.resource.identifier){
                    // var image = level.imagebox.image;
                    // if(!image||image&&(!image.resource||image.resource&&image.resource.identifier)){
                        num++;
                    arr.push(level.imagebox)
                    // }
                }
            })
            return {
                num:num,
                imagebox:arr
            };
        }

        this.arrayAccumulate =function (values,attr) {
            var sum = values.reduce(function(prev, cur, index, array){
                // debugger
                return prev + cur;
            });//求数组中所有值的和
            return sum
        }

        this.minCoinChange = function (coins, amount, pageNums) {
            var result = null;
            if (!amount) return result;

            var makeChange = function(index, value, min) {
                if(index<0) return
                var coin = coins[index];
                var newAmount = Math.floor(value / coin);
                if (newAmount) min[coin] = newAmount;
                if (value % coin !== 0) {
                    makeChange(--index, value - coin * newAmount, min);
                }
            };

            var arr = [];
            for (var i = 0; i < coins.length; i++) {
                var cache = {};
                makeChange(i, amount, cache);
                arr.push(cache);
            }

            console.log(arr);
            var minArr = [];
            arr.forEach(function (item) {
                var min = 0;
                for (var v in item) min += item[v];
                console.log("min: "+min)
                if(min == pageNums){
                    minArr.push(item);
                    result = item;
                }
            });

            console.log(minArr)
            var index = Math.floor((Math.random()*minArr.length));
            console.log(minArr[index])
            console.log("-----------------")

            if(minArr[index]){
                return minArr[index]
            }else{
                return result;
            }

        }

        return this;

    }]);
