(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductViewEc3Controller', ProductViewEc3Controller);

    ProductViewEc3Controller.$inject = ['$scope', '$stateParams', 'entity', '$q', '$localStorage', 'Qinius',
        '$rootScope', 'Theme', 'ItemValue', 'PlatformWechatSetting', 'Store','MessageNotification'];
    function ProductViewEc3Controller($scope, $stateParams, entity, $q, $localStorage, Qinius,
                                    $rootScope, Theme, ItemValue, PlatformWechatSetting , Store, MessageNotification) {
        var vm = this;

        //风格切换  引入不同的css文件
        Theme.judge();
        vm.platformSetting = PlatformWechatSetting.get();
        var audio = document.getElementById("audio");
        // audio.src = "content/ihope.mp3";
        audio.crossOrigin = 'anonymous';

        var timer = null;
        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        vm.aid = $stateParams.aid;
        vm.article = entity.data;
        vm.store = Store.get({id: vm.storeId});
        vm.bindingtype = 'Left';
        vm.counts = 0;
        $scope.k = 0;
        $scope.t = 0;
        $scope.dHeight = vm.dHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        $scope.dWidth = vm.dWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth,'background':'#fff'});

        var $li = $(".templateView2-carousel");
        $li.hide();
        $scope.domains = Qinius.getDomain({storeId: vm.storeId});

        function Play() {
            audio.play().then(function () {
                $(".PanelRight").removeClass('hide1');
                timer = window.setInterval(begin, 10);
            }).catch(function (error) {
                console.log(error);
            });
        }

        var rot = 10 - 0;
        function begin() {
            var micon = document.getElementById("music-icon");
            if (micon == null) {
                window.clearInterval(timer);
                return;
            }
            micon.style.transform = "rotate(" + rot + "deg)";
            rot += 1;
        }

        $("#Play").on('click', function () {
            window.clearInterval(timer);
            if (audio.paused) {
                if ($(this).children().hasClass('play')) {
                    $("#Play").children("img").removeClass("play").addClass("pause");
                    Play();
                }
            }
            else {
                $("#Play").children("img").removeClass("pause").addClass("play");
                audio.pause();
            }
        });

        var monthStr = new Date().getMonth() + 1;
        var dayStr = new Date().getDate();
        var timestr = new Date().getFullYear() + "-" + (monthStr > 9 ? monthStr : "0" + monthStr) + "-" + (dayStr > 9 ? dayStr : "0" + dayStr);
        MessageNotification.getAllByCondition2({
            items: [{key: "available", op: "=", value: "true"}, {
                key: "validate",
                op: ">=",
                value: timestr
            }, {key: "pageType", op: "=", value: "View"}]
        },function (res) {
            if(res.status == 200){
                res.data.sort(compare('seq'))
                vm.message = angular.copy(res.data);
                vm.messageText = "\xa0\xa0\xa0\xa0\xa0\xa0";
                angular.forEach(res.data,function (resp) {
                    console.log(resp);
                    vm.messageText += resp.content+"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                })
            }else {
                MessageService.error(res.message);
            }
        });

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1-value2;
            }
        }

        getAll();
        function getAll() {
            $q.all([$scope.domains.$promise, vm.article.$promise, vm.platformSetting.$promise, vm.store.$promise]).then(function () {
                vm.article = vm.article.data;
                if(vm.article.invalid){//作品失效
                    $state.go('articlesFailure',{storeId:vm.storeId});
                    return
                }
                vm.platformSetting = vm.platformSetting.data;
                vm.store = vm.store.data;
                $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                ItemValue.get({id: vm.article.itemValueId}, function (res) {
                    if(res.status == 200){
                        vm.bindingtype = res.data.bindingType;
                        if (vm.article.musicIdentify) {
                            audio.src = $scope.domain + vm.article.musicIdentify;
                        }else{
                            if (res.data.musicIdentify) {
                                audio.src = $scope.domain + res.data.musicIdentify;
                            } else {
                                audio.src = '';
                            }
                        }
                    }else {
                        MessageService.error(res.message);
                    }
                });
                // 自动播放音乐
                Play();
            });
        }

        //旋转屏幕
        // window.addEventListener('orientationchange', function (event) {
        //     setReviewDoc();
        // });
        window.addEventListener('resize', function (event) {
            if (vm.bindingtype == 'None' || $scope.template.pages.page.length < 2) {
                return
            }

            if (window.orientation == 0) {
                $rootScope.orientations = false;
            }
            setReviewDoc();
        });

        if (window.orientation == 90 || window.orientation == -90) {
            $li.width($scope.dWidth);
            $rootScope.orientation = false;
            console.log("always 横屏");
        } else {
            $rootScope.orientations = true;
        }

        function setReviewDoc() {
            $(".save-page").removeClass('hide1');
            $(".view2-article").addClass('hide1');
            $(".share-wrap").addClass('hide1');
            setTimeout(function () {
                if (window.orientation == 180 || window.orientation == 0) {
                    console.log("竖屏。。。。。");
                    $rootScope.orientations = true;
                    orientationChange();

                } else if (window.orientation == 90 || window.orientation == -90) {
                    console.log("横屏。。。。。");
                    $rootScope.orientations = false;
                    orientationChange();
                }
            }, 500);
        }

        function orientationChange() {
            $scope.k = 0;
            $scope.dHeight = document.documentElement.clientHeight;
            $scope.dWidth = document.documentElement.clientWidth;
            $(".back-to-customer").width($scope.dWidth);
            $li.width($scope.dWidth);
            vm.article = angular.copy(entity.data);
            getAll();
            setTimeout(function () {
                $(".save-page").addClass('hide1');
                $(".view2-article").removeClass('hide1');
                $(".share-wrap").removeClass('hide1');
            }, 1500);
        }
    }
})();
