(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('CodeModalController', CodeModalController);

    CodeModalController.$inject = ['$scope', '$state', 'MessageService', '$location', '$uibModalInstance', 'entity', 'Md5'];

    function CodeModalController($scope, $state, MessageService, $location, $uibModalInstance, entity, Md5) {
        var vm = this;
        var code = entity;

        $scope.modalSave = function () {
            if(!vm.code){
                MessageService.error("请输入秘钥");
                return
            }
            var _code = Md5.hex_md5(angular.copy(vm.code));
            console.log(_code)
            if(_code != code){
                MessageService.error("请输入正确的秘钥");
                return
            }
            MessageService.success("秘钥解密成功");
            $uibModalInstance.close(true);
        }
    }
})();
