(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('PageMySuffixCreateController', PageMySuffixCreateController);

    PageMySuffixCreateController.$inject = ['$scope', 'Category', '$uibModalInstance', 'MessageService','entity','$translate'];

    function PageMySuffixCreateController( $scope, Category, $uibModalInstance, MessageService, entity ,$translate) {

        var vm = this;
        console.log(entity);
        vm.type = entity.type;
        vm.name = entity.name || '';
        vm.clear = clear;
        vm.save = save;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function () {
            if (vm.name == null || vm.name == "") {
                MessageService.error($translate.instant("editorMessage.pleaseEnterFolderName"));
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            onSaveSuccess(vm.name);
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }

})();
