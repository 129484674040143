(function () {
    'use strict';

    var editorPhotoPreview = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/page/editor-photo-preview.html',
        // controller: 'articlePreviewController',
        controllerAs: 'vm',
        bindings: {
            previewArticle: '=',
            article:'='
        },
        controller: ['$scope', '$stateParams', 'Product', '$http', 'FontManagement', '$timeout',
            function ($scope, $stateParams, Product, $http, FontManagement, $timeout) {
                var vm = this;
                vm.isEdit = true;
                Product.get({id: vm.article.productId}, function (res) {
                    vm.product = res.data;
                    $scope.template = angular.copy(vm.previewArticle);
                    documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'processing', function(page){}, function () {
                        $timeout(function () {
                            vm.loading = false;
                            var _top = vm.messageList > 0 ? 132 :92;
                            vm.windowH = window.innerHeight - _top;
                            vm.windowW = window.innerWidth - 460;
                            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                                vm.windowW1 = window.innerWidth/2-50;
                                $(".preview-div").css({'justify-content': 'center'});
                            } else {
                                vm.windowW1 = 200;
                            }

                            vm.placeOrder = true;
                            vm.signalPage = $scope.template.pages.page[0];
                            var windowRatio = 0.8;
                            var custom = $scope.template.pages.page[0].custom;
                            vm.pageWidth = custom.width;
                            vm.pageHeight = custom.height;
                            if(vm.windowH / vm.pageHeight >= vm.windowW / vm.pageWidth ){
                                vm.ratio = vm.windowW / vm.pageWidth * windowRatio;
                            }else {
                                vm.ratio = vm.windowH / vm.pageHeight * windowRatio;
                            }
                            vm.ratio1 = vm.windowW1 / vm.pageWidth;
                            var documentPage = $scope.template.pages.page;
                            vm.data = [];
                            vm.data1 = [];
                            var _data = [];
                            for (var i = 0; i < documentPage.length; i++) {
                                if(!documentPage[i].backup){
                                    if(!vm.product.pictureType || vm.product.pictureType == 'single'){
                                        var isRepetition = false;
                                        var _documentPage1 = documentPage[i];
                                        for(var j=0; j<vm.data.length; j++){
                                            if (_documentPage1.uuid === vm.data[j].uuid) {
                                                isRepetition = true;
                                                vm.data[j].quantity++;
                                                break;
                                            }
                                        }
                                        if(!isRepetition){
                                            _documentPage1.quantity = 1;
                                            vm.data.push(_documentPage1);
                                        }
                                    }
                                    if(vm.product.pictureType == 'spread'){
                                        //双面
                                        var _documentPage2 = documentPage[i];
                                        var isRepetition1 = false;
                                        if (i % 2 === 0){
                                            //偶数

                                            for(var j=0; j<vm.data1.length; j++){
                                                if (_documentPage2.uuid === vm.data1[j][0].uuid) {
                                                    vm.data1[j][0].quantity++;
                                                    isRepetition1 = true;
                                                    break;
                                                }
                                            }
                                            if(!isRepetition1){
                                                _documentPage2.quantity = 1;
                                                _data.push(_documentPage2);
                                            }
                                        } else if (i % 2 === 1){
                                            //奇数
                                            if(_data.length>0){
                                                _data.push(_documentPage2);
                                                vm.data1.push(_data);
                                                _data = [];
                                            }
                                        }
                                    }
                                }
                            };
                            console.log(vm.data1)
                        }, 1000)
                    })
                });
                //边框
                $scope.imageboxW = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.width
                            // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                        }
                    } else {
                        return imagebox.geometry.width
                    }
                }
                $scope.imageboxH = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.height
                            // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                        }
                    } else {
                        return imagebox.geometry.height
                    }
                }
                $scope.imageboxL = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.x
                            // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                        }
                    } else {
                        return imagebox.geometry.x
                    }
                }
                $scope.imageboxT = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.y
                            // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                        }
                    } else {
                        return imagebox.geometry.y
                    }
                }
                $scope.imageboxWEdge = function (imagebox, edge) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.border.lineWidth)
                        }
                        if (imagebox.border.type == 'Pic') {
                            if (edge == 'left') {
                                return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                            }
                            if (edge == 'top') {
                                return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                            }
                            if (edge == 'right') {
                                return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                            }
                            if (edge == 'bottom') {
                                return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                            }
                        }
                    } else {
                        return 0
                    }
                }
                $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        var _ratio = 1;
                        if(ratio && ratio1){
                            if(imagebox.geometry.width > imagebox.geometry.height){
                                _ratio = ratio1
                            }else{
                                _ratio = ratio
                            }
                        }
                        if(ratio && !ratio1){
                            _ratio = ratio
                        }
                        if (imagebox.border.shape == 'Ellipse') {
                            return '50%'
                        }
                        if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                            var _radius = imagebox.border.radius[radius];
                            return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                        }else{
                            var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                            radiusW = radiusW + imagebox.border.lineWidth;
                            var _r = radiusW * imagebox.border.radius[radius] / 100;
                            return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                        }
                    } else {
                        return 0
                    }
                }
                $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        var _ratio = 1;
                        if(ratio && ratio1){
                            if(imagebox.geometry.width > imagebox.geometry.height){
                                _ratio = ratio1
                            }else{
                                _ratio = ratio
                            }
                        }
                        if(ratio && !ratio1){
                            _ratio = ratio
                        }
                        if (imagebox.border.shape == 'Ellipse') {
                            return '50%'
                        }
                        if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                            var _radius = imagebox.border.radius[radius];
                            return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                        }else{
                            var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                            radiusW = radiusW + imagebox.border.lineWidth;
                            var _r = radiusW * imagebox.border.radius[radius] / 100;
                            return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                        }
                    } else {
                        return 0
                    }
                }
                $scope.dropShadow = function(imagebox,ratio,ratio1){
                    if (imagebox.shadow == null) {
                        return
                    }
                    var width = $scope.imageboxW(imagebox) * ratio;
                    var height =$scope.imageboxH(imagebox) * ratio1;
                    return dropShadow(imagebox,width,height)
                }
            }
        ]
    };

    angular
        .module('editorApp')
        .component('editorPhotoPreview', editorPhotoPreview);
})();
