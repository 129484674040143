(function () {
    'use strict';
    angular
        .module('editorApp')
        .factory('Product', Product)
        .factory('MessageNotification', MessageNotification)
        .factory('WechatAuth', WechatAuth)
        .factory('PlatformWechatSetting', PlatformWechatSetting)
        .factory('PageForView', PageForView)
        .factory('PlatformAbutment', PlatformAbutment)
        .factory('Cart', Cart)
        .factory('Iphone', Iphone)
        .factory("OrderArticle", OrderArticle)
        .factory("DiyEditorSetting", DiyEditorSetting)
        .factory("RecommendPics", RecommendPics);

    WechatAuth.$inject = ['$resource'];
    Product.$inject = ['$resource'];
    MessageNotification.$inject = ['$resource'];
    PlatformWechatSetting.$inject = ['$resource'];
    PageForView.$inject = ['$resource'];
    PlatformAbutment.$inject = ['$resource'];
    Cart.$inject = ['$resource'];
    OrderArticle.$inject = ['$resource'];
    DiyEditorSetting.$inject = ['$resource'];
    RecommendPics.$inject = ['$resource'];

    function RecommendPics ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/recommend-pics/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                }
            },
        });
    }
    function WechatAuth($resource) {
        var resourceUrl = 'manager/' + 'wx/:path/:id';
        return $resource(resourceUrl, {}, {
            "auth_notify3": {
                method: "GET",
                params: {
                    path: "customer/auth_notify3"
                }
            },
            "auth_notify4": {
                method: "GET",
                params: {
                    path: "customer/auth_notify4"
                }
            },

        });
    }

    function Product($resource) {
        var resourceUrl = 'manager/' + 'api/products/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                }
            },
            "getAllByCondition2": {
                method: "POST",
                params: {
                    path: "getAllByCondition2"
                }
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                }
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                }
            },
            'get': {
                method: 'GET',
                params: {
                    path: "client"
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function MessageNotification ($resource) {
        var resourceUrl =  'manager/' + 'api/message-notifications/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            }
        });
    }

    function PlatformWechatSetting($resource) {
        var resourceUrl = "manager/api/platform-settings/:path/:id";

        return $resource(resourceUrl, {}, {
            "get": {
                method: "GET"
            }
        });
    }

    function PageForView($resource) {
        var resourceUrl = "service/api/page-for-views/:path/:id";
        return $resource(resourceUrl, {}, {
            "get": {
                method: "GET"
            }
        });
    }

    function PlatformAbutment ($resource) {
        var resourceUrl =  'manager/' + 'api/platform-abutment/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            'getAllByCondition':{
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                }
            }
        });
    }

    function Cart ($resource) {
        var resourceUrl =  'service/' + 'api/carts/:path/';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            'addGoods':{
                method: 'GET',
                params: {
                    path: 'addGoods'
                }
            },
            "quantityChange":{
                method: 'GET',
                params: {
                    path: 'quantityChange'
                }
            },
            'removeGoods':{
                method: 'GET',
                params: {
                    path: 'removeGoods'
                }
            }
        });
    }

    function OrderArticle($resource) {
        var resourceUrl = 'service/' + 'api/order-articles/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getByUser': {
                method: 'GET',
                params: {
                    path: 'getByUser'
                }
            }
        })
    }

    function DiyEditorSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/diy-editor-settings/:path/:id';
        return $resource(resourceUrl, {}, {
            'getOne4Platform': {
                method: 'GET',
                params: {
                    path: 'getOne4Platform'
                }
            },
            'byCondition1': {
                method: 'POST',
                params: {
                    path: 'byCondition1'
                }
            },
            "getAllByCondition1":{
                method:"POST",
                params:{
                    path:"getAllByCondition1"
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': { method:'POST' },
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            },
            'enable':{
                method: 'GET',
                params: {
                    path: 'enable'
                }
            }
        });
    }
    function Iphone () {
        var isIphone = /iphone/gi.test(window.navigator.userAgent);
        var isIphone678 = window.screen.width === 375 && window.screen.height === 667;
        var isIphone678p = window.screen.width === 414 && window.screen.height === 736;
        var isIphoneSE = window.screen.width === 320 && window.screen.height === 568;
        var isIphone5 = window.screen.width === 320 && window.screen.height === 568;
        if (!isIphone || isIphone && (isIphone678 || isIphone678p || isIphoneSE || isIphone5)) {
            return false
        } else {
            return true
        }
    }
})();
