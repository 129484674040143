(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('JhiLanguageController', JhiLanguageController);

    JhiLanguageController.$inject = ['$translate', 'JhiLanguageService', 'tmhDynamicLocale', '$localStorage'];

    function JhiLanguageController ($translate, JhiLanguageService, tmhDynamicLocale, $localStorage) {
        var vm = this;

        vm.changeLanguage = changeLanguage;
        vm.languages = $localStorage.languages;
        // JhiLanguageService.getAll().then(function (languages) {
        //     vm.languages = languages;
        // });

        function changeLanguage (languageKey) {
            $translate.use(languageKey);
            tmhDynamicLocale.set(languageKey);
        }
    }
})();
