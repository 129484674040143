(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductThreeEcController', ProductThreeEcController);

    ProductThreeEcController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'WxJS', 'Gallery', 'ItemValue', 'SweetAlert', 'Theme', '$localStorage', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'Store', 'Article', 'CommonService', '$http', 'Folder',
        'MessageNotification', 'ThemeClassify', 'entity', 'Product', 'ProductXml', 'DateUtils', 'Iphone','DiyEditorSetting',
        'ListService', 'StoreWorkEditConfig','$translate','IMAGE_SUPPORTED_MIME','MessageService','StoreStorages', '$websocket'];

    function ProductThreeEcController($state, $scope, $q, $stateParams, Convert, Qinius, WxJS, Gallery, ItemValue, SweetAlert, Theme, $localStorage, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, Store, Article, CommonService, $http, Folder,
          MessageNotification, ThemeClassify, entity, Product, ProductXml, DateUtils, Iphone, DiyEditorSetting,
          ListService, StoreWorkEditConfig,$translate,IMAGE_SUPPORTED_MIME,MessageService,StoreStorages, $websocket) {
        //初始化
        // $localStorage.authenticationToken = "eyJhbGciOiJIUzUxMiJ9.eyJzdG9yZSI6MTAsInNlcnZpY2UiOiJlY29tbWVyY2UiLCJzdG9yZV9hY3RpdmF0ZWQiOnRydWUsInN0b3JlX3BlcmlvZCI6eyJuYW5vIjo5MTYwMDAwMDAsImVwb2NoU2Vjb25kIjoxNzIwNzQ5Nzc5fSwic3ViIjoiMTIzMDcxMTA2MzA0ODk3MSJ9.rvo6GhnY5CV4nbZgByggqqiGI52jqeIgI14vJTr2Noz2tztYdVc2AttbfeqsypIRZe-b69ibJoCjEqA6RHpI0Q";
        // $localStorage.lagnuages = ['zh-cn', 'en'];
        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        var vm = this;
        vm.isEdit = entity.type == 'edit' ? true : false;
        var variableDatas = JSON.parse(localStorage.getItem('variableData'));
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.salesOrderId = $stateParams.soid ? $stateParams.soid : null;
        vm.productLineId = $stateParams.plid ? $stateParams.plid : null;
        vm.counts = 0;
        vm.selectImg = [];
        vm.indexNum = 0;
        vm.itemsPerPage = 20;
        vm.photoAll = [];
        vm.supportedMimeTypes = IMAGE_SUPPORTED_MIME;
        $scope.domains = Qinius.getDomain();

        $scope.agentIos = false;
        Qinius.get({}, function (res) {
            vm.token = res.token;
            vm.tokenTime = parseInt((res.expireAt - new Date().getTime()));
        });
        vm.platformSetting = PlatformWechatSetting.get();
        vm.isIPhone6 = isIPhone6();
        vm.photoArray = [];
        vm.photoSelectAll = JSON.parse(localStorage.getItem("photoSelectAll")) || [];
        vm.isPhone = false;
        vm.threeIsModal = false;
        vm.threeModalText = "";
        vm.threeLoading = false;
        vm.threeLoadingText=$translate.instant("hint.loadingText");
        vm.threeError = false;
        vm.threeErrorText=$translate.instant("hint.loadingText");

        diyEditorSettingConstInit();
        function diyEditorSettingConstInit(){
            vm.diyEditorSetting = {
                templateShow: true,//模板
                templateIcon: 'https://resources.momentsgocdn.com/gallery-5ae458bb-3254-4b9b-93dc-9caa0838a3ab',
                templateText:$translate.instant("editorBtn.templateText"),
                templateColor:'#333',
                clipartShow: true,//素材
                clipartIcon: 'https://resources.momentsgocdn.com/gallery-9fe28d31-ce19-4a77-bda9-c0e48b55059d',
                clipartText:$translate.instant("editorBtn.clipartText"),
                clipartColor:'#333',
                wordShow: true,//文字
                wordIcon: 'https://resources.momentsgocdn.com/gallery-9cf32f4b-46f2-4679-d454-55b11d66f8ba',
                wordText:$translate.instant("editorBtn.wordText"),
                wordColor:'#333',
                imgShow: true,//图片
                imgIcon: 'https://resources.momentsgocdn.com/gallery-09babd73-2e43-4c06-c4d6-f181f313d7c2',
                imgText:$translate.instant("editorBtn.imgText"),
                imgColor:'#333',
                photoShow: true,//图片框
                photoIcon: 'https://resources.momentsgocdn.com/v2/qn-photoshape.png',
                photoText:$translate.instant("editorBtn.photoText"),
                photoColor:'#333',
                graffitiShow: true,//涂鸦
                graffitiIcon: 'https://resources.momentsgocdn.com/v2/qn-photoshape.png',
                graffitiText:$translate.instant("editorBtn.graffiti"),
                graffitiColor:'#333',
                changeImgSelf: true,//换图
                changeImgIcon: 'https://resources.momentsgocdn.com/gallery-4d1f90d1-6c58-430d-abbb-2582c5eb24af',
                changeImgText:$translate.instant("editorBtn.changeImgText"),
                changeImgColor:'#000',
                filterShow: true,//滤镜显示
                filterIcon: 'https://resources.momentsgocdn.com/gallery-1b8c1b12-bbe1-47da-f73c-b42b5b1c8aaa',
                filterText:$translate.instant("editorBtn.filterText"),
                filterColor:'#000',
                rotationSelf: true,//旋转
                rotationIcon: 'https://resources.momentsgocdn.com/gallery-22f21c70-d5dc-4530-d00c-47fc34e4fb4d',
                rotationText:$translate.instant("editorBtn.rotationText"),
                rotationColor:'#000',
                upSelf: true,//放大
                upIcon: 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2',
                upText:$translate.instant("editorBtn.upText"),
                upColor:'#000',
                downSelf: true,//缩小
                downIcon: 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555',
                downText:$translate.instant("editorBtn.downText"),
                downColor:'#000',
                flipSelf: true,//翻转
                flipIcon: 'https://resources.momentsgocdn.com/gallery-7c05fe8d-31c8-4fae-8b31-9250e777d75e',
                flipText:$translate.instant("editorBtn.flipText"),
                flipColor:'#000',
                restoreSelf: true,//还原
                restoreIcon: 'https://resources.momentsgocdn.com/gallery-a1fd5b5d-ccd9-488f-8951-2753c6a1aa4c',
                restoreText:$translate.instant("editorBtn.restoreText"),
                restoreColor:'#000',
                spreadSelf: true,//展开
                spreadIcon: 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2',
                spreadText:$translate.instant("editorBtn.spreadText"),
                spreadColor:'#000',
                retractSelf: true,//缩回
                retractIcon: 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555',
                retractText:$translate.instant("editorBtn.retractText"),
                retractColor:'#000',
                editWordIconSelf: true,//编辑文字
                editWordIcon: 'https://resources.momentsgocdn.com/gallery-5e70fa05-5a5d-4e9d-ab4f-1699c6877940',
                editWordText:$translate.instant("editorBtn.editWordText"),
                editWordColor:'#0a0a0a',
                wordStyleIconSelf: true,//文字样式
                wordStyleIcon: 'https://resources.momentsgocdn.com/gallery-581f0375-e252-4c0f-ee79-ef69e4dfb1f9',
                wordStyleText:$translate.instant("editorBtn.wordStyleText"),
                wordStyleColor:'#0a0a0a',
                delWordIconSelf: true,//删除文字
                delWordIcon: 'https://resources.momentsgocdn.com/gallery-5072c751-ca0a-4b20-be05-bd61e8910176',
                delWordText:$translate.instant("editorBtn.delWordText"),
                delWordColor:'#0a0a0a',
                upButtonSelf: true, //放大按钮
                upButtonIcon: 'https://resources.momentsgocdn.com/gallery-7c6b81c0-5515-4b6e-994c-0d2da6e4606a',
                downButtonSelf: true, //缩小按钮
                downButtonIcon: 'https://resources.momentsgocdn.com/gallery-fde0cdea-8c55-41e7-fcc1-819c97b9739e',
                mainColor: '#ff6e33', //主色调
                templateSeq: 1,
                clipartSeq: 2,
                wordSeq: 3,
                imgSeq: 4,
                photoSeq: 5,
                graffitiSeq: 6,
            };
        }

        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            //或者return false;
            e.preventDefault();
        };

        init();

        var productId = null;
        function init() {
            if (!vm.isEdit) {
                getProduct(vm.pid,function () {
                    Article.getByUser({id: $stateParams.aid}, function (article) {
                        if (article.status == 200) {
                            vm.article = article.data;
                            if(vm.article.invalid){//作品失效
                                $state.go('articlesFailure',{storeId:vm.storeId});
                                return
                            }
                            ItemValue.get({id: $stateParams.vid}, function (res) {
                                $("body").toggleClass('bg');
                                $scope.status = localStorage.getItem('Production');
                                vm.productXmlLine = res.data.productXmlLines[0];
                                angular.forEach(res.data.productXmlLines, function (line) {
                                    if (line.id == vm.xmlId) {
                                        vm.productXmlLine = angular.copy(line);
                                    }
                                });
                                vm.article.modelBlendentUuid = vm.productXmlLine.modelBlendentUuid;
                                vm.article.cover = vm.productXmlLine.coverImg;
                                vm.article.themeClassifyId = vm.productXmlLine.productXmlThemeId;
                                vm.article.themeClassifyName = vm.productXmlLine.productXmlThemeName;
                                vm.article.productXmlId = vm.productXmlLine.productXmlId;
                                vm.article.productXmlName = vm.productXmlLine.productXmlName;
                                vm.article.musicIdentify = vm.productXmlLine.musicIdentify;
                                vm.article.itemValueId = res.data.id || vm.vid;
                                if (!res.data.productBokehType) {
                                    vm.article.bokehtype = 'None';
                                } else {
                                    vm.article.bokehtype = res.data.productBokehType;
                                }

                                var result = ListService.inList(vm.article, variableDatas, ["productXmlId"]);
                                if(result&&result.data&&result.data.length){
                                    angular.forEach(result.data,function (va,i) {
                                        if(va.type=='text'){
                                            vm.variableTextDatas.push(va)
                                        }
                                        if(va.type=='box'){
                                            vm.variableBoxDatas.push(va)
                                        }
                                    })
                                }
                                Convert.xmlToDocument2({
                                    identifier: vm.productXmlLine ? vm.productXmlLine.productXmlIdentifier : res.data.productXmlIdentifier,
                                    provider: "qiniu",
                                    editorType:vm.article.editorType,
                                    patternType:vm.product.patternType,
                                }, function (res) {
                                    vm.counts = 0;
                                    $scope.pages = res;
                                    productId = vm.pid;
                                    angular.forEach(res.pages.page, function (page, i) {
                                        angular.forEach(page.levels.level, function (level) {
                                            if (level.imagebox && level.imagebox.geometry) {
                                                vm.counts++;
                                            }
                                        })
                                    });
                                    abook();
                                });
                            });
                        }
                    })
                },function (error) {
                    if(error.status === 403){//token失效
                        return $state.go('404',{storeId:vm.storeId});
                    }
                    if(error.data.status === 404){//未找到作品
                        $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                    }
                })
            } else {
                Article.getByUser({id: $stateParams.aid}, function (res) {
                    vm.article = res.data;
                    if(vm.article.invalid){//作品失效
                        $state.go('articlesFailure',{storeId:vm.storeId});
                        return
                    }
                    productId = res.data.productId;
                    getProduct(productId,function () {
                        ItemValue.get({id: res.data.itemValueId}, function (resp) {
                            if(resp.status == 200){
                                vm.article.skuNumber = resp.data.skuNumber;
                                vm.article.productBokeh = resp.data.productBokeh;
                                vm.article.productBokehType = resp.data.productBokehType;
                                if (!resp.data.productBokehType) {
                                    vm.article.bokehtype = 'None';
                                } else {
                                    vm.article.bokehtype = resp.data.productBokehType;
                                }
                                angular.forEach(resp.data.productXmlLines, function (line) {
                                    if (line.productXmlId == vm.article.productXmlId) {
                                        vm.productXmlLine = angular.copy(line);
                                    }
                                });
                                Convert.xmlToDocument2({
                                    identifier: vm.article.fileName,
                                    provider: 'qiniu',
                                    editorType:vm.article.editorType,
                                    patternType:vm.product.patternType,
                                }, function (res) {
                                    vm.article.document = res;
                                    angular.forEach(vm.article.document.pages.page, function (page, i) {
                                        angular.forEach(page.levels.level, function (level) {
                                            if (level.imagebox && level.imagebox.geometry) {
                                                vm.counts++;
                                            }
                                        })
                                    });
                                    abook();
                                });
                            }else {
                                MessageService.error(res.message);
                            }
                        });
                    })
                },function(error){
                    if(error.status === 403){//token失效
                        return $state.go('404',{storeId:vm.storeId});
                    }
                    if(error.data.status === 404){//未找到作品
                        $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                    }
                })
            }
            vm.page4 = true;
            vm.page5 = false;

            $scope.qiniuId = localStorage.getItem("gallery") ? JSON.parse(localStorage.getItem("gallery")) : [];
        }


        vm.fontList = [];
        function getProduct(productId,callback) {
            Product.get({id: productId, cancelled: false}, function (res) {
                vm.product = res.data;
                if(vm.product.editorType == 'DIY_EDITOR'){
                    if(vm.product.diyEditorSettingId){
                        getDiyEditorSetting();
                    }else{
                        //获取所有字体
                        getAllFont();
                        diyEditorSettingInit()
                    }
                }else{
                    //获取所有字体
                    getAllFont();
                    diyEditorSettingInit()
                }
                if(callback){callback()}
            })
        }

        function getDiyEditorSetting(){
            DiyEditorSetting.get({id: vm.product.diyEditorSettingId}, function (res) {
                vm.diyEditorSetting = res.data ? res.data : vm.diyEditorSetting;
                if(vm.diyEditorSetting.templateSelf){
                    vm.diyEditorSetting.templateIcon = vm.diyEditorSetting.templateIcon || 'https://resources.momentsgocdn.com/gallery-5ae458bb-3254-4b9b-93dc-9caa0838a3ab';
                }else{
                    vm.diyEditorSetting.templateIcon = 'https://resources.momentsgocdn.com/gallery-5ae458bb-3254-4b9b-93dc-9caa0838a3ab';
                }
                vm.diyEditorSetting.templateText = vm.diyEditorSetting.templateText || $translate.instant("editorBtn.templateText");
                vm.diyEditorSetting.templateColor = vm.diyEditorSetting.templateColor || '#333';

                if(vm.diyEditorSetting.clipartSelf){
                    vm.diyEditorSetting.clipartIcon = vm.diyEditorSetting.clipartIcon || 'https://resources.momentsgocdn.com/gallery-9fe28d31-ce19-4a77-bda9-c0e48b55059d';
                }else{
                    vm.diyEditorSetting.clipartIcon = 'https://resources.momentsgocdn.com/gallery-9fe28d31-ce19-4a77-bda9-c0e48b55059d';
                }
                vm.diyEditorSetting.clipartText = vm.diyEditorSetting.clipartText || $translate.instant("editorBtn.clipartText");
                vm.diyEditorSetting.clipartColor = vm.diyEditorSetting.clipartColor || '#333';
                if(vm.diyEditorSetting.wordIconSelf){
                    vm.diyEditorSetting.wordIcon = vm.diyEditorSetting.wordIcon || 'https://resources.momentsgocdn.com/gallery-9cf32f4b-46f2-4679-d454-55b11d66f8ba';
                }else{
                    vm.diyEditorSetting.wordIcon = 'https://resources.momentsgocdn.com/gallery-9cf32f4b-46f2-4679-d454-55b11d66f8ba';
                }
                vm.diyEditorSetting.wordText = vm.diyEditorSetting.wordText || $translate.instant("editorBtn.wordText");
                vm.diyEditorSetting.wordColor = vm.diyEditorSetting.wordColor || '#333';

                if(vm.diyEditorSetting.imgSelf){
                    vm.diyEditorSetting.imgIcon = vm.diyEditorSetting.imgIcon || 'https://resources.momentsgocdn.com/gallery-09babd73-2e43-4c06-c4d6-f181f313d7c2';
                }else{
                    vm.diyEditorSetting.imgIcon = 'https://resources.momentsgocdn.com/gallery-09babd73-2e43-4c06-c4d6-f181f313d7c2';
                }
                vm.diyEditorSetting.imgText = vm.diyEditorSetting.imgText || $translate.instant("editorBtn.imgText");
                vm.diyEditorSetting.imgColor = vm.diyEditorSetting.imgColor || '#333';

                if(vm.diyEditorSetting.changeImgSelf){
                    vm.diyEditorSetting.changeImgIcon = vm.diyEditorSetting.changeImgIcon || 'https://resources.momentsgocdn.com/gallery-4d1f90d1-6c58-430d-abbb-2582c5eb24af';
                }else{
                    vm.diyEditorSetting.changeImgIcon = 'https://resources.momentsgocdn.com/gallery-4d1f90d1-6c58-430d-abbb-2582c5eb24af';
                }
                vm.diyEditorSetting.changeImgText = vm.diyEditorSetting.changeImgText || $translate.instant("editorBtn.changeImgText");
                vm.diyEditorSetting.changeImgColor = vm.diyEditorSetting.changeImgColor || '#000';

                if(vm.diyEditorSetting.filterSelf){
                    vm.diyEditorSetting.filterIcon = vm.diyEditorSetting.filterIcon || 'https://resources.momentsgocdn.com/gallery-1b8c1b12-bbe1-47da-f73c-b42b5b1c8aaa';
                }else{
                    vm.diyEditorSetting.filterIcon = 'https://resources.momentsgocdn.com/gallery-1b8c1b12-bbe1-47da-f73c-b42b5b1c8aaa';
                }
                vm.diyEditorSetting.filterText = vm.diyEditorSetting.filterText || $translate.instant("editorBtn.filterText");
                vm.diyEditorSetting.filterColor = vm.diyEditorSetting.filterColor || '#000';

                if(vm.diyEditorSetting.rotationSelf){
                    vm.diyEditorSetting.rotationIcon = vm.diyEditorSetting.rotationIcon || 'https://resources.momentsgocdn.com/gallery-22f21c70-d5dc-4530-d00c-47fc34e4fb4d';
                }else{
                    vm.diyEditorSetting.rotationIcon = 'https://resources.momentsgocdn.com/gallery-22f21c70-d5dc-4530-d00c-47fc34e4fb4d';
                }
                vm.diyEditorSetting.rotationText = vm.diyEditorSetting.rotationText || $translate.instant("editorBtn.rotationText");
                vm.diyEditorSetting.rotationColor = vm.diyEditorSetting.rotationColor || '#000';

                if(vm.diyEditorSetting.upSelf){
                    vm.diyEditorSetting.upIcon = vm.diyEditorSetting.upIcon || 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2';
                }else{
                    vm.diyEditorSetting.upIcon = 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2';
                }
                vm.diyEditorSetting.upText = vm.diyEditorSetting.upText || $translate.instant("editorBtn.upText");
                vm.diyEditorSetting.upColor = vm.diyEditorSetting.upColor || '#000';

                if(vm.diyEditorSetting.downSelf){
                    vm.diyEditorSetting.downIcon = vm.diyEditorSetting.downIcon || 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555';
                }else{
                    vm.diyEditorSetting.downIcon = 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555';
                }
                vm.diyEditorSetting.downText = vm.diyEditorSetting.downText || $translate.instant("editorBtn.downText");
                vm.diyEditorSetting.downColor = vm.diyEditorSetting.downColor || '#000';

                if(vm.diyEditorSetting.flipSelf){
                    vm.diyEditorSetting.flipIcon = vm.diyEditorSetting.flipIcon || 'https://resources.momentsgocdn.com/gallery-7c05fe8d-31c8-4fae-8b31-9250e777d75e';
                }else{
                    vm.diyEditorSetting.flipIcon = 'https://resources.momentsgocdn.com/gallery-7c05fe8d-31c8-4fae-8b31-9250e777d75e';
                }
                vm.diyEditorSetting.flipText = vm.diyEditorSetting.flipText || $translate.instant("editorBtn.flipText");
                vm.diyEditorSetting.flipColor = vm.diyEditorSetting.flipColor || '#000';

                if(vm.diyEditorSetting.restoreSelf){
                    vm.diyEditorSetting.restoreIcon = vm.diyEditorSetting.restoreIcon || 'https://resources.momentsgocdn.com/gallery-a1fd5b5d-ccd9-488f-8951-2753c6a1aa4c';
                }else{
                    vm.diyEditorSetting.restoreIcon = 'https://resources.momentsgocdn.com/gallery-a1fd5b5d-ccd9-488f-8951-2753c6a1aa4c';
                }
                vm.diyEditorSetting.restoreText = vm.diyEditorSetting.restoreText || $translate.instant("editorBtn.restoreText");
                vm.diyEditorSetting.restoreColor = vm.diyEditorSetting.restoreColor || '#000';

                if(vm.diyEditorSetting.spreadSelf){
                    vm.diyEditorSetting.spreadIcon = vm.diyEditorSetting.spreadIcon || 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2';
                }else{
                    vm.diyEditorSetting.spreadIcon = 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2';
                }
                vm.diyEditorSetting.spreadText = vm.diyEditorSetting.spreadText || $translate.instant("editorBtn.spreadText");
                vm.diyEditorSetting.spreadColor = vm.diyEditorSetting.spreadColor || '#000';

                if(vm.diyEditorSetting.retractSelf){
                    vm.diyEditorSetting.retractIcon = vm.diyEditorSetting.retractIcon || 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555';
                }else{
                    vm.diyEditorSetting.retractIcon = 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555';
                }
                vm.diyEditorSetting.retractText = vm.diyEditorSetting.retractText || $translate.instant("editorBtn.retractText");
                vm.diyEditorSetting.retractColor = vm.diyEditorSetting.retractColor || '#000';

                if(vm.diyEditorSetting.editWordIconSelf){
                    vm.diyEditorSetting.editWordIcon = vm.diyEditorSetting.editWordIcon || 'https://resources.momentsgocdn.com/gallery-5e70fa05-5a5d-4e9d-ab4f-1699c6877940';
                }else{
                    vm.diyEditorSetting.editWordIcon = 'https://resources.momentsgocdn.com/gallery-5e70fa05-5a5d-4e9d-ab4f-1699c6877940';
                }
                vm.diyEditorSetting.editWordText = vm.diyEditorSetting.editWordText || $translate.instant("editorBtn.editWordText");
                vm.diyEditorSetting.editWordColor = vm.diyEditorSetting.editWordColor || '#0a0a0a';

                if(vm.diyEditorSetting.wordStyleIconSelf){
                    vm.diyEditorSetting.wordStyleIcon = vm.diyEditorSetting.wordStyleIcon || 'https://resources.momentsgocdn.com/gallery-581f0375-e252-4c0f-ee79-ef69e4dfb1f9';
                }else{
                    vm.diyEditorSetting.wordStyleIcon = 'https://resources.momentsgocdn.com/gallery-581f0375-e252-4c0f-ee79-ef69e4dfb1f9';
                }
                vm.diyEditorSetting.wordStyleText = vm.diyEditorSetting.wordStyleText || $translate.instant("editorBtn.wordStyleText");
                vm.diyEditorSetting.wordStyleColor = vm.diyEditorSetting.wordStyleColor || '#0a0a0a';

                if(vm.diyEditorSetting.delWordIconSelf){
                    vm.diyEditorSetting.delWordIcon = vm.diyEditorSetting.delWordIcon || 'https://resources.momentsgocdn.com/gallery-5072c751-ca0a-4b20-be05-bd61e8910176';
                }else{
                    vm.diyEditorSetting.delWordIcon = 'https://resources.momentsgocdn.com/gallery-5072c751-ca0a-4b20-be05-bd61e8910176';
                }
                vm.diyEditorSetting.delWordText = vm.diyEditorSetting.delWordText || $translate.instant("editorBtn.delWordText");
                vm.diyEditorSetting.delWordColor = vm.diyEditorSetting.delWordColor || '#0a0a0a';

                vm.diyEditorSetting.upButtonIcon = vm.diyEditorSetting.upButtonIcon || 'https://resources.momentsgocdn.com/gallery-7c6b81c0-5515-4b6e-994c-0d2da6e4606a';

                vm.diyEditorSetting.downButtonIcon = vm.diyEditorSetting.downButtonIcon || 'https://resources.momentsgocdn.com/gallery-fde0cdea-8c55-41e7-fcc1-819c97b9739e';
                vm.diyEditorSetting.mainColor = vm.diyEditorSetting.mainColor || '#ff6e33';
                if(vm.diyEditorSetting.wordIconSelf){
                    if(vm.diyEditorSetting.fontSupportSelf){
                        vm.diyEditorSetting.supportFonts.forEach(function (supportFont) {
                            vm.fontList.push(supportFont.fontManagement)
                        })
                    }else{
                        //获取所有字体
                        getAllFont();
                    }
                    if(vm.diyEditorSetting.fontColorSupportSelf){
                        $scope.colorList = [];
                        vm.diyEditorSetting.supportFontColors.forEach(function (supportFontColor) {
                            $scope.colorList.push(supportFontColor.color)
                        })
                    }
                }else{
                    //获取所有字体
                    getAllFont();
                }
                diyEditorSettingInit()
            })
        }

        function diyEditorSettingInit(){
            if(vm.diyEditorSetting){
                vm.diyEditorSetting.seqItem=[];
                if(vm.diyEditorSetting.wordShow){
                    vm.diyEditorSetting.seqItem.push({
                        type:'word',
                        seq: vm.diyEditorSetting.wordSeq
                    })
                }
                if(vm.diyEditorSetting.templateShow){
                    vm.diyEditorSetting.seqItem.push({
                        type:'template',
                        seq: vm.diyEditorSetting.templateSeq
                    })
                }
                if(vm.diyEditorSetting.clipartShow){
                    vm.diyEditorSetting.seqItem.push({
                        type:'clipart',
                        seq: vm.diyEditorSetting.clipartSeq
                    })
                }
                if(vm.diyEditorSetting.imgShow){
                    vm.diyEditorSetting.seqItem.push({
                        type:'img',
                        seq: vm.diyEditorSetting.imgSeq
                    })
                }
                if(vm.diyEditorSetting.graffitiShow){
                    vm.diyEditorSetting.seqItem.push({
                        type:'graffiti',
                        seq: vm.diyEditorSetting.graffitiSeq
                    })
                }
                if(vm.diyEditorSetting.photoShow){
                    vm.diyEditorSetting.seqItem.push({
                        type:'photo',
                        seq: vm.diyEditorSetting.photoSeq
                    })
                }
                vm.diyEditorSetting.seqItem = vm.diyEditorSetting.seqItem.sort(sortItem("seq"));
            }
        }

        $q.all([$scope.domains.$promise,vm.platformSetting.$promise]).then(function () {
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            vm.platformSetting = vm.platformSetting.data;
            websocket();
            /**
             * wechat api
             */
            if (wx) {
                var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
                WxJS.get({url: url}, function (res) {
                    if(res.status==200) {
                        $scope.agentIos = browserVersions();
                        var config = res.data;
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.appId, // 必填，公众号的唯一标识
                            timestamp: config.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.nonceStr, // 必填，生成签名的随机串
                            signature: config.signature,// 必填，签名，见附录1
                            jsApiList: ['chooseImage', 'uploadImage', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function () {
                            console.log('wechat api ready!');

                            wx.hideMenuItems({
                                menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                            });
                        });
                        wx.error(function (res) {
                            console.log(res)
                        });
                    }
                }, function () {
                    console.log("wx config error");
                });
            }

            vm.lagnuages =[];
            if ($localStorage.lagnuages) {
                for(var i = 0 ; i<$localStorage.lagnuages.length; i++ ){
                    var lang = $localStorage.lagnuages[i];
                    var langObj = {key:lang};
                    if (lang == 'zh-cn') {
                        langObj.value = "中文";
                    }
                    if (lang == 'en') {
                        langObj.value = "English";
                    }
                    if (vm.lang == lang) {
                        vm.langValue = langObj.value;
                        langObj.selected = true;
                    }
                    vm.lagnuages.push(langObj);
                }
            }

            // token过期
            setTimeout(function fn() {
                Qinius.get(function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            //获取图片库
            loadAll('isNull');
            //消息列表
            getMessage();
            vm.isIPhoneX = Iphone;
        });

        $scope.uploadPhoto = function () {
            uploadAble(StoreStorages,vm.storeId, function (res) {
                if(res.status == 200){
                    if($scope.agentIos){
                        $rootScope.$broadcast('clickAndroidUpload',true);
                    }else{
                        $timeout(function () {
                            $("#uploadImageMutipleThree").trigger("click");
                        })
                    }
                }else{
                    if(res.status == 400){
                        threeInfo('系统出错');
                    } else {
                        threeInfo(res.data||'系统出错');
                    }
                }
            })
        }
        //消息列表
        function getMessage() {
            vm.messageList = "";
            MessageNotification.getAllByCondition2({
                items: [
                    {key: "available", op: "=", value: "true"},
                    {key: "validate", op: ">=", value: DateUtils.convertLocalDateToServer(new Date())},
                    {key:"storeId", op : "=", value:vm.storeId},
                    {key: "pageType", op: "=", value: "Edit"}]
            }, function (res) {
                if(res.status == 200){
                    res.data.sort(compare('seq'))
                    vm.message = angular.copy(res.data);
                    vm.messageEdit = angular.copy(res.data);
                    vm.messageEditText = "\xa0\xa0\xa0\xa0\xa0\xa0";
                    angular.forEach(res.data, function (resp, i) {
                        vm.messageList += resp.content
                        if (i != res.data.length - 1) {
                            vm.messageList += "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                        }
                    })
                }else {
                    MessageService.error(res.message);
                }
            });
        }
        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }


        $scope.getBack = function (item) {
            if (item == 'page5') {
                vm.page4 = true;
                vm.page5 = false;
            }
        }


        $('.sticker-scroll').scroll(function () {
            if (!vm.photoCompleted) {
                var allHeight = $(this).find("ul").height() - $(this).height();
                if ($(this).scrollTop() >= allHeight) {
                    vm.indexNum++;
                    loadAll()
                }
            }
        });
        vm.uploadPhotoItem=[];
        $scope.$on('uoloadFinished', function(event, args) {
            if(args){
                angular.forEach(vm.photoAll,function (qin) {
                    if(qin.blobCover){
                        qin.blobCover = null;
                    }
                })
                console.log('···················上传完成···················');
                // updatePic();
            }
        });
        // 上传图片后更新图片库/文件夹
        function updatePic() {
            vm.photoAll=[];
            vm.indexNum = 0;
            vm.photoCompleted = false;
            loadAll();
        }
        function loadAll() {
            var items = [];
            items.push({key: "folderId", op: "isNull", value: ''})
            items.push({key: "articleId", op: "=", value: vm.aid})
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            Gallery.storeByCondition({
                items: items,
                page: vm.indexNum,
                size: vm.itemsPerPage,
                sort: sorts()
            }, onSuccess, onError);
        }

        function sorts() {
            return ["id,desc"];
        }

        function onSuccess(res) {
            if (vm.maxId == null && res.data.length > 0) {
                vm.maxId = res.data[0].id;
            }
            for (var i = 0; i < res.data.length; i++) {
                if (!res.data[i].cancelled) {
                    res.data[i].identifier = $scope.domain + res.data[i].identifier;
                    vm.photoAll.push(res.data[i])
                }
            }
            if (res.data.length < 20) {
                vm.photoCompleted = true;
            }
        }

        function onError(data) {
            if(data.status !== 403){
                MessageService.hint($translate.instant("galleryMessage.failedToObtainThePhotoGallery"));
            }

        }


        //获取dpi
        js_getDPI(function (dpi) {
            vm.windowDpi = dpi;
        });
        vm.pageData = null;
        vm.pageIndex = 0;
        $scope.template = null;
        function abook() {
            productXmlGet()
            vm.page4 = true;
            vm.page8 = true;
            if (vm.isEdit) {
                $scope.template = angular.copy(vm.article.document);
            } else {
                $scope.template = angular.copy($scope.pages);
            }
            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                ratioInfo(page)
            }, function () {
                $timeout(function () {
                    vm.page8 = false;
                    vm.pageData = $scope.template.pages.page;
                    vm.pageSum = $scope.template.pages.page.length - 1;
                    vm.pageIndex = 0;
                    warnIcon()
                    $timeout(function () {
                        thumbnailInit();
                    },1000)
                }, 1000)
            })
        }

        function productXmlGet(){
            ProductXml.get({id:vm.article.productXmlId}, function (productXml) {
                vm.productXmlData = productXml.data;
            })
        }
        vm.isEditboxMagnify = false;
        vm.ratioInfo = ratioInfo;
        function ratioInfo(item) {
            vm.windowWidth = document.body.clientWidth;
            vm.windowHeight = document.body.clientHeight * 0.91 - 175;
            if(vm.isAddImagebox || vm.mapDepot || vm.isMaterial || (vm.istemplate && vm.page4)){
                vm.windowHeight = document.body.clientHeight - 280 - (document.body.clientHeight*0.02);
            }
            if(vm.isEditboxMagnify){
                var ratio = item.mediabox.width / item.mediabox.height;
                var ratio1 = vm.windowWidth / vm.windowHeight;
                if (ratio >= ratio1) {
                    item.custom.ratio1 = vm.windowWidth / item.mediabox.width;
                } else {
                    item.custom.ratio1 = vm.windowHeight / item.mediabox.height;
                }
            }else{
                var ratio = item.custom.width / item.custom.height;
                var ratio1 = vm.windowWidth / vm.windowHeight;
                if (ratio >= ratio1) {
                    item.custom.ratio1 = vm.windowWidth / item.custom.width;
                } else {
                    item.custom.ratio1 = vm.windowHeight / item.custom.height;
                }
            }
            item.custom.thumbnailScale = 300 / item.custom.width;
        }
        $scope.editboxMagnify = function () {
            vm.isEditboxMagnify = !vm.isEditboxMagnify;
            ratioInfo(vm.pageData[vm.pageIndex]);
        }
        function warnIcon() {
            vm.warnIcon = false;
            vm.pageData[vm.pageIndex].levels.level.forEach(function (level) {
                if(level.imagebox && level.imagebox.image && pxInsufficient(level.imagebox,vm.imgSize, vm.imgDpi)){
                    vm.warnIcon = true;
                }
            })
        }

        $scope.previousPage = function () {
            vm.pageIndex--;
            warnIcon();
            ratioInfo(vm.pageData[vm.pageIndex]);
        }
        $scope.nextPage = function () {
            vm.pageIndex++;
            warnIcon();
            ratioInfo(vm.pageData[vm.pageIndex]);
        }
        $scope.goPageContent = function (index) {
            vm.isDocumentTextDetection=false;
            vm.pageIndex = index;
            warnIcon();
            ratioInfo(vm.pageData[vm.pageIndex]);
        }

        /*******************************************点击模板*************************************************************************/
        $scope.templateShow = function () {
            vm.istemplate = true;
            productInit()
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.templateHide = function () {
            vm.istemplate = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }

        function productInit() {
            Product.get({id: vm.article.productId, cancelled: false}, function (res) {
                for (var i = 0; i < res.data.itemValues.length; i++) {
                    var itemValue = res.data.itemValues[i];
                    if (itemValue.cancelled) {
                        continue;
                    }
                    if (itemValue.id == vm.article.itemValueId) {
                        vm.tempList = angular.copy(itemValue);
                        productXmlInit()
                    }
                }
            })
        }
        function productXmlInit(){
            vm.productXmlThemeId = '';
            vm.productXmls = [{id:'',name:$translate.instant("editorCommon.all"), seq:''}];
            vm.tempList.productXmlLines.forEach(function (temp) {
                if (temp.themeClassifies && !temp.cancelled && temp.paid) {
                    temp.themeClassifies.forEach(function (themeClassifie) {
                        var isExist = false;
                        for(var i=0; i<vm.productXmls.length; i++){
                            if(vm.productXmls[i].id == themeClassifie.id){
                                isExist = true;
                                break;
                            }
                        }
                        if(!isExist){
                            vm.productXmls.push(themeClassifie)
                        }
                    })
                }
            })
            vm.productXmls.sort(function (a, b) {
                return a.seq - b.seq;
            });
        }
        $scope.productXmlCb = function(item){
            vm.productXmlThemeId = item.id;
        };
        $scope.productXmlShow = function(themeClassifies){
            var _exist = false;
            themeClassifies.forEach(function (themeClassifie) {
                if (vm.productXmlThemeId ==  themeClassifie.id) {
                    _exist = true;
                }
            })
            return _exist
        }
        vm.imgs = [];
        var templateSwiper = null;
        $scope.templateCb = function (temp) {
            if (temp.id == vm.xmlId || temp.productXmlId == vm.article.productXmlId) {
                vm.threeIsModal1 = true;
                vm.threeModalText1 = $translate.instant("editorCommon.alreadyExistTemplate");
                return
            }
            vm.xmlShowPic = true;
            $scope.chooseTemp = temp;
            ProductXml.get({id: temp.productXmlId}, function (res) {
                if (res.data.documentPics && res.data.documentPics.length > 0) {
                    res.data.documentPics.sort(function (a, b) {
                        return a.seq - b.seq;
                    });
                }
                vm.imgs = res.data.documentPics;
                if(vm.imgs.length > 0){
                    $timeout(function () {
                        templateSwiperInit();
                        templateSwiper.init();//现在才初始化
                    },200)
                }
            }, function () {

            })
        }
        function templateSwiperInit() {
            templateSwiper = new Swiper ('.swiper-template-view-content', {
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            })
        }

        var inits = 0, num = 0;
        $scope.previewClose = function () {
            vm.xmlShowPic = false;
        };
        $scope.changeAllTemp = function () {
            vm.threeIsModal = true;
            vm.threeModalText = $translate.instant("editorCommon.whetherReplaceTemplate");
        }

        function templateReplace() {
            if(vm.isEdit){
                vm.pageData.forEach(function (page) {
                    if(page.levels.level){
                        page.levels.level.forEach(function (level) {
                            if (level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                                vm.photoSelectAll.push({
                                    height: level.imagebox.image.height,
                                    id: level.imagebox.image.resourceid,
                                    imageInfo: level.imagebox.image.imageInfo,
                                    identifier: level.imagebox.image.resource.identifier.indexOf('http') > -1 ? level.imagebox.image.resource.identifier : domain + level.imagebox.image.resource.identifier,
                                    imgsize: level.imagebox.image.imgsize,
                                    name: null,
                                    provider: "qiniu",
                                    usenum: 1,
                                    width: level.imagebox.image.width
                                });
                            }
                        })
                    }
                })
                Convert.xmlToDocument2({
                    identifier: $scope.chooseTemp.productXmlIdentifier,
                    provider: "qiniu",
                    editorType:vm.article.editorType,
                    patternType:vm.product.patternType,
                }, function (res) {
                    vm.counts = 0;
                    angular.forEach(res.pages.page, function (page, i) {
                        if(!page.backup) {
                            angular.forEach(page.levels.level, function (level) {
                                if (level.imagebox && level.imagebox.geometry) {
                                    vm.counts++;
                                }
                            })
                        }
                    });
                    $scope.previewClose();
                    $scope.templateHide();
                    vm.article.document = res;

                    vm.productXmlLine = angular.copy($scope.chooseTemp);
                    vm.modelBlendentUuid=vm.productXmlLine.modelBlendentUuid;
                    vm.article.modelBlendentUuid= vm.productXmlLine.modelBlendentUuid;
                    vm.article.cover= vm.productXmlLine.coverImg;
                    vm.article.themeClassifyId= vm.productXmlLine.productXmlThemeId;
                    vm.article.themeClassifyName= vm.productXmlLine.productXmlThemeName;
                    vm.article.productXmlId= vm.productXmlLine.productXmlId;
                    vm.article.productXmlName= vm.productXmlLine.productXmlName;
                    vm.article.productXmlLineId= vm.productXmlLine.id;
                    if(vm.productXmlLine && vm.productXmlLine.musicIdentify){
                        vm.article.musicIdentify= vm.productXmlLine.musicIdentify;
                    }
                    vm.isHtml2canvas = false;
                    vm.editProductXml = true;
                    abook();
                });
            }else{
                // window.location.href = vm.platformSetting.editorNotifyUrl+'/#/'+vm.storeId+'/'+vm.pid+'/'+vm.vid+'/'+$scope.chooseTemp.id+'/'+vm.aid+'/chooseThree';
                $state.go('product-chooseThree-ec', {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: $scope.chooseTemp.id, aid: vm.aid})
                // window.location.reload();
                localStorage.setItem("photoSelectAll", JSON.stringify(vm.photoSelectAll));
            }
        }

        /*******************************************点击图片库*************************************************************************/
        vm.isImageboxSelectItem = false;
        vm.mapDepot = false;
        $scope.photoGalleryShow = function () {
            vm.mapDepot = true;
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.photoGalleryHide = function () {
            vm.mapDepot = false;
            vm.isAddImagebox = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $('#photoGallery').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.photoCompleted){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.indexNum++
                    loadAll("isNull")
                }
            }
        })
        $scope.addImageboxCb = function(){
            vm.isAddImagebox = true;
            ratioInfo(vm.pageData[vm.pageIndex])
        };
        function imageboxAddFn(item, callback){

            imageboxAdd($http, item, vm.pageData[vm.pageIndex].trimbox, vm.article, function(imagebox){

                addImagebox($http, imagebox, function () {
                    var _level = {
                        seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                        imagebox: imagebox
                    }
                    vm.pageData[vm.pageIndex].levels.level.push(_level);
                    $timeout(function () {
                        if(!vm.isImageboxSelectItem){
                            $scope.makeBody();
                        }
                        vm.levelIndex = parseInt(vm.pageData[vm.pageIndex].levels.level.length-1);
                        vm.levelType = 'imagebox';
                        thumbnailInit();
                            if(callback){callback()}
                    });
                });
            })
        }


        /*******************************************素材处理*************************************************************************/
        $scope.makeBody = vm.makeBody = function (callback) {
            vm.levelIndex = null;
            vm.levelType = null;
            vm.isImageboxSelectItem = false;
            vm.textStyleShow = false;
            vm.textboxSelect = false;
            vm.filterShow = false;
            vm.mattingShow = false;
            if(vm.isAddImagebox || vm.mapDepot || vm.isMaterial || vm.istemplate){
                vm.istemplate = false;
                vm.isMaterial = false;
                vm.mapDepot = false;
                vm.isAddImagebox = false;
                ratioInfo(vm.pageData[vm.pageIndex]);
            }else {
                vm.istemplate = false;
                vm.isMaterial = false;
                // vm.mapDepot = false;
                vm.isAddImagebox = false;
            }
            if(callback)(callback())
        };
        vm.levelDome = function(item){
            vm.mattingShow = false;
            vm.filterShow = false;
            vm.isImageboxSelectItem = false;
            vm.istemplate = false;
            vm.isMaterial = false;
            vm.textboxSelect = false;
            vm.mapDepot = false;
            vm.isAddImagebox = false;
            vm.textStyleShow = false;
            if(item){
                vm[item] = true;
            }
        }

        vm.isMaterial = false;
        $scope.materialShow = function () {
            vm.isMaterial = true;
            vm.materialPage = 0;
            vm.materialSum = 20;
            vm.materialData = [];
            getThemeClassify();
            ratioInfo(vm.pageData[vm.pageIndex])
        }

        function getThemeClassify(){
            //分类
            ThemeClassify.getAllByCondition4({type: 'CLIPART', storeId: vm.storeId}, function (res) {
                vm.classify = [];
                if($scope.template.cliparts && $scope.template.cliparts.clipart && $scope.template.cliparts.clipart.length>0){
                    vm.classify = [{
                        id: "",
                        name: $translate.instant("editorCommon.recommend"),
                        seq: 0,
                        type: "CLIPART",
                        used: true
                    }]
                }
                res.data.forEach(function (item) {
                    if(item.storeId) {
                        vm.classify.push(item)
                    }
                })
                res.data.forEach(function (item) {
                    if(!item.storeId) {
                        vm.classify.push(item)
                    }
                })
                vm.classifyW = res.data.length * 86;
                initMaterial(vm.classify[0].id)
            });
        }
        $scope.classifyCb = function (item) {
            vm.materialPage = 0;
            vm.materialData = [];
            initMaterial(item.id)
        }
        //获取素材
        vm.isMaterial1 = false;

        function initMaterial(id) {
            vm.classifyId = id;
            if(id){
                ClipArt.byCondition3({
                    items: [
                        {key: "themeClassify.id", op: "=", value: id}],
                    page: vm.materialPage,
                    size: vm.materialSum,
                    sort: ["seq,asc"]
                }, function (res) {
                    angular.forEach(res.data, function (resp) {
                        var arr = {
                            name: resp.name,
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                            themeClassifyName: resp.themeClassifyName,
                            themeClassifyId: resp.themeClassifyId,
                            resource: {
                                identifier: $scope.domain + resp.identifier,
                                provider: resp.provider
                            }
                        }
                        vm.materialData.push(arr);
                    })
                    vm.materialW = vm.materialData.length * 90 + 20;
                    if (res.data.length < vm.materialSum) {
                        vm.isMaterial1 = true;
                    }else {
                        vm.isMaterial1 = false;
                    }
                });
            }else{
                $scope.template.cliparts.clipart.forEach(function (clipart) {
                    var arr = {
                        name: "",
                        identifier: clipart.resource.identifier,
                        provider: "",
                        themeClassifyName: $translate.instant("editorCommon.recommend"),
                        themeClassifyId: "",
                        resource: clipart.resource
                    }
                    vm.materialData.push(arr);
                })
                vm.materialW = vm.materialData.length * 90 + 20;
                vm.isMaterial1 = true;
            }
        }
        $('#materialView').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.isMaterial1){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.materialPage++;
                    initMaterial(vm.classifyId)
                }
            }
        })
        $scope.materialHide = function () {
            vm.isMaterial = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }

        //添加素材
        vm.repeatAddNumber = 0;
        $scope.addImageart = function (item) {
            vm.threeLoading = true;
            vm.threeLoadingText=$translate.instant("hint.loadingText");
            imageartAdd($http, item, vm.pageData[vm.pageIndex].trimbox, function(imageart){
                if(vm.repeatAddNumber>4){
                    vm.repeatAddNumber = 0;
                }
                var _num = 10*vm.repeatAddNumber;
                imageart.geometry.x += _num/vm.pageData[vm.pageIndex].custom.ratio1;
                imageart.geometry.y += _num/vm.pageData[vm.pageIndex].custom.ratio1;
                var _level = {
                    seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                    imageart: imageart
                }
                vm.pageData[vm.pageIndex].levels.level.push(_level);
                thumbnailInit();
                vm.threeLoading = false;
                vm.repeatAddNumber++;
            },vm.pageData[vm.pageIndex].custom.ratio1)
        }
        $scope.savePage4 = function () {
            $scope.template.pages.page = vm.pageData;
            $scope.save()
        }
        /*******************************************文字处理*************************************************************************/
        $scope.colorList = ['#ff001e', '#ff5c00', '#ff9700', '#61734d', '#838c97', '#8d4ac8', '#b8e986', '#7e6565', '#ffffff', '#f1e7d9', '#b5ce99', '#82c281', '#dac2ef', '#000', '#797979', '#7c330a', '#eec78d', '#444444', '#589ceb', '#4a7bc8', '#ddabab'];
        $scope.selectedColor = $scope.colorList[0];

        vm.textboxSelect = false;
        textStyleTabInit();
        function textStyleTabInit(){
            vm.textStyleTab = [
                {name: $translate.instant("font.textStyleTabFont"), id: 'textStyleTabFont'},
                {name: $translate.instant("font.textStyleTabSize"), id: 'textStyleTabSize'},
                {name: $translate.instant("font.textStyleTabType"), id: 'textStyleTabType'},
                {name: $translate.instant("font.textStyleTabColor"), id: 'textStyleTabColor'},
            ];
        }
        vm.textStyleItem = 'textStyleTabFont';
        //添加文字
        $scope.fontShow = function () {
            vm.page5 = true;
            vm.fontTab = 'fontColor';
            $scope.selectedColor = $scope.colorList[0];

            // var _level ={
            //     seq: vm.pageData[vm.pageIndex].levels.level.length+1,
            //     textbox: textbox
            // }
            var _size = Math.floor(vm.pageData[vm.pageIndex].trimbox.height/8);

            vm.fontData = {
                seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                textbox: {
                    type: 'add',
                    geometry: {
                        x: '0',
                        y: '0',
                        width: 100,
                        height: 20
                    },
                    rotation: {
                        angle: '0'
                    },
                    text: '',
                    style: {
                        font: "",
                        size: _size,
                        color: $scope.selectedColor,
                        bold: false,
                        italic: false,
                        lineheight: _size,
                        letterspacing: 0,
                        align: 'left',
                        writingmode:'horizontal-tb'
                    },
                    fontuuid: vm.fontItem.uuid,
                    identifier: "",
                    editable: true,
                    movable: true,
                    lock: false,
                    version: '2.0',
                    transparency: 1,
                    useradded: true
                }
            }
            var _fontStyle = [];
            _fontStyle.push(vm.fontItem.fontStyles[j].style)
            if (_fontStyle.indexOf('NORMAL') != '-1') {
                vm.fontData.textbox.style.bold = false;
                vm.fontData.textbox.style.italic = false;
            } else {
                if (_fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    vm.fontData.textbox.style.bold = true;
                    vm.fontData.textbox.style.italic = true;
                } else {
                    if (_fontStyle.indexOf('ITALIC') != '-1') {
                        vm.fontData.textbox.style.italic = true;
                        vm.fontData.textbox.style.bold = false;
                    }
                    if (_fontStyle.indexOf('BOLD') != '-1') {
                        vm.fontData.textbox.style.bold = true;
                        vm.fontData.textbox.style.italic = false;
                    }
                }
            }
            getFontCSS(FontManagement, vm.fontData.textbox, $scope);
            vm.text = '';
            $timeout(function () {
                $('textarea.three-font-body-input').focus()
            },100);
        };
        //切换字体
        $scope.updateTextFont = function (data) {
            if (data == undefined) {
                threeInfo($translate.instant("font.failedAddText"));
                return
            }
            if (!data.enable) {
                threeInfo($translate.instant("font.fontChange"));
                return
            }
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            vm.fontStyle = [];
            for (var i = 0; i < vm.fontList.length; i++) {
                if (vm.fontList[i].uuid == data.uuid) {
                    for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                        vm.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold = false;
            text.style.italic = false;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.bold = false;
                text.style.italic = false;
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    text.style.bold = true;
                    text.style.italic = true;
                } else {
                    if (vm.fontStyle.indexOf('ITALIC') != '-1') {
                        text.style.italic = true;
                    }
                    if (vm.fontStyle.indexOf('BOLD') != '-1') {
                        text.style.bold = true;
                    }
                }
            }
            text.style.font = data.family;
            text.fontuuid = data.uuid;
            text.identifier = $scope.domain + data.fontStyles[0].identifier;
            text.loading = true;
            FontSupport2(FontManagement, text, function (res) {
                var msg = res.data;
                if (res.status == 200) {
                    text.text = msg.passed;
                    if (!msg.success) {
                        swal({
                            title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>" + $translate.instant("font.warmPrompt") + "</div>",
                            text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>" + $translate.instant("font.pleaseChangeFonts") + "</div>",
                            html: true,
                            confirmButtonText: $translate.instant("button.returnCorrection")
                        });
                    }else if (msg.success && !msg.supported) {
                        swal({
                            title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>" + $translate.instant("font.warmPrompt") + "</div>",
                            text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>" + $translate.instant("font.notSpecialCharacter") + "<br><span style='color:#FFA91B;font-size: 14px'>" + msg.failed + "</span></div>",
                            html: true,
                            confirmButtonText: $translate.instant("button.returnCorrection")
                        });
                        text.loading = false;
                    } else {
                        getFontCSS(FontManagement, text, $scope);
                        $timeout(function () {
                            sizeHeight();
                            fontStyle(data.uuid);
                            thumbnailInit();
                        })
                    }
                }
            })
        };

        function fontStyle(fontuuid) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            vm.fontStyle = [];
            for (var i = 0; i < vm.fontList.length; i++) {
                if (vm.fontList[i].uuid == fontuuid) {
                    for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                        vm.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold1 = false;
            text.style.italic1 = false;
            if (vm.fontStyle.indexOf('NORMAL') == '-1') {
                text.style.normal = false;
            } else {
                text.style.normal = true;
            }
            if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                text.style.bold1 = true;
                text.style.italic1 = true;
            } else {
                if (vm.fontStyle.indexOf('ITALIC') != '-1') {
                    text.style.italic1 = true;
                }
                if (vm.fontStyle.indexOf('BOLD') != '-1') {
                    text.style.bold1 = true;
                }
            }
        }

        window.densityInput = function (e) {
            var val = $('#rangeChange').val();
            if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.size = parseInt(val);
                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.lineheight = parseInt(val);
            }

            var currentPage = vm.pageData[vm.pageIndex].levels.level;
            var item = currentPage[vm.levelIndex].textbox;

            if (item.geometry.width < parseInt(item.style.size)) {
                item.geometry.width = (Number(parseInt(item.style.size)) + Number(5));
            }
            if (item.geometry.width > vm.pageData[vm.pageIndex].trimbox.width) {
                item.geometry.width = vm.pageData[vm.pageIndex].trimbox.width;
                // item.geometry.x = 0;
            }
            sizeHeight();
            thumbnailInit();
            $scope.$digest();
        }
        window.textStyleTransparency = function (e) {
            var val = $('#textStyleTransparency').val();
            if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.transparency = parseInt(val) / 100;
                vm.transparency = parseInt(val);
            }
        }
        //斜体
        $scope.operationItalic = function () {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.italic = !text.style.italic;
                $timeout(function () {
                    sizeHeight();
                }, 200)
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.fontStyle.indexOf('BOLD') != '-1') {
                    if (text.style.bold) {
                        text.style.italic = !text.style.italic;
                        $timeout(function () {
                            sizeHeight();
                        }, 200)
                    }
                }
            }
        }
        //粗体
        $scope.operationBold = function () {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.bold = !text.style.bold;
                $timeout(function () {
                    sizeHeight();
                }, 200)
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.fontStyle.indexOf('ITALIC') != '-1') {
                    if (text.style.italic) {
                        text.style.bold = !text.style.bold;
                        $timeout(function () {
                            sizeHeight();
                        }, 200)
                    }
                }
            }
        }
        $scope.operationBarDir = function (item) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            text.style.align = item;
            thumbnailInit();
        };
        $scope.fontColorCb = function (item) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            text.style.color = item;
            thumbnailInit();
        }
        $scope.textLayout = function(item){
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            var text1 = angular.copy(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox);
            var _writingmode = angular.copy(text.style.writingmode);
            text.style.writingmode = item;
            if(writingmodeInit(_writingmode) && writingmodeInit(text.style.writingmode)){
            }else{
                text.geometry.width = text1.geometry.height;
                text.geometry.height = text1.geometry.width;
            }
            thumbnailInit();
        };
        vm.fontItem = null;
        function getAllFont() {
            vm.fontItem = null;
            FontManagement.getAll({}, function (res) {
                vm.fontList = res;
                if(vm.fontList && vm.fontList.length>0){
                    vm.fontItem = vm.fontList[0];
                    vm.fontList.forEach(function (item){
                        if(item.defaultShow){
                            vm.fontItem = item;
                        }
                    })
                }
            });
        }

        //判断是ios or Android
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

        var textFlag = true;
        var inputEle = document.getElementById('three-font-body-input');

        // 兼容安卓文字输入（首行空格不显示bug）
        $scope.changeText = function (e) {
            if (isAndroid == true && isiOS == false) {
                fn(e);
                var targ = e.currentTarget ? e.currentTarget : e.srcElement;
                vm.text = vm.fontData.textbox.text = targ.value;
            }
        }

        /**
         * 兼容iOS文字输入
         */
        inputEle.addEventListener('compositionstart', function () {
            textFlag = false;
        });
        inputEle.addEventListener('compositionend', function (event) {
            textFlag = true;
            // compositionend 事件发生在 input 之后，故此需要此处调用 input 中逻辑
            if (isAndroid == false && isiOS == true) {
                vm.text = event.target.value;
            }
        });
        inputEle.addEventListener('input', function (event) {
            // 忽略一切非直接输入，不做逻辑处理
            if (textFlag) {
                if (isAndroid == false && isiOS == true) {
                    vm.text = event.target.value;
                }
            };
        });

        $scope.savePage5 = function () {
            if (!vm.fontData.textbox.text) {
                vm.page4 = true;
                vm.page5 = false;
                return
            }
            if(vm.text){
                vm.fontData.textbox.text =  vm.text;
            }
            FontSupport2(FontManagement, vm.fontData.textbox, function (res) {
                var msg = res.data;
                if (res.status == 200) {
                    // vm.fontData.textbox.text = msg.passed;
                    if (msg.success && !msg.supported) {
                        swal({
                            title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>" + $translate.instant("font.warmPrompt") + "</div>",
                            text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>" + $translate.instant("font.notSpecialCharacter") + "<br><span style='color:#FFA91B;font-size: 14px'>" + msg.failed + "</span></div>",
                            html: true,
                            confirmButtonText: $translate.instant("button.returnCorrection")
                        });
                    } else {
                        vm.page4 = true;
                        vm.page5 = false;
                        vm.fontData.textbox.style.color = $scope.selectedColor;
                        // vm.fontData.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                        // vm.fontData.textbox.geometry.height = (Number($("#c-xml-page-material-fontId").height()) + Number(10));
                        // vm.fontData.textbox.geometry.width = Number(($("#c-xml-page-material-fontId").width() * ( vm.windowDpi / 72) + Number(10)) / vm.pageData[vm.pageIndex].custom.ratio1 * (1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1 > 1 ? 1 : 1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1));
                        // vm.fontData.textbox.geometry.height = Number(($("#c-xml-page-material-fontId").height() * ( vm.windowDpi / 72) + Number(5)) / vm.pageData[vm.pageIndex].custom.ratio1) * (1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1 > 1 ? 1 : 1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1);
                        if (vm.fontData.textbox.type == 'add') {
                            vm.fontData.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                            vm.fontData.textbox.geometry.x = (vm.pageData[vm.pageIndex].trimbox.width - vm.fontData.textbox.geometry.width) / 2 + vm.pageData[vm.pageIndex].trimbox.x;
                            vm.fontData.textbox.geometry.y = (vm.pageData[vm.pageIndex].trimbox.height - vm.fontData.textbox.geometry.height) / 2 + vm.pageData[vm.pageIndex].trimbox.y;
                            vm.pageData[vm.pageIndex].levels.level.push(vm.fontData)
                            vm.levelIndex = vm.pageData[vm.pageIndex].levels.level.length-1;
                        }
                        if (vm.fontData.textbox.type == 'edit') {
                            // if (vm.fontData.textbox.geometry.width >= vm.pageData[vm.pageIndex].trimbox.width) {
                            //     // vm.fontData.textbox.geometry.width = vm.pageData[vm.pageIndex].trimbox.width;
                            //     vm.fontData.textbox.geometry.x = vm.pageData[vm.pageIndex].trimbox.x;
                            // }
                            // if (vm.fontData.textbox.geometry.height >= vm.pageData[vm.pageIndex].trimbox.height) {
                            //     // vm.fontData.textbox.geometry.height = vm.pageData[vm.pageIndex].trimbox.height
                            //     vm.fontData.textbox.geometry.y = vm.pageData[vm.pageIndex].trimbox.y;
                            // }
                            vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] = vm.fontData;
                        }
                        sizeHeight1(function () {
                            thumbnailInit();
                        })
                    }
                }
            })
        };
        //选中文字
        // $scope.textboxSelectCb = function (e, index) {
        //     console.log('dfafaskldfsjkdaf;ljka')
        //     fn(e);
        //     vm.levelIndex = index;
        //     vm.levelType = 'textbox';
        // }
        var isTextbox = false;
        //删除文字
        $scope.deleteTextbox = function (e) {
            fn(e)
            if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                vm.pageData[vm.pageIndex].levels.level.splice(vm.levelIndex, 1);
                vm.levelIndex = null;
                vm.levelType = null;
                $scope.makeBody();
                thumbnailInit();
            }
        }
        $scope.editTextbox = function (e, index, item) {
            fn(e)
            vm.page5 = true;
            vm.fontData = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex];
            vm.fontData.textbox.type = 'edit';
            $scope.selectedColor = vm.fontData.textbox.style.color;
            vm.fontTab = 'fontColor';
            vm.text = vm.fontData.textbox.text;
            $timeout(function () {
                $('textarea.three-font-body-input').focus()
            },100);
        }
        //文字样式
        $scope.textStyleTextbox = function () {
            vm.textStyleShow = true;
        }


        function sizeHeight() {
            sizeHeight1();
            // $timeout(function () {
            //     var _h = Number($('.pitch1').find('.c-xml-page-textboxe-text span').height()) + 5;
            //     vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h;
            //     $scope.$digest();
            // })
        }
        vm.sizeHeight1 = sizeHeight1;
        function sizeHeight1(callback) {
            $timeout(function () {
                var _h = Number($("#c-xml-page-material-fontId1 span").height()) + Number(10);
                var _w = Number($("#c-xml-page-material-fontId1 span").width()) + Number(10);
                if(!writingmodeInit(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h;
                }
                if(writingmodeInit(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width = _w;
                }
                if(callback){callback()}
                $scope.$digest();
            })
        }
        //字体样式
        $scope.textStyleTabCb = function (item) {
            vm.textStyleItem = item.id;
        }
        $scope.textStyleHide = function () {
            vm.textStyleShow = false;
        }

        $scope.writingmodeInit = function (item) {
            if(item == 'vertical-lr' || item == 'vertical-rl'){
                return true
            }else{
                return false
            }
        }
        /*******************************************照片框处理*************************************************************************/

        //展开
        vm.isModeOut = false;
        $scope.modeIn = function () {
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if (_imagebox.bokehtype != 'None') {
                if (_imagebox.image && _imagebox.image.resource.identifier) {
                    imageboxModeIn(_imagebox, function (imagebox) {
                        vm.isModeOut = true;
                        thumbnailInit();
                    })
                }
            }
        };
        //缩放
        $scope.modeOut = function () {
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if (_imagebox.bokehtype == 'None') {
                if (_imagebox.image && _imagebox.image.resource.identifier) {
                    imageboxModeOut(_imagebox, function (imagebox) {
                        vm.isModeOut = false;
                        getBackgroundSource3(_imagebox, _imagebox.geometry.width, _imagebox.geometry.height, _imagebox.image, _imagebox.bokehtypes, $scope, function () {
                            thumbnailInit();
                        });
                    })
                }
            }
        };


        $scope.cropImageboxMassive = function () {
            imageboxMassive(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, vm.pageData[vm.pageIndex]);
            thumbnailInit();
            warnIcon()
        }
        $scope.cropImageboxMin = function () {
            imageboxMin(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, vm.pageData[vm.pageIndex]);
            thumbnailInit();
            warnIcon()
        }
        $scope.cropImageboxRotate = function (flag) {
            imageboxRotate($scope, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, flag);
            $timeout(function () {
                thumbnailInit();
            },1000)
        }
        $scope.cropImagRestore = function () {
            imagRestore($scope, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, $http);
            $timeout(function () {
                thumbnailInit();
            },1000)
            warnIcon()
        }
        $scope.overturnImagebox = function () {
            imageboxOverturn(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox);
            $timeout(function () {
                thumbnailInit();
            },1000)
        }

        vm.mattingShow = false;
        vm.filterShow = false;
        vm.filterType='';
        vm.filterList=[
            {img:'content/images/filter1.png',type:''},
            {img:'content/images/filter2.png',type:'selfie_anime'},
            {img:'content/images/filter3.png',type:'wave'},
            {img:'content/images/filter4.png',type:'warm'},
            {img:'content/images/filter5.png',type:'scream'},
            {img:'content/images/filter6.png',type:'gothic'},
            {img:'content/images/filter7.png',type:'lavender'},
        ];
        vm.mattingList=[
            {img:'content/images/filter8.png',type:''},
            {img:'content/images/filter9.png',type:'body_seg'},
        ];
        $scope.filterCb = function(item){
            if(!vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox || !vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image || !vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier){
                return;
            }
            if(item.type == vm.filterType){
                return;
            }
            vm.threeLoading = true;
            vm.threeLoadingText = $translate.instant("hint.loadingText");
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if(_imagebox.image.resourceid){
                Gallery.getOne({id:_imagebox.image.resourceid},function (res) {
                    // if(res.imgSize.size / 1024 < 1024*4){
                    //     threeInfo("图片不能大于4M")
                    //     return
                    // }
                    var _identifier = $scope.domain + res.data.identifier;
                    if(!item.type){
                        vm.filterType=item.type;
                        vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = _identifier;
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                        thumbnailInit();
                    }else {
                        Convert.imageEnhance({
                            imageUrl: _identifier,
                            type: item.type
                        }, function (resp) {
                            if(resp.status == 200){
                                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = $scope.domain + resp.message;
                                vm.filterType=item.type;
                                thumbnailInit();
                            }else{
                                threeInfo(resp.message)
                            }
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                        }, function () {
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                            threeInfo($translate.instant("editorCommon.loadError"))
                        });
                    }
                }, function () {
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    threeInfo($translate.instant("editorCommon.loadError"))
                })
            }else {
                if(!_imagebox.image.resource.identifier1){
                    _imagebox.image.resource.identifier1 = angular.copy(_imagebox.image.resource.identifier);
                }
                if(!item.type){
                    vm.filterType=item.type;
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = _imagebox.image.resource.identifier1;
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    thumbnailInit();
                }else {
                    Convert.imageEnhance({
                        imageUrl: _imagebox.image.resource.identifier1,
                        type: item.type
                    }, function (resp) {
                        if(resp.status == 200){
                            vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = $scope.domain + resp.message;
                            vm.filterType=item.type;
                            thumbnailInit();
                        }else{
                            threeInfo(resp.message)
                        }
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                    }, function () {
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                        threeInfo($translate.instant("editorCommon.loadError"))
                    });
                }
            }
        };
        function threeInfo(item){
            vm.threeError = true;
            vm.threeErrorText=item;
            $timeout(function () {
                vm.threeError = false;
                vm.threeErrorText="";
            },1500)
        }
        $scope.mattingImage = function () {
            vm.mattingShow = true;
        };
        $scope.cutImage = function () {
            $timeout(function () {
                vm.filterShow = true;
            })
        };
        $scope.filterChoose = function (item) {
            switch (item) {
                case 'selfie_anime':
                    return $translate.instant("editorBtn.selfieAnime");
                    break;
                case 'warm':
                    return $translate.instant("editorBtn.warm");
                    break;
                case 'wave':
                    return $translate.instant("editorBtn.wave");
                    break;
                case 'lavender':
                    return $translate.instant("editorBtn.lavender");
                    break;
                case 'scream':
                    return $translate.instant("editorBtn.scream");
                    break;
                case 'gothic':
                    return $translate.instant("editorBtn.gothic");
                    break;
                case 'body_seg':
                    return $translate.instant("editorBtn.bodySeg");
                    break;
                default:
                    return $translate.instant("editorBtn.originalPainting");
            }
        }

        var dragGal = {};
        var drag = false;
        var startChangeTimeout;
        vm.isSmallPic = false;
        window.startChange = function (e) {
            startChangeTimeout = setTimeout(function() {
                vm.isSmallPic = true;
                $scope.$digest();
            }, 200);
            drag = true;
            var i = e.target.dataset.imgindex;
            $scope.galleryArr = vm.photoAll[i];
            $scope.galleryChoose = vm.photoAll[i].identifier;

            $scope.galleryId = i;
            $scope.galleryX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX - 53 : 0;
            $scope.galleryY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY - 40 : 0;
            dragGal.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
            dragGal.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
            dragGal.pageX = e.changedTouches[0].pageX ? e.changedTouches[0].pageX : 0;
            dragGal.pageY = e.changedTouches[0].pageY ? e.changedTouches[0].pageY : 0;
            dragGal.coordX = $scope.galleryX;
            dragGal.coordY = $scope.galleryY;
            $scope.$digest();
            return false;
        };

        window.dragChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (drag) {
                //手指离开屏幕
                $scope.galleryX = (dragGal.coordX + (e.changedTouches[0].clientX - dragGal.clientX));
                $scope.galleryY = (dragGal.coordY + (e.changedTouches[0].clientY - dragGal.clientY));

                // if (Math.abs($scope.galleryY - dragGal.coordY) > 10) {
                //     vm.isSmallPic = true;
                // }

                //向上滑动;
                if ($scope.galleryY <= 0) {
                    $scope.galleryY = 0;
                }
                //向左滑动;        <-----
                if ($scope.galleryX <= 0) {
                    $scope.galleryX = 0;
                }
                //向下滑动;
                if ($scope.galleryY >= $scope.dHeight - 62) {
                    $scope.galleryY = $scope.dHeight - 62;
                }
                // 向右滑动;    ---->
                if ($scope.galleryX >= $scope.dWidth - 62) {
                    $scope.galleryX = $scope.dWidth - 62;
                }

                var _picTop = $("#small-pic").offset().top;
                var _picLeft = $("#small-pic").offset().left;
                for (var i1 = 0; i1 < vm.pageData[vm.pageIndex].levels.level.length; i1++) {
                    var _level = vm.pageData[vm.pageIndex].levels.level[i1];
                    if (_level.imagebox) {
                        var _imageboxLeft = $(".imagebox" + i1).offset().left;
                        var _imageboxTop = $(".imagebox" + i1).offset().top;
                        var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                        var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                        if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight)) {
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(103, 106, 108, 0.8)")
                            break;
                        }else{
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(0,0,0,0.2)")
                        }
                    }
                }
                $scope.$digest();
                return false;
            }
        };

        window.stopChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (!drag) {
                return
            }
            drag = false;
            var _picTop = $("#small-pic").offset().top;
            var _picLeft = $("#small-pic").offset().left;
            for (var i1 = 0; i1 < vm.pageData[vm.pageIndex].levels.level.length; i1++) {
                var _level = vm.pageData[vm.pageIndex].levels.level[i1];
                if (_level.imagebox) {
                    var _imageboxLeft = $(".imagebox" + i1).offset().left;
                    var _imageboxTop = $(".imagebox" + i1).offset().top;
                    var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                    var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                    if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight)) {
                        $scope.replaceImagebox1($scope.galleryArr, i1)
                        break;
                    }
                }
            }
            vm.isSmallPic = false;
            $scope.$digest();
        };


        $scope.addImageboxImg = function (item) {
            if(vm.isAddImagebox){
                imageboxAddFn(item)
            }else{
                if(vm.pageData[vm.pageIndex] && vm.pageData[vm.pageIndex].levels.level && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox){
                    replaceImagebox(item)
                }else{
                    for (var i = 0; i < vm.pageData[vm.pageIndex].levels.level.length; i++) {
                        var _level = vm.pageData[vm.pageIndex].levels.level[i];
                        if (_level.imagebox && _level.imagebox.image && !_level.imagebox.image.resource.identifier) {
                            vm.levelIndex = i;
                            replaceImagebox(item, function () {
                                vm.levelIndex = null;
                                vm.levelType = null;
                            })
                            break;
                        }
                    }
                }
            }
        };
        $scope.replaceImagebox1 = function (data, index) {
            vm.levelIndex = index;
            replaceImagebox(data)
        }

        function replaceImagebox(data, callback) {
            var item = angular.copy(data);
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            var identifier = _imagebox.mask.resource.identifier;
            // $.ajax({
            //     type: "GET",
            //     url: item.identifier + "?imageInfo",
            //     success: function (va) {
            //         var format = va.format.toLowerCase();
            //         if (format == 'heic') {
            //             swal({
            //                 title: "照片格式错误",
            //                 text: "请更换使用JPG格式图片添加至相框！",
            //                 timer: 2000,
            //                 showConfirmButton: false
            //             });
            //             return
            //         }

                    _imagebox.image = {
                        resource: {
                            identifier: item.identifier,
                            provider: 'qiniu'
                        },
                        resourceid:item.sourceId,
                        filterResource: {
                            identifier: '',
                            provider: 'qiniu'
                        },
                        width: item.width,
                        height: item.height,
                        imgSize: item.imgSize,
                        index: item.seq,
                        parameter: angular.fromJson(item.parameter),
                        scale: 1,
                        angle: 0
                    }
                    addImagebox($http, _imagebox, function () {
                        imageSelectInit();
                        warnIcon();
                        thumbnailInit();
                            if(callback){callback()}
                    });
            //     }
            // });
        }

        function imageSelectInit() {
            vm.photoSelectAll = [];
            for (var i = 0; i < vm.pageData.length; i++) {
                for (var j = 0; j < vm.pageData[i].levels.level.length; j++) {
                    var _level = vm.pageData[i].levels.level[j];
                    if (_level.imagebox) {
                        for (var k = 0; k < vm.photoAll.length; k++) {
                            if (_level.imagebox.image && _level.imagebox.image.resource && _level.imagebox.image.resource.identifier == vm.photoAll[k].identifier) {
                                vm.photoSelectAll.push(vm.photoAll[k]);
                                break
                            }
                        }
                    }
                }
            }
        }


        //保存
        function articleOverdue(callback){
            Article.get({id: vm.article.id}, function (article) {
                if(article.data.fileName != vm.article.fileName){
                    MessageService.minConfirm({
                        cancel:$translate.instant("button.cancel"),
                        confirm:$translate.instant("button.refresh"),
                        msg:$translate.instant("hint.articleOverdue")
                    },function(){
                        $state.go("product-choosesThree-ec", {storeId: vm.storeId, aid:  vm.article.id},{reload:true});
                    })
                }else{
                    callback();
                }
            })
        }
        $scope.isSave = false;
        vm.isDocumentTextDetection = false;
        $scope.save = function () {
            articleOverdue(function () {
                if ($scope.isSave) {
                    return;
                }
                var _documentTextDetection = documentTextDetection1($scope.template.pages.page);
                if(!_documentTextDetection.pass){
                    vm.isDocumentTextDetection = true;
                    vm.documentTextDetection = _documentTextDetection.pages;
                    return;
                }
                if ($scope.template.pages.page.length < $scope.PAGELEN) {
                    SweetAlert.swal({
                        title: $translate.instant("editorCommon.loadTemplate"),
                        imageUrl: Theme.sweetHintImg(),
                        text: "",
                        timer: 2000,
                        showConfirmButton: false
                    });
                    return
                }

                if (vm.article.ordered) {
                    SweetAlert.swal({
                        title: $translate.instant("font.warmPrompt"),
                        imageUrl: Theme.sweetHintImg(),
                        text: $translate.instant("editorCommon.newWork"),
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                //判断是否有图片未上传  且是否继续保存
                if (!$scope.isContinue()) {
                    // vm.completed = false;
                    SweetAlert.swal({
                        title: $translate.instant("editorCommon.saveSure"),
                        imageUrl: Theme.sweetHintImg(),
                        showCancelButton: true,
                        confirmButtonText: $translate.instant("button.confirm"),
                        cancelButtonText: $translate.instant("button.cancel"),
                        closeOnConfirm: true,
                        closeOnCancel: true
                    }, function (isConfirm) {
                        if (isConfirm) {
                            $timeout(function () {
                                generateJsonFile();
                            }, 500);
                            return;
                        }
                    })
                } else {
                    // vm.completed = true;
                    generateJsonFile();
                }
            })
        }

        function generateJsonFile() {
            $scope.isSave = true;
            vm.threeLoading = true;
            vm.threeLoadingText=$translate.instant("hint.loadingText");
            thumbnailInit(function () {
                thumbnailInit1(function () {
                    var template = saveData();
                    template.hostinfo = hostInfo();
                    Convert.articleUploadDocument(template, function (res) {
                        vm.article.articleStatus = vm.completed;
                        vm.article.fileName = res.data.identifier;
                        vm.article.id = vm.aid;
                        vm.article.pageCount = $scope.template.pages.page.length;
                        Article.update(vm.article, function (resp) {
                            $scope.isSave = false;
                            vm.threeLoading = false;
                            swal({
                                title: $translate.instant("editorCommon.modifySuccess"),
                                text: $translate.instant("editorCommon.synchronizationOfWorks"),
                                imageUrl: Theme.sweetSuccedImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                            $state.go("product-view5-ec", {storeId: vm.storeId, aid: resp.data.id}, {reload: true});
                        }, function () {
                            $scope.isSave = false;
                            vm.threeLoading = false;
                            swal({
                                title: $translate.instant("editorCommon.saveError"),
                                text: $translate.instant("editorCommon.saveError1"),
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        })
                    }, function () {
                        $scope.isSave = false;
                        vm.threeLoading = false;
                        swal({
                            title: $translate.instant("editorCommon.saveError"),
                            text: $translate.instant("editorCommon.saveError1"),
                            imageUrl: Theme.sweetHintImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                    })
                })
            })
            localStorage.removeItem("photoSelectAll");
        }

        $scope.isContinue = function () {
            var hasEmpty = true;
            for (var i = 0; i < $scope.template.pages.page.length; i++) {
                var _page = $scope.template.pages.page[i];
                if (_page.levels.level) {
                    for (var j = 0; j < _page.levels.level.length; j++) {
                        var _level = _page.levels.level[j];
                        if (_level.imagebox && _level.imagebox.image) {
                            if (_level.imagebox.image.resource && _level.imagebox.image.resource.identifier) {
                            } else {
                                hasEmpty = false;
                                break;
                            }
                        }
                    }
                }
            }
            vm.completed = hasEmpty ? 'COMPLETED' : 'EDITING';
            return hasEmpty;
        };

        function saveData() {
            var _template = angular.copy($scope.template);
            angular.forEach(_template.pages.page, function (page, index) {
                page.editbox = {
                    width: transitionMm(page.editbox.width),
                    height: transitionMm(page.editbox.height),
                    x: transitionMm(page.editbox.x),
                    y: transitionMm(page.editbox.y)
                };
                if(page.editboxes && page.editboxes.editbox){
                    page.editboxes.editbox.forEach(function (editbox, editboxIndex) {
                        page.editboxes.editbox[editboxIndex] = {
                            width: transitionMm(editbox.width),
                            height: transitionMm(editbox.height),
                            x: transitionMm(editbox.x),
                            y: transitionMm(editbox.y)
                        }
                    })
                }
                page.mediabox = {
                    width: transitionMm(page.mediabox.width),
                    height: transitionMm(page.mediabox.height),
                    x: transitionMm(page.mediabox.x),
                    y: transitionMm(page.mediabox.y)
                };
                page.trimbox = {
                    width: transitionMm(page.trimbox.width),
                    height: transitionMm(page.trimbox.height),
                    x: transitionMm(page.trimbox.x),
                    y: transitionMm(page.trimbox.y)
                };
                page.custom = {};
                if (page.scene) {
                    page.scene.geometry = {
                        width: transitionMm(page.scene.geometry.width),
                        height: transitionMm(page.scene.geometry.height),
                        x: transitionMm(page.scene.geometry.x),
                        y: transitionMm(page.scene.geometry.y)
                    };
                    page.scene.imagebox.geometry = {
                        width: transitionMm(page.scene.imagebox.geometry.width),
                        height: transitionMm(page.scene.imagebox.geometry.height),
                        x: transitionMm(page.scene.imagebox.geometry.x),
                        y: transitionMm(page.scene.imagebox.geometry.y)
                    };
                }
                //条形码
                if (page.barcode) {
                    page.barcode.geometry = {
                        width: transitionMm(page.barcode.geometry.width),
                        height: transitionMm(page.barcode.geometry.height),
                        x: transitionMm(page.barcode.geometry.x),
                        y: transitionMm(page.barcode.geometry.y)
                    };
                }
                if (page.qrcode) {
                    page.qrcode.geometry = {
                        width: transitionMm(page.qrcode.geometry.width),
                        height: transitionMm(page.qrcode.geometry.height),
                        x: transitionMm(page.qrcode.geometry.x),
                        y: transitionMm(page.qrcode.geometry.y)
                    };
                }

                //背景
                if (page.background.resource.identifier) {
                    page.background.offsetx = transitionMm(page.background.offsetx);
                    page.background.offsety = transitionMm(page.background.offsety);
                }
                angular.forEach(page.levels.level, function (level, i) {
                    if (level.imageart) {
                        level.imageart.rotation.angle = -level.imageart.rotation.angle;
                        level.imageart.offsetx = transitionMm(level.imageart.offsetx);
                        level.imageart.offsety = transitionMm(level.imageart.offsety);
                        level.imageart.width = transitionMm(level.imageart.width);
                        level.imageart.height = transitionMm(level.imageart.height);
                        level.imageart.geometry = {
                            width: transitionMm(level.imageart.geometry.width),
                            height: transitionMm(level.imageart.geometry.height),
                            x: transitionMm(level.imageart.geometry.x),
                            y: transitionMm(level.imageart.geometry.y)
                        };
                    }
                    if (level.imagebox) {
                        level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                        level.imagebox.geometry = {
                            width: transitionMm(level.imagebox.geometry.width),
                            height: transitionMm(level.imagebox.geometry.height),
                            x: transitionMm(level.imagebox.geometry.x),
                            y: transitionMm(level.imagebox.geometry.y)
                        };
                        if (level.imagebox.blurredrectangle) {
                            level.imagebox.blurredrectangle = {
                                width: transitionMm(level.imagebox.blurredrectangle.width),
                                height: transitionMm(level.imagebox.blurredrectangle.height),
                                x: transitionMm(level.imagebox.blurredrectangle.x),
                                y: transitionMm(level.imagebox.blurredrectangle.y)
                            };
                        }
                        if (level.imagebox.image) {
                            level.imagebox.image.width = transitionMm(level.imagebox.image.width);
                            level.imagebox.image.height = transitionMm(level.imagebox.image.height);
                            level.imagebox.image.offsetx = transitionMm(level.imagebox.image.offsetx);
                            level.imagebox.image.offsety = transitionMm(level.imagebox.image.offsety);
                        }
                    }
                    if (level.textbox) {
                        if (!(level.textbox.text == '' || level.textbox.text == null || level.textbox.text == $translate.instant("editorCommon.describe29") || level.textbox.text == '双击输入文字' || level.textbox.text == '请输入文字') && (level.textbox.geometry.width != 0 && level.textbox.geometry.height != 0)) {
                            level.textbox.rotation.angle = -level.textbox.rotation.angle;
                            level.textbox.geometry = {
                                width: transitionMm(level.textbox.geometry.width),
                                height: transitionMm(level.textbox.geometry.height),
                                x: transitionMm(level.textbox.geometry.x),
                                y: transitionMm(level.textbox.geometry.y)
                            };
                        }else{
                            level.textbox = null;
                        }
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        level.textdocument.rotation.angle = -level.textdocument.rotation.angle;
                        level.textdocument.geometry = {
                            width: transitionMm(level.textdocument.geometry.width),
                            height: transitionMm(level.textdocument.geometry.height),
                            x: transitionMm(level.textdocument.geometry.x),
                            y: transitionMm(level.textdocument.geometry.y)
                        };

                        var backg = level.textdocument.background;
                        if (backg.resource.identifier) {
                            backg.offsetx = transitionMm(backg.offsetx);
                            backg.offsety = transitionMm(backg.offsety);
                        }

                        angular.forEach(level.textdocument.levels.level, function (lev, l) {
                            if (lev.imageart && lev.imageart.geometry) {
                                var art = lev.imageart;
                                art.geometry = {
                                    width: transitionMm(art.geometry.width),
                                    height: transitionMm(art.geometry.height),
                                    x: transitionMm(art.geometry.x),
                                    y: transitionMm(art.geometry.y)
                                };
                                art.offsetx = transitionMm(art.offsetx);
                                art.offsety = transitionMm(art.offsety);
                            }
                            if (lev.textbox && lev.textbox.geometry) {
                                var text = lev.textbox;
                                text.geometry = {
                                    width: transitionMm(text.geometry.width),
                                    height: transitionMm(text.geometry.height),
                                    x: transitionMm(text.geometry.x),
                                    y: transitionMm(text.geometry.y)
                                };
                            }
                        })
                    }
                })
            })
            _template.version= '3.0';
            return _template;
        }

        function fn($event) {
            var $event = $event || window.event;
            if ($event && $event.preventDefault) {
                $event.preventDefault();
            } else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
        }

        $scope.threeModalHide = function () {
            vm.threeIsModal = false;
            vm.threeModalText = "";
            vm.threeIsModal1 = false;
            vm.threeModalText1 = "";
        }
        $scope.threeModalSave = function () {
            vm.threeIsModal = false;
            vm.threeModalText = "";
            vm.threeIsModal1 = false;
            vm.threeModalText1 = "";
            templateReplace();
        }

        //生成缩略图
        vm.isHtml2canvas = false;
        vm.isThreeView3d1 = false;
        vm.isThreeView3d = true;
        vm.threeView3dW=110;
        vm.threeView3dH=110;
        vm.pageData1 = [];
        vm.threeView3d={
            top: 50,
            right: 5
        }
        $scope.threeView3d1Cb = function () {
            vm.isThreeView3d1=true;
            // vm.threeView3dW=$(window).width();
            // vm.threeView3dH=400;
        }
        $scope.threeView3d1CbHide = function () {
            vm.isThreeView3d1=false;
            // vm.threeView3dW=110;
            // vm.threeView3dH=110;
        }


        var a = null;
        vm.thumbnailInit = thumbnailInit;
        function thumbnailInit(callback) {
            if (vm.productXmlData && vm.productXmlData.modelType === 'THREE') {
                vm.thumbnailPageData = angular.copy(vm.pageData[vm.pageIndex]);
                pageTrimbox(vm.thumbnailPageData, $translate.instant("editorCommon.describe29"))
                if (a !== null) {
                    clearTimeout(a)
                }
                a = setTimeout(function() {
                    $timeout(function () {
                        html2canvas_adapt(document.getElementById('thumbnail-view'), {
                            onrendered: function (canvas) {
                                if(canvas){
                                    var url = canvas.toDataURL("image/png");
                                    var blob = dataURLtoBlob(url);
                                    var formData = new FormData();
                                    formData.append('file', blob);
                                    formData.append('key', '3dThumbnail-' + UidService.get());
                                    formData.append('token', vm.token);
                                    $.ajax({
                                        cache: true,
                                        type: "POST",
                                        url: 'https://upload.qiniup.com/',
                                        data: formData,
                                        async: false,
                                        contentType: false,
                                        processData: false,
                                        success: function (data) {
                                            vm.pageData[vm.pageIndex].pagecover = {
                                                identifier: $scope.domain + data.key,
                                                provider: 'qiniu'
                                            }
                                            vm.isHtml2canvas = true;
                                            vm.pageData1 = angular.copy(vm.pageData);
                                            if(callback){callback()}
                                            $scope.$apply();
                                        },
                                        error: function () {
                                            if (callback) {callback()};
                                        }
                                    })
                                }else{
                                    if (callback) {callback()};
                                }
                            },
                            error:function(){
                                if (callback) {callback()};
                            },
                            letterRendering: true,
                            useCORS: true,
                            width: vm.thumbnailPageData.mediabox.width*vm.thumbnailPageData.custom.thumbnailScale,
                            height: vm.thumbnailPageData.mediabox.height*vm.thumbnailPageData.custom.thumbnailScale,
                            windowWidth: document.body.scrollWidth,
                            windowHeight: document.body.scrollHeight,
                            x: 0,
                            y: 0,
                            backgroundColor: "rgba(0, 0, 0, 0)"
                        });
                    },1000)
                }, 3000)
            }else{
                if(callback){callback()}
            }
        }
        //生成封面图
        function thumbnailInit1(callback) {
            vm.thumbnailPageData1 = angular.copy(vm.pageData[0]);
            pageTrimbox(vm.thumbnailPageData1, $translate.instant("editorCommon.describe29"))
            $timeout(function (){
                html2canvas_adapt(document.getElementById('thumbnail-view1'), {
                    onrendered: function (canvas) {
                        if(canvas){
                            var url = canvas.toDataURL("image/png");
                            var blob = dataURLtoBlob(url);
                            var formData = new FormData();
                            formData.append('file', blob);
                            formData.append('key', '3dThumbnail-' + UidService.get());
                            formData.append('token', vm.token);
                            $.ajax({
                                cache: true,
                                type: "POST",
                                url: 'https://upload.qiniup.com/',
                                data: formData,
                                async: false,
                                contentType: false,
                                processData: false,
                                success: function (data) {
                                    vm.article.covered=true;
                                    vm.article.cover=data.key;
                                    if(callback){callback()};
                                },
                                error: function () {
                                    vm.article.covered=false;
                                    vm.article.cover=null;
                                    if (callback) {callback()};
                                }
                            })
                        }else{
                            vm.article.covered=false;
                            vm.article.cover=null;
                            if (callback) {callback()};
                        }
                    },
                    error:function(){
                        vm.article.covered=false;
                        vm.article.cover=null;
                        if (callback) {callback()};
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: vm.thumbnailPageData1.mediabox.width*vm.thumbnailPageData1.custom.thumbnailScale,
                    height: vm.thumbnailPageData1.mediabox.height*vm.thumbnailPageData1.custom.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            },1000);
        }

        window.threeView3dStart = function (e) {
            fn(e);
            vm.threeView3d1 = angular.copy(vm.threeView3d);
            vm.threeView3d1.x = e.changedTouches[0].clientX != null ? e.changedTouches[0].clientX: 0;          //鼠标到页面左边的距离
            vm.threeView3d1.y = e.changedTouches[0].clientY != null ? e.changedTouches[0].clientY: 0;           //鼠标到页面顶部的距离
            $scope.$digest();
        };
        window.threeView3dOn = function (e) {
            fn(e);
            $timeout(function () {
                var nx = e.changedTouches[0].clientX != null ? e.changedTouches[0].clientX: 0;
                var ny = e.changedTouches[0].clientY != null ? e.changedTouches[0].clientY: 0;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - vm.threeView3d1.x;
                var nt = ny - vm.threeView3d1.y;
                vm.threeView3d.right = vm.threeView3d1.right - nl;
                vm.threeView3d.top = vm.threeView3d1.top + nt;
            })
        }
        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if(res.status == 200){
                vm.imgSize = res.data.imgSize;
                vm.imgDpi = res.data.imgDpi;
            }
        });

        /* -------------------------------------  涂鸦  ----------------------------------------*/
        vm.isGraffiti = false;
        vm.oldGraffitiMagnify = false;
        $scope.graffitiCb = function () {
            // vm.oldGraffitiMagnify = vm.isEditboxMagnify;
            // if(!vm.isEditboxMagnify){
            //     $scope.editboxMagnify();
            // }
            vm.isGraffiti = true;
            canvasInit()
        };

        //涂鸦
        vm.graffitiColors = ["#000000", "#E0E0E0", "#FFFFFF", "#FFF0F6", "#FFD6E7", "#EFDBFF", "#FF85C0", "#FF7875", "#D46B08", "#FFC069", "#FADB14", "#FFF566", "#D9F7BE", "#95DE64", "#87E8DE", "#096DD9", "#BAE7FF", "#B37FEB", "#722ED1"];
        vm.graffitiColor = '#000000';
        vm.graffitiPage = 1;

        window.graffitiPenChen = function (e) {
            var val = $('#graffitiPen').val();
            vm.graffitiPen = parseInt(val);
            console.log(vm.graffitiPen)
        }

        $scope.graffitiColorSet = function(item){
            vm.graffitiColor = item;
        }
        $scope.graffitiHide = function () {
            vm.isGraffiti = false;
        }
        $scope.graffitiSave = function () {
            vm.isGraffiti = false;
            if(vm.step>-1){
                var canvas = document.getElementById('graffitiCanvas');
                var url = canvas.toDataURL('image/png');
                clearImageEdgeBlank(url, 0, function (img, x, y) {
                    var blob = dataURLtoBlob(img);
                    var formData = new FormData();
                    formData.append('file', blob);
                    formData.append('key', '3dThumbnail-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            var imageart = {
                                resource:{
                                    identifier: $scope.domain + data.key
                                }
                            }
                            imageartAdd($http, imageart, vm.pageData[vm.pageIndex].trimbox, function(imageart){
                                if(vm.pageData[vm.pageIndex].scene){
                                    x -= vm.pageData[vm.pageIndex].scene.imagebox.geometry.x * vm.pageData[vm.pageIndex].custom.ratio1;
                                    y -= vm.pageData[vm.pageIndex].scene.imagebox.geometry.y * vm.pageData[vm.pageIndex].custom.ratio1;
                                }
                                imageart.geometry.x = x / vm.pageData[vm.pageIndex].custom.ratio1;
                                imageart.geometry.y = y / vm.pageData[vm.pageIndex].custom.ratio1;
                                var _level = {
                                    seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                                    imageart: imageart
                                }
                                vm.pageData[vm.pageIndex].levels.level.push(_level);
                                // if(!vm.oldGraffitiMagnify){
                                //     $scope.editboxMagnify();
                                // }
                                thumbnailInit();
                            }, vm.pageData[vm.pageIndex].custom.ratio1, true)
                        }
                    })
                })
            }
        }
        function clearImageEdgeBlank(url, padding, callback) {
            // create canvas
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");

            // create image
            var image = new Image();
            image.onload = draw;
            image.src = url;
            image.crossOrigin = "Anonymous";

            function draw() {
                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                var data = imageData.data;
                var width = imageData.width;
                var height = imageData.height;
                // var width = imageData;
                // var height = imageData;
                // 裁剪需要的起点和终点,初始值为画布左上和右下点互换设置成极限值。
                var startX = width,
                    startY = height,
                    endX = 0,
                    endY = 0;
                /*
                col为列，row为行，两层循环构造每一个网格，
                便利所有网格的像素，如果有色彩则设置裁剪的起点和终点
                */
                for (var col = 0; col < width; col++) {
                    for (var row = 0; row < height; row++) {
                        // 网格索引
                        var pxStartIndex = (row * width + col) * 4;

                        // 网格的实际像素RGBA
                        var pxData = {
                            r: data[pxStartIndex],
                            g: data[pxStartIndex + 1],
                            b: data[pxStartIndex + 2],
                            a: data[pxStartIndex + 3]
                        };

                        // 存在色彩：不透明
                        var colorExist = pxData.a !== 0;

                        /*
                        如果当前像素点有色彩
                        startX坐标取当前col和startX的最小值
                        endX坐标取当前col和endX的最大值
                        startY坐标取当前row和startY的最小值
                        endY坐标取当前row和endY的最大值
                        */
                        if (colorExist) {
                            startX = Math.min(col, startX);
                            endX = Math.max(col, startX);
                            startY = Math.min(row, startY);
                            endY = Math.max(row, endY);
                        }
                    }
                }

                // 右下坐标需要扩展1px,才能完整的截取到图像
                endX += 1;
                endY += 1;

                // 加上padding
                startX -= padding;
                startY -= padding;
                endX += padding;
                endY += padding;

                // 根据计算的起点终点进行裁剪
                var cropCanvas = document.createElement("canvas");
                var cropCtx = cropCanvas.getContext("2d");
                cropCanvas.width = endX - startX;
                cropCanvas.height = endY - startY;
                cropCtx.drawImage(
                    image,
                    startX,
                    startY,
                    cropCanvas.width,
                    cropCanvas.height,
                    0,
                    0,
                    cropCanvas.width,
                    cropCanvas.height
                );

                // rosolve裁剪后的图像字符串
                callback(cropCanvas.toDataURL(), startX, startY);
            }
        }
        $scope.graffitiRetreat = function () {
            if (vm.step >= 0) {
                $timeout(function () {
                    var canvas = document.getElementById('graffitiCanvas');
                    vm.step--;
                    vm.canvasHistory = vm.canvasHistory.slice(0,-1);
                    vm.context.clearRect(0, 0, canvas.width, canvas.height);
                    if(vm.canvasHistory[vm.step]){
                        vm.context.putImageData(vm.canvasHistory[vm.step], 0, 0)
                    }
                })
            }
        }

        vm.canvasHistory = [];
        function canvasInit() {
            $timeout(function () {
                var canvas = document.getElementById('graffitiCanvas');
                vm.context = canvas.getContext('2d');
                canvas.width = vm.pageData[vm.pageIndex].custom.width * vm.pageData[vm.pageIndex].custom.ratio1;
                canvas.height = vm.pageData[vm.pageIndex].custom.height * vm.pageData[vm.pageIndex].custom.ratio1;
                vm.graffitiPen = 3;
                vm.canvasHistory = [];
                vm.step = -1;
                vm.eraserEnabled = false;
                listenToMouse(canvas);
            })
        }
        function drawLine(x1, y1, x2, y2) {
            vm.context.beginPath();
            vm.context.strokeStyle = vm.graffitiColor;
            vm.context.lineCap="round";
            vm.context.moveTo(x1, y1) // 起点
            vm.context.lineWidth = vm.graffitiPen;
            vm.context.lineTo(x2, y2) // 终点
            vm.context.stroke();
            vm.context.closePath()
        }
        function listenToMouse(canvas) {
            var using1 = false;
            var lastPoint = {
                x: undefined,
                y: undefined
            };
            // 特性检测
            canvas.addEventListener("touchstart",function(aaa) {
                fn(aaa);
                $timeout(function () {
                    vm.graffitiPage = 1;
                    var x = aaa.targetTouches[0].pageX - (vm.windowWidth - vm.pageData[vm.pageIndex].custom.width * vm.pageData[vm.pageIndex].custom.ratio1) / 2;
                    var y = aaa.targetTouches[0].pageY - ((vm.windowHeight - vm.pageData[vm.pageIndex].custom.height * vm.pageData[vm.pageIndex].custom.ratio1) / 2 + 50);
                    using1 = true
                    if (vm.eraserEnabled) {
                        vm.context.clearRect(x - 5, y - 5, 10, 10)
                    } else {
                        lastPoint = {
                            "x": x,
                            "y": y
                        }
                    }
                })
            })
            canvas.addEventListener("touchmove",function(aaa){
                fn(aaa);
                var x = aaa.changedTouches[0].pageX - (vm.windowWidth - vm.pageData[vm.pageIndex].custom.width * vm.pageData[vm.pageIndex].custom.ratio1) / 2;
                var y = aaa.changedTouches[0].pageY - ((vm.windowHeight - vm.pageData[vm.pageIndex].custom.height * vm.pageData[vm.pageIndex].custom.ratio1) / 2 + 50);
                if (!using1) {
                    return
                }
                if (vm.eraserEnabled) {
                    vm.context.clearRect(x - 5, y - 5, 10, 10)
                } else {
                    var newPoint = {
                        "x": x,
                        "y": y
                    }
                    drawLine(lastPoint.x, lastPoint.y, newPoint.x, newPoint.y)
                    lastPoint = newPoint
                }
            })
            canvas.addEventListener("touchend",function(event){
                fn(event);
                using1 = false
                vm.step++;
                vm.canvasHistory.push(vm.context.getImageData(0, 0, canvas.width, canvas.height))
            })
        }
        //取色器
        $scope.graffitiPageCb = function (item) {
            vm.graffitiPage = 2;
            $timeout(function () {
                var width = document.body.clientWidth;
                var height = 200;
                var can = document.getElementById('ColorTip');
                var ctx = can.getContext('2d');
                var cur = document.getElementById('cur');
                var curColor = 'rgba(255,0,0,1)';
                can.width = width;
                can.height = 200;
                colorBar();
                colorBox(curColor);
                bind();
                /**
                 *  绘制左边颜色条
                 *  用createLinearGradient命令创建一个gradientBar对象 然后给gradientBar对象填充颜色就行了
                 *  这里从默色(红)开始渐变到红结束
                 */
                function colorBar() {
                    var gradientBar = ctx.createLinearGradient(0, 0, 0, height);
                    gradientBar.addColorStop(0, '#f00');
                    gradientBar.addColorStop(1 / 6, '#f0f');
                    gradientBar.addColorStop(2 / 6, '#00f');
                    gradientBar.addColorStop(3 / 6, '#0ff');
                    gradientBar.addColorStop(4 / 6, '#0f0');
                    gradientBar.addColorStop(5 / 6, '#ff0');
                    gradientBar.addColorStop(1, '#f00');

                    ctx.fillStyle = gradientBar;
                    //绘制出去 x y height width
                    ctx.fillRect(0, 0, 20, height);
                }

                function rgb2hex(rgb) {
                    var aRgb = rgb instanceof Array ? rgb : (rgb.split(',') || [0, 0, 0]);
                    var temp;
                    return [
                        (temp = Number(aRgb[0]).toString(16)).length == 1 ? ('0' + temp) : temp,
                        (temp = Number(aRgb[1]).toString(16)).length == 1 ? ('0' + temp) : temp,
                        (temp = Number(aRgb[2]).toString(16)).length == 1 ? ('0' + temp) : temp,
                    ].join('');
                }

                function hex2rgb(hex) {
                    if (hex.length == 3) {
                        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
                    }
                    return [
                        parseInt(hex[0] + hex[1], 16),
                        parseInt(hex[2] + hex[3], 16),
                        parseInt(hex[4] + hex[5], 16),
                    ].join();
                }

                function putCurDom(color) {
                    if (/([0-9a-f]{3}|[0-9a-f]{6})/i.test(color)) {
                        // hex
                        color = hex2rgb(color);
                    } else if (color instanceof Array) {
                        color = color.join(',');
                    } else if (/\d{1,3}(\,\d{1,3}){2}/i.test(color)) {

                    } else {
                        return;
                    }
                }

                /**
                 *  绘制颜色板块
                 *  addColorStop(stop, color) stop介于0于1之间 表示开始和结束的位置 color在stop的位置需要显示的颜色
                 *  这里画2个渐变对象
                 *      gradientBase从左到右开始渐变
                 *      my_gradient1从上到下开始渐变
                 *  然后让2个重叠在一起 只需要他们的绘画位置不同即可
                 *
                 * @param color 默认颜色(红)
                 */

                function colorBox(color) {
                    //这里x偏30 才能让左上角是白色
                    var gradientBase = ctx.createLinearGradient(30, 0, width + 30, 0);
                    gradientBase.addColorStop(0, 'rgba(255,255,255,1)');
                    gradientBase.addColorStop(1, color);
                    ctx.fillStyle = gradientBase;
                    //画偏30 别挡住颜色条
                    ctx.fillRect(30, 0, width, height);

                    var my_gradient1 = ctx.createLinearGradient(0, 0, 0, width);
                    my_gradient1.addColorStop(0, 'rgba(0,0,0,0)');
                    my_gradient1.addColorStop(1, 'rgba(0,0,0,1)');
                    ctx.fillStyle = my_gradient1;
                    ctx.fillRect(30, 0, width, height);
                }
                //绑定点击事件
                function bind() {
                    var rgbaStr = '#000';
                    can.addEventListener('click', function(e) {
                        var ePos = {
                            x: e.offsetX || e.layerX,
                            y: e.offsetY || e.layerY
                        }
                        if (ePos.x >= 0 && ePos.x < 20 && ePos.y >= 0 && ePos.y < width) {
                            // in
                            rgbaStr = getRgbaAtPoint(ePos, 'bar');
                            colorBox('rgba(' + rgbaStr + ')');
                        } else if (ePos.x >= 30 && ePos.x < 30 + width && ePos.y >= 0 && ePos.y < width) {
                            rgbaStr = getRgbaAtPoint(ePos, 'box');
                        } else {
                            return;
                        }
                        cur.style.left = ePos.x + 'px';
                        cur.style.top = ePos.y + 'px';
                        // cur.style.outlineColor = (rgbaStr[0] > 256 / 2 || rgbaStr[1] > 256 / 2 || rgbaStr[2] > 256 / 2) ? '#000' : '#fff';
                        outColor(rgbaStr);
                    });

                    can.addEventListener("touchmove",function(e){
                        var ePos = {
                            x: parseInt(e.touches[0].pageX),
                            y: parseInt(e.touches[0].pageY-$("#ColorTip").offset().top)
                        };
                        if (ePos.x >= 0 && ePos.x < 20 && ePos.y >= 0 && ePos.y < width) {
                            // in
                            rgbaStr = getRgbaAtPoint(ePos, 'bar');
                            colorBox('rgba(' + rgbaStr + ')');
                        } else if (ePos.x >= 30 && ePos.x < 30 + width && ePos.y >= 0 && ePos.y < width) {
                            rgbaStr = getRgbaAtPoint(ePos, 'box');
                        } else {
                            return;
                        }
                        cur.style.left = ePos.x + 'px';
                        cur.style.top = ePos.y + 'px';
                        // cur.style.outlineColor = (rgbaStr[0] > 256 / 2 || rgbaStr[1] > 256 / 2 || rgbaStr[2] > 256 / 2) ? '#000' : '#fff';
                        outColor(rgbaStr);
                    })
                    can.addEventListener("touchend",function(event){

                    })
                }

                function outColor(rgb) {
                    $timeout(function () {
                        vm.graffitiColor = RGB2Color(rgb[0], rgb[1], rgb[2]);
                    })
                }
                function getRgbaAtPoint(pos, area) {
                    if (area == 'bar') {
                        var imgData = ctx.getImageData(0, 0, 20, width);
                    } else {
                        var imgData = ctx.getImageData(0, 0, can.width, can.height);
                    }

                    var data = imgData.data;
                    var dataIndex = parseInt((pos.y * imgData.width + pos.x) * 4);
                    return [
                        data[dataIndex],
                        data[dataIndex + 1],
                        data[dataIndex + 2],
                        (data[dataIndex + 3] / 255).toFixed(2),
                    ];
                }
            })
        }

        //rgb转16进制
        function RGB2Color(r,g,b) {
            return '#' + byte2Hex(r) + byte2Hex(g) + byte2Hex(b);
        }
        function byte2Hex (n) {
            var nybHexString = "0123456789ABCDEF";
            return String(nybHexString.substr((n >> 4) & 0x0F,1)) + nybHexString.substr(n & 0x0F,1);
        }
        /* -------------------------------------  涂鸦end  ----------------------------------------*/
        $scope.$on('changeLanguage', function ($event,lang) {
            textStyleTabInit();
            getMessage();
            diyEditorSettingConstInit();
            vm.materialData=[];
            getThemeClassify();
            vm.fontList = [];
            productInit();
            if(vm.product.editorType == 'DIY_EDITOR'){
                if(vm.product.diyEditorSettingId){
                    getDiyEditorSetting();
                }else{
                    //获取所有字体
                    getAllFont();
                    diyEditorSettingInit()
                }
            }else{
                //获取所有字体
                getAllFont();
                diyEditorSettingInit()
            }

        });
        //部件名
        $scope.partName = function (partid) {
            if (!$scope.template||!$scope.template.config||!$scope.template.config.parts||!$scope.template.config.parts.list) {
                return
            }
            var partName = "";
            for(var i=0; i<$scope.template.config.parts.list.length; i++){
                if($scope.template.config.parts.list[i].id == partid){
                    partName = $scope.template.config.parts.list[i].name;
                }
            }
            return partName
        }


        // websocket接收上传的图片（多端互通实时更新）
        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.aid);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:'+ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                console.log(photo);
                if (photo.folderId == null) {
                    photo.identifier = $scope.domain + photo.identifier;
                    vm.photoAll.unshift(photo)
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }
    }
})();
