(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('Page404Controller', Page404Controller);

    Page404Controller.$inject = ['$scope', '$stateParams'];

    function Page404Controller($scope, $stateParams) {
        var vm = this;
        vm.tips = "抱歉，该链接已失效";
        console.log($stateParams);
        if($stateParams.tips && typeof $stateParams.tips === "string"){
            vm.tips = $stateParams.tips;
        }
    }
})();
