(function () {
    'use strict';

    var uploadImage2 = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/gallery/components/uplpadImage.html',
        controllerAs: 'userCtrl',
        bindings: {
            photoAll:'=',
            folderId:'=',
            qiniuId:'=',
            uploadPhotoItem:'=',
            aid:'='
        },

        controller: ['$scope', '$stateParams', '$q', '$localStorage', 'Qinius','PlatformWechatSetting', 'Customer', 'WxJS', 'Theme', 'Gallery','SweetAlert','$rootScope','ImageService','UidService','Convert','$timeout',
            function ($scope, $stateParams, $q, $localStorage, Qinius, PlatformWechatSetting, Customer, WxJS, Theme, Gallery, SweetAlert,$rootScope,ImageService,UidService,Convert,$timeout) {
                var vm = this;
                // 成功多少张
                console.log(vm.folderId,'folder');
                vm.indd = 0;
                vm.errorImageArr = [];
                vm.filesLength = 0;
                var repeatCount = 0;
                var cancelledCount = 0;
                var localIdsArr = [];
                if (window.location.href.indexOf("?") > -1) {
                    var r = window.location.href.split('?')[1];
                    var typeFlag = r.split('&')[0].split('=')[1];
                    var distinguish = decodeURIComponent(typeFlag);
                    if (distinguish == 'weChatApplet' || distinguish == 'weChatAppletMake') {
                        vm.uploadType = 'wechat';
                    }else if(distinguish == 'toutiaoApp'){
                        vm.uploadType = 'byteDance';
                    }else if(distinguish == 'alipayApp'){
                        vm.uploadType = 'ali';
                    }
                }else{
                    vm.uploadType = 'wechat';
                }
                function addAlertClass() {
                    setTimeout(function () {
                        $(".sweet-alert").addClass('folder-alert').addClass('whiteBg');
                    })
                }
                function removeAlertClass() {
                    setTimeout(function () {
                        $(".sweet-alert").removeClass('folder-alert').removeClass('whiteBg');
                        $(".sa-button-container").removeClass('folder-alert-top')
                        $(".sa-button-container .cancel").removeClass('folder-alert-right')
                    },200)
                }

                //生成缩略图
                function dataURLtoBlob(dataurl) {
                    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    return new Blob([u8arr], {type: mime});
                }
                if (wx) {
                    var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
                    // var url = "https://stage.fotavatar.com/10/544/gallery/uploading/1230814023448763";
                    WxJS.get({url: url}, function (config) {
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.data.appId, // 必填，公众号的唯一标识
                            timestamp: config.data.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.data.nonceStr, // 必填，生成签名的随机串
                            signature: config.data.signature,// 必填，签名，见附录1
                            jsApiList: ['chooseImage', 'previewImage', 'uploadImage','getLocalImgData'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function () {
                            // console.log('editor: wechat api ready!');
                        });
                        wx.error(function (res) {
                            // alert("wx.error: "+res.errMsg)
                            // console.log(res);
                        });
                    }, function (res) {
                    });
                }

                $scope.domains = Qinius.getDomain();
                vm.tokenTime = 360000;
                Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });

                vm.storeId = $stateParams.storeId;
                vm.platformSetting = PlatformWechatSetting.get();
                vm.customer = Customer.current();
                $q.all([$scope.domains.$promise]).then(function(){
                    $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                    setTimeout(function fn() {
                        Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
                            vm.token = msg.token;
                            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                        });
                        setTimeout(fn, vm.tokenTime);
                    }, vm.tokenTime);
                    //安卓
                    $scope.$on('clickAndroidUpload', function(event, args) {
                        vm.indd = 0;
                        if(vm.uploadType=='ali'){
                            ap.chooseImage({
                                sourceType: ['camera', 'album'],
                                sizeType: ['original'],
                                count: 9
                            }, function(res){
                                var files = []
                                for (var i = 0; i < res.apFilePaths.length; i++) {
                                    files.push({
                                        localId:res.apFilePaths[i],
                                        blobCover:res.apFilePaths[i],
                                        imgSize:res.tempFiles[i].size
                                    })
                                }
                                vm.indd = 0;
                                vm.errorImageArr = [];
                                vm.filesLength = files.length;
                                $(".save-pages2").removeClass('hide1');

                                angular.forEach(files,function (file1,index1) {
                                    (function (file,index) {
                                        toBase64(file,index)
                                    })(file1,index1)
                                })
                                function toBase64(file,i) {
                                    // 如下演示如何拿到base64格式的数据，可用于上传到服务器端的场景
                                    var image = new Image();
                                    image.crossOrigin = 'anonymous';
                                    image.size = file.imgSize
                                    image.onload = function() {
                                        var canvas = document.createElement('CANVAS');
                                        var context = canvas.getContext('2d');
                                        canvas.height = image.height;
                                        canvas.width = image.width;
                                        context.drawImage(image, 0, 0);
                                        try {
                                            var dataURL = canvas.toDataURL('image/jpeg');
                                            file.blobCover = dataURL;
                                            Convert.downloadImg4AliH5AngUpload7Niu({
                                                base64:dataURL,
                                                type:"gallery"
                                            },function (res) {
                                                uploadGalleries(files,res,i)
                                            })
                                        } catch (e) {
                                            if(i==files.length-1){
                                                updatePic()
                                            }
                                        }
                                        canvas = null;
                                    }
                                    image.src = file.localId;
                                }

                                function uploadGalleries(files,res,i) {
                                    Gallery.upload({
                                        articleId:vm.aid,
                                        identifiers: res.message,
                                        provider: "QINIU",
                                        fileName: files[i].localId,
                                        imgSize: files[i].size,
                                        folderId: vm.folderId ? vm.folderId : '',
                                        check: true,
                                        type: vm.uploadType
                                    }, function (resp) {
                                        if (resp.data.cancelled) {
                                            cancelledCount += 1;
                                        }

                                        files[i].loading = true;
                                        files[i].usenum = 0;
                                        files[i].id = resp.data.id;
                                        files[i].width = resp.data.width;
                                        files[i].height = resp.data.height;
                                        files[i].imgsize = resp.data.imgSize;
                                        files[i].cancelled = resp.data.cancelled;
                                        files[i].identifier = resp.data.identifier;

                                        vm.indd++;
                                        // 上传成功
                                        if (files.length == (vm.indd + vm.errorImageArr.length)) {
                                            $timeout(function () {
                                                $scope.loadingAllSuccess = true;
                                                updatePic();
                                            });
                                        }

                                    },function () {
                                        var arr = {
                                            localId:files[--i].localId,
                                            blobCover:null
                                        }
                                        vm.errorImageArr.push(files[i]);
                                        if (files.length == (vm.indd + vm.errorImageArr.length)) {
                                            $timeout(function () {
                                                $scope.loadingAllSuccess = true;
                                                updatePic();
                                            });
                                        }
                                    })
                                }
                            });
                        }else{

                            /********************************************
                             * 上传图片功能   后台会返回上传图片的日期
                             * @type {boolean}
                             * 图片接口    chooseImage拍照、本地选图
                             ********************************************/
                            //将blob转换为file
                            function blobToFile(blob, fileName) {
                                blob.lastModifiedDate = new Date();
                                blob.name = fileName;
                                return blob;
                            };
                            vm.indd = 0;  //成功的张数
                            vm.errorImageArr = [];  //错误的张数
                            var files = [];
                            // 微信Android选图9张
                            wx.chooseImage({
                                count: 9, // 默认9
                                sizeType: ['original'], // 可以指定是原图还是压缩图，默认二者都有
                                sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                                success: function (res) {
                                    $(".save-pages2").removeClass('hide1');
                                    var localIds = res.localIds;
                                    localIds.forEach(function(localId){
                                        wx.getLocalImgData({
                                            localId: localId,
                                            success: function (res) {
                                                var base64Data = res.localData;
                                                var blob = '';
                                                if (base64Data.indexOf("base64") != -1) {
                                                    blob = dataURLtoBlob(base64Data);
                                                }
                                                else {
                                                    blob = dataURLtoBlob("data:image/jpeg;base64," + base64Data)
                                                }
                                                var file = blobToFile(blob);
                                                files.push(file);
                                                if (files.length == localIds.length) {
                                                    detectionSize();
                                                }
                                            }
                                        });
                                    })
                                },
                                fail: function (res) {
                                    updatePic();
                                }
                            });
                            // 检查图片
                            function detectionSize(){
                                vm.higherSize = [];
                                angular.forEach(files, function (item, index) {
                                    item.imgSize = item.size;
                                    if (item.size / 1024 > 20480) {
                                        vm.higherSize.push(index + 1);
                                    }
                                });
                                //系统检测到{{}}张大于20M的图片，已自动过滤\n请压缩后再尝试上传！
                                if (vm.higherSize.length > 0) {
                                    $scope.bigSizeTip = true;
                                    $scope.higherSizeLen = vm.higherSize.length;
                                    $scope.$digest();
                                } else {
                                    if(files.length > 0){
                                        filterUpload(files);
                                    }
                                }

                                $scope.filterBigSize = function () {
                                    $scope.bigSizeTip = false;
                                    files = files.filter(function (item, index) {
                                        return item.size / 1024 < 20480;
                                    });
                                    if (files.length == 0) {
                                        updatePic();
                                    } else {
                                        filterUpload(files);
                                    }
                                }
                            }
                        }
                    });

                    /**
                     * iOS上传
                     * 使用自带的上传图片功能  不调用微信的jssdk
                     * 非调用微信公众号上传图片 无9张限制 可多张上传 上传到七牛  并且可以返回路径在页面显示
                     * @param $event
                     */
                    window.uploadImageMutiple = function ($event) {
                        $timeout(function () {
                            uploadImageMutipleCb($event)
                        })
                    };
                });


                function uploadImageMutipleCb($event) {
                    $scope.bigSizeTip = false;
                    var files = [];
                    cancelledCount = 0;
                    for (var i = 0; i < $event.target.files.length; i++) {
                        var file = $event.target.files[i];
                        // image/png, image/jpeg, image/jpg
                        if(file.type =='image/png'||file.type =='image/jpeg'||file.type =='image/jpg'){
                            file.usenum = 0;
                            file.percent = 0;
                            file.blobCover = URL.createObjectURL(file);
                            files.push(file)
                        }
                    }
                    if(files.length == 0){
                        return
                    }

                    vm.higherSize = [];
                    angular.forEach(files, function (item, index) {
                        item.imgSize = item.size;
                        if (item.size / 1024 > 20480) {
                            vm.higherSize.push(index + 1);
                        }
                    });
                    //系统检测到{{}}张大于20M的图片，已自动过滤\n请压缩后再尝试上传！
                    if (vm.higherSize.length > 0) {
                        $scope.bigSizeTip = true;
                        $scope.higherSizeLen = vm.higherSize.length;
                        $scope.$digest();
                    } else {
                        filterUpload(files);
                    }

                    $scope.filterBigSize = function () {
                        $scope.bigSizeTip = false;
                        files = files.filter(function (item, index) {
                            return item.size / 1024 < 20480;
                        });
                        if (files.length == 0) {
                            updatePic();
                        } else {
                            filterUpload(files);
                        }
                    }
                }

                function filterUpload(files) {
                    $(".save-pages2").removeClass('hide1');
                    asyncUpload(files);
                }

                function asyncUpload(files) {
                    vm.uploadPhotoItem = files;
                    vm.indd = 0;
                    vm.errorImageArr = [];
                    $rootScope.$broadcast('uploadStart',true);
                    vm.filesLength = vm.uploadPhotoItem.length;
                    qiniuUpload(vm.uploadPhotoItem[0], vm.uploadPhotoItem, 0);

                }

                function qiniuUpload(file, files, index_) {
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);
                    formData.append('key', "gallery-" + UidService.get());
                    formData.append('token', vm.token);
                    file.percent = 20;

                    var reader = new FileReader();
                    reader.readAsArrayBuffer(file)
                    reader.onloadend = function (e) {
                        formData.append('crc32', CRC32.buf(new Uint8Array(this.result)));
                        return $.ajax({
                            // cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: true,
                            contentType: false,
                            processData: false,
                            xhr:function(){
                                var myXhr = $.ajaxSettings.xhr();
                                if(myXhr.upload){ // check if upload property exists
                                    myXhr.upload.addEventListener('progress',function(e){
                                        var loaded = e.loaded;                  //已经上传大小情况
                                        var total = e.total;                      //附件总大小
                                        var percent = Math.floor(100*loaded/total)+"%";     //已经上传的百分比
                                        file.percent = parseInt(100*loaded/total)/2;
                                    }, false); // for handling the progress of the upload
                                }
                                return myXhr;
                            },
                            success: function (data) {
                                vm.uploadPhotoItem[index_].blobCover = $scope.domain +data.key + '?imageView2/1/w/150/h/150';
                                file.percent = 80;
                                if (files.length > Number(index_) + 1) {
                                    var f = files[index_ + 1];
                                    qiniuUpload(f, files, index_ + 1);
                                }
                                assignGalleries(file, files, index_, data)
                            },
                            error: function (request) {
                                file.state = 'error';
                                vm.errorImageArr.push(files[index_]);
                                //最后一个失败了的话
                                if (files.length == (vm.indd + vm.errorImageArr.length)) {
                                    $timeout(function () {
                                        $scope.loadingAllSuccess = true;
                                        updatePic();
                                    })
                                }else{
                                    if (files.length > Number(index_) + 1) {
                                        var f = files[index_ + 1];
                                        qiniuUpload(f, files, index_ + 1);
                                    }
                                }
                            }
                        });
                    }
                }

                function serviceUpload(data, file, callback) {
                    Gallery.upload({
                        identifiers: data.key,
                        provider: "QINIU",
                        fileName: file.name,
                        imgSize: file.size,
                        width: file.width,
                        height: file.height,
                        folderId: vm.folderId ? vm.folderId : '',
                        check: true,
                        type: vm.uploadType,
                        articleId:vm.aid
                    }, function (resp) {
                        file.percent = 100;
                        file.id = resp.data.id;
                        file.cancelled = resp.data.cancelled;
                        vm.indd++;
                        file.state = 'success';
                        $timeout(function () {
                            if (!resp.data.cancelled) {
                                if(vm.photoAll){
                                    vm.photoAll.push(resp.data)
                                }
                                if(vm.qiniuId){
                                    resp.data.identifier = $scope.domain +resp.data.identifier;
                                    resp.data.usenum = 0;
                                    resp.data.loading = 0;
                                    vm.qiniuId.push(resp.data)
                                }
                            }
                            if(callback){callback()}
                        },1000)
                    }, function (error) {
                        file.state = 'error';
                        vm.errorImageArr.push(file);
                        if(callback){callback()}
                    });
                }


                function assignGalleries(file, files, index_, data) {
                    serviceUpload(data, file,function () {
                        if (files.length == (vm.indd + vm.errorImageArr.length)) {
                            $timeout(function () {
                                $scope.loadingAllSuccess = true;
                                updatePic(files);
                            })
                        }else{
                            // if (files.length > Number(index_) + 1) {
                            //     var f = files[index_ + 1];
                            //     qiniuUpload(f, files, index_ + 1);
                            // }
                        }
                    });
                }
                $scope.reUpload = function () {
                    $scope.errorImageArr_.forEach(function (item) {
                        item.percent = 0;
                        item.state = null;
                    })
                    asyncUpload($scope.errorImageArr_);
                    $scope.errorImageHide();
                    $(".save-pages2").removeClass('hide1');
                }
                // 上传图片后更新图片库/文件夹
                function updatePic(files) {
                    $scope.isLookErrorImages = false;
                    $rootScope.$broadcast('uoloadFinished',true,files);
                    vm.uploadPhotoItem = [];
                    $(".save-pages2").addClass('hide1');
                    vm.indd = 0;
                    vm.filesLength = 0;
                    $timeout(function () {
                        //如果没有上传错误图片，关闭提示框
                        if(vm.errorImageArr.length === 0){
                            $scope.loadingAllSuccess = false;
                            if(!$scope.isLookErrorImages){
                                vm.errorImageArr = [];
                            }
                        }
                    },2000)

                }
                //关闭上传错误弹窗
                $scope.closeErrorMoadl = function (){
                    var target = event.target;
                    if($(target).hasClass("z-modal") || $(target).hasClass("close-btn") || $(target).hasClass("close-icon1")){
                        $scope.loadingAllSuccess = false;
                    }

                }
                //上传进度获取
                $scope.schedule = function () {
                    if(vm.filesLength > 0){
                        return Number(vm.indd)+vm.errorImageArr.length + '/' + vm.filesLength
                    }
                }
                $scope.errorImageArrCb = function () {
                    return vm.errorImageArr.length
                }
                $scope.successImageArrCb = function () {
                    return vm.indd
                }
                $scope.isLookErrorImages = false;
                $scope.lookErrorImages = function () {
                    $scope.isLookErrorImages = true;
                    $scope.loadingAllSuccess = false;
                    $scope.errorImageArr_ = vm.errorImageArr;
                    $scope.errorImageArr_.forEach(function (item) {
                        item.percent = 0;
                    })
                };
                $scope.errorImageHide = function () {
                    $scope.isLookErrorImages = false;
                }

                function threeInfo(item){
                    $scope.threeError = true;
                    $scope.threeErrorText=item;
                    $timeout(function () {
                        $scope.threeError = false;
                        $scope.threeErrorText="";
                    },1500)
                }
            }]
    };

    angular
        .module('editorApp')
        .component('uploadImage2', uploadImage2);
})();
