(function () {
    'use strict';

    var PagePhotoControl = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/page-photo.html',
        controllerAs: 'vm',
        bindings: {
            pageList: '<',
            pageIndex: '<',
            template: '<',
            domain: '<',
            imgSize: '<',
            imgDpi: '<',
            editorType: '<',
            onChange: '&'
        },
        controller: ['$scope', 'ModulesService', 'FontManagement', '$timeout', '$http', '$translate', 'MessageService','ThemeClassify','FontXmlsManger','ClipArt',
            function ($scope, ModulesService, FontManagement, $timeout, $http, $translate, MessageService,ThemeClassify,FontXmlsManger,ClipArt) {
                var vm = this;
                vm.$onChanges = function (data){
                    vm.pages = angular.copy(vm.pageList);
                    vm.levelIndex = 0;
                    vm.menuIndex = 3;
                    vm.elementOn = {
                        index:0,
                        pageIndex:0,
                        name:'',
                        item:null
                    };
                    vm.windowH = $('.page-photo-view3-2').height()*0.9;
                    vm.windowW = $('.page-photo-view3-2').width()*0.9;
                    if(vm.windowH / vm.pages[vm.pageIndex].custom.height >= vm.windowW / vm.pages[vm.pageIndex].custom.width ){
                        vm.pages[vm.pageIndex].custom.ratio = vm.windowW / vm.pages[vm.pageIndex].custom.width;
                    }else {
                        vm.pages[vm.pageIndex].custom.ratio = vm.windowH / vm.pages[vm.pageIndex].custom.height;
                    }
                    $timeout(function (){
                        pageListDrag1()
                    })
                }
                //获取字体
                FontManagement.getAll({family: ""}, function (res) {
                    vm.fontList = angular.copy(res);
                    vm.fontList1 = angular.copy(res);
                    $scope.item = vm.fontList[0];
                    vm.defaultFont = res[0];
                    res.forEach(function (item) {
                        if(item.defaultShow){
                            vm.defaultFont = item;
                        }
                    })
                });
                $scope.menuCb = function (item) {
                    vm.menuIndex = item;
                    classifyInit();
                }
                $scope.editorDialogHide = function () {
                    vm.onChange()
                }
                $scope.save = function (){
                    vm.onChange({text:JSON.parse(JSON.stringify(vm.pages[vm.pageIndex]))});
                }

                $scope.rotateImagebox = function () {
                    imageboxRotate($scope, vm.pages[vm.pageIndex].levels.level[vm.levelIndex].imagebox);
                }
                $scope.imageboxSite = function (item) {
                    if (!vm.pages[vm.pageIndex].levels.level[vm.levelIndex] || vm.pages[vm.pageIndex].levels.level[vm.levelIndex].imagebox.bokehtype != 'None') {
                        return
                    }
                    var _imagebox = vm.pages[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
                    if (item == 'center') {
                        _imagebox.image.offsetx = -(_imagebox.image.width * _imagebox.image.scale - _imagebox.geometry.width) / 2 / _imagebox.image.scale;
                        _imagebox.image.offsety = -(_imagebox.image.height * _imagebox.image.scale - _imagebox.geometry.height) / 2 / _imagebox.image.scale;
                    }
                    if (_imagebox.image.offsetx > 0) {
                        _imagebox.image.offsetx = 0;
                    }
                    if (_imagebox.image.offsety > 0) {
                        _imagebox.image.offsety = 0;
                    }
                }
                $scope.cutImagebox = function () {
                    var item = vm.pages[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
                    vm.pages[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale = 1;
                    vm.pages[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.x = 0;
                    vm.pages[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.y = 0;
                    item.bokehtype = 'None';
                    vm.imageboxLoad = true;
                    getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope, function () {
                        vm.imageboxLoad = false;
                        // $scope.$apply();
                    });
                }
                $scope.fillImagebox = function () {
                    var item = vm.pages[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
                    vm.pages[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale = 1;
                    vm.pages[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.x = 0;
                    vm.pages[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.y = 0;
                    item.bokehtype = 'Transparent';
                    vm.imageboxLoad = true;
                    getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope, function () {
                        vm.imageboxLoad = false;
                        // $scope.$apply();
                    });
                }

                //文字
                function classifyInit() {
                    ThemeClassify.getAllByCondition4({
                        type: vm.menuIndex,
                        storeId: vm.storeId
                    }, function (res) {
                        if (vm.menuIndex == 'ClipArt') {
                            vm.clipArts = [];
                            if(vm.template.cliparts && vm.template.cliparts.clipart && vm.template.cliparts.clipart.length>0){
                                vm.clipArts = [{
                                    id: "",
                                    name: $translate.instant("button.recommend"),
                                    seq: 0,
                                    type: "CLIPART",
                                    used: true
                                }]
                            }
                            res.data.forEach(function (item) {
                                if(item.storeId){
                                    vm.clipArts.push(item)
                                }
                            })
                            res.data.forEach(function (item) {
                                if(!item.storeId){
                                    vm.clipArts.push(item)
                                }
                            })
                            vm.clipArtsPage = 0;
                            vm.clipArtsList = [];
                            clipArtInit(vm.clipArts[0]);
                        }
                        if (vm.menuIndex == 'FONT') {
                            vm.fontlabels = res.data;
                            vm.fontlabelPage = 0;
                            vm.fontlabelList = [];
                            fontInit(res.data[0])
                        }
                    });
                }
                vm.fontList = [];
                vm.fontPage = 0;
                vm.itemsPerPage = 40;
                vm.fontLoading = false;
                vm.fontChooseId = "";
                $scope.showfontdocLabels = false;
                vm.fontlabelCb = fontlabelCb;
                function fontInit(item) {
                    if(!item){
                        return
                    }
                    vm.fontLoading = true;
                    vm.fontChooseId = item;
                    FontXmlsManger.byCondition({
                        items: [
                            {key: "themeClassify.id", op: "=", value: item.id}],
                        page: vm.fontPage,
                        size: vm.itemsPerPage,
                        sort: ["id,desc"]
                    }, function (res, headers) {
                        var _sum = headers('X-Total-Count');
                        vm.fontList = res.data;
                        vm.fontLoading = false;
                        if(vm.fontList.length < _sum){
                            vm.fontFulfill = true;
                        }else {
                            vm.fontFulfill = false;
                        }
                    }, function () {
                        vm.fontLoading = false;
                    })
                }
                function fontlabelCb(item){
                    vm.fontList = [];
                    vm.fontPage = 0;
                    fontInit(item)
                }
                $scope.showMorefontdocLabels = function () {
                    $scope.showfontdocLabels = !$scope.showfontdocLabels;
                }/***********************  素材  ****************************/
                vm.clipArtsList = [];
                vm.clipArtsPage = 0;
                vm.clipArtLoading = false;
                vm.clipArtChooseId = "";
                vm.clipArtCb = clipArtCb;
                function clipArtInit(item) {
                    if(!item){return}
                    vm.clipArtChooseId = item;
                    if(item.id){
                        vm.clipArtLoading = true;
                        ClipArt.byCondition3({
                            items: [
                                {key: "themeClassify.id", op: "=", value: item.id}],
                            page: vm.clipArtsPage,
                            size: vm.itemsPerPage,
                            sort: ["seq,asc"]
                        }, function (res, headers) {
                            var _sum = headers('X-Total-Count');
                            angular.forEach(res.data, function (resp) {
                                var arr = {
                                    name: resp.name,
                                    identifier: vm.domain + resp.identifier,
                                    provider: resp.provider,
                                    themeClassifyName: resp.themeClassifyName,
                                    themeClassifyId: resp.themeClassifyId,
                                    resource: {
                                        identifier: vm.domain + resp.identifier,
                                        provider: resp.provider,
                                    }
                                }
                                vm.clipArtsList.push(arr);
                            })
                            vm.clipArtLoading = false;
                            if(vm.clipArtsList.length < _sum){
                                vm.clipArtFulfill = true;
                            }else {
                                vm.clipArtFulfill = false;
                            }
                        }, function () {
                            vm.clipArtLoading = false;
                        })
                    }else{
                        vm.template.cliparts.clipart.forEach(function (clipart) {
                            var arr = {
                                name: "",
                                identifier: clipart.resource.identifier,
                                provider: "",
                                themeClassifyName: $translate.instant("button.recommend"),
                                themeClassifyId: "",
                                resource: clipart.resource
                            }
                            vm.clipArtsList.push(arr);
                        })
                        vm.clipArtLoading = false;
                    }
                }
                function clipArtCb(item){
                    vm.clipArtsList = [];
                    vm.clipArtsPage = 0;
                    clipArtInit(item)
                }
                $scope.loadMoreClipArt = function () {
                    vm.clipArtsPage++;
                    clipArtInit(vm.clipArtChooseId)
                }

                //页面元素
                vm.isEditorPageElementBody = true;
                $scope.levelInfo = function (level, index) {
                    var item = "";
                    index = completeIndex(index)
                    var i = index+1;
                    if(level.imagebox){
                        item += $translate.instant("editorCommon.imagebox")
                    }
                    if(level.imageart){
                        item += $translate.instant("editorCommon.imageart")
                    }
                    if(level.textbox){
                        item += level.textbox.text || ""
                    }
                    if(level.textdocument){
                        item += $translate.instant("editorCommon.textdocument")
                    }
                    if(level.calMonthBox){
                        item += $translate.instant("editorCommon.calMonthBox")
                    }
                    if(level.calTitleBox){
                        item += $translate.instant("editorCommon.calTitleBox")
                    }
                    return item
                };
                function levelInfo1(level) {
                    var item = "";
                    if(level.imagebox){
                        item = "imagebox"
                    }
                    if(level.imageart){
                        item = "imageart"
                    }
                    if(level.textbox){
                        item = 'textbox'
                    }
                    if(level.textdocument){
                        item = "textdocument"
                    }
                    if(level.calTitleBox){
                        item = "calTitleBox"
                    }
                    if(level.calMonthBox){
                        item = "calMonthBox"
                    }
                    return item
                };
                $scope.levelDelete = function (index) {
                    var levels = vm.pages[vm.pageIndex].levels.level.splice(completeIndex(index), 1);
                    if(levels && levels[0] && levels[0].imagebox){
                        setLayout();
                    }
                    if (levels && levels[0] && levels[0].imagebox && levels[0].imagebox.image&& levels[0].imagebox.image.resourceid) {
                        for (var j = 0; j < $scope.photos.length; j++) {
                            var arr = $scope.photos[j];
                            if (arr.id == levels[0].imagebox.image.resourceid) {
                                arr.usenum == 0 ? 0 : arr.usenum--;
                            }
                        }
                    }
                    cookie();
                };
                $scope.levelHidden = function (e, level) {
                    level.isHidden = !level.isHidden;
                    fn(e)
                }

                //页面元素移动
                var isBoxDown = false;
                vm.pageElementW = $('.editor-page-element-title').width();
                vm.pageElementH = $('.editor-page-element-title').height();
                vm.pageElementL = $(window).width() - vm.pageElementW - 160;
                vm.pageElementT = 110;
                $scope.movePageElement = function (e) {
                    fn(e);
                    var x = e.clientX;
                    var y = e.clientY;
                    var l = angular.copy(vm.pageElementL);
                    var t = angular.copy(vm.pageElementT);
                    isBoxDown = true;
                    window.onmousemove = function (e) {
                        if (isBoxDown) {
                            var nx = e.clientX;
                            var ny = e.clientY;
                            var nl = nx - (x - l);
                            var nt = ny - (y - t);
                            if(nl < $("#canvasView").offset().left){
                                nl = $("#canvasView").offset().left
                            }
                            if(nl > ($("#canvasView").offset().left + $("#canvasView").width() - vm.pageElementW)){
                                nl = ($("#canvasView").offset().left + $("#canvasView").width() - vm.pageElementW)
                            }
                            if(nt < $("#canvasView").offset().top){
                                nt = $("#canvasView").offset().top
                            }
                            if(nt > ($("#canvasView").offset().top + $("#canvasView").height() - vm.pageElementH)){
                                nt = ($("#canvasView").offset().top + $("#canvasView").height() - vm.pageElementH)
                            }
                            vm.pageElementL = nl;
                            vm.pageElementT = nt;
                            $scope.$apply();
                        }

                    }
                    window.onmouseup = function (e) {
                        isBoxDown = false;
                        e.stopPropagation();
                        $scope.$apply();
                    }
                };
                //页面元素拖拽
                var sort1 = null;
                var sortArray1 = [];
                function pageListDrag1() {
                    $timeout(function () {
                        vm.sortingLevel = angular.copy(vm.pages[vm.pageIndex].levels.level);
                        sort1 = new Sortable(document.getElementById('pageList1'), {
                            swap: false,
                            filter: '.filtered',
                            cancel: ".notDrag",
                            preventOnFilter: true,
                            animation: 150,
                            onEnd: function (/**Event*/evt) {
                                sortArray1 = [];
                                angular.forEach($('#pageList1').find(".sortingPageView"), function (item, index) {
                                    var page = item.getAttribute("data-page");
                                    sortArray1.push(JSON.parse(page));
                                });
                                if(sortArray1 && sortArray1.length>0){
                                    vm.pages[vm.pageIndex].levels.level = [];
                                    $timeout(function () {
                                        vm.pages[vm.pageIndex].levels.level = angular.copy(sortArray1.reverse());
                                        vm.pages[vm.pageIndex].levels.level.forEach(function (value, index) {
                                            value.seq = index+1;
                                        })
                                        sortArray1 = [];
                                        $scope.editorBodyView();
                                    })
                                }
                            }
                        });
                    })
                }
                $scope.dragCompleteClick = function (e, level, index) {
                    fn(e)
                    elementInit((vm.pages[vm.pageIndex].levels.level.length-1-index),vm.pageIndex,level[levelInfo1(level)],levelInfo1(level));
                };
                vm.completeIndex = completeIndex;
                function completeIndex(index) {
                    return vm.pages[vm.pageIndex].levels.level.length-1-index
                }

                //画布操作

                vm.elementInit = elementInit;
                function elementInit(index,pageIndex,item,name){
                    vm.elementOn = {
                        index:index,
                        pageIndex:pageIndex,
                        name:name,
                        item:item
                    };
                    vm.levelIndex = index;
                }
                //锁定
                $scope.locking = vm.locking = function(e){
                    fn(e);
                    if(vm.elementOn.item){
                        vm.elementOn.item.lock = !vm.elementOn.item.lock;
                        if(vm.elementOn.name === 'imagebox'){
                            vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].imagebox.lock = vm.elementOn.item.lock;
                        }
                    }
                }
                //缓存每一步
                vm.cookie=cookie;
                vm.cookies=[];
                function cookie(){
                    var _data = angular.copy(vm.pages[vm.pageIndex]);
                    vm.cookies.push(_data);
                }
                $scope.editorBodyView = function(e){
                    if(e){
                        fn(e);
                    }
                    vm.elementOn = {
                        index:"",
                        pageIndex:"",
                        name:"",
                        item:""
                    };
                    vm.levelIndex = "";
                    vm.isOperationColor = false;
                    vm.isOperationSpacing = false;
                    vm.isOperationDir = false;
                }
                /***********************  文字  ****************************/
                fontuuid()
                vm.selectFont = '';
                vm.selectFontBtn = fontuuid;
                vm.selectFontName = selectFontName;
                vm.chineseSupport = true;
                function fontuuid(){
                    vm.fontuuid = [];
                    FontManagement.getAll({family: vm.selectFont}, function (res) {
                        vm.fontuuid = res;
                    });
                }
                function selectFontName(item){
                    if(item){
                        for(var i=0;i<vm.fontuuid.length; i++){
                            if(vm.fontuuid[i].uuid == item){
                                return vm.fontuuid[i].family
                            }
                        }
                    }
                }
                $scope.updateTextFont = function (data) {
                    if (data == undefined) {
                        MessageService.error($translate.instant("editorMessage.failedAddText"));
                        return
                    }
                    if (!data.enable) {
                        MessageService.error($translate.instant("editorMessage.fontChange"));
                        return
                    }
                    var text = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox;
                    if (text.lock) {
                        return
                    }
                    vm.fontStyle = [];
                    for(var i=0; i<vm.fontuuid.length; i++){
                        if(vm.fontuuid[i].uuid == data.uuid){
                            for(var j=0; j<vm.fontuuid[i].fontStyles.length; j++){
                                vm.fontStyle.push(vm.fontuuid[i].fontStyles[j].style)
                            }
                        }
                    }
                    text.style.bold = false;
                    text.style.italic = false;
                    if(vm.fontStyle.indexOf('NORMAL') != '-1'){
                        text.style.bold = false;
                        text.style.italic = false;
                    }else {
                        if(vm.fontStyle.indexOf('BOLD_ITALIC') != '-1'){
                            text.style.bold = true;
                            text.style.italic = true;
                        }else {
                            if(vm.fontStyle.indexOf('ITALIC') != '-1'){
                                text.style.italic = true;
                                text.style.bold = false;
                            }
                            if(vm.fontStyle.indexOf('BOLD') != '-1'){
                                text.style.bold = true;
                                text.style.italic = false;
                            }
                        }
                    }
                    text.style.font = data.family;
                    text.fontuuid = data.uuid;
                    text.identifier = $scope.domain + data.fontStyles[0].identifier;
                    text.loading = true;
                    FontSupport2(FontManagement, text, function (res) {
                        var msg = res.data;
                        if (res.status == 200) {
                            if (!msg.success) {
                                $timeout(function () {
                                    vm.editFailedFont = false;
                                    vm.failedFontArr = null;
                                    MessageService.confirm({
                                        title:$translate.instant("editorMessage.notDelete"),
                                        cancel:$translate.instant("button.cancel"),
                                        confirm:$translate.instant("button.returnCorrection"),
                                        msg:$translate.instant("editorMessage.pleaseChangeFonts")
                                    })
                                    text.loading = false;
                                })
                            }else{
                                $timeout(function () {
                                    text.text = (!msg.passed || msg.passed == "") ? $translate.instant("editorMessage.pleaseEnterText") : msg.passed;
                                    if (msg.success && !msg.supported) {
                                        $timeout(function () {
                                            vm.editFailedFont = true;
                                            vm.failedFontArr = {
                                                failed: msg.failed
                                            }
                                            $('.selected').find('pre span').html(text.text);
                                            text.loading = false;
                                            $timeout(function () {
                                                sizeHeight()
                                                fontStyle(data.uuid);
                                                cookie();
                                            },200)
                                        })
                                    } else {
                                        vm.editFailedFont = false;
                                        vm.failedFontArr = null;
                                        ModulesService.getFontCSS(text);
                                        cookie();
                                        $timeout(function () {
                                            sizeHeight()
                                            fontStyle(data.uuid);
                                        },200)
                                    }
                                })
                            }
                        }
                    })
                };
                //添加文字
                $scope.addText = function (size, value) {
                    textboxWebAdd(vm.defaultFont, vm.defaultFont.fontStyles, size, function (_font) {
                        if(value){
                            _font.text = value;
                            _font.value = value;
                        }else{
                            _font.text = $translate.instant("editorCommon.doubleClickType");
                            _font.value = $translate.instant("editorCommon.doubleClickType");
                        }
                        var _level ={
                            seq: vm.pages[vm.pageIndex].levels.level.length+1,
                            textbox: _font
                        }
                        vm.pages[vm.pageIndex].levels.level.push(_level);
                        elementInit(_level.seq-1,vm.pageIndex,_level.textbox,'textbox');
                        $timeout(function () {
                            // vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox.geometry.width = (Number($("#c-xml-page-material-fontId").width() / (3/4) / vm.pages[vm.pageIndex].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pages[vm.pageIndex].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pages[vm.pageIndex].custom.ratio)) + Number(5));
                            // vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox.geometry.height = Number($("#c-xml-page-material-fontId").height() / (3/4) / vm.pages[vm.pageIndex].custom.ratio) * (1 / 12 * vm.elementOn.item.style.size * vm.pages[vm.pageIndex].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pages[vm.pageIndex].custom.ratio) + Number(10);
                            vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                            vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox.geometry.height = (Number($("#c-xml-page-material-fontId").height()) + Number(10));
                            vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox.geometry.x = (vm.pages[vm.pageIndex].trimbox.width - vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox.geometry.width) / 2 + vm.pages[vm.pageIndex].trimbox.x;
                            vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox.geometry.y = (vm.pages[vm.pageIndex].trimbox.height - vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox.geometry.height) / 2 + vm.pages[vm.pageIndex].trimbox.y;

                            var _num = 0;
                            vm.pages[vm.pageIndex].levels.level.forEach(function (level) {
                                if(level.textbox){
                                    _num+=10;
                                }
                            });
                            vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox.geometry.x += _num/vm.pages[vm.pageIndex].custom.ratio;
                            vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox.geometry.y += _num/vm.pages[vm.pageIndex].custom.ratio;
                            getFontCSS(FontManagement, vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox, $scope)
                            // sizeHeight();
                            cookie();
                            fontStyle(vm.pages[vm.pageIndex].levels.level[_level.seq-1].textbox.fontuuid)
                        })
                    })
                }
                vm.fontStyleCb = fontStyle;
                function fontStyle(fontuuid){
                    if(vm.elementOn.name != "textbox"){
                        return
                    }
                    var text = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox;
                    vm.fontStyle = [];
                    for(var i=0; i<vm.fontuuid.length; i++){
                        if(vm.fontuuid[i].uuid == fontuuid){
                            for(var j=0; j<vm.fontuuid[i].fontStyles.length; j++){
                                vm.fontStyle.push(vm.fontuuid[i].fontStyles[j].style)
                            }
                        }
                    }
                    text.style.bold1 = false;
                    text.style.italic1= false;
                    if(vm.fontStyle.indexOf('NORMAL') == '-1'){
                        text.style.normal = false;
                    }else {
                        text.style.normal = true;
                    }
                    if(vm.fontStyle.indexOf('BOLD_ITALIC') != '-1'){
                        text.style.bold1 = true;
                        text.style.italic1= true;
                    }else {
                        if(vm.fontStyle.indexOf('ITALIC') != '-1'){
                            text.style.italic1= true;
                        }
                        if(vm.fontStyle.indexOf('BOLD') != '-1'){
                            text.style.bold1 = true;
                        }
                    }
                }
                vm.sizeHeight = sizeHeight;
                function sizeHeight(){
                    $timeout(function () {
                        var _h = Number($("#c-xml-page-material-fontId1").height());
                        var _w = Number($("#c-xml-page-material-fontId1").width());
                        var _h1 = Number($("#c-xml-page-material-fontId2").height());
                        var _w1 = Number($("#c-xml-page-material-fontId2").width());
                        if(_h<_h1){_h=_h1};
                        if(_w<_w1){_w=_w1};
                        if(vm.elementOn.name == 'textbox') {
                            if(!writingmodeInit(vm.elementOn.item.style.writingmode)){
                                vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox.geometry.height = _h;
                            }
                            if(writingmodeInit(vm.elementOn.item.style.writingmode)){
                                vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox.geometry.width = _w;
                            }
                            $scope.$digest();
                        }
                    },200)
                }
                function sizeHeight2(textbox){
                    $timeout(function () {
                        var _h = Number($("#c-xml-page-material-fontId1").height()) + Number(10);
                        var _w = Number($("#c-xml-page-material-fontId1").width()) + Number(10);
                        if(!writingmodeInit(vm.elementOn.item.style.writingmode)){
                            textbox.geometry.height = _h;
                        }
                        if(writingmodeInit(vm.elementOn.item.style.writingmode)){
                            textbox.geometry.width = _w;
                        }
                    },200)
                }
                $scope.writingmodeInit = writingmodeInit;
                /***********************  文字end  ****************************/
                /***********************  素材  ****************************/
                //添加素材
                $scope.materialCb = function(item){
                    webAddImageart($scope,$http,item,vm.pages[vm.pageIndex].trimbox, function (_imageart) {
                        _imageart.geometry.width = _imageart.geometry.width/vm.pages[vm.pageIndex].custom.ratio;
                        _imageart.geometry.height = _imageart.geometry.height/vm.pages[vm.pageIndex].custom.ratio;
                        _imageart.geometry.x = (vm.pages[vm.pageIndex].trimbox.width - _imageart.geometry.width) / 2 + vm.pages[vm.pageIndex].trimbox.x;
                        _imageart.geometry.y = (vm.pages[vm.pageIndex].trimbox.height - _imageart.geometry.height) / 2 + vm.pages[vm.pageIndex].trimbox.y;
                        var _num = 0;
                        vm.pages[vm.pageIndex].levels.level.forEach(function (level) {
                            if (level.imageart) {
                                _num += 10;
                            }
                        });
                        _imageart.geometry.x += _num / vm.pages[vm.pageIndex].custom.ratio;
                        _imageart.geometry.y += _num / vm.pages[vm.pageIndex].custom.ratio;
                        var _level ={
                            seq: vm.pages[vm.pageIndex].levels.level.length+1,
                            imageart: _imageart
                        }
                        vm.pages[vm.pageIndex].levels.level.push(_level);
                        elementInit(_level.seq-1,vm.pageIndex,_level.imageart,'imageart');
                        cookie();
                    })
                }
                /***********************  素材end  ****************************/
                /***********************  文字编辑  ****************************/
                //字体
                vm.isOperationColor = false;
                vm.colorList = [
                    {name: '#000000', id: '#000000'},
                    {name: '#666666', id: '#666666'},
                    {name: '#a8a8a8', id: '#a8a8a8'},
                    {name: '#d9d9d9', id: '#d9d9d9'},
                    {name: '#ffffff', id: '#ffffff'},
                    {name: '#647c6e', id: '#647c6e'},
                    {name: '#ffbd4a', id: '#ffbd4a'},
                    {name: '#fef952', id: '#fef952'},
                    {name: '#99e264', id: '#99e264'},
                    {name: '#36b72a', id: '#36b72a'},
                    {name: '#44d9e5', id: '#44d9e5'},
                    {name: '#c27171', id: '#c27171'},
                    {name: '#2fb3ff', id: '#2fb3ff'},
                    {name: '#b760a6', id: '#b760a6'},
                ];
                $scope.operationColorOpen = function ($event) {
                    vm.isOperationColor = !vm.isOperationColor;
                    fn($event)
                }
                $scope.operationColor = function (item) {
                    vm.elementOn.item.style.color = item.id;
                    vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox = vm.elementOn.item;
                }
                $scope.chooseTextColor = function () {
                    vm.elementOn.item.style.color = vm.textColor;
                    vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox = vm.elementOn.item;
                    vm.textColor = null;
                }
                fontSizeInit();
                function fontSizeInit(size){
                    var _size = 101;
                    var _size1 = Math.floor(size/2);
                    $scope.fontSizes = [];
                    for (var f = 6; f < _size; f++) {
                        $scope.fontSizes.push(f);
                    }
                    if(_size1 > _size){
                        $scope.fontSizes.push(_size1);
                    }
                }
                var a = null;
                $scope.updateTextSize = function (size) {
                    if (a !== null) {
                        clearTimeout(a)
                    }
                    a = setTimeout(function () {
                        if (size <= 6) {
                            size = 6
                        }
                        vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox.style.size = size;
                        vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox.style.lineheight = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox.style.size;
                        vm.elementOn.item = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox;
                        $timeout(function () {
                            // vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox.geometry.width = (Number($("#c-xml-page-material-fontId").width() / (3/4) / vm.pages[vm.pageIndex].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pages[vm.pageIndex].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pages[vm.pageIndex].custom.ratio)) + Number(5));
                            // vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox.geometry.height = Number($("#c-xml-page-material-fontId").height() / (3/4) / vm.pages[vm.pageIndex].custom.ratio) * (1 / 12 * vm.elementOn.item.style.size * vm.pages[vm.pageIndex].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pages[vm.pageIndex].custom.ratio) + Number(10);
                            if(vm.elementOn.item.geometry.width < ptToPx( vm.elementOn.item.style.size)){
                                vm.elementOn.item.geometry.width = ptToPx( vm.elementOn.item.style.size)+10;
                            }

                            sizeHeight();
                            // $scope.$digest();
                            cookie();
                        })
                    },500)
                }
                vm.isOperationSpacing = false;
                $scope.operationSpacing = function (item) {
                    vm.isOperationSpacing = item;
                    vm.isOperationDir = false;
                    $timeout(function () {
                        var text = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox;
                        $(".block-width").css({left: text.style.letterspacing});
                        $(".block-height").css({left: text.style.lineheight});
                        vm.template.letterspacing =text.style.letterspacing;
                        vm.template.lineheight = text.style.lineheight;
                    })
                }
                //斜体
                $scope.operationItalic = function () {
                    var text = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox;
                    if (text) {
                        if(text.style.italic){
                            if(vm.fontStyle.indexOf('NORMAL') > -1){
                                text.style.italic = false;
                                if (vm.fontStyle.indexOf('BOLD') == '-1') {
                                    text.style.bold = false;
                                }
                            }else{
                                if(text.style.bold){
                                    text.style.italic = false;
                                }
                            }
                        }else{
                            if(vm.fontStyle.indexOf('ITALIC') > -1){
                                text.style.italic = true;
                                if(text.style.bold){
                                    if(vm.fontStyle.indexOf('BOLD_ITALIC') == -1){
                                        text.style.bold = false;
                                    }
                                }else{
                                    // if( vm.fontStyle.indexOf('BOLD_ITALIC') > -1){
                                    //     text.style.bold = true;
                                    // }
                                }
                            }else if (vm.fontStyle.indexOf('BOLD_ITALIC') > -1) {
                                text.style.bold = true;
                                text.style.italic = true;
                            }
                        }
                        $timeout(function () {
                            sizeHeight();
                        },200)
                    }
                }
                //粗体
                $scope.operationBold = function () {
                    var text = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox;
                    if (text) {
                        if(text.style.bold){
                            if(vm.fontStyle.indexOf('NORMAL') > -1){
                                text.style.bold = !text.style.bold;
                                if (vm.fontStyle.indexOf('ITALIC') == '-1') {
                                    text.style.italic = text.style.bold;
                                }
                            }else{
                                if(text.style.italic){
                                    text.style.bold = !text.style.bold;
                                }
                            }
                        }else{
                            if(vm.fontStyle.indexOf('BOLD') > -1){
                                text.style.bold = true;
                                if(text.style.italic){
                                    if(vm.fontStyle.indexOf('BOLD_ITALIC') == -1){
                                        text.style.italic = false;
                                    }
                                }else{
                                    // if( vm.fontStyle.indexOf('BOLD_ITALIC') > -1){
                                    //     text.style.italic = true;
                                    // }
                                }
                            }else if (vm.fontStyle.indexOf('BOLD_ITALIC') > -1) {
                                text.style.bold = true;
                                text.style.italic = true;
                            }
                        }
                        $timeout(function () {
                            sizeHeight();
                        },200)
                    }
                }

                // 字/行间距鼠标点击移动
                var spacingW = {};
                var flagSpacing = false
                window.resizeSpacingStart = function (e) {
                    fn(e);
                    var targ = e.target ? e.target : e.srcElement;
                    spacingW.clientX = e.clientX || 0;
                    spacingW.originLeft = targ.offsetLeft;
                    spacingW.value = targ.dataset.value;
                    var text = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox;
                    if (text.lock) {
                        return
                    }
                    flagSpacing = true;
                    document.addEventListener("mousemove", resizeSpacingOn, true);
                    document.addEventListener("mouseup", resizeSpacingEnd, true);
                }

                function resizeSpacingOn(e) {
                    if (flagSpacing) {
                        var text = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox;
                        var l = spacingW.originLeft + e.clientX - spacingW.clientX;
                        if (spacingW.value == 'height' && l < text.style.size) {
                            l = text.style.size;
                        } else if (spacingW.value == 'width' && l < 0) {
                            l = 0;
                        }
                        if (l > 110) {
                            l = 110
                        }
                        if (spacingW.value == 'width') {
                            $(".block-width").css({left: l});
                            text.style.letterspacing = vm.template.letterspacing = l;
                        } else {
                            $(".block-height").css({left: l});
                            text.style.lineheight = vm.template.lineheight = l;
                        }
                        sizeHeight();
                        // var h = Number($('.selectedBorder').find('.c-xml-page-textboxe-text').height());
                        // text.geometry.height = h / vm.pages[vm.pageIndex].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pages[vm.pageIndex].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pages[vm.pageIndex].custom.ratio) + Number(5);
                        $scope.$digest();
                    }
                }
                function resizeSpacingEnd() {
                    if(flagSpacing){
                        cookie();
                    }
                    spacingW = {}
                    flagSpacing = false;
                    document.removeEventListener("mousemove", resizeSpacingOn, true);
                    document.removeEventListener("mouseup", resizeSpacingEnd, true);
                }
                vm.isOperationDir = false;
                $scope.operationDir = function (item) {
                    vm.isOperationDir = item;
                    vm.isOperationSpacing = false;
                }
                //左对齐
                $scope.operationBarDir = function (item) {
                    var text = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox;
                    text.style.align = item;
                }
                //横版
                $scope.textHengban = function () {
                    var text = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox;
                    var text1 = angular.copy(text);
                    text.style.writingmode = 'horizontal-tb';
                    text.geometry.width = text1.geometry.height;
                    text.geometry.height = text1.geometry.width;
                }
                //竖版
                $scope.textShuban = function (item) {
                    var text = vm.pages[vm.pageIndex].levels.level[vm.elementOn.index].textbox;
                    var text1 = angular.copy(text);
                    var _writingmode = angular.copy(text.style.writingmode);
                    text.style.writingmode = item;
                    $timeout(function () {
                        if(writingmodeInit(_writingmode) && writingmodeInit(text.style.writingmode)){
                        }else{
                            text.geometry.width = text1.geometry.height;
                            text.geometry.height = text1.geometry.width;
                        }
                    },200)
                }
                /***********************  文字编辑end  ****************************/

                //删除
                $scope.deleteBox = vm.deleteBox = function ($event) {
                    if(vm.elementOn.item.lock){
                        return;
                    }
                    if(vm.elementOn&&vm.elementOn.name != 'imagebox'){
                        vm.pages[vm.pageIndex].levels.level.splice(vm.elementOn.index, 1);
                    }
                    $scope.editorBodyView();
                    cookie();
                }
            }]
    };

    angular.module('editorApp')
        .component('pagePhoto', PagePhotoControl);

})();
