(function () {
    'use strict';

    angular
        .module('editorApp')
        .factory('User', User)
        .factory('ToneSettings', ToneSettings);

    User.$inject = ['$resource'];
    ToneSettings.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'}
        });

        return service;
    }
    function ToneSettings ($resource) {
        var resourceUrl =  'manager/' + 'api/tone-settings/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'byStoreId': {
                method: 'GET',
                params:{
                    path:"byStoreId"
                }
            },
            "save":{
                method:"POST"
            },
            "delete":{
                method:"DELETE"
            },
            'update': { method:'PUT' },
        });
    }
})();
