(function () {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('product-choose-ec', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{aid}/choose',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumEcController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        $translatePartialLoader.addPart('choose');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })

            //新版编辑器
            .state('product-chooseNew-ec', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{aid}/chooseNew',
                data: {
                    authorities: [],
                    pageTitle: 'choose.title'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumEcController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        $translatePartialLoader.addPart('choose');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })

            //edit---->chooses
            .state('product-chooses-ec', {
                parent: 'app',
                url: '/{storeId}/{aid}/chooses',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumEcController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        $translatePartialLoader.addPart('choose');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'edit'
                        };
                    }
                }
            })

            //新版编辑器edit---->chooses
            .state('product-choosesNew-ec', {
                parent: 'app',
                url: '/{storeId}/{aid}/choosesNew',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumEcController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        $translatePartialLoader.addPart('choose');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'edit'
                        };
                    }
                }
            })

            //新增编辑器Three
            .state('product-chooseThree-ec', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{aid}/gift',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/productsThree.html',
                        controller: 'ProductThreeEcController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })
            //新增编辑器Three
            .state('product-chooseThree1', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/chooseThree1',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product/productsThree1.html',
                        controller: 'ProductThree1Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {id: null};
                    }
                }
            })
            .state('product-choosesThree-ec', {
                parent: 'app',
                url: '/{storeId}/{aid}/gift',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/productsThree.html',
                        controller: 'ProductThreeEcController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'edit'
                        };
                    }
                }
            })

            // h5 小程序  作品预览
            .state('product-view-ec', {
                parent: 'app',
                url: '/{storeId}/{aid}/view',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.viewTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/product-view.html',
                        controller: 'ProductViewEcController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Article', function ($stateParams, Article) {
                        return Article.get({id: $stateParams.aid}).$promise;
                    }]
                }
            })
            // h5 小程序 作品分享的预览
            .state('product-view2-ec', {
                parent: 'app',
                url: '/{storeId}/{aid}/view2/{viewIndex}',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.shareTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/product-view2.html',
                        controller: 'ProductViewEc2Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Article', function ($stateParams, Article) {
                        return Article.get({id: $stateParams.aid}).$promise;
                    }]
                }
            })

            //h5  订单作品分享的预览
            .state('product-viewOrder', {
                parent: 'app',
                url: '/{storeId}/{aid}/view/{oid}',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.shareTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/product-view2.html',
                        controller: 'ProductViewEc2Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OrderArticle', function ($stateParams, OrderArticle) {
                        return OrderArticle.get({id: $stateParams.oid}).$promise;
                    }]
                }
            })

            //小程序  订单中作品的预览  以及订单中的分享页面  --订单预览没有二维码
            .state('product-view3', {
                parent: 'app',
                url: '/{storeId}/{id}/productView3',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.show'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/product-view2.html',
                        controller: 'ProductViewEc3Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OrderArticle', function ($stateParams, OrderArticle) {
                        return OrderArticle.get({id: $stateParams.id}).$promise;
                    }]
                }
            })

            //h5 小程序 (海报编辑器) 作品Three-- 预览
            .state('product-view5-ec', {
                parent: 'app',
                url: '/{storeId}/{aid}/view5',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.viewTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/product-viewThree.html',
                        controller: 'ProductViewEcThreeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Article', function ($stateParams, Article) {
                        return Article.get({id: $stateParams.aid}).$promise;
                    }]
                }
            })
            //h5 小程序 (海报编辑器) 作品Three--分享
            .state('product-viewShare', {
                parent: 'app',
                url: '/{storeId}/{aid}/viewShare',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.shareTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/product-viewThree-share.html',
                        controller: 'ProductViewEcThreeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Article', function ($stateParams, Article) {
                        return Article.get({id: $stateParams.aid}).$promise;
                    }]
                }
            })

            //h5 小程序 (海报编辑器) 订单作品Three--预览  --订单预览没有二维码
            .state('product-viewPreview', {
                parent: 'app',
                url: '/{storeId}/{id}/viewPreview',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.show'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/product-viewThree-preview.html',
                        controller: 'ProductViewEcThreePreviewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OrderArticle', function ($stateParams, OrderArticle) {
                        return OrderArticle.get({id: $stateParams.id}).$promise;
                    }]
                }
            })

            //新增编辑器Three
            .state('editorPhotoAlbumM', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{aid}/photobook',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumEcController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })
            .state('editorPhotoAlbumEditM', {
                parent: 'app',
                url: '/{storeId}/{aid}/photobookEdit',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumEcController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'edit'
                        };
                    }
                }
            })

            .state('editorPhoto', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{aid}/editorPhoto',
                cache:false,
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/editor-photo-both.html',
                        controller: 'EditorPhotoBothController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'add'
                        };
                    }
                }
            })
            .state('editorPhotoEdit', {
                parent: 'app',
                url: '/{storeId}/{aid}/editorPhotoEdit',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/product-ec/editor-photo-both.html',
                        controller: 'EditorPhotoBothController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'edit'
                        };
                    }
                }
            })
            .state('editorPhotoPreview', {
                parent: 'app',
                url: '/{storeId}/{aid}/editorPhotoPreview',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/editor-photo-preview.html',
                        controller: 'EditorPhotoPreviewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }]
                }
            })
            .state('editorPhotoPreviewShare', {
                parent: 'app',
                url: '/{storeId}/{aid}/editorPhotoShare',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/editor-photo-share.html',
                        controller: 'EditorPhotoPreviewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }]
                }
            })


            .state('editorPhotoBoth', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{aid}/editorPhotoBoth',
                cache:false,
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/editor-photo-both.html',
                        controller: 'EditorPhotoBothController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'add'
                        };
                    }
                }
            })
            .state('editorPhotoBothEdit', {
                parent: 'app',
                url: '/{storeId}/{aid}/editorPhotoBothEdit',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/product-ec/editor-photo-both.html',
                        controller: 'EditorPhotoBothController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'edit'
                        };
                    }
                }
            })
            //表单编辑器
            .state('editorForm', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{aid}/editorForm',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/editor-form.html',
                        controller: 'EditorFormController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })

            .state('idPhoto', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{aid}/idPhoto',
                cache:false,
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-ec/id-photo.html',
                        controller: 'IdPhotoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }]
                }
            })
            .state('idPhotoEdit', {
                parent: 'app',
                url: '/{storeId}/{aid}/idPhotoEdit',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/product-ec/id-photo.html',
                        controller: 'IdPhotoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }

})();
