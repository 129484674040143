(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('EditorPhotoPreviewController', EditorPhotoPreviewController);

    EditorPhotoPreviewController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'WxJS', 'Gallery', 'ItemValue', 'SweetAlert', 'Theme', '$localStorage', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'Store', 'Article', 'CommonService', '$http', 'Folder','MessageNotification', 'Product', 'Music', 'StoreConfig','Cart', 'Iphone', 'StoreWorkEditConfig',
        'ModulesService', '$translate'];

    function EditorPhotoPreviewController($state, $scope, $q, $stateParams, Convert, Qinius, WxJS, Gallery, ItemValue, SweetAlert, Theme, $localStorage, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, Store, Article, CommonService, $http, Folder,MessageNotification, Product, Music, StoreConfig,Cart, Iphone, StoreWorkEditConfig,
                                          ModulesService, $translate) {
        var vm = this;
        //初始化
        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        vm.returnurl = localStorage.getItem('returnurl');
        vm.returnurl1 = localStorage.getItem('returnurl1');
        vm.returnurl2 = localStorage.getItem('returnurl2');
        vm.source = localStorage.getItem('source');
        vm.returnurl2Text = $translate.instant("button.buttonText");

        vm.storeId = $stateParams.storeId;
        vm.aid = $stateParams.aid;


        var audio = document.getElementById('audio');
        audio.crossOrigin = 'anonymous';

        $scope.domains = Qinius.getDomain();
        PlatformWechatSetting.get({}, function (res) {
            vm.platformSetting = res.data;
        });
        vm.store = Store.get({id: vm.storeId});

        // vm.storeCart = StoreConfig.byStoreIdAndType({
        //     storeId: vm.storeId,
        //     type:'SHOPPINGCART'
        // });

        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if(res.status == 200){
                vm.returnurl2Text = res.data.buttonText;
            }else{
                swal.showInputError(res.message);
            }
        });
        $q.all([$scope.domains.$promise]).then(function () {
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            vm.isIPhoneX = Iphone;
            init();
            document.addEventListener('UniAppJSBridgeReady', function() {})
            //获取自定义颜色
            ModulesService.getToneSettings(vm.storeId,function (res) {
                vm.toneSettings = res;
            });
        });
        vm.windowDpi = js_getDPI();
        //主页
        function init(){
            Article.get({id: vm.aid}, function (res) {
                vm.article = res.data;
                if(vm.article.invalid){//作品失效
                    $state.go('articlesFailure',{storeId:vm.storeId});
                    return
                }
                $(document).attr("title",vm.article.name);
                Product.get({id: vm.article.productId}, function (resP) {
                    Convert.xmlToDocument2({
                        identifier: res.data.fileName,
                        provider: "qiniu",
                        editorType:vm.article.editorType,
                        pictureType:resP.data.pictureType,
                        patternType:resP.data.patternType,
                    }, function (resp1) {
                        vm.previewArticle = resp1;
                        vm.page = resp1.pages.page;
                        // abook()
                        getItemValue();
                    })
                })
            })

            var monthStr = new Date().getMonth() + 1;
            var dayStr = new Date().getDate();
            var timestr = new Date().getFullYear() + "-" + (monthStr > 9 ? monthStr : "0" + monthStr) + "-" + (dayStr > 9 ? dayStr : "0" + dayStr);
            MessageNotification.getAllByCondition2({
                items: [{key: "available", op: "=", value: "true"}, {
                    key: "validate",
                    op: ">=",
                    value: timestr
                }, {key: "pageType", op: "=", value: "View"}]
            }, function (res) {
                res.data.sort(compare('seq'))
                vm.message = angular.copy(res.data);
                vm.messageText = "\xa0\xa0\xa0\xa0\xa0\xa0";
                angular.forEach(res.data, function (resp) {
                    vm.messageText += resp.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                })
            });


            Music.allByCondition({
                items: [],
                sort: ["id,asc"]
            }, function (resp) {
                $scope.Musics = resp.data;
            });
        }

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }
        vm.isHideMusicBtn = false;
        vm.isPlayBack = false;

        var timer = null;
        var rot = 10 - 0;
        function getItemValue(){
            ItemValue.get({id: vm.article.itemValueId}, function (msg) {
                vm.itemValue = msg;
                $scope.price = msg.data.price;
                if (vm.article.musicIdentify) {
                    audio.src = $scope.domain + vm.article.musicIdentify;
                } else {
                    if ( msg.data.productXmlLines) {
                        for (var i = msg.data.productXmlLines.length - 1; i >= 0; i--) {
                            var xml = msg.data.productXmlLines[i];
                            if (xml.productXmlId == vm.article.productXmlId && xml.musicIdentify) {
                                audio.src = $scope.domain + xml.musicIdentify;
                            }
                        }
                    } else {
                        audio.src = '';
                        vm.isHideMusicBtn = true;
                    }
                }
                if(!vm.isHideMusicBtn){
                    Play();
                }
            });
        }

        function Play() {
            audio.play().then(function () {
                $(".PanelRight").removeClass('hide1');
                timer = window.setInterval(begin, 10);
            }).catch(function (error) {
                console.log(error);
            });
        }

        var rot = 10 - 0;
        function begin() {
            var micon = document.getElementById("music-icon");
            if (micon == null) {
                window.clearInterval(timer);
                return;
            }
            micon.style.transform = "rotate(" + rot + "deg)";
            rot += 1;
        }

        $("#Play").on('click', function () {
            window.clearInterval(timer);
            if (audio.paused) {
                audio.play();
                Play();
                $("#Play").children("img").removeClass("play").addClass("pause");
            }
            else {
                $("#Play").children("img").removeClass("pause").addClass("play");
                audio.pause();
            }
        });

        function abook() {
            var windowRatio = 0.4;
            angular.forEach(vm.page, function (page) {
                page.mediabox = {
                    height: transitionPx(page.mediabox.height),
                    width: transitionPx(page.mediabox.width),
                    x: transitionPx(page.mediabox.x),
                    y: transitionPx(page.mediabox.y)
                }
                page.ratio = $scope.dWidth * windowRatio / page.mediabox.width;
                angular.forEach(page.levels.level, function (level) {
                    if(level.imagebox){
                        level.imagebox.blurredrectangle={
                            height: transitionPx(level.imagebox.blurredrectangle.height),
                            width: transitionPx(level.imagebox.blurredrectangle.width),
                            x: transitionPx(level.imagebox.blurredrectangle.x),
                            y: transitionPx(level.imagebox.blurredrectangle.y)
                        }
                    }
                })
            })
        }
        $scope.openMusicList = function () {
            $scope.musicPage = true;
            vm.musicIdentify = angular.copy(vm.article.musicIdentify);
        };
        $scope.backTo = function(){
            if(vm.article.musicIdentify != vm.musicIdentify){
                audio.src = $scope.domain + vm.article.musicIdentify;
            }
            $scope.musicPage = false;
        }
        $scope.playList = function (identify, index) {
            // audioTest.pause();
            audio.src = $scope.domain + identify;
            vm.musicIdentify = identify;
        };
        $scope.saveMusic = function(){
            if(vm.article.musicIdentify != vm.musicIdentify){
                vm.article.musicIdentify = vm.musicIdentify;
                audio.src = $scope.domain + vm.article.musicIdentify;
            }
            $scope.musicPage = false;
        }

        $scope.editorPhoto = function () {
            Product.get({id: vm.article.productId}, function (product) {
                $state.go("editorPhotoBothEdit", {storeId: vm.storeId, aid:  vm.article.id});
            })
        }

        $scope.goToCustomer = function () {
            if(vm.source){
                uni.redirectTo({
                    url: vm.returnurl1
                })
                return
            }
            window.location.href = vm.returnurl1;
        };
        $scope.save = function () {
            if(vm.source){
                uni.redirectTo({
                    url: vm.returnurl2
                })
                return
            }
            window.location.href = vm.returnurl2;
        }
        //边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.dropShadow = function(imagebox,ratio,ratio1){
            if (imagebox.shadow == null) {
                return
            }
            var width = $scope.imageboxW(imagebox) * ratio;
            var height =$scope.imageboxH(imagebox) * ratio1;
            return dropShadow(imagebox,width,height)
        }
    }
})();
