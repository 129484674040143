(function() {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$locationProvider', '$uibTooltipProvider'];

    function stateConfig($stateProvider, $locationProvider, $uibTooltipProvider) {
        if ($uibTooltipProvider) {
            $uibTooltipProvider.options({ appendToBody: true });
        }
        $stateProvider
        .state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        })
        .state('app2', {
            abstract: true,
            views: {
                'navbar2@': {
                    templateUrl: 'app/layouts/navbar/navbar_login.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        });
        $locationProvider.html5Mode({ enabled: true, requireBase: true });
    }
})();
