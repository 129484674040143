(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductNewEcController', ProductNewEcController);

    ProductNewEcController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'Gallery', 'ItemValue', 'SweetAlert', 'Theme', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'CommonService', '$http', 'Folder', 'MessageNotification', 'ThemeClassify', 'Article','ListService',
    'WxJS', 'StoreWorkEditConfig', '$translate', '$localStorage', 'IMAGE_SUPPORTED_MIME'];

    function ProductNewEcController($state, $scope, $q, $stateParams, Convert, Qinius, Gallery, ItemValue, SweetAlert, Theme, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, CommonService, $http, Folder, MessageNotification, ThemeClassify, Article, ListService,
                                    WxJS, StoreWorkEditConfig, $translate, $localStorage, IMAGE_SUPPORTED_MIME) {

        var vm = this;
        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            e.preventDefault();
        };
        var variableDatas = JSON.parse(localStorage.getItem('variableData'));
        vm.variableTextDatas = [];
        vm.variableBoxDatas=[];
        console.log(variableDatas)
        $scope.agentIos = false;

        vm.windowDpiW = js_getDPI();

        //风格切换  引入不同的css文件
        Theme.judge();

        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.isEdit = vm.pid ? false : true;
        vm.pattern = 'BOOK';
        vm.replace = "true";
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        $scope.status = localStorage.getItem('Production');

        vm.supportedMimeTypes = IMAGE_SUPPORTED_MIME;

        $scope.countN = parseInt(localStorage.getItem('countN'));
        var tt = localStorage.getItem('guide-step');
        if ($scope.status == 'make' && tt) {
            localStorage.setItem('countN', 8);
            $scope.countN = 8;
        } else {
            $scope.countN = parseInt(localStorage.getItem('countN'));
        }

        if (tt == 'true') {
            vm.guideStep = true;
        } else if (tt == 'false' || tt == '' || tt == null || tt == undefined) {
            vm.guideStep = false;
        }
        $scope.domains = Qinius.getDomain();
        Qinius.get({}, function (res) {
            vm.token = res.token;
            vm.tokenTime = parseInt((res.expireAt - new Date().getTime()));
        });
        vm.platformSetting = PlatformWechatSetting.get();

        var monthStr = new Date().getMonth() + 1;
        var dayStr = new Date().getDate();
        var timestr = new Date().getFullYear() + "-" + (monthStr > 9 ? monthStr : "0" + monthStr) + "-" + (dayStr > 9 ? dayStr : "0" + dayStr);
        getMessageNotification();
        function getMessageNotification(){
           //工作区
            MessageNotification.getAllByCondition2({
                items: [
                    {key: "available", op: "=", value: "true"},
                    {key: "validate", op: ">=", value: timestr},
                    {key: "pageType", op: "=", value: "Gallery"}]
            }, function (res) {
                if(res.status == 200){
                    res.data.sort(compare('seq'))
                    vm.messageGallery = angular.copy(res.data);
                    vm.messageGalleryText = "\xa0\xa0\xa0\xa0\xa0\xa0";
                    angular.forEach(res.data, function (resp, i) {
                        vm.messageGalleryText += resp.content
                        if (i != res.data.length - 1) {
                            vm.messageGalleryText += "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                        }
                    })
                }else {
                    MessageService.error(res.message);
                }
            });
            // 平铺
            MessageNotification.getAllByCondition2({
                items: [
                    {key: "available", op: "=", value: "true"},
                    {key: "validate", op: ">=", value: timestr},
                    {key: "pageType", op: "=", value: "Tile"}]
            }, function (res) {
                if(res.status == 200){
                    res.data.sort(compare('seq'))
                    vm.messageTile = angular.copy(res.data);
                    vm.messageTileText = "\xa0\xa0\xa0\xa0\xa0\xa0";
                    angular.forEach(res.data, function (resp, i) {
                        vm.messageTileText += resp.content
                        if (i != res.data.length - 1) {
                            vm.messageTileText += "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                        }
                    })
                }else {
                    MessageService.error(res.message);
                }
            });
            //编辑
            MessageNotification.getAllByCondition2({
                items: [
                    {key: "available", op: "=", value: "true"},
                    {key: "validate", op: ">=", value: timestr},
                    {key: "pageType", op: "=", value: "Edit"}]
            }, function (res) {
                if(res.status == 200){
                    res.data.sort(compare('seq'))
                    vm.message = angular.copy(res.data);
                    vm.messageEdit = angular.copy(res.data);
                    vm.messageEditText = "\xa0\xa0\xa0\xa0\xa0\xa0";
                    angular.forEach(res.data, function (resp, i) {
                        vm.messageEditText += resp.content
                        if (i != res.data.length - 1) {
                            vm.messageEditText += "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                        }
                    })
                }else {
                    MessageService.error(res.message);
                }
            });
        }


        var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
        localStorage.setItem('url', url);

        vm.editHeight = document.documentElement.clientHeight;

        if (!window.__wxjs_is_wkwebview) {
            $scope.dHeight = vm.editHeight;
            // 禁止安卓默认事件
            var androidDefault = document.querySelector('.page-make');
            androidDefault.addEventListener('contextmenu', function (e) {
                e.preventDefault();
            });
        } else {
            $scope.dHeight = localStorage.getItem('windowMinH') || vm.editHeight;
        }

        var unitTransform = function (mm) {
            // mm换算成px  Math.ceil(mm / 25.4 * 300);
            // return (mm * 3) - 0;
            return mm / 25.4 * vm.windowDpiW;
        };
        var unitTransformPx = function (px) {
            // px换算成mm  Math.floor(px / 300 * 25.4)
            // return (px * 1 / 3).toFixed(2) - 0;
            return px / vm.windowDpiW * 25.4;
        };

        var $he = $(window).height() / 2;
        $('.arrowCircle-up').on('click', function (e) {
            e.preventDefault();
            var scrollTop = $(".edit-page-content").scrollTop();
            var cha = (scrollTop - $he) > 0 ? scrollTop - $he : 0;
            $(".edit-page-content").scrollTop(cha);
        });
        $('.arrowCircle-down').on('click', function (e) {
            e.preventDefault();
            var scrollTop = $(".edit-page-content").scrollTop();
            $(".edit-page-content").scrollTop(scrollTop + $he);
        });

        $scope.isSave = false;
        $rootScope.currentCarouselIndex = 0;
        $scope.selectSketchMenu = 'sketch-color';
        $scope.colorList = ['#ff001e', '#ff5c00', '#ff9700', '#61734d', '#838c97', '#8d4ac8', '#b8e986', '#7e6565', '#ffffff', '#f1e7d9', '#b5ce99', '#82c281', '#dac2ef', '#000', '#797979', '#7c330a', '#eec78d', '#444444', '#589ceb', '#4a7bc8', '#ddabab'];
        $scope.selectedColor = $scope.colorList[0];
        $scope.stickerList = [];
        $scope.selectedFont = '';
        $scope.domain = null;
        $scope.PAGELEN = 0;
        //底部模板栏，像素栏，图片栏高度:84+pading:8   =100
        $scope.mHeight = 84;
        //52+62+100 +20
        $scope.tHeight = 240;
        // 左移按钮
        $scope.visibleLeft = false;
        // 右移按钮
        $scope.visibleRight = false;
        // 编辑按钮
        $scope.showBarMenu = true;
        // 底部按钮栏
        $scope.showBottomMenu = true;
        $scope.showTempMenu = false;
        $scope.showCut = false;
        $scope.showText = false;
        $scope.loading = true;
        $scope.template = {pages: {page: []}};
        $scope.pageAllPx = {page: []};
        $scope.weixinFlag = localStorage.getItem('distinguish');
        $scope.template.maxPageHeight = 0;
        $scope.template.ulWidth = 0;
        vm.FirstSeven = 0;
        vm.flags = false;
        vm.isOrientation = false;
        vm.counts = localStorage.getItem('article-count') || 0;


        // 成功多少张
        var indd = 0;
        var errorImageArr = [];
        var initValue = 0;

        vm.galleryPage = [];
        vm.loadMore = loadMore;
        vm.indexNum = 0;
        vm.itemsPerPage = 20;
        vm.more = true;


        var mL = 0, liW = 0, ulW = 0, selectTextIndex = 0;
        var runR = true, runL = true, flag = true, isDragging = false, moveSticker = false, moveText = false;
        var drag;
        var dragBox = {}, stickerDragBox = {};
        var goes = [], local_pages = [];
        var $ul = $(".carousel5");
        var $li = $(".template-carousel");
        $li.hide();
        vm.fontuuids = [];

        function getLocalStorage() {
            // 工作区中编辑作品所用到的图片
            $scope.qiniuId = JSON.parse(localStorage.getItem("gallery")) || [];
            // $scope.template.pages数据
            local_pages = JSON.parse(localStorage.getItem("pages")) || [];
        }

        if ($scope.status == 'make' || $scope.status == 'view') {
            goToMake()
        }

        //这一步是分页下拉  刷新滚动条置顶
        window.scrollTo(0, 0);

        function loadAll(folderId) {
            if (folderId == 'isNull') {
                Gallery.storeByCondition({
                    items: [{key: "folderId", op: "isNull", value: ''},{key: "articleId", op: "=", value: vm.aid}],
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sorts()
                }, onSuccess, onError);
            } else {
                Gallery.storeByCondition({
                    items: [{key: "folderId", op: "=", value: folderId},{key: "articleId", op: "=", value: vm.aid}],
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sorts()
                }, onSuccess, onError);
            }
        }

        function sorts() {
            return ["id,desc"];
        }

        function onSuccess(res) {
            if(res.status == 200){
                if (res.data.length < vm.itemsPerPage) {
                    vm.more = false;
                    flag = false;
                } else {
                    vm.more = true;
                    flag = true;
                }
                angular.forEach(res.data, function (item) {
                    item.usenum = 0;
                    item.identifier = $scope.domain + item.identifier;
                    vm.galleryPage.push(item);
                });
                getPagePhoto();
            }else {
                MessageService.error(res.message);
            }
        }

        function onError(error) {
            if(error.status !== 403){
                swal({
                    title: $translate.instant("galleryMessage.failedToObtainThePhotoGallery"),
                    text: "",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            }

        }

        function loadMore() {
            vm.indexNum++;
            if ($scope.folderInside) {
                loadAll($scope.folderOne.id);
            } else {
                loadAll('isNull');
            }
        }

        $scope.getCount = function () {
            if ($scope.qiniuId == null) {
                return vm.count;
            }
            if (vm.counts - $scope.qiniuId.length > 0) {
                return vm.counts - $scope.qiniuId.length;
            } else {
                return 0;
            }
        };

        //获取分页图片 (删除、上传都会重新刷新获取一定数量的照片)
        function getPagePhoto() {
            // console.log(vm.galleryPage);//这个是分页限制的数据格式 每次只显示10条
            var timeList = [];
            vm.photoArray = [];
            //判断日期是否重复
            for (var i = 0; i < vm.galleryPage.length; i++) {
                var photoCreatedDate = new Date(vm.galleryPage[i].createdDate);
                if (checkExist(timeList, photoCreatedDate)) {
                    timeList.push(photoCreatedDate);
                }
            }
            //将相同日期下的照片整合
            for (var m = 0; m < timeList.length; m++) {
                var tempObj = {};
                var tempItems = [];
                for (var j = 0; j < vm.galleryPage.length; j++) {
                    var createdDate = new Date(vm.galleryPage[j].createdDate);
                    if (timeList[m].getFullYear() == createdDate.getFullYear() &&
                        timeList[m].getMonth() == createdDate.getMonth() &&
                        timeList[m].getDate() == createdDate.getDate()) {
                        tempItems.push(vm.galleryPage[j])
                    }
                }
                var time = timeList[m].getFullYear() + '年' + (timeList[m].getMonth() + 1) + '月' + timeList[m].getDate() + '日';
                tempObj = {"createDate": time, "galleries": tempItems};
                vm.photoArray.push(tempObj);
            }
            $scope.photos = vm.photoArray;
            $(".photo-show").show();
        }

        /**
         * 加载七牛域名和localStorage
         */
        $q.all([$scope.domains.$promise, vm.platformSetting.$promise]).then(function () {
            vm.platformSetting = vm.platformSetting.data;
            /**
             * wechat api
             */

            vm.lagnuages =[];
            if ($localStorage.lagnuages) {
                for(var i = 0 ; i<$localStorage.lagnuages.length; i++ ){
                    var lang = $localStorage.lagnuages[i];
                    var langObj = {key:lang};
                    if (lang == 'zh-cn') {
                        langObj.value = "中文";
                    }
                    if (lang == 'en') {
                        langObj.value = "English";
                    }
                    if (vm.lang == lang) {
                        vm.langValue = langObj.value;
                        langObj.selected = true;
                    }
                    vm.lagnuages.push(langObj);
                }
            }

            if (wx) {
                var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
                WxJS.get({url: url}, function (res) {
                    if(res.status==200){
                        $scope.agentIos = browserVersions();
                        console.log("agentIos: "+$scope.agentIos)
                        var config = res.data;
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.appId, // 必填，公众号的唯一标识
                            timestamp: config.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.nonceStr, // 必填，生成签名的随机串
                            signature: config.signature,// 必填，签名，见附录1
                            jsApiList: ['chooseImage', 'uploadImage', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function () {
                            console.log('wechat api ready!');
                            wx.hideMenuItems({
                                menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                            });
                        });
                        wx.error(function (res) {
                            console.log(res)
                        });
                    }

                }, function () {
                    console.log("wx config error");
                });
            }
            //设置回退事件
            $scope.bool = false;
            //监听浏览器返回的事件
            setTimeout(function () {
                $scope.bool = true;
            }, 2000);
            window.addEventListener("popstate", function (e) {
                if (window.location.href.indexOf("chooseNew") > 0 || window.location.href.indexOf("choosesNew") > 0) {
                    if ($scope.bool) {
                        swal({
                            title: $translate.instant("choose.editorMessage.worksNoSave"),
                            text: $translate.instant("choose.editorMessage.sendBackCatalog"),
                            imageUrl: Theme.sweetHintImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                    }
                    $(".folder-footer").show();
                    removeAlertClass();
                    $scope.bool = false;
                } else if (window.location.href == (vm.platformSetting.editorNotifyUrl + '/#/') || window.location.href == vm.platformSetting.editorNotifyUrl + '/#/login') {
                    if (vm.pid && vm.vid) {
                        $state.go('product-chooseNew-ec', {
                            storeId: vm.storeId,
                            pid: vm.pid,
                            vid: vm.vid,
                            xmlId: vm.xmlId,
                            aid: vm.aid,
                        }, {reload: false});
                    }else if (vm.aid) {
                        $state.go('product-choosesNew-ec', {
                            storeId: vm.storeId,
                            aid: vm.aid
                        }, {reload: false});
                    }
                } else if (window.location.href == (vm.platformSetting.editorNotifyUrl + '/#/login')) {
                    var url = "";
                    if (vm.pid && vm.vid) {
                        url = vm.platformSetting.editorNotifyUrl + '/#/' + vm.storeId + '/' + vm.pid + '/' + vm.vid + '/' + vm.aid + '/productThumbnail';
                        // $state.go('thumbnail',{storeId:vm.storeId,pid:vm.pid,vid:vm.vid,aid:vm.aid})
                    } else {
                        //编辑
                        url = vm.platformSetting.editorNotifyUrl + '/#/' + vm.storeId + '/' + vm.aid + '/view';
                    }
                    window.location.href = url;
                }
            }, false);
            function pushHistory() {
                var url = null;
                //新建
                if (vm.pid && vm.vid) {
                    url = vm.platformSetting.editorNotifyUrl + '/#/' + vm.storeId + '/' + vm.pid + '/' + vm.vid + '/' + vm.aid + '/productThumbnail';
                } else {
                    //编辑
                    url = vm.platformSetting.editorNotifyUrl + '/#/' + vm.storeId + '/' + vm.aid + '/view';
                }
                var state = {
                    title: "",
                    url: url
                };
                window.history.pushState(state, "");
            }

            pushHistory();

            //设置title
            CommonService.setDocumentTitle($translate.instant("choose.title"));
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            // token过期
            setTimeout(function fn() {
                Qinius.get(function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            loadAll('isNull');
            loadFolders();
            getItem();

            // alert($scope.agentIos)
            /**
             * 上传本地图片
             * 保存到七牛
             */
            $scope.chooseWx = function () {
                initValue = $scope.qiniuId.length;
                var upindex = 0;
                wx.chooseImage({
                    count: 9, // 默认9
                    sizeType: ['original'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (res) {
                        var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                        $(".save-pages").removeClass('hide1');
                        asyncGetImg(localIds);

                        function asyncGetImg(datas) {
                            $(".save-pages").addClass('hide1');
                            setTimeout(function () {
                                swal({
                                    title: $translate.instant("choose.editorMessage.startUploading",{datasLength:datas.length}),
                                    text: $translate.instant("choose.editorMessage.imagUploaSlow")+"\n"+$translate.instant("choose.editorMessage.pleaseWaitPatiently"),
                                    showConfirmButton: false,
                                    type: "info"
                                });
                            }, 500);

                            indd = 0; //成功张数
                            errorImageArr = []; //失败张数
                            var index_ = 0;
                            if (datas.length > index_) {
                                getImg2(datas, index_);
                            }
                        }

                        function getImg2(datas, index_) {
                            //上传到微信服务器
                            wx.uploadImage({
                                localId: datas[index_],
                                isShowProgressTips: 1,
                                success: function (res) {
                                    index_++;
                                    if (datas.length > index_) {
                                        getImg2(datas, index_);
                                    }
                                    swal({
                                        title: $translate.instant("choose.editorMessage.thePictureIsUploaded")+"(" + (indd + 1 + errorImageArr.length) + "/" + datas.length + ")....",
                                        text: $translate.instant("choose.editorMessage.imagUploaSlow")+"\n"+$translate.instant("choose.editorMessage.pleaseWaitPatiently"),
                                        showConfirmButton: false,
                                        type: "info"
                                    });
                                    Gallery.upload({
                                        identifiers: res.serverId,
                                        provider: "QINIU",
                                        fileName: '',
                                        imgSize: 0,
                                        folderId: $scope.folderOne ? $scope.folderOne.id : '',
                                        articleId:vm.aid
                                    }, function (resp) {
                                        if(resp.status==200){
                                            console.log('Gallery.upload服务器输出' + resp.data);
                                            qiniuToImg(resp.data);
                                            indd++;
                                            if (indd == datas.length) {
                                                setTimeout(function () {
                                                    var text = '';
                                                    if ($scope.status == 'start') {
                                                        text = (datas.length + initValue - $scope.qiniuId.length) > 0 ?  $translate.instant("choose.editorMessage.pictureRepetition",{pictureLength:(datas.length + initValue - $scope.qiniuId.length)}): ''
                                                    }
                                                    swal({
                                                        title: $translate.instant("choose.editorMessage.uploadCompleted"),
                                                        text: text,
                                                        imageUrl: Theme.sweetSuccedImg(),
                                                        confirmButtonText: $translate.instant("choose.editor.confirm")
                                                    });
                                                }, 1000);

                                                updatePic();

                                            } else if (datas.length == (indd + errorImageArr.length) && errorImageArr.length != 0) {
                                                SweetAlert.swal({
                                                    title: $translate.instant("choose.editorMessage.uploadCompleted"),
                                                    text: $translate.instant("choose.editorMessage.pictureRepetition",{pictureLength:errorImageArr.length}),
                                                    imageUrl: Theme.sweetHintImg(),
                                                    showCancelButton: true,
                                                    confirmButtonText: $translate.instant("choose.editor.confirm"),
                                                    cancelButtonText: $translate.instant("choose.editor.cancel")
                                                }, function (isConfirm) {
                                                    if (isConfirm) {
                                                        asyncGetImg(errorImageArr);
                                                    } else {
                                                        SweetAlert.swal({
                                                            title: $translate.instant("choose.editorMessage.pictureUploadingCancel"),
                                                            imageUrl: Theme.sweetHintImg(),
                                                            text: "",
                                                            timer: 2000,
                                                            showConfirmButton: false
                                                        });

                                                        updatePic();
                                                    }
                                                })

                                            }
                                        }
                                    }, function (error) {
                                        errorImageArr.push(datas[index_]);
                                        //最后一个失败了的话
                                        if (datas.length == (indd + errorImageArr.length)) {
                                            SweetAlert.swal({
                                                title: $translate.instant("choose.editorMessage.uploadCompleted"),
                                                text: $translate.instant("choose.editorMessage.pictureRepetition",{pictureLength:errorImageArr.length}),
                                                imageUrl: Theme.sweetHintImg(),
                                                showCancelButton: true,
                                                confirmButtonText:  $translate.instant("choose.editor.confirm"),
                                                cancelButtonText: $translate.instant("choose.editor.cancel")
                                            }, function (isConfirm) {
                                                if (isConfirm) {
                                                    asyncGetImg(errorImageArr);
                                                } else {
                                                    SweetAlert.swal({
                                                        title: $translate.instant("choose.editorMessage.pictureUploadingCancel"),
                                                        imageUrl: Theme.sweetHintImg(),
                                                        text: "",
                                                        timer: 2000,
                                                        showConfirmButton: false
                                                    });
                                                }
                                            })
                                        }
                                    });
                                },
                                error: function (error) {
                                    swal({
                                        title: $translate.instant("uploadContent.pictureUpload",{currentLenght:(indd + 1 + errorImageArr.length),datasLength:datas.length}),
                                        text: $translate.instant("uploadContent.greatBeach"),
                                        showConfirmButton: false,
                                        type: "info"
                                    });
                                    index_++;
                                    if (datas.length > index_) {
                                        getImg2(datas, index_);
                                    }
                                    errorImageArr.push(datas[index_]);
                                    //最后一个失败了的话
                                    if (datas.length == (indd + errorImageArr.length)) {
                                        SweetAlert.swal({
                                            title: $translate.instant("choose.editorMessage.uploadCompleted"),
                                            text: $translate.instant("choose.editorMessage.pictureRepetition",{pictureLength:errorImageArr.length}),
                                            imageUrl: Theme.sweetHintImg(),
                                            showCancelButton: true,
                                            confirmButtonText: $translate.instant("choose.editor.confirm"),
                                            cancelButtonText: $translate.instant("choose.editor.cancel")
                                        }, function (isConfirm) {
                                            if (isConfirm) {
                                                asyncGetImg(errorImageArr);
                                            } else {
                                                SweetAlert.swal({
                                                    title: $translate.instant("choose.editorMessage.pictureUploadingCancel"),
                                                    imageUrl: Theme.sweetHintImg(),
                                                    text: "",
                                                    timer: 2000,
                                                    showConfirmButton: false
                                                });
                                            }
                                        })
                                    }
                                }
                            });
                        }
                    }
                });
            };

            //非调用微信公众号上传图片 无9张限制 可多张上传 上传到七牛  并且可以返回路径在页面显示
            window.uploadPicMutiple = function ($event) {
                initValue = $scope.qiniuId.length;
                var files = [];
                vm.higherSize = [];
                for (var i = 0; i < $event.target.files.length; i++) {
                    var f = $event.target.files[i];
                    if(f.type =='image/png'||f.type =='image/jpeg'||f.type =='image/jpg' || !f.type){
                        f.imgSize = f.size;
                        if ( f.size / 1024 > 20480) {
                            vm.higherSize.push(i + 1);
                        }
                        files.push(f)
                    }
                }

                //系统检测到{{}}张大于20M的图片，已自动过滤\n请压缩后再尝试上传！
                if (vm.higherSize.length > 0) {
                    $scope.bigSizeTip = true;
                    $scope.higherSizeLen = vm.higherSize.length;
                    $scope.$digest();
                } else {
                    if(files.length > 0){
                        $(".save-pages").removeClass('hide1');
                        filterUpload(files);
                    }
                }
                $scope.filterBigSize = function () {
                    $scope.bigSizeTip = false;
                    console.log(files)
                    files = files.filter(function (item, index) {
                        return item.size / 1024 < 20480;
                    });
                    if (files.length == 0) {
                        updatePic();
                    } else {
                        filterUpload(files);
                    }
                }
            };
        });

        function getItem() {
            $scope.qiniuId = JSON.parse(localStorage.getItem("gallery")) || [];        //拿到 localStorage 中的图片数据
            localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));           //工作区中所有的图片信息

            localStorage.removeItem('go');
            $scope.multipartUpload = localStorage.getItem("multipartUpload");
            //新建页面
            if (vm.pid && $scope.status == 'start') {
                init();
            } else if (vm.aid && $scope.status == 'start') {      //编辑页面
                vm.counts = localStorage.getItem('article-count');
            }
        }

        /**************************************---图片上传操作---************start*******************************/
        function filterUpload(files) {
            $(".save-pages").addClass('hide1');
            asyncUpload(files)
        }

        function asyncUpload(files) {
            indd = 0;
            errorImageArr = [];
            setTimeout(function () {
                swal({
                    title: $translate.instant("uploadContent.startUploading",{datasLength:files.length}),
                    text: $translate.instant("uploadContent.greatBeach"),
                    showConfirmButton: false,
                    type: "info"
                });
            }, 500);
            for (var i = 0; i < 5 && i < files.length; i++) {
                var file = files[i];
                qiniuUpload(file, files, i);
            }
        }

        function qiniuUpload(file, files, index_) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "file-" + UidService.get());
            formData.append('token', vm.token);
            var reader = new FileReader();
            reader.readAsArrayBuffer(file)
            reader.onloadend = function (e){
                formData.append('crc32', CRC32.buf(new Uint8Array(this.result)));
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: true,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        swal({
                            title: $translate.instant("uploadContent.pictureUpload",{currentLenght:(indd + 1 + errorImageArr.length),datasLength:files.length}),
                            text: $translate.instant("uploadContent.greatBeach"),
                            showConfirmButton: false,
                            type: "info"
                        });
                        $.ajax({
                            type: "GET",
                            url: $scope.domain + data.key + "?imageInfo",
                            success: function (va) {
                                console.log(va)
                                if (va.orientation == "Left-bottom" || va.orientation == "Right-top" || va.orientation == "Left-top" || va.orientation == "Right-bottom") {
                                    file.width = va.height;
                                    file.height = va.width;
                                } else {
                                    file.width = va.width;
                                    file.height = va.height;
                                }
                                serviceUpload(data, file, files.length);
                            },
                            error: function (request) {
                                serviceUpload(data, file, files.length);
                            }
                        });
                        if (files.length > index_ + 1 && index_ >= 4) {
                            var f = files[index_ + 1];
                            qiniuUpload(f, files, index_ + 1);
                        }
                    },
                    error: function (request) {
                        errorImageArr.push(file);
                        swal({
                            title: $translate.instant("uploadContent.pictureUpload",{currentLenght:(indd + 1),datasLength:files.length}),
                            text: $translate.instant("uploadContent.greatBeach"),
                            showConfirmButton: false,
                            type: "info"
                        });
                        //最后一个失败了的话
                        if (files.length == (indd + errorImageArr.length)) {
                            SweetAlert.swal({
                                title: $translate.instant("choose.editorMessage.uploadCompleted"),
                                text: $translate.instant("choose.editorMessage.pictureRepetition",{pictureLength:errorImageArr.length}),
                                imageUrl: Theme.sweetHintImg(),
                                showCancelButton: true,
                                confirmButtonText: $translate.instant("choose.editor.confirm"),
                                cancelButtonText: $translate.instant("choose.editor.cancel")
                            }, function (isConfirm) {
                                if (isConfirm) {
                                    asyncUpload(errorImageArr);
                                } else {
                                    SweetAlert.swal({
                                        title: $translate.instant("choose.editorMessage.pictureUploadingCancel"),
                                        imageUrl: Theme.sweetHintImg(),
                                        text: "",
                                        timer: 2000,
                                        showConfirmButton: false
                                    });
                                    updatePic();
                                }
                            })
                        }
                    }
                });
            }
        }

        function serviceUpload(data, file, len) {
            Gallery.upload({
                identifiers: data.key,
                provider: "QINIU",
                fileName: file.name,
                imgSize: file.size,
                width: file.width,
                height: file.height,
                folderId: $scope.folderOne ? $scope.folderOne.id : '',
                articleId:vm.aid
            }, function (resp) {
                if(resp.status == 200){
                    indd++;
                    if (len == indd) {
                        setTimeout(function () {
                            var text = '';
                            if ($scope.status == 'start') {
                                text = (len + initValue - $scope.qiniuId.length) > 0 ? "存在" + (len + initValue - $scope.qiniuId.length) + "张照片重复\n请重新选择上传" : ''
                            }
                            swal({
                                title: $translate.instant("choose.editorMessage.uploadCompleted"),
                                text: text,
                                imageUrl: Theme.sweetSuccedImg(),
                                confirmButtonText: $translate.instant("choose.editor.confirm")
                            });
                            updatePic();
                        }, 1000);
                    } else if (len == (indd + errorImageArr.length) && errorImageArr.length != 0) {
                        SweetAlert.swal({
                            title: $translate.instant("choose.editorMessage.uploadCompleted"),
                            text: $translate.instant("choose.editorMessage.breakUpload",{errorImageArrLength:errorImageArr.length}),
                            imageUrl: Theme.sweetHintImg(),
                            showCancelButton: true,
                            confirmButtonText: $translate.instant("choose.editor.confirm"),
                            cancelButtonText: $translate.instant("choose.editor.cancel")
                        }, function (isConfirm) {
                            if (isConfirm) {
                                asyncUpload(errorImageArr);
                            } else {
                                SweetAlert.swal({
                                    title: $translate.instant("choose.editorMessage.pictureUploadingCancel"),
                                    imageUrl: Theme.sweetHintImg(),
                                    text: "",
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                updatePic();
                            }
                        })
                    }
                    qiniuToImg(resp.data);
                }else {
                    errorImageArr.push(file);
                    //最后一个失败了的话
                    if (len == (indd + errorImageArr.length)) {
                        SweetAlert.swal({
                            title: $translate.instant("choose.editorMessage.uploadCompleted"),
                            text: $translate.instant("choose.editorMessage.pictureRepetition",{pictureLength:errorImageArr.length}),
                            imageUrl: Theme.sweetHintImg(),
                            showCancelButton: true,
                            confirmButtonText: $translate.instant("choose.editor.confirm"),
                            cancelButtonText: $translate.instant("choose.editor.cancel")
                        }, function (isConfirm) {
                            if (isConfirm) {
                                asyncUpload(errorImageArr);
                            } else {
                                SweetAlert.swal({
                                    title: $translate.instant("choose.editorMessage.pictureUploadingCancel"),
                                    imageUrl: Theme.sweetHintImg(),
                                    text: "",
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                updatePic();
                            }
                        })
                    }
                }
            }, function (error) {
                errorImageArr.push(file);
                //最后一个失败了的话
                if (len == (indd + errorImageArr.length)) {
                    SweetAlert.swal({
                        title: $translate.instant("choose.editorMessage.uploadCompleted"),
                        text: $translate.instant("choose.editorMessage.pictureRepetition",{pictureLength:errorImageArr.length}),
                        imageUrl: Theme.sweetHintImg(),
                        showCancelButton: true,
                        confirmButtonText: $translate.instant("choose.editor.confirm"),
                        cancelButtonText: $translate.instant("choose.editor.cancel")
                    }, function (isConfirm) {
                        if (isConfirm) {
                            asyncUpload(errorImageArr);
                        } else {
                            SweetAlert.swal({
                                title: $translate.instant("choose.editorMessage.pictureUploadingCancel"),
                                imageUrl: Theme.sweetHintImg(),
                                text: "",
                                timer: 2000,
                                showConfirmButton: false
                            });
                            updatePic();
                        }
                    })
                }
            });
        }

        /**
         * 上传本地图片
         * 更新$scope.qiniuId
         * 更新localStorage       新建页面：pid?-gallery    编辑页面：aid?-gallery
         * @param photos
         */
        function qiniuToImg(photos) {
            if ($scope.status == 'gallery') {
                return
            }
            $scope.multipartUpload = localStorage.getItem("multipartUpload");

            $scope.qiniuId = JSON.parse(localStorage.getItem("gallery")) || [];
            photos.identifier = $scope.domain + photos.identifier;
            photos.usenum = 0;
            $scope.qiniuId.push(photos);

            //筛选出重复的  比较identifier
            $scope.qiniuId = uniqeByKeys($scope.qiniuId, ['id', 'identifier']);

            //更新缓存
            localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));
        }

        function obj2key(obj, keys) {
            var n = keys.length,
                key = [];
            while (n--) {
                key.push(obj[keys[n]]);
            }
            return key.join('|');
        }

        //去重操作
        function uniqeByKeys(array, keys) {
            var arr = [];
            var hash = {};
            for (var i = 0, j = array.length; i < j; i++) {
                var k = obj2key(array[i], keys);
                if (!(k in hash)) {
                    hash[k] = true;
                    arr.push(array[i]);
                }
            }
            return arr;
        }

        /**************************************---图片上传操作---*************end********************************/


        /**************************************---图片库操作---************start*******************************/
        // 返回工作区
        $scope.backToView = function () {
            if ($scope.folderInside) {
                $scope.hideFolderContent();
            }
            setProductionStorage('start');
            $scope.gallaryPic = [];
            angular.forEach($scope.photos, function (p, i) {
                p.select_photoAll = false;
                angular.forEach(p.galleries, function (g, j) {
                    g.select_photo = false
                });
            });
            window.scrollTo(0, 0);
        };

        // 上传图片后更新图片库/文件夹
        function updatePic() {
            if ($scope.folderOne && $scope.folderOne.id) {
                $scope.showFolderContent($scope.folderOne);
            } else {
                $scope.folderInside = false;
                $scope.currentNav = 'gallery';
                $scope.moveGallery = false;
                $scope.delBar = false;
                vm.galleryPage = [];
                vm.indexNum = 0;
                loadAll('isNull');
            }
        }

        //初始化的时候判断照片是否可选
        var arrTemp = JSON.parse(localStorage.getItem("gallery")) || [];
        var temp = arrTemp.map(function (item) {
            return item.identifier;
        });
        $scope.justifySelect = function (gallery) {
            if (temp.length > 0) {
                for (var i = 0; i < temp.length; i++) {
                    if (temp.indexOf(gallery.identifier) > -1) {
                        gallery.select_photo = false;
                        gallery.flag = false;
                        return true;
                    } else {
                        gallery.flag = true;
                        return false;
                    }
                }
            } else {
                gallery.flag = true;
                return false;
            }
        };
        //下一步  点击图片框 选择图片库  这里图片无限制  单选
        $scope.selectThis2 = function (gallery, photo, index) {
            if (!gallery.flag) {
                return
            }
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            gallery.select_photo = !gallery.select_photo;
            for (var i = 0; i < photo.galleries.length; i++) {
                if (!photo.galleries[i].select_photo) {
                    photo.select_photoAll = false;
                    break;
                } else if (i == photo.galleries.length - 1) {
                    photo.select_photoAll = true;
                }
            }
            var t = 0;
            angular.forEach($scope.photos, function (p) {
                angular.forEach(p.galleries, function (g) {
                    if (g.select_photo) {
                        t++;
                        $scope.gallaryPic.push(g);       //选择导入图片的数组
                        $scope.moveGalleryIds.push(g.id);
                    }
                })
            });
            $scope.select_photo = t ? true : false;         //确认按钮的显示
            $scope.delBar = t ? true : false; //删除栏的显示
        };

        //下一步  点击图片框 选择图片库  这里图片无限制  全选
        $scope.selectedPhoto = function (index, photo) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            if ($scope.photos == '' || $scope.photos == null) {
                return;
            } else {
                if (photo.select_photoAll == null) {
                    photo.select_photoAll = true;
                } else {
                    photo.select_photoAll = !photo.select_photoAll;
                }
                $scope.delBar = true;
                angular.forEach(photo.galleries, function (g, i) {
                    g.select_photo = photo.select_photoAll;
                });

                if (photo.select_photoAll) {
                    $scope.select_photoAll = true;
                } else {
                    $scope.select_photoAll = false;
                }

                var t = 0;
                angular.forEach($scope.photos, function (p, i) {
                    angular.forEach(p.galleries, function (g, j) {
                        if (g.select_photo == true && g.flag == true) {
                            $scope.gallaryPic.push(g);      //选择导入图片的数组
                            $scope.moveGalleryIds.push(g.id);
                        }
                    });
                    if (!p.select_photoAll) {
                        t++;
                    }
                    if (i == $scope.photos.length - 1) {
                        if (t == $scope.photos.length && $scope.gallaryPic.length == 0) {
                            $scope.delBar = false;
                        }
                    }
                });
            }
        };

        //导入功能   判断所选图片是否符合要求
        $scope.confirmImport = function ($event) {
            $event.stopPropagation();
            //导入之后 项目库不再出现引导页
            $scope.qiniuId = JSON.parse(localStorage.getItem("gallery")) || [];
            cancelSelect($scope.qiniuId)
            $scope.multipartUpload = localStorage.getItem("multipartUpload");
            $scope.selectAllFolder = false;
            // 文件夹选择导入
            if ($scope.currentNav == 'folder') {
                var str = '';
                angular.forEach($scope.folderIds, function (f) {
                    str += f + ';'
                });
                var temp = $scope.qiniuId.map(function (item) {
                    return item.identifier;
                });

                Gallery.all({
                    items: [{key: "folderId", op: "in", value: str},{key: "articleId", op: "=", value: vm.aid}],
                    sort: sorts()
                }, function (resp) {
                    if(resp.status == 200){
                        angular.forEach(resp.data, function (p) {
                            angular.forEach(p.galleries, function (g, i) {
                                g.usenum = 0;
                                g.identifier = $scope.domain + g.identifier;
                                if (temp.length > 0) {
                                    for (var i = 0; i < temp.length; i++) {
                                        if (temp.indexOf(g.identifier) > -1) {
                                            // console.log(g.identifier)
                                            return;
                                        }
                                    }
                                }

                                if ((typeof g.parameter) == 'string') {
                                    g.parameter = JSON.parse(g.parameter);
                                }
                                // 从尾部添加
                                $scope.qiniuId.push(g);
                            })
                        });
                        setLocalstorage();
                        return;
                    }else {
                        MessageService.error(resp.message);
                    }
                });
            }
            angular.forEach($scope.gallaryPic, function (p, i) {
                if ((typeof p.parameter) == 'string') {
                    p.parameter = JSON.parse(p.parameter);
                }

                $scope.qiniuId.push(p);

            });
            setLocalstorage();
            if ($scope.folderInside) {
                $scope.hideFolderContent();
            }
        };

        function setLocalstorage() {
            localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));
            setProductionStorage('start');
            $scope.gallaryPic = [];
            angular.forEach($scope.photos, function (p, i) {
                p.select_photoAll = false;
                angular.forEach(p.galleries, function (g, j) {
                    g.select_photo = false
                });
            });

            cancelSelectFolders();
            $scope.selectAllGallery = $scope.delBar = false;
            window.scrollTo(0, 0);
        }

        /**************************************---图片库操作---*************end********************************/
        $scope.gallaryPic = [];         //个人图片库选择

        $scope.selectAllGallery = false;
        $scope.moveGalleryIds = [];
        /**
         * 初始化
         */
        function init() {
            ItemValue.get({id: $stateParams.vid}, function (res) {
                if(res.status == 200){
                    vm.itemValue = res.data;
                    $("body").toggleClass('bg');
                    $scope.status = localStorage.getItem('Production');
                    vm.productXmlLine = angular.copy(res.data.productXmlLines[0]);
                    angular.forEach(res.data.productXmlLines, function (line) {
                        if (line.id == vm.xmlId) {
                            vm.productXmlLine = angular.copy(line);
                        }
                    })
                    vm.article = {
                        cover: vm.productXmlLine.coverImg,
                        themeClassifyId: vm.productXmlLine.productXmlThemeId,
                        themeClassifyName: vm.productXmlLine.productXmlThemeName,
                        productXmlId: vm.productXmlLine.productXmlId,
                        productXmlName: vm.productXmlLine.productXmlName,
                        musicIdentify: vm.productXmlLine ? vm.productXmlLine.musicIdentify : res.data.musicIdentify,
                        sku: res.data.sku,
                        skuNumber: res.data.skuNumber,
                        name: res.data.productName,
                        productId: res.data.productId,
                        productName: res.data.productName,
                        categoryId: res.data.categoryId,
                        categoryName: res.data.categoryName,
                        categoryStoreId: res.data.categoryStoreId,
                        categoryStoreName: res.data.categoryStoreName,
                        editorType: res.data.productEditorType,
                        productBokehType: res.data.productBokehType,
                        itemValueId: res.data.itemValueId || vm.vid
                    };

                    vm.article.bokehtype = res.data.productBokehType;


                    var result = ListService.inList(vm.article, variableDatas, ["productXmlId"]);
                    if(result&&result.data&&result.data.length){
                        angular.forEach(result.data,function (va,i) {
                            if(va.type=='text'){
                                vm.variableTextDatas.push(va)
                            }
                            if(va.type=='box'){
                                vm.variableBoxDatas.push(va)
                            }
                        })
                    }
                    console.log(vm.variableTextDatas)
                    console.log(vm.variableBoxDatas)

                    $scope.isCanClick = false;
                    Convert.xmlToDocument({
                        identifier: vm.productXmlLine ? vm.productXmlLine.productXmlIdentifier : res.data.productXmlIdentifier,
                        provider: 'qiniu'
                    }, function (res) {
                        $scope.picArr = [];
                        vm.counts = 0;
                        var isChoose = JSON.parse(localStorage.getItem('picArrCopy'));
                        angular.forEach(res.pages.page, function (page, i) {
                            if (page.backup) {
                                if (i == res.pages.page.length - 1) {
                                    $scope.isCanClick = true;
                                }
                                return
                            }
                            var len = 0;
                            angular.forEach(page.levels.level, function (level) {
                                if (level.imagebox && level.imagebox.geometry) {
                                    len++;
                                    vm.counts++;
                                    if(vm.variableBoxDatas.length&&level.name){
                                        for (var _i = 0; _i < vm.variableBoxDatas.length; _i++) {
                                            if(vm.variableBoxDatas[_i].name==level.name){
                                                vm.counts--
                                            }
                                        }
                                    }
                                }
                            })

                            if (len && !isChoose) {
                                var arr = {
                                    pageI: i,
                                    maskI: len,
                                    index: -1
                                };
                                $scope.picArr.push(arr);
                            }

                            if (i == res.pages.page.length - 1) {
                                $scope.isCanClick = true;
                            }
                        });
                        if (!isChoose) {
                            localStorage.setItem('picArr', JSON.stringify($scope.picArr));
                            localStorage.setItem('picArrCopy', JSON.stringify($scope.picArr));
                            localStorage.setItem('picArrChoose', 0);
                        }
                    });
                }else {
                    MessageService.error(res.message);
                }
            });
        }

        $scope.delPhoto = function (photo, index) {
            var newArr = angular.copy($scope.qiniuId);
            newArr.splice(index, 1);
            $scope.qiniuId = newArr;
            localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));

            $scope.delPic = JSON.parse(localStorage.getItem('delPic')) || [];
            $scope.picI = parseInt(localStorage.getItem('picArrChoose'));
            var arr = $scope.picArr[$scope.picI];
            if (photo.seq && photo.seq.select) {
                $scope.delPic.push(photo.seq);
                $scope.delPic.sort(compare2('pageI', compare2('maskI')));
                arr = $scope.picArr[photo.seq.picI];
                arr.index -= 1;
                photo.seq = {
                    pageI: '',
                    maskI: '',
                    select: false
                };
                photo.pageI = '';
                photo.maskI = '';
            }
            localStorage.setItem('picArr', JSON.stringify($scope.picArr));
            localStorage.setItem('picArrChoose', $scope.picI);
            localStorage.setItem('delPic', JSON.stringify($scope.delPic));
        };

        /**
         * 显示图库div
         */
        $scope.showGallery = function () {
            setProductionStorage('gallery');
            window.scrollTo(0, 0);
            //初始化的时候判断照片是否可选
            var arrTemp = JSON.parse(localStorage.getItem("gallery")) || [];
            var temp = arrTemp.map(function (item) {
                return item.identifier;
            });
            $scope.justifySelect = function (gallery) {
                if (temp.length > 0) {
                    for (var i = 0; i < temp.length; i++) {
                        if (temp.indexOf(gallery.identifier) > -1) {
                            gallery.select_photo = false;
                            gallery.flag = false;
                            return true;
                        } else {
                            gallery.flag = true;
                            return false;
                        }
                    }
                } else {
                    gallery.flag = true;
                    return false;
                }
            };
        };

        //显示图片选择图标以及删除栏
        $scope.showDeleteBtn = function () {
            if (!$scope.photos.length) {
                $scope.selectAllGallery = $scope.delBar = false;
                swal({
                    title: "操作失败",
                    text: "没有图片选择",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 1500,
                    showConfirmButton: false
                });
                return
            }
            $scope.selectAllGallery = !$scope.selectAllGallery;
            $scope.delBar = $scope.selectAllGallery;

            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            isShowSelectDot($scope.selectAllGallery);
        };

        function isShowSelectDot(flag) {
            angular.forEach($scope.photos, function (p) {
                p.select_photoAll = flag;
                angular.forEach(p.galleries, function (g) {
                    g.select_photo = flag;
                    if (flag) {
                        $scope.gallaryPic.push(g);       //选择导入图片的数组
                        $scope.moveGalleryIds.push(g.id);
                    }
                });
            });
        }

        /**************************************---文件夹操作---************start*******************************/
        $scope.currentNav = 'gallery';
        // 是否进入文件夹内
        $scope.folderInside = false;
        // 选中的某一文件夹信息
        $scope.folderOne = {};
        // 是否点击移动按钮
        $scope.moveGallery = false;
        // 文件夹点击
        $scope.selectAllFolder = false;
        $scope.selectOne = {
            folderId: '',
            selected: false
        };
        $scope.folderIds = [];
        $scope.editFolderNameArr = [];

        function loadFolders() {
            Folder.storeGetAllByCondition({
                items: [{key: "type", op: "=", value: "Gallery"},{key: "articleId", op: "=", value: vm.aid}],
                sort: ["id,asc"]
            }, function (resp) {
                if(resp.status == 200){
                    $scope.Folders = resp.data;
                }else {
                    MessageService.error(resp.message);
                }
            })
        }

        // 增加文件夹
        $scope.addFolder = function () {
            addAlertClass();
            $(".folder-footer").hide();
            swal({
                title: $translate.instant("uploadContent.createAlbum"),
                text: "",
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonText: $translate.instant("choose.editor.confirm"),
                cancelButtonText: $translate.instant("choose.editor.cancel"),
                animation: "slide-from-top",
                inputPlaceholder: $translate.instant("choose.editorMessage.pleaseEnterAFolderName")
            }, function (inputValue) {
                if (inputValue === false) {
                    $(".folder-footer").show();
                    return false;
                }
                if (inputValue === "") {
                    swal.showInputError("请输入名称!");
                    return false
                }
                if (inputValue.indexOf(" ") != -1) {
                    swal.showInputError("请不要输入空格!");
                    return false
                }
                Folder.save({
                    name: inputValue,
                    type: 'Gallery',
                    articleId:vm.aid
                }, function (resp) {
                    if(resp.status == 200){
                        $scope.Folders.push(resp.data);
                        $(".folder-footer").show();
                        swal.close();
                        removeAlertClass();
                    }else {
                        MessageService.error(resp.message);
                    }
                }, function () {
                    swal.showInputError("请输入正确的文件名!");
                })
            });
        };
        function addAlertClass() {
            setTimeout(function () {
                $(".sweet-alert").addClass('folder-alert');
                $(".sa-button-container").addClass('folder-alert-top');
                $(".sa-button-container .cancel").addClass('folder-alert-right')
            }, 200)
        }

        function removeAlertClass() {
            setTimeout(function () {
                $(".sweet-alert").removeClass('folder-alert');
                $(".sa-button-container").removeClass('folder-alert-top');
                $(".sa-button-container .cancel").removeClass('folder-alert-right')
            }, 200)
        }

        // 修改文件夹名
        $scope.editFolder = function () {
            addAlertClass();
            var len = $scope.folderIds.length;
            if (len > 1 || !len) {
                return
            }
            var folder = $scope.editFolderNameArr;
            $(".folder-footer").hide();
            swal({
                title: $translate.instant("choose.editorMessage.rename"),
                text: "",
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonText: $translate.instant("choose.editor.confirm"),
                cancelButtonText: $translate.instant("choose.editor.cancel"),
                animation: "slide-from-top",
                inputPlaceholder: $translate.instant("choose.editorMessage.pleaseEnterAFolderName"),
                inputValue: folder.name
            }, function (inputValue) {
                if (inputValue === false) {
                    $(".folder-footer").show();
                    removeAlertClass();
                    return false;
                }
                if (inputValue === "") {
                    swal.showInputError($translate.instant("choose.editorMessage.pleaseEnterCompanyName"));
                    return false
                }
                if (inputValue.indexOf(" ") != -1) {
                    swal.showInputError($translate.instant("choose.editorMessage.pleaseDoNotEnterASpace"));
                    return false
                }
                if (inputValue === folder.name) {
                    swal.showInputError($translate.instant("choose.editorMessage.pleaseEnterADifferentFolderName"));
                    return false
                }
                Folder.update({
                    name: inputValue,
                    type: 'Gallery',
                    id: folder.id,
                    articleId:vm.aid
                }, function (resp) {
                    if(resp.status == 200){
                        cancelSelectFolders();
                        folder.name = resp.data.name;
                        $(".folder-footer").show();
                        swal.close();
                        removeAlertClass();
                    }else {
                        MessageService.error(resp.message);
                    }
                }, function () {
                    swal.showInputError($translate.instant("choose.editorMessage.pleaseEnterTheCorrectFileName"));
                })
            });
        };

        function cancelSelectFolders() {
            $scope.folderIds = [];
            angular.forEach($scope.Folders, function (item) {
                item.selected = false;
            });
        }

        /**
         * 单选
         * @param item
         * @param flag-----   true：文件夹     false:作品
         */
        $scope.selectSingle = function (item, flag) {
            if (!item) {
                $scope.selectOne = {
                    folderId: '',
                    selected: true
                };
                angular.forEach($scope.Folders, function (folder, index) {
                    folder.selected = false;
                });
                return
            }
            item.selected = !item.selected;
            // 移动状态中
            if ($scope.moveGallery) {

                $scope.selectOne = {
                    folderId: item.id,
                    selected: item.selected
                };

                angular.forEach($scope.Folders, function (folder, index) {
                    folder.selected = false;
                });
                item.selected = $scope.selectOne.selected;
                if (item.selected) {
                    $scope.folderOne = angular.copy(item);
                }
                return
            }

            $scope.selectOne = {
                folderId: '',
                selected: false
            };
            //未选中时
            if (!item.selected) {
                // 文件夹
                if (flag) {
                    $scope.selectAllFolder = false;
                    var ind = $scope.folderIds.indexOf(item.id);
                    if (ind >= 0) {
                        $scope.folderIds.splice(ind, 1);
                    }
                } else {
                    $scope.selectAllAritle = false;
                    var ind = $scope.moveGalleryIds.indexOf(item.id);
                    if (ind >= 0) {
                        $scope.moveGalleryIds.splice(ind, 1);
                    }
                }
            } else {
                if (flag) {
                    $scope.folderIds.push(item.id);
                }
            }
        };

        $scope.selectFolders = function (item) {
            item.selected = !item.selected;

            $scope.selectOne = {
                folderId: '',
                selected: false
            };
            //未选中时
            if (!item.selected) {
                $scope.selectAllFolder = false;
                var ind = $scope.folderIds.indexOf(item.id);
                if (ind >= 0) {
                    $scope.folderIds.splice(ind, 1);
                }

                $scope.editFolderNameArr = [];

            } else {
                $scope.folderIds.push(item.id);
                $scope.editFolderNameArr = item;
            }
            showConfirmBtn();
        };

        /**
         * 全选
         * @param flag-----  true：文件夹     false:作品
         */
        $scope.chooseAll = function (flag) {
            if (flag) {
                $scope.folderIds = [];
                $scope.editFolderNameArr = [];
                $scope.selectAllFolder = !$scope.selectAllFolder;
                angular.forEach($scope.Folders, function (item, index) {
                    if ($scope.selectAllFolder) {
                        item.selected = true;
                        $scope.folderIds.push(item.id);
                    } else {
                        item.selected = false;
                    }
                });

                showConfirmBtn();
            } else {
                $scope.selectAllGallery = !$scope.selectAllGallery;
                angular.forEach($scope.photos, function (photo) {
                    angular.forEach(photo.galleries, function (item) {
                        if ($scope.selectAllGallery) {
                            item.select_photo = true;
                            photo.select_photoAll = true
                        } else {
                            item.select_photo = false;
                            photo.select_photoAll = false
                        }
                    })
                })
            }
        };

        $scope.saveMoveToFolder = function () {
            if (!$scope.selectOne.selected) {
                swal({
                    title: $translate.instant("choose.editorMessage.operationFailure"),
                    text: $translate.instant("choose.editorMessage.pleaseSelectAFolder"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 1500,
                    showConfirmButton: false
                });
                return
            }
            angular.forEach($scope.Folders, function (folder, index) {
                folder.selected = false;
            });

            $(".prev-btn-gallery").show();

            Gallery.moveFolder({
                galleries: $scope.moveGalleryIds,
                folderId: $scope.selectOne.folderId,
                articleId: vm.aid
            }, function (res) {
                if(res.status == 200){
                    $scope.currentNav = 'gallery';
                    $scope.delBar = false;
                    $scope.gallaryPic = [];
                    $scope.moveGalleryIds = [];
                    $scope.moveGallery = false;
                    $(".photo-show").hide();
                    if ($scope.selectOne.folderId) {
                        $scope.folderInside = true;
                        vm.indexNum = 0;
                        vm.galleryPage = [];
                        loadAll($scope.folderOne.id);
                    } else {
                        $scope.folderInside = false;
                        $scope.delBar = false;
                        vm.galleryPage = [];
                        vm.indexNum = 0;
                        loadAll('isNull');
                    }
                    $scope.selectOne = {
                        folderId: '',
                        selected: false
                    };
                }else {
                    MessageService.error(res.message);
                }
            })
        };

        $scope.backToGallery = function () {
            $scope.currentNav = 'gallery';
            $scope.moveGallery = false;
            $scope.delBar = false;
            $scope.selectOne = {
                folderId: '',
                selected: false
            };
            angular.forEach($scope.Folders, function (folder) {
                folder.selected = false;
            });
            clearGalleryAdd();
            $(".prev-btn-gallery").show();
        };

        $scope.moveToFolder = function () {
            if (!$scope.gallaryPic.length) {
                swal({
                    title: $translate.instant("choose.editorMessage.failedToMove"),
                    text: $translate.instant("choose.editorMessage.pleaseSelectAtLeastOnePicture"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            $scope.moveGallery = true;
            $scope.delBar = true;
            $scope.folderInside = false;
            $(".prev-btn-gallery").hide();
            $scope.currentNav = 'folder';
        };
        // 进入某一文件夹中
        $scope.showFolderContent = function (folder) {
            if (folder.selected) {
                folder.selected = false;
                $scope.selectAllGallery = !$scope.selectAllGallery;
                $scope.selectOne = {
                    folderId: '',
                    selected: false
                };

                $scope.selectAllFolder = false;
                var ind = $scope.folderIds.indexOf(folder.id);
                if (ind >= 0) {
                    $scope.folderIds.splice(ind, 1);
                }
                $scope.editFolderNameArr = [];
                showConfirmBtn();
                return
            }
            $scope.folderInside = true;
            $scope.currentNav = 'gallery';
            $scope.folderOne = angular.copy(folder);
            vm.indexNum = 0;
            vm.galleryPage = [];
            $(".photo-show").hide();
            loadAll(folder.id);
        };
        // 从某一文件夹出去
        $scope.hideFolderContent = function () {
            $scope.folderInside = false;
            $scope.moveGallery = false;
            $scope.currentNav = 'folder';
            $scope.folderOne = {};
            $scope.selectAllGallery = $scope.delBar = false;
        };
        // 图片库nav 点击
        $scope.galleryNavSel = function () {
            $(".photo-show").hide();
            vm.galleryPage = [];
            $scope.currentNav = 'gallery';
            $(".folder-editbar").addClass('hide1');
            $(".arrow-btn").removeClass('folder-arrow-up');
            angular.forEach($scope.Folders, function (folder, index) {
                folder.selected = false;
            });
            $scope.folderIds = [];
            $scope.selectAllGallery = $scope.delBar = false;
            vm.indexNum = 0;
            $scope.folderOne = null;
            loadAll('isNull');
        };
        // 图片库nav 点击
        $scope.folderNavSel = function () {
            vm.galleryPage = [];
            $scope.currentNav = 'folder';
            $scope.selectAllGallery = $scope.delBar = false;
        };

        function showConfirmBtn() {
            if ($scope.folderIds.length > 0) {
                $scope.delBar = true;
            } else {
                $scope.delBar = false;
            }
        }

        function clearGalleryAdd() {
            $scope.moveGalleryIds = [];
            $scope.selectAllGallery = false;
            angular.forEach($scope.photos, function (photo) {
                photo.select_photoAll = false;
                angular.forEach(photo.galleries, function (item) {
                    item.select_photo = false;
                })
            })
        }

        /**************************************---文件夹操作---*************end********************************/


        /**************************************---照片排序操作---************start*******************************/
        /**
         * 照片排序
         * 实现照片拖动排序 不是替换 而是占位
         */
        $scope.photoSort = function () {
            if ($scope.qiniuId.length == 0) {
                swal({
                    title: $translate.instant("choose.editorMessage.notPicture"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            } else {
                setProductionStorage('sort');
                var picI = parseInt(localStorage.getItem('picArrChoose'));
                localStorage.setItem('picArrChoose2', picI);
                $scope.delPic = JSON.parse(localStorage.getItem('delPic')) || [];
                localStorage.setItem('gallery2', JSON.stringify($scope.qiniuId));
            }
        };

        $scope.delPic = [];
        $scope.selectPage = function (photo) {
            $scope.picArr = JSON.parse(localStorage.getItem('picArr'));
            $scope.picI = parseInt(localStorage.getItem('picArrChoose'));
            var arr = $scope.picArr[$scope.picI];

            if (photo.seq && photo.seq.select) {
                $scope.delPic.push(photo.seq);
                $scope.delPic.sort(compare2('pageI', compare2('maskI')));
                arr = $scope.picArr[photo.seq.picI];
                arr.index -= 1;
                photo.seq = {
                    pageI: '',
                    maskI: '',
                    select: false
                }
            } else if ($scope.delPic.length) {
                var delArr = $scope.delPic.shift();
                arr = $scope.picArr[delArr.picI];
                arr.index += 1;
                photo.seq = {
                    pageI: delArr.pageI,
                    maskI: delArr.maskI,
                    select: true,
                    picI: delArr.picI
                }
            } else {
                if (arr.index + 1 >= arr.maskI) {
                    if ($scope.picI + 1 < $scope.picArr.length) {
                        arr = $scope.picArr[++$scope.picI];
                    } else {
                        SweetAlert.swal({
                            title: $translate.instant("choose.editorMessage.photoFillingIsComplete"),
                            imageUrl: Theme.sweetHintImg(),
                            text: $translate.instant("choose.editorMessage.pleaseMakeItImmediately"),
                            timer: 1500,
                            showConfirmButton: false
                        });
                        return
                    }
                }
                arr.index += 1;
                photo.seq = {
                    pageI: arr.pageI,
                    maskI: arr.index,
                    select: true,
                    picI: $scope.picI
                }
            }

            photo.pageI = photo.seq.pageI;
            photo.maskI = photo.seq.maskI;
            localStorage.setItem('picArr', JSON.stringify($scope.picArr));
            localStorage.setItem('picArrChoose', $scope.picI);
            localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));
        };

        $scope.sureSort = function () {
            localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));
            localStorage.setItem("isSort", "true");
            setProductionStorage('start');
            var picArr = JSON.parse(localStorage.getItem("picArr")) || [];
            localStorage.setItem("picArrCopy", JSON.stringify(picArr));
            $scope.qiniuId = JSON.parse(localStorage.getItem("gallery")) || [];
            localStorage.setItem("gallery2", JSON.stringify($scope.qiniuId));
            var picI = parseInt(localStorage.getItem('picArrChoose'));
            localStorage.setItem('picArrChoose2', picI);
            localStorage.setItem('delPic', JSON.stringify($scope.delPic));
        };

        //返回上一页 （从排序页面返回到工作区）
        $scope.showStartBtn = function () {
            getItem();
            setProductionStorage('start');
            $scope.selectAllGallery = $scope.delBar = false;
            angular.forEach($scope.photos, function (p, i) {
                p.select_photoAll = false;
                angular.forEach(p.galleries, function (g, j) {
                    g.select_photo = false
                });
            });
            //这一步是分页下拉  刷新滚动条置顶
            window.scrollTo(0, 0);
            var picArrCopy = JSON.parse(localStorage.getItem("picArrCopy")) || [];
            localStorage.setItem("picArr", JSON.stringify(picArrCopy));
            $scope.qiniuId = JSON.parse(localStorage.getItem("gallery2")) || [];
            localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));
            var picI = parseInt(localStorage.getItem('picArrChoose2'));
            localStorage.setItem('picArrChoose', picI);
        };
        /**************************************---照片排序操作---*************end********************************/

        function cancelSelect(data) {
            $scope.photoPic = [];
            angular.forEach(data, function (item) {
                item.flg = false;
                item.photo_select = false;
            });
            t = 0;
        }

        /**
         * 下一步跳转页面
         * 移除localStorage    pid?-go
         */
        $scope.nextTo = function () {         //下一步跳转页面时移除“一键Go”的localStorage
            $scope.countN++;
            localStorage.setItem('countN', $scope.countN);
            localStorage.removeItem("go");
            removeOldPageStorage();
            localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));
            var t = 0;
            angular.forEach($scope.qiniuId, function (photo, i) {
                photo.usenum = 0;
                if (photo.width) {
                    photo.ratio = photo.width / photo.height;
                    if ((typeof photo.parameter) == 'string') {
                        photo.parameter = JSON.parse(photo.parameter);
                    }
                    localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));

                } else {
                    var newImg = document.createElement('img');
                    newImg.identifier = photo.identifier;
                    newImg.src = $scope.domain + photo.identifier + '?imageMogr2/strip';
                    newImg.onload = function () {
                        t++;
                        photo.width = newImg.width;
                        photo.height = newImg.height;
                        photo.ratio = newImg.width / newImg.height;
                        if ((typeof photo.parameter) == 'string') {
                            photo.parameter = JSON.parse(photo.parameter);
                        }
                        localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));
                    };
                }

            });
            setProductionStorage('view');
            goToMake();
        };

        /**
         * 一键Go 跳转页面
         * 设置localStorage    pid?-go
         */
        $scope.goTo = function () {
            var isHasLowPic = false;
            // if (!$scope.isCanClick) {
            //     return
            // }
            var isSort = localStorage.getItem('isSort');
            if (isSort) {
                $scope.qiniuId.sort(compare2('pageI', compare2('maskI')));
            }

            angular.forEach($scope.qiniuId, function (photo, i) {
                if (i < vm.counts) {
                    photo.usenum = 1;
                } else {
                    photo.usenum = 0;
                }
                if (photo.imgSize / 1024 < 150) {
                    isHasLowPic = true;
                }
                if ((typeof photo.parameter) == 'string') {
                    photo.parameter = JSON.parse(photo.parameter);
                }

                if (!photo.width) {
                    var newImg = document.createElement('img');
                    newImg.identifier = photo.identifier;
                    newImg.src = $scope.domain + photo.identifier + '?imageMogr2/strip';
                    newImg.onload = function () {
                        photo.width = newImg.width;
                        photo.height = newImg.height;
                        photo.ratio = newImg.width / newImg.height;
                        localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));
                    };
                } else {
                    photo.ratio = photo.width / photo.height;
                    localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));
                }
            });

            if (isHasLowPic) {
                SweetAlert.swal({
                        title: $translate.instant("choose.editorMessage.thereAreLowResolutionPhotos"),
                        imageUrl: Theme.sweetHintImg(),
                        showCancelButton: true,
                        closeOnConfirm: false,
                        confirmButtonText: $translate.instant("choose.editor.confirm"),
                        cancelButtonText: $translate.instant("choose.editor.cancel")
                    }, function (isConfirm) {
                        if (isConfirm) {
                            virtual();
                        } else {
                            return;
                        }
                    }
                )
            } else {
                virtual();
            }
        };

        function compare2(name, minor) {
            return function (o, p) {
                var a, b;
                if (o && p && typeof o === 'object' && typeof p === 'object') {
                    a = o[name];
                    b = p[name];
                    if (a === b) {
                        return typeof minor === 'function' ? minor(o, p) : 0;
                    }
                    if (typeof a === typeof b) {
                        return a < b ? -1 : 1;
                    }
                    return typeof a < typeof b ? -1 : 1;
                } else {
                    console.log("error");
                }
            }
        }

        //虚化展示
        function virtual() {
            if (vm.article.productBokehType!='None') {
                SweetAlert.swal({
                        title: $translate.instant("choose.editor.go"),
                        text: $translate.instant("choose.editorMessage.fillHint"),
                        imageUrl: Theme.sweetHintImg(),
                        showCancelButton: true,
                        confirmButtonText: $translate.instant("choose.editor.goOn"),
                        cancelButtonText: $translate.instant("choose.editor.sortBack"),
                    }, function (isConfirm) {
                        if (isConfirm) {
                            going();
                        } else {
                            return;
                        }
                    }
                )
            } else {
                swal.close();
                going();
            }
        };

        function going() {
            localStorage.setItem("go", JSON.stringify($scope.qiniuId));
            removeOldPageStorage();

            $scope.countN++;
            localStorage.setItem('countN', $scope.countN);
            setProductionStorage('view');
            goToMake();
        }

        function goToMake() {
            if (vm.pid) {
                if (localStorage.getItem('multipartUpload')) {
                    local_pages = JSON.parse(localStorage.getItem("pages")) || [];
                } else {
                    getLocalStorage();
                }
                // 一键Go
                goes = JSON.parse(localStorage.getItem("go"));

                // 新建作品
                //  显示/隐藏引导页
                $scope.isHide = localStorage.getItem('isHide') || false;
                if (!$scope.isHide) {
                    $(".come-in").show();
                }
                Article.get({id: $stateParams.aid}, function (resp) {
                    if (resp.status == 200) {
                        vm.article = resp.data;
                        ItemValue.get({id: $stateParams.vid}, function (res) {
                            if(res.status == 200){
                                vm.itemValue = res.data;
                                vm.pattern = vm.itemValue.patternType;
                                vm.productXmlLine = angular.copy(vm.itemValue.productXmlLines[0]);
                                angular.forEach(vm.itemValue.productXmlLines, function (line) {
                                    if (line.id == vm.xmlId) {
                                        vm.productXmlLine = angular.copy(line);
                                    }
                                })
                                vm.article.cover = vm.productXmlLine.coverImg;
                                vm.article.themeClassifyId = vm.productXmlLine.productXmlThemeId;
                                vm.article.productXmlThemeName = vm.productXmlLine.productXmlThemeName;
                                vm.article.productXmlId = vm.productXmlLine.productXmlId;
                                vm.article.productXmlName = vm.productXmlLine.productXmlName;
                                vm.article.musicIdentify = vm.productXmlLine ? vm.productXmlLine.musicIdentify : vm.itemValue.musicIdentify;
                                vm.article.itemValueId = vm.itemValue.itemValueId || vm.vid;

                                if (!vm.itemValue.productBokehType) {
                                    vm.article.bokehtype = 'None';
                                } else {
                                    vm.article.bokehtype = vm.itemValue.productBokehType;
                                }
                                Convert.xmlToDocument({
                                    identifier: vm.productXmlLine ? vm.productXmlLine.productXmlIdentifier : vm.itemValue.productXmlIdentifier,
                                    provider: 'qiniu'
                                }, function (res) {
                                    vm.replace = res.replace;
                                    setPageContent(res);
                                });
                            }else {
                                MessageService.error(res.message);
                            }
                        });
                    }
                })

                var workSave = {
                    pid: vm.pid,
                    vid: vm.vid,
                    xmlId: vm.xmlId,
                    date: new Date().getTime()
                };
                localStorage.setItem('work-save', JSON.stringify(workSave));
            } else if (vm.aid) {
                // 编辑作品
                Article.get({id: $stateParams.aid}, function (resp) {
                    if(resp.status == 200){
                        vm.article = angular.copy(resp.data);
                        ItemValue.get({id: resp.data.itemValueId}, function (res) {
                            if(res.status == 200){
                                vm.pattern = res.data.patternType;
                                vm.article.skuNumber = res.data.skuNumber;
                                vm.article.productBokehType = res.data.productBokehType;
                                vm.article.bokehtype = res.data.productBokehType;
                            }else {
                                MessageService.error(res.message);
                            }
                        });
                        Convert.xmlToDocument({
                            identifier: vm.article.fileName,
                            provider: 'qiniu'
                        }, function (res) {
                            vm.replace = res.replace;

                            if (!!res.galleries && !!res.galleries.gallery) {
                                localStorage.setItem("gallery", JSON.stringify(res.galleries.gallery));
                                // getLocalStorage();
                            }

                            getLocalStorage();
                            $scope.isHide = true;
                            setPageContent(res);
                        });
                    }else {
                        MessageService.error(resp.message);
                    }
                });
            }
        }

        function removeOldPageStorage() {
            localStorage.removeItem("saveOldPage");
            localStorage.removeItem("saveOldPageIndex");
            localStorage.removeItem("galleryCopy");
            localStorage.removeItem("delPic");
            localStorage.removeItem("picArrChoose");
            localStorage.removeItem("picArrChoose2");
            localStorage.removeItem("gallery2");
            localStorage.removeItem("picArrCopy");
            localStorage.removeItem("picArr");
            localStorage.removeItem("isSort");
        }isIphoneX

        $scope.goBackToProduct = function () {
            setProductionStorage('make');
        };
        function isIphoneX() {
            return /iphone/gi.test(window.navigator.userAgent) && window.screen.width === 375 && (window.screen.height === 812 || window.screen.height == 724);
        }

        $scope.isIphoneX = isIphoneX();

        var windowH = $(window).height();
        vm.wid = $(window).width();
        localStorage.setItem('windowH', windowH);

        $(window).scroll(function () {
            if ($scope.isIphoneX) {
                var wh = $(window).height(); //页面可视化区域高度
                if (wh > windowH) {
                    $(".iphoneX-content").height(36);
                    $(".footer_bar,.footer_pic").height(84);
                    $(".footer-title").height(76);
                } else {
                    $(".iphoneX-content").height(0);
                    $(".footer_bar,.footer_pic").height(48);
                    $(".footer-title").height(40);
                }
            }
            //    向下滑动加载照片
            var scrollTop = $(window).scrollTop();
            var widH = $(window).height();
            var docH = $(document).height();
            if (scrollTop + widH + 100 >= docH) {
                if (flag == true && localStorage.getItem('Production') == 'gallery') {
                    loadMore();
                } else {
                    return;
                }
            } else {
                return;
            }
        });

        $('.sticker-scroll').scroll(function () {
            var allWidth = 92 * $scope.stickerList.length - $(this).width() - 1;
            if ($(this).scrollLeft() >= allWidth && $scope.artlabelChooseId != null) {
                if ($scope.stickerList.length == vm.totalClipartItems) {
                    SweetAlert.swal({
                        title: $translate.instant("choose.editorMessage.allFilesHaveBeenLoaded"),
                        imageUrl: Theme.sweetHintImg(),
                        timer: 1000,
                        showConfirmButton: false
                    });
                    return
                }
                vm.indexArtNum++;
                getBackgrounds($scope.labels[$scope.artlabelChooseIndex], ClipArt, vm.indexArtNum, function (res) {
                    vm.totalClipartItems = res.items;
                    angular.forEach(res.arr, function (arr) {
                        $scope.stickerList.push(arr);
                    })

                });
            }
        });

        // 判断是否重复
        function checkExist(list, obj) {
            if (list == null || list.length == 0) {
                return true;
            }
            for (var i = 0; i < list.length; i++) {
                if (list[i].getFullYear() == obj.getFullYear() &&
                    list[i].getMonth() == obj.getMonth() &&
                    list[i].getDate() == obj.getDate()) {
                    return false;
                }
            }
            return true;
        }

        /**
         * Get document
         * @param obj
         */
        function setPageContent(obj) {
            var k = 0;
            // 正常页
            var arrUse = {
                pages: {
                    page: []
                }
            };
            // 备用页
            var arrUnUse = {
                pages: {
                    page: []
                }
            };
            vm.counts = 0;
            //新建
            if (vm.pid) {
                angular.forEach(obj.pages.page, function (page, index) {
                    angular.forEach(page.levels.level, function (level, n) {
                        if (level.imagebox && level.imagebox.image && level.imagebox.image.resource) {
                            level.imagebox.image = null;
                        }
                    })
                })
                var usebox = []; //模板中标记使用的页
                var unusebox = []; //模板中标记不使用的页
                for (var i = 0; i < obj.pages.page.length; i++) {
                    var page = obj.pages.page[i];
                    if (page.uuid == null) {
                        page.uuid = UidService.get();
                    }
                    if (!page.backup) {
                        page.count = 1;
                        arrUse.pages.page.push(page);
                        $scope.PAGELEN++;
                        k++;
                        angular.forEach(page.levels.level, function (level) {
                            if (level.imagebox && level.imagebox.geometry) {
                                vm.counts++;
                                if (k < 8 || $scope.PAGELEN <= 7) {
                                    vm.FirstSeven++;
                                }
                            }
                        })
                    }
                    var p = angular.copy(page);
                    //将模板所有页设为备用页
                    if (p.backup) {
                        p.count = 0;
                        unusebox.push(p);
                    } else {
                        p.backup = true;
                        usebox.push(p);
                    }
                }
                // 将备用页放在正常页面的后面
                // arrUnUse.pages.page = usebox.concat(unusebox);
                arrUnUse.pages.page = unusebox.concat(usebox);
            } else {
                for (var i = 0; i < obj.pages.page.length; i++) {
                    var page = obj.pages.page[i];
                    if (page.uuid == null) {
                        page.uuid = UidService.get();
                    }
                    if (!page.backup) {
                        arrUse.pages.page.push(page);
                        $scope.PAGELEN++;
                        k++;
                        angular.forEach(page.levels.level, function (level) {
                            if (level.imagebox && level.imagebox.geometry) {
                                vm.counts++;
                                if (k < 8 || $scope.PAGELEN <= 7) {
                                    vm.FirstSeven++;
                                }
                            }
                        })
                    } else {
                        arrUnUse.pages.page.push(page);
                    }
                }
                //计算页面使用次数
                for (var i = 0; i < arrUnUse.pages.page.length; i++) {
                    var p1 = arrUnUse.pages.page[i];
                    var count = 0;
                    for (var j = arrUse.pages.page.length - 1; j >= 0; j--) {
                        var p2 = arrUse.pages.page[j];
                        if (p1.uuid == p2.uuid) {
                            count++;
                        }
                    }
                    p1.count = count;
                }
            }

            obj.pages = angular.copy(arrUse.pages);

            vm.article.bgimgs2 = angular.copy(obj.bgimgs2);
            vm.article.documentpics = angular.copy(obj.documentpics);
            vm.article.masks = angular.copy(obj.masks);
            vm.article.cliparts = angular.copy(obj.cliparts);
            vm.cover = angular.copy(obj.cover);
            vm.article.document = choose(local_pages, obj);
            localStorage.setItem('article-count', vm.counts);


            //单页编辑 底下可选择的模板
            if (local_pages.page && local_pages.page.length > 0) {
                for (var i = 0; i < arrUnUse.pages.page.length; i++) {
                    var p = arrUnUse.pages.page[i];
                    var count = 0;
                    for (var j = 0; j < local_pages.page.length; j++) {
                        var p2 = local_pages.page[j];
                        if (p.uuid == p2.uuid) {
                            count++;
                        }
                    }
                    p.count = count;
                }
            }
            getAll2(arrUnUse);
            //平铺页   单页编辑
            getAll(vm.article.document);
            $scope.qiniuId = JSON.parse(localStorage.getItem("gallery")).sort(compare('usenum'));
            localStorage.setItem('gallery', JSON.stringify($scope.qiniuId));
            $scope.artlabelChooseId = $scope.labels[0].id || null;
            $scope.artlabelChooseIndex = 0;
            getRecommendSource(obj.cliparts.clipart, $scope.labels, ClipArt, vm.indexArtNum, 'CLIPART');
        }

        $scope.labels = [$translate.instant("choose.editorMessage.recommend")];
        vm.indexArtNum = 0;
        vm.totalClipartItems = 0;

        function getRecommendSource(objs, labels, url, num, attr) {
            var list = []
            angular.forEach(objs, function (obj, i) {
                var arr = {
                    name: obj.name,
                    identifier: obj.resource.identifier,
                    provider: obj.resource.provider,
                    themeClassifyName: $translate.instant("choose.editorMessage.recommend"),
                    themeClassifyId: null,
                    resource: obj.resource
                }
                list.push(arr);
            })

            var len = 0;
            if (objs && objs.length) {
                len = objs.length;
            }
            //分类
            ThemeClassify.getAllByCondition4({type: attr,storeId:vm.storeId}, function (res) {
                if(res.status == 200){
                    labels = angular.copy(res.data);

                    if (len) {
                        labels.unshift({
                            name: $translate.instant("choose.editorMessage.recommend"),
                            id: null
                        })
                        setSource(attr, list, labels)
                        vm.totalClipartItems = len;
                    } else if (labels.length) {
                        getBackgrounds(labels[0], url, num, function (res) {
                            vm.totalClipartItems = res.items;
                            setSource(attr, res.arr, labels);
                        });
                    }
                }else {
                    MessageService.error(res.message);
                }
            });
        }

        function setSource(attr, list, labels) {
            $scope.stickerList = angular.copy(list);
            vm.cliparts = angular.copy($scope.stickerList);
            $scope.labels = labels;
            $scope.artlabelChooseId = labels[0].id;
            $scope.artlabelChooseIndex = 0;
        }

        function getBackgrounds(label, url, num, callback) {
            var list = {
                items: 0,
                arr: []
            };
            url.byCondition3({
                items: [
                    {key: "themeClassify.id", op: "=", value: label.id}],
                page: num,
                size: vm.itemsPerPage,
                sort: ["seq,asc"]
            }, function (res, headers) {
                list.items = headers('X-Total-Count');
                if(res.status==200){
                    angular.forEach(res.data, function (resp) {
                        var arr = {
                            name: resp.name,
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                            themeClassifyName: resp.themeClassifyName,
                            themeClassifyId: resp.themeClassifyId,
                            resource: {
                                identifier: $scope.domain + resp.identifier,
                                provider: resp.provider,
                            }
                        }
                        list.arr.push(arr);
                    })
                    callback.call(this, list);
                }else{
                    swal({
                        title: res.message,
                        text: "",
                        imageUrl: Theme.sweetSuccedImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
            });
        }

        function showPages() {
            $timeout(function () {
                $scope.loading = false;
                $(".page-loading").hide().css({'opacity': 0});
                $li.show();
                $(".guide-view").removeClass('opacity-zero');
                // 等到刷新后直接赋值
                localStorage.setItem('pages', JSON.stringify($scope.template.pages));
            });

        }

        function choose(local_pages, res) {
            console.log($scope.picArr);
            // 一键Go
            if ((goes != null || goes != undefined) && goes.length && vm.vid) {
                var i = 0;
                angular.forEach(res.pages.page, function (page) {
                    if (page.backup) {
                        return
                    }
                    var arr = [];
                    angular.forEach(page.levels.level, function (level, j) {
                        // level.sort(compare('imagebox'));
                        var len = 0, img = level.imagebox;
                        if (img && img.geometry) {
                            len++;
                            if (!img.image) {
                                img.image = {}
                            }
                            if (len > 1) {
                                // 得到占位框的宽高比
                                img.ratio = img.geometry.width / img.geometry.height;
                                if (goes[i]) {
                                    goes[i].index = i;
                                    arr.push(goes[i]);
                                    i++;
                                }
                                console.log(j)
                                if (j == page.levels.level.length - 1) {
                                    // 按照占位框的宽高比排序
                                    page.levels.level.sort(compare('ratio'));
                                    // 按照工作区中图片的宽高比排序
                                    arr.sort(compare('ratio'));
                                }
                            } else {
                                if (!goes[i]) {
                                    return
                                }
                                if(level.variable){
                                    if(vm.variableBoxDatas.length&&level.name){
                                        var flag = true;
                                        for (var _i = 0; _i < vm.variableBoxDatas.length; _i++) {
                                            if(vm.variableBoxDatas[_i].name==level.name){
                                                flag = false;
                                                if(!vm.variableBoxDatas[_i].value){
                                                    vm.variableBoxDatas[_i].value = 'https://photoavatar-pic-dev.deltazo.com/file-463ea37f-1577-4451-a903-dc534e83f5f1'
                                                }
                                                vm.variableBoxDatas[_i].identifier = vm.variableBoxDatas[_i].value;
                                                $.ajax({
                                                    type: "GET",
                                                    url: vm.variableBoxDatas[_i].value + "?imageInfo",
                                                    async: false,
                                                    success: function (va) {
                                                        vm.variableBoxDatas[_i].imgSize = va.size;
                                                        if (va.orientation == "Left-bottom" || va.orientation == "Right-top" || va.orientation == "Left-top" || va.orientation == "Right-bottom") {
                                                            vm.variableBoxDatas[_i].width = va.height;
                                                            vm.variableBoxDatas[_i].height = va.width;
                                                        }else{
                                                            vm.variableBoxDatas[_i].width = va.width;
                                                            vm.variableBoxDatas[_i].height = va.height;
                                                        }
                                                        goes.splice(i,0,vm.variableBoxDatas[_i]);
                                                        img.image = imgParamter(img, goes[i], i);
                                                        if (vm.article.productBokehType!='None') {
                                                            img.blurredrectangle = {}
                                                        }
                                                        i++
                                                    },
                                                    error: function (request) {

                                                    }
                                                });
                                                break
                                            }
                                        }
                                        if(flag){
                                            img.image = imgParamter(img, goes[i], i);
                                            if (vm.article.productBokehType!='None') {
                                                img.blurredrectangle = {}
                                            }
                                            i++
                                        }
                                    }
                                }else{
                                    img.image = imgParamter(img, goes[i], i);
                                    if (vm.article.productBokehType!='None') {
                                        img.blurredrectangle = {}
                                    }
                                    i++
                                }
                            }
                        }
                    })
                });
            } else {
                // $scope.template.pages.page中的内容
                if (local_pages.page && local_pages.page.length > 0) {
                    // vm.counts = 0;
                    res.pages = angular.copy(local_pages);
                    angular.forEach(res.pages.page, function (p, index) {
                        // if (!p.backup) {
                        //     vm.counts += p.imageboxes.imagebox.length;
                        // }
                        angular.forEach(p.levels.level, function (level, i) {
                            var img = level.imagebox;
                            if (img && img.geometry && p && img.blobImage) {
                                img.blurredrectangle = img.blurredrectangle || {};
                                var imgIdentifier = "";
                                if (img.image && img.image.resource && img.image.resource.identifier) {
                                    imgIdentifier = img.image.resource.identifier;
                                }
                                if (!img.image) {
                                    img.image = {};
                                }
                                img.image = {
                                    resource: {
                                        identifier: imgIdentifier,
                                        provider: 'qiniu'
                                    },
                                    parameter: img.image.parameter || [],
                                    offsetx: img.image.offsetx || 0,
                                    offsety: img.image.offsety || 0,
                                    angle: img.image.angle || 0,
                                    scale: img.image.scale || 1,
                                    index: img.image.index ? img.image.index : vm.pid ? i :'',
                                    width: img.image.width || '',
                                    height: img.image.height || '',
                                    scaleW: img.image.scaleW || 1,
                                    scaleH: img.image.scaleH || 1,
                                    imgSize: img.image.imgSize || '',
                                    lowSize: img.image.lowSize == false ? false : true,
                                    resourceid: img.image ? img.image.sourceId ? img.image.sourceId : img.image.resourceid ? img.image.resourceid : '' : ''
                                };
                            }
                        })
                    });
                }
            }

            vm.article.document = {
                pages: res.pages
            };

            localStorage.removeItem("go");
            return res;
        }

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }

        function imgParamter(img, arr, i) {
            return {
                resource: {
                    identifier: img.image.resource && img.image.resource.identifier != '' ? img.image.resource.identifier : arr.identifier,
                    provider: 'qiniu',
                    width: arr.width,
                    height: arr.height
                },
                parameter: arr.parameter,
                offsetx: img.image.offsetx || 0,
                offsety: img.image.offsety || 0,
                angle: img.image.angle || 0,
                scale: img.image.scale || 1,
                index: i,
                width: arr.width,
                height: arr.height,
                imageInfo: img.image.imageInfo ?img.image.imageInfo: null,
                imgSize: arr.imgSize,
                resourceid: arr.sourceId,
                scaleH: img.image.scaleH || 1,
                scaleW: img.image.scaleW || 1,
            };
        }

        function getAll(document) {
            var marLeft = getSingleLocalStorage('marginLeft');
            $ul.css({'margin-left': -marLeft + 'px'});
            $rootScope.currentCarouselIndex = Math.abs(parseInt(marLeft / ($scope.dWidth * 0.94)));
            $scope.visibleLeft = $rootScope.currentCarouselIndex ? true : false;
            $scope.visibleRight = $rootScope.currentCarouselIndex + 1 == document.pages.page.length ? true : false;
            getFontList();
            //取得xml文档内容
            if (!(local_pages.length || local_pages.page && local_pages.page.length)) {
                getPageToPx1(document)
            } else {
                //刷新操作
                $scope.template.pages = angular.copy(local_pages);
                imageboxLow();
                vm.pageItem = angular.copy($scope.template.pages[$rootScope.currentCarouselIndex])
                f = vm.counts;
                showPages();
            }
        }

        function getAll2(document) {
            $scope.pageAllMm = angular.copy(document.pages);
            getPageToPx1(document, 'backup');
        }

        function getSingleLocalStorage(value) {
            return localStorage.getItem(value) || 0
        }

        function getPageToPx1(document, attr) {
            vm.template = angular.copy(document);
            documentAnalysis($scope, $http, vm, vm.template,FontManagement, 'gaoji', function(page,index){
                //平铺页面
                if ((index == 0 || index == $scope.PAGELEN - 1) && vm.pattern == 'BOOK') {
                    page.styleScale = $scope.dWidth * 0.6 / page.mediabox.width;
                } else {
                    page.styleScale = $scope.dWidth * 0.45 / page.mediabox.width;
                }
                // 编辑页面
                if (page.isMaxWidth) {
                    page.styleScale2 = $scope.dWidth * 0.94 / page.mediabox.width;
                } else {
                    page.styleScale2 = ($scope.dHeight - $scope.tHeight) / page.mediabox.height;
                }
                page.ratio = page.mediabox.width*page.styleScale / page.mediabox.width;
                page.ratio2 = page.mediabox.width*page.styleScale2 / page.mediabox.width

            }, function (res) {
                vm.thumbnailScale = 300 / res.pages.page[0].mediabox.width;
                if (attr == 'backup') {
                    $scope.pageAllPx = res.pages;
                } else {
                    $scope.template.pages = res.pages;
                    // if (vm.counts == 0 || vm.FirstSeven == 0) {
                    //     showPages();
                    // }
                    showPages();
                    $scope.isLeftRotation = false;
                    setTimeout(function(){
                        vm.thumbnailScale = 300 / $scope.template.pages.page[0].mediabox.width;
                        getSameUseNum();
                        imageboxLow();
                    },200)
                }

            })
        }

        function imageboxLow() {
            $scope.template.pages.page.forEach(function (page) {
                if(page && page.levels && page.levels.level){
                    page.levels.level.forEach(function (level) {
                        if(level.imagebox&&level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                            pxInsufficient(level.imagebox, vm.imgSize, vm.imgDpi)
                        }
                    })
                }
            })
        }
        function getSameUseNum() {
            angular.forEach($scope.template.pages.page, function (page, index) {
                angular.forEach(page.levels.level, function (level, i) {
                    var imagebox = level.imagebox;
                    if (imagebox && imagebox.image) {
                        for (var j = 0; j < $scope.qiniuId.length; j++) {
                            if ($scope.qiniuId[j].sourceId == imagebox.image.resourceid) {
                                imagebox.image.index = j;
                            }
                        }
                    }
                })
            })
        }

        function getScale(screenW, screenH, pageW, pageH) {
            if (screenW * 0.94 / (screenH - $scope.tHeight) < pageW / pageH) {
                //宽定，高不定
                return true;
            } else {
                //高定，宽不定
                return false;
            }
        }

        function getGeometryPx(page) {
            return {
                height: unitTransform(page.height),
                width: unitTransform(page.width),
                x: unitTransform(page.x),
                y: unitTransform(page.y)
            }
        }

        /*******************************************图片功能****************************start*********************************************/
        function getImageScale(imagebox, images) {
            var w0 = angular.copy(imagebox.width);
            var h0 = angular.copy(imagebox.height);
            if (imagebox.resource) {
                w0 = angular.copy(imagebox.resource.width);
                h0 = angular.copy(imagebox.resource.height);
                if (imagebox.angle == 90 || imagebox.angle == 270) {
                    w0 = angular.copy(imagebox.resource.height);
                    h0 = angular.copy(imagebox.resource.width);
                }
            } else {
                if (imagebox.angle == 90 || imagebox.angle == 270) {
                    w0 = angular.copy(imagebox.height);
                    h0 = angular.copy(imagebox.width);
                }
            }
            var x = 0, y = 0;

            var w = angular.copy(w0);
            var h = angular.copy(h0);
            var maskWidth = images.width;
            var maskHight = images.height;
            var isWidth = true;
            if ((w0 <= h0 || maskWidth <= maskHight) && (w0 / h0 < maskWidth / maskHight) || w0 >= h0 && maskWidth >= maskHight && (w0 / h0 < maskWidth / maskHight)) {
                w0 = maskWidth;
                h0 = maskWidth * h / w;
                isWidth = true;
            } else {
                h0 = maskHight;
                w0 = maskHight * w / h;
                isWidth = false;
            }

            var scaleW = w0 / w;
            var scaleH = h0 / h;
            x = x / scaleW;
            y = y / scaleH;
            return {
                isWidth: isWidth,
                nWidth: w0,
                nHeight: h0,
                scaleW: scaleW,
                scaleH: scaleH,
                x: x,
                y: y
            }
        }

        var f = 0;

        $scope.modeIn = function () {
            changeBlurred(true);
        };
        $scope.modeOut = function () {
            changeBlurred(false);
        };

        function changeBlurred(modeIn) {
            getCurrentImage();
            var imagebox = $scope.currentPage.levels.level[$scope.i].imagebox;

            if (modeIn) {
                imagebox.bokehtype = 'None'
                $(".cut-image").show();
            } else {
                imagebox.bokehtype = vm.article.bokehtype;
                imagebox.image.angle = 0;
                $(".cut-image").hide();
            }

            imgScale(imagebox, $scope.index, $scope.i, 0, 0, null, $scope, vm);
        }

        /**
         * 3种返回状态
         * 1： 平铺页面---->产品选择
         * 2： 图片编辑---->作品制作当前页
         * 3： 作品制作页面---->平铺页面
         */
        $scope.backTo = function () {
            var index = $rootScope.currentCarouselIndex;
            // 平铺页面---->产品选择
            if ($scope.status == 'view') {
                swal({
                    title: $translate.instant("choose.editorMessage.worksNotSave"),
                    text: "",
                    imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: $translate.instant("choose.editor.cancel"),
                    cancelButtonText: $translate.instant("choose.editor.confirm"),
                    closeOnConfirm: true
                }, function (isConfirm) {
                    if (isConfirm) {
                    } else {
                        $scope.bool = false;
                        if (vm.pid) {
                            $state.go('thumbnail',{storeId:vm.storeId,pid:vm.pid,vid:vm.vid,aid:vm.aid});
                        } else {
                            $state.go("product-view-ec", {storeId: vm.storeId, aid: vm.aid});
                        }
                        removeAll();
                    }
                }, function () {
                });
            }

            // 图片编辑---->作品制作当前页
            if ($scope.showCut) {
                $scope.showCut = false;
                $scope.showBottomMenu = true;
                $scope.template.pages.page[index].levels.level[$scope.template.levelI].imagebox = $scope.cutImagebox;
                $scope.cutImagebox = [];
                tipStatus(false);
                $(".edit-head").css({position: 'fixed'});
                return
            }
            // 文字---->作品制作当前页
            if ($scope.showTextModal) {
                $scope.showTextMenu = false;
                $scope.showTextModal = false;
                $scope.showBottomMenu = true;
                tipStatus(false);
                var levels = $scope.template.pages.page[index].levels.level
                if (vm.addText) {
                    levels.splice($scope.template.levelI, 1);
                    $scope.template.levelI = levels.length - 1;
                } else {
                    levels[$scope.template.levelI].textbox = angular.copy($scope.textCopy);
                }
                vm.addText = false;
                $scope.textCopy = [];
                tipStatus(false);
                return
            }

            // 作品制作页面---->平铺页面
            if ($scope.status == 'make') {
                swal({
                    title: $translate.instant("choose.editorMessage.pageNotSave"),
                    text: "",
                    imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: $translate.instant("choose.editor.cancel"),
                    cancelButtonText: $translate.instant("choose.editor.confirm"),
                    closeOnConfirm: true
                }, function (isConfirm) {
                    if (!isConfirm) {
                        $scope.template.pageI = null;
                        tipStatus(false);
                        $scope.showTempMenu = false;
                        $scope.isReplaceShow = false;
                        $scope.showStickerMenu = false;
                        setProductionStorage('view');
                        localStorage.removeItem('marginLeft');

                        $scope.galleryCopy = JSON.parse(localStorage.getItem("galleryCopy"));
                        localStorage.setItem('gallery', JSON.stringify($scope.galleryCopy));
                        $scope.qiniuId = $scope.galleryCopy;

                        var pageI = localStorage.getItem("saveOldPageIndex");
                        var oldPage = JSON.parse(localStorage.getItem("saveOldPage"));
                        if (vm.pageItem == null) {
                            $scope.template.pages.page[pageI] = angular.copy(oldPage);
                        } else {
                            $scope.template.pages.page[pageI] = angular.copy(vm.pageItem);
                            vm.pageItem = null;
                        }
                        vm.counts = 0;
                        angular.forEach($scope.template.pages.page, function (item, index) {
                            angular.forEach(item.levels.level, function (level) {
                                if (level.imagebox && level.imagebox.geometry) {
                                    vm.counts++;
                                }
                            })
                        });
                        angular.forEach($scope.pageAllPx.page, function (item, index) {
                            var count = 0;
                            angular.forEach($scope.template.pages.page, function (page, index) {
                                if (item.uuid == page.uuid) {
                                    count++;
                                }
                            });
                            item.count = count
                        });
                        $ul.css({marginLeft: '0px'});
                        $("#small-pic").addClass('hide1');

                        backToScrollTop();
                        $scope.$digest();
                        return;
                    }
                }, function () {
                });

            }
        };

        function backToScrollTop() {
            // 设置滚动距离
            var viewScrollTop = localStorage.getItem('view-scrollTop') || 0;
            if (!window.__wxjs_is_wkwebview) {
                $('.template-view').css("transform", "translateY(-" + viewScrollTop + "px)");
                setTimeout(function () {
                    $('.template-view').css("transform", "translateY(0px)");
                    $(".edit-page-content")[0].scrollTop = viewScrollTop;
                }, 10);
            } else {
                setTimeout(function () {
                    $(".edit-page-content")[0].scrollTop = viewScrollTop;
                }, 100);
            }

        }

        $scope.isFirstEdit = true;
        if ($scope.status == 'make') {
            $scope.isFirstEdit = false;
        }
        /**
         * 平铺页面点击进入作品制作页
         * @param page  点击页
         * @param index 点击页的下标
         */
        $scope.clickToEdit = function (page, index) {
            vm.isDocumentTextDetection=false;
            if (vm.sortStatus) {
                return
            }
            $rootScope.currentCarouselIndex = index;
            // 当引导页点击“不在提示”以后就不再count++;
            if (!vm.guideStep) {
                $scope.countN++;
                localStorage.setItem('countN', $scope.countN);
            }

            if (!vm.guideStep && ($scope.countN == 9 || $scope.countN == 10 || $scope.countN == 11 || $scope.countN == 12 || $scope.countN == 13)) {
                $('.tip-image').addClass('hide1');
            } else {
                $('.tip-image').removeClass('hide1');
            }

            var str = parseInt($('.template-view')[0].style.webkitTransform.replace(/[^0-9]+/g, '') || 0);
            var scrollT = parseInt($(".edit-page-content")[0].scrollTop);

            localStorage.setItem('view-scrollTop', scrollT + str);

            vm.pageIndex = index;
            vm.pageItem = angular.copy(page);
            setReviewStorage(index);
            setProductionStorage('make');

            $scope.showBottomMenu = true;

            $ul.css({marginLeft: -($scope.dWidth * 0.94 * index) + 'px'});

            $scope.visibleLeft = $rootScope.currentCarouselIndex ? true : false;
            $scope.visibleRight = $rootScope.currentCarouselIndex + 1 == $scope.template.pages.page.length ? true : false;

            $('.edit-menu-make').addClass('opacity-zero1');

            $scope.isFirstEdit = false;
            imageboxLow()
        };

        /**
         * 切换当前页所使用的模板
         * @param index     点击模板页下标
         * @param page      被点击模板页
         */
        $scope.changeTemp = function (index, page) {
            // 所要展示的page
            var currentInd = $rootScope.currentCarouselIndex;

            var currentPage = angular.copy($scope.template.pages.page[currentInd]);
            // 两个page 相同时不交换
            if (currentPage.uuid == page.uuid) {
                $(".text-tip").removeClass('hide1');
                setTimeout(function () {
                    $(".text-tip").addClass('hide1');
                }, 2000);
                return;
            }

            // 模板选中状态判断条件
            $scope.selectedLi = page.uuid;

            //使用次数+1
            page.count++;

            // 拷贝被点击模板     page不能受影响
            var clickPage = angular.copy(page);

            // 使用次数-1
            for (var i = $scope.pageAllPx.page.length - 1; i >= 0; i--) {
                var element = $scope.pageAllPx.page[i];
                if ($scope.template.pages.page[currentInd].uuid == element.uuid) {
                    element.count--;
                }
            }

            angular.forEach($scope.template.pages.page[currentInd].levels.level, function (level, l) {
                // 用户添加的"素材"需要留在当前页面
                if (level.imageart && level.imageart.geometry) {
                    if (level.imageart.useradded) {
                        var currentLevel = addLevel(clickPage);
                        currentLevel.imageart = level.imageart;
                    }
                }

                // 用户添加的"文字"需要留在当前页面
                if (level.textbox && level.textbox.geometry) {
                    if (level.textbox.useradded) {
                        var currentLevel = addLevel(clickPage);
                        currentLevel.textbox = level.textbox;
                    }
                }
            })

            // 开始交换
            $scope.template.pages.page[currentInd] = angular.copy(clickPage);
            $scope.template.pages.page[currentInd].backup = false;
            $scope.template.pages.page[currentInd].seq = currentInd;

            // 占位框从图片 有---->无 排序
            var arrs = [];
            angular.forEach(currentPage.levels.level, function (level) {
                if (level.imagebox && level.imagebox.geometry) {
                    var imagebox = level.imagebox;
                    if (imagebox.image && imagebox.image.resource && imagebox.image.resource.identifier) {
                        if ($scope.qiniuId.length && $scope.qiniuId[imagebox.image.index]) {
                            $scope.qiniuId[imagebox.image.index].usenum == 0 ? 0 : $scope.qiniuId[imagebox.image.index].usenum--;
                        }
                        arrs.push(imagebox)
                    }
                }
            })

            var i = 0;
            angular.forEach($scope.template.pages.page[currentInd].levels.level, function (level, l) {
                if (level.imagebox && level.imagebox.geometry) {
                    var imagebox = level.imagebox;
                    imagebox.bokehtype = vm.article.bokehtype;
                    if (vm.article.productBokehType!='None') {
                        imagebox.blurredrectangle = {}
                    }

                    verifyPic(arrs,i,imagebox,currentInd,l)
                    i++;
                }
            })
            vm.counts = 0;
            angular.forEach($scope.template.pages.page, function (item, index) {
                angular.forEach(item.levels.level, function (level) {
                    if (level.imagebox && level.imagebox.geometry) {
                        vm.counts++;
                    }
                })
            });
            setGalleryStorage();
        };


        function verifyPic(arrs,i,imagebox,currentInd,l){
            if (arrs[i]) {
                // $.ajax({
                //     type: "GET",
                //     url: arrs[i].image.resource.identifier + "?imageInfo",
                //     async: false,
                //     success: function (va) {
                //         console.log("i: "+i)
                //         if(va.format=='heic'){
                //             swal({
                //                 title: "照片格式错误",
                //                 text: "请更换使用JPG格式图片添加至相框！",
                //                 timer: 2000,
                //                 showConfirmButton: false
                //             });
                //             // i++;
                //             // return
                //         }else{
                            imagebox.imgUrl = arrs[i].imgUrl;
                            imagebox.image = angular.copy(arrs[i].image);
                            imgScale(imagebox, currentInd, l, 0, 0, 'center', $scope, vm);
                            if (imagebox.image && $scope.qiniuId.length && $scope.qiniuId[imagebox.image.index]) {
                                $scope.qiniuId[imagebox.image.index].usenum++;
                            }
                        // }
                //     }
                // });

            } else {
                imagebox.imgUrl = null;
                imagebox.image = {
                    resource: null,
                    filterResource: {
                        identifier: '',
                        provider: ''
                    },
                    offsetx: 0,
                    offsety: 0,
                    width: '',
                    height: '',
                    imgsize: '',
                    index: '',
                    parameter: [],
                    scale: 1,
                    angle: 0,
                }
                imagebox.blobImage = null;
            }

        }

        var moveLaR = function () {
            var page = $scope.template.pages.page[$rootScope.currentCarouselIndex];
            if ((window.orientation == 90 || window.orientation == -90) && vm.flags) {
                $li.removeClass('template-heng').addClass('template-heng2');
                var heights = $scope.dWidth * 0.94 * page.height / page.width - 1;
                $("#slide" + $rootScope.currentCarouselIndex).find(".slide-content").css({'height': heights});
            }
            $li.removeClass('template-heng3');
            tipStatus(false);
            $(".change-btn").hide();
            mL = Math.abs(parseFloat($ul.css('marginLeft')));
            liW = parseFloat($li[0].getBoundingClientRect().width);
            ulW = liW * $scope.PAGELEN;

            $scope.showBottomMenu = true;
        };

        /**
         * 左移按钮
         */
        $scope.pageLeft = function () {
            moveLaR();
            $scope.visibleRight = false;
            if (runL) {
                if (mL <= liW + 10) {
                    $scope.visibleLeft = false;
                }
                var index = $rootScope.currentCarouselIndex;
                $rootScope.currentCarouselIndex--;

                $ul.css({opacity: 0}).removeClass('fade-out').removeClass('is-paused');
                $ul.css({marginLeft: -(mL - liW) + 'px'});
                setTimeout(function () {
                    runL = true;
                    $ul.addClass('is-paused').addClass('fade-out').css({opacity: 1});
                }, 700);

                setReviewStorage($rootScope.currentCarouselIndex);
                setGalleryStorage();
                localStorage.setItem("marginLeft", $scope.dWidth * 0.94 * $rootScope.currentCarouselIndex);

                vm.pageIndex = $rootScope.currentCarouselIndex;
                vm.pageItem = angular.copy($scope.template.pages.page[vm.pageIndex]);
            }
            runL = false;
        };

        /**
         * 右移按钮
         */
        $scope.pageRight = function () {
            moveLaR();
            $scope.visibleLeft = true;
            if (runR) {
                if (mL + liW >= ulW - liW - 10) {
                    $scope.visibleRight = true;
                }
                $rootScope.currentCarouselIndex++;

                $ul.css({opacity: 0}).removeClass('fade-out').removeClass('is-paused');
                $ul.css({marginLeft: -(mL + liW) + 'px'});
                setTimeout(function () {
                    runR = true;
                    $ul.addClass('is-paused').addClass('fade-out').css({opacity: 1});
                }, 700);

                setReviewStorage($rootScope.currentCarouselIndex);
                setGalleryStorage();

                vm.pageIndex = $rootScope.currentCarouselIndex;
                vm.pageItem = angular.copy($scope.template.pages.page[vm.pageIndex]);
                localStorage.setItem("marginLeft", $scope.dWidth * 0.94 * $rootScope.currentCarouselIndex);
            }
            runR = false;
        };

        // 旋转屏幕
        window.addEventListener('orientationchange', function () {
            setTimeout(function () {
                $(".save-page").addClass('hide1');
                if (window.orientation == 180 || window.orientation == 0) {
                    //竖屏
                    $(".page-orientation").addClass('hide1');
                    if ($scope.isSave) {
                        return
                    }
                    orientationChange(false);
                    if (vm.isOrientation) {
                        vm.isOrientation = false;
                        return
                    }

                    var mar = ($scope.dWidth * 0.94 - $li.width()) / 2;
                    $li.css({'margin-left': mar}).removeClass('mar-top-0');
                } else if (window.orientation == 90 || window.orientation == -90) {
                    //横屏
                    $(".page-orientation").removeClass('hide1');
                    if ($scope.isSave) {
                        return
                    }
                    orientationChange(true);
                    var mar = ($scope.dWidth * 0.94 - $li.width()) / 2;
                    $li.css({'margin-left': mar}).addClass('mar-top-0');
                }
            }, 500);
        });

        if (window.orientation == 90 || window.orientation == -90) {
            $(".page-orientation").removeClass('hide1');
            vm.flags = false;
            vm.isOrientation = true;
            $(document.body).addClass('bgColor');
        }

        function orientationChange(flag) {
            vm.flags = flag;
            $scope.dHeight = document.documentElement.clientHeight;
            $scope.dWidth = document.documentElement.clientWidth;
            $(document.body).toggleClass('bgColor');

            $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});      //设置页面的最小宽度
            localStorage.setItem("marginLeft", $scope.dWidth * 0.94 * $rootScope.currentCarouselIndex);
        }

        /**
         * 放大缩小及删除
         */
        function changeImgOffset(zoomIn) {
            getCurrentImage();
            var imagebox = $scope.currentImage.image;

            var number = 1.2;
            if (zoomIn) {
                imagebox.scale = imagebox.scale ? imagebox.scale * number : number;
            } else {
                if (imagebox.scale.toFixed(2) <= 1) {
                    imagebox.scale = 1;
                    return;
                }
                imagebox.scale = imagebox.scale / number;
            }
            imagebox.offsetx = 0;
            imagebox.offsety = 0;
            $("#cut-img").css({left: imgX, top: imgY});

            $scope.moveImg = {
                width: $("#cut-opacity").width(),
                height: $("#cut-opacity").height(),
                angle: imagebox.angle
            };
            pxInsufficient($scope.currentImage, vm.imgSize, vm.imgDpi);
        }

        function getCurrentImage() {
            $scope.index = $rootScope.currentCarouselIndex || 0;
            $scope.i = $scope.template.pages.page[$scope.index].selectImgIndex;
            $scope.imgs = document.getElementById("imgInput" + $scope.index + '' + $scope.i);
            $scope.imgReals = $(".imgBox" + $scope.index + '' + $scope.i);
            $scope.currentPage = $scope.template.pages.page[$scope.index];
        }

        var boxW = 0, boxH = 0, picW = 0, picH = 0, scale = 0;

        function getDpi(box) {
            boxW = unitTransformPx(box.geometry.width);
            boxH = unitTransformPx(box.geometry.height);
            picW = box.image.resource.width;
            picH = box.image.resource.height;
            scale = box.image.scale;
            var x = 0, y = 0;
            // 宽度适配
            if (box.adaptation == 'Width') {
                x = picW / (boxW / 25.4) / scale;
                y = boxH * picW / boxW / (boxH / 25.4) / scale;
            } else {
                x = boxW * picH / boxH / (boxW / 25.4) / scale;
                y = picH / (boxH / 25.4) / scale;
            }
            if (x < 150 || y < 150) {
                box.image.lowSize = true;
            } else {
                box.image.lowSize = false;
            }
        }

        $scope.zoomIn = function () {
            changeImgOffset(true);
        };

        $scope.zoomOut = function () {
            changeImgOffset(false);
        };

        var imgX = parseFloat($(".cut-position").css('left'));
        var imgY = parseFloat($(".cut-position").css('top'));
        /**
         * 进入图片编辑模式
         */
        $scope.cutImage = function () {
            getCurrentImage();
            $scope.showCut = true;
            $scope.template.levelI = $scope.i;
            var box = $scope.currentImage = $scope.currentPage.levels.level[$scope.i].imagebox;
            $("#cut-mask").attr('src', '');

            var ww = angular.copy(box.geometry.width);
            var hh = angular.copy(box.geometry.height);
            $scope.cutImagebox = angular.copy($scope.currentPage.levels.level[$scope.i].imagebox);
            var s = box.maskUrl + '?imageMogr2/strip/rotate/' + box.image.angle;
            if (ww > hh) {
                ww = $scope.dWidth * 0.8;
                hh = ww / box.geometry.width * box.geometry.height;
            } else {
                hh = ($scope.dHeight - 50) * 0.8;
                ww = hh / box.geometry.height * box.geometry.width;
            }
            if (ww > $scope.dWidth * 0.8) {
                ww = $scope.dWidth * 0.8;
                hh = ww / box.geometry.width * box.geometry.height;
            }
            if (hh > ($scope.dHeight - 50) * 0.8) {
                hh = ($scope.dHeight - 50) * 0.8;
                ww = hh / box.geometry.height * box.geometry.width;
            }

            if (box.adaptation == 'Width') {
                $scope.wOpacity = ww;
                $scope.hOpacity = '';
                $scope.w = ww;
                $scope.h = '';
            } else {
                $scope.hOpacity = hh;
                $scope.wOpacity = '';
                $scope.h = hh;
                $scope.w = '';
            }

            $scope.moveGeo = {
                width: ww,
                height: hh,
                scale: ww / box.geometry.width
            };
            imgX = ($scope.dWidth - ww) / 2;

            $scope.t = imgY;
            $scope.l = imgX;
            ImageService.convertImgToBase64(s, function (s) {
                var imagecanvas = document.createElement('canvas');
                var imagecontext = imagecanvas.getContext('2d');
                var newImg = document.createElement('img');
                newImg.src = 'content/images/zhezhao1.png';
                newImg.onload = function () {
                    newImg.onload = null;
                    var masks = document.createElement('img');
                    masks.src = s;
                    masks.onload = function () {
                        masks.onload = null;
                        imagecanvas.width = $scope.dWidth;
                        imagecanvas.height = $scope.dHeight - 50;
                        imagecontext.drawImage(masks, $scope.l, $scope.t, ww, hh);
                        imagecontext.globalCompositeOperation = 'source-out';
                        imagecontext.drawImage(newImg, 0, 0, $scope.dWidth, $scope.dHeight - 50);
                        $("#cut-mask").attr('src', imagecanvas.toDataURL());
                        $scope.imgW = ww;
                        $scope.imgH = hh;
                        $scope.moveImg = {
                            width: $("#cut-opacity").width(),
                            height: $("#cut-opacity").height(),
                            angle: box.image.angle
                        };
                        var coordX = box.image.offsetx * box.image.scale * $scope.moveGeo.scale;
                        var coordY = box.image.offsety * box.image.scale * $scope.moveGeo.scale;
                        $("#cut-img").css({
                            left: coordX + imgX,
                            top: coordY + imgY
                        });

                        $scope.$digest();
                    };
                };
                newImg.src = 'content/images/zhezhao1.png';
            });
        };

        /**
         * 拖拽编辑的图片
         */
        window.startEdit = function (e) {
            fn(e);
            var box = $scope.currentImage;
            if ($scope.moveImg.width <= 0 || $scope.moveImg.height <= 0) {
                $scope.moveImg = {
                    width: $("#cut-opacity").width(),
                    height: $("#cut-opacity").height(),
                    angle: box.image.angle
                };
            }
            selectImgBoxes($scope.i);
            $scope.imageArr = getImageScale($scope.moveImg, $scope.moveGeo);
            dragBox.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
            dragBox.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
            dragBox.coordX = box.image.offsetx * box.image.scale * $scope.moveGeo.scale * $scope.imageArr.scaleW;
            dragBox.coordY = box.image.offsety * box.image.scale * $scope.moveGeo.scale * $scope.imageArr.scaleH;
            drag = true;
        };

        window.dragEdit = function (e) {
            getCurrentImage();
            var box = $scope.currentImage;

            //乘以放大缩小倍数后的宽高
            var orignW = $scope.imageArr.nWidth * box.image.scale;
            var orignH = $scope.imageArr.nHeight * box.image.scale;
            var isWidth = $scope.imageArr.isWidth;
            if (drag) {
                var x = 0, y = 0;
                //手指离开屏幕
                if (!isWidth && box.image.scale == 1) {    //宽图
                    x = (dragBox.coordX + (e.changedTouches[0].clientX - dragBox.clientX)) / $scope.imageArr.scaleW;
                } else if (isWidth && box.image.scale == 1) {     //高图
                    y = (dragBox.coordY + (e.changedTouches[0].clientY - dragBox.clientY)) / $scope.imageArr.scaleH;
                } else if (box.image.scale > 1) {
                    x = (dragBox.coordX + (e.changedTouches[0].clientX - dragBox.clientX)) / $scope.imageArr.scaleW;
                    y = (dragBox.coordY + (e.changedTouches[0].clientY - dragBox.clientY)) / $scope.imageArr.scaleH;
                }

                //向上滑动;
                if (-y >= (orignH - $scope.moveGeo.height - 1)) {
                    y = -(orignH - $scope.moveGeo.height - 1);
                }
                //向左滑动;        <-----
                if (-x >= (orignW - ($scope.moveGeo.width + 1))) {
                    x = -(orignW - ($scope.moveGeo.width + 1));
                }
                //向下滑动;
                if (y >= 0) {
                    y = 0;
                }
                // 向右滑动;    ---->
                if (x >= 0) {
                    x = 0;
                }

                $("#cut-img").css({left: x + imgX, top: y + imgY});

                box.image.offsetx = x / box.image.scale / $scope.moveGeo.scale;
                box.image.offsety = y / box.image.scale / $scope.moveGeo.scale;

                $scope.$digest();
                e.stopPropagation();
                e.preventDefault();
                return false;

            }

        };

        window.stopEdit = function () {
            drag = false;
            dragBox = {};
        };

        /**
         * 保存所编辑过的图片
         */
        $scope.saveMove = function () {
            getCurrentImage();
            var box = $scope.currentImage;
            $scope.showBottomMenu = true;

            var imageArr = getImageScale(box.image, box.geometry);
            box.image.scaleW = imageArr.scaleW;
            box.image.scaleH = imageArr.scaleH;
            tipStatus(false);

            box.blobImage = true;
            $scope.showCut = false;
            var newImg = document.createElement('img');
            var img = box.imgUrl + '?imageMogr2/strip/thumbnail/!20p';
            if (box.image.angle != 0) {
                img += '/rotate/' + box.image.angle
            }
            newImg.src = img;
            newImg.onload = function () {
                box.image.resource.width = angular.copy(newImg.width * 5);
                box.image.resource.height = angular.copy(newImg.height * 5);
                console.log(newImg.width)
                console.log(newImg.height)
                imgScale(box, $scope.index, $scope.i, box.image.offsetx, box.image.offsety, null, $scope, vm);
                $scope.$digest();
            }
            newImg.src = img;
        };

        /**
         * 选择图片
         * @param flag   true: 逆时针旋转    false： 顺时针旋转
         */
        $scope.rotateImage = function (flag) {
            var box = $scope.currentImage;
            if (flag) {
                box.image.angle -= 90;
                if (box.image.angle == -90) {
                    box.image.angle = 270
                }
            } else {
                box.image.angle += 90;
                if (Math.abs(box.image.angle) == 360) {
                    box.image.angle = 0
                }
            }
            $scope.moveImg.angle = box.image.angle;
            $scope.imageArr = getImageScale($scope.moveImg, $scope.moveGeo);

            var imageEdit = getImageScale(box.image, box.geometry);

            box.image.scaleW = imageEdit.scaleW;
            box.image.scaleH = imageEdit.scaleH;

            if (imageEdit.isWidth) {
                $scope.w = $scope.imageArr.nWidth;
                $scope.h = '';
            } else {
                $scope.h = $scope.imageArr.nHeight;
                $scope.w = '';
            }

            box.image.offsetx = 0;
            box.image.offsety = 0;

            $("#cut-img").css({left: imgX, top: imgY});

            $scope.moveImg = {
                width: $("#cut-opacity").width(),
                height: $("#cut-opacity").height(),
                angle: box.image.angle
            }
        };

        $scope.addGallery = function () {
            getCurrentImage();

            $(".change,.cut-image").hide();
            $(".mode1,.mode2").addClass('hide1');
            $('.edit-menu').removeClass('opacity-zero1');

            tipStatus(false);
            $scope.showBottomMenu = true;

            if (!$scope.qiniuId) {
                return
            }

            $scope.multipartUpload = 'multipartUpload';
            localStorage.setItem("multipartUpload", "multipartUpload");
            setProductionStorage('start');
            localStorage.setItem('article-count', vm.counts);
            cancelSelect($scope.qiniuId)
        };

        // 点击显示 占位框中的 切换按钮
        $scope.isReplaceShow = true;
        $scope.replaceImage = function () {
            var index = $rootScope.currentCarouselIndex || 0;
            var i = $scope.template.pages.page[index].selectImgIndex || 0;
            if ($scope.isReplaceShow) {
                $(".change-btn").show();
            } else {
                $(".change-btn").hide();
            }
            $("#change-btn" + index + '' + i).hide();
            $scope.isReplaceShow = !$scope.isReplaceShow;
        };

        // 切换占位框图片
        $scope.changeMaskImage = function (index, i, e) {
            fn(e);
            var index0 = $rootScope.currentCarouselIndex != null ?$rootScope.currentCarouselIndex: 0;
            var i0 = $scope.template.pages.page[index].selectImgIndex != null ?$scope.template.pages.page[index].selectImgIndex: 0;

            // 需要切换的占位框
            var box0 = $scope.template.pages.page[index0].levels.level[i0].imagebox;

            // 被切换的占位框
            var box = $scope.template.pages.page[index].levels.level[i].imagebox;

            if(box0.mask.resource.identifier&&box0.mask.resource.identifier.length){
                // $.ajax({
                //     type: "GET",
                //     url: box.image.resource.identifier + "?imageInfo",
                //     async: false,
                //     success: function (va) {
                //         if(va.format.toLowerCase()=='heic'){
                //             SweetAlert.swal({
                //                 title: '照片格式错误',
                //                 imageUrl: Theme.sweetHintImg(),
                //                 text: "请更换使用JPG格式图片添加至相框！",
                //                 timer: 2000,
                //                 showConfirmButton: false
                //             });
                //             $(".change-btn").hide();
                //             $('.tip-image').removeClass('hide1');
                //         }else{
                //             swapBox();
                //         }
                //     },
                //     error: function (request) {
                //
                //     }
                // });
                swapBox();
            }else if(box.mask.resource.identifier&&box.mask.resource.identifier.length){
                // $.ajax({
                //     type: "GET",
                //     url: box0.image.resource.identifier + "?imageInfo",
                //     async: false,
                //     success: function (va) {
                //         if(va.format.toLowerCase()=='heic'){
                //             SweetAlert.swal({
                //                 title: '照片格式错误',
                //                 imageUrl: Theme.sweetHintImg(),
                //                 text: "请更换使用JPG格式图片添加至相框！",
                //                 timer: 2000,
                //                 showConfirmButton: false
                //             });
                //             $(".change-btn").hide();
                //             $('.tip-image').removeClass('hide1');
                //         }else{
                //             swapBox();
                //         }
                //     },
                //     error: function (request) {
                //
                //     }
                // });
                swapBox();
            }else{
                swapBox();
            }


            function swapBox() {
                var img0 = box0.image;
                var imgUrl0 = box0.imgUrl;

                box0.image = box.image;
                box.image = img0;

                box0.imgUrl = box.imgUrl;
                box.imgUrl = imgUrl0;

                imgScale(box0, index0, i0, 0, 0, 'center', $scope, vm);
                imgScale(box, index0, i, 0, 0, 'center', $scope, vm);
                $(".change-btn").hide();
                $('.tip-image').removeClass('hide1');
                $scope.template.currentMenu = '';
            }
        };

        //这块是图片中需要上传的区域
        $scope.chooseImage = function ($index, i) {
            $scope.template.pages.page[$index].selectImgIndex = i;
            var box = $scope.template.pages.page[$index].levels.level[i].imagebox;
            $scope.i = i;
            $(".change,.cut-image").hide();
            $(".mode1,.mode2").addClass('hide1');
            $('.edit-menu').removeClass('opacity-zero1');
            $scope.showBottomMenu = true;
            $scope.showTextModal = false;
            $scope.showCut = false;

            tipStatus(true);
            if (box.image && box.image.resource && box.image.resource.identifier) {
                if (box.bokehtype != 'None') {
                    $(".mode1,.mode2").removeClass('hide1');
                }
                return
            }

            if (!$scope.qiniuId) {
                return
            }
            getCurrentImage();
            $scope.multipartUpload = 'multipartUpload';
            localStorage.setItem("multipartUpload", "multipartUpload");
            setProductionStorage('start');
            localStorage.setItem('article-count', vm.counts);
            cancelSelect($scope.qiniuId)
        };

        var selectImgBoxes = function ($index) {
            var index = $rootScope.currentCarouselIndex || 0;
            $scope.template.pages.page[index].selectImgIndex = $index;
        };
        var getCurrentPage = function (index) {
            if (typeof index == 'undefined') {
                index = getPageIndex();
            }
            return $scope.template.pages.page[index];
        };

        var getPageIndex = function () {
            return $rootScope.currentCarouselIndex || 0
        };

        var isImagebox = false;
        vm.isImageboxSelectItem = false;
        window.moveImageboxStart = function (e) {
            var index = getPageIndex();
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            var textIndex = targ.dataset.index || vm.levelIndex;
            selectImgBoxes(textIndex);
            vm.levelIndex = $scope.i = $scope.template.levelI = parseInt(textIndex);
            vm.levelType = 'imagebox';
            vm.isImageboxSelectItem = true;
            $scope.index = $scope.template.pageI = index;

            var currentPage = $scope.template.pages.page[$scope.index];
            var sticker = currentPage.levels.level[textIndex].imagebox;
            vm.cropImagebox = angular.copy(sticker);
            stickerDragBox.x = e.changedTouches[0].clientX || 0;          //鼠标到页面左边的距离
            stickerDragBox.y = e.changedTouches[0].clientY || 0;          //鼠标到页面顶部的距离
            stickerDragBox.l = sticker.image.offsetx * sticker.image.scale * currentPage.styleScale2;
            stickerDragBox.t = sticker.image.offsety * sticker.image.scale * currentPage.styleScale2;
            stickerDragBox.r = -(sticker.image.width * sticker.image.scale - sticker.geometry.width) * currentPage.styleScale2;
            stickerDragBox.b = -(sticker.image.height * sticker.image.scale - sticker.geometry.height) * currentPage.styleScale2;
            isImagebox = true;
            $scope.$apply();
            fn(e);
        }
        window.moveImageboxOn = function (e) {
            var currentPage = $scope.template.pages.page[$scope.index];
            var sticker = currentPage.levels.level[vm.levelIndex].imagebox;
            if (!isImagebox) {
                return
            }
            fn(e);
            var angle = Number(sticker.rotation.angle);
            var cx = (stickerDragBox.l + stickerDragBox.r) / 2;
            var cy = (stickerDragBox.t + stickerDragBox.b) / 2;
            var pt0 = rotatePoint({x: stickerDragBox.x, y: stickerDragBox.y}, {x: cx, y: cy}, -angle);
            var pt1 = rotatePoint({x: e.touches[0].pageX, y: e.touches[0].pageY}, {x: cx, y: cy}, -angle);
            var deltaX = pt1.x - pt0.x;
            var deltaY = pt1.y - pt0.y;
            var nl = stickerDragBox.l + deltaX;
            var nt = stickerDragBox.t + deltaY;
            if (nl < stickerDragBox.r) {
                nl = stickerDragBox.r
            };
            if (nl > 0) {
                nl = 0
            };
            if (nt < stickerDragBox.b) {
                nt = stickerDragBox.b
            };
            if (nt > 0) {
                nt = 0
            };

            sticker.image.offsetx = nl / sticker.image.scale / currentPage.styleScale2;
            sticker.image.offsety = nt / sticker.image.scale / currentPage.styleScale2;
            $scope.$apply();
        }
        window.moveImageboxEnd = function (e) {
            fn(e);
            isImagebox = false;
            var currentPage = $scope.template.pages.page[$scope.index];
            var box = $scope.template.pages.page[$scope.index].levels.level[$scope.template.levelI].imagebox;
            getCurrentImage();
            var imageLen = 0;
            angular.forEach($scope.template.pages.page[$scope.index].levels.level, function (level) {
                if (level.imagebox && level.imagebox.image && level.imagebox.image.resource) {
                    imageLen++;
                }
            })
            $.ajax({
                type: "GET",
                url: box.image.resource.identifier + "?imageInfo",
                success: function (va) {
                    if (box.image.resource.width == va.height && va.orientation == "Bottom-right") {
                        box.image.resource.width = va.width;
                        box.image.resource.height = va.height;
                        imgScale(box, $scope.index, $scope.i, 0, 0, null, $scope, vm);
                        return
                    }
                    if (va.orientation == "Left-bottom" || va.orientation == "Right-top" || va.orientation == "Left-top" || va.orientation == "Right-bottom") {
                        if (box.image.resource.width == va.height || box.image.angle == 90 || box.image.angle == 270) {
                            return
                        }
                        box.image.resource.width = va.height;
                        box.image.resource.height = va.width;
                        imgScale(box, $scope.index, $scope.i, 0, 0, null, $scope, vm);
                    }
                },
                error: function (request) {

                }
            });
            /**
             * 选中图片框   点击照片出现操作按钮
             */
            if (Math.abs(stickerDragBox.l / currentPage.styleScale2 - box.image.offsetx * box.image.scale) <= 6 && Math.abs(stickerDragBox.t / currentPage.styleScale2 - box.image.offsety * box.image.scale) <= 6) {
                stickerDragBox = {};
                selectImgBoxes($scope.i);

                tipStatus(true);

                if (vm.article.productBokehType!='None') {
                    $(".mode1,.mode2").removeClass('hide1');
                } else {
                    $(".mode1,.mode2").addClass('hide1');
                }

                if (box.bokehtype != 'None') {
                    $(".cut-image").hide();
                } else {
                    if (box.image && box.image.resource.identifier) {
                        $(".cut-image").show();
                    }
                }

                $(".change-btn").hide();
                $scope.isReplaceShow = true;
                if (imageLen < 2) {
                    $(".change").hide();
                } else {
                    $(".change").show();
                }

                $scope.showImageMenu = !$scope.showImageMenu;
                $scope.showStickerMenu = false;
                if ($scope.showImageMenu) {
                    $scope.showBottomMenu = false;
                } else {
                    $scope.showBottomMenu = true;
                }
                $scope.showTempMenu = false;

                $scope.$digest();

            } else {
                flag = true;
            }

            dragBox = {};
            $scope.$apply();
        }

        /*******************************************图片功能****************************end*********************************************/


        /*******************************************贴纸功能****************************start*********************************************/
        /**
         * 切换贴纸菜单
         */
        $scope.toggleStickerMenu = function ($event) {
            if ($event) {
                $event.stopPropagation();
            }
            $scope.showStickerMenu = !$scope.showStickerMenu;
            $scope.showBottomMenu = !$scope.showBottomMenu;
            if ($scope.showStickerMenu) {
                $scope.showTextMenu = false;
            }
        };

        $scope.selectLabel = function (label, index) {
            $scope.artlabelChooseId = label.id;
            $scope.artlabelChooseIndex = index;
            $scope.stickerList = [];
            vm.indexArtNum = 0;
            if (label.id == null) {
                vm.totalClipartItems = vm.cliparts.length;
                $scope.stickerList = angular.copy(vm.cliparts)
            } else {
                getBackgrounds(label, ClipArt, vm.indexArtNum, function (res) {
                    vm.totalClipartItems = res.items;
                    $scope.stickerList = res.arr;
                });
            }
        };

        /**
         * 显示模板
         */
        $scope.toggleTempMenu = function ($event) {
            if ($event) {
                $event.stopPropagation();
            }
            $scope.showTempMenu = !$scope.showTempMenu;
            $scope.showBottomMenu = !$scope.showBottomMenu;
            setUlWidth();
            getCurrentImage();
            $scope.selectedLi = $scope.currentPage.uuid;
        };

        function addLevel(currentPage) {
            $(".transparency").addClass("hide1");
            vm.isTransp = false;
            $(".coverage").addClass("hide1");
            vm.isCoverage = false;

            var arr = {
                seq: 1,
                imagebox: null,
                textbox: null,
                imageart: null,
                textdocument: null
            };

            if (!currentPage.levels.level) {
                currentPage.levels.level = [arr];
            } else {
                currentPage.levels.level.push(arr);
            }

            $scope.template.levelI = currentPage.levels.level.length - 1;
            var currentLevel = currentPage.levels.level[$scope.template.levelI];

            currentLevel.seq = $scope.template.levelI + 1;

            return currentLevel;
        }

        /**
         * 添加贴纸
         */
        $scope.addSticker = function (e, sticker) {
            $scope.template.pageI = $rootScope.currentCarouselIndex;
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            $scope.template.currentMenu = 'imageart';
            imageartAdd($http, sticker, currentPage.trimbox, function(imageart){
                var _num = 0;
                currentPage.levels.level.forEach(function (level) {
                    if(level.imageart){
                        _num+=10;
                    }
                });
                imageart.geometry.x += _num/currentPage.styleScale2;
                imageart.geometry.y += _num/currentPage.styleScale2;
                imageart.levelI = currentPage.levels.level.length;
                imageart.pageI = $scope.template.pageI;
                var _level = {
                    seq: currentPage.levels.level.length + 1,
                    imageart: imageart,
                }
                currentPage.levels.level.push(_level);
                console.log(currentPage)
            })
        };

        /**
         * 选中编辑贴纸
         * @param $event
         * @param $index
         */
        $scope.editSticker = function ($event, $index) {
            if ($event) {
                $event.stopPropagation();
            }
            $scope.template.levelI = $index;

        };

        /**
         * 缩放旋转贴纸
         * @type {boolean}
         */
        window.resizeStickerStart = function (e) {
            var currentPage = getCurrentPage();
            $scope.template.currentMenu = 'imageart';
            imageartZoomStart(e, currentPage.levels.level, 'outline1-solid', currentPage.styleScale2)
            fn(e);
        };

        window.resizeStickerOn = function (e) {
            var currentPage = getCurrentPage();
            var imageart = currentPage.levels.level[$scope.template.levelI].imageart;
            imageartZoomMove(e, imageart, currentPage.styleScale2,currentPage.trimbox);
            fn(e);
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();
            return false;
        };

        window.resizeStickerEnd = function () {
            imageartZoomEnd()
        };

        /**
         * 移动贴纸
         * @param e
         */
        $(".template-carousel").on("touchstart",".sticker-edit-box img", function (e) {
            var currentPage = getCurrentPage();
            vm.isImageboxSelectItem = false;
            imageartTouchstartStart(e, $scope.template.levelI, currentPage.levels.level, currentPage.styleScale2,currentPage.trimbox, function (imgIndex) {
                $scope.template.levelI = imgIndex;
                $scope.template.currentMenu = 'imageart';
            })
            fn(e);
            $scope.$digest();
        })
        $(".template-carousel").on("touchmove",".sticker-edit-box", function (e) {
            var currentPage = getCurrentPage();
            if (!e.originalEvent || e.originalEvent.touches.length == 1) {
                imagearOne(e, currentPage.levels.level[$scope.template.levelI].imageart, currentPage.styleScale2,currentPage.trimbox, function () {
                    vm.hint = !editboxIn(currentPage.editboxes.editbox , currentPage.levels.level[$scope.template.levelI].imageart.geometry, currentPage.levels.level[$scope.template.levelI].imageart.rotation.angle);
                });
                $scope.$digest();
            };
            if (e.originalEvent && e.originalEvent.touches.length >= 2) {
                var now = e.originalEvent.touches;
                Math.abs(e.originalEvent.touches[0].pageX - e.originalEvent.touches[1].pageX);
                imagearTouchstartTwo(function (imageartZoomItem) {
                    imagearTwo(e,getDistance(now[0], now[1]) - getDistance(imageartZoomItem.start[0], imageartZoomItem.start[1]),currentPage.levels.level[$scope.template.levelI].imageart, currentPage.trimbox, currentPage.styleScale2)
                })
                $scope.$digest();
            }
        })
        $(".template-carousel").on("touchend",".sticker-edit-box", function (e) {
            vm.hint = false;
            imageartZoomEnd();
            $scope.$apply();
        })

        /**
         * 删除贴纸
         * @param event
         * @param i
         */
        $scope.deleteSticker = function (event, i, index) {
            $scope.deleteAction(event, i);
        };

        $scope.deleteAction = function (e, i) {
            if (e) {
                fn(e);
            }
            var index = $rootScope.currentCarouselIndex || 0;
            var list = $scope.template.pages.page[index].levels.level
            list.splice(i, 1);
        }

        /*******************************************贴纸功能****************************end*********************************************/


        /*******************************************文字模板****************************start*********************************************/

        //文本框点击时

        $scope.selectDoc = function (e, $index, textdocument) {
            $scope.template.levelI = $index;
            $scope.template.angle = textdocument.rotation.angle;
            textdocument.current = 'textdocument';
            $scope.selectBox = textdocument;
        };

        var dragTextDoc = {};
        window.moveTextDocStart = function (e) {
            var $event = e || window.event;
            if ($event && $event.preventDefault) {
                $event.preventDefault();
            }
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            $scope.buttonShows = true;
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = targ.dataset.index;
            var currentPage = $scope.template.pages.page[$rootScope.currentCarouselIndex];
            var currentLevel = currentPage.levels.level[$scope.template.levelI];
            var currentImg = currentLevel.textdocument;
            $scope.template.angle = currentImg.rotation.angle;
            if (!currentImg.movable || !currentImg.useradded) {
                return
            }

            dragTextDoc.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
            dragTextDoc.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
            dragTextDoc.coordX = targ.offsetLeft;
            dragTextDoc.coordY = targ.offsetTop;
            dragTextDoc.x = currentPage.trimbox.x;
            dragTextDoc.y = currentPage.trimbox.y;
            dragTextDoc.w = currentPage.trimbox.width;
            dragTextDoc.h = currentPage.trimbox.height;
            drag = true;
        };
        //move 事件
        window.moveTextDocOn = function (e) {
            fn(e);
            $scope.$digest();
            if (e.target.nodeName == 'SPAN') {
                return;
            }
            if (drag) {
                var currentPage = $scope.template.pages.page[$rootScope.currentCarouselIndex];
                var pressImg = currentPage.levels.level[$scope.template.levelI].textdocument;

                var offsetx = dragTextDoc.coordX + (e.changedTouches[0].clientX - dragTextDoc.clientX);
                var offsety = dragTextDoc.coordY + (e.changedTouches[0].clientY - dragTextDoc.clientY);

                if (offsetx <= dragTextDoc.x) {
                    offsetx = dragTextDoc.x;
                }

                if (offsetx >= (dragTextDoc.w - pressImg.geometry.width + dragTextDoc.x)) {
                    offsetx = dragTextDoc.w - pressImg.geometry.width + dragTextDoc.x;
                }
                if (offsety <= dragTextDoc.y) {
                    offsety = dragTextDoc.y;
                }

                if (offsety >= (dragTextDoc.h - pressImg.geometry.height + dragTextDoc.y)) {
                    offsety = dragTextDoc.h - pressImg.geometry.height + dragTextDoc.y;
                }
                pressImg.geometry.x = offsetx;
                pressImg.geometry.y = offsety;

            }
            return false;
        }

        //鼠标松开事件
        window.moveTextDocEnd = function () {
            if (!drag) {
                return
            }
            drag = false;
            dragTextDoc = {};
            $scope.$digest();
        }

        window.rotateTextDocStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            var currentPage = $scope.template.pages.page[$rootScope.currentCarouselIndex];
            var currentLevel = currentPage.levels.level[$scope.template.levelI];
            var currentTextdocument = currentLevel.textdocument;
            if (currentTextdocument.lock) {
                return
            }
            drag = true;

            dragBox.clientX = e.clientX || 0;
            dragBox.clientY = e.clientY || 0;

            $scope.RightPanel = 'textDoc';


            dragBox.imgRotate = currentTextdocument.rotation.angle ? 360 - Number(currentTextdocument.rotation.angle) : 0;
            var radius = Math.sqrt(currentTextdocument.geometry.width * currentTextdocument.geometry.width + currentTextdocument.geometry.height * currentTextdocument.geometry.height)
            dragBox.originalRotate = parseInt(180 * Math.asin(currentTextdocument.geometry.width / radius) / (2 * Math.asin(1))) + dragBox.imgRotate;
            $(".rotate-icon" + $scope.template.levelI).addClass('rotate-icon-active');

            document.addEventListener("mousemove", rotateDocOn, true);
            document.addEventListener("mouseup", rotateDocEnd, true);
        }

        function rotateDocOn(e) {
            fn(e);
            var currentPage = $scope.template.pages.page[$rootScope.currentCarouselIndex];
            var currentImg = currentPage.levels.level[$scope.template.levelI].textdocument;
            if (drag) {
                var offsetX = (e.clientX - dragBox.clientX);
                var offsetY = (e.clientY - dragBox.clientY);
                var newX = (currentImg.geometry.width / 2 * $scope.currentPage.scale / $scope.currentPage.proportion + offsetX);
                var newY = (currentImg.geometry.height / 2 * $scope.currentPage.scale / $scope.currentPage.proportion + offsetY);
                dragBox.newRadius = Math.sqrt(newX * newX + newY * newY);
                var rotate = parseInt(180 * Math.asin(newX / dragBox.newRadius) / (2 * Math.asin(1)));
                if (newX > 0 && newY > 0) {
                    currentImg.rotation.angle = (dragBox.originalRotate - rotate);
                } else if (newX < 0 && newY > 0) {
                    currentImg.rotation.angle = (dragBox.originalRotate - rotate);
                } else if (newX < 0 && newY < 0) {
                    currentImg.rotation.angle = (dragBox.originalRotate + 180 + rotate);
                } else {
                    currentImg.rotation.angle = (dragBox.originalRotate + 180 + rotate);
                }


                if (currentImg.rotation.angle >= 360) {
                    currentImg.rotation.angle %= 360;
                }
                currentImg.rotation.angle = 360 - currentImg.rotation.angle
                $scope.template.angle = currentImg.rotation.angle;

            }
            $scope.$digest();
            return false;
        }

        function rotateDocEnd() {
            drag = false;
            dragBox = {};

            document.removeEventListener("mousemove", rotateDocOn, true);
            document.removeEventListener("mouseup", rotateDocEnd, true);
            $scope.$digest();
        }

        /*******************************************文字模板*****************************end*********************************************/


        /*******************************************文字处理****************************start*********************************************/
        /**
         * 更改文字字体
         */
        function getFontList() {
            FontManagement.getDefaultShow({}, function (res) {
                if(res.status == 200){
                    vm.defaultFont = res.data;
                }else {
                    swal({
                        title: $translate.instant("choose.editorMessage.failToGet"),
                        text: res.message,
                        imageUrl: Theme.sweetSuccedImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
            });
            FontManagement.getAll({}, function (res) {
                    $scope.fontList = [];
                    angular.forEach(res, function (msg, i) {
                        $scope.fontList.push({
                            id: 0,
                            // 字体名称
                            value: msg.family,
                            child: [],
                            uuid: msg.uuid
                        });
                        var zh = null;
                        angular.forEach(msg.fontStyles, function (font, j) {
                            switch (font.style) {
                                case 'NORMAL':
                                    zh = "常规";
                                    break;
                                case 'BOLD':
                                    zh = "粗体";
                                    break;
                                case 'ITALIC':
                                    zh = "斜体";
                                    break;
                                case 'BOLD_ITALIC':
                                    zh = "粗斜体";
                                    break;
                                default:
                                    zh = null
                            }
                            $scope.fontList[i].child.push({
                                // 字体样式中文
                                value: zh,
                                // 字体样式英文
                                en: font.style,
                                path: font.path,
                                identifier: font.identifier,
                                domain: $scope.domain
                            });
                        });
                        if (i == res.length - 1) {
                            getFont();
                        }
                    });
            });
        }

        function getFont() {
            $scope.defaultText = {
                family: $scope.fontList[0].value,
                style: $scope.fontList[0].child[0].en,
                path: $scope.fontList[0].child[0].path,
                identifier: $scope.fontList[0].child[0].identifier,
                domain: $scope.domain
            };

            new MultiPicker({
                input: 'multiPickerInput',//点击触发插件的input框的id
                container: 'targetContainer',//插件插入的容器id
                jsonData: $scope.fontList,
                success: function (arr) {
                    $("#multiPickerInput").val(arr[0].value + "（" + arr[1].value + "）");
                    $scope.defaultText = {
                        family: arr[0].value,
                        uuid: arr[0].uuid,
                        style: arr[1].en,
                        path: arr[1].path,
                        identifier: arr[1].identifier,
                        domain: $scope.domain
                    };
                    var text = getCurrentText();
                    text.fontuuid = arr[0].uuid
                    if (vm.fontuuids.indexOf(text.fontuuid) == -1) {
                        vm.fontuuids.push(text.fontuuid);
                        getFontCSS(FontManagement, text, $scope);
                    }
                    text.styles = arr[1].en;
                    text.style.font = arr[0].value;
                    text.identifier = arr[1].path;
                    if (text.styles.indexOf('BOLD') != -1) {
                        text.style.bold = true;
                    } else {
                        text.style.bold = false;
                    }
                    if (text.styles.indexOf('ITALIC') != -1) {
                        text.style.italic = true;
                    } else {
                        text.style.italic = false;
                    }
                }//回调
            });
        }

        /**
         * 文字
         */
        var getCurrentText = function () {
            var index = $rootScope.currentCarouselIndex || 0;
            return $scope.template.pages.page[index].levels.level[$scope.template.levelI].textbox;
        };

        var elem = document.querySelector('input[type="range"]');

        var rangeValue = function () {
            var newValue = elem.value;
            var target = document.querySelector('.text-value');
            target.innerHTML = $translate.instant("choose.editor.fontSize",{size:newValue});
            var text = getCurrentText();
            text.style.size = newValue;
        };

        elem.addEventListener("input", rangeValue);

        /**
         * 更新文字
         */
        $scope.updateTextContents = function () {
            var w = $("#text-area").width();
            $("#text-div").width(w);
            var page = $scope.template.pages.page[$scope.template.pageI];
            setTimeout(function () {
                var text = page.levels.level[$scope.template.levelI].textbox;
                var $con = $("#text-div");

                var str = $scope.textContent.split('\n').join('<br/>');
                text.text = $scope.textContent;
                $con.html(str);
            }, 200);
        };

        /**
         * 文字输入确认按钮
         */
        $scope.saveText = function () {
            getCurrentImage();
            $scope.currentText = $scope.currentPage.levels.level[$scope.template.levelI].textbox;

            $scope.textContent = $scope.textContent.split(' ').join(' ');

            FontManagement.fontSupport({
                uuid: $scope.currentText.fontuuid,
                bold: $scope.currentText.style.bold,
                italic: $scope.currentText.style.italic,
                content: $scope.textContent
            }, function (res) {
                var msg = res.data;
                $scope.currentText.success = res.data.success;
                if (res.status == 200) {
                    if(!msg.success){
                        SweetAlert.swal({
                            title: $translate.instant("choose.editorMessage.fontIsNotAvailable"),
                            imageUrl: Theme.sweetHintImg(),
                            text: "",
                            timer: 2000,
                            showConfirmButton: false
                        });
                        return
                    }
                    if (msg.success && !msg.supported) {
                        swal({
                            title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>"+$translate.instant("choose.editorMessage.warmPrompt")+"</div>",
                            text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>"+$translate.instant("choose.editorMessage.specialCharacter")+"<br>"+$translate.instant("choose.editorMessage.pleaseModify")+"<span style='color:#FFA91B;font-size: 14px'>" + msg.failed + "</span></div>",
                            html: true,
                            confirmButtonText: $translate.instant("choose.editorMessage.correction")
                        });
                    } else {
                        $(window).unbind('scroll');

                        $scope.showBottomMenu = true;
                        $scope.showBarMenu = false;

                        tipStatus(false);
                        var i = $scope.template.levelI;
                        var index = $scope.template.pageI;
                        $(".slide-li").eq(index).find(".text-box").eq(i).removeClass('opacity-zero');
                        $scope.showTextModal = false;

                        $scope.template.currentMenu = 'textbox';
                        $scope.showTextMenu = false;
                        $scope.currentText.text = $scope.textContent;
                        $scope.currentText.style.lineheight = $scope.currentText.style.size;
                        //下载字体
                        if (vm.fontuuids.indexOf($scope.currentText.fontuuid) == -1) {
                            vm.fontuuids.push($scope.currentText.fontuuid);
                        }
                        getFontCSS(FontManagement, $scope.currentText, $scope);
                        vm.addText = false;
                        toImg($scope.currentText, $scope.currentPage.styleScale2, index, i);
                    }
                }
            })
        };

        /**
         * 文字菜单切换（点击）及初始化
         */
        $scope.toggleTextMenu = function ($event) {
            $(".text-img").css({'height': 0});
            // $("#text-area").attr('autofocus', "autofocus");

            $scope.showTextModal = !$scope.showTextModal;
            $scope.showTextMenu = !$scope.showTextMenu;
            $scope.showBottomMenu = !$scope.showBottomMenu;

            // 清空样式
            $scope.textContent = "";
            vm.addText = true;

            /**
             * 格式化颜色和文字的选择
             */
            $scope.selectedColor = $scope.colorList[0];
            $scope.selectedFont = $scope.fontList[0];
            angular.forEach($scope.fontList, function (item, index) {
                item.selected = !index;
            });

            $(".tip-image").addClass('hide1');

            //初始化并添加一个文字框
            if ($scope.showTextMenu) {
                var currentPage = $scope.template.pages.page[$rootScope.currentCarouselIndex];
                var _size = Math.floor(currentPage.trimbox.height/8);
                var currentLevel = addLevel(currentPage);

                $scope.template.pageI = $rootScope.currentCarouselIndex;
                currentLevel.textbox = ({
                    geometry: {
                        height: 24 * currentPage.styleScale,
                        width: 200 * currentPage.styleScale,
                        x: null,
                        y: null
                    },
                    rotation: {
                        angle: 0
                    },
                    style: {
                        color: $scope.selectedColor,
                        font: vm.defaultFont.family,
                        size: _size || $(".text-range").val(),
                        bold: false,
                        italic: false,
                        lineheight: _size || $(".text-range").val(),
                        letterspacing: 0,
                        align: 'left',
                        writingmode:'horizontal-tb'
                    },
                    text: '请输入文字',
                    type: 'dynamic',
                    status: '',
                    xM: null,
                    yM: null,
                    levelI: $scope.template.levelI,
                    pageI: $scope.template.pageI,
                    transparency: 1,
                    onlyshow: false,
                    movable: true,
                    editable: true,
                    useradded: true,
                    fontuuid: vm.defaultFont.uuid,
                    identifier: null,
                    version: '2.0'
                });

                $scope.template.currentMenu = 'text';
                $scope.selectCurrentTextBox($event, $scope.template.levelI);

            }
            var textbox = currentPage.levels.level[$scope.template.levelI];
            ;
            $scope.textCopy = angular.copy(textbox);
        };

        /**
         * 底部 编辑按钮<---->菜单栏 的切换
         * @param event
         */
        $scope.toggleBarMenu = function (event) {
            if (event) {
                event.stopPropagation();
            }
            $scope.showBarMenu = !$scope.showBarMenu;
            // 当引导页点击“不在提示”以后就不再count++;

            if (!vm.guideStep) {
                $scope.countN++;
                localStorage.setItem('countN', $scope.countN);
            }
        };

        /**
         * 关闭按钮--->菜单栏
         */
        $scope.closeToBarMenu = function (event) {
            if (event) {
                event.stopPropagation();
            }
            $scope.showBottomMenu = true;
            $scope.showBarMenu = false;
            $scope.showStickerMenu = false;
            $scope.showTempMenu = false;
        };

        var setUlWidth = function () {
            var len = vm.article.document.pages.page.length;
            if (len < 3) {
                return
            }
            //需要加上选中的边框值：2
            var w = 2;
            if ($scope.pageAllPx && $scope.pageAllPx.page.length) {
                var arr = $scope.pageAllPx.page;
                for (var j = 0; j < arr.length; j++) {
                    if (!arr[j].unreplace) {
                        arr[j].width = arr[j].mediabox.width * arr[j].styleScale2;
                        arr[j].height = arr[j].mediabox.height * arr[j].styleScale2;
                        w += arr[j].width * $scope.mHeight / arr[j].height + 12;
                    }

                }
            }

            $(".temp-ul").width(w + 'px');
        };

        /**
         * 选中当前文字框
         */
        $scope.selectCurrentTextBox = function ($event, $index) {
            // selectTextIndex = $index;
            if ($event) {
                $event.stopPropagation();
            }
            var currentPage = getCurrentPage();
            var currentText = currentPage.levels.level[$scope.template.levelI].textbox;

            /**
             * 显示文字颜色及字体选择菜单
             */
            $scope.showTextMenu = true;
            $scope.showStickerMenu = false;

            /**
             * 更新对应的颜色选择和字体
             */
            $scope.selectedColor = currentText.style.color;
            angular.forEach($scope.fontList, function (item) {
                if (item.value == currentText.style.font) {
                    item.selected = true;
                    $scope.selectedFont = item;
                } else {
                    item.selected = false;
                }
            });
        };
        /**
         * 更改文字颜色
         */
        $scope.selectTextColor = function (color) {
            $scope.selectedColor = color;
            var text = getCurrentText();
            text.style.color = color;
        };


        $scope.hideMenu = function () {
            $scope.showTextMenu = false;
            $scope.showStickerMenu = false;

            $scope.showBarMenu = true;
            $scope.showTempMenu = false;
            if (!$scope.showTextModal) {
                $scope.showBottomMenu = true;
            } else {
                $scope.showBottomMenu = false;
            }
            // $scope.template.currentMenu = '';
            // $scope.template.levelI = null;

            var currentPage = getCurrentPage();
        };


        function toImg(text, styleScale, index, textI) {
            if (text.text == '') {
                return
            }

            var page = $scope.template.pages.page[$scope.template.pageI];
            var pagemaxW = page.trimbox.width * styleScale;

            var fontStyle = text.styles ? text.styles : $scope.defaultText.style;

            // 常用emoji
            var regStr = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig;
            var org_val = text.text;
            if (regStr.test(org_val)) {
                text.text = org_val.replace(regStr, "");
            }

            var tmpNode = document.createElement("DIV");
            $(tmpNode).html(text.text).css({'font-size': text.style.size});

            //根据换行符分数组
            var arrs = $(tmpNode).html().split('\n');
            // 空格替换
            var str = $(".text-width").html(arrs[0]).html().replace(/\s/g, 'a');
            var ratio = $scope.currentPage.ratio2;
            $(".text-width").html(str)
            //先拿到数组中下标为0的宽度
            var $width = $(".text-width").html(str).css({
                'font-size': (text.style.size * ratio > 12 ? text.style.size * ratio : 12) + 'pt',
                'line-height': (text.style.size * ratio > 12 ? text.style.lineheight * ratio : text.style.lineheight * 12 / text.style.size) + 'pt',
                'letter-spacing': (text.style.size * ratio > 12 ? text.style.letterspacing * ratio : text.style.letterspacing * 12 / text.style.size) + 'pt',
                'font-weight': fontStyle,
                'font-family': '\'' + text.fontuuid + '\'',
                'transform': 'scale(' + (1 / 12 * text.style.size * ratio > 1 ? 1 : 1 / 12 * text.style.size * ratio) + ')',
            });

            var w0 = angular.copy(text.geometry.width * styleScale);
            var h0 = angular.copy(text.geometry.height * styleScale);
            var scale = 1 / 12 * text.style.size * ratio > 1 ? 1 : 1 / 12 * text.style.size * ratio;
            var htmlWidth = $width.width() * scale;
            var htmlHeight = $width.height() * scale;
            var t = 0,
                k = 0;
            if (htmlWidth > pagemaxW) {
                k = k + Math.ceil(htmlWidth / pagemaxW);
            }
            if (text.type != 'static' && pagemaxW) {
                var pattern_char = /[a-zA-Z0-9]/g;
                // var pattern_chin = /[\u4e00-\u9fa5]/g;
                var pattern = $width.html().match(pattern_char);
                var count_char = pattern ? pattern.length : 4;
                var maxW = (htmlWidth) > pagemaxW ? pagemaxW : (htmlWidth + count_char * 2);
                var maxH = htmlHeight;
                if (arrs.length == 1) {
                    w0 = maxW;
                    h0 = maxH * (arrs.length + k) + 2;
                } else {
                    for (var i = 1, len = arrs.length; i < len; i++) {
                        var count = arrs[i].match(pattern_char);
                        var over_char = count ? count.length : 4;
                        // 空格替换
                        var newWord = $width.html(arrs[i]).html().replace(/\s/g, '&nbsp;');
                        var overWidth = $width.html(newWord).width() * scale + over_char * 2;

                        //得到数组中宽度最大的一个
                        if (maxW < overWidth && overWidth < pagemaxW) {
                            maxW = overWidth;
                            maxH = $width.html(arrs[i]).height() * scale;
                            t = i;
                        } else if (maxW < overWidth && overWidth > pagemaxW) {
                            maxW = pagemaxW;
                            maxH = $width.html(arrs[i]).height() * scale;
                            k = k + parseInt(overWidth / pagemaxW);
                        }
                    }
                    $width.html(arrs[t]);
                    w0 = maxW;
                    h0 = maxH * (arrs.length + k);
                }
            }

            if(text.style.writingmode && text.style.writingmode != 'horizontal-tb'){
                text.geometry.width = h0 / styleScale;
                text.geometry.height = w0 / styleScale;
            }else{
                text.geometry.width = w0 / styleScale;
                text.geometry.height = h0 / styleScale;
            }

            var slideW = $("#slide-li" + $scope.template.pageI).find('.slide-content').width();
            var slideH = $("#slide-li" + $scope.template.pageI).find('.slide-content').height();
            var x = ((slideW - w0) / 2 + $scope.template.levelI) / styleScale;
            var y = ((slideH - h0) / 2 + $scope.template.levelI) / styleScale;


            if (text.geometry.x == null || text.geometry.y == null) {
                text.geometry.x = x;
                text.geometry.y = y;
            }

            if (text.geometry.width >= $scope.currentPage.trimbox.width) {
                text.geometry.x = $scope.currentPage.trimbox.x;
            }
        }

        /**
         * 删除文字框
         * @param $event
         * @param i
         * @param index
         */
        $scope.deleteSelectedText = function ($event, i, index) {
            $scope.template.levelI = i;
            $scope.deleteAction(event, i);
            $scope.showTextMenu = false;
        };

        /**
         * 编辑文字内容
         * @param $event
         * @param i
         * @param text
         * @param index
         */
        $scope.editTextContent = function ($event, i, text, index) {
            $scope.showTextModal = true;
            $scope.showImageMenu = false;
            $scope.showTempMenu = false;
            $scope.showStickerMenu = false;
            // selectTextIndex = i;
            $(".text-img-pic").css({width: text.geometry.width, height: text.geometry.height});
            $scope.textContent = text.text;
            $scope.template.levelI = i;
            $scope.template.pageI = index;
            $(".text-value").html(text.style.size + ' px');
            $(".rangeSize").val(text.style.size);

            // angular.element('#text-area')[0].focus();

            getCurrentImage();
            var textbox = $scope.template.pages.page[index].levels.level[i].textbox;
            $scope.textCopy = angular.copy(textbox);
            $(".tip-image").addClass('hide1');

        };

        /**
         * 移动文字框
         */
        var getCurrentTextFromEvent = function (e) {
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            $scope.template.levelI = targ.dataset.index;
            $scope.template.currentMenu = 'text';
            return getCurrentText();
        };

        window.moveTextBoxStart = function (e) {
            fn(e);
            var index = $rootScope.currentCarouselIndex || 0;
            $scope.template.pageI = index;
            $scope.template.currentMenu = 'textbox';
            $('.tip-image').addClass('hide1');
            var currentPage = $scope.template.pages.page[$rootScope.currentCarouselIndex];

            textBoxMoveStart(e, $scope.template.levelI, currentPage.levels.level, currentPage.styleScale2, currentPage.trimbox, function (textIndex) {
                $scope.template.levelI = parseInt(textIndex);
                var text = $scope.template.pages.page[index].levels.level[textIndex].textbox;
                $(".text-img-pic").css({width: text.geometry.width, height: text.geometry.height});
                $scope.$digest();
            })
        };
        window.moveTextBoxOn = function (e) {
            textBoxMoveOn(e, $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox, $scope.template.pages.page[$scope.template.pageI].styleScale2, $scope.template.pages.page[$scope.template.pageI].trimbox,function () {
                vm.hint = !editboxIn($scope.template.pages.page[$scope.template.pageI].editboxes.editbox , $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox.geometry, $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox.rotation.angle);
            })
            $scope.$digest();
            fn(e);
        };
        window.moveTextBoxEnd = function (e) {
            vm.hint = false;
            textBoxMoveEnd();
            $scope.$apply();
            fn(e);
        };

        /**
         *旋转缩放文字
         * @param e
         */
        window.resizeTextBoxStart = function (e) {
            fn(e);
            // IE uses srcElement, others use target
            var text = getCurrentTextFromEvent(e);
            var currentPage = getCurrentPage();
            dragBox.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
            dragBox.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
            dragBox.coordX = angular.copy(text.geometry.x * currentPage.styleScale2);
            dragBox.coordY = angular.copy(text.geometry.y * currentPage.styleScale2);
            dragBox.width = angular.copy(text.geometry.width * currentPage.styleScale2);
            dragBox.height = angular.copy(text.geometry.width * currentPage.styleScale2);
            dragBox.originalFontSize = text.style.size;
            dragBox.radius = parseInt(Math.sqrt(dragBox.width * dragBox.width + dragBox.height * dragBox.height));
            dragBox.textRotate = text.rotation.angle ? Number(text.rotation.angle) : 0;
            dragBox.originalRotate = parseInt(180 * Math.asin(dragBox.width / dragBox.radius) / (2 * Math.asin(1))) + dragBox.textRotate;
            window.isDragging = true;
        };
        window.resizeTextBoxOn = function (e) {
            if (window.isDragging) {
                var text = getCurrentTextFromEvent(e);
                var offsetx = (e.changedTouches[0].clientX - dragBox.clientX);
                var offsety = (e.changedTouches[0].clientY - dragBox.clientY);
                var newX = (dragBox.width / 2 + offsetx);
                var newY = (dragBox.height / 2 + offsety);
                dragBox.newRadius = Math.sqrt(newX * newX + newY * newY);
                var rotate = parseInt(180 * Math.asin(newX / dragBox.newRadius) / (2 * Math.asin(1)));
                if (newX > 0 && newY > 0) {
                    text.rotation.angle = (dragBox.originalRotate - rotate);
                } else if (newX < 0 && newY > 0) {
                    text.rotation.angle = dragBox.originalRotate - rotate;
                } else if (newX < 0 && newY < 0) {
                    text.rotation.angle = dragBox.originalRotate + 180 + rotate;
                } else {
                    text.rotation.angle = (dragBox.originalRotate + 180 + rotate);
                }
            }

            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();
            return false;
        };
        window.resizeTextBoxEnd = function (e) {
            window.isDragging = false;
            dragBox = {};
        };

        /*******************************************文字处理****************************end*********************************************/


        //更新所有占位框的使用照片图片
        function getUsedImageboxsNum() {
            vm.usedImageboxsNum = 0;
            vm.counts = 0;
            angular.forEach($scope.template.pages.page, function (item, index) {
                angular.forEach(item.levels.level, function (level) {
                    if (level.imagebox) {
                        vm.counts++;
                    }
                    if (level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                        vm.usedImageboxsNum++;
                    }
                })
            });
        }

        var completed = null;
        $scope.saveWork = function () {
            completed = vm.article.articleStatus;
            var index = $rootScope.currentCarouselIndex;
            // 作品保存后，将页面改为0；
            $rootScope.currentCarouselIndex = 0;
            vm.article.pageCount = $scope.template.pages.page.length;
            if ($scope.status == 'make') {
                $scope.template.pageI = null;
                setProductionStorage('view');
                $scope.showTempMenu = false;
                $scope.isReplaceShow = false;
                $scope.showStickerMenu = false;

                setGalleryStorage();
                localStorage.removeItem('marginLeft');

                $ul.css({marginLeft: '0px'});
                backToScrollTop();
                localStorage.setItem('pages', JSON.stringify($scope.template.pages));
                return;
            }
            var _documentTextDetection = documentTextDetection1($scope.template.pages.page);
            if(!_documentTextDetection.pass){
                vm.isDocumentTextDetection = true;
                vm.documentTextDetection = _documentTextDetection.pages;
                return;
            }
            if ($scope.template.pages.page.length < $scope.PAGELEN) {
                SweetAlert.swal({
                    title: $translate.instant("choose.editorMessage.templateIsLoading"),
                    imageUrl: Theme.sweetHintImg(),
                    text: "",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }

            if (vm.article.ordered) {
                SweetAlert.swal({
                    title: $translate.instant("choose.editorMessage.warmPrompt"),
                    imageUrl: Theme.sweetHintImg(),
                    text: $translate.instant("choose.editorMessage."),
                    timer: 2000,
                    showConfirmButton: false
                });
            }

            getUsedImageboxsNum();
            if (vm.usedImageboxsNum < vm.counts) {
                completed = "EDITING";
                SweetAlert.swal({
                    title: $translate.instant("choose.editorMessage.baaroque"),
                    imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: $translate.instant("choose.editor.confirm"),
                    cancelButtonText: $translate.instant("choose.editor.cancel"),
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        $timeout(function () {
                            $(".save-page").removeClass('hide1');
                            thumbnailInit(function (res){
                                generateJsonFile();
                            })
                        }, 500);
                        return;
                    }
                })
            } else {
                completed = 'COMPLETED';
                $(".save-page").removeClass('hide1');
                thumbnailInit(function (res){
                    generateJsonFile();
                })
            }
        };

        //json下载文件中需要的内容
        var generateJsonFile = function () {
            $scope.isSave = true;
            var template = {
                pages: {
                    page: []
                },
                bgimgs2: vm.article.bgimgs2,
                documentpics: vm.article.documentpics,
                masks: vm.article.masks,
                cliparts: vm.article.cliparts,
                replace: vm.replace,
                cover: vm.cover,
                version: '2.0'
            };

            var doc = saveData();
            template.pages = doc.pages;
            //作品保存的时候 将用户选择照片填入 文档
            $scope.qiniuId = JSON.parse(localStorage.getItem("gallery"));
            template.galleries = {gallery: $scope.qiniuId};
            vm.article.id = vm.aid;
            removeAll();

            template.hostinfo = hostInfo();
            Convert.articleUploadDocument(template, function (res) {
                if(res.status == 200){
                    vm.article.id = vm.aid;
                    vm.article.articleStatus = completed;
                    vm.article.productType = 'CustomPrint';
                    vm.article.fileName = res.data.identifier;
                    Article.update(vm.article,function (resp) {
                        if(resp.status == 200){
                            $(".save-page").addClass('hide1');
                            $('.sweet-alert').removeClass('hide-button');
                            $scope.bool = false;
                            swal({
                                title: $translate.instant("choose.editorMessage.saveSuccessfully"),
                                text: $translate.instant("choose.editorMessage.workHasBeenSyncedToMyWork"),
                                imageUrl: Theme.sweetSuccedImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                            localStorage.setItem('guide-step', true);
                            $scope.isSavingJson = false;
                            removeLocalStorage();
                            $state.go("product-view-ec", {storeId: vm.storeId, aid: resp.data.id});
                        }else {
                            $(".save-page").addClass('hide1');
                            swal({
                                title: $translate.instant("choose.editorMessage.failSave"),
                                text: $translate.instant("choose.editorMessage.saveError"),
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        }
                    })
                }

            }, function () {
                $(".save-page").addClass('hide1');
                swal({
                    title: $translate.instant("choose.editorMessage.failSave"),
                    text: $translate.instant("choose.editorMessage.saveError"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            })

        };
        function saveData() {
            return documentMM($scope.template,$scope.pageAllPx.page,'gaoji',$scope)
        }

        var removeLocalStorage = function () {
            localStorage.removeItem("choose");
            localStorage.removeItem("multipartUpload");
            localStorage.removeItem("go");
            localStorage.removeItem("pages");
            localStorage.removeItem("marginLeft");
            localStorage.removeItem("$index");
            localStorage.removeItem("gallery");
            localStorage.removeItem("work-save");
        };
        removeAll();
        function removeAll() {
            //清除关于作品的localStorage
            localStorage.removeItem("choose");
            localStorage.removeItem("multipartUpload");
            localStorage.removeItem("index");
            localStorage.removeItem("i");
            localStorage.removeItem("marginLeft");
            localStorage.removeItem("single");
            localStorage.removeItem("pages");
            localStorage.removeItem("gallery");
            localStorage.removeItem("galleryCopy");
            localStorage.removeItem("reviewPages");
            localStorage.removeItem("saveOldPage");
            localStorage.removeItem("saveOldPageIndex");
            localStorage.removeItem("pageAllPx");
            localStorage.removeItem("pageAllMm");
            localStorage.removeItem("view-scrollTop");
            localStorage.removeItem("work-save");
            localStorage.removeItem("delPic");
            localStorage.removeItem("picArrChoose");
            localStorage.removeItem("picArrChoose2");
            localStorage.removeItem("gallery2");
            localStorage.removeItem("picArrCopy");
            localStorage.removeItem("picArr");
            localStorage.removeItem("isSort");
        };

        function setProductionStorage(attr) {
            localStorage.setItem('Production', attr);
            $scope.status = attr;
        }

        function setReviewStorage(index) {
            localStorage.setItem('saveOldPage', JSON.stringify($scope.template.pages.page[index]));
            localStorage.setItem('saveOldPageIndex', index);
            localStorage.setItem('galleryCopy', JSON.stringify($scope.qiniuId));
            localStorage.setItem('pages', JSON.stringify($scope.template.pages));
        }

        function setGalleryStorage() {
            localStorage.setItem('gallery', JSON.stringify($scope.qiniuId));
        }

        function tipStatus(flag) {
            if (flag) {
                $('.edit-menu').removeClass('opacity-zero1');
                $('.tip-image').addClass('hide1');
                return
            } else {
                $('.edit-menu').addClass('opacity-zero1');
                $('.tip-image').removeClass('hide1');
            }
        }

        $scope.hideComeIn = function () {
            $scope.isHide = true;
            localStorage.setItem('isHide', true);
        };

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }

        /**
         * 点击其他地方时取消选中贴纸，文字框
         */
        $scope.$on('documentClicked', function () {
            setEveryThingUnselected();
            $scope.$digest();
        });
        var setEveryThingUnselected = function () {
            $scope.showTextMenu = false;
            $scope.showStickerMenu = false;
            $(".change-btn").hide();
        };

        /**
         * carousel滑动后初始化编辑菜单
         */
        $scope.$on('carouselSlideAway', function () {
            //关闭贴纸菜单
            $scope.showStickerMenu = false;
            //关闭文字菜单
            if ($scope.showTextMenu) {
                $scope.toggleTextMenu();
            }

            //取消当前页面的选择框
            if ($scope.template) {
                setEveryThingUnselected()
            }
        });


        //    引导页部分
        $scope.nextStep = function () {
            $scope.countN++;
            localStorage.setItem('countN', $scope.countN);
        };
        $scope.nextStep2 = function () {
            $scope.countN++;
            localStorage.setItem('countN', $scope.countN);
        };
        $scope.neverHint = function () {
            localStorage.setItem('guide-step', true);
            vm.guideStep = true;
            if ($scope.status == 'make') {
                $('.tip-image').removeClass('hide1');
            }
        };
        $scope.nextStep3 = function () {
            $scope.countN++;
            localStorage.setItem('countN', $scope.countN);
            if ($scope.countN == 13) {
                $('.tip-image').removeClass('hide1');
            }
        };

        //多选选择照片限制
        $scope.photoPic = [];
        var t = 0;
        $scope.selectRestrict = function (identifier, photo, $index) {
            var len = 0;
            photo.index = $index;
            //当前页
            $scope.currentPage = $scope.template.pages.page[$scope.index];
            //当前框
            $scope.currentImage = $scope.currentPage.levels.level[$scope.i].imagebox;
            // $scope.totalImgaes = $scope.currentPage.imageboxes.imagebox.filter(function (item) {
            //     return item.image == null || angular.equals({}, item.image)
            // })
            angular.forEach($scope.currentPage.levels.level, function (level) {
                if (level.imagebox && (!level.imagebox.image || level.imagebox.image && level.imagebox.image.resource == null)) {
                    len++;
                }
            })

            if ($scope.currentImage.image != null && $scope.currentImage.image.resource != null && $scope.currentImage.image.resource.identifier != null && $scope.currentImage.image.resource.identifier != "") {
                var total = len + 1;
            } else {
                var total = len;
            }
            if (photo.photo_select == false || photo.photo_select == undefined) {
                photo.photo_select = true;
                t++;
                if (t > total) {
                    SweetAlert.swal({
                        title: $translate.instant("choose.editorMessage.reselect"),
                        imageUrl: Theme.sweetHintImg(),
                        text: "",
                        timer: 2000,
                        showConfirmButton: false
                    });
                    photo.photo_select = false;
                    t--;
                    angular.forEach($scope.qiniuId, function (item) {
                        if (item.photo_select == undefined || item.photo_select == false) {
                            item.flg = true;
                        } else {
                            item.flg = false;
                        }
                    });
                }
            } else {
                photo.photo_select = false;
                t--;
                angular.forEach($scope.qiniuId, function (item) {
                    item.flg = false;
                });
            }

            $scope.photoPic = $scope.qiniuId.filter(function (item) {
                return item.photo_select == true;
            });
            localStorage.setItem('gallery', JSON.stringify($scope.qiniuId));
        };


        //    多张确认导入
        $scope.confirmChoose = function () {
            localStorage.setItem("photoPic", JSON.stringify($scope.photoPic));
            multipartTo($scope.photoPic);
        };


        //   多选照片导入
        function multipartTo(photos) {
            localStorage.setItem("gallery", JSON.stringify($scope.qiniuId));
            setProductionStorage('make');

            //当前页
            $scope.currentPage = $scope.template.pages.page[$scope.index];
            //当前框
            $scope.currentImage = $scope.currentPage.levels.level[$scope.i].imagebox;
            var imagebox = $scope.currentImage;
            //先赋值 下标i的占位框   选择的照片移除已赋值的

            //循环imageboxes 如果占位框有值则continue    赋值空box  赋值的usenum++   并移除已赋值的照片
            //如果都已经移除完毕   则循环结束 或者 imageboxes循环到尾
            if(photos[0].imageInfo){
                multipartTo1(photos[0].imageInfo)
            }else{
                $.ajax({
                    type: "GET",
                    url: photos[0].identifier + "?imageInfo",
                    async: false,
                    success: function (va) {
                        photos[0].imageInfo = va;
                        multipartTo1(va)
                    }
                })
            }

            function multipartTo1(va) {
                photos[0].usenum++;
                if (imagebox.image) {
                    $scope.imgIndex = imagebox.image.index;
                    $scope.imgId = imagebox.image.resourceid;
                    if ($scope.qiniuId.length) {
                        angular.forEach($scope.qiniuId, function (arr, i) {
                            if (arr.id == $scope.imgId) {
                                arr.usenum == 0 ? 0 : arr.usenum--;
                                setGalleryStorage();
                            }
                        })
                    }
                } else {
                    imagebox.image = {}
                }
                imagebox.image.imageInfo = va;
                imagebox.image.angle = orientationInit1(va.orientation).angle;
                imagebox.flip = orientationInit1(va.orientation).flip;
                setImgs(imagebox,photos[0],$scope.i);
                photos.splice(0, 1);
                for (var j = 0; j < $scope.currentPage.levels.level.length; j++) {
                    var level = $scope.currentPage.levels.level[j];
                    if (level.imagebox && level.imagebox.geometry&&j!=$scope.i) {
                        var img = level.imagebox;
                        if (img.image != null && img.image.resource != null && img.image.resource.identifier != null && img.image.resource.identifier != "") {
                            continue;
                        }
                        if (photos == null || photos.length == 0) {
                            break;
                        }

                        var photo = photos.splice(0, 1);
                        if(photo[0].imageInfo){
                            img.image.imageInfo = photo[0].imageInfo;
                            multipartTo2(JSON.parse(img.image.imageInfo))
                        }else{
                            $.ajax({
                                type: "GET",
                                url: photo[0].identifier + "?imageInfo",
                                async: false,
                                success: function (va) {
                                    photo[0].imageInfo = va;
                                    img.image.imageInfo = va;
                                    multipartTo2(JSON.parse(img.image.imageInfo))
                                }
                            })
                        }
                        function multipartTo2(imageInfo){
                            photo[0].usenum++;
                            img.image.angle = orientationInit1(imageInfo.orientation).angle;
                            img.flip = orientationInit1(imageInfo.orientation).flip;
                            photo[0] = orientationInit(photo[0]);
                            setImgs(img,photo[0],j)
                        }
                    }
                }
            }


            function setImgs(img,photo,x) {
                if (img.mask.resource.identifier) {
                    img.maskUrl = img.mask.resource.identifier;
                } else {
                    img.maskUrl = 'content/images/timg.jpg';
                }

                img.image = imgParamter(img, photo, photo.index);

                img.imgUrl = photo.identifier;
                img.image.resource.identifier = photo.identifier;
                img.image.offsety = 0;
                img.image.offsetx = 0;
                img.image.scale = 1;
                // img.image.angle = 0;
                if (vm.article.productBokeh) {
                    img.blurred = true;
                    img.blurredrectangle = {}
                }
                imgScale(img, $scope.index, x, 0, 0, 'center',$scope,vm);
            }

            $scope.showBottomMenu = true;
            $('.tip-image').removeClass('hide1');
            $scope.template.currentMenu = '';
        };

        vm.sortStatus = false;
        vm.orignTemp = null;
        var sort = null;
        var sortArray = [];
        $scope.sortPage = function () {
            vm.sortStatus = true;
            vm.orignTemp = angular.copy($scope.template);
            sort = new Sortable(document.getElementById('my-ui-list1'), {
                swap: true,
                filter: '.filtered',
                animation: 150,
                onEnd: function (/**Event*/evt) {
                    console.log('onEnd.foo:', [evt.item, evt.from]);
                    sortArray = [];
                    angular.forEach($('#my-ui-list1').find('.slide-content'), function (item, index) {
                        var seq = item.getAttribute("data-sort-tag");
                        var value = angular.copy($scope.template.pages.page[seq]);
                        value.seq = index;
                        sortArray.push(value)
                    });
                }
            });
        }

        $scope.sortBack = function () {
            vm.sortStatus = false;
            $scope.template = angular.copy(vm.orignTemp);
            vm.orignTemp = null;
            sort.destroy();
        }

        $scope.sortCancel = function () {
            $scope.template = angular.copy(vm.orignTemp);
        }

        $scope.saveSort = function () {
            if (!sortArray.length) {
                // $scope.template = angular.copy(vm.orignTemp);
            } else {
                $scope.template.pages.page = angular.copy(sortArray);

            }
            vm.sortStatus = false;
            sort.destroy();
        }
        $scope.textLayout = function(item){
            var text = $scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox;
            var text1 = angular.copy($scope.template.pages.page[$scope.template.pageI].levels.level[$scope.template.levelI].textbox);
            text.style.writingmode = item;
            $timeout(function () {
                // text.geometry.width = Number($("#c-xml-page-material-fontId1").height()) + Number(5);
                // text.geometry.height = (Number($("#c-xml-page-material-fontId1").width()) + Number(5));
                text.geometry.width = text1.geometry.height;
                text.geometry.height = text1.geometry.width;
            },200)
        }
        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if(res.status == 200){
                vm.imgSize = res.data.imgSize;
                vm.imgDpi = res.data.imgDpi;
            }
        });
        //边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.dropShadow = function(imagebox,ratio,ratio1){
            if (imagebox.shadow == null) {
                return
            }
            var width = $scope.imageboxW(imagebox) * ratio;
            var height =$scope.imageboxH(imagebox) * ratio1;
            return dropShadow(imagebox,width,height)
        }
        //生成缩略图
        function thumbnailInit(callback){
            thumbnailInit1(function (res){
                vm.article.covered=true;
                vm.article.cover=res;
                callback(res);
            })
        }
        function thumbnailInit1(callback) {
            $timeout(function (){
                html2canvas_adapt(document.getElementById('thumbnail-view'), {
                    onrendered: function (canvas) {
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);
                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', '3dThumbnail-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                var identifier = data.key;
                                callback(identifier)
                            }
                        })
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: $scope.template.pages.page[0].mediabox.width*vm.thumbnailScale,
                    height: $scope.template.pages.page[0].mediabox.height*vm.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: -($scope.template.pages.page[0].mediabox.width - $scope.template.pages.page[0].mediabox.width*vm.thumbnailScale)/2,
                    y: -($scope.template.pages.page[0].mediabox.height - $scope.template.pages.page[0].mediabox.height*vm.thumbnailScale)/2,
                    ratio: vm.thumbnailScale,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            })
        }
        $scope.$on('changeLanguage', function () {
            getMessageNotification();
            getFontList();
            ThemeClassify.getAllByCondition4({type: 'CLIPART',storeId:vm.storeId}, function (res) {
                if(res.status == 200){
                    $scope.labels = angular.copy(res.data);
                }else {
                    MessageService.error(res.message);
                }
            });
        });

    }
})();
