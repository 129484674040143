(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$translate', 'Store', 'Auth', 'Qinius', '$q', 'VerifyCode', 'PlatformWechatSetting',
    'MessageService', '$scope', '$interval', '$state', 'Customer', '$stateParams', '$rootScope', 'UidService', 'WechatAuth', 'entity', '$localStorage', '$uibModalInstance',
    'Account', "$cookieStore"];

    function RegisterController ($translate, Store, Auth, Qinius, $q, VerifyCode, PlatformWechatSetting,
        MessageService, $scope, $interval, $state, Customer, $stateParams, $rootScope, UidService, WechatAuth, entity, $localStorage, $uibModalInstance,
        Account, $cookieStore) {

        var vm = this;

        vm.loginContent = true;
        vm.loginText = true;
        vm.passwordReset = false;
        vm.userRegister = false;
        vm.userBinding = false;


        vm.rememberMe = true;
        vm.storeId = $stateParams.storeId || $cookieStore.get("store-id");
        if (!vm.storeId) {
            return;
        }
        vm.store = Store.get({id: vm.storeId});
        $scope.domains = Qinius.getDomain({storeId: vm.storeId});
        vm.login = login;
        vm.register = register;
        vm.resetPassword = resetPassword;
        vm.register2 = register2;
        vm.registerAccount = {storeId:vm.storeId};
        vm.checkCode = false;
        vm.disabled = true;
        vm.disabled2 = false;
        $scope.paracont = "获取验证码";

        //验证码的定时器
        var timer = null;
        //微信扫码的定时器
        var timer2 = null;
        //扫码是的uuid
        vm.uuid = null;

        vm.platformWechatSetting = PlatformWechatSetting.get();
        $q.all([$scope.domains.$promise, vm.platformWechatSetting.$promise, vm.store.$promise]).then(function () {
            vm.domain = 'https://' + $scope.domains.domains[0] + '/';
            vm.loginLogo = 'content/images/login-bg.png';
            vm.registerLogo = 'content/images/login-bg.png';
            if(vm.store.webLoginLogo){
                vm.loginLogo = vm.domain+vm.store.webLoginLogo+'?imageView2';
            }
            if(vm.store.webRegisterLogo){
                vm.registerLogo = vm.domain+vm.store.webRegisterLogo+'?imageView2';
            }
        });

        if (entity!=null && entity.type == "Register") {
            vm.loginContent = false;
            vm.userRegister = true;
            vm.passwordReset = false;
            vm.userBinding = false;

            vm.disabled = true;
            vm.disabled2 = false;
        }


        $scope.requestReset = function(){
            vm.loginContent = false;
            vm.userRegister = false;
            vm.passwordReset = true;
            vm.userBinding = false;

            vm.disabled = true;
            vm.disabled2 = false;

        }

        $scope.register = function(){
            vm.loginContent = false;
            vm.userRegister = true;
            vm.passwordReset = false;
            vm.userBinding = false;

            vm.disabled = true;
            vm.disabled2 = false;
        }

        $scope.hasUser = function(){
            vm.loginContent = true;
            vm.passwordReset = false;
            vm.userRegister = false;
            vm.userBinding = false;
            vm.loginText = true;

            vm.disabled = true;
            vm.disabled2 = false;
        }

        $scope.showPhoneLogin = function(){
            vm.loginText = true;
            $interval.cancel(timer2);
        }


        $scope.showQrCode = function(){
            vm.loginText = false;
            vm.uuid = UidService.get();
            var obj = new WxLogin({
              id: "login_container",
              appid: vm.platformWechatSetting.webAppId,
              scope: "snsapi_login",
              redirect_uri: encodeURIComponent(vm.platformWechatSetting.editorNotifyUrl+ "/#/"+ vm.storeId+"/commodity"),
              state: vm.uuid,
              style: "black",
              href: ""});
            timer2 = $interval(function () {
                if (location.hash.indexOf("?")>=0) {
                    $interval.cancel(timer2);
                    var data = {};
                    var search = location.hash.substring(location.hash.indexOf("?")+1);
                    var searchList = search.split("&");
                    if (searchList.length == 2) {
                        for (var i = searchList.length - 1; i >= 0; i--) {
                            var params = searchList[i].split("=");
                            if (params.length >= 2) {
                                data[params[0]]=params[1];
                            }
                        }
                    }
                    WechatAuth.auth_notify3({storeId:vm.storeId, code:data.code, state:data.state },function(value){
                        if (value.status == 205) {
                            vm.loginContent = false;
                            vm.passwordReset = false;
                            vm.userRegister = false;
                            vm.userBinding = true;
                            location.href =  location.href.substring(0, location.href.indexOf("?"));
                        }else if (value.status == 200){
                            $localStorage.authenticationToken = value.message;
                            Account.get().$promise.then(getAccountThen);
                            $uibModalInstance.close();
                        }
                        },function(){
                            MessageService.error("登录失败，请刷新后重新登陆或者使用其他方式登录");
                        })
                }
            }, 1000);
        }

        $scope.close = function(){
            $uibModalInstance.close();
        }

        function getAccountThen (account) {
            $localStorage.user = account.data;
            $state.reload();
        }


        $scope.getVerifyCode = function () {
            if (vm.registerAccount.phone == null || vm.registerAccount.phone == "") {
                MessageService.error("请先输入手机号码");
                return;
            }
            if (vm.disabled) {
                return;
            }
            vm.countdown = 120;
            // 发送验证码到手机
            VerifyCode.getVerifyCode({
                phone: vm.registerAccount.phone,
                incident: "用户注册",
                storeId:vm.storeId
            }, function (msg) {
                clearInterval(timer);
                timer = $interval(function () {
                    if (vm.countdown > 0) {
                        vm.disabled = true;
                        vm.countdown -- ;
                        $scope.paracont = "重新发送(" + vm.countdown + ")";
                    } else {
                        vm.disabled = false;
                        $interval.cancel(timer);
                        $scope.paracont = "重发验证码";
                    }
                }, 1000);
            });
        };

        function login(event) {
            if (vm.phone == null || vm.phone == '' || vm.password == null || vm.password == '') {
                MessageService.error("请输入手机号和密码");
                return;
            }
            event.preventDefault();
            Auth.login({
                phone: vm.phone,
                password: vm.password,
                storeId:vm.storeId,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                $rootScope.$broadcast('authenticationSuccess');
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    // $state.go(previousState.name, previousState.params);
                }
                $uibModalInstance.close();
                $state.reload();
            }).catch(function (err) {
                MessageService.error("用户名或者密码错误");
                vm.authenticationError = true;
            });
        }


        $scope.getVerifyCode2 = function () {
            if (vm.registerAccount.phone == null || vm.registerAccount.phone == "") {
                MessageService.error("请先输入手机号码");
                return;
            }
            if (vm.disabled2) {
                return;
            }
            vm.countdown = 120;
            // 发送验证码到手机
            Customer.checkPhone({phone:vm.registerAccount.phone, storeId:vm.storeId}, function(data) {
                if (data.status != 200) {
                    VerifyCode.getVerifyCode({
                        phone: vm.registerAccount.phone,
                        storeId:vm.storeId,
                        incident: "忘记密码"
                    }, function (msg) {
                        clearInterval(timer);
                        timer = $interval(function () {
                            if (vm.countdown > 0) {
                                vm.disabled2 = true;
                                vm.countdown -- ;
                                $scope.paracont = "重新发送(" + vm.countdown + ")";
                            } else {
                                vm.disabled2 = false;
                                $interval.cancel(timer);
                                $scope.paracont = "重发验证码";
                            }
                        }, 1000);
                    });
                } else {
                    MessageService.info("未能找到用户信息，请先确认是否注册用户");
                }
            })


        };


        $scope.checkVerifyCode = function(){
            VerifyCode.check({
                phone: vm.registerAccount.phone,
                code: vm.registerAccount.code,
            }, function (msg) {
                if (msg.status == 200 && msg.message==true) {
                    vm.checkCode = true;
                }else{
                   MessageService.error("手机验证失败");
                }
            },function(){
                MessageService.error("手机验证失败");
            });
        }

        $scope.checkPhone = function(){
            if (vm.registerAccount.phone == null) {
                return;
            }
            Customer.checkPhone({phone:vm.registerAccount.phone, storeId:vm.storeId}, function(data) {
                if (data.status != 200) {
                    MessageService.error("该手机号已经存在，请选择其他手机号");
                    vm.disabled = true;
                    vm.disabled2 = true;
                } else {
                    MessageService.info("手机号可用");
                    vm.disabled = false;
                    vm.disabled2 = false;
                }
            })
        }


        function resetPassword () {
            if (!$scope.validata()) {
                return;
            }
            var postData = {
                phone:vm.registerAccount.phone,
                password:vm.registerAccount.password,
                storeId:vm.storeId
            }
            Customer.changePassword(postData,
                function(data) {
                    vm.loginContent = true;
                    vm.passwordReset = false;
                    vm.userRegister = false;
                    MessageService.success("密码重置成功，请使用新密码登录");
                    // $state.go("home", {storeId:vm.storeId});
                },
                function() {
                    MessageService.error("重置密码失败，请稍后再试");
                });
        }

        $scope.validata = function(){
            if (vm.registerAccount.password == null || vm.registerAccount.password !== vm.confirmPassword) {
                MessageService.error("两次输入的密码不一致，请重新输入");
                return false;
            }
            if (vm.registerAccount.password.length <=4 || vm.registerAccount.password.length>= 50) {
                MessageService.error("密码要求长度为4到50位之间，请重新输入");
                return false;
            }
            return true;
        }

        function register2(){
            if (!$scope.validata()) {
                return;
            }
            vm.registerAccount.langKey = $translate.use();
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;
            VerifyCode.check({
                phone: vm.registerAccount.phone,
                code: vm.registerAccount.code,
                incident: "用户注册"
            }, function (msg) {
                if (msg.status == 200 && msg.message==true) {
                    vm.registerAccount.uuid = vm.uuid;
                    Customer.register2(vm.registerAccount, function(data){
                        if (data.status== 200) {
                            // vm.loginContent = true;
                            // vm.passwordReset = false;
                            // vm.userRegister = false;
                            // vm.userBinding = false;
                            // vm.loginText = true;
                            $localStorage.authenticationToken = data.message;
                            Account.get().$promise.then(getAccountThen);
                            $uibModalInstance.close();
                            // MessageService.success("注册成功,请前往登录");
                        }else{
                            MessageService.error(data.message);
                        }
                    },function(){
                        MessageService.error("注册失败，手机号已经被使用，请更换后重新注册");
                    });
                }else{
                   MessageService.error("手机验证失败");
                }
            },function(){
                 MessageService.error("手机验证失败");
            });
        }

        $scope.phoneChange = function(){
            if (vm.registerAccount.phone == null || vm.registerAccount.phone == "") {
                return;
            }
            if (vm.registerAccount.phone.length == 11) {
                $scope.checkPhone();
            }

        }

        function register () {
            if (!$scope.validata()) {
                return;
            }
            vm.registerAccount.langKey = $translate.use();
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;
            VerifyCode.check({
                phone: vm.registerAccount.phone,
                code: vm.registerAccount.code,
                incident: "用户注册"
            }, function (msg) {
                if (msg.status == 200 && msg.message==true) {
                    Customer.register(vm.registerAccount, function(data){
                        if (data.status== 200) {
                            $localStorage.authenticationToken = data.message;
                            Account.get().$promise.then(getAccountThen);
                            $uibModalInstance.close();
                            // vm.loginContent = true;
                            // vm.passwordReset = false;
                            // vm.userRegister = false;
                            // MessageService.success("注册成功,请前往登录");
                        }else{
                            MessageService.error(data.message);
                        }
                    },function(){
                        MessageService.error("注册失败，手机号已经被使用，请更换后重新注册");
                    });
                }else{
                   MessageService.error("手机验证失败");
                }
            },function(){
                 MessageService.error("手机验证失败");
            });
        }

        $scope.showOrHidePassword = function () {
            if($(".fa").hasClass("fa-eye-slash")){
                $(".fa").removeClass("fa-eye-slash").addClass("fa-eye");
                $(".input-password").attr("type", "text");
            }else{
                $(".fa").removeClass("fa-eye").addClass("fa-eye-slash");
                $(".input-password").attr("type", "password");
            }

        }

    }
})();
