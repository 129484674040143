(function () {
    'use strict';

    angular
        .module('editorApp')
        .factory('Theme', Theme);


    function Theme () {
        return{
            judge:function() {
                console.log(localStorage.getItem('themeFlag'));
                var newCss = document.getElementById("linkid");
                if (localStorage.getItem('themeFlag') == 'three') {
                    newCss.href = "content/css/themeThree.css";
                } else if (localStorage.getItem('themeFlag') == 'two') {
                    newCss.href = "content/css/themeTwo.css";
                } else {
                    newCss.href = "content/css/themeOne.css";
                }
            },
            sweetHintImg:function(){
                if (localStorage.getItem('themeFlag') == 'three') {
                    return "content/images/icons/popThree_ic_msg_hint.svg";
                }else if(localStorage.getItem('themeFlag') == 'two'){
                    return "content/images/icons/pop_ic_msg_hint.svg";
                }else{
                    return "content/images/icons/pop_ic_msg_hint.svg";
                }
            },
            sweetSuccedImg: function(){
                if (localStorage.getItem('themeFlag') == 'three') {
                    return "content/images/icons/popThree_ic_msg_succeed.svg";
                }else if(localStorage.getItem('themeFlag') == 'two'){
                    return "content/images/icons/pop_ic_msg_succeed.svg";
                }else{
                    return "content/images/icons/pop_ic_msg_succeed.svg";
                }
            },
            sweetBrokenImg:function(){
                return "content/images/icons/broken.svg";
            },
        };
    }
})();
