(function () {
    'use strict';

    var editorGallery = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/editor-gallery-ec.html',
        controllerAs: 'vm',
        bindings: {
            product:'<',
            photoSelectAll: '<',//已选图片
            article:'<',//作品
            productXmlLine:'<',//模板
            counts:'=',//照片数量
            isDomain:'=',//是否加入前缀domain
            agentIos:'=',//
            fullMap:'<',
            fullMapNumber:'<'
        },

        controller: ['$scope', '$stateParams', '$q', '$localStorage', 'Qinius', 'Store', '$http', '$rootScope', 'Theme', 'Article',
            '$timeout', 'ModulesService', 'MessageService', '$translate', 'Folder', 'Gallery','SweetAlert','IMAGE_SUPPORTED_MIME','StoreStorages','$websocket','PlatformWechatSetting',
            function ($scope, $stateParams, $q, $localStorage, Qinius, Store, $http, $rootScope, Theme, Article,
                $timeout, ModulesService, MessageService, $translate, Folder, Gallery, SweetAlert, IMAGE_SUPPORTED_MIME,StoreStorages, $websocket, PlatformWechatSetting) {
                var vm = this;
                console.log(vm.fullMap)
                vm.insideFolder = false;
                vm.currentNav = 'gallery';
                vm.photoAll = [];
                vm.indexNum = 0;
                $scope.domains = Qinius.getDomain();
                vm.store = Store.get({id: vm.storeId});
                vm.storeId = $stateParams.storeId;
                vm.pid = $stateParams.pid;
                vm.aid = $stateParams.aid;
                $scope.folderIds = [];
                $scope.agentIos = vm.agentIos;
                vm.isIPhone6 = isIPhone6();
                vm.supportedMimeTypes = IMAGE_SUPPORTED_MIME;
                vm.platformSetting = PlatformWechatSetting.get();
                $q.all([$scope.domains.$promise, vm.store.$promise ,vm.platformSetting.$promise]).then(function () {
                    $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                    vm.platformSetting = vm.platformSetting.data;
                    loadAll('isNull')
                    qInit()
                    websocket();
                })
                $scope.uploadPhoto = function () {
                    uploadAble(StoreStorages,vm.storeId, function (res) {
                        if(res.status == 200){
                            if($scope.agentIos){
                                $rootScope.$broadcast('clickAndroidUpload', true);
                            }else{
                                $timeout(function () {
                                    $("#uploadImageMutipleId").trigger("click");
                                })
                            }
                        }else{
                            if(res.status == 400){
                                threeInfo('系统出错');
                            } else {
                                threeInfo(res.data||'系统出错');
                            }
                        }
                    })
                }
                $scope.$on('uoloadBroken', function (event, args) {
                    if (args) {
                        // updatePic();
                    }
                });
                $scope.$on('uoloadFinished', function (event, args) {
                    if (args) {
                        // updatePic();
                    }
                });
                vm.uploadPhotoItem=[];
                vm.today = "";
                $scope.$on('uploadStart',function(){
                    var date = new Date();
                    vm.today = date.getFullYear() + '-'+ (date.getMonth() + 1) + '-' + date.getDate();
                    if(vm.photoArray.length > 0 && vm.photoArray[0].createDate === vm.today){
                        return;
                    }
                    //造一个空的今日title项
                    vm.photoArray.unshift({
                        createDate: vm.today,
                        galleries:[]
                    })

                })

                // 上传图片后更新图片库/文件夹
                function updatePic() {
                    if (vm.folderOne && vm.folderOne.id) {
                        vm.photoAll = [];
                        loadAll(vm.folderOne.id);
                    } else {
                        vm.photoAll = [];
                        vm.indexNum = 0;
                        vm.maxId = null;
                        vm.photoCompleted = false;
                        loadAll('isNull');
                    }
                }

                $scope.galleryNavSel = function (item) {
                    vm.currentNav = item;
                    vm.indexNum = 0;
                    vm.maxId = null;
                    vm.photoCompleted = false;
                    vm.photoAll = [];
                    vm.folderOne = null;
                    if (item == 'gallery') {
                        loadAll('isNull')
                    }
                    if (item == 'folder') {
                        loadFolders()
                    }
                };

                function qInit() {
                    $('.c-productThree2-head3-page2').on('scroll', function (event) {
                        var realScrollHeight = event.currentTarget.clientHeight + event.currentTarget.scrollTop;
                        vm.realScrollHeight = realScrollHeight;
                        if (!vm.photoCompleted) {
                            if ((event.currentTarget.scrollHeight - 1) < realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight + 1)) {
                                vm.indexNum++;
                                loadAll('isNull');
                            }
                        }
                    })
                    $('.c-productThree2-head3-page9').on('scroll', function (event) {
                        var realScrollHeight = event.currentTarget.clientHeight + event.currentTarget.scrollTop;
                        vm.realScrollHeight = realScrollHeight;
                        if (!vm.photoCompleted) {
                            if ((event.currentTarget.scrollHeight - 1) < realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight + 1)) {
                                vm.indexNum++;
                                loadAll(vm.folderOne.id);
                            }
                        }
                        $scope.$digest();
                    })
                }

                // 增加文件夹
                $scope.addFolder = function () {
                    swal({
                        title: $translate.instant("editorCommon.addPhotoAlbumPop"),
                        text: "",
                        type: "input",
                        showCancelButton: true,
                        closeOnConfirm: false,
                        confirmButtonText: $translate.instant("button.confirm"),
                        cancelButtonText: $translate.instant("button.cancel"),
                        animation: "slide-from-top",
                        inputPlaceholder: $translate.instant("editorCommon.pleaseFolderName")
                    }, function (inputValue) {
                        if (inputValue === false) {
                            $(".folder-footer").show();
                            return false;
                        }
                        if (inputValue === "") {
                            swal.showInputError($translate.instant("editorCommon.pleaseName"));
                            return false
                        }
                        if (inputValue.indexOf(" ") != -1) {
                            swal.showInputError($translate.instant("editorCommon.pleaseEnterTheContent"));
                            return false
                        }
                        Folder.save({
                            articleId: vm.article.id,
                            name: inputValue,
                            type: 'Gallery',
                        }, function (resp) {
                            // $scope.Folders.push(resp.data);
                            loadFolders();
                            swal.close();
                        }, function () {
                            swal.showInputError($translate.instant("editorCommon.pleaseEnterTheCorrectFileName"));
                        })
                    });
                };
                $scope.chooseAll = function(){
                    if ($scope.folderIds.length == $scope.Folders.length) {
                        $scope.Folders.forEach(function (data) {
                            data.selected = false;
                        })
                        $scope.folderIds = [];
                    }else{
                        $scope.folderIds = [];
                        $scope.Folders.forEach(function (data) {
                            data.selected = true;
                            $scope.folderIds.push(data.id)
                        })
                    }
                }
                //文件夹多选
                vm.isChoiceFolder = false;
                $scope.choiceFolder = function () {
                    vm.isChoiceFolder = true;
                }
                $scope.cancelFolder = function () {
                    vm.isChoiceFolder = false;
                    vm.folderSelectData = "";
                    $scope.folderIds= [];
                    $scope.Folders.forEach(function (data) {
                        data.selected = false;
                    })
                }
                vm.isEditFolderName = false;
                $scope.editFolder = function () {
                    if($scope.folderIds.length!=1){
                        console.log($scope.folderIds);
                        return
                    }
                    $scope.Folders.forEach(function (data) {
                        if ($scope.folderIds[0] == data.id) {
                            vm.folderSelectData = angular.copy(data);
                        }
                    })
                    if (!vm.folderSelectData) {
                        threeInfo($translate.instant("editorCommon.pleaseSelectAFolder"));
                        return
                    }
                    vm.isEditFolderName = true;
                }

                function threeInfo(item) {
                    vm.threeError = true;
                    vm.threeErrorText = item;
                    $scope.folderIds= [];
                    vm.folderSelectData = "";
                    $timeout(function () {
                        vm.threeError = false;
                        vm.threeErrorText = "";
                    }, 1500)
                }
                $scope.editFolderNameHide = function () {
                    vm.isEditFolderName = false;
                }
                $scope.editFolderNameSave = function () {
                    vm.isEditFolderName = false;
                    if (!vm.folderSelectData || !vm.folderSelectData.name) {
                        threeInfo($translate.instant("editorCommon.pleaseName"));
                        return false
                    }
                    if (vm.folderSelectData.name.indexOf(" ") != -1) {
                        threeInfo($translate.instant("editorCommon.pleaseEnterTheContent"));
                        return false
                    }
                    vm.folderSelectData.articleId = vm.article.id;
                    Folder.update(vm.folderSelectData, function (resp) {
                        threeInfo($translate.instant("editorCommon.folderNameSuccess"));
                        $scope.cancelFolder();
                        loadFolders();
                        swal.close();
                    }, function () {
                        threeInfo($translate.instant("editorCommon.pleaseEnterTheCorrectFileName"));
                    })
                }

                function loadFolders() {
                    $scope.Folders = [];
                    Folder.getAllByConditionAndQuantity({articleId:vm.article.id}, function (resp) {
                        $scope.Folders = resp.data || [];
                    })
                }
                vm.folderSelectData = "";
                $scope.folderSelect = function (item) {
                    vm.photoCompleted = false;
                    if (vm.isChoiceFolder) {
                        $scope.folderIds = [];
                        $scope.Folders.forEach(function (data) {
                            if (item.id == data.id) {
                                data.selected = !data.selected ? true : false;
                            }
                            if (data.selected) {
                                $scope.folderIds.push(data.id)
                            }
                        })
                        console.log($scope.folderIds);
                    } else {
                        vm.folderOne = item;
                        vm.photoAll = [];
                        vm.photoArray = [];
                        vm.indexNum = 0;
                        vm.maxId = null;
                        loadAll(item.id)
                        vm.insideFolder = true;
                    }
                }
                vm.maxId = null;
                vm.itemsPerPage = 40;//分页大小
                function loadAll(folderId) {
                    var items = [];
                    items.push({ key: "articleId", op: "=", value: vm.aid });
                    if (folderId == 'isNull') {
                        items.push({key: "folderId", op: "isNull", value: ''})
                    } else {
                        items.push({ key: "folderId", op: "=", value: folderId })
                    }
                    if (vm.maxId) {
                        items.push({key: "id", op: "<=",value: vm.maxId});
                    }
                    Gallery.storeByCondition({
                        items: items,
                        page: vm.indexNum,
                        size: vm.itemsPerPage,
                        sort: sorts()
                    }, onSuccess, onError);
                }

                function sorts() {
                    return ["id,desc"];
                }

                function onSuccess(res) {
                    if (vm.maxId == null && res.data.length > 0) {
                        vm.maxId = res.data[0].id;
                    }
                    for (var i = 0; i < res.data.length; i++) {
                        if (!res.data[i].cancelled) {
                            res.data[i].identifier = $scope.domain + res.data[i].identifier;
                            vm.photoAll.push(res.data[i])
                        }
                    }
                    if (res.data.length < vm.itemsPerPage) {
                        vm.photoCompleted = true;
                    }
                    getPagePhoto();
                }

                function onError(data) {
                    if(data.status !== 403){
                        MessageService.hint($translate.instant("editorCommon.failedToObtainThePhotoGallery"));
                    }

                }

                function getPagePhoto() {
                    var timeList = [];
                    vm.photoArray = [];
                    for (var i = 0; i < vm.photoAll.length; i++) {
                        var photoCreatedDate = new Date(vm.photoAll[i].createdDate);
                        if (checkExist(timeList, photoCreatedDate)) {
                            timeList.push(photoCreatedDate);
                        }
                    }
                    //将相同日期下的照片整合
                    for (var m = 0; m < timeList.length; m++) {
                        var tempObj = {};
                        var tempItems = [];
                        for (var j = 0; j < vm.photoAll.length; j++) {
                            var createdDate = new Date(vm.photoAll[j].createdDate);
                            if (timeList[m].getFullYear() == createdDate.getFullYear() &&
                                timeList[m].getMonth() == createdDate.getMonth() &&
                                timeList[m].getDate() == createdDate.getDate()) {
                                tempItems.push(vm.photoAll[j])
                            }
                        }
                        var time = timeList[m].getFullYear() + '-' + (timeList[m].getMonth() + 1) + '-' + timeList[m].getDate();
                        tempObj = { "createDate": time, "galleries": tempItems };
                        vm.photoArray.push(tempObj);
                    }
                    getPagePhotoSelected();
                }

                function getPagePhotoSelected() {
                    vm.phoneAllSelected = true;
                    for(var i = 0; i < vm.photoArray.length; i++){
                        vm.photoArray[i].selected = true;
                        for(var j=0; j<vm.photoArray[i].galleries.length; j++){
                            vm.photoArray[i].galleries[j].selected = false;
                            for(var k=0; k<vm.photoSelectAll.length; k++){
                                if(vm.photoArray[i].galleries[j].id == vm.photoSelectAll[k].id){
                                    vm.photoArray[i].galleries[j].selected = true;
                                }
                            }
                            if(!vm.photoArray[i].galleries[j].selected){
                                vm.photoArray[i].selected = false;
                                vm.phoneAllSelected = false;
                            }
                        }
                    }
                    photoArrayAllSelect();
                }

                // 判断是否重复
                function checkExist(list, obj) {
                    if (list == null || list.length == 0) {
                        return true;
                    }
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].getFullYear() == obj.getFullYear() &&
                            list[i].getMonth() == obj.getMonth() &&
                            list[i].getDate() == obj.getDate()) {
                            return false;
                        }
                    }
                    return true;
                }

                $scope.photoAllCb = function (item) {
                    item.selected = !item.selected ? true : false;
                    for(var i=0; i<item.galleries.length; i++){
                        item.galleries[i].selected = item.selected;
                        photoSelect(item.galleries[i])
                    }

                    selectAllBtnState()
                }
                //全选
                vm.phoneAllSelected = false;
                $scope.photoAllSelect = function () {
                    vm.phoneAllSelected = !vm.phoneAllSelected;
                    vm.photoArray.forEach(function (item) {
                        item.selected = vm.phoneAllSelected;
                        for(var i=0; i<item.galleries.length; i++){
                            item.galleries[i].selected = vm.phoneAllSelected;
                            photoSelect(item.galleries[i])
                        }
                    })
                }
                //选中当前图片
                $scope.photoOneCb = function (item) {
                    if(vm.fullMap && !item.selected && vm.photoSelectAll.length >= vm.fullMapNumber){
                        threeInfo($translate.instant("hint.imagesMaxQuantity"))
                        return
                    }
                    item.selected = !item.selected ? true : false;
                    photoSelect(item);
                    selectAllBtnState()
                }

                function selectAllBtnState(){
                    // 多选按钮状态
                    vm.phoneAllSelected = true;
                    for(var i=0; i<vm.photoArray.length; i++){
                        vm.photoArray[i].selected = true;
                        for(var j=0; j<vm.photoArray[i].galleries.length; j++){
                            if(!vm.photoArray[i].galleries[j].selected){
                                vm.photoArray[i].selected = false;
                                vm.phoneAllSelected = false;
                            }
                        }
                    }
                }

                function photoSelect(item) {
                    // 图片选中判断是否有图片没有再添加
                    if (item.selected) {
                        var isExist = false;
                        for(var i=0; i<vm.photoSelectAll.length;i++){
                            var photoSelect = vm.photoSelectAll[i];
                            if (photoSelect.id == item.id) {
                                isExist=true;
                                break
                            }
                        }
                        if (!isExist) {
                            var a = angular.copy(item);
                            if(vm.isDomain){
                                if(a.identifier.indexOf("/") > -1){
                                    var str = a.identifier.split("/")
                                    a.identifier = str[str.length-1]
                                    vm.photoSelectAll.push(a)
                                }else{
                                    vm.photoSelectAll.push(item)
                                }
                            }else{
                                vm.photoSelectAll.push(item)
                            }
                        }
                        return
                    }
                    // 取消选择图片
                    for(var i=0; i<vm.photoSelectAll.length;i++){
                        var photoSelect = vm.photoSelectAll[i];
                        if (photoSelect.id == item.id) {
                            vm.photoSelectAll.splice(i,1);
                            return
                        }
                    }
                }

                function deletePhotoSelect(item) {
                    var index = null;
                    for (var i = 0; i < vm.photoSelectAll.length; i++) {
                        if (vm.photoSelectAll[i].id == item.id) {
                            index = i;
                            break
                        }
                    }
                    vm.photoSelectAll.splice(index, 1);
                }

                 //删除多个文件夹
                $scope.deleteFolders = function () {
                    if ($scope.folderIds.length == 0) {
                        return;
                    }
                    SweetAlert.swal({
                        title: $translate.instant("editorCommon.hint"),
                        text: $translate.instant("editorCommon.deleteFoldeHint"),
                        showCancelButton: true,
                        confirmButtonText: $translate.instant("editorBtn.delete"),
                        cancelButtonText: $translate.instant("editorBtn.back"),
                    }, function (isConfirm) {
                        if (isConfirm) {
                            Folder.batchDelete({ids: $scope.folderIds}, function () {
                                loadFolders();
                                $scope.cancelFolder();
                            }, function () {

                            });
                        } else {
                            return;
                        }
                    }
                )

                };

                function photoArrayAllSelect() {
                    for (var i = 0; i < vm.photoArray.length; i++) {
                        vm.photoArray[i].selected = true;
                        for (var j = 0; j < vm.photoArray[i].galleries.length; j++) {
                            if (!vm.photoArray[i].galleries[j].selected) {
                                vm.photoArray[i].selected = false;
                            }
                        }
                    }
                }

                $scope.photoIndex = function (item) {
                    var index = null;
                    for (var i = 0; i < vm.photoSelectAll.length; i++) {
                        if (vm.photoSelectAll[i].id == item.id) {
                            index = i;
                            break
                        }
                    }
                    return index + 1;
                }

                vm.windowDpi = js_getDPI();

                $scope.galleryNavSel = function (item) {
                    vm.currentNav = item;
                    vm.indexNum = 0;
                    vm.maxId = null;
                    vm.photoCompleted = false;
                    vm.photoAll = [];
                    vm.folderOne = null;
                    if (item == 'gallery') {
                        loadAll('isNull')
                    }
                    if (item == 'folder') {
                        loadFolders()
                    }
                };
                //添加图片
                $scope.photoSave = function () {
                    if(vm.fullMap && vm.photoSelectAll.length > vm.fullMapNumber){
                        threeInfo($translate.instant("hint.imagesMaxQuantity"))
                        return
                    }
                    $scope.$emit('confirmGallery', vm.photoSelectAll);
                }

                // 事件通知
                $scope.folderSave = function () {
                    if(vm.fullMap && vm.photoSelectAll.length > vm.fullMapNumber){
                        threeInfo($translate.instant("hint.imagesMaxQuantity"))
                        return
                    }
                    $scope.$emit('confirmGallery', vm.photoSelectAll);

                }
                // 返回按钮事件通知
                $scope.getBack = function () {
                    if (vm.insideFolder) {
                        vm.insideFolder = false;
                        loadFolders()
                    }else{
                        $scope.$emit('closeGallery',  true);
                    }
                };
                $scope.$on('uoloadFinished', function(event, args) {
                    if(args){
                        getPagePhoto();
                    }
                });
                // websocket接收上传的图片（多端互通实时更新）
                function websocket(){
                    var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
                    urlws = urlws.split("/")[0];
                    var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.aid);

                    ws.onOpen(function() {
                        console.log('WebSocket连接已打开, url:'+ws.url);
                    });

                    ws.onMessage(function(message) {
                        var photo = JSON.parse(message.data);
                        if ((photo.folderId == null && vm.folderOne==null) || (photo.folderId == vm.folderOne.id)) {
                            photo.identifier = $scope.domain + photo.identifier;
                            photo.selected = true;
                            // vm.photoSelectAll.push(photo);
                            vm.photoAll.unshift(photo);
                            getPagePhoto();
                        }
                    });

                    ws.onError(function(error) {
                        console.log('WebSocket错误：', error);
                    });

                    ws.onClose(function() {
                        console.log('WebSocket连接已关闭');
                    });
                }
            }]
    };

    angular
        .module('editorApp')
        .component('editorGallery', editorGallery);
})();
