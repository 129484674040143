(function() {
    'use strict';

    angular
        .module('editorApp')
        .factory('translationStorageProvider', translationStorageProvider);

    translationStorageProvider.$inject = ['$cookies', '$log', 'LANGUAGES', '$localStorage','$injector'];

    function translationStorageProvider($cookies, $log, LANGUAGES, $localStorage,$injector) {
        return {
            get: get,
            put: put,
            getLanguageType: getLanguageType
        };

        function get(name) {
            if (name == 'NG_TRANSLATE_LANG_KEY' && $localStorage.lagnuages && $localStorage.lagnuages.indexOf($cookies.getObject(name)) === -1) {
                $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to preferred language "en"');
                var lang = navigator.language;
                if ($localStorage.store) {
                    if (!$localStorage.store.availableLangKey || $localStorage.store.availableLangKey.indexOf(lang)<0) {
                        lang = $localStorage.store.defaultLangKey;
                    }
                }
                var result = getLanguageType(lang);
                if (!!result) {
                    lang = result.value;
                }
                if (!!lang) {
                    $cookies.putObject(name, lang);
                }
            }
            var value =  $cookies.getObject(name);
            if (value == 'zh' ||!value) {
                value = 'zh-cn';
            }
            return value;
        }

        function put(name, value) {
            $cookies.putObject(name, value);
        }

        function getLanguageType(key){
            var defaultValue = {key: $injector.get('$translate').instant("language.zh-cn"), value: "zh-cn"};
            if (key == null) {
                return defaultValue;
            }
            var allLanguages = [
                {key: $injector.get('$translate').instant("language.zh-cn"), value: "zh-cn"},
                {key: $injector.get('$translate').instant("language.en"), value: "en"},
                {key: $injector.get('$translate').instant("language.zh-tw"), value: "zh-tw"},
                {key: $injector.get('$translate').instant("language.ja"), value: "ja"},
                {key: $injector.get('$translate').instant("language.ko"), value: "ko"},
                {key: $injector.get('$translate').instant("language.Deutsch"), value: "Deutsch"}
            ]
            key = key.toLowerCase();
            for (var i = allLanguages.length - 1; i >= 0; i--) {
                var l = allLanguages[i];
                if (l.value.toLowerCase() ==key) {
                    return l;
                }
            }
            if (key.indexOf("_")>0) {
                key = key.split("_")[0];
            }else if(key.indexOf("-")>0){
                key = key.split("-")[0];
            }
            for (var i =0; i<allLanguages.length; i++) {
                var l = allLanguages[i];
                if (key.indexOf(l.value.toLowerCase())>=0) {
                    return l;
                }
            }
            return defaultValue;
        }
    }
})();
