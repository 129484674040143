(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('IdPhotoController', IdPhotoController);

    IdPhotoController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'WxJS', 'Gallery', 'ItemValue', 'SweetAlert', 'Theme', '$localStorage', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'Store', 'Article', 'CommonService', '$http', 'Folder', 'MessageNotification', 'Product',
        'StoreWorkEditConfig', '$translate','IMAGE_SUPPORTED_MIME','MessageService','DateUtils','$websocket','StoreStorages'];

    function IdPhotoController($state, $scope, $q, $stateParams, Convert, Qinius, WxJS, Gallery, ItemValue, SweetAlert, Theme, $localStorage, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, Store, Article, CommonService, $http, Folder, MessageNotification, Product,
        StoreWorkEditConfig, $translate,IMAGE_SUPPORTED_MIME,MessageService,DateUtils,$websocket,StoreStorages) {
        //初始化
        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        var vm = this;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        $scope.agentIos = false;
        vm.loading = true;
        vm.levelIndex = null;
        $scope.domains = Qinius.getDomain();
        Qinius.get({}, function (res) {
            vm.token = res.token;
            vm.tokenTime = parseInt((res.expireAt - new Date().getTime()));
        });
        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if (res.status == 200) {
                vm.imgSize = res.data.imgSize;
                vm.imgDpi = res.data.imgDpi;
            }
        });
        $q.all([$scope.domains.$promise]).then(function () {
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            wxInfo();
            PlatformWechatSetting.get({},function (res) {
                vm.platformSetting = res.data;
                websocket();
            });
            getMessage();
            init();
        })

        function wxInfo() {
            /**
             * wechat api
             */
            if (wx) {
                var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
                WxJS.get({url: url}, function (res) {
                    if(res.status==200) {
                        $scope.agentIos = browserVersions();
                        var config = res.data;
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.appId, // 必填，公众号的唯一标识
                            timestamp: config.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.nonceStr, // 必填，生成签名的随机串
                            signature: config.signature,// 必填，签名，见附录1
                            jsApiList: ['chooseImage', 'uploadImage', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function () {
                            console.log('wechat api ready!');

                            wx.hideMenuItems({
                                menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                            });
                        });
                        wx.error(function (res) {
                            console.log(res)
                        });
                    }
                }, function () {
                    console.log("wx config error");
                });
            }
        }
        //消息列表
        function getMessage() {
            vm.messageList = "";
            MessageNotification.getAllByCondition2({
                items: [
                    {key: "available", op: "=", value: "true"},
                    {key: "validate", op: ">=", value: DateUtils.convertLocalDateToServer(new Date())},
                    {key:"storeId", op : "=", value:vm.storeId},
                    {key: "pageType", op: "=", value: "Edit"}]
            }, function (res) {
                if(res.status == 200){
                    res.data.sort(compare('seq'))
                    vm.message = angular.copy(res.data);
                    vm.messageEdit = angular.copy(res.data);
                    vm.messageEditText = "\xa0\xa0\xa0\xa0\xa0\xa0";
                    angular.forEach(res.data, function (resp, i) {
                        vm.messageList += resp.content
                        if (i != res.data.length - 1) {
                            vm.messageList += "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                        }
                    })
                }else {
                    MessageService.error(res.message);
                }
            });
        }
        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }

        function getProduct(productId,callback) {
            Product.get({id: productId, cancelled: false}, function (res) {
                vm.product = res.data;
                if(callback){callback()}
            })
        }
        vm.isImage = true;
        function init() {
            Article.getByUser({id: $stateParams.aid}, function (res) {
                vm.article = res.data;
                if(vm.article.invalid){//作品失效
                    $state.go('articlesFailure',{storeId:vm.storeId});
                    return
                }
                getProduct(res.data.productId,function () {
                    ItemValue.get({id: res.data.itemValueId}, function (resp) {
                        if(resp.status == 200){
                            vm.productXmlLine = resp.data.productXmlLines[0];
                            angular.forEach(resp.data.productXmlLines, function (line) {
                                if (line.id == vm.xmlId) {
                                    vm.productXmlLine = angular.copy(line);
                                }
                            });
                            vm.article.modelBlendentUuid = vm.productXmlLine.modelBlendentUuid;
                            vm.article.cover = vm.productXmlLine.coverImg;
                            vm.article.productXmlId = vm.productXmlLine.productXmlId;
                            vm.article.productXmlName = vm.productXmlLine.productXmlName;
                            vm.article.musicIdentify = vm.productXmlLine.musicIdentify;
                            vm.article.skuNumber = resp.data.skuNumber;
                            vm.article.productBokeh = resp.data.productBokeh;
                            vm.article.productBokehType = resp.data.productBokehType;
                            if(!vm.article.fileName){
                                vm.article.fileName = vm.productXmlLine ? vm.productXmlLine.productXmlIdentifier : resp.data.productXmlIdentifier;
                            }
                            if (!resp.data.productBokehType) {
                                vm.article.bokehtype = 'None';
                            } else {
                                vm.article.bokehtype = resp.data.productBokehType;
                            }
                            Convert.xmlToDocument2({
                                identifier: vm.article.fileName,
                                provider: 'qiniu',
                                editorType:vm.article.editorType,
                                patternType:vm.product.patternType,
                            }, function (res) {
                                vm.article.document = res;
                                abook();
                            });
                        }else {
                            MessageService.error(res.message);
                        }
                    });
                })
            },function(error){
                if(error.status === 403){//token失效
                    return $state.go('404',{storeId:vm.storeId});
                }
                if(error.data.status === 404){//未找到作品
                    $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                }
            })
        }
        $scope.template = null;
        function abook() {
            $scope.template = angular.copy(vm.article.document);
            $scope.template.pages.page = $scope.template.pages.page.slice(0, 1);
            angular.forEach($scope.template.pages.page, function (page, i) {
                angular.forEach(page.levels.level, function (level) {
                    if (level.imageart && level.imageart.geometry) {
                        level.imageart.onlyshow = true;
                    }
                    if (level.imagebox && level.imagebox.geometry) {
                        if(!level.imagebox.image || !level.imagebox.image.resource || !level.imagebox.image.resource.identifier){
                            vm.isImage = false;
                        }
                    }
                })
            });
            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                ratioInfo(page)
            }, function () {
                warnIcon();
                vm.loading = false;
            })
        }
        vm.ratioInfo = ratioInfo;
        function ratioInfo(item) {
            vm.windowWidth = $(window).width() * 0.9;
            vm.windowHeight = $(window).height() - 300;
            var ratio = item.custom.width / item.custom.height;
            var ratio1 = vm.windowWidth / vm.windowHeight;
            if (ratio >= ratio1) {
                item.custom.ratio1 = vm.windowWidth / item.custom.width;
            } else {
                item.custom.ratio1 = vm.windowHeight / item.custom.height;
            }
            item.custom.thumbnailScale = 300 / item.custom.width;
        }
        function warnIcon() {
            vm.warnIcon = false;
            $scope.template.pages.page[0].levels.level.forEach(function (level) {
                if(level.imagebox && level.imagebox.image && pxInsufficient(level.imagebox,vm.imgSize, vm.imgDpi)){
                    vm.warnIcon = true;
                }
            })
        }
        $scope.uploadPhoto = function () {
            uploadAble(StoreStorages,vm.storeId, function (res) {
                if(res.status == 200){
                    if($scope.agentIos){
                        $rootScope.$broadcast('clickAndroidUpload',true);
                    }else{
                        $timeout(function () {
                            $("#uploadImageMutipleThree").trigger("click");
                        })
                    }
                }else{
                    if(res.status == 400){
                        threeInfo('系统出错');
                    } else {
                        threeInfo(res.data||'系统出错');
                    }
                }
            })
        }
        // websocket接收上传的图片（多端互通实时更新）
        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.aid);
            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:'+ws.url);
            });
            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                photo.identifier = $scope.domain + photo.identifier;
                addImage(photo)
            });
            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });
            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }
        function addImage(photo) {
            vm.isImage = true;
            $scope.template.pages.page[0].levels.level.forEach(function (level,index) {
                if(level.imagebox){
                    (function (levelIndex) {
                        replaceImagebox(levelIndex, photo)
                    })(index)
                }
            })
        }
        function replaceImagebox(levelIndex, data) {
            var item = angular.copy(data);
            var imagebox = $scope.template.pages.page[0].levels.level[levelIndex].imagebox;
            imagebox.image = {
                resource: {
                    identifier: item.identifier,
                    provider: 'qiniu'
                },
                resourceid:item.id,
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                width: item.width,
                height: item.height,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0
            }
            addImagebox($http, imagebox, function () {});
        }

        function isContinue() {
            var hasEmpty = true;
            for (var i = 0; i < $scope.template.pages.page.length; i++) {
                var _page = $scope.template.pages.page[i];
                if (_page.levels.level) {
                    for (var j = 0; j < _page.levels.level.length; j++) {
                        var _level = _page.levels.level[j];
                        if (_level.imagebox && _level.imagebox.image) {
                            if (_level.imagebox.image.resource && _level.imagebox.image.resource.identifier) {
                            } else {
                                hasEmpty = false;
                                break;
                            }
                        }
                    }
                }
            }
            vm.completed = hasEmpty ? 'COMPLETED' : 'EDITING';
            return hasEmpty;
        };
        $scope.isSave = false;
        $scope.saveTab = function () {
            if ($scope.isSave) {
                return;
            }
            //判断是否有图片未上传  且是否继续保存
            if (!isContinue()) {
                SweetAlert.swal({
                    title: $translate.instant("editorCommon.saveSure"),
                    imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: $translate.instant("button.confirm"),
                    cancelButtonText: $translate.instant("button.cancel"),
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        $timeout(function () {
                            generateJsonFile();
                        }, 500);
                        return;
                    }
                })
            } else {
                generateJsonFile();
            }
        }
        function generateJsonFile() {
            $scope.isSave = true;
            vm.threeLoading = true;
            vm.threeLoadingText=$translate.instant("hint.loadingText");
            // 生成缩略图保存作品
            thumbnailInit(function () {
                $scope.template = documentMM($scope.template, null, null);
                $scope.isSave = true;
                $scope.template.hostinfo = hostInfo();
                Convert.articleUploadDocument($scope.template, function (res) {
                    vm.article.articleStatus = vm.completed;
                    vm.article.productType = 'CustomPrint';
                    vm.article.pageCount = $scope.template.pages.page.length;
                    vm.article.fileName = res.data.identifier;
                    vm.article.unFilledCount = 0;
                    Article.update(vm.article, function (resp) {
                        vm.threeLoading = false;
                        $scope.isSave = false;
                        swal({
                            title: $translate.instant("editorCommon.modifySuccess"),
                            text: $translate.instant("editorCommon.synchronizationOfWorks"),
                            imageUrl: Theme.sweetSuccedImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                        $state.go("product-view5-ec", {storeId: vm.storeId, aid: resp.data.id}, {reload: true});
                    }, function () {
                        vm.threeLoading = false;
                        $scope.isSave = false;
                        swal({
                            title: $translate.instant("editorCommon.saveError"),
                            text: $translate.instant("editorCommon.saveError1"),
                            imageUrl: Theme.sweetHintImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                    })
                }, function () {
                    $scope.isSave = false;
                    vm.threeLoading = false;
                    swal({
                        title: $translate.instant("editorCommon.saveError"),
                        text: $translate.instant("editorCommon.saveError1"),
                        imageUrl: Theme.sweetHintImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                })
            })
        }
        //生成封面图
        function thumbnailInit(callback) {
            $timeout(function () {
                html2canvas_adapt(document.getElementById('thumbnail-view'), {
                    onrendered: function (canvas) {
                        if(canvas){
                            var url = canvas.toDataURL("image/png");
                            var blob = dataURLtoBlob(url);
                            var formData = new FormData();
                            formData.append('file', blob);
                            formData.append('key', '3dThumbnail-' + UidService.get());
                            formData.append('token', vm.token);
                            $.ajax({
                                cache: true,
                                type: "POST",
                                url: 'https://upload.qiniup.com/',
                                data: formData,
                                async: false,
                                contentType: false,
                                processData: false,
                                success: function (data) {
                                    var identifier = data.key;
                                    vm.article.covered = true;
                                    vm.article.cover = identifier;
                                    if (callback) {callback()};
                                },
                                error: function () {
                                    vm.article.covered=false;
                                    vm.article.cover=null;
                                    if (callback) {callback()};
                                }
                            })
                        }else{
                            vm.article.covered=false;
                            vm.article.cover=null;
                            if (callback) {callback()};
                        }
                    },
                    error:function(){
                        vm.article.covered=false;
                        vm.article.cover=null;
                        if (callback) {callback()};
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: $scope.template.pages.page[0].custom.width * $scope.template.pages.page[0].custom.thumbnailScale,
                    height: $scope.template.pages.page[0].custom.height * $scope.template.pages.page[0].custom.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            })
        }
        function threeInfo(item) {
            vm.threeError = true;
            vm.threeErrorText = item;
            $timeout(function () {
                vm.threeError = false;
                vm.threeErrorText = "";
            }, 1500)
        }
        $scope.canvasOuter = function (e) {
            fn(e)
            vm.levelIndex = null;
        }

        $scope.rotateImagebox = function () {
            if ($scope.template.pages.page[0].levels.level[vm.levelIndex] && $scope.template.pages.page[0].levels.level[vm.levelIndex].imagebox) {
                var imagebox = $scope.template.pages.page[0].levels.level[vm.levelIndex].imagebox;
                imageboxRotate($scope, imagebox);
            }
        }

        //图片框的操作
        $scope.imageboxSite = function (item) {
            if (!$scope.template.pages.page[0].levels.level[vm.levelIndex] || $scope.template.pages.page[0].levels.level[vm.levelIndex].imagebox.bokehtype != 'None') {
                return
            }
            var _imagebox = $scope.template.pages.page[0].levels.level[vm.levelIndex].imagebox;
            if (item == 'center') {
                _imagebox.image.offsetx = -(_imagebox.image.width * _imagebox.image.scale - _imagebox.geometry.width) / 2 / _imagebox.image.scale;
                _imagebox.image.offsety = -(_imagebox.image.height * _imagebox.image.scale - _imagebox.geometry.height) / 2 / _imagebox.image.scale;
            }
            if (_imagebox.image.offsetx > 0) {
                _imagebox.image.offsetx = 0;
            }
            if (_imagebox.image.offsety > 0) {
                _imagebox.image.offsety = 0;
            }
        }

        $scope.fillImagebox = function () {
            $scope.template.pages.page[0].levels.level.forEach(function (level, i) {
                if (level.imagebox && i == vm.levelIndex) {
                    var item = level.imagebox;
                    item.bokehtype = 'Transparent';
                    vm.imageboxLoad = true;
                    getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope, function () {
                        vm.imageboxLoad = false;
                    });
                }
            })
        }
        $scope.cutImagebox = function () {
            $scope.template.pages.page[0].levels.level.forEach(function (level, i) {
                if (level.imagebox && i == vm.levelIndex) {
                    var item = level.imagebox;
                    item.bokehtype = 'None';
                    vm.imageboxLoad = true;
                    getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope, function () {
                        vm.imageboxLoad = false;
                    });
                }
            })
        }
    }
})();
