/**
 * Created by rae on 3/26/2018.
 */
window.angular.module('image.service', []).
factory('ImageService', ['$location', '$http', 'SweetAlert', '$timeout', '$rootScope', '$state','UidService',
    function ($location, $http, SweetAlert, $timeout, $rootScope, $state, UidService) {

        /**
         * png,jpg等图片格式转base64
         * @param url
         * @param callback
         * @param outputFormat
         */
        this.convertImgToBase64 = function (url, callback, outputFormat) {
            var canvas = document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'),
                img = new Image;
            img.crossOrigin = 'Anonymous';
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = function () {
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.imageSmoothingEnabled = true;
                ctx.mozImageSmoothingEnabled = true;
                ctx.webkitImageSmoothingEnabled = true;
                ctx.msImageSmoothingEnabled = true;
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL(outputFormat || 'image/png');
                // callback.call(this, dataURL);
                callback.call(this, dataURLtoBlobs(dataURL));
                canvas = null;
            };
            img.src = url;
        };

        /**
         * base64转Blob
         * @param dataurl
         * @returns {*}
         */
        this.dataURLtoBlob=function(dataurl) {
            return dataURLtoBlobs(dataurl);
        };
        function dataURLtoBlobs(dataurl) {
            var arr = dataurl.split(','),
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            var createObjectURL = function (blob) {
                return window[window.webkitURL ? 'webkitURL' : 'URL']['createObjectURL'](blob);
            };
            var fileURL = URL.createObjectURL(new Blob([u8arr], {type: 'image/*'}));
            return fileURL;
        }

        this.backendUploadPics = function(file, domain, token, callback, errorback) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "gallery-" + UidService.get());
            formData.append('token',  token);
            var reader2 = new FileReader();
            reader2.readAsArrayBuffer(file)
            reader2.onloadend = function(e) {
                formData.append('crc32', CRC32.buf(new Uint8Array(this.result)));
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function(data) {
                        callback.call(this, data, file);
                    },
                    error: function(request) {
                        errorback.call(this, file)
                    }
                });
            };
        }

        return this;

    }]);
