(function () {
    'use strict';
    angular
        .module('editorApp')
        .controller('GalleryController', GalleryController)
        .filter('dateFilter', dateFilter);
    GalleryController.$inject = ['$scope', '$state', 'Gallery', '$q', '$stateParams', '$localStorage', 'Qinius', 'SweetAlert',
        '$timeout', 'UidService', 'MessageService', 'Theme', 'CommonService', 'Folder', '$translate'];
    function GalleryController($scope, $state, Gallery, $q, $stateParams, $localStorage, Qinius, SweetAlert,
                               $timeout, UidService, MessageService, Theme, CommonService, Folder, $translate) {

        // 数据存储结构
        // $scope.photos -- for -- photo -- photo.galleries -- for -- gallery
        var vm = this;

        //这一步是分页下拉  刷新滚动条置顶
        window.scrollTo(0, 0);

        wx.hideOptionMenu();
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        $scope.currentNav = 'gallery';
        // 是否进入相册内
        $scope.folderInside = false;
        // 选中的某一相册信息
        $scope.folderOne = {};
        // 是否点击移动按钮
        $scope.moveGallery = false
        // 相册点击
        $scope.folderClick = false;
        $scope.selectAllFolder = false;
        $scope.selectOne = {
            folderId: '',
            selected: false
        };
        $scope.selectAllGallery = false;
        $scope.moveGalleryIds = [];
        $scope.folderIds = [];
        $scope.editFolderNameArr = [];

        //区别小程序、公众号
        if (window.location.href.indexOf("?") == -1) {
            localStorage.setItem('distinguish', '');
            var storeAuth = localStorage.getItem('store-' + vm.storeId + '-authenticationToken');
            localStorage.setItem('editor-' + vm.storeId + '-authenticationToken', storeAuth);
        } else {
            var r = window.location.href.split('?')[1];
            var A = r.split('&')[0].split('=')[1];
            var B = r.split('&')[1].split('=')[1];
            var distinguish = decodeURIComponent(A);
            var tempAuthorization = decodeURIComponent(B);
            var Authorization = tempAuthorization.slice(7, tempAuthorization.length);
            if (distinguish == 'weChatApplet' || distinguish == 'weChatAppletMake') {
                localStorage.setItem('Production', 'gallery');
                localStorage.setItem('distinguish', distinguish);
                $localStorage.authenticationToken = Authorization;
            } else {
                localStorage.setItem('distinguish', '');
            }
        }

        //避免重复调用
        var production = localStorage.getItem('Production');
        if (production == 'make') {
            return;
        }

        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        $scope.agentIos = true;

        // $scope.photos = Gallery.get();
        $scope.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.galleryPage = [];
        var tempArr = [];

        // 成功多少张
        var indd = 0;
        var errorImageArr = [];
        vm.loadMore = loadMore;
        var repeatCount = 0;
        vm.indexNum = 0;
        vm.itemsPerPage = 20;
        // var Orientation = 0;
        vm.more = true;
        CommonService.setDocumentTitle($translate.instant("galleryContent.iCloudAlbums"));
        var flag = true;//用来标志是否滑动到底 加载完成
        function loadAll(folderId) {
            if (folderId == 'isNull') {
                Gallery.getPage({
                    items: [{key: "folderId", op: "isNull", value: ''}],
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Gallery.getPage({
                    items: [{key: "folderId", op: "=", value: folderId}],
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                return ["createdDate,desc"];
            }

            function onSuccess(res) {
                if(res.status == 200){
                    if (vm.indexNum == 0 && res.data != null && res.data.length != 0) {
                        $scope.noPic = false;
                    } else {
                        // $scope.noPic = true;
                    }
                    if (res.data.length < vm.itemsPerPage) {
                        vm.more = false;
                        flag = false;
                    } else {
                        vm.more = true;
                        flag = true;
                    }

                    angular.forEach(res.data, function (item) {
                        vm.galleryPage.push(item);
                    });
                    getPagePhoto();
                }else {
                    MessageService.error(res.message);
                }
            }

            function onError(error) {
                if(error.status !== 403){
                    MessageService.hint($translate.instant("galleryMessage.failedToObtainThePhotoGallery"));
                }
            }
        }

        function loadMore() {
            vm.indexNum++;
            if ($scope.folderInside) {
                loadAll($scope.folderOne.id);
            } else {
                loadAll('isNull');
            }
        }

        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            //或者return false;
            e.preventDefault();
        };

        //判断是ios or Android
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        //风格切换  引入不同的css文件
        Theme.judge();
        console.log(localStorage.getItem('distinguish'));

        //获取分页图片 (删除、上传都会重新刷新获取一定数量的照片)
        function getPagePhoto() {
            console.log(vm.galleryPage);//这个是分页限制的数据格式 每次只显示10条
            var timeList = [];
            vm.photoArray = [];
            //判断日期是否重复
            for (var i = 0; i < vm.galleryPage.length; i++) {
                var photoCreatedDate = new Date(vm.galleryPage[i].createdDate);
                if (checkExist(timeList, photoCreatedDate)) {
                    timeList.push(photoCreatedDate);
                }
            }
            //将相同日期下的照片整合
            for (var m = 0; m < timeList.length; m++) {
                var tempObj = {};
                var tempItems = [];
                for (var j = 0; j < vm.galleryPage.length; j++) {
                    var createdDate = new Date(vm.galleryPage[j].createdDate);
                    if (timeList[m].getFullYear() == createdDate.getFullYear() &&
                        timeList[m].getMonth() == createdDate.getMonth() &&
                        timeList[m].getDate() == createdDate.getDate()) {
                        tempItems.push(vm.galleryPage[j])
                    }
                }
                var time = timeList[m].getFullYear() + '年' + (timeList[m].getMonth() + 1) + '月' + timeList[m].getDate() + '日';
                tempObj = {"createDate": time, "galleries": tempItems};
                vm.photoArray.push(tempObj);
            }
            $scope.photos = vm.photoArray;
            $(".photo-show").show();
        };

        $q.all([$scope.domains.$promise, vm.tokens.$promise]).then(function () {
            loadAll('isNull');
            loadFolders();
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            $scope.select_photo = false;
            $scope.delBar = false;
            $scope.select_photoAll = false;
            $scope.photoCount = false;
            // if (!!$scope.photos.length) {
            //     $scope.noPic = false;
            // }

            if (isAndroid == true && isiOS == false) {
                console.log('android');
                $scope.agentIos = true;
                /********************************************
                 * 上传图片功能   后台会返回上传图片的日期
                 * @type {boolean}
                 * 图片接口    chooseImage拍照、本地选图
                 ********************************************/
                $scope.uploadPhoto = function () {
                    var currentIndex = 0;
                    wx.chooseImage({
                        count: 9, // 默认9
                        sizeType: ['original'], // 可以指定是原图还是压缩图，默认二者都有
                        sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                        success: function (res) {
                            var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                            $(".save-pages").removeClass('hide1');
                            //异步上传     9张  18s+
                            asyncGetImg(localIds);

                            function asyncGetImg(datas) {
                                $(".save-pages").addClass('hide1');
                                setTimeout(function () {
                                    swal({
                                        title: $translate.instant("uploadContent.startUploading",{datasLength:datas.length}),
                                        text: $translate.instant("uploadContent.greatBeach"),
                                        showConfirmButton: false,
                                        type: "info"
                                    });
                                }, 500);
                                indd = 0;  //成功的张数
                                errorImageArr = [];  //错误的张数
                                var index_ = 0;   //上传到第几张
                                if (datas.length > index_) {
                                    getImg2(datas, index_);
                                }
                            }

                            function getImg2(datas, index_) {
                                //上传到微信服务器
                                wx.uploadImage({
                                    localId: datas[index_],
                                    isShowProgressTips: 1,
                                    success: function (res) {
                                        index_++;
                                        if (datas.length > index_) {
                                            getImg2(datas, index_);
                                        }
                                        swal({
                                            title: $translate.instant("uploadContent.illegalContent",{datasLength:datas.length}),
                                            text: $translate.instant("uploadContent.greatBeach"),
                                            showConfirmButton: false,
                                            type: "info"
                                        });
                                        Gallery.upload({
                                            identifiers: res.serverId,
                                            provider: "QINIU",
                                            fileName: '',
                                            imgSize: 0,
                                            folderId: $scope.folderOne ? $scope.folderOne.id : ''
                                        }, function (resp) {
                                            if(resp.status == 200){
                                                if (resp.data[0].state == '1') {
                                                    repeatCount += 1;
                                                }
                                                indd++;
                                                if (indd == datas.length) {
                                                    setTimeout(function () {
                                                        swal({
                                                            title: $translate.instant("uploadContent.uploadCompleted"),
                                                            text: repeatCount > 0 ? $translate.instant("uploadContent.illegalContent",{repeatCount:repeatCount}) : '',
                                                            imageUrl: Theme.sweetSuccedImg(),
                                                            confirmButtonText: $translate.instant("uploadContent.confirm")
                                                        });
                                                        updatePic();
                                                    }, 1000);
                                                } else if (indd == (datas.length + errorImageArr.length) && errorImageArr.length != 0) {
                                                    SweetAlert.swal({
                                                        title: $translate.instant("uploadContent.uploadCompleted"),
                                                        text: $translate.instant("uploadContent.breakUpload",{breakUpload:errorImageArr.length}),
                                                        imageUrl: Theme.sweetHintImg(),
                                                        showCancelButton: true,
                                                        confirmButtonText: $translate.instant("uploadContent.confirm"),
                                                        cancelButtonText: $translate.instant("uploadContent.cancel"),
                                                    }, function (isConfirm) {
                                                        if (isConfirm) {
                                                            asyncGetImg(errorImageArr);
                                                        } else {
                                                            SweetAlert.swal({
                                                                title: $translate.instant("uploadContent.uploadCancle"),
                                                                imageUrl: Theme.sweetHintImg(),
                                                                text: "",
                                                                timer: 2000,
                                                                showConfirmButton: false
                                                            });
                                                            updatePic();
                                                        }
                                                    })

                                                }
                                            }else {
                                                MessageService.error(res.message);
                                            }
                                        }, function (error) {
                                            errorImageArr.push(datas[index_]);
                                            //最后一个失败了的话
                                            if (datas.length == (indd + errorImageArr.length)) {
                                                SweetAlert.swal({
                                                    title: $translate.instant("uploadContent.uploadCompleted"),
                                                    text: $translate.instant("uploadContent.breakUpload",{breakUpload:errorImageArr.length}),
                                                    imageUrl: Theme.sweetHintImg(),
                                                    showCancelButton: true,
                                                    confirmButtonText: $translate.instant("uploadContent.confirm"),
                                                    cancelButtonText: $translate.instant("uploadContent.cancel"),
                                                }, function (isConfirm) {
                                                    if (isConfirm) {
                                                        asyncGetImg(errorImageArr);
                                                    } else {
                                                        SweetAlert.swal({
                                                            title: $translate.instant("uploadContent.uploadCancle"),
                                                            imageUrl: Theme.sweetHintImg(),
                                                            text: "",
                                                            timer: 2000,
                                                            showConfirmButton: false
                                                        });
                                                        updatePic();
                                                    }
                                                })
                                            }
                                        });
                                    },
                                    error: function (error) {
                                        swal({
                                            // title: "图片上传中(" + (indd + 1 + errorImageArr.length) + "/" + datas.length + ")....",
                                            title: $translate.instant("uploadContent.startUploading2",{currentLenght:indd + 1 + errorImageArr.length,datasLength:datas.length}),
                                            text: $translate.instant("uploadContent.greatBeach"),
                                            showConfirmButton: false,
                                            type: "info"
                                        });
                                        errorImageArr.push(datas[index_]);
                                        //最后一个失败了的话
                                        if (datas.length == (indd + errorImageArr.length)) {
                                            SweetAlert.swal({
                                                title: $translate.instant("uploadContent.uploadCompleted"),
                                                text: $translate.instant("uploadContent.breakUpload",{breakUpload:errorImageArr.length}),
                                                imageUrl: Theme.sweetHintImg(),
                                                showCancelButton: true,
                                                confirmButtonText: $translate.instant("uploadContent.confirm"),
                                                cancelButtonText: $translate.instant("uploadContent.cancel"),
                                            }, function (isConfirm) {
                                                if (isConfirm) {
                                                    asyncGetImg(errorImageArr);
                                                } else {
                                                    SweetAlert.swal({
                                                        title: $translate.instant("uploadContent.uploadCancle"),
                                                        imageUrl: Theme.sweetHintImg(),
                                                        text: "",
                                                        timer: 2000,
                                                        showConfirmButton: false
                                                    });
                                                    updatePic();
                                                }
                                            })
                                        }
                                    }
                                });
                            }
                        },
                        error: function () {
                            console.log("error");
                        }
                    });
                };

            } else if (isAndroid == false && isiOS == true) {
                console.log('ios');
                // 使用自带的上传图片功能  不调用微信的jssdk
                $scope.agentIos = false;
                //非调用微信公众号上传图片 无9张限制 可多张上传 上传到七牛  并且可以返回路径在页面显示
                window.uploadImageMutiple = function ($event) {
                    var files = [];
                    for (var i = 0; i < $event.target.files.length; i++) {
                        files.push($event.target.files[i])
                    }
                    var lowerSize = [];
                    angular.forEach(files, function (item, index) {
                        if (item.size / 1024 < 150 || item.size / 1024 > 20480) {
                            lowerSize.push(index + 1);
                        }
                    });
                    if (lowerSize.length > 0) {
                        swal({
                                // title: '存在' + lowerSize.length + '张图片小于150k或大于20M\n确认上传？',
                                title: $translate.instant("uploadContent.pictureError",{lowerSizeLenght:lowerSize.length}),
                                imageUrl: Theme.sweetHintImg(),
                                showCancelButton: true,
                                confirmButtonText: $translate.instant("uploadContent.confirm"),
                                cancelButtonText: $translate.instant("uploadContent.cancel"),
                            },
                            function (isConfirm) {
                                $(".save-pages").removeClass('hide1');
                                if (isConfirm) {
                                    filterUpload(files);
                                } else {
                                    files = files.filter(function (item, index) {
                                        return item.size / 1024 >= 150;
                                    });
                                    files = files.filter(function (item, index) {
                                        return item.size / 1024 < 20480;
                                    });
                                    if (files.length == 0) {
                                        updatePic();
                                        // $state.go('gallery', {storeId: vm.storeId}, {reload: true});
                                    } else {
                                        filterUpload(files);
                                    }
                                }
                            })
                    } else {
                        $(".save-pages").removeClass('hide1');
                        filterUpload(files);
                    }
                    function filterUpload(files) {
                        $(".save-pages").addClass('hide1');

                        //七牛异步 本地同步 9张图 使用时间 7s+      9s
                        // halfAsyncUpload(files);
                        //完全异步 9张图  使用时间  6s+    8s
                        asyncUpload(files);

                        //完全同步 9张图 使用时间 14s+    12s
                        // fileGet(0, files);
                    }


                    function asyncUpload(files) {
                        indd = 0;
                        errorImageArr = [];
                        setTimeout(function () {
                            swal({
                                title: $translate.instant("uploadContent.startUploading",{datasLength:files.length}),
                                text: $translate.instant("uploadContent.greatBeach"),
                                showConfirmButton: false,
                                type: "info"
                            });
                        }, 500);
                        var len = files.length;
                        for (var i = 0; i < 5 && i < files.length; i++) {
                            var file = files[i];
                            qiniuUpload(file, files, i);
                        }
                    }

                    function qiniuUpload(file, files, index_) {

                        var formData = new FormData();
                        formData.append('file', file);
                        formData.append('unique_names', false);
                        formData.append('key', "gallery-" + UidService.get());
                        formData.append('token', vm.token);
                        var reader = new FileReader();
                        reader.readAsArrayBuffer(file)
                        reader.onloadend = function (e) {
                            formData.append('crc32', CRC32.buf(new Uint8Array(this.result)));
                            return $.ajax({
                                cache: true,
                                type: "POST",
                                url: 'https://upload.qiniup.com/',
                                data: formData,
                                async: true,
                                contentType: false,
                                processData: false,
                                success: function (data) {
                                    swal({
                                        title: $translate.instant("uploadContent.startUploading2",{currentLenght:indd + 1 + errorImageArr.length,datasLength:files.length}),
                                        text: $translate.instant("uploadContent.greatBeach"),
                                        showConfirmButton: false,
                                        type: "info"
                                    });
                                    $.ajax({
                                        type: "GET",
                                        url: $scope.domain + data.key + "?imageInfo",
                                        success: function (va) {
                                            if (va.orientation == "Left-bottom" || va.orientation == "Right-top") {
                                                file.width = va.height;
                                                file.height = va.width;
                                            } else {
                                                file.width = va.width;
                                                file.height = va.height;
                                            }
                                            serviceUpload(data, file, files.length);
                                        },
                                        error: function (request) {
                                            serviceUpload(data, file, files.length);
                                        }
                                    });
                                    if (files.length > index_ + 1 && index_ >= 4) {
                                        var f = files[index_ + 1];
                                        qiniuUpload(f, files, index_ + 1);
                                    }
                                },
                                error: function (request) {
                                    errorImageArr.push(file);
                                    swal({
                                        // title: "图片上传中(" + (indd + 1) + "/" + files.length + ")....",
                                        title: $translate.instant("uploadContent.pictureUpload",{currentLenght:indd + 1,datasLength:files.length}),
                                        text: $translate.instant("uploadContent.greatBeach"),
                                        showConfirmButton: false,
                                        type: "info"
                                    });
                                    //最后一个失败了的话
                                    if (files.length == (indd + errorImageArr.length)) {
                                        SweetAlert.swal({
                                            title: $translate.instant("uploadContent.uploadCompleted"),
                                            text: $translate.instant("uploadContent.breakUpload",{breakUpload:errorImageArr.length}),
                                            imageUrl: Theme.sweetHintImg(),
                                            showCancelButton: true,
                                            confirmButtonText: $translate.instant("uploadContent.confirm"),
                                            cancelButtonText: $translate.instant("uploadContent.cancel"),
                                        }, function (isConfirm) {
                                            if (isConfirm) {
                                                asyncUpload(errorImageArr);
                                            } else {
                                                SweetAlert.swal({
                                                    title: $translate.instant("uploadContent.uploadCancle"),
                                                    imageUrl: Theme.sweetHintImg(),
                                                    text: "",
                                                    timer: 2000,
                                                    showConfirmButton: false
                                                });
                                                updatePic();
                                                // $state.go('gallery', {storeId: vm.storeId}, {reload: true});
                                            }
                                        })
                                    }
                                }
                            });
                        }
                    }

                    function serviceUpload(data, file, len) {
                        Gallery.upload({
                            identifiers: data.key,
                            provider: "QINIU",
                            fileName: file.name,
                            imgSize: file.size,
                            width: file.width,
                            height: file.height,
                            folderId: $scope.folderOne ? $scope.folderOne.id : ''
                        }, function (resp) {
                            if(resp.status == 200){
                                if (resp.data[0].state == '1') {
                                    repeatCount += 1;
                                }
                                indd++;
                                if (len == (indd + errorImageArr.length) && errorImageArr.length == 0) {
                                    setTimeout(function () {
                                        swal({
                                            title: $translate.instant("uploadContent.uploadCompleted"),
                                            text: repeatCount > 0 ? $translate.instant("uploadContent.illegalContent",{repeatCount:repeatCount}) : '',
                                            imageUrl: Theme.sweetSuccedImg(),
                                            confirmButtonText: $translate.instant("uploadContent.confirm")
                                        });
                                        updatePic();
                                    }, 1000)
                                } else if (len == (indd + errorImageArr.length) && errorImageArr.length != 0) {
                                    SweetAlert.swal({
                                        title: $translate.instant("uploadContent.uploadCompleted"),
                                        text: $translate.instant("uploadContent.breakUpload",{breakUpload:errorImageArr.length}),
                                        imageUrl: Theme.sweetHintImg(),
                                        showCancelButton: true,
                                        confirmButtonText: $translate.instant("uploadContent.confirm"),
                                        cancelButtonText: $translate.instant("uploadContent.cancel"),
                                    }, function (isConfirm) {
                                        if (isConfirm) {
                                            asyncUpload(errorImageArr);
                                        } else {
                                            SweetAlert.swal({
                                                title: $translate.instant("uploadContent.uploadCancle"),
                                                imageUrl: Theme.sweetHintImg(),
                                                text: "",
                                                timer: 2000,
                                                showConfirmButton: false
                                            });
                                            updatePic();
                                        }
                                    })
                                }
                            }else {
                                MessageService.error(res.message);
                            }
                        }, function (error) {
                            errorImageArr.push(file);
                            //最后一个失败了的话
                            if (len == (indd + errorImageArr.length)) {
                                SweetAlert.swal({
                                    title: $translate.instant("uploadContent.uploadCompleted"),
                                    text: $translate.instant("uploadContent.breakUpload",{breakUpload:errorImageArr.length}),
                                    imageUrl: Theme.sweetHintImg(),
                                    showCancelButton: true,
                                    confirmButtonText: $translate.instant("uploadContent.confirm"),
                                    cancelButtonText: $translate.instant("uploadContent.cancel"),
                                }, function (isConfirm) {
                                    if (isConfirm) {
                                        asyncUpload(errorImageArr);
                                    } else {
                                        SweetAlert.swal({
                                            title: $translate.instant("uploadContent.uploadCancle"),
                                            imageUrl: Theme.sweetHintImg(),
                                            text: "",
                                            timer: 2000,
                                            showConfirmButton: false
                                        });
                                        updatePic();
                                    }
                                })
                            }
                        });
                    }
                };
            }
        });


        $scope.noPic = true;
        // var select = true;
        vm.aid = $stateParams.aid;
        vm.vid = '';
        vm.pid = '';
        $scope.dHeight = document.documentElement.clientHeight;
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        if ($stateParams.pid) {
            vm.pid = $stateParams.pid;
            vm.vid = $stateParams.vid;
        }
        $scope.gallaryPic = [];         //个人图片库选择
        $scope.counts = localStorage.getItem('maskCounts');

        //个人图片库单张选中当前图片
        $scope.selectThis = function (gallery, photo, index) {
            console.log('personal gallery');
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            gallery.select_photo = !gallery.select_photo;
            for (var i = 0; i < photo.galleries.length; i++) {
                if (!photo.galleries[i].select_photo) {
                    photo.select_photoAll = false;
                    break;
                } else if (i == photo.galleries.length - 1) {
                    photo.select_photoAll = true;
                }
            }
            var t = 0;
            angular.forEach($scope.photos, function (p) {
                angular.forEach(p.galleries, function (g) {
                    if (g.select_photo) {
                        t++;
                        $scope.gallaryPic.push(g);       //选择导入图片的数组
                        $scope.moveGalleryIds.push(g.id);
                    }
                })
            });
            $scope.select_photo = t ? true : false;         //确认按钮的显示
            $scope.delBar = t ? true : false; //删除栏的显示
        };

        //个人图片库全选   这里也不一定就会有图片  说不定就是现场上传图片
        $scope.selectedPhoto = function (index, photo) {
            console.log('personal gallery');
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            if ($scope.photos == '' || $scope.photos == null) {
                return;
            } else {
                if (photo.select_photoAll == null) {
                    photo.select_photoAll = true;
                } else {
                    photo.select_photoAll = !photo.select_photoAll;
                }
                $scope.delBar = true;
                angular.forEach(photo.galleries, function (g, i) {
                    g.select_photo = photo.select_photoAll;
                });

                if (photo.select_photoAll) {
                    $scope.select_photoAll = true;
                } else {
                    $scope.select_photoAll = false;
                }

                var t = 0;
                angular.forEach($scope.photos, function (p, i) {
                    angular.forEach(p.galleries, function (g, j) {
                        if (g.select_photo == true) {
                            $scope.gallaryPic.push(g);      //选择导入图片的数组
                            $scope.moveGalleryIds.push(g.id);
                        }
                    });
                    if (!p.select_photoAll) {
                        t++;
                    }
                    if (i == $scope.photos.length - 1) {
                        if (t == $scope.photos.length && $scope.gallaryPic.length == 0) {
                            $scope.delBar = false;
                        }
                    }
                });

            }
        };

        //显示图片选择图标以及删除栏
        $scope.showDeleteBtn = function () {
            $scope.selectAllGallery = !$scope.selectAllGallery;
            $scope.delBar = $scope.selectAllGallery;
            isShowSelectDot($scope.selectAllGallery);
        };

        function isShowSelectDot(flag) {
            angular.forEach($scope.photos, function (p) {
                p.select_photoAll = flag;
                angular.forEach(p.galleries, function (g) {
                    g.select_photo = flag;
                    if (flag) {
                        $scope.gallaryPic.push(g);       //选择导入图片的数组
                        $scope.moveGalleryIds.push(g.id);
                    } else {
                        $scope.gallaryPic = [];
                        $scope.moveGalleryIds = [];
                    }
                });
            });
        }

        //删除图库图片（数据库）
        $scope.deleteThis = function () {
            var del_str = '';
            angular.forEach($scope.photos, function (photo, index) {
                angular.forEach(photo.galleries, function (gallery, i) {
                    if (gallery.select_photo) {
                        del_str += gallery.id + ','
                    }
                })
            });
            if (!del_str) {
                swal({
                    title: $translate.instant("uploadContent.operationFailure"),
                    text: $translate.instant("uploadContent.atLeastPicture"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 1500,
                    showConfirmButton: false
                });
                return
            }
            swal({
                    title: $translate.instant("uploadContent.deleteThePicture"),
                    text: "",
                    imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: $translate.instant("uploadContent.confirm"),
                    cancelButtonText: $translate.instant("uploadContent.cancel"),
                    closeOnConfirm: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        swal.close();
                        Gallery.delete({id: del_str}, function (res) {
                            if(res.status == 200){
                                swal({
                                    title: $translate.instant("uploadContent.deletedSuccess"),
                                    text: "",
                                    imageUrl: Theme.sweetSuccedImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                updatePic();
                            }else {
                                MessageService.error(res.message);
                            }
                        }, function () {
                            swal({
                                title: $translate.instant("uploadContent.deleteFailed"),
                                text: "",
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        });
                    } else {
                        console.log('取消');
                    }
                });
        };

        function isIphoneX() {
            return /iphone/gi.test(navigator.userAgent) && (screen.height == 812 && screen.width == 375)
            // return /iphone/gi.test(navigator.userAgent)
        }

        $scope.isIphoneX = isIphoneX();

        var windowH = $(window).height();
        localStorage.setItem('windowH', windowH);

        $(window).scroll(function () {
            if ($scope.isIphoneX) {
                var wh = $(window).height(); //页面可视化区域高度
                if (wh > windowH) {
                    $(".iphoneX-content").height(36);
                    $(".footer-del").height(96);
                } else {
                    $(".iphoneX-content").height(0);
                    $(".footer-del").height(60);
                }
            }

            //    向下滑动加载照片
            var scrollTop = $(window).scrollTop();
            var widH = $(window).height();
            var docH = $(document).height();
            if (scrollTop + widH == docH) {
                if (flag == true) {
                    loadMore();
                } else {
                    return;
                }
            } else {
                return;
            }
        })

        function loadFolders() {
            Folder.getAllByCondition({
                items: [{key: "type", op: "=", value: "Gallery"}],
                sort: ["id,asc"]
            }, function (resp) {
                if(resp.status == 200){
                    $scope.Folders = resp.data;
                }else {
                    MessageService.error(resp.message);
                }
            })
        }

        // 增加相册
        $scope.addFolder = function () {
            addAlertClass();
            $(".folder-footer").hide();
            swal({
                title: $translate.instant("uploadContent.createAlbum"),
                text: "",
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonText: $translate.instant("uploadContent.confirm"),
                cancelButtonText: $translate.instant("uploadContent.cancel"),
                animation: "slide-from-top",
                inputPlaceholder: $translate.instant("uploadContent.pleaseEnterTheAlbumName"),
            }, function (inputValue) {
                if (inputValue === false) {
                    $(".folder-footer").show();
                    removeAlertClass();
                    return false;
                }
                if (inputValue === "") {
                    swal.showInputError($translate.instant("uploadContent.pleaseEnterName"));
                    return false
                }
                if (inputValue.indexOf(" ") != -1) {
                    swal.showInputError($translate.instant("uploadContent.pleaseDoNotEnterSpaces"));
                    return false
                }
                Folder.save({
                    name: inputValue,
                    type: 'Gallery'
                }, function (resp) {
                    if(resp.status == 200){
                        $scope.Folders.push(resp.data);
                        $(".folder-footer").show();
                        swal.close();
                        removeAlertClass();
                    }else {
                        MessageService.error(resp.message);
                    }
                },function () {
                    swal.showInputError($translate.instant("uploadContent.pleaseEnterTheCorrectFileName"));
                })
            });
        }
        function addAlertClass() {
            setTimeout(function () {
                $(".sweet-alert").addClass('folder-alert');
                $(".sa-button-container").addClass('folder-alert-top')
                $(".sa-button-container .cancel").addClass('folder-alert-right')
            },200)
        }
        function removeAlertClass() {
            setTimeout(function () {
                $(".sweet-alert").removeClass('folder-alert');
                $(".sa-button-container").removeClass('folder-alert-top')
                $(".sa-button-container .cancel").removeClass('folder-alert-right')
            },200)
        }

        // 修改相册名
        $scope.editFolder = function () {
            addAlertClass();
            var len = $scope.folderIds.length;
            if (len > 1 || !len) {
                return
            }
            var folder = $scope.editFolderNameArr;
            $(".folder-footer").hide();
            $(".folder-footer").hide();
            swal({
                title: $translate.instant("galleryContent.rechristen"),
                text: "",
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonText: $translate.instant("uploadContent.confirm"),
                cancelButtonText: $translate.instant("uploadContent.cancel"),
                animation: "slide-from-top",
                inputPlaceholder: $translate.instant("uploadContent.pleaseEnterTheAlbumName"),
                inputValue: folder.name
            }, function (inputValue) {
                if (inputValue === false) {
                    $(".folder-footer").show();
                    removeAlertClass();
                    return false;
                }
                if (inputValue === "") {
                    swal.showInputError($translate.instant("uploadContent.pleaseEnterName"));
                    return false
                }
                if (inputValue.indexOf(" ") != -1) {
                    swal.showInputError($translate.instant("uploadContent.pleaseDoNotEnterSpaces"));
                    return false
                }
                if (inputValue === folder.name) {
                    swal.showInputError($translate.instant("uploadContent.PleaseEnterADifferentAlbumName"));
                    return false
                }
                Folder.update({
                    name: inputValue,
                    type: 'Gallery',
                    id: folder.id
                }, function (resp) {
                    if(resp.status == 200){
                        cancelSelectFolders();
                        folder.name = resp.data.name;
                        $(".folder-footer").show();
                        swal.close();
                        removeAlertClass();
                    }else {
                        MessageService.error(resp.message);
                    }
                },function () {
                    swal.showInputError($translate.instant("uploadContent.pleaseEnterTheCorrectFileName"));
                })
            });
        }

        /**
         * 单选
         * @param item
         * @param flag-----   true：相册     false:作品
         * @param y
         * @param d
         * @param i
         */
        $scope.selectSingle = function (item, flag, y, d, i) {
            if (!item) {
                $scope.selectOne = {
                    folderId: '',
                    selected: true
                };
                angular.forEach($scope.Folders, function (folder, index) {
                    folder.selected = false;
                });
                return
            }
            item.selected = !item.selected;
            // 移动状态中
            if ($scope.moveGallery) {

                $scope.selectOne = {
                    folderId: item.id,
                    selected: item.selected
                };

                angular.forEach($scope.Folders, function (folder, index) {
                    folder.selected = false;
                });
                item.selected = $scope.selectOne.selected;
                if(item.selected){
                    $scope.folderOne = angular.copy(item);
                }
                return
            }

            $scope.selectOne = {
                folderId: '',
                selected: false
            };
            //未选中时
            if (!item.selected) {
                // 相册
                if (flag) {
                    $scope.selectAllFolder = false;
                    var ind = $scope.folderIds.indexOf(item.id);
                    if (ind >= 0) {
                        $scope.folderIds.splice(ind, 1);
                    }
                } else {
                    $scope.selectAllAritle = false;
                    var ind = $scope.moveGalleryIds.indexOf(item.id);
                    if (ind >= 0) {
                        $scope.moveGalleryIds.splice(ind, 1);
                    }
                }
            } else {
                if (flag) {
                    $scope.folderIds.push(item.id);

                }else{
                    // 选中状态时的作品列表
                    // var arr = vm.yearDateList[y].data[d].items[i];
                    // $scope.moveGalleryIds.push(arr.id);
                }
            }
        }

        $scope.selectFolders = function (item) {
            item.selected = !item.selected;

            $scope.selectOne = {
                folderId: '',
                selected: false
            };
            //未选中时
            if (!item.selected) {
                $scope.selectAllFolder = false;
                var ind = $scope.folderIds.indexOf(item.id);
                if (ind >= 0) {
                    $scope.folderIds.splice(ind, 1);
                }

                $scope.editFolderNameArr = [];

            } else {
                $scope.folderIds.push(item.id);
                $scope.editFolderNameArr = item;
            }
        }

        function cancelSelectFolders() {
            $scope.folderIds = [];
            angular.forEach($scope.Folders, function (item) {
                item.selected = false;
            });
        }

        /**
         * 全选
         * @param flag-----  true：相册     false:作品
         */
        $scope.chooseAll = function (flag) {
            if (flag) {
                $scope.editFolderNameArr = [];
                $scope.selectAllFolder = !$scope.selectAllFolder;
                angular.forEach($scope.Folders, function (item, index) {
                    if ($scope.selectAllFolder) {
                        item.selected = true;
                        $scope.folderIds.push(item.id);
                    } else {
                        item.selected = false;
                        $scope.folderIds = [];
                    }
                });
            } else {
                $scope.selectAllGallery = !$scope.selectAllGallery;
                angular.forEach($scope.photos, function (photo) {
                    angular.forEach(photo.galleries, function (item) {
                        if ($scope.selectAllGallery) {
                            item.select_photo = true;
                            photo.select_photoAll = true
                        } else {
                            item.select_photo = false;
                            photo.select_photoAll = false
                        }
                    })
                })
            }

        }

        $scope.saveMove = function () {
            if (!$scope.selectOne.selected) {
                swal({
                    title: $translate.instant("uploadContent.operationFailure"),
                    text:  $translate.instant("uploadContent.pleaseSelectAnAlbum"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 1500,
                    showConfirmButton: false
                });
                return
            }
            angular.forEach($scope.Folders, function (folder, index) {
                folder.selected = false;
            });
            Gallery.moveFolder({
                galleries: $scope.moveGalleryIds,
                folderId: $scope.selectOne.folderId
            }, function (res) {
                if(res.status == 200){
                    $(".photo-show").hide();
                    $scope.currentNav = 'gallery';
                    $scope.gallaryPic = [];
                    $scope.moveGallery = false;
                    $scope.delBar = false;
                    $scope.moveGalleryIds = [];
                    if ($scope.selectOne.folderId) {
                        $scope.folderInside = true;
                        vm.indexNum = 0;
                        vm.galleryPage = [];
                        loadAll($scope.folderOne.id);
                    } else {
                        $scope.folderInside = false;
                        vm.galleryPage = [];
                        vm.indexNum = 0;
                        loadAll('isNull');
                    }
                    $scope.selectOne = {
                        folderId: '',
                        selected: false
                    };
                }else {
                    MessageService.error(res.message);
                }
            })
        }

        $scope.backTo = function () {
            $scope.currentNav = 'gallery';
            $scope.moveGallery = false;
            $scope.delBar = false;
            $scope.selectOne = {
                folderId: '',
                selected: false
            };
            angular.forEach($scope.Folders, function (folder) {
                folder.selected = false;
            });
            clearGalleryAdd();
        }

        //删除多个相册
        $scope.deleteFolders = function () {
            if (!$scope.folderIds.length) {
                swal({
                    title: $translate.instant("uploadContent.operationFailure"),
                    text: $translate.instant("uploadContent.atLeastAlbum"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 1500,
                    showConfirmButton: false
                });
                return
            }
            swal({
                    title: $translate.instant("uploadContent.deleteAlbumHint"),
                    text: "",
                    imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: $translate.instant("uploadContent.confirm"),
                    cancelButtonText: $translate.instant("uploadContent.cancel"),
                    closeOnConfirm: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        swal.close();
                        Folder.batchDelete({ids: $scope.folderIds}, function (res) {
                            if(res.status == 200){
                                swal({
                                    title: $translate.instant("uploadContent.deletedSuccess"),
                                    text: "",
                                    imageUrl: Theme.sweetSuccedImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                loadFolders();
                                cancelSelectFolders();
                            }else {
                                MessageService.error(res.message);
                            }
                        }, function () {
                            swal({
                                title: $translate.instant("uploadContent.deleteFailed"),
                                text: "",
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        });
                    } else {
                        console.log('取消');
                    }
                });
        };

        $scope.moveToFolder = function () {
            if (!$scope.gallaryPic.length) {
                swal({
                    title: $translate.instant("uploadContent.moveFailure"),
                    text: $translate.instant("uploadContent.atLeastPicture"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            $scope.moveGallery = true;
            $scope.folderInside = false;
            $scope.currentNav = 'folder';
        }

        function clearGalleryAdd() {
            // $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            $scope.selectAllGallery = false;
            angular.forEach($scope.photos, function (photo) {
                photo.select_photoAll = false
                angular.forEach(photo.galleries, function (item) {
                    item.select_photo = false;
                })
            })
        }

        // 进入某一相册中
        $scope.showFolderContent = function (folder) {
            if (folder.selected) {
                folder.selected = false;
                $scope.selectAllGallery = false;
                $scope.selectOne = {
                    folderId: '',
                    selected: false
                };

                $scope.selectAllFolder = false;
                var ind = $scope.folderIds.indexOf(folder.id);
                if (ind >= 0) {
                    $scope.folderIds.splice(ind, 1);
                }
                $scope.editFolderNameArr = [];
                return
            }
            $scope.folderInside = true;
            $scope.currentNav = 'gallery';
            $scope.folderOne = angular.copy(folder);
            vm.indexNum = 0;
            vm.galleryPage = [];
            $(".photo-show").hide();
            loadAll(folder.id);
        }
        // 从某一相册出去
        $scope.hideFolderContent = function () {
            $scope.folderInside = false;
            $scope.moveGallery = false;
            $scope.currentNav = 'folder';
            $scope.folderOne = {};
            $scope.selectAllGallery = $scope.delBar = false;
        }
        // 图片库nav 点击
        $scope.galleryNavSel = function () {
            vm.galleryPage = [];
            $(".photo-show").hide();
            $scope.currentNav = 'gallery';
            $(".folder-editbar").addClass('hide1');
            $(".arrow-btn").removeClass('folder-arrow-up');
            angular.forEach($scope.Folders, function (folder, index) {
                folder.selected = false;
            });
            $scope.folderIds = [];
            $scope.folderClick = false;
            vm.indexNum = 0;
            loadAll('isNull');
        }

        // 图片库nav 点击
        $scope.folderNavSel = function () {
            vm.galleryPage = [];
            $scope.currentNav = 'folder';
            $scope.selectAllGallery = $scope.delBar = false;
        }

        // 上传图片后更新图片库/相册
        function updatePic() {
            if ($scope.folderOne && $scope.folderOne.id) {
                $scope.showFolderContent($scope.folderOne);
            } else {
                $scope.folderInside = false;
                $scope.currentNav = 'gallery';
                $scope.moveGallery = false;
                vm.galleryPage = [];
                vm.indexNum = 0;
                loadAll('isNull');
            }
        }

    }

    function dateFilter() {
        return function (input) {
            var year = input.split('年')[0];
            var mon = input.split('年')[1].split('月')[0];
            var day = input.split('年')[1].split('月')[1].split('日')[0];
            if (mon < 10) {
                mon = '0' + mon;
            }
            if (day < 10) {
                day = '0' + day;
            }
            input = year + '年' + mon + '月' + day + '日';
            return input;
        }
    }

    // 判断是否重复
    function checkExist(list, obj) {
        if (list == null || list.length == 0) {
            return true;
        }
        for (var i = 0; i < list.length; i++) {
            if (list[i].getFullYear() == obj.getFullYear() &&
                list[i].getMonth() == obj.getMonth() &&
                list[i].getDate() == obj.getDate()) {
                return false;
            }
        }
        return true;
    }


})();
