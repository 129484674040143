(function() {
    'use strict';

    angular
        .module('editorApp')
        .constant('IMAGE_SUPPORTED_MIME', "image/png, image/jpeg, image/jpg, image/heic")
        ;

    
})();
