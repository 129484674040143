(function () {
    'use strict';

    var uploadImage = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/gallery/uplpadImage.html',
        controllerAs: 'userCtrl',
        bindings: {
            aid:'=',
            tag:'=',
            domain:'=',
            onUploadphoto:'&',
            photoAll:'=',
            folderOne:'=',
            status:'=',
            qiniuId:'=',
            choose:'=',
            uploadPhotoItem:'='
        },

        controller: ['$scope', '$stateParams', '$q', '$localStorage', 'Qinius', 'WxJS', 'Theme', 'Gallery','SweetAlert','$rootScope','ImageService','UidService','Upload','$timeout','$translate',
            function ($scope, $stateParams, $q, $localStorage, Qinius, WxJS, Theme, Gallery, SweetAlert,$rootScope,ImageService,UidService,Upload,$timeout,$translate) {
                var vm = this;

                // 成功多少张
                vm.indd = 0;
                vm.errorImageArr = [];
                vm.filesLength = 0;
                if (window.location.href.indexOf("?") > -1) {
                    // localStorage.removeItem('guide-step');
                    var r = window.location.href.split('?')[1];
                    var typeFlag = r.split('&')[0].split('=')[1];
                    var distinguish = decodeURIComponent(typeFlag);
                    if (distinguish == 'weChatApplet' || distinguish == 'weChatAppletMake') {
                        vm.uploadType = 'wechat';
                    }else if(distinguish == 'toutiaoApp'){
                        vm.uploadType = 'byteDance';
                    }else if(distinguish == 'alipayApp'){
                        vm.uploadType = 'ali';
                    }
                }else{
                    vm.uploadType = 'wechat';
                }

                if (wx) {
                    var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
                    // var url = "https://stage.fotavatar.com/10/143/1230321120340111/editorPhotoBoth";
                    WxJS.get({url: url}, function (config) {
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.data.appId, // 必填，公众号的唯一标识
                            timestamp: config.data.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.data.nonceStr, // 必填，生成签名的随机串
                            signature: config.data.signature,// 必填，签名，见附录1
                            jsApiList: ['chooseImage', 'previewImage', 'uploadImage','getLocalImgData'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function () {
                            // console.log('editor: wechat api ready!');
                        });
                        wx.error(function (res) {
                            // alert("wx.error: "+res.errMsg)
                            // console.log(res);
                        });
                    }, function (res) {
                        // alert("data: "+res.data);
                        // alert("status: "+res.status);
                        // alert("statusText: "+res.statusText);
                        // console.log("editor: wx config error");
                    });
                }

                $scope.domains = Qinius.getDomain();
                vm.tokens = Qinius.get({"mimeLimit":"image/jpeg;image/png;image/jpg;image/heic"});
                $q.all([$scope.domains.$promise, vm.tokens.$promise]).then(function(){
                    $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                    vm.token = vm.tokens.token;
                    //安卓
                    $scope.$on('clickAndroidUpload', function(event, args) {
                        if(vm.uploadType=='ail'){
                            // my.postMessage({name:"测试web-view"});
                            ap.chooseImage(9, function(res){
                                var files = []
                                for (var i = 0; i < res.apFilePaths.length; i++) {
                                    files.push({
                                        localId:res.apFilePaths[i],
                                        blobCover:res.apFilePaths[i],
                                        imgSize:res.tempFiles[i].size
                                    })
                                }
                                var index = 0;
                                // readImages(index);
                                // function readImages(index){
                                //     ap.uploadFile({
                                //         url: ' /fileserves/api/convert/downloadImg4AliAngUpload7Niu',
                                //         fileType: 'image',
                                //         fileName: 'file',
                                //         filePath: '...',
                                //         header: {
                                //             'Content-Type': 'application/x-www-form-urlencoded',
                                //         },
                                //         success: function (resBackData) {
                                //             // alert(resBackData)
                                //         },
                                //         fail: function (res) {
                                //             // alert(res.errMsg);
                                //             console.warn("getLocalImgData fail",res);
                                //             $(".save-pages2").addClass('hide1');
                                //         }
                                //     });
                                // }
                                Gallery.upload({
                                    identifiers: res.apFilePaths[0],
                                    provider: "QINIU",
                                    fileName: '',
                                    imgSize: 0,
                                    folderId: vm.folderOne ? vm.folderOne.id : null,
                                    check: true,
                                    type: vm.uploadType,
                                    articleId:vm.aid
                                }, function (resp) {

                                })
                                return
                            });
                            return;
                        }
                        //将blob转换为file
                        function blobToFile(blob, fileName) {
                            blob.lastModifiedDate = new Date();
                            blob.name = fileName;
                            return blob;
                        };
                        vm.indd = 0;  //成功的张数
                        vm.errorImageArr = [];  //错误的张数
                        var files = [];
                        console.log('···················调用微信传图··················');
                        // 微信Android选图9张
                        wx.chooseImage({
                            count: 9, // 默认9
                            sizeType: ['original'], // 可以指定是原图还是压缩图，默认二者都有
                            sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                            success: function (res) {
                                console.log('···················调用微信成功的回调··················');
                                $(".save-pages2").removeClass('hide1');
                                var localIds = res.localIds;
                                localIds.forEach(function(localId){
                                    wx.getLocalImgData({
                                        localId: localId,
                                        success: function (res) {
                                            var base64Data = res.localData;
                                            var blob = '';
                                            if (base64Data.indexOf("base64") != -1) {
                                                blob = dataURLtoBlob(base64Data);
                                            }
                                            else {
                                                blob = dataURLtoBlob("data:image/jpeg;base64," + base64Data)
                                            }
                                            var file = blobToFile(blob);
                                            file.blobCover = base64Data.indexOf("base64") != -1 ? base64Data : ("data:image/jpeg;base64," + base64Data);
                                            files.push(file);
                                            if (files.length == localIds.length) {
                                                detectionSize();
                                            }
                                        }
                                    });
                                })
                            },
                            fail: function (res) {
                                SweetAlert.swal({
                                    title: '调用微信失败的回调:'+JSON.stringify(res),
                                    imageUrl: Theme.sweetHintImg(),
                                    text: "",
                                    showConfirmButton: true
                                });
                                console.log('···················调用微信失败的回调··················');
                                updatePic();
                            }
                        });

                        function detectionSize(){
                            vm.higherSize = [];
                            angular.forEach(files, function (item, index) {
                                item.imgSize = item.size;
                                if (item.size / 1024 > 20480) {
                                    vm.higherSize.push(index + 1);
                                }
                            });
                            //系统检测到{{}}张大于20M的图片，已自动过滤\n请压缩后再尝试上传！
                            if (vm.higherSize.length > 0) {
                                $scope.bigSizeTip = true;
                                $scope.higherSizeLen = vm.higherSize.length;
                                $scope.$digest();
                            } else {
                                if(files.length > 0){
                                    filterUpload(files);
                                }
                            }

                            $scope.filterBigSize = function () {
                                $scope.bigSizeTip = false;
                                files = files.filter(function (item, index) {
                                    return item.size / 1024 < 20480;
                                });
                                if (files.length == 0) {
                                    updatePic();
                                } else {
                                    filterUpload(files);
                                }
                            }
                        }
                    });

                    /**
                     * iOS上传
                     * 使用自带的上传图片功能  不调用微信的jssdk
                     * 非调用微信公众号上传图片 无9张限制 可多张上传 上传到七牛  并且可以返回路径在页面显示
                     * @param $event
                     */
                    window.uploadImageMutiple = function ($event) {
                        $timeout(function () {
                            $scope.bigSizeTip = false;
                            var files = [];
                            for (var i = 0; i < $event.target.files.length; i++) {
                                var file = $event.target.files[i];
                                if(file.type =='image/png'||file.type =='image/jpeg'||file.type =='image/jpg'||!file.type){
                                    file.usenum = 0;
                                    file.percent = 0;
                                    file.blobCover = URL.createObjectURL(file);
                                    files.push(file)
                                }
                            }

                            vm.higherSize = [];
                            angular.forEach(files, function (item, index) {
                                item.imgSize = item.size;
                                if (item.size / 1024 > 20480) {
                                    vm.higherSize.push(index + 1);
                                }
                            });
                            //系统检测到{{}}张大于20M的图片，已自动过滤\n请压缩后再尝试上传！
                            if (vm.higherSize.length > 0) {
                                $scope.bigSizeTip = true;
                                $scope.higherSizeLen = vm.higherSize.length;
                                $scope.$digest();
                            } else {
                                if(files.length > 0){
                                    filterUpload(files);
                                }
                            }
                            $scope.filterBigSize = function () {
                                $scope.bigSizeTip = false;
                                files = files.filter(function (item, index) {
                                    return item.size / 1024 < 20480;
                                });
                                if (files.length == 0) {
                                    updatePic();
                                } else {
                                    filterUpload(files);
                                }
                            }
                        })

                    };
                });


                function filterUpload(files) {
                    $(".save-pages2").removeClass('hide1');
                    asyncUpload(files);
                }

                function asyncUpload(files) {
                    vm.uploadPhotoItem = files;
                    vm.indd = 0;
                    vm.errorImageArr = [];
                    $rootScope.$broadcast('uploadStart',true);
                    vm.filesLength = vm.uploadPhotoItem.length;
                    qiniuUpload(vm.uploadPhotoItem[0], vm.uploadPhotoItem, 0);
                }

                // function qiniuUpload(file, files, index_) {
                //     var formData = new FormData();
                //     formData.append('file', file);
                //     formData.append('unique_names', false);
                //     formData.append('key', "gallery-" + UidService.get());
                //     formData.append('token', vm.token);
                //     var reader2 = new FileReader();
                //     reader2.readAsArrayBuffer(file)
                //     file.percent = 20;
                //     reader2.onloadend = function (e) {
                //         formData.append('crc32', CRC32.buf(new Uint8Array(this.result)));
                //         return $.ajax({
                //             // cache: true,
                //             type: "POST",
                //             url: 'https://upload.qiniup.com/',
                //             data: formData,
                //             async: true,
                //             contentType: false,
                //             processData: false,
                //             xhr:function(){
                //                 var myXhr = $.ajaxSettings.xhr();
                //                 if(myXhr.upload){ // check if upload property exists
                //                     myXhr.upload.addEventListener('progress',function(e){
                //                         var loaded = e.loaded;                  //已经上传大小情况
                //                         var total = e.total;                      //附件总大小
                //                         var percent = Math.floor(100*loaded/total)+"%";     //已经上传的百分比
                //                         file.percent = parseInt(100*loaded/total)/2;
                //                     }, false); // for handling the progress of the upload
                //                 }
                //                 return myXhr;
                //             },
                //             success: function (data) {
                //                 vm.uploadPhotoItem[index_].blobCover = $scope.domain +data.key + '?imageView2/1/w/150/h/150';
                //                 file.percent = 80;
                //                 assignGalleries(file, files, index_, data)
                //             },
                //             error: function (request) {
                //                 file.state = 'error';
                //                 vm.errorImageArr.push(files[index_]);
                //                 //最后一个失败了的话
                //                 if (files.length == (Number(vm.indd) + Number(vm.errorImageArr.length))) {
                //                     $timeout(function () {
                //                         $scope.loadingAllSuccess = true;
                //                         updatePic();
                //                     })
                //                 }else{
                //                     var _index1 = Number(index_) + 1;
                //                     if (files.length > _index1) {
                //                         var f = files[index_ + 1];
                //                         qiniuUpload(f, files, _index1);
                //                     }
                //                 }
                //             }
                //         });
                //     }
                // }

                function qiniuUpload(file, files , index_){
                    ImageService.backendUploadPics(file, $scope.domain, vm.token, function(data, f){
                        // vm.uploadPhotoItem[index_].blobCover = $scope.domain +data.key + '?imageView2/1/w/150/h/150';
                        file.percent = 80;
                        assignGalleries(file, files, index_, data)
                    },function(err){
                        file.state = 'error';
                        vm.errorImageArr.push(files[index_]);
                        //最后一个失败了的话
                        if (files.length == (Number(vm.indd) + Number(vm.errorImageArr.length))) {
                            $timeout(function () {
                                $scope.loadingAllSuccess = true;
                                updatePic();
                            })
                        }else{
                            var _index1 = Number(index_) + 1;
                            if (files.length > _index1) {
                                var f = files[index_ + 1];
                                qiniuUpload(f, files, _index1);
                            }
                        }
                    })
                }

                function serviceUpload(data, file,callback) {
                    Gallery.upload({
                        identifiers: data.key,
                        provider: "QINIU",
                        fileName: file.name,
                        imgSize: file.size,
                        width: file.width,
                        height: file.height,
                        folderId: vm.folderOne ? vm.folderOne.id : '',
                        check: true,
                        type: vm.uploadType,
                        articleId:vm.aid
                    }, function (resp1) {
                        file.percent = 100;
                        var resp = resp1.data;
                        file.id = resp.id;
                        file.cancelled = resp.cancelled;
                        vm.indd++;
                        file.state = 'success';
                        if (!resp.cancelled) {
                            resp.identifier = $scope.domain +resp.identifier;
                            if(vm.photoAll){
                                vm.photoAll.push(resp)
                            }
                            if(vm.qiniuId){
                                resp.usenum = 0;
                                resp.loading = true;
                                vm.qiniuId.push(resp)
                            }
                            if(vm.onUploadphoto){
                                vm.onUploadphoto({text:JSON.stringify(resp)})
                            }
                        }
                        if(callback){callback()}
                    }, function (error) {
                        file.state = 'error';
                        vm.errorImageArr.push(file);
                        if(callback){callback()}
                    });
                }


                function assignGalleries(file, files, index_, data) {
                    serviceUpload(data, file,function () {
                        if (files.length == (Number(vm.indd) + Number(vm.errorImageArr.length))) {
                            $timeout(function () {
                                $scope.loadingAllSuccess = true;
                                updatePic();
                            })
                        }else{
                            var _index1 = Number(index_) + 1;
                            if (files.length > _index1) {
                                var f = files[_index1];
                                qiniuUpload(f, files, _index1);
                            }
                        }
                    });
                }
                $scope.errorImageArr_=[];
                $scope.reUpload = function () {
                    $scope.errorImageArr_.forEach(function (item) {
                        item.percent = 0;
                        item.state = null;
                    });
                    $scope.errorImageHide();
                    $(".save-pages2").removeClass('hide1');
                    asyncUpload($scope.errorImageArr_);
                }
                // 上传图片后更新图片库/文件夹
                vm.successSum = 0;
                function updatePic() {
                    $scope.isLookErrorImages = false;
                    $(".save-pages2").addClass('hide1');
                    vm.uploadPhotoItem = [];
                    $rootScope.$broadcast('uoloadFinished',vm.errorImageArr.length>0 ? false : true);
                    console.log(vm.errorImageArr)
                    vm.successSum = angular.copy(vm.indd);
                    $timeout(function () {
                        vm.indd = 0;
                        vm.filesLength = 0;
                        //如果没有上传错误图片，关闭提示框
                        if(vm.errorImageArr.length === 0){
                            $scope.loadingAllSuccess = false;
                        }else{
                            $scope.loadingAllSuccess = true;
                        }
                    },2000)
                }
                //关闭上传错误弹窗
                $scope.closeErrorMoadl = function (){
                    var target = event.target;
                    if($(target).hasClass("z-modal") || $(target).hasClass("close-btn") || $(target).hasClass("close-icon1")){
                        $scope.loadingAllSuccess = false;
                    }
                    $rootScope.$broadcast('uoloadFinished',true);
                }
                //上传进度获取
                $scope.schedule = function () {
                    if(vm.filesLength > 0){
                        return Number(vm.indd)+vm.errorImageArr.length + '/' + vm.filesLength
                    }
                }
                $scope.errorImageArrCb = function () {
                    return vm.errorImageArr.length
                }
                $scope.successImageArrCb = function () {
                    return vm.indd || vm.successSum
                }
                $scope.isLookErrorImages = false;
                $scope.lookErrorImages = function () {
                    $scope.isLookErrorImages = true;
                    $scope.loadingAllSuccess = false;
                    $scope.errorImageArr_ = vm.errorImageArr;
                    $scope.errorImageArr_.forEach(function (item) {
                        item.percent = 0;
                    })
                };
                $scope.errorImageHide = function () {
                    $scope.isLookErrorImages = false;
                    $rootScope.$broadcast('uoloadFinished',true);
                }

                function threeInfo(item){
                    $scope.threeError = true;
                    $scope.threeErrorText=item;
                    $timeout(function () {
                        $scope.threeError = false;
                        $scope.threeErrorText="";
                    },1500)
                }
            }]
    };

    angular
        .module('editorApp')
        .component('uploadImage', uploadImage);
})();
