(function() {
    'use strict';

    angular
        .module('editorApp')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http', '$localStorage', '$sessionStorage', '$q', 'MessageService', 'Store', 'LANGUAGES'];

    function AuthServerProvider ($http, $localStorage, $sessionStorage, $q, MessageService, Store, LANGUAGES) {
        var service = {
            getToken: getToken,
            login: login,
            loginWithToken: loginWithToken,
            storeAuthenticationToken: storeAuthenticationToken,
            logout: logout
        };

        return service;

        function getToken () {

            return $localStorage.authenticationToken || $sessionStorage.authenticationToken;
        }

        function login (credentials) {

            var data = {
                phone: credentials.phone,
                password: credentials.password,
                storeId:credentials.storeId,
                rememberMe: credentials.rememberMe
            };
            return $http.post('manager/api/customers/authenticate', data).success(authenticateSuccess);

            function authenticateSuccess (data, status, headers) {
                var bearerToken = headers('Authorization');
                if (bearerToken== null || bearerToken.length<=7) {
                    MessageService.error("登录失败，请重新验证");
                    return ;
                }
                if (angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ') {
                    var jwt = bearerToken.slice(7, bearerToken.length);
                    service.storeAuthenticationToken(jwt, credentials.rememberMe);
                    return jwt;
                }
            }
        }

        function loginWithToken(jwt, rememberMe) {
            var deferred = $q.defer();

            if (angular.isDefined(jwt)) {
                this.storeAuthenticationToken(jwt, rememberMe);
                deferred.resolve(jwt);
            } else {
                deferred.reject();
            }

            return deferred.promise;
        }

        function storeAuthenticationToken(jwt, rememberMe) {
            if(rememberMe){
                $localStorage.authenticationToken = jwt;
            } else {
                $sessionStorage.authenticationToken = jwt;
            }
            Store.current({},function(res){
                if (res.status == 200) {
                    $localStorage.store = res.data;
                    if ($localStorage.store.availableLangKey) {
                        $localStorage.lagnuages = $localStorage.store.availableLangKey;
                    }
                }else{
                    MessageService.error("商户信息获取失败");
                }
            });
            
            // if ($localStorage.lagnuages == null) {
            //     if ($localStorage.store == null) {
            //         Store.current({},function(res){
            //             if (res.status == 200) {
            //                 $localStorage.store = res.data;
            //                 if ($localStorage.store.availableLangKey) {
            //                     $localStorage.lagnuages = $localStorage.store.availableLangKey;
            //                 }
            //             }else{
            //                 MessageService.error("商户信息获取失败");
            //             }
            //         });
            //     }else{
            //         if ($localStorage.store.availableLangKey) {
            //             $localStorage.lagnuages = $localStorage.store.availableLangKey;
            //         }
            //     }
            // }
            
            
        }

        function logout () {
            delete $localStorage.user;
            delete $localStorage.authenticationToken;
            delete $sessionStorage.authenticationToken;
        }
    }
})();
