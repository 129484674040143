(function () {
    'use strict';

    var language = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/language.html',
        controllerAs: 'vm',
        bindings: {
            isShow: '='
        },

        controller: ['$scope', '$stateParams', '$q', 'MessageService', '$translate', '$localStorage', '$cookies', 'translationStorageProvider', '$timeout',
            function ($scope, $stateParams, $q, MessageService, $translate, $localStorage, $cookies, translationStorageProvider, $timeout) {
                var vm = this;
                vm.lang = $cookies.getObject("NG_TRANSLATE_LANG_KEY");
                vm.lagnuages =[];
                if ($localStorage.lagnuages) {
                    for(var i = 0 ; i<$localStorage.lagnuages.length; i++ ){
                        var lang = $localStorage.lagnuages[i];
                        var result = translationStorageProvider.getLanguageType(lang);
                        if (!!result) {
                            if (vm.lang == result.value) {
                                vm.langKey = result.key;
                                result.selected = true;
                            }
                            vm.lagnuages.push(result);
                        }
                    }
                }
                $scope.changeLagnuage = function(lang){
                    if (lang == null || lang.key == vm.langKey ) {
                        return;
                    }
                    angular.forEach(vm.lagnuages, function(item){
                        item.selected =false;
                    });
                    lang.selected = true;
                    vm.langKey = lang.key;
                    $translate.use(lang.value).then(function () {
                        $scope.$emit('changeLanguage', lang);
                        vm.isShow = false;
                    });
                    
                };

                $scope.cancel = function () {
                    vm.isShow = false;
                }
                $scope.save = function () {
                    vm.isShow = false;
                }
        }]
    };

    angular
        .module('editorApp')
        .component('language', language);
})();
