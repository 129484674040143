(function() {
    'use strict';

    angular
        .module('editorApp')
        .factory('Editors', Editors);

    Editors.$inject = ['$state', 'Product'];

    function Editors ($state, Product) {

        var service = {
            navigateTo: navigateTo,
            navigateSelect: navigateSelect
        };

        return service;


        function startMobileMade(pid, vid, xmlId) {
            localStorage.removeItem("delPic");
            localStorage.removeItem("picArrChoose");
            localStorage.removeItem("picArrChoose2");
            localStorage.removeItem("gallery2");
            localStorage.removeItem("picArrCopy");
            localStorage.removeItem("picArr");
            localStorage.removeItem("isSort");
            var workSave = JSON.parse(localStorage.getItem('work-save'));
            if (workSave) {
                var nearHour = (new Date().getTime() - workSave.date) / 1000 / 3600;
                console.log(nearHour);
                if (workSave.pid == pid && workSave.vid == vid && (workSave.xmlId == xmlId || workSave.xmlId == "null" && xmlId == null) && nearHour <= 3) {
                    return
                }
            }

            localStorage.setItem("Production", "start");
            localStorage.removeItem("choose");
            localStorage.removeItem("multipartUpload");
            localStorage.removeItem("index");
            localStorage.removeItem("i");
            localStorage.removeItem("go");
            localStorage.removeItem("single");
            localStorage.removeItem("pages");
            localStorage.removeItem("marginLeft");
            localStorage.removeItem("$index");
            localStorage.removeItem("next");
            localStorage.removeItem("gallery");
            localStorage.removeItem("galleryCopy");
            localStorage.removeItem("saveOldPage");
            localStorage.removeItem("saveOldPageIndex");
            localStorage.removeItem("reviewPages");
            localStorage.removeItem("pageAllPx");
            localStorage.removeItem("pageAllMm");
            localStorage.removeItem("work-save");
            localStorage.setItem('countN', 1);

            var gallerys = JSON.parse(localStorage.getItem('gallery'));
            angular.forEach(gallerys, function (gallery) {
                gallery.useNum = 0;       //次数清零
            });
            localStorage.setItem('gallery', JSON.stringify(gallerys));
        };

        /*
        *   导航到编辑器
        *   storeId - 商户id - 必需
        *   options:
        *       editorType - 编辑器类型 - 必需
        *       pid - 产品ID - 必需
        *       vid - skuID - 必需
        *       xmlId - 模版ID - 必需
        *       aid - 作品ID - 可选
        *       pictureType - 冲印单双面 - 可选
        *       mobile - 是否移动端, true/false
        */
        function navigateTo (storeId, options) {
            if (!storeId || !options || !options.pid || !options.vid || !options.xmlId) {
                console.log("can't navigate to editor!!!!, storeId=" + storeId + ", options:", options);
                return;
            }
            var isMobile = !!options.mobile;
            var editorType = options.editorType || '';

            if (!isMobile) {
                if (editorType == "PICTURE" || editorType == "PICTURE_PRO") {
                    $state.go("editorPhotoBothWeb", {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, aid: options.aid});
                }else{
                    $state.go("editorPhotoAlbum", {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, aid: options.aid});
                }
            }
            else {
                startMobileMade(options.pid, options.vid, options.xmlId);
                if (editorType == null || editorType == "" || editorType == "BOOK_SENIOR") {
                    $state.go('product-chooseNew-ec', {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, aid: options.aid});
                } else if (editorType == "BOOK_JUNIOR") {
                    $state.go('product-choose-ec', {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, aid: options.aid});
                }  else if (editorType == "PICTURE" || editorType == "PICTURE_PRO") {
                    $state.go('editorPhotoBoth', {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, aid: options.aid});
                } else if (editorType == "PHOTO_EDITOR") {
                    $state.go('idPhoto', {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, aid: options.aid});
                } else if (editorType == "POSTER" || editorType == "DIY_EDITOR") {
                    $state.go('product-chooseThree-ec', {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, aid: options.aid});
                } else if (editorType == "FORM_EDITOR") {
                    $state.go('editorForm', {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, aid: options.aid});
                } else if (editorType == "NEW_EDITOR"||editorType == "CALENDAR_EDITOR") {
                    $state.go('editorPhotoAlbumM', {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, aid: options.aid});
                } else {
                    $state.go('product-chooseNew-ec', {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, aid: options.aid});
                }
            }
        }

        /*
        *   导航到模版选择或者编辑器
        *   storeId - 商户id - 必需
        *   options:
        *       editorType - 编辑器类型 - 必需
        *       pid - 产品ID - 必需
        *       vid - skuID - 必需
        *       xmlId - 模版ID - 必需
        *       aid - 作品ID - 可选
        *       pictureType - 冲印单双面 - 可选
        *       mobile - 是否移动端, true/false
        *       selectState - 选择器 - 必需
        */
        function navigateSelect(storeId, options) {
            if (!storeId || !options || !options.pid || !options.vid) {
                console.log("can't navigate to editor!!!!, storeId=" + storeId + ", options=" + options);
                return;
            }
            var vm = this;

            var selectState = options.selectState || 'commoditySelect';
            Product.get({id: options.pid}, function(res) {
                var product = res.data;

                var xmlCount = 0;
                var xml = null;
                if (product.itemValues != null && product.itemValues.length > 0) {
                    for (var i = 0; i < product.itemValues.length; i++) {
                        var itemValue = product.itemValues[i];
                        if (itemValue.id == options.vid) {
                            if (itemValue.productXmlLines && itemValue.productXmlLines.length) {
                                for (var j = 0; j < itemValue.productXmlLines.length; j++) {
                                    var line = itemValue.productXmlLines[j];
                                    if (!line.cancelled) {
                                        xmlCount++;
                                        xml = line;
                                    }
                                }
                                if (xmlCount == 1 && xml) {
                                    var editorType = itemValue.productEditorType || product.productEditorType || options.editorType;
                                    vm.navigateTo(storeId, {editorType: editorType, pid: options.pid, vid: options.vid, xmlId: xml.id, aid: options.aid, pictureType: product.pictureType, mobile: options.mobile});
                                    return;
                                }
                            }
                            break;
                        }
                    }
                }

                $state.go(selectState, {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: null, aid: options.aid});
            });
        }
    }
})();
