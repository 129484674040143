(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('Qinius', Qinius)
        .factory('WxJS', WxJS)
        .factory('ServiceWxJS', ServiceWxJS)
        .factory('ItemValue', ItemValue)
        .factory('Convert', Convert)
        .factory('StoreWechatSetting', StoreWechatSetting)
        .factory('Evaluate', Evaluate)
        .factory('Store', Store)
        .factory('StoreConfig', StoreConfig)
        .factory("SubstitutionSetting", SubstitutionSetting)
        .factory('ProductXml', ProductXml)
        .factory('ThemeClassify', ThemeClassify)
        .factory('ProductTitle', ProductTitle)
        .factory('ProductTitleConfig', ProductTitleConfig)
        .factory('ProductTitleStyle', ProductTitleStyle)
        .factory('ProductPurchase', ProductPurchase)
        .factory('Document', Document);

    Qinius.$inject = ['$resource'];
    WxJS.$inject = ['$resource'];
    ServiceWxJS.$inject = ['$resource'];
    ItemValue.$inject = ['$resource'];
    Convert.$inject = ['$resource'];
    StoreWechatSetting.$inject = ['$resource'];
    Evaluate.$inject = ['$resource', 'DateUtils'];
    Store.$inject = ['$resource'];
    StoreConfig.$inject = ['$resource'];
    SubstitutionSetting.$inject = ['$resource'];
    ProductXml.$inject = ['$resource'];
    ThemeClassify.$inject = ['$resource'];
    ProductTitle.$inject = ['$resource'];
    ProductTitleConfig.$inject = ['$resource'];
    ProductTitleStyle.$inject = ['$resource'];
    ProductPurchase.$inject = ['$resource'];
    Document.$inject = ['$resource'];

    function SubstitutionSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/substitution-settings/:path';
        return $resource(resourceUrl, {}, {
            "getByStore":{
                method:"GET",
                params:{
                    path:"getByStore"
                }
            }
        });
    }

    function ProductXml ($resource) {
        var resourceUrl =  'manager/' + 'api/product-xmls/:path/:id';
        return $resource(resourceUrl, {}, {
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "get":{
                method:"GET"
            }
        });
    }

    function ItemValue($resource) {
        var resourceUrl = 'manager/' + 'api/item-values/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                params:{
                    path:"client"
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function Convert ($resource) {
        var resourceUrl =  'fileserves/' + 'api/convert/:path/:storeId/:id';

        return $resource(resourceUrl, {}, {
            "thumbnailator": {
                method:"GET",
                params:{
                    path:"thumbnailator"
                }
            },
            "imageOptimize":{
                method:"GET",
                params:{
                    path:"imageOptimize"
                }
            },
            'xmlToPicLayoutDocument': {
                method: 'GET',
                params: {
                    path: 'xmlToPicLayoutDocument'
                }
            },
            "uploadDocument":{
                method:"POST",
                params:{
                    path:"uploadDocument"
                }
            },
            "articleUploadDocument":{
                method:"POST",
                params:{
                    path:"articleUploadDocument"
                }
            },
            "xmlToDocument2":{
                method:"GET",
                params:{
                    path:"xmlToDocument2"
                }
            },
            "xmlToDocument":{
                method:"GET",
                params:{
                    path:"xmlToDocument"
                }
            },
            "imgFilter":{
                method: 'GET',
                params: {
                    path: "filter"
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "articles":{
                method:"GET",
                params:{
                    path:"articles"
                }
            },
            "saveArticle":{
                method:"POST",
                params:{
                    path:"saveArticle"
                }
            }
        });
    }

    function Evaluate($resource, DateUtils) {
        var resourceUrl = 'service/' + 'api/evaluates/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        // data.startTime = DateUtils.convertLocalDateFromServer(data.startTime);
                        // data.endTime = DateUtils.convertLocalDateFromServer(data.endTime);
                    }
                    return data;
                }
            },
            "addEvaluates":{
                method:"GET",
                params:{
                    path:"add"
                }
            }
        });
    }

    function Store($resource) {
        var resourceUrl = 'manager/' + 'api/stores/:path/:id';

        return $resource(resourceUrl, {}, {
            "get": {
                method: "GET"
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                }
            },
            "current": {
                method: "GET",
                params: {
                    path: "current"
                }
            }
        });
    }

    function StoreConfig($resource) {
        var resourceUrl = 'manager/' + 'api/store-configs/:path/:id';

        return $resource(resourceUrl, {}, {
            'getCurrent': {
                method: 'GET',
                params: {
                    path: "current"
                },
            },
            'getAllByTypes': {
                method: 'POST',
                params: {
                    path: "getAllByTypes"
                }
            },
            "byStoreIdAndType": {
                method: 'GET',
                params: {
                    path: "byStoreIdAndType"
                },
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                }
            },
            'update': {
                method: 'PUT',
            }
        });
    }

    function Qinius($resource) {
        var service = $resource('manager/api/qiniu/:path/:id', {}, {
            'get': {
                method: 'GET',
                params: {
                    path: 'upload-token'
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'tokenWithPolicy': {
                method: 'GET',
                params: {
                    path: 'tokenWithPolicy'
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getDomain':{
                method: 'GET',
                params: {
                    path: 'bucket-domains'
                }
            }
        });
        return service;
    }

    function ServiceWxJS ($resource) {
        var resourceUrl =  'service/' + 'api/wechat/:path';
        return $resource(resourceUrl, {}, {
            'payConfig': {
                method: 'GET',
                params:{
                    path:"payConfig"
                }
            }
        });
    }

    function WxJS ($resource) {
        var resourceUrl =  'manager/' + 'api/wechat/:path';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                params:{
                    path:"webConfig"
                }
            },
            'get1': {
                method: 'GET',
                params: {
                    path: "small_webconfig"
                }
            },
            'get2': {
                method: 'GET',
                params:{
                    path:"webconfig2"
                }
            },
            'getTicket': {
                method: 'GET',
                params: {
                    path: "js-ticket"
                }
            },
            'payConfig': {
                method: 'GET',
                params:{
                    path:"payConfig"
                }
            },
            'wechartQRPay': {
                method: 'GET',
                params: {
                    path: 'wechartQRPay'
                }
            }
        });
    }

    function StoreWechatSetting($resource){
        var resourceUrl = "manager/api/store-wechat-settings/:path/:id";
        return $resource(resourceUrl,{},{
            "get":{
                method:"GET",
            },
            "getByStoreId":{
                method:"GET",
                params:{
                    path:"getByStoreId"
                }
            },
            "getPublicWechatSetting":{
                method:"GET",
                params:{
                    path:"getPublicWechatSetting"
                }
            },
            "getAuthorizerInfo": {
                method: "GET",
                params: {
                    path: "getAuthorizerInfo"
                }
            }

        });
    }

    function ThemeClassify($resource){
        var resourceUrl = "manager/api/theme-classify/:path/:id";

        return $resource(resourceUrl, {}, {
            "getAll": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                }
            },
            "getAllByCondition3":{
                method:"POST",
                params:{
                    path:"getAllByCondition3"
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
            "byCondition4":{
                method:"GET",
                params:{
                    path:"byCondition4"
                }
            },
            "getAllByCondition4":{
                method:"GET",
                params:{
                    path:"getAllByCondition4"
                }
            },
            "del":{
                method:"DELETE"
            },
            "changeStatue":{
                method:"GET",
                params:{
                    path:"changeStatue"
                }
            },
            "get":{
                method:"GET"
            },
            "save":{
                method:"PUT"
            }
        });
    }

    function ProductTitle ($resource) {
        var resourceUrl =  'manager/' + 'api/product-titles/:path/:id';
        return $resource(resourceUrl, {}, {
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            }
        });
    }

    function ProductTitleConfig ($resource) {
        var resourceUrl =  'manager/' + 'api/product-title-configs/:path/:id';

        return $resource(resourceUrl, {}, {
            'storeId': {
                method: 'GET',
                params: {
                    path: 'storeId'
                },
            },
            'update': { method:'PUT' }
        });
    }

    function ProductTitleStyle ($resource) {
        var resourceUrl =  'manager/' + 'api/product-title-styles/:path/:id';
        return $resource(resourceUrl, {}, {
            "countHits":{
                method:"GET",
                params:{
                    path:"countHits"
                }
            },
            "getProducts":{
                method: 'POST',
                params: {
                    path: 'getProducts'
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            }
        });
    }

    function ProductPurchase ($resource){
        var resourceUrl ="manager/api/product-purchases/:path/:id";
        return $resource(resourceUrl,{},{
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        })
    }

    function Document ($resource){
        var resourceUrl =  'fileserves/' + 'api/convert/document/:path/:storeId/:id';
        return $resource(resourceUrl,{},{
            "importData":{
                method:"POST",
                params:{
                    path:"importData"
                }
            }
        })
    }
})();
