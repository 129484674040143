(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('GalleryUploadPageController', GalleryUploadPageController);

    GalleryUploadPageController.$inject = ['$scope', '$q', 'Gallery', '$stateParams', '$localStorage', 'StoreWechatSetting', '$http','ModulesService',
        'PlatformWechatSetting', 'AuthServerProvider', 'Store', 'CommonService', 'Qinius', 'Theme', 'SweetAlert','WxJS','$rootScope'];

    function GalleryUploadPageController($scope, $q, Gallery, $stateParams, $localStorage, StoreWechatSetting, $http, ModulesService,
                                         PlatformWechatSetting, AuthServerProvider, Store, CommonService, Qinius, Theme, SweetAlert,WxJS, $rootScope) {
        var vm = this;
        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        vm.uploadPhotoItem = [];
        $scope.agentIos = false;
        console.log(window.screen.width)
        vm.storeId = $stateParams.storeId;
        vm.aid = $stateParams.aid;
        vm.fid = $stateParams.fid;
        $scope.photos = [];
        if (vm.storeId == null) {
            return;
        }
        ;
        if (vm.fid == 0) {
            vm.fid = null;
        }

        CommonService.setDocumentTitle('照片上传');
        //风格切换  引入不同的css文件
        Theme.judge();
        vm.closePage = closePage;

        function closePage() {
            console.log(123);
        }

        // 成功多少张
        var indd = 0;
        var errorImageArr = [];
        var repeatCount = 0;

        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            //或者return false;
            e.preventDefault();
        };

        $scope.deletePic = function (id, index) {
            console.log($scope.photos);
            Gallery.delete({id: id}, function () {
                $scope.photos.splice(index, 1);
            }, function () {

            });
        }


        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function () {
            vm.domain = 'https://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;

            /**
             * wechat api
             */
            if (wx) {
                var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
                WxJS.get({url: url}, function (res) {
                    if(res.status==200) {
                        $scope.agentIos = browserVersions();
                        var config = res.data;
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.appId, // 必填，公众号的唯一标识
                            timestamp: config.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.nonceStr, // 必填，生成签名的随机串
                            signature: config.signature,// 必填，签名，见附录1
                            jsApiList: ['chooseImage', 'uploadImage', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function () {
                            console.log('wechat api ready!');

                            wx.hideMenuItems({
                                menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                            });
                        });
                        wx.error(function (res) {
                            console.log(res)
                        });
                    }
                }, function () {
                    console.log("wx config error");
                });
            }
            //获取自定义颜色
            ModulesService.getToneSettings(vm.storeId,function (res) {
                vm.toneSettings = res;
            });
        });


        //非调用微信公众号上传图片 无9张限制 可多张上传 上传到七牛  并且可以返回路径在页面显示
        // window.uploadImageMutiple = function ($event) {
        //     var files = [];
        //     for (var i = 0; i < $event.target.files.length; i++) {
        //         files.push($event.target.files[i])
        //     }
        //     vm.higherSize = [];
        //     angular.forEach(files, function (item, index) {
        //         if (item.size / 1024 > 20480) {
        //             vm.higherSize.push(index + 1);
        //         }
        //     });

        //     //系统检测到{{}}张大于20M的图片，已自动过滤\n请压缩后再尝试上传！
        //     if (vm.higherSize.length > 0) {
        //         $scope.bigSizeTip = true;
        //         $scope.higherSizeLen = vm.higherSize.length;
        //         $scope.$digest();
        //     } else {
        //         $(".save-pages").removeClass('hide1');
        //         filterUpload(files);
        //     }

        //     $scope.filterBigSize = function () {
        //         $scope.bigSizeTip = false;
        //         console.log(files)
        //         files = files.filter(function (item, index) {
        //             return item.size / 1024 < 20480;
        //         });
        //         if (files.length == 0) {
        //             updatePic();
        //         } else {
        //             filterUpload(files);
        //         }
        //     }

        //     function filterUpload(files) {
        //         $(".save-pages").addClass('hide1');

        //         //七牛异步 本地同步 9张图 使用时间 7s+      9s
        //         // halfAsyncUpload(files);
        //         //完全异步 9张图  使用时间  6s+    8s
        //         asyncUpload(files);

        //         //完全同步 9张图 使用时间 14s+    12s
        //         // fileGet(0, files);
        //     }


        //     function asyncUpload(files) {
        //         indd = 0;
        //         errorImageArr = [];
        //         setTimeout(function () {
        //             swal({
        //                 title: "开始上传中(1/" + files.length + ")....",
        //                 text: "大图片上传相对缓慢\n请耐心等候...",
        //                 showConfirmButton: false,
        //                 type: "info"
        //             });
        //         }, 500);
        //         var len = files.length;
        //         for (var i = 0; i < 5 && i < files.length; i++) {
        //             var file = files[i];
        //             qiniuUpload(file, files, i);
        //         }
        //     }

        //     function qiniuUpload(file, files, index_) {

        //         var formData = new FormData();
        //         formData.append('file', file);
        //         formData.append('unique_names', false);
        //         formData.append('token', vm.token);
        //         $.ajax({
        //             cache: true,
        //             type: "POST",
        //             url: 'https://upload.qiniup.com/',
        //             data: formData,
        //             async: true,
        //             contentType: false,
        //             processData: false,
        //             success: function (data) {
        //                 swal({
        //                     title: "开始上传中(" + (indd + 1 + errorImageArr.length) + "/" + files.length + ")....",
        //                     text: "大图片上传相对缓慢\n请耐心等候...",
        //                     showConfirmButton: false,
        //                     type: "info"
        //                 });
        //                 console.log(vm.domain + data.key + "?imageInfo");
        //                 $.ajax({
        //                     type: "GET",
        //                     url: vm.domain + data.key + "?imageInfo",
        //                     success: function (va) {
        //                         if (va.orientation == "Left-bottom" || va.orientation == "Right-top") {
        //                             file.width = va.height;
        //                             file.height = va.width;
        //                         } else {
        //                             file.width = va.width;
        //                             file.height = va.height;
        //                         }
        //                         console.log("获取成功");
        //                         console.log(file);
        //                         serviceUpload(data, file, files.length);
        //                     },
        //                     error: function (request) {
        //                         console.log("获取失败");
        //                         serviceUpload(data, file, files.length);
        //                     }
        //                 });
        //                 if (files.length > index_ + 1 && index_ >= 4) {
        //                     var f = files[index_ + 1];
        //                     qiniuUpload(f, files, index_ + 1);
        //                 }
        //             },
        //             error: function (request) {
        //                 errorImageArr.push(file);
        //                 swal({
        //                     title: "图片上传中(" + (indd + 1) + "/" + files.length + ")....",
        //                     text: "大图片上传相对缓慢\n请耐心等候...",
        //                     showConfirmButton: false,
        //                     type: "info"
        //                 });
        //                 //最后一个失败了的话
        //                 if (files.length == (indd + errorImageArr.length)) {
        //                     SweetAlert.swal({
        //                         title: '上传完成',
        //                         text: "存在" + errorImageArr.length + "张照片中断\n请重新选择上传",
        //                         imageUrl: Theme.sweetHintImg(),
        //                         showCancelButton: true,
        //                         confirmButtonText: "确认",
        //                         cancelButtonText: "取消"
        //                     }, function (isConfirm) {
        //                         if (isConfirm) {
        //                             asyncUpload(errorImageArr);
        //                         } else {
        //                             SweetAlert.swal({
        //                                 title: '图片已经取消上传',
        //                                 imageUrl: Theme.sweetHintImg(),
        //                                 text: "",
        //                                 timer: 2000,
        //                                 showConfirmButton: false
        //                             });
        //                         }
        //                     })
        //                 }
        //             }
        //         });
        //     }

        //     function serviceUpload(data, file, len) {
        //         Gallery.upload({
        //             identifiers: data.key,
        //             provider: "QINIU",
        //             fileName: file.name,
        //             imgSize: file.size,
        //             width: file.width,
        //             height: file.height,
        //             userId: vm.userId,
        //             folderId: vm.fid,
        //             articleId: vm.aid
        //         }, function (resp) {
        //             if (resp.status == 200) {
        //                 if (resp.data[0].state == '1') {
        //                     repeatCount += 1;
        //                 }
        //                 $scope.photos = $scope.photos.concat(resp.data);
        //                 indd++;
        //                 if (len == (indd + errorImageArr.length) && errorImageArr.length == 0) {
        //                     setTimeout(function () {
        //                         swal({
        //                             title: "上传完成",
        //                             text: repeatCount > 0 ? "存在" + (repeatCount + "张照片重复\n请重新选择上传") : '',
        //                             imageUrl: Theme.sweetSuccedImg(),
        //                             confirmButtonText: "确认"
        //                         });
        //                     }, 1000)
        //                 } else if (len == (indd + errorImageArr.length) && errorImageArr.length != 0) {
        //                     SweetAlert.swal({
        //                         title: '上传完成',
        //                         text: "存在" + errorImageArr.length + "张照片中断\n请重新选择上传",
        //                         imageUrl: Theme.sweetHintImg(),
        //                         showCancelButton: true,
        //                         confirmButtonText: "确认",
        //                         cancelButtonText: "取消"
        //                     }, function (isConfirm) {
        //                         if (isConfirm) {
        //                             asyncUpload(errorImageArr);
        //                         } else {
        //                             SweetAlert.swal({
        //                                 title: '图片已经取消上传',
        //                                 imageUrl: Theme.sweetHintImg(),
        //                                 text: "",
        //                                 timer: 2000,
        //                                 showConfirmButton: false
        //                             });
        //                         }
        //                     })
        //                 }
        //             }

        //         }, function (error) {
        //             errorImageArr.push(file);
        //             //最后一个失败了的话
        //             if (len == (indd + errorImageArr.length)) {
        //                 SweetAlert.swal({
        //                     title: '上传完成',
        //                     text: "存在" + errorImageArr.length + "张照片中断\n请重新选择上传",
        //                     imageUrl: Theme.sweetHintImg(),
        //                     showCancelButton: true,
        //                     confirmButtonText: "确认",
        //                     cancelButtonText: "取消"
        //                 }, function (isConfirm) {
        //                     if (isConfirm) {
        //                         asyncUpload(errorImageArr);
        //                     } else {
        //                         SweetAlert.swal({
        //                             title: '图片已经取消上传',
        //                             imageUrl: Theme.sweetHintImg(),
        //                             text: "",
        //                             timer: 2000,
        //                             showConfirmButton: false
        //                         });
        //                     }
        //                 })
        //             }
        //         });
        //     }
        // };

        // $scope.chooseWx = function () {
        //     console.log('android upload');
        //     var currentIndex = 0;
        //     wx.chooseImage({
        //         count: 9, // 默认9
        //         sizeType: ['original'], // 可以指定是原图还是压缩图，默认二者都有
        //         sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
        //         success: function (res) {
        //             var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
        //             $(".save-pages").removeClass('hide1');
        //             //异步上传     9张  18s+
        //             asyncGetImg(localIds);

        //             function asyncGetImg(datas) {
        //                 $(".save-pages").addClass('hide1');
        //                 setTimeout(function () {
        //                     swal({
        //                         title: "开始上传中(1/" + datas.length + ")....",
        //                         text: "大图片上传相对缓慢\n请耐心等候...",
        //                         showConfirmButton: false,
        //                         type: "info"
        //                     });
        //                 }, 500);
        //                 indd = 0;  //成功的张数
        //                 errorImageArr = [];  //错误的张数
        //                 var index_ = 0;   //上传到第几张
        //                 if (datas.length > index_) {
        //                     getImg2(datas, index_);
        //                 }
        //             }

        //             function getImg2(datas, index_) {
        //                 //上传到微信服务器
        //                 wx.uploadImage({
        //                     localId: datas[index_],
        //                     isShowProgressTips: 1,
        //                     success: function (res) {
        //                         index_++;
        //                         if (datas.length > index_) {
        //                             getImg2(datas, index_);
        //                         }
        //                         swal({
        //                             title: "图片上传中(" + (indd + 1) + "/" + datas.length + ")....",
        //                             text: "大图片上传相对缓慢\n请耐心等候...",
        //                             showConfirmButton: false,
        //                             type: "info"
        //                         });
        //                         console.log(res.serverId);
        //                         Gallery.upload({
        //                             identifiers: res.serverId,
        //                             provider: "QINIU",
        //                             fileName: '',
        //                             imgSize: 0,
        //                             folderId: $scope.folderOne ? $scope.folderOne.id : '',
        //                             articleId:vm.aid,
        //                         }, function (resp) {
        //                             if(resp.status==200){
        //                                 $scope.photos = $scope.photos.concat(resp.data);
        //                                 if (resp.data[0].state == '1') {
        //                                     repeatCount += 1;
        //                                 }
        //                                 indd++;
        //                                 if (indd == datas.length) {
        //                                     setTimeout(function () {
        //                                         swal({
        //                                             title: "上传完成",
        //                                             text: repeatCount > 0 ? "存在" + (repeatCount + "张照片重复\n请重新选择上传") : '',
        //                                             imageUrl: Theme.sweetSuccedImg(),
        //                                             confirmButtonText: "确认"
        //                                         });
        //                                     }, 1000);
        //                                 } else if (indd == (datas.length + errorImageArr.length) && errorImageArr.length != 0) {
        //                                     SweetAlert.swal({
        //                                         title: '上传完成',
        //                                         text: "存在" + errorImageArr.length + "张照片中断\n请重新选择上传",
        //                                         imageUrl: Theme.sweetHintImg(),
        //                                         showCancelButton: true,
        //                                         confirmButtonText: "确认",
        //                                         cancelButtonText: "取消"
        //                                     }, function (isConfirm) {
        //                                         if (isConfirm) {
        //                                             asyncGetImg(errorImageArr);
        //                                         } else {
        //                                             SweetAlert.swal({
        //                                                 title: '图片已经取消上传',
        //                                                 imageUrl: Theme.sweetHintImg(),
        //                                                 text: "",
        //                                                 timer: 2000,
        //                                                 showConfirmButton: false
        //                                             });
        //                                         }
        //                                     })

        //                                 }
        //                             }
        //                         }, function (error) {
        //                             errorImageArr.push(file);
        //                             //最后一个失败了的话
        //                             if (datas.length == (indd + errorImageArr.length)) {
        //                                 SweetAlert.swal({
        //                                     title: '上传完成',
        //                                     text: "存在" + errorImageArr.length + "张照片中断\n请重新选择上传",
        //                                     imageUrl: Theme.sweetHintImg(),
        //                                     showCancelButton: true,
        //                                     confirmButtonText: "确认",
        //                                     cancelButtonText: "取消"
        //                                 }, function (isConfirm) {
        //                                     if (isConfirm) {
        //                                         asyncGetImg(errorImageArr);
        //                                     } else {
        //                                         SweetAlert.swal({
        //                                             title: '图片已经取消上传',
        //                                             imageUrl: Theme.sweetHintImg(),
        //                                             text: "",
        //                                             timer: 2000,
        //                                             showConfirmButton: false
        //                                         });
        //                                     }
        //                                 })
        //                             }
        //                         });
        //                     },
        //                     error: function (error) {
        //                         swal({
        //                             title: "图片上传中(" + (indd + 1 + errorImageArr.length) + "/" + datas.length + ")....",
        //                             text: "大图片上传相对缓慢\n请耐心等候...",
        //                             showConfirmButton: false,
        //                             type: "info"
        //                         });
        //                         errorImageArr.push(file);
        //                         //最后一个失败了的话
        //                         if (datas.length == (indd + errorImageArr.length)) {
        //                             SweetAlert.swal({
        //                                 title: '上传完成',
        //                                 text: "存在" + errorImageArr.length + "张照片中断\n请重新选择上传",
        //                                 imageUrl: Theme.sweetHintImg(),
        //                                 showCancelButton: true,
        //                                 confirmButtonText: "确认",
        //                                 cancelButtonText: "取消"
        //                             }, function (isConfirm) {
        //                                 if (isConfirm) {
        //                                     asyncGetImg(errorImageArr);
        //                                 } else {
        //                                     SweetAlert.swal({
        //                                         title: '图片已经取消上传',
        //                                         imageUrl: Theme.sweetHintImg(),
        //                                         text: "",
        //                                         timer: 2000,
        //                                         showConfirmButton: false
        //                                     });
        //                                 }
        //                             })
        //                         }
        //                     }
        //                 });
        //             }
        //         },
        //         error: function () {
        //             console.log("error");
        //         }
        //     });
        // };

        $scope.uploadPhoto = function () {
            if($scope.agentIos){
                $rootScope.$broadcast('clickAndroidUpload',true);
            }else {
                $timeout(function () {
                    $("#uploadImg").trigger("click");
                })
            }
        }

        $scope.$on('uoloadFinished', function(event, args, files) {
            if(args){
                if (files && files.length > 0) {
                    for (var i = files.length-1; i >= 0; i--) {
                        var file = files[i];
                        if (file.state == 'success') {
                            $scope.photos.unshift(file);
                        }
                    }
                }
            }
        });
    }
})();
