(function () {
    'use strict';

    var PageSortControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/page-sort.html',
        controllerAs: 'vm',
        bindings: {
            photoAll: '<',
            agentIos: '<',
            isIPhone6: '<',
            storeId: '<',
            aid: '<',
            onChange: '&',
        },

        controller: ['$scope','ModulesService','FontManagement','$timeout', 'StoreStorages', '$translate', 'MessageService','$rootScope',
            function ($scope,ModulesService,FontManagement,$timeout,StoreStorages, $translate, MessageService, $rootScope) {
            var vm = this;
            vm.$onChanges = function(data) {
                photoAllInfo();
            }
            this.uploadPhotoItem = [];
            $scope.uploadPhoto = function () {
                uploadAble(StoreStorages,vm.storeId, function (res) {
                    if(res.status == 200){
                        if(vm.agentIos){
                            $rootScope.$broadcast('clickAndroidUpload',true);
                        }else{
                            $timeout(function () {
                                $("#page-sort-upload-id").trigger("click");
                            })
                        }
                    }else{
                        if(res.status == 400){
                            MessageService.hint('系统出错');
                        } else {
                            MessageService.hint(res.data||'系统出错');
                        }
                    }
                })
            }
            $scope.book = function (){
                vm.onChange({text: JSON.stringify(vm.photoAll)})
            }

            var sortArray = [];
            vm.draggable = false;
            var sort1 = null;
            function photoAllInfo() {
                sort1 = new Sortable(document.getElementById('photoAllId'), {
                    swap: false,
                    filter: '.filtered',
                    animation: 150,
                    forceFallback: false,
                    delay: 500, // 设置长按时间为0.5秒
                    onEnd: function (/**Event*/evt) {
                        sortArray = [];
                        angular.forEach($('#photoAllId').find(".sortingPageView"), function (item, index) {
                            var page = item.getAttribute("data-page");
                            sortArray.push(JSON.parse(page));
                        });
                        if(sortArray && sortArray.length>0){
                            vm.photoAll = [];
                            $timeout(function () {
                                vm.photoAll = angular.copy(sortArray);
                                sortArray = [];
                            })
                        }
                    },
                    // 元素被选中
                    onChoose: function (/**Event*/evt) {
                        vm.draggable = true;
                        $scope.$apply();
                        sort1.option("delay", 0);
                    },
                });
            }
            $scope.completeSort = function(){
                sort1.option("delay", 500);
                vm.draggable = false;
            }
            $scope.photoReverse = function () {
                if(vm.photoAll && vm.photoAll.length>0){
                    vm.photoAll.reverse();
                }
            }
            $scope.deletePhotoSelect = function (e,index) {
                vm.photoAll.splice(index, 1);
                fn(e);
            }
        }]
    };

    angular.module('editorApp')
    	.component('pageSort', PageSortControl);

})();
