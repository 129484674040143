window.angular.module('common.service', []).factory('CommonService', ['$location', '$http', 'SweetAlert', '$timeout', '$rootScope', '$state', 'Gallery', 'UidService',
    function ($location, $http, SweetAlert, $timeout, $rootScope, $state, Gallery, UidService) {
        /**
         * 封装请求后端的middleware
         * @param params
         * @param success
         * @param failure
         */
        $rootScope.requestCount = 0;
        this.request = function (params, success, failure, hideLoading, errorCodeHandler) {
            if (!hideLoading) {
                $rootScope.requestCount++;
            }
            params.header = {
                Accept: 'application/json, text/plain, */*'
            };
            try {
                $http(params).then(function (resp) {
                    if (!hideLoading) {
                        if ($rootScope.requestCount) {
                            $rootScope.requestCount--;
                        }
                    }
                    if (+resp.data.code) {
                        if (errorCodeHandler) {
                            errorCodeHandler(resp.data)
                        } else {
                            SweetAlert.swal('', resp.data.msg, 'error');
                        }
                    } else {
                        success(resp.data);
                    }
                }, function (error) {
                    if (!hideLoading) {
                        if ($rootScope.requestCount) {
                            $rootScope.requestCount--;
                        }
                    }
                    if (failure) {
                        failure(error);
                    }
                    //else {
                    //    SweetAlert.swal('', `request failed ${error.status} ${error.statusText}`, 'error');
                    //}
                })
            } catch (e) {
                console.log(e);
            }

        };


        /**
         *
         * Hack 微信中title修改不了的问题
         */
        this.setDocumentTitle = function (title) {
            document.title = title;
            if (/ip(hone|od|ad)/i.test(navigator.userAgent)) {
                var i = document.createElement('iframe');
                // i.src = '/favicon.ico';
                i.style.display = 'none';
                i.onload = function () {
                    setTimeout(function () {
                        i.remove();
                    }, 9)
                };
                document.body.appendChild(i);
            }
        };

        this.alert = function (title, text, callback, time, type) {
            SweetAlert.swal({
                title: title,
                text: text,
                imageUrl: "content/images/icons/pop_ic_msg_succeed.svg",
                timer: 2000,
                showConfirmButton: false
            });
            // SweetAlert.swal('', text, type ? type : 'success');
            $timeout(function () {
                if (callback) {
                    callback();
                }
            }, time ? time : 2000);
        };

        /**
         * 深对比对象是否相等
         */
        this.checkObjectEqual = function (origin, current) {
            var unEqual = false;
            Array.prototype.unique = function () {
                var a = this.concat();
                for (var i = 0; i < a.length; ++i) {
                    for (var j = i + 1; j < a.length; ++j) {
                        if (a[i] === a[j])
                            a.splice(j--, 1);
                    }
                }
                return a;
            };

            var checkObject = function (ori, cur) {
                if (unEqual) return;
                if (!ori || !cur) return;
                const keys1 = Object.keys(ori);
                const keys2 = Object.keys(cur);
                const keys = keys1.concat(keys2).unique();
                //console.log(keys);
                angular.forEach(keys, function (key) {
                    if (unEqual) return;
                    if (key == '$$hashKey') return;
                    if (angular.isObject(ori[key]) && angular.isObject(cur[key])) {
                        checkObject(ori[key], cur[key]);
                    } else {
                        switch (typeof ori[key]) {
                            case 'string':
                                if (ori[key].length) {
                                    unEqual = (ori[key] != cur[key]);
                                } else {
                                    unEqual = !!cur[key] || (angular.isObject(cur[key]) && Object.keys(cur[key].length));
                                }
                                break;
                            case 'number':
                                if (ori[key]) {
                                    unEqual = (ori[key] != cur[key]);
                                } else {
                                    unEqual = !!cur[key] && (cur[key] != '0');
                                }
                                break;
                            case 'object':
                                if (ori[key] == null) {
                                    unEqual = !!cur[key] || (angular.isObject(cur[key]) && Object.keys(cur[key].length));
                                } else {
                                    if (Object.keys(ori[key]).length) {
                                        unEqual = true;
                                    } else {
                                        unEqual = !!cur[key];
                                    }
                                }
                                break;
                            case 'undefined':
                                unEqual = !!cur[key] || (angular.isObject(cur[key]) && Object.keys(cur[key].length));
                                break;
                            default:
                                unEqual = (!ori[key] == !!cur[key]);
                                break;
                        }
                    }

                });
            };

            checkObject(origin, current);
            console.log(">>>>>>>>>finall--" + unEqual);
            return unEqual;

        };


        /**
         * 切换路由时检查当前是否有表单为提交
         */
        this.checkDirtyPage = function ($scope, checkFormDirty, callback) {
            $scope.$on('$stateChangeStart', function (e, toState, toParams, fromState, fromParams) {
                $('.sweet-alert').removeClass('hide-button');

                if (checkFormDirty() && !$scope.cancelCheck) {
                    e.preventDefault();
                    SweetAlert.swal({
                            title: '',
                            text: "当前页面有改动尚未保存，小主确定要离开吗？",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonText: "狠心离开",
                            cancelButtonText: "取消",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function () {
                            $scope.cancelCheck = true;
                            $state.go(toState.name, toParams);
                        });
                } else {
                    $('.sweet-alert .confirm').trigger("click");
                    $rootScope.leaveEditPage = true;
                }

            });
        };

        this.uploadFile = function (file, callback, shouldReadIMGInfo) {
            // var ext = '-' + ipCookie('userid') + '-' + parseInt(Math.random()*100000) + '.png';
            //
            // if (!this.instance) {
            //     var config = {
            //         bucket: window.diyUpyunConfig.bucket,
            //         expiration: parseInt((new Date().getTime() + 3600000) / 1000),
            //         form_api_secret: window.diyUpyunConfig.form_api_secret
            //     };
            //
            //     var instance = new Sand(config);
            //
            //     this.instance = instance;
            // }
            //
            // this.instance.upload('/upload/template/diy/' + parseInt((new Date().getTime() + 3600000) / 1000) + ext, file, function(resp) {
            //     if (!resp) {
            //         SweetAlert.swal('', "图片上传失败！没有返回值", 'error');
            //         return;
            //     }
            //     var path = imagePath+resp.path;
            //     if (shouldReadIMGInfo) {
            //         $http({url: path+"!imageInfo", method: 'get'}).then(function(resp) {
            //             if (!resp.data) {
            //                 SweetAlert.swal('', '获取图片信息失败！Sad...', 'error');
            //                 return;
            //             }
            //             var response = {
            //                 url: '' + path,
            //                 width: resp.data.width,
            //                 height: resp.data.height
            //             };
            //             if (resp.data.EXIF) {
            //                 var orientation = resp.data.EXIF.Orientation;
            //                 if (orientation == '6' || orientation == '8') {
            //                     response.width = resp.data.height;
            //                     response.height = resp.data.width;
            //                 }
            //             }
            //             callback(response);
            //         });
            //     } else {
            //         callback(path);
            //     }
            //
            // }, function(err) {
            //     console.error(err);
            //     $('.sweet-alert').removeClass('hide-button');
            //     SweetAlert.swal('', "图片上传失败，请重新上传", 'info');
            // });
        };

        this.UpdateQiniuService = function (files, i, len, domain, token, folderId, aid, callback , errorcallback) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(files[i]);//创建Image的对象的url
            img.crossOrigin = "Anonymous";

            var formData = new FormData();
            formData.append('file', files[i]);
            formData.append('key', "file-" + UidService.get());
            formData.append('token', token);

            var reader = new FileReader();
            reader.readAsArrayBuffer(files[i])
            reader.onloadend = function (e) {
                formData.append('crc32', CRC32.buf(new Uint8Array(this.result)));
                return $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: true,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        return $.ajax({
                            type: "GET",
                            url: domain + data.key + "?imageInfo",
                            success: function (va) {
                                // console.log(va)
                                if (va.orientation == "Left-bottom" || va.orientation == "Right-top") {
                                    files[i].width = va.height;
                                    files[i].height = va.width;
                                } else {
                                    files[i].width = va.width;
                                    files[i].height = va.height;
                                }
                                Gallery.upload({
                                    identifiers: data.key,
                                    provider: "QINIU",
                                    fileName: files[i].name,
                                    imgSize: files[i].size,
                                    width: files[i].width,
                                    height: files[i].height,
                                    folderId: folderId ? folderId : '',
                                    articleId: aid
                                }, function (resp) {
                                    callback.call(this, resp);
                                },function(){
                                    errorcallback.call(this, files[i]); //接口保存链接失败
                                });

                            },
                            error: function (request) {
                                errorcallback.call(this, files[i]); //接口保存链接失败
                                $(".pic-loading").addClass('hide1');
                                $(".leftPanel-bg-show").removeClass('overflow-hidden');
                            }
                        });
                    },
                    error: function (request) {
                        errorcallback(files[i]);
                        $(".pic-loading").addClass('hide1');
                        $(".leftPanel-bg-show").removeClass('overflow-hidden');
                    }
                });
            }
        }

        return this;

    }]);
