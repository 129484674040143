(function () {
    'use strict';

    var PageMobileEditControl = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/page-mobile-edit.html',
        controllerAs: 'vm',
        bindings: {
            editorType: '<',
            pageData: '=',
            pageIndex: '=',
            levelIndex: '=',
            levelType: '=',
            that: '=',
            imgSize: '<',
            imgDpi: '<',
            isSmallPic: '=', //拖动图片图片框显示‘拖拽到此处替换图片’
            transparency: '=', //文字透明度
            isImageboxLock: '=', //照片框的解锁按钮
            notImageboxTip: '=',
        },

        controller: ['$scope','FontManagement','$timeout', function ($scope,FontManagement,$timeout) {
            var vm = this;
            //像素不足提醒
            var throttledResize = angular.throttle(function (item) {
                return pxInsufficient(item, vm.imgSize, vm.imgDpi)
            }, 1000);

            $scope.pxInsufficient = function (item) {
                return pxInsufficient(item, vm.imgSize, vm.imgDpi);
            }
            /***********************  图片框  ****************************/
            //边框
            $scope.imageboxW = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.width
                        // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                    }
                } else {
                    return imagebox.geometry.width
                }
            }
            $scope.imageboxH = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.height
                        // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                    }
                } else {
                    return imagebox.geometry.height
                }
            }
            $scope.imageboxL = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.x
                        // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                    }
                } else {
                    return imagebox.geometry.x
                }
            }
            $scope.imageboxT = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.y
                        // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                    }
                } else {
                    return imagebox.geometry.y
                }
            }
            $scope.imageboxWEdge = function (imagebox, edge,ratio) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.border.lineWidth)
                    }
                    if (imagebox.border.type == 'Pic') {
                        if (edge == 'left') {
                            return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100 * ratio
                        }
                        if (edge == 'top') {
                            return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100 * ratio
                        }
                        if (edge == 'right') {
                            return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100 * ratio
                        }
                        if (edge == 'bottom') {
                            return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100 * ratio
                        }
                    }
                } else {
                    return 0
                }
            }
            $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    var _ratio = 1;
                    if(ratio && ratio1){
                        if(imagebox.geometry.width > imagebox.geometry.height){
                            _ratio = ratio1
                        }else{
                            _ratio = ratio
                        }
                    }
                    if(ratio && !ratio1){
                        _ratio = ratio
                    }
                    if (imagebox.border.shape == 'Ellipse') {
                        return '50%'
                    }
                    if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                        var _radius = imagebox.border.radius[radius];
                        return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                    }else{
                        var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                        radiusW = radiusW + imagebox.border.lineWidth;
                        var _r = radiusW * imagebox.border.radius[radius] / 100;
                        return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                    }
                } else {
                    return 0
                }
            }
            $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    var _ratio = 1;
                    if(ratio && ratio1){
                        if(imagebox.geometry.width > imagebox.geometry.height){
                            _ratio = ratio1
                        }else{
                            _ratio = ratio
                        }
                    }
                    if(ratio && !ratio1){
                        _ratio = ratio
                    }
                    if (imagebox.border.shape == 'Ellipse') {
                        return '50%'
                    }
                    if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                        var _radius = imagebox.border.radius[radius];
                        return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                    }else{
                        var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                        radiusW = radiusW + imagebox.border.lineWidth;
                        var _r = radiusW * imagebox.border.radius[radius] / 100;
                        return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                    }
                } else {
                    return 0
                }
            }
            $scope.dropShadow = function(imagebox,ratio){
                if (imagebox.shadow == null) {
                    imagebox.shadow={
                        color:'#777777',
                        x:0,
                        y:0,
                        blur:0
                    }
                }
                var width = $scope.imageboxW(imagebox) * ratio;
                var height =$scope.imageboxH(imagebox) * ratio;
                return dropShadow(imagebox,width,height)
            }

            vm.imageboxTip = false;
            $(".page-mobile-view").on("touchstart",".imageboxview", function (e) {
                fn(e);
                vm.imageboxTip = true;
                if(vm.that.levelDome){vm.that.levelDome()};
                var targ = e.target ? e.target : e.srcElement;
                var imgIndex = targ.dataset.index || vm.levelIndex;
                vm.levelIndex = parseInt(imgIndex);
                vm.levelType = 'imagebox';
                imageboxTouchStart(e, vm.levelIndex, vm.pageData[vm.pageIndex].custom.ratio1, vm.pageData[vm.pageIndex].levels.level, function (imgIndex) {
                    vm.levelIndex = parseInt(imgIndex);
                    vm.levelType = 'imagebox';
                }, function (imgIndex) {
                    vm.that.cropImagebox = vm.pageData[vm.pageIndex].levels.level[imgIndex].imagebox;
                }, function () {
                    if(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.lock){
                        vm.isImageboxTrim = true;
                    }
                })
                $scope.$apply();
            })
            $(".page-mobile-view").on("touchmove",".imageboxview", function (e) {
                fn(e);
                var targ = e.target ? e.target : e.srcElement;
                var imgIndex = targ.dataset.index || vm.levelIndex;
                vm.levelIndex = parseInt(imgIndex);
                vm.levelType = 'imagebox';
                imageboxTouchmove(e, vm.levelIndex, vm.pageData[vm.pageIndex].custom.ratio1, vm.pageData[vm.pageIndex].levels.level, vm.pageData[vm.pageIndex], function (imgIndex) {
                    $scope.$apply();
                })
            })
            $(".page-mobile-view").on("touchend",".imageboxview", function (e) {
                var targ = e.target ? e.target : e.srcElement;
                var imgIndex = targ.dataset.index || vm.levelIndex;
                vm.levelIndex = parseInt(imgIndex);
                vm.levelType = 'imagebox';
                vm.imageboxTip = false;
                vm.isImageboxTrim = false;
                imageboxTouchend()
                var currentPage = vm.pageData[vm.pageIndex].levels.level;
                var sticker = currentPage[vm.levelIndex].imagebox;
                if(!sticker){
                    return
                }
                if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
                if (sticker.image && sticker.image.resource && sticker.image.resource.identifier) {
                    vm.that.isImageboxSelectItem = true;
                    vm.that.mapDepot = false;
                } else {
                    if(vm.that.fullMapSelection){
                        vm.that.fullMapSelection();
                    }
                }
                fn(e)
                $scope.$apply();
            })
            $scope.imageboxLock = function(e, item, imagebox){
                imagebox.lock = item;
                fn(e)
            }
            //缩放图片框
            window.imageboxScaleStart = function (e) {
                imageboxZoomStart(e, vm.pageData[vm.pageIndex].levels.level, 'pitch', vm.pageData[vm.pageIndex].custom.ratio1,function (textIndex) {
                    vm.levelIndex = parseInt(textIndex);
                    vm.levelType = 'imagebox';
                })
                fn(e);
            };
            window.imageboxScaleOn = function (e) {
                fn(e);
                var currentPage = vm.pageData[vm.pageIndex].levels.level;
                var imagebox = currentPage[vm.levelIndex][vm.levelType];
                imageboxZoomMove(e, imagebox, vm.pageData[vm.pageIndex].custom.ratio1,vm.pageData[vm.pageIndex].trimbox);
                $scope.$apply();
                e.stopPropagation();
                e.preventDefault();
                return false;
            };
            window.imageboxScaleEnd = function (e) {
                imageboxZoomEnd(function () {
                    if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
                })
            };

            window.imageboxStartStart = function (e) {
                var targ = e.currentTarget ? e.currentTarget : e.srcElement;
                var site = targ.dataset.site;
                var imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
                imageboxStartStartCu(e, imagebox);
                fn(e);
            };
            window.imageboxStartOn = function (e) {
                var targ = e.currentTarget ? e.currentTarget : e.srcElement;
                var site = targ.dataset.site;
                var imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
                imageboxStartOnCu(e, imagebox, site, vm.pageData[vm.pageIndex].custom.ratio1, function () {
                    $scope.$apply();
                });
                fn(e);
            };
            window.imageboxStartEnd = function (e) {
                imageboxStartEndCu(e, function () {
                    var currentPage = vm.pageData[vm.pageIndex].levels.level;
                    var imagebox = currentPage[vm.levelIndex][vm.levelType];
                    getBackgroundSource3(imagebox, imagebox.geometry.width, imagebox.geometry.height, imagebox.image, imagebox.bokehtype, $scope, function () {
                        if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
                    });
                    $scope.$apply();
                });
                fn(e);
            };
            //删除图片框
            $scope.imageboxDelete = function(e, index){
                fn(e)
                vm.pageData[vm.pageIndex].levels.level.splice(index, 1);
                vm.levelIndex = null;
                vm.levelType = null;
                if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
                vm.that.makeBody();
            };
            /***********************  图片框end  ****************************/
            /***********************  素材  ****************************/
            //移动素材
            $scope.imageartSelect = function(e,index,imageart){
                fn(e)
                if(vm.editorType && (vm.editorType == 'PICTURE' || vm.editorType == 'FORM_EDITOR' || vm.editorType == 'PHOTO_EDITOR')){
                    return
                }
                if(!imageart.useradded){
                    return
                }
                vm.levelIndex = index;
                vm.levelType = 'imageart';
            }
            $(".page-mobile-view").on("touchstart",".imageartview img", function (e) {
                if(vm.editorType && (vm.editorType == 'PICTURE' || vm.editorType == 'FORM_EDITOR' || vm.editorType == 'PHOTO_EDITOR')){
                    return
                }
                if(vm.that.levelDome){vm.that.levelDome()};
                imageartTouchstartStart(e, vm.levelIndex, vm.pageData[vm.pageIndex].levels.level, vm.pageData[vm.pageIndex].custom.ratio1,vm.pageData[vm.pageIndex].trimbox, function (imgIndex) {
                    vm.levelIndex = imgIndex;
                    vm.levelType = 'imageart';
                })
                fn(e);
                $scope.$apply();
            })
            $(".page-mobile-view").on("touchmove",".imageartview", function (e) {
                if (!e.originalEvent || e.originalEvent.touches.length == 1) {
                    imagearOne(e, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imageart, vm.pageData[vm.pageIndex].custom.ratio1,vm.pageData[vm.pageIndex].trimbox, function () {
                        vm.hint = !editboxIn(vm.pageData[vm.pageIndex].editboxes.editbox , vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imageart.geometry, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imageart.rotation.angle);
                    });
                    $scope.$apply();
                };
                if (e.originalEvent && e.originalEvent.touches.length >= 2) {
                    var now = e.originalEvent.touches;
                    Math.abs(e.originalEvent.touches[0].pageX - e.originalEvent.touches[1].pageX);
                    imagearTouchstartTwo(function (imageartZoomItem) {
                        imagearTwo(e,getDistance(now[0], now[1]) - getDistance(imageartZoomItem.start[0], imageartZoomItem.start[1]),vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imageart, vm.pageData[vm.pageIndex].trimbox, vm.pageData[vm.pageIndex].custom.ratio1)
                    })
                    $scope.$apply();
                }
            })
            $(".page-mobile-view").on("touchend",".imageartview", function (e) {
                vm.hint = false;
                imageartZoomEnd();
                if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
                $scope.$apply();
            })

            //删除素材
            $scope.deleteImageart = function (e, index) {
                fn(e)
                vm.pageData[vm.pageIndex].levels.level.splice(index, 1);
                vm.levelIndex = null;
                vm.levelType = null;
                if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
            };
            //翻转素材
            $scope.overturnImageart = function (e, imageart) {
                imageartOverturn(imageart);
                if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
                fn(e)
            };
            //复制素材
            $scope.copyImageart = function (e, level) {
                imageartCopys(vm.pageData[vm.pageIndex].levels.level, level);
                vm.levelIndex = vm.pageData[vm.pageIndex].levels.level.length-1;
                if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
                fn(e)
            };
            //缩放素材
            window.resizeStickerStart = function (e) {
                imageartZoomStart(e, vm.pageData[vm.pageIndex].levels.level, 'pitch', vm.pageData[vm.pageIndex].custom.ratio1,function (textIndex) {
                    vm.levelIndex = parseInt(textIndex);
                    vm.levelType = 'imageart';
                })
                fn(e);
            };
            window.resizeStickerOn = function (e) {
                fn(e);
                var currentPage = vm.pageData[vm.pageIndex].levels.level;
                var imageart = currentPage[vm.levelIndex][vm.levelType];
                imageartZoomMove(e, imageart, vm.pageData[vm.pageIndex].custom.ratio1,vm.pageData[vm.pageIndex].trimbox);
                $scope.$apply();
            };
            window.resizeStickerEnd = function (e) {
                imageartZoomEnd();
                if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
            };
            /***********************  素材end  ****************************/
            /***********************  文字  ****************************/
            window.moveTextBoxStart = function (e) {
                if(vm.editorType && (vm.editorType == 'PICTURE' || vm.editorType == 'FORM_EDITOR' || vm.editorType == 'PHOTO_EDITOR')){
                    return
                }
                if(vm.that.levelDome){vm.that.levelDome()};
                textBoxMoveStart(e, vm.levelIndex, vm.pageData[vm.pageIndex].levels.level, vm.pageData[vm.pageIndex].custom.ratio1, vm.pageData[vm.pageIndex].trimbox, function (textIndex) {
                    vm.levelIndex = parseInt(textIndex);
                    vm.levelType = 'textbox';
                    vm.transparency = parseInt(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.transparency * 100);
                    if(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.editable){
                        vm.that.textboxSelect = true;
                    }else if(!vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.editable){
                        vm.that.textboxSelect = false;
                    }
                    $scope.$apply();
                })
                fn(e);
            }
            window.moveTextBoxOn = function (e) {
                if(vm.pageData[vm.pageIndex] && vm.pageData[vm.pageIndex].levels && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex]) {
                    textBoxMoveOn(e, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox, vm.pageData[vm.pageIndex].custom.ratio1, vm.pageData[vm.pageIndex].trimbox,function () {
                        vm.hint = !editboxIn(vm.pageData[vm.pageIndex].editboxes.editbox , vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.rotation.angle);
                    })
                    fn(e);
                    $scope.$apply();
                }
            }
            window.moveTextBoxEnd = function (e) {
                fn(e);
                vm.hint = false;
                textBoxMoveEnd();
                if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
            }


            //旋转文字
            window.textboxStart = function (e) {
                textboxRotateStart(e, vm.levelIndex, vm.pageData[vm.pageIndex].levels.level, function (textIndex) {
                    vm.levelIndex = parseInt(textIndex);
                    vm.levelType = 'textbox';
                })
                fn(e);
            }
            window.textboxStickerOn = function (e) {
                textboxRotateOn(e, vm.levelIndex, vm.pageData[vm.pageIndex].levels.level, vm.pageData[vm.pageIndex].custom.ratio1, "pitch1",function (textIndex) {
                    $scope.$apply();
                })
                e.stopPropagation();
                e.preventDefault();
                return false;
            };
            window.textboxStickerEnd = function (e) {
                var currentPage = vm.pageData[vm.pageIndex].levels.level;
                var sticker = currentPage[vm.levelIndex].textbox;
                sticker.rotation.angle = sticker.rotation.angle;
                textBoxMoveEnd();
                if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
            };

            window.textboxZoomOnStart = function (e) {
                textboxZoomStart1(e, vm.levelIndex, vm.pageData[vm.pageIndex].levels.level, vm.pageData[vm.pageIndex].custom.ratio1, "pitch1", function (textIndex) {
                    vm.levelIndex = parseInt(textIndex);
                    vm.levelType = 'textbox';
                })
                fn(e);
            }
            window.textboxZoomRight1 = function (e) {
                textboxZoom2(e, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox, vm.pageData[vm.pageIndex].custom.ratio1, function (nl) {
                    if(vm.that.sizeHeight1){vm.that.sizeHeight1(nl)};
                    $scope.$apply();
                });
                fn(e);
            }
            window.textboxZoomRightStatr = function (e) {
                textboxZoomRightStart(e, vm.levelIndex, vm.pageData[vm.pageIndex].levels.level, vm.pageData[vm.pageIndex].custom.ratio1, "pitch1", function (textIndex) {
                    vm.levelIndex = parseInt(textIndex);
                    vm.levelType = 'textbox';
                })
                fn(e);
            }


            window.textboxZoomLeftStatr = function (e) {
                textboxZoomLeftStart(e, vm.levelIndex, vm.pageData[vm.pageIndex].levels.level, vm.pageData[vm.pageIndex].custom.ratio1, "pitch1", function (textIndex) {
                    vm.levelIndex = parseInt(textIndex);
                    vm.levelType = 'textbox';
                })
                fn(e);
            }
            window.textboxZoomRight = function (e) {
                var currentPage = vm.pageData[vm.pageIndex].levels.level;
                var sticker = currentPage[vm.levelIndex].textbox;
                textboxZoom(e, sticker, 'right',vm.pageData[vm.pageIndex].custom.ratio1, vm.pageData[vm.pageIndex].trimbox, function (accomplish) {
                    if(accomplish){
                        if(vm.that.sizeHeight1){vm.that.sizeHeight1()};
                    }
                    $scope.$apply();
                })
                fn(e);
            }
            window.textboxZoomTop = function (e) {
                var currentPage = vm.pageData[vm.pageIndex].levels.level;
                var sticker = currentPage[vm.levelIndex].textbox;
                textboxZoom(e, sticker, 'top',vm.pageData[vm.pageIndex].custom.ratio1, vm.pageData[vm.pageIndex].trimbox, function (accomplish) {
                    if(accomplish){
                        if(vm.that.sizeHeight1){vm.that.sizeHeight1()};
                    }
                    $scope.$apply();
                })
                fn(e);
            }
            window.textboxZoomLeft = function (e) {
                var currentPage = vm.pageData[vm.pageIndex].levels.level;
                var sticker = currentPage[vm.levelIndex].textbox;
                textboxZoom(e, sticker, 'left',vm.pageData[vm.pageIndex].custom.ratio1, vm.pageData[vm.pageIndex].trimbox, function (accomplish) {
                    if(accomplish){
                        if(vm.that.sizeHeight1){vm.that.sizeHeight1()};
                    }
                    $scope.$apply();
                })
                fn(e);
            }

            //鼠标抬起事件
            window.textboxZoomEnd = function (e) {
                textBoxMoveEnd();
                if(vm.that.thumbnailInit){vm.that.thumbnailInit()};
                e.stopPropagation();
            }

            $scope.writingmodeInit = function (item) {
                if(item == 'vertical-lr' || item == 'vertical-rl'){
                    return true
                }else{
                    return false
                }
            }
            /***********************  文字end  ****************************/
            /***********************  文字模板  ****************************/
            /***********************  文字模板end  ****************************/
            //移除画布透明效果
            $scope.opacityElement = function(mediabox, geometry){
                var opacity = 1;
                if(!geometry || !mediabox){
                    return 1
                }
                if(geometry.x > -geometry.width && geometry.y > -geometry.height && geometry.x < mediabox.width && geometry.y < mediabox.height){
                    opacity = 1;
                }else {
                    opacity = 0.3;
                }
                return opacity
            }
        }]
    };

    angular.module('editorApp')
        .component('pageMobileEdit', PageMobileEditControl);

})();
