(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ThumbnailatorController', ThumbnailatorController);

    ThumbnailatorController.$inject = ['$scope', 'Category', '$uibModalInstance', 'MessageService','entity',
    'CommonService', 'Qinius', 'Base64Image'];

    function ThumbnailatorController( $scope, Category, $uibModalInstance, MessageService,entity,
     CommonService, Qinius, Base64Image) {

        var vm = this;
        // vm.isEnd = true;
        vm.files = entity.files;
        vm.folderId = entity.folderId;
        vm.aid = entity.aid;
        vm.token = entity.token;
        $scope.domain = entity.domain;

        $scope.photos = [];

        angular.forEach(vm.files,function (f) {
            f.blobCover = URL.createObjectURL(f);
        })

        vm.clear = clear;
        vm.save = save;

        $scope.deletePic = function ($index) {
            vm.files.splice($index,1)
        }

        function blobToFile(theBlob, fileName) {
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        };

        $scope.compression = function () {
            vm.loading = true;
            var f = 0;
            angular.forEach(vm.files,function (file,i) {
                var img = new  Image();
                img.src = file.blobCover;
                Base64Image.compress(file.blobCover, img.height, 0.9, function(newBase64) {
                    var compressedSize = Base64Image.getSize(newBase64);
                    var blob = dataURLtoBlob(newBase64);
                    var aasfile = blobToFile(blob, file["name"]);
                    var aafile = new File([aasfile], file["name"], { type: file["type"] });
                    console.log("compress: " + file.size + " ==> " + aafile.size);

                    CommonService.UpdateQiniuService([aafile], 0, 1, $scope.domain, vm.token, vm.folderId,vm.aid,function (resp) {
                        file.lowerSize = Math.ceil(compressedSize/1024/1024);
                        file.newSize = compressedSize;

                        // var arr = resp.data;
                        // var identifier = angular.copy(arr.identifier)
                        // arr.identifier = $scope.domain + arr.identifier;
                        // arr.usenum = 0;
                        // arr.imgsize = arr.imgSize;
                        // $scope.photos.unshift(arr);
                        i++;
                        if(i == vm.files.length){
                            vm.isEnd = true;
                            vm.loading = false;
                            $scope.$digest();
                        }
                    }, function(){
                        i++;
                        if(i == vm.files.length){
                            vm.isEnd = true;
                            vm.loading = false;
                            $scope.$digest();
                        }
                    })
                });

            })
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            console.log($scope.photos)
            $uibModalInstance.close($scope.photos);
            vm.isSaving = false;
            // onSaveSuccess(vm.files);
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }

})();
