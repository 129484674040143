(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('UploadFailedImageListPopupController', UploadFailedImageListPopupController);

        UploadFailedImageListPopupController.$inject = ['$scope', '$uibModalInstance', 'MessageService','entity'];

    function UploadFailedImageListPopupController( $scope, $uibModalInstance, MessageService,entity) {

        var vm = this;
        vm.uploadFailedList = entity;
        vm.uploadFailedList.forEach(function(errorFile){
            errorFile.blobCover = URL.createObjectURL(errorFile);
        });


        $scope.close = function() {
            $uibModalInstance.close(false);
        }

        $scope.upload = function() {
            $uibModalInstance.close(true);
        }
    }

})();
