(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('CommoditySelectController', CommoditySelectController);

    CommoditySelectController.$inject = ['$scope', 'Principal', 'Product', '$state', 'MessageService', '$localStorage',
        '$stateParams', '$uibModal', 'Qinius', '$q', '$timeout','$translate', '$translatePartialLoader', '$cookies', 'translationStorageProvider'];

    function CommoditySelectController ($scope, Principal, Product, $state, MessageService, $localStorage,
                                        $stateParams, $uibModal, Qinius, $q, $timeout,$translate, $translatePartialLoader, $cookies, translationStorageProvider) {
        var vm = this;

        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        $(".well").css({'margin-bottom': 0});

        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        // vm.product = entity.data;
        vm.domains = Qinius.getDomain({storeId:vm.storeId});
        vm.themeId = 'all';
        vm.themeList = [{id: 'all', name: $translate.instant("commodity.theme.all")}];
        vm.itemValue = null;
        vm.productXmlList = [];  //页面展示的集合
        vm.productXmlListCB = [];  //当前属性的模板集合
        vm.properties = [];
        vm.returnurl1 = localStorage.getItem('returnurl1') || null;


        //测试时使用
        // $localStorage.lagnuages = ['zh-cn', 'en'];
        $scope.showLagnuages = function(){
            vm.showLagnuages = !vm.showLagnuages;
            if (vm.showLagnuages) {
                $(".lang-icn").css({'transform': 'rotate(270deg)'});
            }else{
                $(".lang-icn").css({'transform': 'rotate(90deg)'});
            }
        }

        lagnuageInit();
        function lagnuageInit() {
            vm.lang = $cookies.getObject("NG_TRANSLATE_LANG_KEY");
            vm.lagnuages =[];
            if ($localStorage.lagnuages) {
                for(var i = 0 ; i<$localStorage.lagnuages.length; i++ ){
                    var lang = $localStorage.lagnuages[i];
                    var result = translationStorageProvider.getLanguageType(lang);
                    if (!!result) {
                        if (vm.lang == result.value) {
                            vm.langKey = result.key;
                            result.selected = true;
                        }
                        vm.lagnuages.push(result);
                    }
                }
            }
            vm.productEntity = Product.get({id: $stateParams.pid});
            $q.all([vm.productEntity.$promise, vm.domains.$promise]).then(function () {
                vm.product = vm.productEntity.data;
                $scope.init();
            });
        }
        //swiper
        function swiper() {
            var swiper = new Swiper('.swiper-container1', {
                slidesPerView: 7,
                slidesPerGroup : 7,
                spaceBetween : 36,
                navigation: {
                    nextEl: '.swiper-button-next1',
                    prevEl: '.swiper-button-prev1',
                },
            });
        }

        $scope.changeLagnuage = function(lang){
            $timeout(function () {
                if (lang == null || lang.key == vm.langKey ) {
                    return;
                }
                angular.forEach(vm.lagnuages, function(item){
                    item.selected =false;
                });
                lang.selected = true;
                vm.langKey = lang.key;
                $translate.use(lang.value);
                vm.showLagnuages =false;
                $timeout(function () {
                    lagnuageInit();
                },100)
            })
        }



        $scope.returnurl1 = function(){
            if(vm.returnurl1){
                window.location.href = vm.returnurl1;
            }
        }

         //商户：先编辑后支付 且 商品：定制 点选属性值事件
        $scope.switchPaper = function (value , pValue) {
            if (!pValue.available) {
                return;
            }
            for (var i = value.propertyValues.length - 1; i >= 0; i--) {
                if (value.propertyValues[i].id == pValue.id) {
                    continue;
                }
                value.propertyValues[i].selected = false;
            }
            pValue.selected  = !pValue.selected;
            if (pValue.selected) {
                value.selected = true;
            }else{
                value.selected = false;
            }

            $scope.restoreAvailable(vm.product.itemValue.objectValues);
            $scope.getAvailable(vm.product.itemValues, vm.product.itemValue.objectValues);

            var valueArray = [];
            for (var i = vm.product.itemValue.objectValues.length - 1; i >= 0; i--) {
                var value = vm.product.itemValue.objectValues[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    var pv = value.propertyValues[j];
                    if (pv.selected) {
                        valueArray.push(pv.propertyId + "_" + pv.id);
                    }
                }
            }
            vm.product.itemValue.valueArray = valueArray;
            vm.itemValue = null;
            for(var i=0;i<vm.product.itemValues.length;i++){
                var itemValue = vm.product.itemValues[i];
                if (itemValue.cancelled) {
                    continue;
                }
                if (JSON.stringify(itemValue.valueArray.sort()) === JSON.stringify(vm.product.itemValue.valueArray.sort())) {
                    vm.itemValue = itemValue;

                }
            }
            $scope.getProductXmlList();
            paging();
            $scope.getThemeList();
        }

        $scope.getProductXmlList = function(){
            vm.productXmlListCB = [];
            if (vm.itemValue == null) {
                return;
            }
            for (var i = 0 ; i<vm.itemValue.productXmlLines.length ; i++) {
                var productXmlLine = vm.itemValue.productXmlLines[i];
                if (productXmlLine.cancelled) {
                    continue;
                }
                vm.productXmlListCB.push(productXmlLine);
            }
            vm.productXmlListCB = vm.productXmlListCB.sort(function(a, b ){
                return a.seq-b.seq;
            })
        }

        $scope.includePropertyValue = function(propertyValues, obValue){
            if (propertyValues == null || propertyValues.length == 0) {
                return false;
            }
            for (var i = propertyValues.length - 1; i >= 0; i--) {
                var pValue = propertyValues[i];
                if (pValue.id == obValue.propertyValueId ) {
                    return true;
                }
            }
            return false;
        }

        $scope.isInclude = function(elememt, array) {
            if (array.indexOf(elememt)>=0) {
                return true;
            }
            return false;
        }


        $scope.setPropertyValue = function(item, obValue){
            if (item == null || item.objectValues == null || item.objectValues.length == 0) {
                return null;
            }
            for (var i = item.objectValues.length - 1; i >= 0; i--) {
                var objectValue = item.objectValues[i];
                if (objectValue.propertyId == obValue.propertyId) {
                    if (!$scope.includePropertyValue(objectValue.propertyValues, obValue)) {
                        objectValue.propertyValues.push({
                            id:obValue.propertyValueId,
                            value :obValue.propertyValueValue,
                            propertyId:obValue.propertyId,
                            propertyName: obValue.propertyName
                        })
                    }
                }
            }
        }

         //先将所有可选项置为可选择的
        $scope.restoreAvailable = function(values) {
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    value.propertyValues[j].available = true;
                }
            }
        }

        //判断余下属性值是否可以点选
        $scope.getAvailable = function(arrayValues, values) {
            var selectedValue = [];
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    var pv = value.propertyValues[j];
                    if (!$scope.isAvailable(arrayValues, [pv.propertyId + "_" + pv.id])) {
                        value.propertyValues[j].available = false;
                    }
                }
            }

            //先将选择的都拿出来
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (value.selected) {
                    for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                        var pv = value.propertyValues[j];
                        if (pv.selected) {
                            selectedValue.push(pv.propertyId + "_" + pv.id);
                        }
                    }
                }
            }
            //没有选中的行 选项是否可以被选择
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (!value.selected) {
                    for (var z = value.propertyValues.length - 1; z >= 0; z--) {
                        var pv = value.propertyValues[z];
                        if (!pv.available) {
                            continue;
                        }
                        var selectedValueBack = angular.copy(selectedValue);
                        selectedValueBack.push((pv.propertyId + "_" + pv.id));
                        if (!$scope.isAvailable(arrayValues, selectedValueBack)) {
                            pv.available = false;
                        }
                    }
                }
            }
            //选中的行  将本行取出 拿本行其他选项与 其他行已经选中的搭配看是否可以选中
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (value.selected ) {
                    var selectedValueBack = angular.copy(selectedValue);
                    for (var j = selectedValueBack.length - 1; j >= 0; j--) {
                        var selectV = selectedValueBack[j];  //3_10
                        if (selectV.split("_")[0]== value.propertyId) {
                            selectedValueBack.splice(j,1);
                            for (var z = value.propertyValues.length - 1; z >= 0; z--) {
                                var pValue = value.propertyValues[z];
                                if (pValue.selected || !pValue.available) {
                                    continue;
                                }
                                var selectedValueBack2 = angular.copy(selectedValueBack);
                                selectedValueBack2.push(pValue.propertyId + "_" + pValue.id);
                                if (!$scope.isAvailable(arrayValues, selectedValueBack2)) {
                                    pValue.available = false;
                                }
                            }
                        }
                    }
                }
            }
        }

        //判断属性值是否可以点选
        $scope.isAvailable = function(arrayValues, selectedValueBack) {
            for (var z = arrayValues.length - 1; z >= 0; z--) {
                var l = arrayValues[z];
                if (l.cancelled) {
                    continue;
                }

                var includes = true;
                for (var i = selectedValueBack.length - 1; i >= 0; i--) {
                    var el = selectedValueBack[i];
                    if (!$scope.isInclude(el, l.valueArray)) {
                        includes = false;
                        break;
                    }
                }
                if (includes) {
                    return true;
                }
            }
            return false;
        }

        $scope.init = function(){
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            vm.editorType = vm.product.editorType;

            for (var i = 0; i < vm.product.itemValues.length; i++) {
                var item = vm.product.itemValues[i];
                if (item.cancelled || item.id != vm.vid) {
                    continue;
                }
                vm.product.itemValue = angular.copy(item);
                vm.product.itemValue.valueArray = vm.product.itemValue.skuNumber.split("-");
                vm.itemValue = angular.copy(item);
                if (vm.product.itemValue.objectValues && vm.product.itemValue.objectValues.length > 0) {
                    for (var z = vm.product.itemValue.objectValues.length - 1; z >= 0; z--) {
                        var value = vm.product.itemValue.objectValues[z];
                        value.selected = true;
                        for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                            if (value.propertyValueId == value.propertyValues[j].id) {
                                value.propertyValues[j].selected = true;
                            }
                        }
                    }
                }
                break;
            }

            if (vm.product.itemValues && vm.product.itemValues.length>0) {
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var item = vm.product.itemValues[i];
                    if (item.cancelled) {
                        continue;
                    }
                    item.valueArray = item.skuNumber.split("-");
                }
            }

            if (vm.product.itemValue) {
                for (var i = vm.product.itemValue.objectValues.length - 1; i >= 0; i--) {
                    var objectValue = vm.product.itemValue.objectValues[i];
                    for (var j = objectValue.propertyValues.length - 1; j >= 0; j--) {
                        var value = objectValue.propertyValues[j];
                        if (!value.selected) {
                            objectValue.propertyValues.splice(j, 1);
                        }
                    }
                }
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var itL = vm.product.itemValues[i];
                    if (itL.cancelled) {
                        continue;
                    }
                    for (var j = itL.objectValues.length - 1; j >= 0; j--) {
                        var ov = itL.objectValues[j];
                        $scope.setPropertyValue(vm.product.itemValue, ov);
                    }
                }
                $scope.restoreAvailable(vm.product.itemValue.objectValues);
                $scope.getAvailable(vm.product.itemValues, vm.product.itemValue.objectValues);
            }

            $scope.getProductXmlList();
            paging();
            $scope.getThemeList();

            setTimeout(function () {
                var _h = $("#c-commodity-top-content").height();
                if(_h > 35){
                    $("#c-commodity-top-content").css({height: '35px'});
                    $("#c-commodity-top-btn").show();
                }
                $("#c-commodity-top-btn").on('click', function () {
                    if($("#c-commodity-top-content").height() > 35){
                        $("#c-commodity-top-content").css({height: '35px'});
                    }else {
                        $("#c-commodity-top-content").css({height: 'auto'});
                    }
                });

                $(".c-commodity-top1").each(function () {
                    var _that = this;
                    var _h1 = $(this).find(".c-commodity-top-content").height();
                    if(_h1 > 35){
                        $(this).find(".c-commodity-top-content").css({height: '35px'});
                        $(this).find(".c-commodity-top-btn").show();
                    }
                    $(this).find(".c-commodity-top-btn").on('click', function () {
                        if($(_that).find(".c-commodity-top-content").height() > 35){
                            $(_that).find(".c-commodity-top-content").css({height: '35px'});
                        }else {
                            $(_that).find(".c-commodity-top-content").css({height: 'auto'});
                        }
                    });
                })
            }, 500);
            $scope.themeCb(vm.themeList[0]);

            $timeout(function () {
                swiper();
            },300);
        }

        // $q.all([vm.product.$promise, vm.domains.$promise]).then(function () {
        //     $scope.init();
        // });

        $scope.getThemeList = function(){
            vm.themeList = [{id: 'all', name: $translate.instant("commodity.theme.all")}];
            if (vm.itemValue == null) {
                return;
            }

            if (vm.itemValue.productXmlLines != null && vm.itemValue.productXmlLines.length>0) {
                for (var i = 0; i < vm.itemValue.productXmlLines.length; i++) {
                    var xmlLine = vm.itemValue.productXmlLines[i];
                    if (!xmlLine.themeClassifies || xmlLine.cancelled||!xmlLine.paid) {
                        continue;
                    }
                    xmlLine.themeClassifies.forEach(function (themeClassifie) {
                        if(!checkRepetition(themeClassifie.id)){
                            vm.themeList.push({
                                id: themeClassifie.id,
                                name: themeClassifie.name,
                                seq: Number(xmlLine.productXmlThemeSeq) + Number(themeClassifie.seq)
                            });
                        }
                    })
                }
            }
            vm.themeList.sort(function (a, b) {
                return a.seq - b.seq;
            });
        }

        $scope.themeCb = function (data) {
            if (vm.themeId == data.id) {
                return ;
            }
            vm.themeId = data.id;
            formatting();
        };

        function formatting() {
            vm.page = 1;
            vm.productXmlListCB = [];  //用来确认是那些 可以展示的 xmllines  将会通过 cancelled、themeId过滤
            $scope.getThemeList();
            if(vm.themeId != 'all'){
                if (vm.itemValue.productXmlLines != null && vm.itemValue.productXmlLines.length>0) {
                    for (var i = 0; i< vm.itemValue.productXmlLines.length ;i++) {
                        var xmlLine = vm.itemValue.productXmlLines[i];
                        if(!xmlLine.notAvailable&&!xmlLine.cancelled&&xmlLine.paid){
                            var _exist = false;
                            xmlLine.themeClassifies.forEach(function (themeClassifie) {
                                if (vm.themeId ==  themeClassifie.id) {
                                    _exist = true;
                                }
                            })
                            if (_exist) {
                                vm.productXmlListCB.push(xmlLine);
                            }
                        }
                    }
                }
            } else{
                if (vm.itemValue.productXmlLines != null && vm.itemValue.productXmlLines.length>0) {
                    for (var i = 0; i< vm.itemValue.productXmlLines.length ;i++) {
                        var xmlLine = vm.itemValue.productXmlLines[i];
                        if(!xmlLine.notAvailable&&!xmlLine.cancelled&&xmlLine.paid){
                            vm.productXmlListCB.push(xmlLine);
                        }
                    }
                }
            }
            vm.productXmlListCB = vm.productXmlListCB.sort(function(a, b ){
                return a.seq-b.seq;
            })
            paging();
        }

        function paging() {
                var nums = 10; //每页出现的数量
                var pages = Math.ceil(vm.productXmlListCB.length/nums); //得到总页数
                var thisDate = function(curr){
                    //此处只是演示，实际场景通常是返回已经当前页已经分组好的数据
                    var str = [], last = curr*nums - 1;
                    last = last >= vm.productXmlListCB.length ? (vm.productXmlListCB.length-1) : last;
                    for(var i = (curr*nums - nums); i <= last; i++){
                        str.push(vm.productXmlListCB[i]);
                    }
                    if (str.length>0) {
                        str = str.sort(function(a, b ){
                            return a.seq-b.seq;
                        })
                    }
                    return str;
                };

                //调用分页
                laypage({
                    cont: 'biuuu_city',
                    curr: vm.page, //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    jump: function(obj){
                        $timeout(function () {
                            vm.productXmlList = thisDate(obj.curr);
                            vm.productXmlList.sort(sortNavTitle("seq"));
                            vm.page = obj.curr;
                        })
                    }
                });
        }

        function sortNavTitle(propertyName) {
            return function(object1, object2) {
                var value1 = object1[propertyName];
                var value2 = object2[propertyName];
                return value1 - value2;
            }
        }
        // 检查是否存在重复--vm.themeList
        function checkRepetition(id) {
            if (vm.themeList.length == 0) {
                return false;
            } else {
                for (var i = 0; i < vm.themeList.length; i++) {
                    if (vm.themeList[i].id == id) {
                        return true;
                    }
                }
            }
            return false;
        }
        //预览
        $scope.commodityPreview = function (value){
            $uibModal.open({
                templateUrl: 'app/entities/commodity/commodity-preview.html',
                controller: 'CommodityPreviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        var _obj = {
                            storeId :vm.storeId,
                            pid :vm.pid,
                            value: value,
                            itemValueId: vm.itemValue.id,
                            aid:vm.aid,
                            vid:vm.vid,
                            editorType:vm.product.editorType
                        }
                        return _obj;
                    }],
                    translatePartialLoader: [function () {
                        $translatePartialLoader.addPart('commodityPage');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                }
            }).result.then(function (backData) {
                console.log(backData);
            });
        };
    }
})();
