(function () {
    'use strict';

    var threePreview = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/three-preview.html',
        // controller: 'articlePreviewController',
        controllerAs: 'vm',
        bindings: {
            height: '<',
            width: '<',
            modelPath: '<',
            modelBlendents: '<',
            modelData: '<',
            modelBlendentUuid: '<',
            pages: '<',
        },
        controller: ['$scope', '$stateParams', 'Qinius', '$http', '$q', 'ImageService', 'PlatformWechatSetting',
            'Article', 'Music', 'ItemValue', 'Store', 'MessageService','$state', '$compile','$timeout', 'ThreeEnv',
            function ($scope, $stateParams, Qinius, $http, $q, ImageService, PlatformWechatSetting,
                      Article, Music, ItemValue, Store, MessageService,$state, $compile,$timeout, ThreeEnv) {
                var vm = this;
                $scope.domains = Qinius.getDomain();
                vm.loading = true;
                vm.error = false;
                vm.data = "";
                vm.viewWidth = vm.width || $(window).width();
                vm.viewHeight = vm.height || $(window).height();
                vm.$onChanges = function(data) {
                    if (data.pages) {
                        var pages = data.pages.currentValue;
                        if(pages && vm.modelData){
                            if(pages.length == 1){
                                for(var i=0; i<vm.modelData.length; i++){
                                    if(vm.modelData[i].page > 0 || (vm.modelData[i].page == 0 && vm.modelData[i].page!=='')){
                                        vm.modelData[i].img = pages[0].pagecover.identifier;
                                    }
                                }
                            }else {
                                for (var i = 0; i < vm.modelData.length; i++) {
                                    for (var j = 0; j < pages.length; j++) {
                                        if (vm.modelData[i].page == j && vm.modelData[i].page !== '') {
                                            vm.modelData[i].img = pages[j].pagecover.identifier;
                                        }
                                    }
                                }
                            }
                            initContent1();
                        }
                    }
                    if (data.width && data.width.previousValue>0) {
                        vm.viewWidth = data.width.currentValue;
                        vm.viewHeight = data.height.currentValue;
                        document.getElementById("threeView").appendChild(renderer.domElement);
                        onWindowResize()
                    }
                };

                vm.$onDestroy = function(){
                    cancelAnimationFrame(vm.animateFrame);
                }
                vm.$onInit = function(){
                    if(vm.modelBlendents){
                        vm.modelBlendents.forEach(function (item) {
                            if(item.uuid == vm.modelBlendentUuid){
                                vm.modelBlendent = item;
                            }
                        })
                    }
                    if(vm.pages.length == 1){
                        for(var i=0; i<vm.modelData.length; i++){
                            if(vm.modelData[i].page > 0 || (vm.modelData[i].page == 0 && vm.modelData[i].page!=='')){
                                vm.modelData[i].img = vm.pages[0].pagecover.identifier;
                            }
                        }
                    }else{
                        for(var i=0; i<vm.modelData.length; i++){
                            for(var j=0; j<vm.pages.length; j++){
                                if(vm.modelData[i].page == j&&vm.modelData[i].page!==''){
                                    vm.modelData[i].img = vm.pages[j].pagecover.identifier;
                                }
                            }
                        }
                    }
                    if(vm.modelBlendent){
                        for(var i=0; i<vm.modelData.length; i++){
                            for(var j=0; j<vm.modelBlendent.modelData.length; j++){
                                if(vm.modelData[i].materialName === vm.modelBlendent.modelData[j].materialName){
                                    vm.modelData[i].color = vm.modelBlendent.modelData[j].color;
                                }
                            }
                        }
                    }else{
                        for(var i=0; i<vm.modelData.length; i++){
                            vm.modelData[i].color = null;
                        }
                    }
                    init();
                }

                function loaddingError(message) {
                    vm.loading = false;
                    vm.error = true;
                    vm.errorText = message;
                }
                function loaddingSuccess() {
                    vm.loading = false;
                    vm.error = false;
                }

                var scene, camera, renderer, controls, guiControls;
                // var stats = initStats();

                /* 场景 */
                function initScene() {
                    scene = new THREE.Scene();
                    // scene.background = new THREE.Color(0xB3CEFB);
                    // scene.fog = new THREE.Fog(scene.background, 0.1, 1);
                }

                /* 相机 */
                function initCamera(clientWidth, clientHeight) {
                    // camera = new THREE.PerspectiveCamera(45, vm.viewWidth / vm.viewHeight, 0.1, 100000);
                    // camera.position.z = 15;
                    // camera.lookAt(new THREE.Vector3(0, 10, 0));



                    camera = new THREE.PerspectiveCamera(50, clientWidth / clientHeight, 0.1, 1000);
                    var l1 = new THREE.AmbientLight(0xffffff, 0.3);
                    l1.name = 'a-light';
                    camera.add(l1);
                    var l2 = new THREE.DirectionalLight(0xffffff, 0.8 * Math.PI);
                    l2.position.set(6, 0, 6); // ~60º
                    l2.name = 'm-light';
                    l2.castShadow = true;
                    camera.add(l2);
                    var l3 = new THREE.HemisphereLight(0xffffff, 0x000000, 1);
                    l3.name = 'h-light';
                    camera.add(l3);
                    scene.add(camera);

                }

                /* 渲染器 */
                function initRender() {
                    renderer = new THREE.WebGLRenderer({
                        antialias: true,
                        alpha:true,
                        precision: 'highp',
                        preserveDrawingBuffer: true
                    });
                    renderer.setClearColor(new THREE.Color(0xf3f3f3),0.0);
                    renderer.setSize(vm.viewWidth, vm.viewHeight);
                    renderer.shadowMap.enabled = true;
                    renderer.physicallyCorrectLights = true;
                    renderer.outputEncoding = THREE.sRGBEncoding;
                    renderer.minFilter = THREE.LinearFilter;
                    renderer.gammaOutput = true;
                    renderer.setPixelRatio( window.devicePixelRatio );

                    $timeout(function () {
                        document.getElementById("threeView").appendChild(renderer.domElement);
                        onWindowResize()
                        initContent();
                        loaddingSuccess();
                        $scope.$apply();
                    })
                }

                /* 灯光 */
                function initLight() {
                    scene.add(new THREE.AmbientLight(0xdddddd));
                    var directionalLight = new THREE.DirectionalLight(0xdddddd, 0.1);
                    directionalLight.position.set(-4, 8, 4);
                    var dhelper = new THREE.DirectionalLightHelper(directionalLight, 5, 0xff0000);

                    var hemisphereLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.6);
                    hemisphereLight.position.set(0, 8, 0);
                    var hHelper = new THREE.HemisphereLightHelper(hemisphereLight, 5);

                    // directionalLight.castShadow = true;
                    // directionalLight.shadow.mapSize = new THREE.Vector2(100000, 100);
                    // directionalLight.shadow.bias = -0.1;
                    // scene.add(hemisphereLight);
                    scene.add(directionalLight);


                    // let floorGeometry = new THREE.PlaneGeometry(500, 500, 32);
                    // var material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
                    // let floorMaterial = new THREE.MeshPhongMaterial({
                    //     color: 0xffff00,
                    //     shininess: 0,
                    //     // wireframe: true
                    // });
                    // let floor = new THREE.Mesh(floorGeometry, material);
                    // floor.rotation.x = -0.5 * Math.PI;
                    // floor.position.y = -120;
                    // floor.receiveShadow = true;
                    // scene.add(floor);
                }

                /* 控制器 */
                function initControls() {
                    controls = new THREE.OrbitControls(camera, renderer.domElement);
                    controls.autoRotate = true;
                    controls.autoRotateSpeed = -6;
                }

                /* 场景中的内容 */
                function initContent() {
                    // 加载 glTF 格式的模型
                    var loader = new THREE.GLTFLoader();
                    if (THREE.DRACOLoader) {
                        var dracoLoader = new THREE.DRACOLoader();
                        if (dracoLoader) {
                            dracoLoader.setDecoderPath("lib/three/draco/");
                            loader.setDRACOLoader( dracoLoader );
                        }
                    }
                    loader.load(vm.modelPath, function (gltf) {
                        var metalness = checkGltfMetalness(gltf);
                        var gltfScene = gltf.scene || gltf.scenes[0];
                        var clips = gltf.animations || [];
                        gltfScene.updateMatrixWorld();
                        var box = new THREE.Box3().setFromObject(gltfScene);
                        var size = box.getSize(new THREE.Vector3()).length();
                        var center = box.getCenter(new THREE.Vector3());
                        // controls.reset();
                        gltfScene.position.x += gltfScene.position.x - center.x;
                        gltfScene.position.y += gltfScene.position.y - center.y;
                        gltfScene.position.z += gltfScene.position.z - center.z;

                        // 重新设置相机参数
                        controls.maxDistance = size * 10;
                        camera.near = size / 80;
                        camera.far = size * 80;
                        camera.updateProjectionMatrix();

                        camera.position.copy(center);
                        camera.position.x += size / 1.0;
                        camera.position.y += size / 1.0;
                        camera.position.z += size / 1.0;
                        camera.lookAt(center);

                        gltf.scene.traverse(function(child){
                            if (child.isMesh) {
                                child.material.depthWrite = !child.material.transparent;
                                var materials = Array.isArray(child.material) ? child.material : [child.material];
                                materials.forEach(function (node) {
                                    for(var i=0; i<vm.modelData.length; i++){
                                        if(vm.modelData[i].materialName === node.name){
                                            if(vm.modelData[i].img){
                                                var textureLoader = new THREE.TextureLoader();
                                                var map = textureLoader.load(vm.modelData[i].img);
                                                map.minFilter = THREE.LinearFilter;
                                                map.flipY = false;
                                                if (node.map) {
                                                    // copy current map repeat and offset to the new map
                                                    // this is for gltfpack (meshoptimizer) compressed models
                                                    // Also, in the model, you need to embed a texture image so that we can retrieve it here.
                                                    map.repeat.copy(node.map.repeat);
                                                    map.offset.copy(node.map.offset);
                                                }

                                                // copy texture encoding from existing one to the loaded texture
                                                map.encoding = node.map ? node.map.encoding : THREE.sRGBEncoding;

                                                node.map = map;
                                                node.needsUpdate = true;
                                            }else{
                                                if(vm.modelData[i].color){
                                                    node.color = new THREE.Color(vm.modelData[i].color);
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        })
                        scene.add(gltf.scene);
                        if (metalness > 0) {
                            console.log("metalness=" + metalness);
                            updateEnvironment();
                        }
                        // 判断当前的gltf问价中是否有动画
                        if (clips.length !== 0) {
                            var mixer = new THREE.AnimationMixer(gltfScene);
                            mixer.clipAction(gltf.animations[0]).play();
                        }
                    }, function (xhr) {
                        // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                    }, function (error) {
                        // console.log(error)
                        loaddingError();
                    })

                }
                function initContent1() {
                    // 加载 glTF 格式的模型
                    var loader = new THREE.GLTFLoader();
                    if (THREE.DRACOLoader) {
                        var dracoLoader = new THREE.DRACOLoader();
                        if (dracoLoader) {
                            dracoLoader.setDecoderPath("lib/three/draco/");
                            loader.setDRACOLoader( dracoLoader );
                        }
                    }
                    loader.load(vm.modelPath, function (gltf) {
                        var gltfScene = gltf.scene || gltf.scenes[0];
                        var clips = gltf.animations || [];
                        gltfScene.updateMatrixWorld();
                        var box = new THREE.Box3().setFromObject(gltfScene);
                        var size = box.getSize(new THREE.Vector3()).length();
                        var center = box.getCenter(new THREE.Vector3());
                        gltfScene.position.x += gltfScene.position.x - center.x;
                        gltfScene.position.y += gltfScene.position.y - center.y;
                        gltfScene.position.z += gltfScene.position.z - center.z;

                        gltf.scene.traverse(function(child){
                            if (child.isMesh) {
                                var materials = Array.isArray(child.material) ? child.material : [child.material];
                                materials.forEach(function (node) {
                                    for(var i=0; i<vm.modelData.length; i++){
                                        if(vm.modelData[i].materialName === node.name){
                                            if(vm.modelData[i].img){
                                                var textureLoader = new THREE.TextureLoader();
                                                var map = textureLoader.load(vm.modelData[i].img);
                                                map.minFilter = THREE.LinearFilter;
                                                map.flipY = false;

                                                if (node.map) {
                                                    // copy current map repeat and offset to the new map
                                                    // this is for gltfpack (meshoptimizer) compressed models
                                                    // Also, in the model, you need to embed a texture image so that we can retrieve it here.
                                                    map.repeat.copy(node.map.repeat);
                                                    map.offset.copy(node.map.offset);
                                                }

                                                // copy texture encoding from existing one to the loaded texture
                                                map.encoding = node.map ? node.map.encoding : THREE.sRGBEncoding;

                                                node.map = map;
                                                node.needsUpdate = true;
                                            }else{
                                                if(vm.modelData[i].color){
                                                    node.color = new THREE.Color(vm.modelData[i].color);
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        })
                        scene.add(gltf.scene);
                    }, function (xhr) {
                        // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                    }, function (error) {
                        // console.log(error)
                        loaddingError();
                    })

                }

                /* 窗口变动触发 */
                function onWindowResize() {
                    var width =vm.viewWidth;
                    var height = vm.viewHeight;
                    camera.aspect = width / height;
                    camera.updateProjectionMatrix();
                    renderer.setSize(width, height);
                }

                /* 数据更新 */
                // function update() {
                //     stats.update();
                // }
                /* 初始化 */
                function init() {
                    var container = document.getElementById("threeView");
                    initScene();
                    initCamera(container.clientWidth, container.clientHeight);
                    initRender();
                    // initLight();
                    initControls();
                    // initGui();
                    /* 监听事件 */
                    window.addEventListener('resize', onWindowResize, false);
                    animate();
                }

                /* 循环渲染 */
                function animate() {

                    // 每次取消动画
                    cancelAnimationFrame(vm.animateFrame);

                    var r = function (){
                        if (!renderer) {
                            return;
                        }
                        vm.animateFrame = requestAnimationFrame(r);
                        renderer.render(scene, camera);
                        controls.update();
                    };
                    r();


                    // scene.rotation.y += 0.01;
                    // renderer.render(scene, camera);
                    // controls.update();
                    // requestAnimationFrame(animate);
                }

                $scope.onMouseOver = function () {
                    controls.autoRotate = false;
                }
                $scope.onMouseOut = function () {
                    controls.autoRotate = true;
                }

                function checkGltfMetalness(gltf) {
                    var metalness = 0;
                    if (gltf && gltf.scene) {
                        metalness = parseGroupMetalness(gltf.scene);
                    }
                    return metalness;
                }

                function parseGroupMetalness(group) {
                    var metalness = 0;
                    if (!group.children) {
                        return metalness;
                    }
                    for (var i = 0; i < group.children.length; i++) {
                        var child = group.children[i];
                        if (child.type == 'Mesh') {
                            if (child.material && child.material.metalness > metalness) {
                                metalness = child.material.metalness;
                            }
                        }
                        else if (child.type == 'Group') {
                            var val = parseGroupMetalness(child);
                            if (val > metalness) {
                                metalness = val;
                            }
                        }
                    }
                    return metalness;
                }

                function updateEnvironment () {
                    ThreeEnv.getEnvMap(renderer).then(
                        function (data) {
                            if (data && data.envMap) {
                                scene.environment = data.envMap;
                            }
                        }
                    );
                }
            }
        ]
    };

    angular
        .module('editorApp')
        .component('threePreview', threePreview);
})();
