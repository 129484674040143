(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', '$q', 'ProfileService', 'LoginService', '$scope', '$localStorage', '$uibModal',
    "$stateParams", "Product", "Store", "Qinius", "$cookieStore", "MessageNotification", 'MessageService', 'DateUtils', 'ToneSettings'];

    function NavbarController ($state, Auth, Principal, $q, ProfileService, LoginService, $scope, $localStorage, $uibModal,
        $stateParams, Product, Store, Qinius, $cookieStore, MessageNotification, MessageService, DateUtils, ToneSettings) {
        var vm = this;
        var _urlParam = getUrlParam();
        handleFontSizeInit();
        if(_urlParam && _urlParam['authorization']){
            $localStorage.authenticationToken = _urlParam['authorization'];
        };
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.login = login;
        vm.register = register;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.isLogin = false;
        vm.shrink = shrink;
        vm.storeId = $stateParams.storeId || $state.params.storeId || $cookieStore.get("store-id");
        if (!vm.storeId) {
            return;
        }
        vm.sidebar2Shrik = sidebar2Shrik;
        vm.titleNavbar = titleNavbar;
        vm.lmenuClick = lmenuClick;
        vm.account = $localStorage.user;
        vm.navSelection = "";
        vm.store = Store.get({id:vm.storeId});
        $scope.domains = Qinius.getDomain({storeId: vm.storeId});

        //获取自定义颜色
        getToneSettings();
        function getToneSettings() {
            ToneSettings.byStoreId({id:vm.storeId}, function (res) {
                if(res.status == 200){
                    if(res.data){
                        vm.data = res.data;
                        for(var key in  res.data){
                            if(res.data[key]){
                                document.body.style.setProperty('--' + key, res.data[key]);
                            }
                        }
                    }
                }else{
                    MessageService.error(res.message)
                }
            }, function (error) {});
        }
        //获取商品
        var items = [{key: "saleable", op: "=", value: "true"}, {
            key: "productRangeType",
            op: "like",
            value: "ALL"
        }, {key: "storeId", op: "=", value: vm.storeId}];

        var items2 = [{key: "saleable", op: "=", value: "true"}, {
            key: "productRangeType",
            op: "like",
            value: "WEB"
        }, {key: "storeId", op: "=", value: vm.storeId}];


        $q.all([$scope.domains.$promise, vm.store.$promise]).then(function () {
            vm.store = vm.store.data;
            vm.domain = 'https://' + $scope.domains.domains[0] + '/';
            vm.logoUrl = vm.store.menuLogo ? vm.domain + vm.store.menuLogo : 'content/images/logo.png';
        });

        //作品库
        if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
            vm.isLogin = false;
        }else{
            vm.isLogin = true;
        }

        $scope.getName = function(){
            if (vm.account.name == "未知"  && !!vm.account.phone) {
                return vm.account.phone.substring(0,3)+"****"+vm.account.phone.substring(7);
            }else{
                return vm.account.name;
            }
        }

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
            $(".titleNavbar").eq(vm.menu).find($(".arrowsB")).removeClass('arrows').addClass('arrowsTop');
            // 菜单刷新后定位
            vm.lmenu = sessionStorage.getItem("lmenu") == null ? 'a_storeManagement' : sessionStorage.getItem("lmenu");
        });

        var $bar1 = $(".sidebar1-btn");
        var $side1 = $(".sidebar1");
        var $bar2 = $(".sidebar2-btn");
        var $side2 = $(".sidebar2");
        var isHide = false;
        var $navbar = $(".navbar-wrap");
        window.showSidebar2Shrink = function (event) {
            if ($navbar.hasClass("sidebar-collapse3")){
                $(".sidebar2-shrink").removeClass('shidebar2-shrink-left').addClass('shidebar2-shrink-left2');
                return
            }
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left').addClass('shidebar2-shrink-left');
        };

        window.hideSidebar2Shrink = function (event) {
            if ($navbar.hasClass("sidebar-collapse3")){
                $(".sidebar2-shrink").removeClass('shidebar2-shrink-left2');
                return
            }
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left');
        };

        function lmenuClick(a_id) {
            vm.lmenu = a_id;
            sessionStorage.setItem("lmenu", a_id);
        }

        var createModal = function (url, controller, type) {
            if (type == null) {
                type = "Login";
            }

            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:{
                        type:type
                    },
                }
            });
        };

        function login() {
            createModal('app/account/register/register.html', 'RegisterController', "Login").result.then(
                function () {
                });
        };

        function register(){
            createModal('app/account/register/register.html', 'RegisterController', "Register").result.then(
                function () {
                });
        }



        function logout() {
            collapseNavbar();
            Auth.logout();
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }


        function shrink() {
            $navbar.toggleClass("sidebar-collapse");
            $side2.addClass('transition');
            if (isHide) {
                if ($navbar.hasClass("sidebar-collapse")) {
                    $bar1.removeClass('btn1-shrik1').addClass("btn1-shrik2");
                    $navbar.toggleClass("sidebar-collapse3").toggleClass("sidebar-collapse4");
                    $(".wrap").removeClass("wrap-mar130").removeClass("wrap-mar50").toggleClass("wrap-mar60");
                } else {
                    $bar1.removeClass('btn1-shrik2').addClass("btn1-shrik1");
                    $(".wrap").removeClass("wrap-mar50").removeClass("wrap-mar60").toggleClass("wrap-mar130");
                    $navbar.toggleClass("sidebar-collapse4").toggleClass("sidebar-collapse3");
                }
            } else {$bar1.toggleClass('btn1-shrik1').toggleClass("btn1-shrik2");
                $(".wrap").toggleClass("wrap-mar50");
            }
            return false;
        }

        $scope.resetPassword = function(){
            createModal('app/account/password/password.html', 'PasswordController').result.then(
                function(data) {
                });
        };

        function titleNavbar(i) {
            var $titleNavbar = $(".titleNavbar");
            var $menu1 = $(".menu1");
            // click时清空一级菜单样式
            $titleNavbar.find($(".navbar-title")).css({
                background: '#3c434d'
            });
            $titleNavbar.find($(".arrowsB")).removeClass('arrowsTop').addClass('arrows');
            //若二级菜单为隐藏状态：
            if ($titleNavbar.eq(i).find($menu1).is(':hidden')) {
                //箭头运动状态向下
                $titleNavbar.eq(i).find($(".arrowsB")).removeClass('arrows').addClass('arrowsTop');
                // click时改变一级菜单背景色和字体色
                $titleNavbar.eq(i).find($(".navbar-title")).css({
                    background: '#313840'
                });
                //显示当前二级菜单
                $titleNavbar.eq(i).find($menu1).slideDown(400);
                localStorage.setItem("menu1",i);
                //隐藏其他二级菜单
                $titleNavbar.eq(i).siblings().find($(".menu1")).hide();

            } else {
                $titleNavbar.eq(i).find($(".arrowsB")).removeClass('arrowsTop').addClass('arrows');
                $titleNavbar.eq(i).find($(".navbar-title")).css({
                    background: '#313840'
                });
                $titleNavbar.eq(i).find($menu1).slideUp(400);
                $titleNavbar.eq(i).siblings().find($menu1).hide();
            }
        }

        function sidebar2Shrik() {
            if(!($(".sidebar2-shrink").hasClass('shidebar2-shrink-left')||$(".sidebar2-shrink").hasClass('shidebar2-shrink-left2'))){
                return
            }

            $side2.removeClass('transition');
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left2');
            isHide = !isHide;

            if(isHide){
                $(".sidebar2").addClass("sidebar2-zIndex");
                $bar2.removeClass('btn2-shrik1').addClass("btn2-shrik2");
            }else {
                $(".sidebar2").removeClass("sidebar2-zIndex");
                $bar2.removeClass('btn2-shrik2').addClass("btn2-shrik1");
            }
            if ($navbar.hasClass("sidebar-collapse")) {
                $(".wrap").toggleClass("wrap-mar50").toggleClass("wrap-mar60");
                $navbar.removeClass("sidebar-collapse3").toggleClass("sidebar-collapse4");
            } else {
                $navbar.removeClass("sidebar-collapse2").removeClass("sidebar-collapse4").toggleClass("sidebar-collapse3");
                $(".wrap").removeClass("wrap-mar50").toggleClass("wrap-mar130");
            }
        }


        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }



        if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
            vm.isLogin = false;
        }else{
            vm.isLogin = true;
        }

        $scope.login = function () {
            createModal('app/account/register/register.html', 'RegisterController').result.then(
                function () {
                });
        };
        function route() {
            var _class = angular.element('#myWork').attr('class');
            if(_class == 'pageOn'){
                angular.element('#myWork').removeClass('pageOn');
            }
        }


        //消息列表
        vm.messageList = [];
        // MessageNotification.getAllByCondition2(
        //     {items:[
        //         {key:"pageType", op : "!=", value:"Edit"},
        //         {key:"storeId", op : "=", value:vm.storeId},
        //         {key:"available", op : "=", value:"true"},
        //         {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
        // }, function (res) {
        //         if(res.status == 200){
        //             vm.messageList =res.data;
        //         }else {
        //             MessageService.error(res.message);
        //         }
        // })
        function compareDate(s1,s2){
            return ((new Date(s1.replace(/-/g,"\/")))>(new Date(s2.replace(/-/g,"\/"))));
        }
        function getNowFormatDate() {
            var date = new Date();
            var seperator1 = "-";
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            return currentdate;
        }

        userAgent()
        function userAgent() {
            var _userAgentTime = localStorage.getItem("userAgentTime");
            if(getExploreName().indexOf('Chrome') == -1 && time()!=_userAgentTime && !isMobile()){
                $uibModal.open({
                    templateUrl: 'app/layouts/navbar/userAgent.html',
                    controller: 'UserAgentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {}
                });
            }
        }
        function isMobile(){
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }
        function time() {
            var d = new Date();
            var getFullYear = d.getFullYear();
            var getMonth = d.getMonth() + 1;
            var getDate = d.getDate();
            return getFullYear + '-' + getMonth + '-' + getDate
        }

    }
})();
