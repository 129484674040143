(function () {
    'use strict';

    var bgOperation = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        controllerAs: 'vm',
        bindings: {
            document: '=',
            arrI: '=',
            pageI: '=',
            currentPage: '=',
            showBg: '=',
            bgw: '=',
            bgh: '=',
            slideW: '=',
            slideH: '=',
            bgl: '=',
            bgt: '=',
        },

        controller: ['$scope', 'ImageService', '$q', 'Qinius', 'Store', '$http', '$rootScope', 'Article', 'FontManagement', '$state',
            function ($scope, ImageService, $q, Qinius, Store, $http, $rootScope, Article, FontManagement, $state) {
                var vm = this;
                var drag = false;
                var dragBox = {};

                window.doubleClickbgImg = function (e) {
                    fn(e);
                    var currentPage = vm.currentPage;
                    if (currentPage.background && currentPage.background.resource && currentPage.background.resource.identifier) {
                        $scope.cutBgImg(currentPage, vm.pageI);
                    }
                    return
                }

                /**
                 * 进入图片编辑模式
                 */
                $scope.cutBgImg = function (page, pageI) {
                    var page = vm.document.pages[vm.pageI];
                    var arr = page.arr;
                    if (vm.arrI == 2) {
                        arr = page.arr2
                    }

                    vm.currentPage = arr;

                    vm.showBg = true;
                    console.log(vm)

                    vm.bgw = $('.bg-image' + vm.arrI + pageI).width();
                    vm.bgh = $('.bg-image' + vm.arrI + pageI).height();
                    vm.slideW = $(".slide" + vm.arrI + pageI).width();
                    vm.slideH = $(".slide" + vm.arrI + pageI).height();


                    $("#cut-mask-bg").attr('src', '');

                    var background = arr.background;
                    background.width = vm.bgw / arr.pageScaleW / page.scale;
                    background.height = vm.bgh / arr.pageScaleH / page.scale;

                    // 显示区域（包含上/下页按钮）
                    var bodyW = $(".web-con").width();
                    var bodyH = $(".web-con").height();

                    // 裁切框显示位置
                    var left = parseFloat($(".page-center").css('marginLeft')) + parseFloat($(".page-center").css('left')) + parseFloat($(".web" + vm.pageI).css('marginLeft'));
                    // var top = bodyH / 2 - $(".page-center").height() / 2;
                    var top = parseFloat($(".page-center").css('marginTop')) + parseFloat($(".page-center").css('top'));
                    if (vm.arrI == 2) {
                        left += $(".slide1" + vm.pageI).width();
                    }
                    vm.bgl = left;
                    vm.bgt = top;
                    $("#cut-bg").css({
                        left: left + background.offsetx * arr.pageScaleW * page.scale,
                        top: top + background.offsety * arr.pageScaleH / page.scale
                    });

                    ImageService.convertImgToBase64('content/images/white.png', function (s) {
                        var imagecanvas = $("#mask-canvas")[0];
                        var imagecontext = imagecanvas.getContext('2d');
                        var newImg = document.createElement('img');
                        // newImg.crossOrigin = "Anonymous";
                        newImg.src = 'content/images/zhezhao1.png';
                        newImg.onload = function () {
                            newImg.onload = null;
                            var masks = document.createElement('img');
                            masks.src = s;
                            // masks.crossOrigin = "Anonymous";
                            masks.onload = function () {
                                masks.onload = null;
                                imagecanvas.width = bodyW;
                                imagecanvas.height = bodyH;
                                imagecontext.drawImage(masks, left, top, vm.slideW, vm.slideH);
                                imagecontext.globalCompositeOperation = 'source-out';
                                imagecontext.drawImage(newImg, 0, 0, bodyW, bodyH);

                                $("#cut-mask-bg").attr('src', ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                                $scope.$digest();
                            };
                            // masks.src = box.maskUrl;
                        };
                        newImg.src = 'content/images/zhezhao1.png';
                    });
                    $scope.$apply();
                };

                /**
                 * 拖拽编辑的图片
                 */
                window.startBgEdit = function (e) {
                    fn(e);

                    // $scope.bgw = $('.bg-image' + vm.arrI + vm.pageI).width();
                    // $scope.bgh = $('.bg-image' + vm.arrI + vm.pageI).height();
                    // $scope.slideW = $(".slide" + vm.arrI + vm.pageI).width();
                    // $scope.slideH = $(".slide" + vm.arrI + vm.pageI).height();

                    var page = vm.document.pages[vm.pageI];
                    dragBox.clientX = e.clientX ? e.clientX : 0;
                    dragBox.clientY = e.clientY ? e.clientY : 0;
                    dragBox.picW = $("#cut-bg").width();
                    dragBox.picH = $("#cut-bg").height();
                    var background = vm.currentPage.background;
                    dragBox.coordX = background.offsetx * vm.currentPage.pageScaleW * page.scale;
                    dragBox.coordY = background.offsety * vm.currentPage.pageScaleH * page.scale;

                    drag = true;
                    document.addEventListener("mousemove", dragBgEdit, true);
                    document.addEventListener("mouseup", stopBgEdit, true);
                };

                function dragBgEdit(e) {

                    if (drag) {
                        var page = vm.document.pages[vm.pageI];
                        var background = vm.currentPage.background;
                        var x = 0, y = 0;

                        //手指离开屏幕
                        if (background.resource.adaptation == 'Height') {    //宽图
                            x = (dragBox.coordX + (e.clientX - dragBox.clientX));
                            //向左滑动;        <-----
                            if (-x >= (vm.bgw - vm.slideW)) {
                                x = -(vm.bgw - vm.slideW);
                            }
                            // 向右滑动;    ---->
                            if (x >= 0) {
                                x = 0;
                            }
                        } else {     //高图
                            y = (dragBox.coordY + (e.clientY - dragBox.clientY));
                            //向上滑动;
                            if (-y >= (vm.bgh - vm.slideH)) {
                                y = -(vm.bgh - vm.slideH);
                            }
                            //向下滑动;
                            if (y >= 0) {
                                y = 0;
                            }
                        }

                        $("#cut-bg").css({left: x + vm.bgl, top: y + vm.bgt});

                        background.offsetx = x / (vm.currentPage.pageScaleW * page.scale);
                        background.offsety = y / (vm.currentPage.pageScaleH * page.scale);

                        $scope.$digest();
                        e.stopPropagation();
                        e.preventDefault();
                        return false;

                    }

                };

                function stopBgEdit() {
                    drag = false;
                    dragBox = {};
                    document.removeEventListener("mousemove", dragBgEdit, true);
                    document.removeEventListener("mouseup", stopBgEdit, true);
                };

                //防止事件冒泡，默认事件
                function fn(e) {
                    // e = e || window.event;
                    // e.cancelBubble = true;
                    // e.stopPropagation();
                    // e.returnValue = false;
                    // e.preventDefault();
                    if (e && e.stopPropagation) {
                        // 因此它支持W3C的stopPropagation()方法
                        e.stopPropagation();
                    } else {
                        // 否则，我们需要使用IE的方式来取消事件冒泡
                        window.event.cancelBubble = true;
                    }

                    // 阻止默认浏览器动作(W3C)
                    if (e && e.preventDefault) {
                        e.preventDefault();
                    } else {
                        // IE中阻止函数器默认动作的方式
                        window.event.returnValue = false;
                    }
                }
            }]
    };

    angular
        .module('editorApp')
        .component('bgOperation', bgOperation);
})();
